import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Badge, Box, Button, Divider, Grid, IconButton, InputAdornment, Paper, Switch, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { getOrderReviewers, postFacilityUserReviewerForm, postReviewerForm } from '../../../../api/facility';
import { getUsers } from '../../../../redux/slices/users';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ChevronBox } from '../../../../reusable-components/chevron-box';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import FormDropdown from '../../../../reusable-components/form/FormDropdown';
import { useSnackbar } from '../../../../reusable-components/snackbar';

ApprovalWorkflowTab.propTypes = {
  selectedRow: PropTypes.object,
  companies: PropTypes.array,
  data: PropTypes.array,
  fetchData: PropTypes.func,
  setTab: PropTypes.func,
  setSelectedRow: PropTypes.func,
};
export default function ApprovalWorkflowTab({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const { users } = useSelector((state) => state.users.data);
  const userData = useMemo(
    () => Object.values(users?.map((u) => ({ id: u.email, label: `${u.firstName} ${u.lastName}` })))
    , [users]
  );
  const [editSectionOpen, setEditSectionOpen] = useState([]);
  const [value, setValue] = useState({
    //reviewers:
    //  [
    //    {
    //      user: { id: '', label: '' },
    //      required: false,
    //      minimum: 0,
    //      categories: [],
    //    },
    //    {
    //      user: { id: '', label: '' },
    //      required: false,
    //      minimum: 0,
    //      categories: [],
    //    },
    //  ] || '',
    //users:
    //  [
    //    {
    //      name: { id: '', label: '' },
    //      reviewers: [{ user: { id: '', label: '' }, categories: [], required: false, minimum: 0, }],
    //    },
    //  ] || [],
  });
  const dispatch = useDispatch();
  const handleEdit = () => {
    setEdit(true);
  };
  const handleGetData = async () => {
    dispatch(getUsers());
    const response = await getOrderReviewers(selectedRow.id);
    if (response.data) {
      const usersMap = response.data.facilityUsers?.map((user) => ({
        name: { id: user.email, label: `${user.name} - ${user.position}` },
        reviewers: [
          ...response.data.reviewers
            ?.filter((rev) => rev.facilityUserEmail === user.email)
            ?.map((rev) => ({
              user: { id: rev.reviewerEmail, label: rev.reviewerName, orderReviewerId: rev.orderReviewerId },
              required: rev.required,
              minimum: rev.minimum,
              categories: [
                { id: 3, label: 'DME' },
                { id: 4, label: 'Office' },
              ],
            })),
        ],
      }));
      const facilityReviewsMap = response.data.reviewers
        ?.filter((rev) => rev.facilityUserEmail === null)
        .map((rev) => ({
          user: { id: rev.reviewerEmail, label: rev.reviewerName, orderReviewerId: rev.orderReviewerId },
          required: rev.required,
          minimum: rev.minimum,
          categories: [
            { id: 1, label: 'dietary' },
            { id: 2, label: 'medical' },
          ],
        }));
      setValue({ ...value, reviewers: facilityReviewsMap, users: usersMap });
    }
  };
  useEffect(() => {
    handleGetData();
  }, []);
  const handleRemoveAll = async (user1) => {
    if (value.reviewers.some((r) => r?.user?.id === user1?.user?.id)) {
      setValue((prevValue) => {
        const newUsers = [...prevValue?.reviewers];
        const newUserReviewers = [...newUsers.filter((reviewer) => reviewer.user.id !== user1.user.id)];
        return { ...prevValue, reviewers: newUserReviewers };
      });
    }
    value.users.forEach((user, userIndex) => {
      if (user.reviewers.map((r) => r?.user?.id).some((o) => o === user1?.user?.id)) {
        setValue((prevValue) => {
          const newUsers = [...prevValue?.users];
          const newUserReviewers = [
            ...newUsers[userIndex]?.reviewers.filter((reviewer) => reviewer.user.id !== user1.user.id),
          ];
          newUsers[userIndex] = { ...newUsers[userIndex], reviewers: newUserReviewers };
          return { ...prevValue, users: newUsers };
        });
      }
    });
  };
  const handleEditSave = async () => {
    const response = await postReviewerForm(value, selectedRow.id);
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      enqueueSnackbar(`Successfully updated facility reviewers`, {
        variant: 'success',
      });
      setEdit(false);
    } else
      enqueueSnackbar(`Error: Could not update reviewers`, {
        variant: 'error',
      });
    handleGetData();
  };
  const handleEditUserSave = async (index) => {
    const response = await postFacilityUserReviewerForm(value?.users[index], selectedRow.id);
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    }
    else if (response.status === 200) {
      enqueueSnackbar(`Successfully updated facility reviewers`, {
        variant: 'success',
      });
      setEdit(false);
    } else
      enqueueSnackbar(`Error: Could not update reviewers`, {
        variant: 'error',
      });
    handleGetData();
  };
  const handleEditSection = (index) => {
    if (editSectionOpen.includes(index)) setEditSectionOpen(editSectionOpen.filter((number) => number !== index));
    else setEditSectionOpen([...editSectionOpen, index]);
  };
  return (
    <Paper sx={{ mt: 2, px: 8, pb: 4 }}>
      <Grid item xl={12}>
        <Grid item xs={8}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item sx={{ mr: 3, my: 3 }}>
              <Typography variant="h3" color="info.main" display="inline">
                {selectedRow.facilityName} | Review Workflow
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box>
          <Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item sx={{ my: 3 }}>
                <Typography variant="subtitle1" color="info.main">Facility Reviewers</Typography>
              </Grid>
              <Grid item>
                {!edit && (
                  <IconButton onClick={handleEdit} sx={{ color: '#212B36', '&:hover': { color: 'info.main' } }}>
                    <EditIcon />
                  </IconButton>
                )}
                {edit && (
                  < Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                      <IconButton onClick={() => { setEdit(false); setEditSectionOpen([]); handleGetData(); }} sx={{ color: "#212B36", "&:hover": { color: "error.main" } }}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton sx={{ color: '#212B36', '&:hover': { color: 'info.main' } }} onClick={handleEditSave}>
                        <Grid container direction="column">
                          <SaveIcon sx={{ mb: 0, pb: 0, height: '14px' }} />
                          <Typography sx={{ mt: 0, pt: 0, fontSize: '8px' }}>save all</Typography>
                        </Grid>
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item sx={{ mx: 2 }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  {value?.reviewers?.length > 0 && value?.reviewers?.map((reviewer, reviewerIndex) => {
                    const colors = ['#90269d', 'secondary.main', '#cc6ad3'];
                    const badgecontent = (reviewer?.minimum > 0) ? (<Tooltip arrow placement="top" title={`review orders over $${reviewer.minimum}`}><Box>{`$${reviewer.minimum}`}</Box></Tooltip>) : (undefined);
                    return <>
                      {reviewer?.user?.label?.length > 0 &&
                        <Badge badgeContent={badgecontent} max={100000} color="info" >
                          <ChevronBox
                            color={colors[reviewerIndex % colors.length]}
                            reviewerIndex={reviewerIndex}
                            length={value?.reviewers.length}
                            children={
                              <Typography>
                                {reviewer?.user?.label}
                                <Tooltip title="reviewer required">{reviewer?.required ? ' *' : ''}</Tooltip>
                              </Typography>
                            }
                          />
                        </Badge>
                      }
                    </>
                  })
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid container >
              {value?.reviewers?.length > 0 && value?.reviewers?.map((reviewer, reviewerIndex) =>
                <Grid item xs={4} key={reviewer.orderReviewerId}>
                  {edit &&
                    <Grid container sx={{ ml: 4, mb: 2 }} alignItems="flex-start" spacing={2}>
                      <Grid item sx={{ mt: 1.5 }}>
                        <Typography variant="subtitle1" >Reviewer {reviewerIndex + 1}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container direction="row">
                          <Grid item xs={10}>
                            <FormDropdown
                              disableClearable
                              disabled={!edit}
                              fullWidth
                              value={value?.reviewers[reviewerIndex].user}
                              onChange={(event, newValue) => {
                                setValue((prevValue) => {
                                  prevValue?.users.forEach((user) => {
                                    const userRevIndex = user.reviewers
                                      ?.map((u) => u?.user?.id)
                                      ?.findIndex((v) => v === prevValue?.reviewers[reviewerIndex].user?.id);
                                    if (userRevIndex > -1) {
                                      const revs = user.reviewers;
                                      revs[userRevIndex] = { categories: [], user: { ...newValue } };
                                    }
                                  });
                                  const newReviewers = [...prevValue.reviewers];
                                  const orderReviewerId2 = newReviewers[reviewerIndex]?.user?.orderReviewerId;
                                  const newReviewer = {
                                    categories: []
                                    , user: { ...newValue, orderReviewerId: orderReviewerId2 }
                                  };
                                  newReviewers[reviewerIndex] = newReviewer;
                                  return { ...prevValue, reviewers: newReviewers };
                                });
                              }}
                              options={userData}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              getOptionLabel={(option) => option?.label ? `${option?.label} - ${option?.id}` : ''}
                              renderInput={(params) => <TextField {...params} label="Search or select" />}
                            />
                          </Grid>
                          <ConfirmDialog
                            icon="delete-sweep"
                            title="Delete"
                            color="error"
                            content={`Deleting a reviewer from the facility completely will delete their permission to edit orders`}
                            actionButton="Delete"
                            action={() => handleRemoveAll(reviewer)}
                          />
                        </Grid>
                        <Grid item container direction="column" alignItems="flex-start" sx={{ ml: 0.5 }}>
                          <Grid item container direction="row" alignItems="center" sx={{ mt: 1 }}>
                            <Typography variant="caption">
                              Review if over
                            </Typography>
                            <TextField
                              size="small"
                              type="number"
                              variant="standard"
                              label=""
                              sx={{ maxWidth: 70, ml: 3 }}
                              InputLabelProps={{ style: { fontSize: 12, lineHeight: 3, p: 0 } }} // font size of input label
                              InputProps={{
                                sx: { fontSize: 12, p: 0 },
                                startAdornment: (
                                  <InputAdornment size="small" position="start">
                                    <Typography variant="caption">$</Typography>
                                  </InputAdornment>
                                )
                              }}
                              value={value.reviewers[reviewerIndex].minimum}
                              onChange={(event) => {
                                const newValue = event.target.value;
                                setValue((prevValue) => {
                                  prevValue?.users.forEach((user) => {
                                    const userRevIndex = user.reviewers
                                      ?.map((u) => u?.user?.id)
                                      ?.findIndex((v) => v === prevValue?.reviewers[reviewerIndex].user?.id);
                                    if (userRevIndex > -1) {
                                      const revs = user.reviewers;
                                      revs[userRevIndex] = { ...revs[userRevIndex], minimum: newValue };
                                    }
                                  });
                                  const newReviewers = [...prevValue.reviewers];
                                  const userOld = newReviewers[reviewerIndex];
                                  const newReviewer = {
                                    ...userOld, minimum: newValue
                                  };
                                  newReviewers[reviewerIndex] = newReviewer;
                                  return { ...prevValue, reviewers: newReviewers };
                                });
                              }}
                            />
                          </Grid>
                          <Grid item sx={{ mt: 1 }}>
                            <Typography variant="caption">
                              Reviewer is required
                              <Switch size="small" sx={{ ml: 3 }}
                                checked={value.reviewers[reviewerIndex].required}
                                onChange={(event, newValue) => {
                                  setValue((prevValue) => {
                                    prevValue?.users.forEach((user) => {
                                      const userRevIndex = user.reviewers
                                        ?.map((u) => u?.user?.id)
                                        ?.findIndex((v) => v === prevValue?.reviewers[reviewerIndex].user?.id);
                                      if (userRevIndex > -1) {
                                        const revs = user.reviewers;
                                        revs[userRevIndex] = { ...revs[userRevIndex], required: newValue };
                                      }
                                    });
                                    const newReviewers = [...prevValue.reviewers];
                                    const userOld = newReviewers[reviewerIndex];
                                    const newReviewer = {
                                      ...userOld, required: newValue
                                    };
                                    newReviewers[reviewerIndex] = newReviewer;
                                    return { ...prevValue, reviewers: newReviewers };
                                  });
                                }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              )
              }
            </Grid>
            {edit &&
              <Grid item>
                <Button variant="outlined" color="secondary" size="small" sx={{ ml: 6, mt: 2 }}
                  onClick={() => {
                    setValue(prevValue => {
                      const newReviewers = [...prevValue.reviewers];
                      newReviewers.push({ user: {}, categories: [] });
                      prevValue.users.forEach((user) => {
                        const revs = user.reviewers;
                        revs.push({ user: {}, categories: [] });
                      });
                      return { ...prevValue, reviewers: newReviewers };
                    });
                  }} >Add Reviewer</Button>
              </Grid>
            }
            <Divider sx={{ mt: 2, width: '100%', borderColor: '#4ca5e7', borderStyle: 'dashed' }} />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ marginBottom: 2, mt: 2 }}
            >
              <Grid item>
                <Typography variant="subtitle1" color="info.main">
                  Facility Employee Reviewers
                </Typography>
              </Grid>
              <Grid item>
                {/**/}
              </Grid>
            </Grid>
            {value?.users?.length > 0 &&
              value?.users?.map((user, index) => (
                <>
                  <Box sx={{ mx: 4, my: 2 }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} >
                      <Typography sx={{ mr: 1 }}> <strong>{index + 1})</strong> {value?.users[index]?.name?.label}</Typography>
                      <IconButton onClick={() => handleEditSection(index)} sx={{ color: '#212B36', '&:hover': { color: editSectionOpen?.includes(index) ? 'error.main' : 'info.main' } }}>
                        {editSectionOpen?.includes(index) ?
                          <CloseIcon />
                          : <EditIcon />}
                      </IconButton>
                      {editSectionOpen?.includes(index) &&
                        <Tooltip title="Save">
                          <IconButton sx={{ color: '#212B36', '&:hover': { color: 'info.main' } }}
                            onClick={() => { handleEditUserSave(index); handleEditSection(index); }}>
                            <Grid container direction="column">
                              <SaveIcon sx={{ mb: 0, pb: 0, height: '14px' }} />
                              <Typography sx={{ mt: 0, pt: 0, fontSize: '8px' }}>save</Typography>
                            </Grid>
                          </IconButton>
                        </Tooltip>
                      }
                      {user?.reviewers?.length > 0 && user?.reviewers?.map((reviewer, reviewerIndex) => {
                        const colors = ['#90269d', 'secondary.main', '#cc6ad3'];
                        const badgecontent = (reviewer?.minimum > 0) ? (<Tooltip title={`review orders over $${reviewer.minimum}`}><Box>{`$${reviewer.minimum}`}</Box></Tooltip>) : (undefined);
                        return <> {reviewer?.user?.label?.length > 0 &&
                          <Badge badgeContent={badgecontent} max={100000} color={'info'} >
                            <ChevronBox
                              color={colors[reviewerIndex % colors.length]}
                              reviewerIndex={reviewerIndex}
                              length={user?.reviewers.length}
                              children={
                                <Typography>
                                  {reviewer?.user?.label}<Tooltip title="reviewer required">{reviewer?.required ? ' *' : ''}</Tooltip>
                                </Typography>} />
                          </Badge>
                        }
                        </>
                      })
                      }
                    </Grid>
                  </Box>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    {user?.reviewers?.length > 0 &&
                      user?.reviewers?.map((reviewer, reviewerIndex) =>
                        <>
                          <Grid item xs={4}>
                            {(editSectionOpen?.includes(index)) &&
                              <Grid container sx={{ ml: 4 }} alignItems="flex-start" spacing={2}>
                                <Grid item sx={{ mt: 1.5 }}>
                                  <Typography variant="subtitle1" >Reviewer {reviewerIndex + 1}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Grid container direction="row">
                                    <Grid item xs={10}>
                                      <FormDropdown
                                        disableClearable
                                        disabled={!editSectionOpen?.includes(index)}
                                        fullWidth
                                        value={value?.users[index]?.reviewers[reviewerIndex].user}
                                        onChange={(event, newValue) => {
                                          setValue(prevValue => {
                                            const newUsers = [...prevValue?.users];
                                            const newUserReviewers = [...newUsers[index]?.reviewers];
                                            const orderReviewerId2 = newUserReviewers[reviewerIndex]?.user?.orderReviewerId;
                                            newUserReviewers[reviewerIndex] = {
                                              ...newUserReviewers[reviewerIndex]
                                              , user: { ...newValue, orderReviewerId: orderReviewerId2 }
                                            };
                                            newUsers[index] = { ...newUsers[index], reviewers: newUserReviewers };
                                            return { ...prevValue, users: newUsers };
                                          })
                                        }}
                                        options={userData}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        getOptionLabel={(option) => option?.label ? `${option?.label} - ${option?.id}` : ''}
                                        renderInput={(params) => <TextField {...params} label="Search or select" />}
                                      />
                                    </Grid>

                                    <ConfirmDialog
                                      icon="delete-outline"
                                      title="Delete"
                                      color="error"
                                      content={`Deleting a reviewer from the facility completely will delete their permission to edit orders`}
                                      actionButton="Delete"
                                      action={() => {
                                        const usersArr = [...value.users];
                                        const reviewersArr = [...usersArr[index]?.reviewers]
                                        reviewersArr.splice(reviewerIndex, 1);
                                        usersArr[index] = { ...usersArr[index], reviewers: reviewersArr };
                                        setValue({ ...value, users: usersArr })
                                      }}
                                    />
                                    <Grid item container direction="column" alignItems="flex-start" sx={{ ml: 0.5 }} >
                                      <Grid item container direction="row" alignItems="center" sx={{ mt: 1 }}>
                                        <Typography variant="caption">
                                          Review if over
                                        </Typography>
                                        <TextField
                                          size="small"
                                          type="number"
                                          variant="standard"
                                          sx={{ maxWidth: 70, ml: 3 }}
                                          InputLabelProps={{ style: { fontSize: 12, lineHeight: 3, p: 0 } }} // font size of input label
                                          InputProps={{
                                            sx: { fontSize: 12, p: 0 },
                                            startAdornment: <InputAdornment size="small" position="start"><Typography variant="caption">$</Typography></InputAdornment>,
                                          }}
                                          value={value?.users[index]?.reviewers[reviewerIndex].minimum}
                                          onChange={(event) => {
                                            setValue(prevValue => {
                                              const newUsers = [...prevValue?.users];
                                              const newUserReviewers = [...newUsers[index]?.reviewers];
                                              newUserReviewers[reviewerIndex] = {
                                                ...newUserReviewers[reviewerIndex]
                                                , minimum: event.target.value
                                              };
                                              newUsers[index] = { ...newUsers[index], reviewers: newUserReviewers };
                                              return { ...prevValue, users: newUsers };
                                            })
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sx={{ mt: 1 }}>
                                        <Typography variant="caption">
                                          Reviewer Required
                                          <Switch size="small" sx={{ ml: 3 }}
                                            checked={value?.users[index]?.reviewers[reviewerIndex].required}
                                            onChange={(event, newValue) => {
                                              setValue((prevValue) => {
                                                const newUsers = [...prevValue?.users];
                                                const newUserReviewers = [...newUsers[index]?.reviewers];
                                                newUserReviewers[reviewerIndex] = {
                                                  ...newUserReviewers[reviewerIndex]
                                                  , required: newValue
                                                };
                                                newUsers[index] = { ...newUsers[index], reviewers: newUserReviewers };
                                                return { ...prevValue, users: newUsers };
                                              })
                                            }} />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                          </Grid>
                        </>)
                    }
                  </Grid>
                  {(editSectionOpen?.includes(index)) &&
                    <Grid item xs={2} sx={{ ml: 6, mt: 4, mb: 2 }}>
                      <Button variant="outlined" color="secondary" size="small" onClick={() => {
                        setValue((prevValue) => {
                          const newUsers = [...value.users];
                          const newUserReviewers = [...newUsers[index]?.reviewers];
                          newUserReviewers.push({ user: {}, categories: [] });
                          newUsers[index] = { ...newUsers[index], reviewers: newUserReviewers };
                          return { ...prevValue, users: newUsers };
                        });
                      }} >
                        Add Reviewer
                      </Button>
                    </Grid>}
                  <Divider />
                </>
              ))}
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
}