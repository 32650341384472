import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import RentalOrderSummary from '../../../components/dashboard/rentals/newRental/RentalOrderSummary';
import RentalMultipleOrderSummary from '../../../components/dashboard/rentals/newRental/RentalMultipleOrderSummary';

export default function RentalSummary() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get('residentId') || null;

  return (
    <>
      <Helmet>
        <title>Rental Order Summary | Platform Solutions</title>{' '}
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {residentId != null ? <RentalOrderSummary /> : <RentalMultipleOrderSummary />}
        </Grid>
      </Grid>
    </>
  );
}
