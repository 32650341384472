import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Tabs, Tab, Box } from '@mui/material';
import ReceivedEmailsView from '../../../components/dashboard/admin/received-emails/ReceivedEmailsView';
import { TabPanel } from '../../../reusable-components/tab-panel';
import InvalidInvoicesView from '../../../components/dashboard/admin/received-emails/InvalidInvoicesView';
import axiosInstance from '../../../utils/axios';

export default function ReceivedEmails()
{
  const [tab, setTab] = useState(0);
  const [canViewInvalidInvoices, setCanViewInvalidInvoices] = useState(false);

  useEffect(() => {
    axiosInstance.get("/Admin/ReceivedEmail/CanViewInvalidInvoices")
      .then(res => setCanViewInvalidInvoices(res.data))
      .catch(() => { });
  }, [])

  return (
    <>
      <Helmet>
        <title>Admin Received Emails | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
            <Tab label="Received Emails" />
            {canViewInvalidInvoices  && <Tab label="Invalid Invoices" />}
          </Tabs>
          <Box sx={{ width: "100%" }}>
            <TabPanel value={tab} index={0}>
              <ReceivedEmailsView />
            </TabPanel>
            {canViewInvalidInvoices && <TabPanel value={tab} index={1}>
              <InvalidInvoicesView />
            </TabPanel>}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
