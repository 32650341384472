import { Button, Grid, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { postSaveOrder, setReviewOrder } from '../../../../api/orders';
import { clearTempOrderList, getPendingOrderInfo, getPendingOrders } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import NewItemDialog from '../../admin/catalog/NewItemDialog';
import CartCheck from './CartCheck';
import DownloadOrderDropdown from './DownloadOrderDropdown';
import OrderTotal from './OrderTotal';
import { fDate, fISODateTime, timestamp } from '../../../../utils/formatTime';

CustomToolbar.propTypes = {
  data: PropTypes.array,
  setSelected: PropTypes.func,
  setSplitOrder: PropTypes.func,
  addItemCategoryId: PropTypes.number,
  setAddItemCategoryId: PropTypes.func,
  setOpenProcessDialog: PropTypes.func,
  allReqDates: PropTypes.array,
};

export default function CustomToolbar({
  data,
  setSelected,
  setSplitOrder,
  addItemCategoryId,
  setAddItemCategoryId,
  setOpenProcessDialog,
  allReqDates,
}) {
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const hasAccess = useScopeCheck(['Edit-Order', 'Submit-Order'], true);
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { orderById } = useSelector((state) => state.orders || {});
  const {
    unsavedChanges,
    items = [],
    facilityId,
    orderAgoraCategories = [],
    vendors = [],
    orderNumber,
    orderDelay,
  } = orderById[id] || {};
  const {
    data: { pendingOrders },
  } = useSelector((state) => state.orders);
  const { user } = useSelector((state) => state.user);
  const orderReviews = useMemo(
    () => pendingOrders?.find((o) => o.id === orderById[id]?.id)?.reviews,
    [id, orderById, pendingOrders]
  );

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const openMenu = Boolean(menuAnchorEl);

  const handleSetReviewOrder = async (id) => {
    setLoading(true);
    const response = await setReviewOrder(id);
    if (response.status === 200) {
      dispatch(getPendingOrders());
      dispatch(getPendingOrderInfo(id));

      enqueueSnackbar('Order has been reviewed', { variant: 'success' });
    } else {
      enqueueSnackbar('Error reviewing order', { variant: 'error' });
    }
    setLoading(false);
  };

  const saveOrderFunction = async (saveAndProcess) => {
    const response = await postSaveOrder({
      orderId: id,
      items,
      vendorDates: allReqDates.map((date) => ({
        vendorId: date.vendorId,
        requestedDate: fISODateTime(date.date),
      })),
    });
    if (response.status === 200) {
      dispatch(clearTempOrderList(id));
      dispatch(getPendingOrderInfo(id, true));
      setSelected([]);
      setSplitOrder(false);
      setAddItemCategoryId(null);
      enqueueSnackbar('Order has been updated!', { variant: 'success' });
      if (saveAndProcess) setOpenProcessDialog(true);
    } else {
      enqueueSnackbar('Error saving order', { variant: 'error' });
    }
  };

  const itemMissingCategory = items?.some(
    (item) => !item.deleted && item.approved === true && (!item.agoraCategory?.id || !item.agoraSubcategory?.id)
  );
  const noItems = items === null || items.length === 0;

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mb: 1 }}>
        <Grid item>
          <Button
            variant="text"
            onClick={() => {
              if (filter === 'rerun') navigate(`/dashboard/orders/${id}?filter=previous`);
              else navigate(`/dashboard/orders?filter=pending`);
            }}
            startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
            sx={{
              color: '#454F5B',
              fontWeight: 'bold',
            }}
          >
            {filter === 'rerun' ? 'Back to completed order' : ' Back to pending orders'}
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="#208ee0">
            Order #{orderNumber || id}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>

      <Grid container direction="row" spacing={1}>
        {addItemCategoryId ? (
          <>
            <Grid item>
              <NewItemDialog
                agoraCat={orderAgoraCategories}
                agoraSubCat={vendors}
                agoraVendors={vendors.map((vendor) => ({ value: vendor.id, label: vendor.name }))}
                addToOrderList={{
                  facilityId,
                  categoryId: addItemCategoryId,
                }}
                buttonProps={{ sx: {} }}
              />
            </Grid>
            <Grid item>
              <OrderTotal />
            </Grid>
          </>
        ) : (
          <>
            <ScopeGuard scopes={['Edit-Order']} allowAdmin>
              <Grid item>
                <Button
                  color="secondary"
                  size="small"
                  variant="outlined"
                  startIcon={<Iconify icon="eva:plus-outline" />}
                  onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                >
                  Add items
                </Button>
              </Grid>
            </ScopeGuard>
            <Menu anchorEl={menuAnchorEl} open={openMenu} onClose={() => setMenuAnchorEl(null)}>
              {orderAgoraCategories?.map((category) => (
                <MenuItem
                  key={category.id}
                  onClick={() => {
                    setMenuAnchorEl(null);
                    setAddItemCategoryId(category.id);
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {category.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            <Grid item>
              <DownloadOrderDropdown />
            </Grid>
            {!unsavedChanges && filter !== 'rerun' && (
              <ScopeGuard scopes={['Edit-Order']} allowAdmin>
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setSplitOrder(true);
                    }}
                    variant="outlined"
                    size="small"
                    startIcon={<Iconify icon={'lucide:split'} />}
                  >
                    Split order
                  </Button>
                </Grid>
              </ScopeGuard>
            )}
            <ScopeGuard scopes={['Cart-Check', 'Edit-Order']} allowAdmin>
              <Grid item>
                <CartCheck data={data} />
              </Grid>
            </ScopeGuard>
            <Grid item>
              <OrderTotal />
            </Grid>
            {orderReviews?.find((o) => o.reviewerEmail === user.email)?.reviewed === false && (
              <Grid item>
                <AwaitButton
                  color="primary"
                  size="small"
                  variant="outlined"
                  loading={loading}
                  onClick={() => handleSetReviewOrder(id)}
                >
                  Review order
                </AwaitButton>
              </Grid>
            )}
            {filter !== 'rerun' && unsavedChanges && !addItemCategoryId && hasAccess && (
              <Grid item>
                <Button
                  color="error"
                  onClick={() => {
                    window.location.reload();
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1 }}
                  endIcon={<Iconify icon={'mdi:reload'} />}
                >
                  Cancel
                </Button>

                <AwaitButton color="primary" size="small" variant="contained" onClick={saveOrderFunction}>
                  Save changes
                </AwaitButton>
              </Grid>
            )}
            <ScopeGuard scopes={['Submit-Order']} allowAdmin>
              <Tooltip
                placement="top"
                arrow
                title={
                  itemMissingCategory ? (
                    'Make sure all approved items have a category and subcategory.'
                  ) : noItems ? (
                    'There must be at least one item.'
                  ) : orderDelay ? (
                    <div>
                      Order was set on a timer by {orderDelay.addedBy} on {timestamp(orderDelay.dateAdded)}
                      <br />
                      Order will be sent out on {fDate(orderDelay.sendOrderAt, 'MM/dd/yyyy hh:mm a')}
                    </div>
                  ) : (
                    ''
                  )
                }
              >
                <Grid item>
                  <Tooltip title={orderReviews?.some((r) => r.required === true && r.reviewed === false) ?
                    "Order is waiting on required reviewers" : ""}>
                    <span> <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={
                      itemMissingCategory ||
                      noItems ||
                      orderReviews?.some((r) => r.required === true && r.reviewed === false)
                    }
                    onClick={() => {
                      if (unsavedChanges && filter !== 'rerun') saveOrderFunction(true);
                      else setOpenProcessDialog(true);
                    }}
                  >
                    {unsavedChanges && filter !== 'rerun' ? 'Save & Process' : 'Process'}
                    </Button>   </span>
                  </Tooltip>
                </Grid>
              </Tooltip>
            </ScopeGuard>
          </>
        )}
      </Grid>
    </>
  );
}
