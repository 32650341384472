import { Alert, Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { postVendorForm } from '../../../../api/warehouse-vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';

WarehouseVendorModal.propTypes = {
  row: PropTypes.object,
  vendors: PropTypes.array,
  vendorTypes: PropTypes.array,
  refetchData: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function WarehouseVendorModal({ open, onClose, row, vendors, vendorTypes, refetchData })
{
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({});

  useEffect(() => {
    setValue({
      id: row ? row.id : null,
      name: row ? row.name : '',
      globalVendor: row ? row.globalVendor : false,
      vendorTypeId: row ? row.vendorTypeId : 0,
      division: row ? row.division : null,
    })
  }, [row, open]);


  const isDuplicateRule = vendors.find((vendor) => vendor.name?.toLowerCase().trim() === value.name?.toLowerCase().trim() && (!row || vendor.id !== row.id));

  const handleSave = async () => {
    const response = await postVendorForm({ ...value, id: row?.id });
    if (response.data) {
      enqueueSnackbar('Warehouse vendor updated successfully', { variant: 'success' });
      onClose();
    } else {
      enqueueSnackbar('Failed to update warehouse vendor', { variant: 'error' });
    }
    refetchData();
  };

  const vendorType = vendorTypes?.find((opt) => opt.value === value.vendorTypeId) ?? null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        {row ? 'Edit' : 'Add'} Warehouse Vendor
        <CloseIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            {isDuplicateRule && <Alert severity="error">This vendor already exists</Alert>}
            <Typography variant="subtitle1">Name</Typography>
            <TextField
              fullWidth
              size="small"
              value={value.name}
              onChange={(e) => setValue({ ...value, name: e.target.value })}
              sx={{
                mt: .5,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle1">Division</Typography>
            <TextField
              fullWidth
              size="small"
              value={value.division}
              onChange={(e) => setValue({ ...value, division: e.target.value })}
              sx={{
                mt: .5,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle1">Vendor Group</Typography>
            <Autocomplete
              fullWidth
              size='small'
              renderInput={(params) => <TextField {...params} label="Search or select" />}
              sx={{
                mt: 0.5,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
              value={vendorType}
              onChange={(event, newValue) => setValue({ ...value, vendorTypeId: newValue?.value })}
              options={vendorTypes ?? []}
            />
          </Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                <Checkbox
                  checked={value.globalVendor || (vendorType?.usesGlobalAccounts ?? false)}
                  disabled={(vendorType?.usesGlobalAccounts ?? false)}
                  onChange={(event, val) => setValue({ ...value, globalVendor: val })}
                />{' '}
                Unique accounts
                <Box sx={{ color: 'info.main', display: 'inline' }}>
                  <Tooltip title="Makes sure no site has a duplicate account">
                    <Iconify
                      icon="material-symbols:info-outline"
                      width={18}
                      height={18}
                      style={{ paddingTop: '5px', paddingLeft: '2px' }}
                    />
                  </Tooltip>
                </Box>
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <AwaitButton
          variant="contained"
          color="primary"
          size="medium"
          sx={{ width: 150 }}
          onClick={handleSave}
          disabled={!value.name || isDuplicateRule}
        >
          {row ? 'Save' : 'Add Vendor'}
        </AwaitButton>
      </DialogActions>
    </Dialog>
  );
}
