import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

EditIconButton.propTypes = {
  onClick: PropTypes.func,
  tooltipText: PropTypes.string
};

export default function EditIconButton({ onClick, tooltipText = "Edit" }) {
  return (
    <Tooltip title={tooltipText}>
      <IconButton edge="end" color="secondary" onClick={onClick} sx={{ mr: 0.5 }}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}
