// remember to import this file in index.js

export default function Container() {
  return {
    MuiContainer: {
      styleOverrides: {
        root: { marginTop: '18px' },
      },
    },
  };
}
