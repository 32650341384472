import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { SystemView } from '../../../components/dashboard/admin/system-notices';

// ----------------------------------------------------------------------

export default function SystemNotices() {
  return (
    <>
      <Helmet>
        <title>Admin System Notices | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <SystemView />
        </Grid>
      </Grid>
    </>
  );
}
