import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { postDeleteWarehouseVendor } from '../../../../api/warehouse-vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { WAREHOUSE_VENDORS } from '../../../../reusable-components/datagrid/vendorColumns';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import WarehouseVendorFileModal from './WarehouseVendorFilesModal';
import WarehouseVendorModal from './WarehouseVendorModal';
import EditIconButton from '../../../../reusable-components/basic-buttons/EditIconButton';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';

WarehouseVendors.propTypes = {
  vendorData: PropTypes.array,
  vendorTypes: PropTypes.array,
  isLoading: PropTypes.bool,
  fetchVendorData: PropTypes.func,
};

export default function WarehouseVendors({ vendorData, vendorTypes, fetchVendorData, isLoading })
{
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [row, setRow] = useState(null);

  const deleteWarehouseVendor = async (id) => {
    try {
      const response = await postDeleteWarehouseVendor(id);
      if (response.status === 200) {
        enqueueSnackbar('Warehouse vendor deleted successfully', { variant: 'success' });
        fetchVendorData();
      } else {
        enqueueSnackbar('Problem deleting Warehouse vendor', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error deleting warehouse vendor:', error);
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    id: 'actions',
    renderCell: (params) => <>
      <WarehouseVendorFileModal edit row={params.row} vendorData={vendorData} fetchData={fetchVendorData} />
      <EditIconButton onClick={() => { setRow(params.row); setOpen(true); }} />

      <ConfirmDialog
        content={`Are you sure you want to delete ${params.row.name}?`}
        action={() => deleteWarehouseVendor(params.row.id)}
      />
    </>,
  };

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-warehouse-vendors"
          boxSX={{ height: 'calc(100vh - 340px)' }}
          data={vendorData}
          gridColumns={[...WAREHOUSE_VENDORS, actionButtons]}
          CustomLeftToolbar={() => <AddButton onClick={() => { setRow(null); setOpen(true); }}>Add Vendor</AddButton>}
          pinnedColumns={{ right: ['actions'] }}
          sort={[{ field: 'name', sort: 'asc' }]}
          isLoading={isLoading}
        />
        <WarehouseVendorModal open={open} onClose={() => { setRow(null); setOpen(false); }} row={row} vendors={vendorData} vendorTypes={vendorTypes} refetchData={fetchVendorData} />
      </Grid>
    </>
  );
}
