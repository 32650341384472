import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET SystemNotice / SystemNotices;
export async function getSystemNoticesData() {
  const response = await axios.get('SystemNotice/SystemNotices');
  return response;
}

// GET SystemNotice / SystemMessages; id
export async function getSystemMessagesData(id) {
  const response = await axios.get(`SystemNotice/SystemMessages?id=${id}`);
  return response;
}

export async function postSystemNoticeForm({ id = null, title, complete = false, messages }) {
  const formData = objectToFormData({
    id,
    title,
    complete,
    messages: messages?.map((m) => {
      const { id, ...rest } = m;
      return id < 0 ? rest : m;
    }),
  });
  const response = await axios.post('SystemNotice/SystemNoticeForm', formData);
  return response;
}

// id; complete
// POST SystemNotice / SystemNoticeCompleteToggle;
export async function postSystemNoticeCompleteToggle({ id, complete }) {
  const response = await axios.post(`SystemNotice/SystemNoticeCompleteToggle?id=${id}&complete=${complete}`);
  return response;
}

// GET SystemNotice / DownloadSystemNotices;
