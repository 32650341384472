import PropTypes from 'prop-types';
import TextFormField from './IntegrationTextFormField';
import CheckboxFormField from './IntegrationCheckboxFormField';
import Row from './IntegrationFormRow';

PunchoutIntegration.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func
};

export default function PunchoutIntegration({ value, setValue }) {

  return <>
    <TextFormField
      header={"Start Punchout URL"}
      value={value.startPunchoutURL}
      onChange={(e) => setValue({ ...value, startPunchoutURL: e.target.value })}
    />
    <TextFormField
      header={"Return Punchout URL"}
      value={value.returnPunchoutURL}
      onChange={(e) => setValue({ ...value, returnPunchoutURL: e.target.value })}
    />
    <TextFormField
      header={"Append To Vendor Identity"}
      value={value.appendToVendorIdentity}
      onChange={(e) => setValue({ ...value, appendToVendorIdentity: e.target.value })}
    />  
    <Row>
      <CheckboxFormField
        label={"Disabled"}
        checked={value.disabled ?? false}
        onChange={(e) => setValue({ ...value, disabled: e.target.checked })}
      />
      <CheckboxFormField
        label={"Extrinsic Unique Name"}
        checked={value.extrinsicUniqueName ?? false}
        onChange={(e) => setValue({ ...value, extrinsicUniqueName: e.target.checked })}
      />
      <CheckboxFormField
        label={"Allows Order Changes"}
        checked={value.allowsOrderChanges ?? false}
        onChange={(e) => setValue({ ...value, allowsOrderChanges: e.target.checked })}
      />
    </Row>
    <CheckboxFormField
      label={"Use Account As Vendor Identity"}
      checked={value.useAccountAsVendorIdentity ?? false}
      onChange={(e) => setValue({ ...value, useAccountAsVendorIdentity: e.target.checked })}
    />     
  </>;
}
