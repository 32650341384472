import { Box, Grid, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';

ProgressBar.propTypes = {
  values: PropTypes.array,
};

export default function ProgressBar({ values })
{
  return <Box sx={{ m: 2 }}>
    <Grid container direction="row" spacing={1}>
      {values?.map((value, index) => <Grid key={index} xs item>
        <LinearProgress variant="determinate" value={value} sx={{ height: 8, borderRadius: 3 }} />
      </Grid>)}
    </Grid>
  </Box>;
} 
