import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// Api/Category/CategoriesData;
export const getCategoriesData = async () => {
  const response = await axios.get('/Category/CategoriesData');
  return response;
};

// POST
// Api/Category/DeleteCategory; id
export const postDeleteCategory = async (id) => {
  const response = await axios.post(`/Category/DeleteCategory?id=${id}`);
  return response;
};

// GET
// Api/Category/CheckCategoryHasSubcategory; id
export const getCheckCategoryHasSubcategory = async (id) => {
  const response = await axios.get(`/Category/CheckCategoryHasSubcategory?id=${id}`);
  return response;
};

// GET
// Api/Category/CheckDuplicateSubcategory; id mainCategoryId name
export const getCheckDuplicateSubcategory = async (id, mainCategoryId, name) => {
  const response = await axios.get(
    `/Category/CheckDuplicateSubcategory?id=${id}&mainCategoryId=${mainCategoryId}&name=${name}`
  );
  return response;
};

// GET
// Api/Category/CheckDuplicateCategory; id name
export const getCheckDuplicateCategory = async (id, name) => {
  const response = await axios.get(`/Category/CheckDuplicateCategory?id=${id}&name=${name}`);
  return response;
};

// POST
// Api/Category/DeletePurchasingSubcategory; id substituteSubcategoryId substituteCategoryId
export const postDeletePurchasingSubcategory = async ({ id, substituteSubcategoryId, substituteCategoryId }) => {
  const formData = objectToFormData({
    id,
    substituteSubcategoryId,
    substituteCategoryId,
  });
  const response = await axios.post('Category/DeletePurchasingSubcategory', formData);

  return response;
};

// POST
// Api/Category/CategoryForm; id name
export const postCategoryForm = async ({ id, name, icon }) => {
  const formData = objectToFormData({
    name,
    icon,
  });

  if (id) {
    formData.append('id', id);
  }
  const response = await axios.post('Category/CategoryForm', formData);

  return response;
};

// POST
// Api/Category/SubcategoryForm; Id Name MainCategoryId
export const postSubcategoryForm = async ({ id, name, mainCategoryId }) => {
  const formData = objectToFormData({
    name,
    mainCategoryId,
  });

  if (id) {
    formData.append('id', id);
  }

  const response = await axios.post('Category/SubcategoryForm', formData);

  return response;
};

// POST
// Api/Category/DownloadCategories;
////Download Api is used in code bec we need the `${HOST_API_URL}

// POST
// Api/Category/DownloadSubcategories;
//Download Api is used in code bec we need the `${HOST_API_URL}
