import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Button, DialogActions } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { listOfEmojis } from '../../../../utils/cssStyles';

EmojiPicker.propTypes = {
  icon: PropTypes.string,
  setIcon: PropTypes.func,
};

export default function EmojiPicker({ icon, setIcon }) {
  console.log({ icon });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <Iconify icon={icon} width={60} height={60} />
      </IconButton>
      <Dialog open={open} maxWidth="lg" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Select a icon
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <Grid container spacing={1} sx={{ mb: 2 }}>
            {listOfEmojis?.map((emoji, index) => (
              <Grid
                item
                xs={1}
                key={index}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ cursor: 'pointer', height: '100%' }}
              >
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setIcon(emoji);
                      handleClose();
                    }}
                  >
                    <Iconify icon={emoji} width={40} height={40} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
