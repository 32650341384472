import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';

PinToggle.propTypes = {
  value: PropTypes.bool,
};

export default function PinToggle({ value }) {
  return <Tooltip arrow title={value ? "Unpin ticket" : "Pin ticket"}>
    <IconButton
      color="secondary"
      onClick={(event) => {
        if (value) event.removePin = true;
        else event.addPin = true;
      }}
      sx={{
        opacity: 0.7,
        '&:hover': {
          opacity: 1,
        },
      }}
    >
      {value ? < ArrowCircleDownOutlinedIcon /> : <ArrowCircleUpOutlinedIcon />}
    </IconButton>
  </Tooltip>;
}
