import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { useDispatch } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { addItemToAnOrderInRedux, removeItemFromErrorList } from '../../../../redux/slices/orders';
import { fCurrency } from '../../../../utils/formatNumber';
import AddItem from './AddItem';
import { AwaitButton } from '../../../../reusable-components/await-button';

AddUseItemQuantityField.propTypes = {
  // product: PropTypes.object,
  row: PropTypes.object,
  errorRow: PropTypes.object,
};

export default function AddUseItemQuantityField({ row, errorRow }) {
  const dispatch = useDispatch();
  const { id = '' } = useParams();

  const [quantity, setQuantity] = useState(errorRow.quantity);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const useOnce = () => {
    dispatch(
      addItemToAnOrderInRedux({
        ...row,
        orderId: id,
        agoraCategory: errorRow.agoraCategory,
        agoraSubcategory: errorRow.agoraSubcategory,
        id: errorRow.vendorItemId,
        approved: true,
        unsavedId: 0,
        itemId: -3,
        vendorId: errorRow.vendorId,
        vendor: errorRow.vendor,
        uomType: row.uom,
        quantity,
        pictures: row.pictureUrls,
      })
    );
    dispatch(
      addItemToAnOrderInRedux({
        ...errorRow,
        orderId: id,
        approved: false,
        errors: [],
        itemDirectReplacements: [],
        itemSubstitutes: [],
      })
    );

    dispatch(removeItemFromErrorList(id, errorRow.id));
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item>
          <TextField
            size="small"
            value={quantity || ''}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              if (!Number.isNaN(newValue) && newValue >= 0) {
                setQuantity(newValue);
                // handleAddCart(newValue);
              }
            }}
            sx={{ maxWidth: '70px' }}
          />
        </Grid>
        <Grid item sx={{ minWidth: '70px' }}>
          <Typography variant="subtitle2"> {fCurrency(quantity * row.price)}</Typography>
        </Grid>
        <Grid item>
          <AwaitButton variant="outlined" color="black" onClick={useOnce}>
            Use Item Once
          </AwaitButton>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="black" onClick={handleClickOpen}>
            Add Item to Catalog
          </Button>
        </Grid>
      </Grid>
      <Dialog open={openModal} maxWidth="md" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Add Item to Catalog
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AddItem row={row} errorRow={errorRow} handleClose={handleClose} quantity={quantity} />
        </DialogContent>
      </Dialog>
    </>
  );
}
