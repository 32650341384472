import { createSlice } from '@reduxjs/toolkit';
import {
  getOrderSheetData,
  getOrderingFacilitiesData,
  getOrderingCategoriesData,
  getCartTotalData,
  addToCartApi,
  copyOrderItems,
} from '../../api/order-sheet';
import { getBudgetsData } from '../../api/orders';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    cart: [],
    cartItems: [],
    agoraCategory: {},
    budgets: null,
    cartInfo: [],
    facilities: [],
    items: [],
    added: 0,
    categories: [],
    selectedFacilityId: null,
    currFacilityId: null,
    hasNoItems: false,
    hasNoBudget: false,
  },
};

const slice = createSlice({
  name: 'orderSheet',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setProductsFiltered(state, action) {
      state.productsFiltered = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDataSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, ...action.payload };
    },

    getBudgetsSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, budgets: action.payload };
    },

    getCartTotalSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, cartInfo: action.payload };
    },

    getOrderSheetSuccess(state, action) {
      const hasNoItems = action.payload.items.length === 0;
      const hasNoBudget = action.payload.budgets.length === 0;
      state.data = { ...state.data, ...action.payload, hasNoItems, hasNoBudget };
      state.isLoading = false;
    },

    addToCart(state, action) {
      const { cart } = state.data;
      const product = action.payload;
      const index = cart?.findIndex((item) => item.id === product.id);
      if (index === -1) {
        if (product.quantity === 0) return;
        cart.push(product);
      } else if (product.quantity === 0) cart?.splice(index, 1);
      else cart[index].quantity = product.quantity;
      state.isLoading = false;
    },

    addToSingleCart(state, action) {
      const { cart, items } = state.data;
      const { cartItems, categoryId, facilityId } = action.payload;
      const tempCart = [];
      cartItems.forEach((cartItem) => {
        const item = items.find((i) => i.id === cartItem.copyProductId);

        if (item) {
          const { agoraCategory, agoraSubcategory, productDetails } = item;
          const newItem = {
            facilityId,
            categoryId,
            id: item.id,
            productDetails: item.productDetails,
            quantity: cartItem.quantity,
            uomType: cartItem.uomType,
            price: productDetails[0].price,
            uomAmount: productDetails[0].uomAmount,
            agoraCategoryId: agoraCategory.id,
            agoraSubcategoryId: agoraSubcategory.id,
            agoraSubcategory: agoraSubcategory.name,
            ...cartItem,
          };

          tempCart.push(newItem);
        }
      });
      state.data.cart = [...cart, ...tempCart];
      state.isLoading = false;
    },

    updateReduxCatalogItem(state, action) {
      const { id, field, value } = action.payload;
      const { items } = state.data;
      const item = items?.find((item) => item.id === id);

      if (item.productDetails[0]) {
        item.productDetails[0][field] = value;
      }
      state.data = { ...state.data, items };
      state.isLoading = false;
    },

    resetTempCart(state, action) {
      state.data.cart = [];
      state.data.added = action.payload;
    },

    clearAdded(state) {
      state.data.added = 0;
    },
  },
});

export function getOrderSheet(categoryId, facilityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getOrderSheetData(categoryId, facilityId);
      const mergeFacilityIDToResponse = { ...response.data, currFacilityId: facilityId };
      dispatch(slice.actions.getOrderSheetSuccess(mergeFacilityIDToResponse));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getOrderingFacilities() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getOrderingFacilitiesData();
      dispatch(slice.actions.getDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getOrderingCategories(facilityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getOrderingCategoriesData(facilityId);
      dispatch(slice.actions.getDataSuccess({ categories: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addToCart({
  id,
  quantity,
  price,
  uomAmount,
  uomType,
  agoraCategoryId,
  agoraSubcategoryId,
  agoraSubcategory,
  productCategories,
  facilityId,
  categoryId,
  productDetails,
}) {
  return async (dispatch) => {
    dispatch(
      slice.actions.addToCart({
        facilityId,
        categoryId,
        id,
        quantity,
        price,
        uomAmount,
        uomType,
        agoraCategoryId,
        agoraSubcategoryId,
        agoraSubcategory,
        productCategories,
        productDetails,
      })
    );
  };
}

export function resetTempCart(added) {
  return (dispatch) => {
    dispatch(slice.actions.resetTempCart(added));
    setTimeout(() => {
      dispatch(slice.actions.clearAdded());
    }, 2000);
  };
}

export function getCartTotal(facilityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCartTotalData(facilityId);
      dispatch(slice.actions.getDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItemsToCart(items, facilityId, categoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await addToCartApi(items, facilityId, categoryId);
      dispatch(slice.actions.getCartTotalSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateReduxShoppingCart(orderId, categoryId, facilityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await copyOrderItems(orderId);
      dispatch(slice.actions.addToSingleCart({ cartItems: response.data, categoryId, facilityId }));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return true;
  };
}

export function getBudgets({ facilityId, includeUnapproved, useCurrentCensus, orderNumber = 0, categories }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getBudgetsData({
        facilityId,
        includeUnapproved,
        useCurrentCensus,
        orderNumber,
        categories,
      });
      dispatch(slice.actions.getBudgetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// updateCartItemInRedux
export function updateCatalogItemInRedux(id, field, value) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.updateReduxCatalogItem({ id, field, value }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
