import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { CatalogItemView } from '../../../../components/dashboard/admin/catalog/item';
import { getProductDetails } from '../../../../redux/slices/catalog';

CatalogItem.propTypes = {
  row: PropTypes.object,
};

export default function CatalogItem({ row }) {
  const dispatch = useDispatch();

  const {
    data: { productDetails, agoraCategories },
  } = useSelector((state) => state.catalog);
  const { id = '' } = useParams();

  useEffect(() => {  
    if (row === undefined) {
      if (productDetails.productId !== parseInt(id, 10)) dispatch(getProductDetails(id, agoraCategories?.length === 0));
    } else if (productDetails.productId !== row.productId)
      dispatch(getProductDetails(row.productId, agoraCategories?.length === 0));
  }, [productDetails, dispatch, id, agoraCategories, row]);

  return (
    <>
      <Helmet>
        <title>Admin Catalog Item | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <CatalogItemView row={row} />
        </Grid>
      </Grid>
    </>
  );
}
