import { Box, Chip, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { editTicketData } from '../../../../redux/slices/tickets';
import { useDispatch, useSelector } from '../../../../redux/store';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import NewUser from './NewUser';

TicketRecipients.propTypes = {
  subticketId: PropTypes.string,
};

export default function TicketRecipients({ subticketId }) {
  const { id = '' } = useParams();
  const subOrMainId = subticketId > 0 ? subticketId : id;
  const { mainTicket } = useSelector((state) => state.tickets.data.ticketInfoById[subOrMainId]) || {};
  const { subtickets } = useSelector((state) => state.tickets.data.ticketInfoById[id]) || {};
  const { ticketMessages, users } = useSelector((state) => state.tickets.data.ticketById[subOrMainId]) || {};
  const dispatch = useDispatch();
  const [participants, setParticipants] = useState([]);
  const { data: { users: allUsers } } = useSelector((state) => state.tickets);

  useEffect(() => {
    const recipients = [];

    subtickets?.find(s => s.id === subticketId)?.users?.forEach((user) => {
      if (!user.userName) {
        const userObj = allUsers.find((u) => u.id === user.userEmail);
        recipients.push({ id: user.userEmail, value: userObj?.value || null });
      } else {
        recipients?.push({ id: user.userEmail, value: user.userName });
      }
    });

    setParticipants(recipients);

  }, [allUsers, ticketMessages, users, subtickets, subticketId]);

  const handleDelete = (newValue) => {
    editTicket({
      Id: subticketId,
      value: newValue.filter((user) => user.id).map((user) => user.id),
      updateType: 'Users',
    });
    setParticipants(newValue);
  };

  const { enqueueSnackbar } = useSnackbar();

  const editTicket = async ({ Id, value, updateType }) => {
    const response = await dispatch(editTicketData({ Id: mainTicket?.ticketNumber || Id, value, updateType, mainId: id }));
    if (response) {
      enqueueSnackbar('Facility updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Facility failed to update', { variant: 'error' });
    }
    return response;
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="flex-start" spacing={4}>
        <Grid item sx={{ mt: 2 }}>
          <Box>
            <Grid container>
              {participants?.length > 0 && (
                <>
                  {participants?.map((participant, index) => (
                    <Box key={participant.id}>
                      <Chip
                        key={participant.id}
                        label={participant.value || participant?.id}
                        onDelete={() => handleDelete(participants.filter((user) => user.id !== participant.id))}
                        avatar={
                          <CustomAvatar
                            name={participant.value || participant?.id}
                            email={participant.value && participant?.id}
                            index={index}
                            key={index}
                            sx={{ '&.MuiChip-avatar': { color: '#fff' } }}
                          />
                        }
                        sx={{ mr: 1, mb: 1, maxWidth: "180px" }}
                      />
                    </Box>
                  ))}
                </>
              )}
              <Box sx={{ mt: 0.5 }}>
                <NewUser participants={participants}
                  setParticipants={setParticipants}
                  ticketId={subticketId || id}
                  parentTicketId={id} />
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
