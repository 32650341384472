import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography } from '@mui/material';
import { fCurrency } from '../../utils/formatNumber';
import { fMonthDayYear } from '../../utils/formatTime';
import { cloudinaryThumbnail, cloudinaryFull } from '../../utils/formatCloudinary';
import Image from '../image';
import Lightbox from '../lightbox';
import UOMDropdownForCatalog from './UOMDropdownForCatalog';

export const renderColorBox = (value, color) => (
  <Typography
    color={color || 'primary'}
    variant="subtitle2"
    sx={{
      border: 1,
      minWidth: '20px',
      minHeight: '20px',
      borderRadius: '3px',
    }}
  >
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'hidden', // Hide overflow
        textOverflow: 'ellipsis', // Add ellipsis for overflow
        whiteSpace: 'nowrap', // Prevent wrapping
        m: 0.5,
      }}
    >
      {value || ''}
    </Box>
  </Typography>
);
export default function RenderImage({ params }) {
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const { pictures } = params.row.productDetails[0];
  const picture = pictures[0];
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(picture)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictures?.map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}

const picture = {
  field: 'picture',
  headerName: 'Image',
  flex: 0.4,
  renderCell: (params) => <RenderImage params={params} />,
  sortable: false,
  filterable: false,
};

const description = {
  field: 'description',
  headerName: 'Description',
  flex: 1,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const subcategory = {
  field: 'subcategory',
  headerName: 'Subcategory',
  flex: 0.8,
  minWidth: 105,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.row.agoraSubcategory.name}
    </Box>
  ),
  valueGetter: (params) => params.row.agoraSubcategory.name,
  type: 'customSelect',
  path: 'agoraSubcategory.name',
};
const category = {
  field: 'agoraCategory',
  headerName: 'Category',
  flex: 1,
  minWidth: 90,
  renderCell: (params) => renderColorBox(params.row.agoraCategory.name, 'secondary'),
  valueGetter: (params) => params.row.agoraCategory.name,
  type: 'customSelect',
  path: 'agoraCategory.name',
};
const price = {
  field: 'price',
  headerName: 'Price',
  flex: 0.5,
  minWidth: 80,
  renderCell: (params) => fCurrency(params.row.productDetails[0].price),
  valueGetter: (params) => params.row.productDetails[0].price,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
};
const id = {
  field: 'id',
  headerName: 'PS ID',
  flex: 0.5,
  minWidth: 80,
  renderCell: (params) => `PS${params.row.id}`,
  valueGetter: (params) => `PS${params.row.id}`,
  type: 'customText',
  headerAlign: 'left',
  align: 'left',
};
const uom = {
  field: 'uom',
  headerName: 'UOM',
  minWidth: 60,
  flex: 0.5,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
  // valueGetter: (params) => params.row.productDetails[0].uomAmount,
  renderCell: (params) => {
    const { uomAmount, uomType } = params.row.productDetails[0];
    return `${uomAmount}/${uomType}`;
  },
  valueGetter: (params) => {
    const { uomAmount, uomType } = params.row.productDetails[0];
    return `${uomAmount}/${uomType}`;
  },
};

const changeUom = {
  field: 'change_uom',
  headerName: 'UOM',
  renderCell: (params) => {
    const { uomAmount, uomType } = params.row.productDetails[0];

    return params.row.productDetails?.[0]?.productDetailUOMs?.length > 0 ? (
      <UOMDropdownForCatalog row={params.row} />
    ) : (
      `${uomAmount}/${uomType}`
    );
  },
  valueGetter: (params) => {
    const { uomAmount, uomType } = params.row.productDetails[0];
    return `${uomAmount}/${uomType}`;
  },
  width: 90,
  type: 'customSelect',
};

const manufacturer = {
  field: 'manufacturer',
  headerName: 'Manufacturer',
  flex: 1.3,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {params.row.productDetails[0].manufacturer}
    </Box>
  ),
  type: 'customText',
  valueGetter: (params) => params.row.productDetails[0].manufacturer,
};
const manufacturerId = {
  field: 'manufacturerId',
  headerName: ' Id',
  flex: 0.8,
  minWidth: 90,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.row.productDetails[0].manufacturerId}
    </Box>
  ),
  type: 'customText',
  valueGetter: (params) => params.row.productDetails[0].manufacturerId,
};
const vendorItemId = {
  field: 'vendorItemId',
  headerName: 'SKU',
  flex: 0.8,
  minWidth: 120,
  renderCell: (params) => {
    const hasLink = params.row.productDetails[0]?.vendorItemLink;
    return hasLink ? (
      <a href={params.row.productDetails[0]?.vendorItemLink} target="_blank" rel="noreferrer">
        {params.row.productDetails[0]?.vendorItemId}
      </a>
    ) : (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        {params.row.productDetails[0].vendorItemId}
      </Box>
    );
  },
  type: 'customText',
  valueGetter: (params) => params.row.attachedItemIds,
};

const baseUom = {
  field: 'baseUom',
  headerName: 'Base UOM',
  flex: 0.5,
  minWidth: 80,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
  renderCell: (params) =>
    `${params.row.productDetails[0].baseUOMAmount ? params.row.productDetails[0].baseUOMAmount : ''}${
      params.row.productDetails[0].baseUOMType ? '/' : ''
    }${params.row.productDetails[0].baseUOMType ? params.row.productDetails[0].baseUOMType : ''}`,
  valueGetter: (params) => params.row.productDetails[0].baseUOMAmount,
};

const previouslyOrderedItems = {
  field: 'previouslyOrderedItems',
  headerName: 'History',
  minWidth: 90,
  flex: 0.8,
  renderCell: (params) => (
    <Tooltip
      title={
        params.row.productDetails[0].previouslyOrderedItems?.length > 0
          ? params.row.productDetails[0].previouslyOrderedItems?.map((item) => (
              <div key={item.orderNumber}>
                Order# {item.orderNumber}, Qty {item.ordered}, {fMonthDayYear(item.orderedOn)}
                <br />
              </div>
            ))
          : ''
      }
    >
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        {params.row.productDetails[0].previouslyOrderedItems?.map((item) => item.ordered).join(', ')}
      </Box>
    </Tooltip>
  ),
  valueGetter: (params) => params.row.productDetails[0].previouslyOrderedItems?.map((item) => item.ordered).join(', '),
};

const SHOP_CATALOG = [
  picture,
  description,
  vendorItemId,
  subcategory,
  price,
  id,
  manufacturer,
  manufacturerId,
  changeUom,
  baseUom,
  previouslyOrderedItems,
];
const CATALOG = [
  picture,
  description,
  vendorItemId,
  category,
  subcategory,
  price,
  id,
  changeUom,
  baseUom,
  previouslyOrderedItems,
];

export { SHOP_CATALOG, CATALOG };
