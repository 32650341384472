import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  TextField,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { checkCompanyName, postCompanyForm } from '../../../../api/companies';
import FormTextWithValue from '../../../../reusable-components/form/FormTextWithValue';
import FormDropdown from '../../../../reusable-components/form/FormDropdown';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { FIFTY_STATES } from '../../../../utils/dropdowns';
import { AwaitButton } from '../../../../reusable-components/await-button';

AddItemDialog.propTypes = {
  getGroups: PropTypes.func,
};

export default function AddItemDialog({ getGroups }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    name: '',
    shortName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });
  const { enqueueSnackbar } = useSnackbar();

  const checkDuplicateItem = async () => {
    const response = await checkCompanyName(value.name, -1);
    if (response.status === 200) {
      if (response.data === true) {
        enqueueSnackbar('Group  already exists', { variant: 'error' });
        return true;
      }
    }
    return false;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const isDuplicate = await checkDuplicateItem();
    if (isDuplicate) return;

    const response = await postCompanyForm({ value });
    if (response.status === 200) {
      getGroups();
      enqueueSnackbar('Group updated successfully', { variant: 'success' });
      handleClose();
    } else {
      enqueueSnackbar('Error updating group', { variant: 'error' });
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        sx={{ mr: 1 }}
        size="small"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        Add Group
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Add Group
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 0.5 }}
              spacing={2}
            >
              <FormTextWithValue
                value={value}
                title="Group Name*"
                flex={6}
                minWidth="350px"
                name="name"
                setValue={setValue}
              />
              <FormTextWithValue
                value={value}
                title="Short Name"
                flex={6}
                minWidth="350px"
                name="shortName"
                setValue={setValue}
              />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 0.5 }}
              spacing={2}
            >
              <FormTextWithValue
                value={value}
                title="Address"
                flex={6}
                minWidth="350px"
                name="address"
                setValue={setValue}
              />
              <FormTextWithValue value={value} title="City" flex={6} minWidth="350px" name="city" setValue={setValue} />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 0.5 }}
              spacing={2}
            >
              <Grid item xs={6}>
                <FormDropdown
                  title="State"
                  fullWidth
                  value={
                    FIFTY_STATES.find((state) => state.value === value.state) || {
                      label: value.state,
                      value: value.state,
                    }
                  }
                  onChange={(event, newValue) => {
                    setValue({ ...value, state: newValue.value });
                  }}
                  options={FIFTY_STATES}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  flex={6}
                />
              </Grid>
              <FormTextWithValue value={value} title="Zip" flex={6} minWidth="350px" name="zip" setValue={setValue} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <AwaitButton
            disabled={value.name === ''}
            variant="contained"
            color="primary"
            sx={{ width: '200px', boxShadow: 3, fontWeight: 'bold' }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
