import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { Typography, Stack, Grid, Box } from '@mui/material';
import { useSelector } from '../../../../redux/store';
import Lightbox from '../../../../reusable-components/lightbox';
import { fMonthDayTimeYear } from '../../../../utils/formatTime';
import TicketMessageItem from './TicketMessageItem';
import { SpinningLogo } from '../../../../reusable-components/logo';
import Scrollbar from '../../../../reusable-components/scrollbar';

TicketMessageList.propTypes = {
  hideAvatar: PropTypes.bool,
  ticketId: PropTypes.number, //this comes from embeded tickets not the main ticket page
};

export default function TicketMessageList({ hideAvatar = false, ticketId }) {
  const location = useLocation();
  const { id = '' } = useParams();
  const subticketId = new URLSearchParams(location.search).get('subticket');
  const [selectedImage, setSelectedImage] = useState(-1);
  const scrollRef = useRef(null);
  const IMAGES = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg'];
  const { ticketIsLoading } = useSelector((state) => state.tickets);
  const { ticketMessages, users } = useSelector(
    (state) => state.tickets.data.ticketById[ticketId || subticketId || id] || {}
  );

  useEffect(() => {
    const scrollScrollbarToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight + 200;
      }
    };

    scrollScrollbarToBottom();
  }, [ticketMessages]);

  //two options for imagesLightbox url one is from message.note contains img src and the other is from message.file
  const imagesLightbox = ticketMessages
    ?.filter(
      (message) =>
        message.note.includes('<img src="') || (message.file && IMAGES.includes(message.note.split('.').pop()))
    )
    .flatMap((message, index) => {
      if (message.file) {
        return [{ src: `/api/TicketFile?noteId=${message.id}` }];
        // eslint-disable-next-line no-else-return
      } else if (message.note.includes('img src="')) {
        const imageSources = message.note.matchAll(/src="([^"]*)"/g);
        return Array.from(imageSources, (match) => ({ src: match[1] }));
      }
      return [];
    });

  const getImageID = (imageUrl) => {
    const localIdRegex = /\/TicketFile\?noteId=(\d+)/; // Regular expression to match image ID in URL
    const idRegex = /\/(\w+)\.\w+$/; // Regular expression to match image ID in URL
    if (localIdRegex.test(imageUrl)) {
      return imageUrl.match(localIdRegex)[1];
    }
    if (idRegex.test(imageUrl)) {
      return imageUrl.match(idRegex)[1];
    }
    return imageUrl;
  };

  const handleOpenLightbox = (imageUrl) => {
    const imageIndex = imagesLightbox?.findIndex((image) => getImageID(image.src) === getImageID(imageUrl));
    setSelectedImage(imageIndex);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  return (
    <Box
      sx={{
        minHeight: '42vh',
        maxHeight: '44vh',
        // pr: 1,
      }}
    >
      {ticketIsLoading && (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
          <Box
            sx={{
              zIndex: 999999999999999,
              height: '100%',
              minHeight: '70vh',
            }}
          >
            <SpinningLogo />
          </Box>
        </Grid>
      )}
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
        {ticketMessages?.map((message, index) => {
          if (message.systemMessage) {
            // Render a system message with a timestamp.
            return (
              <Stack key={index} direction="row" justifyContent="center" sx={{ my: 3 }}>
                <Typography noWrap variant="caption" sx={{ color: 'secondary.main' }}>
                  {`${message.note},`} &nbsp;
                  {fMonthDayTimeYear(message.sentOn)}
                </Typography>
              </Stack>
            );
          }
          // Check if the current message has an attachment.
          const hasAttachment = message.file;
          const previousAttachmentId = ticketMessages[index - 1]?.attachFileId;
          const nextAttachmentId = ticketMessages[index + 1]?.attachFileId;

          if (hasAttachment) {
            if (message.attachFileId !== previousAttachmentId) {
              return (
                <TicketMessageItem
                  hideAvatar={hideAvatar}
                  key={index}
                  message={message}
                  fileArray={ticketMessages.filter((msg) => msg.attachFileId === message.attachFileId)}
                  previousMessage={ticketMessages[index - 1]}
                  // nextMessage={ticketMessages[index + 1] || null} this should be the next message with thats not getting skipped from message.attachFileId !== previousAttachmentId
                  // nextMessage={ticketMessages.find(
                  //   (msg) => msg.attachFileId === message.attachFileId && msg.id !== message.id
                  // )}
                  nextMessage
                  users={users}
                  onOpenLightbox={(imgUrl) => handleOpenLightbox(imgUrl)}
                />
              );
            }
            if (message.attachFileId === 0) {
              return (
                <TicketMessageItem
                  hideAvatar={hideAvatar}
                  key={index}
                  message={message}
                  fileArray={[message]}
                  previousMessage={ticketMessages[index - 1]}
                  nextMessage={ticketMessages[index + 1] || null}
                  users={users}
                  onOpenLightbox={(imgUrl) => handleOpenLightbox(imgUrl)}
                />
              );
            }
            return null;
          }

          return (
            <TicketMessageItem
              hideAvatar={hideAvatar}
              key={index}
              message={message}
              previousMessage={ticketMessages[index - 1]}
              nextMessage={ticketMessages[index + 1] || null}
              users={users}
              onOpenLightbox={(imgUrl) => handleOpenLightbox(imgUrl)}
            />
          );
        })}
      </Scrollbar>

      <Lightbox index={selectedImage} slides={imagesLightbox} open={selectedImage >= 0} close={handleCloseLightbox} />
    </Box>
  );
}
