export default function convertTicketFieldValuesToHTML(ticketTypeFieldGroups) {
  const getDisplayHTMLValue = (field, group) => {
    const value = field.value || "";
    const htmlValue = field.htmlValue || "";

    switch (field.type) {
      case "Order Search - With Item Table":
        return htmlValue;
      case "Order Search":
        return htmlValue;
      case "Checkbox":
        return value === "Yes" ? "Yes" : "No";
      case "Link": {
        const name = group.fields.find(field => field.name?.toLowerCase().includes("name"))?.value;
        return `<a href="${value}">${(name ?? value)}</a>`;
      }
      case "Text": {
        if (!field.name?.toLowerCase().includes("name")) return value;
        const link = group.fields.find(field => field.type === "Link")?.value;
        return link ? `<a href="${link}">${value}</a>` : value;
      }
      case "PS Item Number": {
        const name = group.fields.find(field => field.name?.toLowerCase()?.includes("name"))?.value;
        return name || value ? `<a href="/dashboard/admin/catalog/${value}">${(name ?? `PS# ${value}`)}</a>` : "";
      }
      default:
        return value;
    }
  };

  return ticketTypeFieldGroups.filter(group => !group.repeatedGroupId || (group.fields.some(field => field.value) && group.fields.filter(field => field.required)
    .every(field => field.value))).map(group => {
      if (!group.miscellaneousFieldsGroup) {
        let html = group.displayHTMLValue;
        group.fields.forEach(field => { html = html.replace(`{${field.id} - ${field.name}}`, getDisplayHTMLValue(field, group)) });
        return html;
      }
      return group.fields.map(field => field.displayHTMLValue?.replace(`{${field.id} - ${field.name}}`, getDisplayHTMLValue(field, group))).join("");
    }).join("");
}