import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Paper, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import Integrations from './Integrations';
import Notes from './Notes';

VendorIntegrationsTab.propTypes = {
  selectedRow: PropTypes.object,
};
export default function VendorIntegrationsTab({ selectedRow }) {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderTabContent = () => {
    switch (tab) {
      case 0:
        return <Notes selectedRow={selectedRow} />;
      case 1:
        return <Integrations selectedRow={selectedRow} />;

      default:
        return <Notes selectedRow={selectedRow} />;
    }
  };

  return (
    <Paper sx={{ px: 2, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Box sx={{ ml: 6 }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="transparent"
            sx={{
              borderRadius: '4px 4px 0px 0px',
            }}
          >
            <CustomTab
              label={
                <>
                  <Typography
                    variant="subtitle1"
                    color={selectedRow ? `info.${tab === 2 ? 'main' : 'light'}` : 'success.contrastText'}
                  >
                    Integration Notes
                  </Typography>
                </>
              }
            />
            <CustomTab
              label={
                <>
                  <Typography
                    variant="subtitle1"
                    color={selectedRow ? `info.${tab === 2 ? 'main' : 'light'}` : 'success.contrastText'}
                  >
                    All Integrations
                  </Typography>
                </>
              }
            />
          </Tabs>
        </Box>
      </Grid>

      {renderTabContent()}
    </Paper>
  );
}

const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '200px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
