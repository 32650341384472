import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Autocomplete,
  IconButton,
  Avatar,
  Badge,
  Typography,
} from '@mui/material';
import { useScopeCheck } from '../../../reusable-components/scopes';
import { useSelector, useDispatch } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { getOrderingFacilities, getOrderingCategories, resetTempCart } from '../../../redux/slices/orderSheet';
import { resetShoppingCart } from '../../../redux/slices/cart';
import NewRental from './NewRental';

export default function NewTicket() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSuperAdmin = useScopeCheck(['Super-Admin']);

  const {
    data: { facilities, selectedFacilityId, categories },
  } = useSelector((state) => state.orderSheet);

  const [openNewOrderDialog, setOpenNewOrderDialog] = useState(false);
  const [value, setValue] = useState();
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
  const [openRentalDialog, setOpenRentalDialog] = useState(false);
  const handleClickOpen = () => {
    setOpenNewOrderDialog(true);
    if (!isSuperAdmin) setOpenPurchaseDialog(true);
  };

  const handleClose = () => {
    setOpenNewOrderDialog(false);
  };
  const handlePurchaseClick = () => {
    setOpenPurchaseDialog(true);
  };

  const handleRentalClick = () => {
    setOpenRentalDialog(true);
  };

  const handleClosePurchaseDialog = () => {
    setOpenPurchaseDialog(false);
    setOpenNewOrderDialog(false);
  };

  const handleCategoryClick = (categoryId, name) => {
    dispatch(resetShoppingCart());
    handleClose();
    navigate(
      `/dashboard/orders/new-order/${value?.value}?categoryId=${categoryId}&category=${encodeURIComponent(name)}`
    );
  };

  useEffect(() => {
    if (openNewOrderDialog && !facilities?.length) dispatch(getOrderingFacilities());
  }, [dispatch, facilities, openNewOrderDialog]);

  useEffect(() => {
    setValue(facilities.find((option) => option.value === selectedFacilityId) || null);
  }, [facilities, selectedFacilityId]);

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
        color="secondary"
        sx={{
          minWidth: 120,
        }}
      >
        New order
      </Button>

      <Dialog open={openNewOrderDialog} onClose={handleClose} fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
            color: '#8cc53f',
            p: 3,
          }}
        >
          New Order
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
            <Grid item>
              <Box
                sx={{
                  boxShadow: 3,
                }}
              >
                <Button fullWidth variant="outlined" onClick={handlePurchaseClick}>
                  <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item style={{ paddingTop: 15 }}>
                      <Avatar
                        sx={{
                          height: 60,
                          minWidth: 60,
                          backgroundColor: '#fff',
                          border: '1px solid #4ca5e7',
                        }}
                      >
                        <Iconify icon="clarity:store-line" heigh={40} width={40} style={{ color: '#4ca5e7' }} />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" color="#4ca5e7">
                        Purchasing
                      </Typography>
                    </Grid>
                    <Grid item sx={{ pb: 2 }}>
                      <Typography variant="subtitle2" color="#4ca5e7">
                        Click here to begin new purchase
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Box>
            </Grid>
            <Dialog open={openPurchaseDialog} onClose={handleClosePurchaseDialog} maxWidth="sm" fullWidth>
              <DialogTitle
                sx={{
                  textAlign: 'center',
                  color: 'primary',
                }}
              >
                New Purchase
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ fontWeight: 'bold' }}>Select a facility</DialogContentText>{' '}
                <Autocomplete
                  sx={{
                    width: '100%',
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': 'none',
                  }}
                  type="new-password"
                  options={facilities}
                  autoHighlight
                  getOptionLabel={(option) => option?.label}
                  getOptionSelected={(option, value) => option.label === value.label}
                  value={value}
                  onChange={(event, newValue) => {
                    if (!newValue) return;
                    dispatch(resetTempCart());
                    setValue(newValue);
                    dispatch(getOrderingCategories(newValue?.value));
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  )}
                />
                <DialogContentText sx={{ fontWeight: 'bold', mt: 3 }}>Select a category</DialogContentText>
                <Grid container spacing={1} sx={{ mb: 2 }}>
                  {categories?.map((category) => (
                    <Grid
                      item
                      xs={3}
                      key={category.id}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      onClick={() => handleCategoryClick(category.id, category.name)}
                      sx={{ cursor: 'pointer', height: '100%' }}
                    >
                      <Grid item>
                        <IconButton color="primary">
                          <Iconify icon={category.icon} width={40} height={40} />
                        </IconButton>
                      </Grid>
                      <Grid item sx={{ textAlign: 'center' }}>
                        {category.name}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
            </Dialog>

            <Badge
              badgeContent="Beta"
              color="info"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiBadge-badge': {
                  right: 22,
                  top: 13,
                  border: '2px solid #fff',
                  padding: '0 4px',
                },
              }}
            >
              <Grid item>
                <Box
                  sx={{
                    boxShadow: 3,
                  }}
                >
                  <Button fullWidth variant="outlined" onClick={handleRentalClick} color="primary">
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                      <Grid item style={{ paddingTop: 15 }}>
                        <Avatar
                          sx={{
                            height: 60,
                            minWidth: 60,
                            backgroundColor: '#fff',
                            border: '1px solid #4ca5e7',
                          }}
                        >
                          <Iconify icon="carbon:hospital-bed" height={40} width={40} style={{ color: '#4ca5e7' }} />
                        </Avatar>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" color="#4ca5e7">
                          Rentals
                        </Typography>
                      </Grid>
                      <Grid item sx={{ pb: 2 }}>
                        <Typography variant="subtitle2" color="#4ca5e7">
                          Click here to begin new rental
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Box>
              </Grid>
            </Badge>

            {openRentalDialog && (
              <NewRental
                openRentalDialog={openRentalDialog}
                setOpenRentalDialog={setOpenRentalDialog}
                openNewOrderDialog={openNewOrderDialog}
                setOpenNewOrderDialog={setOpenNewOrderDialog}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
