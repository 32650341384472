import { Alert, Badge, Box, Card, Grid, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getPendingOrders } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ChevronBox } from '../../../../reusable-components/chevron-box';

export default function OrderReviewers() {
  const { id = '' } = useParams();
  const { orderById } = useSelector((state) => state.orders || {});
  const {
    data: { pendingOrders },
  } = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const orderReviews = useMemo(
    () =>
      pendingOrders
        ?.find((o) => o.id === orderById[id]?.id)
        ?.reviews?.slice() // Create a shallow copy of the reviews array
        .sort((a, b) => {
          if (a.reviewed === b.reviewed) {
            return 0;
          }
          return a.reviewed ? -1 : 1;
        }),
    [pendingOrders, orderById, id]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!pendingOrders?.length > 0 || !orderReviews?.length > 0) {
        await dispatch(getPendingOrders());
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {orderReviews?.length > 0 && (
        <Box sx={{ minWidth: '300px', width: '100%', mb: 2 }}>
          <Card sx={{ p: 1, width: '100%' }}>
            {orderReviews?.some((r) => r.required === true && r.reviewed === false) && (
              <Alert sx={{ mb: 1 }} severity="warning">
                Order is waiting on required reviewers
              </Alert>
            )}
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
              <Grid item>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="info.main">
                  Approvers:
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
                  {orderReviews?.length > 0 &&
                    orderReviews?.map((reviewer, reviewerIndex) => {
                      const { reviewerName, reviewerEmail, minimum, reviewed } = reviewer;
                      const firstName = reviewerName.split(' ')[0];
                      const colors = ['#208ee0', '#f19e3b', '#b128bc', '#8dc63f'];
                      const badgeContent =
                        reviewer?.minimum > 0 ? (
                          <Tooltip arrow placement="top" title={`review orders over $${minimum}`}>
                            <Box>{`$${minimum}`}</Box>
                          </Tooltip>
                        ) : null;
                      return (
                        <>
                          <Badge badgeContent={badgeContent} max={100000} color="info" sx={{ zIndex: orderReviews?.length - reviewerIndex }}>
                            <ChevronBox
                              color={colors[reviewerIndex % colors.length]}
                              reviewed={reviewed}
                              reviewerIndex={reviewerIndex}
                              length={orderReviews.length}
                              children={<Tooltip title={reviewer?.required ? `reviewer required - ${reviewer.reviewerEmail}` : `${reviewerName} - ${reviewerEmail}`}>
                                <Typography>
                                  {firstName}
                                  {reviewer?.required ? ' *' : ''}
                                </Typography>
                              </Tooltip>
                              }
                            />
                          </Badge>
                        </>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      )}
    </>
  );
}
