import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { SHIPPING_LIST } from '../../../../reusable-components/datagrid/vendorColumns';
import { getVendorDatesData } from '../../../../api/vendors';
import { useSelector } from '../../../../redux/store';

export default function ListVewGrid() {
  const {
    data: { facilities, vendors },
  } = useSelector((state) => state.vendors);
  const { openModal } = useSelector((state) => state.vendors);

  const typeIdLookUp = (typeId, types) => {
    const type = types.find((t) => t.value === typeId);
    return type ? type.label : '';
  };
  function getDaysArray(start, end) {
    const arr = [];
    const dt = new Date(start);
    end = new Date(end);

    while (dt <= end) {
      arr.push(dt.getDay());
      dt.setDate(dt.getDate() + 1);
    }

    // Remove duplicates (if any) and return
    return [...new Set(arr)];
  }
  const facilityIdToName = (facilityId) => {
    const facility = facilities.find((v) => v.value === facilityId);
    return facility ? facility.label : '';
  };
  const vendorIdToName = (vendorId) => {
    const vendor = vendors.find((v) => v.id === vendorId);
    return vendor ? vendor.name : '';
  };
  function updateWeeklyEvents(events, types) {
    return events.map((event) => {
      const title = typeIdLookUp(event.typeId, types);

      if (event.weekly) {
        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);

        // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
        const daysOfWeek = getDaysArray(startDate, endDate);

        // Format start and end times (e.g., '10:00')
        const startTime = event.startDate.split('T')[1].substring(0, 5);
        const endTime = event.endDate.split('T')[1].substring(0, 5);

        // Update the event
        return {
          ...event,
          daysOfWeek,
          startTime,
          endTime,
          title,
          color: '#1890FF',
          facility: facilityIdToName(event.facilityId),
          vendor: vendorIdToName(event.vendorId),
        };
      }
      return {
        ...event,
        title,
        color: '#FFC107',
        start: new Date(event.startDate),
        end: new Date(event.endDate),
      };
    });
  }
  const [events, setEvents] = useState([]);
  useEffect(() => {
    if (openModal) return;

    const fetchData = async () => {
      try {
        const response = await getVendorDatesData(0, 0);
        const { dates, types } = response.data;
        const updatedEvents = updateWeeklyEvents(dates, types);
        setEvents(updatedEvents);
      } catch (error) {
        console.error('Error fetching vendor data:', error);
      }
    };
    fetchData();
  }, [openModal]);

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="vendors=shipping-list"
          boxSX={{ height: 'calc(100vh - 200px)' }}
          data={events}
          gridColumns={[...SHIPPING_LIST]}
          sort={[{ field: 'facility', sort: 'asc' }]}
        />
      </Grid>
    </>
  );
}
