import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Link, IconButton, TextField, Box } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { editTicketData } from '../../../../redux/slices/tickets';
import { useScopeCheck } from '../../../../reusable-components/scopes';

EditSubject.propTypes = {
  index: PropTypes.number,
  parentTicketId: PropTypes.string,
  subticket: PropTypes.object,
  checkTicketId: PropTypes.func,
};
export default function EditSubject({ index, subticket, parentTicketId, checkTicketId }) {
  const subticketId = new URLSearchParams(window.location.search).get('subticket');
  const { id, extraInfo } = subticket;

  const hasEditAccess = useScopeCheck(['Tickets-Edit'], true);
  const { ticketNumber } = useSelector((state) => state.tickets.data.ticketById[subticketId || id]) || {};

  const dispatch = useDispatch();
  const [editSubject, setEditSubject] = useState(false);
  const [subject, setSubject] = useState(extraInfo);

  const editTicket = () => {
    dispatch(editTicketData({ Id: ticketNumber || id, value: subject || extraInfo, updateType: 'ExtraInfo' }));
    setEditSubject(false);
  };

  return (
    <>
      {editSubject ? (
        <>
          <TextField
            id="standard-search"
            type="search"
            variant="standard"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            onBlur={() => {
              setEditSubject(false);
              editTicket();
            }}
            sx={{
              width: 'auto',
              height: '30px',
              mb: 1,
              '& .MuiInputBase-input': {
                height: '30px',
                fontSize: '12px',
                fontWeight: '600',
                margin: 0,
                marginLeft: 0.5,
                marginBottom: -1,
                fontFamily: `Public Sans,sans-serif`,
                color: '#637381',

                '& .MuiInputBase-input:focus': {
                  height: '30px',
                },
              },

              '& .MuiInputBase-root': {
                height: '30px',
                '& .MuiInputBase-input': {
                  height: '30px',
                  '& .MuiInputBase-input:focus': {
                    height: '30px',
                  },
                },
              },
            }}
          />
          <IconButton onClick={editTicket} color="primary">
            <Iconify icon="material-symbols:check" />
          </IconButton>
        </>
      ) : (
        <>
          <Link
            to={`/dashboard/tickets/${parentTicketId}?subticket=${id}`}
            component={RouterLink}
            sx={{
              padding: 1,
              backgroundColor: checkTicketId(id) ? 'info.main' : 'transparent',
              // boxShadow: checkTicketId(id) ? '0px 3px 5px 2px  #8BC5EF' : 'none',
              borderRadius: '3px',
              border: `1px solid #8BC5EF`,
              borderBottom: 'none',
              color: checkTicketId(id) ? '#fff' : 'text.secondary',
              ':hover': { backgroundColor: 'info.main', color: '#fff !important' },
            }}
            key={index}
          >
            <Typography variant="subtitle2" key={index}>
              {extraInfo}
              {id === parseInt(subticketId, 10) && hasEditAccess && (
                <IconButton color="white" onClick={() => setEditSubject(true)} sx={{ padding: 0, pl: 0.5 }}>
                  <Iconify icon="material-symbols:edit" width={15} height={5} />
                </IconButton>
              )}
            </Typography>
          </Link>
          {/* {id === parseInt(subticketId, 10) && hasEditAccess ? (
            <IconButton onClick={() => setEditSubject(true)} sx={{ ml: -0.5 }}>
              <Iconify icon="material-symbols:edit" width={15} height={5} />
            </IconButton>
          ) : (
            <Box sx={{ width: '24px' }} />
          )} */}
        </>
      )}
    </>
  );
}
