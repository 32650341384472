import PropTypes from 'prop-types';
import { Box, Tooltip, Stack, IconButton } from '@mui/material';
//
import { fileData, fileFormat, fileThumb } from './utils';
import DownloadThumbnailButton from './DownloadThumbnailButton';
import ViewButton from './ViewButton';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

FileThumbnail.propTypes = {
  sx: PropTypes.object,
  imgSx: PropTypes.object,
  tooltip: PropTypes.bool,
  onImageView: PropTypes.func,
  onDownload: PropTypes.func,
  onRemove: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  preview: PropTypes.string,
  displayImage: PropTypes.bool,
};

export default function FileThumbnail({
  file,
  tooltip,
  onImageView,
  onDownload,
  onRemove,
  sx,
  imgSx,
  preview,
  displayImage,
}) {
  const { name = '', path = '' } = fileData(file);
  const format = fileFormat(path || name);
  const renderContent =
    (format === 'image' && onImageView) || displayImage ? (
      <Box>
        {onRemove && (
          <IconButton
            size="small"
            onClick={onRemove}
            sx={{
              ml: '40px',
              mb: '24px',
              position: 'absolute',
              '&:hover': { bgcolor: 'error.lighter' },
            }}
          >
            <Iconify icon="mdi:close" />
          </IconButton>
        )}
        <Box
          component="img"
          src={preview}
          sx={{
            width: 1,
            height: 1,
            flexShrink: 0,
            objectFit: 'cover',
            ...imgSx,
          }}
        />
      </Box>
    ) : (
      <Box sx={{ ...sx, }}>
        <Iconify
          icon={fileThumb(format)}
          sx={{ ...sx, }} />

      </Box>

    );

  if (tooltip) {
    return (
      <Tooltip title={name}>
        <Stack
          flexShrink={0}
          component="span"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 'fit-content',
            height: 'inherit',
          }}
        >
          {renderContent}

          {onDownload && !onImageView && <DownloadThumbnailButton onDownload={onDownload} />}
          {onImageView && !onDownload && <ViewButton onImageView={onImageView} />}
          {onDownload && onImageView && format !== 'image' ? (
            <DownloadThumbnailButton onDownload={onDownload} />
          ) : onImageView && format === 'image' ? (
            <ViewButton onImageView={onImageView} />
          ) : null}
          {onRemove && (
            <IconButton
              size="small"
              onClick={onRemove}
              sx={{
                ml: '40px',
                mb: '24px',
                position: 'absolute',
                '&:hover': { bgcolor: 'error.lighter' },
              }}
            >
              <Iconify icon="mdi:close" />
            </IconButton>
          )}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      {renderContent}
      {onDownload && <DownloadThumbnailButton onDownload={onDownload} onRemove={onRemove} />}
    </>
  );
}
