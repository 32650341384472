import PropTypes from 'prop-types';
import { Typography, Stack, Box } from '@mui/material';
import { useDispatch } from '../../../../redux/store';
import Markdown from '../../../../reusable-components/markdown';
// import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import { stringToColor } from '../../../../utils/cssStyles';
import { fMonthDayTimeYear, convertToUnix } from '../../../../utils/formatTime';
import { HOST_API_URL } from '../../../../config-global';
import { useTabs } from '../../../../context/TabContext';
import { postDeleteTicketMessage } from '../../../../api/tickets';
import { getTicketMessagesFromOrder } from '../../../../redux/slices/tickets';

// ----------------------------------------------------------------------

OrderNotesItem.propTypes = {
  message: PropTypes.object,
  previousMessage: PropTypes.object,
  onOpenLightbox: PropTypes.func,
  index: PropTypes.number,
};

export default function OrderNotesItem({ message, previousMessage, onOpenLightbox, index }) {
  const dispatch = useDispatch();

  const { addNewTabAndNavigate } = useTabs();

  const { file, fullName, id, note, sentOn, sentBy } = message;

  // const isImage = contentType === 'image';

  const name = fullName || sentBy;
  const previousMsgName = previousMessage?.fullName || previousMessage?.sentBy;

  const deleteTicketMessage = async (messageId) => {
    const res = await postDeleteTicketMessage(messageId);
    if (res.data) {
      if (id) {
        dispatch(getTicketMessagesFromOrder(id));
      }
    }
  };

  const replaceLinks = (text) => {
    // Split the text into parts based on <img> tags
    const parts = text.split(/(<img[^>]*>)/gi);

    // Process each part separately
    return parts
      .map((part) => {
        // If the part is an <img> tag, return it as is
        if (part.toLowerCase().startsWith('<img')) {
          return part;
        }

        // Regular expression to match HTTP and HTTPS URLs not already inside <a> tags or part of an <img> tag
        const urlRegex = /(\bhttps?:\/\/[^\s<>"']+[^\s<>"']*)(?![^<]*<\/a>)/gi;

        // Function to convert URL into an <a> tag
        const linkify = (url) => {
          // Check if the URL is part of an existing <a> tag with additional attributes
          const existingATagPattern = new RegExp(`<a [^>]*href=["']${url}["'][^>]*>`, 'gi');
          if (existingATagPattern.test(part)) {
            return url; // If so, return the URL as is
          }

          // Convert URL into an <a> tag if it's not part of an existing <a> tag
          return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        };
        // Apply the URL replacement for non-<img> parts
        return part.replace(urlRegex, linkify);
      })
      .join('');
  };

  const removeDetails =
    previousMessage &&
    previousMsgName === name &&
    convertToUnix(sentOn) - convertToUnix(previousMessage?.sentOn) < 120000;

  const getTicketFile = async () => {
    window.open(`${HOST_API_URL}Api/TicketFile?noteId=${id}`, '_blank');
  };

  const sameSite = (url) => url.includes('localhost:44475/') || url.includes('app.platformsolutions.com');

  return (
    <>
      <Stack direction="row" justifyContent="flex-start" sx={{ mt: removeDetails ? 0.5 : 2 }} key={index}>
        <Stack alignItems={'flex-start'}>
          {removeDetails ? null : (
            <>
              <Typography noWrap variant="caption">
                {name || sentBy},
              </Typography>
              <Typography noWrap variant="caption" sx={{ color: 'text.disabled' }}>
                {fMonthDayTimeYear(message.sentOn)}
              </Typography>
            </>
          )}
          {/* <Typography
          component="span"
          sx={{
            marginLeft: 1,
            color: (theme) => theme.palette.error.main,
            '&:hover': { cursor: 'pointer' },
          }}
          onClick={() => deleteTicketMessage(message.id)}
        >
          <Iconify icon="material-symbols:delete" width={16} height={16} />
        </Typography> */}
          {file ? (
            <Stack direction="row" alignItems="flex-end">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 46,
                  height: 46,
                  flexShrink: 0,
                  borderRadius: 1,
                  overflow: 'hidden',
                  position: 'relative',
                  backgroundColor: 'background.neutral',
                }}
              >
                <FileThumbnail
                  file={note}
                  tooltip
                  onDownload={getTicketFile}
                  sx={{ width: 46, height: 46 }}
                  onImageView={() => onOpenLightbox(`/api/TicketFile?noteId=${id}`)}
                  preview={`/api/TicketFile?noteId=${id}`}
                />
              </Stack>
            </Stack>
          ) : (
            <Box>
              <Stack
                sx={{
                  p: 1,
                  // pr: 6,
                  minWidth: note.includes('<img') ? '500px' : 'fit-content',
                  maxWidth: '40vw',
                  borderRadius: '9px',
                  wordWrap: 'break-word',
                  typography: 'body2',
                  border: () => `solid 1px ${stringToColor(name)}`,
                  display: 'inline-block',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                <Box
                  onClick={(event) => {
                    if (event.target.tagName === 'A') {
                      event.preventDefault();
                      if (sameSite(event.target.href)) {
                        addNewTabAndNavigate(event.target.href);
                      } else {
                        window.open(event.target.href, '_blank');
                      }
                    }
                    if (event.target.tagName === 'IMG') {
                      onOpenLightbox(event.target.src);
                    }
                  }}
                  sx={{
                    // pr: 2,
                    // mr: 2,
                    '& img': {
                      cursor: 'pointer',
                      '&:hover': {
                        filter: 'brightness(0.9)',
                        padding: 0.3,
                        border: () => `solid 1px ${stringToColor(name)}`,
                      },
                    },
                  }}
                >
                  <Markdown children={replaceLinks(note)} />
                </Box>
              </Stack>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
}
