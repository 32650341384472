import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

///Api/Punchout/Punchout categoryId vendorId facilityId
export async function getPunchoutData(categoryId, vendorId, facilityId) {
  const response = await axios.get(
    `/Punchout/Punchout?categoryId=${categoryId}&vendorId=${vendorId}&facilityId=${facilityId}`
  );
  return response;
}
