import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Collapse, Typography, Grid, Link, Tooltip, Box, Stack } from '@mui/material';
import { useSelector, useDispatch } from '../../../../redux/store';
import Scrollbar from '../../../../reusable-components/scrollbar';
import Iconify from '../../../../reusable-components/iconify';
import { fDateTime } from '../../../../utils/formatTime';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { deleteInvoiceLog } from '../../../../redux/slices/invoicing';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { LogContainer } from './reusable';

LogTemplate.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.element,
  logEntry: PropTypes.object,
};
function LogTemplate({ icon, message, logEntry }) {
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 55);
    }
  }, [message]);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(logEntry?.user?.email)
      .then(() => {
        enqueueSnackbar('Email copied to clipboard!', {
          variant: 'info',
        });
      })
      .catch((err) => {
        console.error('Failed to copy email: ', err);
      });
  };
  let addedBy = logEntry?.user?.fullName;
  const hasColon = addedBy?.indexOf(':') > -1;
  if (hasColon) {
    addedBy = addedBy?.split(':', 1);
  }
  return (
    <>
      <LogContainer
        title={fDateTime(logEntry?.dateAdded)}
        isOverflowing={isOverflowing}
        icon={icon}
        pointer={isOverflowing}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <Box sx={{ flex: 1, whiteSpace: 'normal', wordBreak: 'break-word' }}>
          <Collapse collapsedSize={55} in={expanded}>
            <Box ref={contentRef} sx={{ fontSize: '14px', display: 'flex', alignItems: 'center' }} minHeight={55}>
              {message}
            </Box>
          </Collapse>
        </Box>
        {isOverflowing && (
          <Box>
            <Iconify
              icon={!expanded ? 'ic:outline-expand-more' : 'ic:outline-expand-less'}
              color="info"
              height="18px"
              width="18px"
            />
          </Box>
        )}
        {user.id === logEntry.user.id && (
          <Box>
            <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
              <ConfirmDialog
                icon="delete-outline"
                title="Delete"
                color="error"
                content={`Are you sure you want to delete this comment?`}
                actionButton="Delete"
                action={async (e) => {
                  const response = await dispatch(deleteInvoiceLog(logEntry.id));
                  if (response === 'success') {
                    enqueueSnackbar('comment has been deleted.', { variant: 'success' });
                  } else enqueueSnackbar('Failed to delete comment. ', { variant: 'error' });
                }}
              />
            </ScopeGuard>
          </Box>
        )}
      </LogContainer>
      <Grid container justifyContent={'end'} p={0.5}>
        {logEntry?.user?.id === '0' ? (
          <Typography
            color="text.secondary"
            sx={{
              fontSize: '12px',
              display: 'inline',
            }}
          >
            {addedBy}&nbsp;
          </Typography>
        ) : (
          <Tooltip
            onClick={handleCopyToClipboard}
            title={
              <Stack direction={'row'}>
                <Icon icon="ph:copy-bold" width="1rem" height="1rem" /> &nbsp;{logEntry?.user?.email}
              </Stack>
            }
            arrow
          >
            <Typography
              color="text.secondary"
              sx={{
                fontSize: '12px',
                display: 'inline',
              }}
            >
              {addedBy}&nbsp;
            </Typography>
          </Tooltip>
        )}
      </Grid>
    </>
  );
}

//Invoice added by users name
InvoiceAdded.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceAdded({ logEntry }) {
  const htmlMessage = (
    <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
      Invoice added
    </Typography>
  );
  return <LogTemplate icon={'ic:baseline-sync'} message={htmlMessage} logEntry={logEntry} />;
}

//Invoice added by users name
InvoiceViewed.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceViewed({ logEntry }) {
  const htmlMessage = (
    <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
      Invoice Viewed
    </Typography>
  );
  return <LogTemplate icon={'hugeicons:view'} message={htmlMessage} logEntry={logEntry} />;
}

//<users name> has rejected this invoice INVOICE NOTE
InvoiceRejection.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceRejection({ logEntry }) {
  const htmlMessage = (
    <>
      <Tooltip title={logEntry.extraInfo}>
        <Typography color="text.secondary" sx={{ textDecoration: 'underline', fontSize: '14px' }}>
          Rejected this invoice
        </Typography>
      </Tooltip>
    </>
  );
  return <LogTemplate icon={'fluent:text-change-reject-20-filled'} message={htmlMessage} logEntry={logEntry} />;
}
//<user name> has approved the invoice
InvoiceApproval.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceApproval({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Approved the invoice</Typography>
    </>
  );
  return <LogTemplate icon={'mdi:approve'} message={htmlMessage} logEntry={logEntry} />;
}
//<user name> has submitted the invoice to __________
InvoiceSubmission.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceSubmission({ logEntry }) {
  const htmlMessage = (
    <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
      Submitted the invoice to {logEntry.extraInfo}
    </Typography>
  );
  return <LogTemplate icon={'tabler:send'} message={htmlMessage} logEntry={logEntry} />;
}
//<users name>has indicated  the invoice is not theirs to handle
InvoiceHandlingMismatch.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceHandlingMismatch({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Indicated the invoice is not theirs to handle</Typography>
    </>
  );
  return <LogTemplate icon={'ic:round-do-not-disturb-alt'} message={htmlMessage} logEntry={logEntry} />;
}
//user has indicated that this is a duplicate invoice
InvoiceDuplicateIndicator.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceDuplicateIndicator({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Indicated that this is a duplicate invoice</Typography>
    </>
  );
  return <LogTemplate icon={'heroicons:document-duplicate'} message={htmlMessage} logEntry={logEntry} />;
}

//<users name> added a ticket for this invoice
InvoiceTicketCreation.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceTicketCreation({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Added a&nbsp;</Typography>
      <Link
        onClick={() => {}}
        color="secondary.main"
        component="button"
        sx={{
          textDecoration: 'underline',
          display: 'inline',
        }}
      >
        ticket
      </Link>
      <Typography
        color="text.secondary"
        sx={{
          display: 'inline',
        }}
      >
        &nbsp;for this invoice
      </Typography>
    </>
  );
  return <LogTemplate icon={'majesticons:chat-line'} message={htmlMessage} logEntry={logEntry} />;
}
//<users name> has updated x on the invoice

InvoiceFieldUpdate.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceFieldUpdate({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
        {logEntry.extraInfo}
      </Typography>
    </>
  );
  return <LogTemplate icon={'clarity:update-line'} message={htmlMessage} logEntry={logEntry} />;
}

export const InvoiceLog = () => {
  const {
    data: { invoiceLogData },
  } = useSelector((state) => state.invoicing);

  return (
    <>
      <Box
        sx={{
          borderRadius: '4px',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          backgroundColor: '#fff',
          overflow: 'auto',
          border: 'solid 1px #c1c9d0',
        }}
      >
        <Box
          sx={{
            padding: '15px 25px',
          }}
        >
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: 'info.main' }}>Invoice log</Typography>
            </Grid>
            <Grid item>
              <Box padding={1}>
                <Iconify icon={'pajamas:log'} color="secondary" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Scrollbar sx={{ maxHeight: '400px', mx: 3 }}>
          <Box>
            {invoiceLogData
              ?.filter((x) => x.messageType !== 'Comment')
              ?.map((logEntry) => {
                switch (logEntry.messageType) {
                  case 'NewInvoice':
                    return <InvoiceAdded logEntry={logEntry} />;
                  case 'View':
                    return <InvoiceViewed logEntry={logEntry} />;
                  case 'Reject':
                    return <InvoiceRejection logEntry={logEntry} />;
                  case 'Approve':
                    return <InvoiceApproval logEntry={logEntry} />;
                  case 'Submit':
                    return <InvoiceSubmission logEntry={logEntry} />;
                  case 'HandleMismatch':
                    return <InvoiceHandlingMismatch logEntry={logEntry} />;
                  case 'Duplicate':
                    return <InvoiceDuplicateIndicator logEntry={logEntry} />;
                  case 'NewTicket':
                    return <InvoiceTicketCreation logEntry={logEntry} />;
                  case 'FieldUpdate':
                    return <InvoiceFieldUpdate logEntry={logEntry} />;
                  default:
                    return <div />;
                }
              })}
          </Box>
        </Scrollbar>
      </Box>
    </>
  );
};
