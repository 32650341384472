import { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Button, Tabs, Tooltip, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from '../../../redux/store';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { DateRangePickerSelect, MultiDateRange } from '../../../reusable-components/date-range-picker';
import Iconify from '../../../reusable-components/iconify';
import { ScopeGuard, useScopeCheck } from '../../../reusable-components/scopes';
import SelectAll from './SelectAll';
import ReportsDropdown from './ReportsDropdown';
import {
  getReportsData,
  setReportTypeAction,
  setFirstPageViewAction,
  setFormValuesInRedux,
  setDateValuesInRedux,
  getItemsOrderedReportAction,
  getFacilityComparisonReportAction,
  getVendorComparisonReportAction,
  getTopLevelBudgetReportAction,
  getFacilityHistoryReportAction, //facilities, categories, startDate, endDate
  getPoInvoiceMatchupReportsAction,
  getFacilitiesInvoiceReportAction, //facilities, vendors, startDate, endDate
  getInvoiceIssuesReportAction, //facilities, startDate, endDate
  getCheckInvoicePDFsReportAction, //facilityId, vendors, skip, take
  getSubstituteReportAction,
} from '../../../redux/slices/reports';
import { fCurrency } from '../../../utils/formatNumber';
import {
  downloadItemsOrderedReport,
  downloadFacilityComparisonReport,
  downloadVendorComparisonReport,
  downloadTopLevelBudgetReport,
  downloadFacilityHistoryReport,
  downloadPoInvoiceMatchupReports,
  downloadFacilitiesInvoiceReport,
  downloadInvoiceIssuesReport,
  downloadCheckInvoicePDFsReport,
  downloadSubstituteReport,
} from '../../../api/reports';
import { fMonthDayYear } from '../../../utils/formatTime';
import { AwaitButton } from '../../../reusable-components/await-button';

ReportsView.propTypes = {
  invoiceView: PropTypes.bool,
  CustomToolbar: PropTypes.func,
};

export default function ReportsView({ invoiceView = false, CustomToolbar }) {
  const {
    data: { purchasing, other, columns, reports, selectedReport, firstPageView, formValues: values },
  } = useSelector((state) => state.reports);

  const isLoading = useSelector((state) => state.reports.isLoading);
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get('start');
  const endDate = searchParams.get('end');

  const canViewPurchasingReports = useScopeCheck(['View-PurchasingReports'], true);
  const canViewOtherReports = useScopeCheck(['View-OtherReports'], true);
  const [tabValue, setTabValue] = useState(invoiceView ? 1 : canViewOtherReports && !canViewPurchasingReports ? 1 : 0);
  const [downloadLoading, setDownloadLoading] = useState(false)

  useEffect(() => {
    dispatch(getReportsData(tabValue === 0 ? 'Purchasing' : 'Other'));
  }, [dispatch, tabValue]);

  const setSelectedReportClick = async (value) => {
    await dispatch(setReportTypeAction(value));
  };
  const setFirstPageViewClick = async (value) => {
    await dispatch(setFirstPageViewAction(value));
  };
  const setValuesClick = async (value) => {
    await dispatch(setFormValuesInRedux(value));
  }; 

  const setPrevNewValuesClick = async (newValueFn) => {
    const newValues = newValueFn();
    const fullNewValue = { ...values, ...newValues };
    await dispatch(setFormValuesInRedux(fullNewValue));
  };

  const optionGuard = (option) => {
    if (selectedReport) {
      return selectedReport?.options?.includes(option);
    }
    return false;
  };

  const [dynamicColumns, setDynamicColumns] = useState(null);
  const allValues = useMemo(() => values, [values]);

  const data = tabValue === 0 ? purchasing : other;

  const disabledButton = useMemo(() => {
    if (selectedReport) {
      const blankEnabledReports = [1, 4, 15];
      if (blankEnabledReports.includes(selectedReport.reportId)) return false;

      return selectedReport?.options?.some((option) => {
        if (option === 'date') {
          return false;
        }
        if (option === 'dateSelect') {
          return values?.dateValues?.length === 0 || values?.dateValues?.length === undefined || values?.dateValues === null;
        }
        if (option === 'facilityId') {
          return values?.facilityId === null;
        }
        if (option === 'skipAndTake') {
          return false;
        }
        if (option === 'facilitySelector' || option === 'facilitiesByGroup') {
          return values?.facilities?.length === 0 || values?.facilities?.length === undefined;
        }
        if (option === 'undefined') {
          return false;
        }
        if (option === 'subcategories') {
          return false;
        }
        if (option === 'companies' || option === 'companiesAndFacilities') {
          return (
            (values?.companies?.length === 0 || values?.companies?.length === undefined) &&
            (values?.facilities?.length === 0 || values?.facilities?.length === undefined)
          );
        }

        return values?.[option]?.length === 0 || values?.[option]?.length === undefined;
      });
    }
    return true;
  }, [selectedReport, values]);

  useEffect(() => {
    if (selectedReport?.dynamicColumns && reports) {
      // Initialize an array to collect all names
      let allDynamicColumnsNames = [];
      if (selectedReport?.topLevelColumns) {
        reports?.[selectedReport?.topLevelColumns]?.forEach((col) => {
          allDynamicColumnsNames = allDynamicColumnsNames.concat(
            fMonthDayYear(col?.[selectedReport?.topLevelColumnsKey])
          );
        });
      } else {
        reports.forEach((item) => {
          if (item[selectedReport?.dynamicColumns]) {
            // Collect all names for the current item's dynamic column
            const names = item[selectedReport?.dynamicColumns].map((subItem) => subItem?.[selectedReport?.dynamicKey]);
            // Concatenate to the main array (this will create a nested array structure)
            allDynamicColumnsNames = allDynamicColumnsNames.concat(names);
          }
        });
      }
      // Flatten the array and filter for unique values
      allDynamicColumnsNames = [...new Set(allDynamicColumnsNames.flat())];

      setDynamicColumns(allDynamicColumnsNames);
    }
  }, [selectedReport, reports]);

  const getReports = async () => {
    if (selectedReport) {
      const { reportId } = selectedReport;
      switch (reportId) {
        case 1:
          dispatch(getItemsOrderedReportAction({ startDate, endDate, facilities: allValues?.facilities || [] }));
          break;
        case 2:
          dispatch(
            getFacilityComparisonReportAction({
              startDate,
              endDate,
              facilities: allValues?.facilities,
              categories: allValues?.categories,
            })
          );
          break;
        case 3:
          dispatch(
            getVendorComparisonReportAction({
              startDate,
              endDate,
              vendors: allValues?.vendors || [],
              categories: allValues?.categories || [],
            })
          );
          break;
        case 4:
          dispatch(
            getTopLevelBudgetReportAction({
              companies: allValues?.companies || [],
              facilities: allValues?.facilities || [],
              categories: allValues?.categories || [],
              subcategories: allValues?.subcategories || [],
              startDate,
              endDate,
            })
          );
          break;
        case 5:
          // eslint-disable-next-line no-case-declarations
          const dateRange = [];

          if (values?.dateValues) {
            values.dateValues.forEach((range) => {
              range.forEach((date) => {
                const formattedDate = date.format('YYYY-MM-DD');
                if (dateRange?.length > 0) {
                  const lastDate = dateRange[dateRange?.length - 1];
                  const nextDay = new Date(lastDate);
                  nextDay.setDate(nextDay.getDate() + 1);

                  const nextDayFormatted = nextDay.toISOString().split('T')[0];

                  if (nextDayFormatted === formattedDate) {
                    dateRange.pop(); // Remove the last date if it's the day before the current date
                  }
                }
                dateRange.push(formattedDate);
              });
            });
          }
          dispatch(
            getFacilityHistoryReportAction({
              facilityId: allValues?.facilityId?.id || null,
              categories: allValues?.categories || [],
              dates: dateRange,
            })
          );
          break;
        case 6:
          dispatch(
            getPoInvoiceMatchupReportsAction({
              facilities: allValues?.facilities || [],
              vendors: allValues?.vendors || [],
              startDate,
              endDate,
            })
          );
          break;
        case 7:
          dispatch(getSubstituteReportAction({
            companies: allValues?.companies || [],
            facilities: allValues?.facilities || [],
            categories: allValues?.categories || [],
            subcategories: allValues?.subcategories || [],
            startDate,
            endDate,
          }));
          break;
        case 11:
          dispatch(
            getFacilitiesInvoiceReportAction({
              facilities: allValues?.facilities || [],
              vendors: allValues?.vendors || [],
              startDate,
              endDate,
            })
          );
          break;
        case 12:
          dispatch(
            getCheckInvoicePDFsReportAction({
              facilityId: allValues?.facilityId?.id || null,
              vendors: allValues?.vendors || [],
              skip: allValues?.skip || 0,
              take: allValues?.take || 100,
            })
          );
          break;
        case 13:
          dispatch(
            getInvoiceIssuesReportAction({
              facilities: allValues?.facilities || [],
              startDate,
              endDate,
            })
          );
          break;
        case 15:
          dispatch(getItemsOrderedReportAction({ startDate, endDate, facilities: allValues?.facilities || [], allItems: true  }));
          break;
        default:
          dispatch(getItemsOrderedReportAction({ startDate, endDate, facilities: null }));
          break;
      }
    } else {
      dispatch(getItemsOrderedReportAction({ startDate, endDate, facilities: [] }));
    }
  };
  const downloadReports = () => {
    if (selectedReport) {
      setDownloadLoading(true)
        const { reportId } = selectedReport;
        switch (reportId) {
          case 1:
            downloadItemsOrderedReport({ startDate, endDate, facilities: allValues?.facilities || [] }).then(
              (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ItemsOrderedReportConsolidated.xlsx');
                document.body.appendChild(link);
                link.click();
                setDownloadLoading(false)
              }
            ).catch((e) => {setDownloadLoading(false);});
            break;
          case 2:
            downloadFacilityComparisonReport({
              startDate,
              endDate,
              facilities: allValues?.facilities,
              categories: allValues?.categories,
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'FacilityComparisonReport.xlsx');
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);})
            break;
          case 3:
            downloadVendorComparisonReport({
              startDate,
              endDate,
              vendors: allValues?.vendors || [],
              categories: allValues?.categories || [],
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'VendorComparisonReport.xlsx');
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);})
            break;
          case 4:
            downloadTopLevelBudgetReport({
              companies: allValues?.companies || [],
              facilities: allValues?.facilities || [],
              categories: allValues?.categories || [],
              subcategories: allValues?.subcategories || [],
              startDate,
              endDate,
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'TopLevelBudgetReport.xlsx');
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);});
            break;
          case 5:
            // eslint-disable-next-line no-case-declarations
            const dateRange = [];

            if (values?.dateValues) {
              values.dateValues.forEach((range) => {
                range.forEach((date) => {
                  const formattedDate = date.format('YYYY-MM-DD');
                  if (dateRange?.length > 0) {
                    const lastDate = dateRange[dateRange?.length - 1];
                    const nextDay = new Date(lastDate);
                    nextDay.setDate(nextDay.getDate() + 1);

                    const nextDayFormatted = nextDay.toISOString().split('T')[0];

                    if (nextDayFormatted === formattedDate) {
                      dateRange.pop(); // Remove the last date if it's the day before the current date
                    }
                  }
                  dateRange.push(formattedDate);
                });
              });
            }
            downloadFacilityHistoryReport({
              facilityId: allValues?.facilityId?.id || 0,
              categories: allValues?.categories || [],
              dates: dateRange,
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));

              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${allValues.facilityId.name}-FacilityHistoryReport.xlsx`);
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);});
            break;
          case 6:
            downloadPoInvoiceMatchupReports({
              facilities: allValues?.facilities || [],
              vendors: allValues?.vendors || [],
              startDate,
              endDate,
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'PoInvoiceMatchupReport.xlsx');
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);});
            break;
          case 7:
            downloadSubstituteReport({
              companies: allValues?.companies || [],
              facilities: allValues?.facilities || [],
              categories: allValues?.categories || [],
              subcategories: allValues?.subcategories || [],
              startDate,
              endDate
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'SubstituteReport.xlsx');
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);});
            break;
          case 11:
            downloadFacilitiesInvoiceReport({
              facilities: allValues?.facilities || [],
              vendors: allValues?.vendors || [],
              startDate,
              endDate,
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'FacilitiesInvoiceReport.xlsx');
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);});
            break;
          case 12:
            downloadCheckInvoicePDFsReport({
              facilityId: allValues?.facilityId?.id || 0,
              vendors: allValues?.vendors || [],
              skip: allValues?.skip || 0,
              take: allValues?.take || 100,
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'CheckInvoicePDFsReport.xlsx');
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);});
            break;
          case 13:
            downloadInvoiceIssuesReport({
              facilities: allValues?.facilities || [],
              startDate,
              endDate,
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'InvoiceIssuesReport.xlsx');
              document.body.appendChild(link);
              link.click();
              setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);});
            break;
          case 15:
            downloadItemsOrderedReport({ startDate, endDate, facilities: allValues?.facilities || [], allItems: true }).then(
              (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ItemsOrderedReport.xlsx');
                document.body.appendChild(link);
                link.click();
                setDownloadLoading(false)
            }).catch((e) => {setDownloadLoading(false);});;
            break;

      default:
        setDownloadLoading(false)
        break;
       
    }
   
  }
}
      


  useEffect(() => {
    if (firstPageView) return;
    if (selectedReport || (startDate && endDate)) {
      getReports();
    }
  }, [startDate, endDate, selectedReport, firstPageView]);

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
          <>
            <Typography variant="subtitle1" color="secondary" sx={{ ml: 3, mr: 2, mb: 0.5 }}>
              {selectedReport?.title}
            </Typography>
          </>

          {optionGuard('date') && (
            <Grid item>
              <DateRangePickerSelect />
            </Grid>
          )}
         {
         
         <LoadingButton
          variant={ "outlined"}
          loading = {downloadLoading}
          sx={{ ml: 3 }}
          size="small"
          onClick={() => {
            downloadReports(startDate, endDate);
          }}
          startIcon={<Iconify icon="mdi:tray-arrow-down" />}
          endIcon={<Iconify icon="vscode-icons:file-type-excel" />}
        >
          Download
        </LoadingButton>                  
                              
         }
            
            
             
        </Grid>
      </Grid>
    ),
    [selectedReport, allValues, startDate, endDate, downloadLoading]
  );

  const allColumns = useMemo(() => {
    if (selectedReport?.dynamicColumns && dynamicColumns) {
      return [
        ...selectedReport?.columns,
        ...dynamicColumns.map((col) => ({
          field: col,
          headerName: col,
          width: selectedReport?.reportId === 3 ? 500 : 250,
          alignItems: 'center',
          renderCell: (params) => {
            const nameLookup =
              selectedReport?.topLevelColumnsKey === 'date'
                ? params?.row[selectedReport?.dynamicColumns]?.find(
                    (item) => fMonthDayYear(item[selectedReport?.dynamicKey]) === col
                  )
                : params?.row[selectedReport?.dynamicColumns]?.filter(
                    (item) => item[selectedReport?.dynamicKey] === col
                  );

            return (
              <>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                  {selectedReport?.dynamicFields.map &&
                    selectedReport?.dynamicFields.map((fieldObj) => {
                      const { combineTwoFields, isCurrency, headerName, field, isNumber, tooltip } = fieldObj;
                      return (
                        <Tooltip key={field} title={tooltip} arrow placement="top">
                          <Grid item key={field}>
                            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                              <Grid item>
                                <Box
                                  component="div"
                                  sx={{
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {headerName}
                                </Box>
                              </Grid>

                              {nameLookup?.length > 0 ? (
                                nameLookup?.map((item) => (
                                  <Grid item key={item.id}>
                                    <Box
                                      component="div"
                                      sx={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        fontSize: '0.75rem',
                                      }}
                                    >
                                      {combineTwoFields
                                        ? `${item?.[combineTwoFields[0]] || 0} / ${item?.[combineTwoFields[1]] || 0}`
                                        : isCurrency
                                        ? fCurrency(item?.[field])
                                        : isNumber
                                        ? item?.[field] || 0
                                        : item?.[field] || ''}
                                    </Box>
                                  </Grid>
                                ))
                              ) : (
                                <Box
                                  component="div"
                                  sx={{
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {isCurrency
                                    ? fCurrency(nameLookup?.[field])
                                    : isNumber
                                    ? nameLookup?.[field] || 0
                                    : nameLookup?.[field] || ''}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Tooltip>
                      );
                    })}
                </Grid>
              </>
            );
          },
          valueGetter: (params) => {
            const nameLookup =
              selectedReport?.topLevelColumnsKey === 'date'
                ? params?.row[selectedReport?.dynamicColumns]?.filter(
                    (item) => fMonthDayYear(item[selectedReport?.dynamicKey]) === col
                  )
                : params?.row[selectedReport?.dynamicColumns]?.filter(
                    (item) => item[selectedReport?.dynamicKey] === col
                  );

            return nameLookup?.map((item) => item?.[selectedReport?.dynamicKey]) || '';
          },
        })),
      ];
    }

    return selectedReport?.columns || [];
  }, [selectedReport, dynamicColumns, selectedReport?.columns]);

  //console.log("selected", selectedReport);
  //console.log("redux report", selectedReport);

  return (
    <>
      {invoiceView ? (
        <Grid item>
          <CustomToolbar />
        </Grid>
      ) : null}
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
        <Box>
          <Tabs value={tabValue}>
            {!invoiceView && (
              <ScopeGuard scopes={['View-PurchasingReports']} allowAdmin>
                <ReportsDropdown
                  selectedOption={selectedReport}
                  setSelectedOption={setSelectedReportClick}
                  title="Purchasing"
                  setTabValue={setTabValue}
                  setFirstPageView={setFirstPageViewClick}
                />
              </ScopeGuard>
            )}
            <ScopeGuard scopes={['View-OtherReports']} allowAdmin>
              <ReportsDropdown
                selectedOption={selectedReport}
                setSelectedOption={setSelectedReportClick}
                title="Invoices"
                setTabValue={setTabValue}
                setFirstPageView={setFirstPageViewClick}
              />
            </ScopeGuard>
          </Tabs>
        </Box>
      </Grid>
      {firstPageView ? (
        <>
          {selectedReport && (
            <AwaitButton
              variant="outlined"
              size="small"
              color="secondary"
              sx={{ mb: 2 }}
              onClick={() => {
                setFirstPageViewClick(false);
              }}
              endIcon={<Iconify icon={'tabler:send'} />}
              disabled={disabledButton}
            >
              Get {selectedReport?.title}
            </AwaitButton>
          )}
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            {optionGuard('date') && (
              <Grid item>
                <DateRangePickerSelect />
              </Grid>
            )}
            {optionGuard('skipAndTake') && (
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Grid item>
                    <TextField
                      label="Skip"
                      type="number"
                      size="small"
                      value={allValues?.skip || 0}
                      onChange={(e) => {
                        setValuesClick({ ...values, skip: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Take"
                      type="number"
                      size="small"
                      value={allValues?.take || 100}
                      onChange={(e) => {
                        setValuesClick({ ...values, take: e.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {optionGuard('facilitySelector') && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={allValues?.groupId || null}
                  onChange={(event, newValue) => {
                    setValuesClick({
                      ...values,
                      groupId: newValue,
                      facilities: data.facilities.filter((facility) => facility.companyId === newValue?.id),
                    });
                  }}
                  options={data.companies}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Group" />}
                  sx={{
                    minWidth: '90vw',
                    width: '100%',
                  }}
                />
              </Grid>
            )}
            {optionGuard('facilitySelector') && (
              <Grid item>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  value={allValues?.facilities || []}
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return [{ name: 'Select All...', all: true }, ...filtered];
                  }}
                  onChange={(event, newValue) => {
                    if (newValue.some((option) => option.all)) {
                      const isSelectAllNewlyAdded = newValue.length === 1 || newValue[newValue.length - 2].all !== true;
                      setValuesClick({ ...values, facilities: isSelectAllNewlyAdded ? data.facilities : [] });
                    } else {
                      setValuesClick({ ...values, facilities: newValue });
                    }
                  }}
                  options={data.facilities}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Facility" />}
                  sx={{
                    minWidth: '90vw',
                    width: '100%',
                  }}
                />
              </Grid>
            )}
            {optionGuard('companies') && (
              <Grid item>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  options={data.companies}
                  getOptionLabel={(option) => option.name}
                  value={allValues?.companies || []}
                  title="Groups"
                  onChange={(event, newValue) => {
                    const compIds = newValue?.map((c) => c.id);
                    setValuesClick({
                      ...values,
                      companies: newValue ?? [],
                      facilities: data.facilities.filter((facility) => compIds.includes(facility?.companyId)),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} label="Group" />}
                  sx={{
                    minWidth: '90vw',
                    width: '100%',
                  }}
                />
              </Grid>
            )}
            {optionGuard('facilities') && (
              <Grid item>
                <SelectAll options={data.facilities} title="Facilities" id="facilities" setValues={setValuesClick} />
              </Grid>
            )}
            {optionGuard('companiesAndFacilities') && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  multiple
                  size="small"
                  options={data.facilities}
                  getOptionLabel={(option) => option.name}
                  value={allValues?.facilities || []}
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return [{ name: 'Select All...', all: true }, ...filtered];
                  }}
                  onChange={(event, newValue) => {
                    if (newValue.some((option) => option.all)) {
                      const isSelectAllNewlyAdded = newValue.length === 1 || newValue[newValue.length - 2].all !== true;
                      setValuesClick({ ...values, facilities: isSelectAllNewlyAdded ? data.facilities : [] });
                    } else {
                      setValuesClick({ ...values, facilities: newValue });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Facilities" />}
                  sx={{
                    minWidth: '90vw',
                    width: '100%',
                  }}
                />
              </Grid>
            )}
            {optionGuard('facilityId') && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={allValues?.facilityId || null}
                  onChange={(event, newValue) => {
                    setValuesClick({ ...values, facilityId: newValue });
                  }}
                  options={data.facilities}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Facility" />}
                  sx={{
                    minWidth: '90vw',
                    width: '100%',
                  }}
                />
              </Grid>
            )}
            {optionGuard('vendors') && (
              <Grid item>
                <SelectAll options={data.vendors} title="Vendors" id="vendors" setValues={setPrevNewValuesClick} />
              </Grid>
            )}
            {optionGuard('categories') && (
              <Grid item>
                <SelectAll
                  options={data.categories}
                  title="Categories"
                  id="categories"
                  setValues={setPrevNewValuesClick}
                />
              </Grid>
            )}
            {optionGuard('subcategories') && (
              <Grid item>
                <SelectAll
                  // options={data.subcategories}
                  options={
                    data.subcategories.filter((sub) =>
                      values?.categories?.some((cat) => cat.id === sub.mainCategoryId)
                    ) || []
                  }
                  title="Subcategories"
                  id="subcategories"
                  setValues={setPrevNewValuesClick}
                />
              </Grid>
            )}
            {optionGuard('dateSelect') && (
              <Grid item>
                <MultiDateRange datesValue={allValues?.dateValues} setDatesValue={(dateValues) => setValuesClick({ ...values, dateValues })} />
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          <CustomDataGrid
            gridId="reports"
            boxSX={{ height: 'calc(100vh - 310px)', width: '100%' }}
            data={selectedReport?.nestedData ? reports?.[selectedReport?.nestedData] || [] : reports || []}
            gridColumns={allColumns || []}
            getRowId={(row) =>
              selectedReport?.getRowId
                ? selectedReport.getRowId(row)
                : selectedReport?.nestedDataKey
                ? row?.[selectedReport?.nestedDataKey]?.id
                : row.id
            }
            CustomLeftToolbar={CustomLeftToolbar}
            isLoading={isLoading}
            //forceReRender={ }
            pinnedColumns={selectedReport?.pinnedColumns || {}}
          />
        </>
      )}
    </>
  );
}
