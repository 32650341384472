import DownloadIcon from '@mui/icons-material/Download';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { getVendorFileDownload, getVendorFiles, postDeleteVendorFile } from '../../../../api/warehouse-vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { FILES } from '../../../../reusable-components/datagrid/vendorColumns';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import WarehouseVendorFileAddModal from './WarehouseVendorFileAddModal';

WarehouseVendorFilesModal.propTypes = {
  row: PropTypes.object,
};

export default function WarehouseVendorFilesModal({ row }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchVendorData = async () => {
    setIsLoading(true);
    const response = await getVendorFiles(row.id);
    if (response.status === 200) {
      setData(response.data);
    } else {
      enqueueSnackbar('Error fetching warehouse vendor files', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const downloadFile = async (id, fileName, extension) => {
    const res = await getVendorFileDownload(id).catch(() =>
      enqueueSnackbar('File failed to download', { variant: 'error' })
    );

    if (res.status === 200) {
      const blob = new Blob([res.data], { type: `application/${extension}` });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleClickOpen = () => {
    fetchVendorData();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    minWidth: 80,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <Stack sx={{ flexDirection: 'row' }}>
          <IconButton
            color="info"
            onClick={() => downloadFile(params.row.id, params.row.fileName, params.row.fileName.split('.').pop())}
          >
            <DownloadIcon />
          </IconButton>
          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete ${params.row.fileName}?`}
            actionButton="Delete"
            action={() => {
              postDeleteVendorFile(id);
              fetchVendorData();
            }}
          />
        </Stack>
      );
    },
    id: 'actions',
  };

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
        <Box>
          <WarehouseVendorFileAddModal row={row} data={data} fetchData={fetchVendorData} />
        </Box>
      </Grid>
    ), // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <>
      <Tooltip title="Vendor files">
        <IconButton edge="end" color="primary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
          <FolderOpenIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Vendor Files
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {row.name}
                  </Typography>
                  {data?.files && (
                    <Grid item xs={12}>
                      <CustomDataGrid
                        gridId="admin-warehouse-vendor-files-modal"
                        boxSX={{ height: 'calc(100vh - 340px)' }}
                        data={data?.files}
                        gridColumns={[...FILES, actionButtons]}
                        CustomLeftToolbar={CustomLeftToolbar}
                        isLoading={isLoading}
                        isModal
                      />
                    </Grid>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
