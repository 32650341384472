/* eslint-disable camelcase */
import { Box, Divider } from '@mui/material';
import { renderColorBox } from '../../utils/cssStyles';
import Iconify from '../iconify';
import { fCurrency, fNumber } from '../../utils/formatNumber';
import { fMonthDayYear, fDateTime } from '../../utils/formatTime';

const subcategory = {
  field: 'agoraSubcategory',
  headerName: 'Subcategory',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),

  type: 'customSelect',
};
const category = {
  field: 'agoraCategory',
  headerName: 'Category',
  width: 150,
  type: 'customSelect',
  renderCell: (params) => renderColorBox(params.row.agoraCategory, 'secondary'),
  valueGetter: (params) => params.row.agoraCategory || '',
};

const vendorItemId = {
  field: 'vendorItemId',
  headerName: 'Vendor Item Id',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.value,
  type: 'customText',
};
const replacementSku = {
  field: 'replacementSku',
  headerName: 'Replacement Sku',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.value,
  type: 'customText',
};
const replacementPrice = {
  field: 'replacementPrice',
  headerName: 'Replacement Price',
  width: 150,
  type: 'customText',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.row.replacementPrice || 0,
};
const replacementDescription = {
  field: 'replacementDescription',
  headerName: 'Replacement Description',
  width: 200,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const description = {
  field: 'description',
  headerName: 'Description',
  width: 200,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const issue = {
  field: 'issue',
  headerName: 'Issue',
  width: 300,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontSize: '0.70rem',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.value,
  type: 'customText',
};
const manufacturer = {
  field: 'manufacturer',
  headerName: 'Manufacturer',
  width: 150,
  type: 'customText',
};
const manufacturerId = {
  field: 'manufacturerId',
  headerName: 'Manufacturer Id',
  width: 150,
  type: 'customText',
};
const agoraNumber = {
  field: 'agoraNumber',
  headerName: 'PS ID',
  width: 150,
  type: 'customText',
};
const id = {
  field: 'id',
  headerName: 'PS ID',
  width: 150,
  type: 'customText',
};
const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  width: 150,
  type: 'customText',
};
const orderNumber = {
  field: 'orderNumber',
  headerName: 'Order #',
  width: 150,
  type: 'customText',
};
const facility = {
  field: 'facility',
  headerName: 'Facility',
  width: 200,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontSize: '0.70rem',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.value,
  type: 'customText',
};
const amount = {
  field: 'amount',
  headerName: 'Qty',
  width: 50,
  type: 'customText',
};
const uom = {
  field: 'uom',
  headerName: 'UOM',
  width: 150,
  type: 'customText',
};
const uomAmount = {
  field: 'uomAmount',
  headerName: 'UOM Amount',
  width: 150,
  type: 'customText',
};
const replacementUomAmount = {
  field: 'replacementUomAmount',
  headerName: 'Replacement UOM Amount',
  width: 150,
  type: 'customText',
};
const uomType = {
  field: 'uomType',
  headerName: 'UOM Type',
  width: 150,
  type: 'customText',
};
const replacementUomType = {
  field: 'replacementUomType',
  headerName: 'Replacement UOM Type',
  width: 150,
  type: 'customText',
};
const todaysPrice = {
  field: 'mostRecentPrice',
  headerName: 'Most Recent Price',
  width: 150,
  type: 'customText',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.row.mostRecentPrice || 0,
};

const item_vendorItemId = {
  field: 'vendorItemId',
  headerName: 'Vendor Item Id',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.item?.vendorItemId}
    </Box>
  ),
  valueGetter: (params) => params?.row?.item?.vendorItemId,
  type: 'customText',
};

//item_description
const item_description = {
  field: 'description',
  headerName: 'Description',
  width: 200,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.item?.description}
    </Box>
  ),

  valueGetter: (params) => params?.row?.item?.description,
};
//order_description
const order_description = {
  field: 'description',
  headerName: 'Ordered Description',
  width: 200,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.orderItem?.description}
    </Box>
  ),
  valueGetter: (params) => params?.row?.orderItem?.description,
};
const order_qty = {
  field: 'quantity',
  headerName: 'Qty Ord.',
  width: 100,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.orderItem?.quantity}
    </Box>
  ),
  valueGetter: (params) => params?.row?.orderItem?.quantity,
};
const order_price = {
  field: 'price',
  headerName: 'Price Ord.',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {fCurrency(params?.row?.orderItem?.price)}
    </Box>
  ),
  valueGetter: (params) => params?.row?.orderItem?.price,
};
const order_uomType = {
  field: 'uomType',
  headerName: 'UOM Ord.',
  width: 80,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.orderItem?.uomType}
    </Box>
  ),
  valueGetter: (params) => params?.row?.orderItem?.uomType,
};
const invoiceItems = {
  field: 'invoiceItems',
  headerName: 'Invoice Items',
  width: 500,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.invoiceItems?.map((item, index) => (
        <div key={index}>
          {index !== 0 && <Divider sx={{ width: '100%', my: 0.5, backgroundColor: '#000' }} />}
          <div>
            Received: {fDateTime(item.dateInvoiceReceived)}. Invoiced: {fDateTime(item.dateInvoiceReceived)}
          </div>
          <div>{item.description}</div>
          <div>
            {fCurrency(item.price)}. Qty: {fNumber(item.qty)}. UOM: {item.uomType}{' '}
          </div>
          <div>
            Invoice Id: {item.vendorInvoiceId}. Sent: {item.sent ? 'True' : 'False'}
          </div>
        </div>
      ))}
    </Box>
  ),
  valueGetter: (params) =>
    params?.row?.invoiceItems
      ?.map(
        (item) => `${item.description} ${item.price} ${item.qty} ${item.uomType} ${item.vendorInvoiceId} ${item.sent}`
      )
      .join(' '),
};

// item_manufacturer,

const item_manufacturer = {
  field: 'manufacturer',
  headerName: 'Manufacturer',
  width: 150,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.item?.manufacturer}
    </Box>
  ),
  valueGetter: (params) => params?.row?.item?.manufacturer,
};

// item_manufacturerId,
// item_category,
// item_subcategory,
// item_agoraNumber,
// item_vendor,
// item_amount,
// item_uom,
// item_todaysPrice,

const item_manufacturerId = {
  field: 'manufacturerId',
  headerName: 'Manufacturer Id',
  width: 150,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.item?.manufacturerId}
    </Box>
  ),
  valueGetter: (params) => params?.row?.item?.manufacturerId,
};

const item_category = {
  field: 'agoraCategory',
  headerName: 'Category',
  width: 150,
  type: 'customSelect',
  renderCell: (params) => renderColorBox(params?.row?.item?.agoraCategory, 'secondary'),
  valueGetter: (params) => params?.row?.item?.agoraCategory || '',
  path: 'item.agoraCategory',
};

const item_subcategory = {
  field: 'agoraSubcategory',
  headerName: 'Subcategory',
  width: 150,
  type: 'customSelect',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.item?.agoraSubcategory}
    </Box>
  ),
  valueGetter: (params) => params?.row?.item?.agoraSubcategory || '',
  path: 'item.agoraSubcategory',
};

const item_agoraNumber = {
  field: 'agoraNumber',
  headerName: 'PS ID',
  width: 150,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.item?.productId}
    </Box>
  ),
  valueGetter: (params) => params?.row?.item?.productId,
};

const item_vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  width: 150,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params?.row?.item?.vendor}
    </Box>
  ),
  valueGetter: (params) => params?.row?.item?.vendor,
};

const item_price = {
  field: 'price',
  headerName: 'Price',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params?.row.item?.price),
  valueGetter: (params) => params?.row.item?.price || 0,
};

const item_uomAmount = {
  field: 'uomAmount',
  headerName: 'UOM',
  width: 150,
  minWidth: 70,
  renderCell: (params) =>
    `${params.row.item.uomAmount ? params.row.item.uomAmount : ''}/${
      params.row.item.uomType && params.row.item.uomType !== 'undefined' ? params.row.item.uomType : ''
    }`,
  valueGetter: (params) => `${params.row.item.uomAmount ? params.row.item.uomAmount : ''}/${params.row.item.uomType}`,
};
const perItem = {
  field: 'perItem',
  headerName: 'Per Item',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params?.row?.perItem),
  valueGetter: (params) => params?.row?.perItem || 0,
};

const price = {
  field: 'price',
  headerName: 'Price',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params?.row?.price),
  valueGetter: (params) => params?.row?.price || 0,
};
const total = {
  field: 'total',
  headerName: 'Total',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params?.row?.total),
  valueGetter: (params) => params?.row?.total || 0,
};
const originalPrice = {
  field: 'originalPrice',
  headerName: 'Original Price',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params?.row?.originalPrice),
  valueGetter: (params) => params?.row?.originalPrice || 0,
};
const dateApproved = {
  field: 'dateApproved',
  headerName: 'Date Approved',
  width: 180,
  type: 'customDate',
  renderCell: (params) => fDateTime(params.value),
  valueGetter: (params) => params.value || 0,
};
const glCode = {
  field: 'glCode',
  headerName: 'Gl Code',
  width: 80,
  type: 'customText',
};
const facilityName = {
  field: 'facilityName',
  headerName: 'Facility',
  width: 150,
  type: 'customText',
};
//budget
//spent
// fullSpent
//census
//ppdBudget
//ppdSpent

const budget = {
  field: 'budget',
  headerName: 'Budget',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.value || 0,
};

const spent = {
  field: 'spent',
  headerName: 'Spent',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.value || 0,
};

const fullSpent = {
  field: 'fullSpent',
  headerName: 'Full Spent',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.value || 0,
};

const census = {
  field: 'census',
  headerName: 'Census',
  width: 150,
  type: 'customNumber',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.value || 0,
};

const ppdBudget = {
  field: 'ppdBudget',
  headerName: 'PPD Budget',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.value || 0,
};

const ppdSpent = {
  field: 'ppdSpent',
  headerName: 'PPD Spent',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.value || 0,
};

const invoiceId = {
  field: 'invoiceId',
  headerName: 'Invoice Id',
  width: 150,
  type: 'customText',
};

const vendorInvoiceId = {
  field: 'vendorInvoiceId',
  headerName: 'Vendor Id',
  width: 150,
  type: 'customText',
};

const expectedPrice = {
  field: 'expectedPrice',
  headerName: 'Expected Price',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.value || 0,
};

const quantity = {
  field: 'quantity',
  headerName: 'Sent',
  width: 50,
  type: 'customNumber',
};

const qty = {
  field: 'quantity',
  headerName: 'QTY',
  width: 50,
  type: 'customNumber',
};

const quantityPriceTotal = {
  field: 'quantityPriceTotal',
  headerName: 'Total',
  width: 50,
  valueGetter: (params) => (params.row?.quantity ?? 0) * (params.row?.price ?? 0),
  renderCell: (params) => fCurrency((params.row?.quantity ?? 0) * (params.row?.price ?? 0)),
  type: 'customNumber',
};

const expectedQuantity = {
  field: 'expectedQuantity',
  headerName: 'Ordered',
  width: 50,
  type: 'customNumber',
};

const account = {
  field: 'account',
  headerName: 'Account',
  width: 150,
  type: 'customText',
};
const approvedBy = {
  field: 'approvedBy',
  headerName: 'Approved By',
  width: 150,
  type: 'customText',
};
const submittedBy = {
  field: 'submittedBy',
  headerName: 'Submitted By',
  width: 150,
  type: 'customText',
};
const invoiceDate = {
  field: 'invoiceDate',
  headerName: 'Invoice Date',
  width: 150,
  type: 'customDate',
  renderCell: (params) => fDateTime(params.value),
  valueGetter: (params) => params.value || 0,
};
const dateOrdered = {
  field: 'dateOrdered',
  headerName: 'Date Ordered',
  width: 180,
  type: 'customDate',
  renderCell: (params) => fDateTime(params.value),
  valueGetter: (params) => params.value || 0,
};
const sent = {
  field: 'sent',
  headerName: 'Sent',
  width: 100,
  // <Iconify icon="mdi:check" color="primary" width={28} height={28} />;
  renderCell: (params) => (params.value ? <Iconify icon="mdi:check" color="primary" width={28} height={28} /> : ''),
  valueGetter: (params) => params.value || false,
};

const item = {
  field: 'item',
  headerName: 'Item',
  width: 150,
  type: 'customText',
};

const PURCHASING_ITEMS_ORDERED = [
  vendorItemId,
  description,
  manufacturer,
  manufacturerId,
  category,
  subcategory,
  agoraNumber,
  vendor,
  amount,
  uom,
  todaysPrice,
];
const ITEMS_ORDERED_FULL = [
  orderNumber,
  vendorItemId,
  description,
  manufacturer,
  manufacturerId,
  category,
  subcategory,
  agoraNumber,
  vendor,
  uom,
  price,
  qty,
  total,
  facility,
  glCode,
  dateOrdered,
  approvedBy,
  submittedBy
];
const FACILITIES_INVOICES_REPORT = [
  invoiceId,
  vendorInvoiceId,
  facility,
  vendorItemId,
  description,
  price,
  expectedPrice,
  quantity, // sent
  expectedQuantity, // Sent
  quantityPriceTotal,
  category,
  subcategory,
  vendor,
];
const FACILITIES_INVOICES_DISCREPANCY = [
  vendorInvoiceId,
  vendor,
  facility,
  account,
  sent,
  invoiceDate,
  item,
  issue,
  sent,
];
const INVOICES_ISSUES_REPORT = [vendorInvoiceId, vendor, issue];
const TOP_LEVEL_BUDGET_REPORT = [facilityName, budget, spent, fullSpent, census, ppdBudget, ppdSpent];
const FACILITY_COMPARISON_REPORT = [
  item_description,
  item_vendorItemId,
  item_manufacturer,
  item_manufacturerId,
  item_category,
  item_subcategory,
  item_agoraNumber,
  item_vendor,
  item_price,
];
const FACILITY_HISTORY_REPORT = [
  item_description,
  item_vendorItemId,
  item_manufacturer,
  item_manufacturerId,
  item_category,
  item_subcategory,
  item_agoraNumber,
  item_vendor,
  item_price,
  item_uomAmount,
];
const PO_INVOICE_REPORT = [
  orderNumber,
  vendor,
  dateOrdered,
  vendorItemId,
  order_description,
  order_qty,
  order_price,
  order_uomType,
  invoiceItems,
];
const SUBSTITUTE_REPORT = [
  description,
  category,
  subcategory,
  orderNumber,	
  dateApproved,	
  vendor,
  vendorItemId,	
  originalPrice,
  uomAmount,
  uomType,
  replacementSku,
  replacementPrice,
  replacementDescription,
  replacementUomAmount,
  replacementUomType
];
const VENDOR_COMPARISON_REPORT = [description, id, category, subcategory];

export {
  FACILITY_HISTORY_REPORT,
  PO_INVOICE_REPORT,
  PURCHASING_ITEMS_ORDERED,
  FACILITY_COMPARISON_REPORT,
  VENDOR_COMPARISON_REPORT,
  TOP_LEVEL_BUDGET_REPORT,
  FACILITIES_INVOICES_REPORT,
  INVOICES_ISSUES_REPORT,
  FACILITIES_INVOICES_DISCREPANCY,
  SUBSTITUTE_REPORT,
  ITEMS_ORDERED_FULL
};
