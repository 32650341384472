import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Grid, Button, Paper } from '@mui/material';
import { MINIMUM } from '../../../../reusable-components/datagrid/vendorColumns';
import { postDeleteVendorMinimum, getVendorMinimumsData } from '../../../../api/vendors';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import MinimumsModal from './MinimumsModal';

VendorMinimumsTab.propTypes = {
  selectedRow: PropTypes.object,
};

export default function VendorMinimumsTab({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getVendorMinimumsData();
      const { data } = response; // Filter booking data if a row is selected
      const filteredVendorData = selectedRow ? data?.filter((d) => d.vendorId === selectedRow.id) : data;
      setData(filteredVendorData);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Error fetching vendor minimum', { variant: 'error' });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const deleteMin = async (id) => {
    try {
      const response = await postDeleteVendorMinimum(id);
      if (response.status === 200) {
        enqueueSnackbar('Minimum deleted successfully', { variant: 'success' });
        fetchData();
      }
    } catch (error) {
      enqueueSnackbar('Error deleting minimum', { variant: 'success' });
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <MinimumsModal fetchData={fetchData} edit row={params.row} />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete minimum ?`}
            actionButton="Delete"
            action={() => deleteMin(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <MinimumsModal fetchData={fetchData} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    ),
    []
  );

  return (
    <>
      <Paper sx={{ px: 2, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
        <Grid item xs={12}>
          <CustomDataGrid
            gridId="admin-vendors-minimums-tab"
            boxSX={{ height: 'calc(100vh - 280px)' }}
            data={data}
            gridColumns={[...MINIMUM, actionButtons]}
            forceReRender={data}
            sort={[{ field: 'facility', sort: 'asc' }]}
            CustomLeftToolbar={customToolbar}
            isLoading={isLoading}
            disableSelectionOnClick
            isModal
          />
        </Grid>
      </Paper>
    </>
  );
}
