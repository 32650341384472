import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Divider } from '@mui/material';
import { useSelector, useDispatch } from '../../../../redux/store';
import RentalItem from './RentalItem';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { getRentalItems } from '../../../../redux/slices/rentals';

export default function RentalItemsSelect() {
  const dispatch = useDispatch();
  const {
    data: { items, categoryData, facilityVendors },
  } = useSelector((state) => state.rentals);

  const [searchParams] = useSearchParams();
  const selectedCategoryName = searchParams.get('category');
  const selectedSubcategoryName = searchParams.get('subcategory');

  const selectedCategory = categoryData.find((cat) => cat.categoryName === selectedCategoryName);
  const selectedCategoryId = selectedCategory?.categoryId;

  const selectedSubcategory = categoryData.find(
    (subcat) => subcat.categoryName === selectedCategoryName && subcat.subcategoryName === selectedSubcategoryName
  );
  const selectedSubcategoryId = selectedSubcategory?.subcategoryId;

  const itemsJoinedWithVendor = items
    .map((item) => {
      const matchingVendor = facilityVendors.find((fv) => fv.vendorId === item.vendorId);
      return {
        ...item,
        isPreferred: matchingVendor ? matchingVendor.isPreferred : false,
      };
    })
    .sort((a, b) => b.isPreferred - a.isPreferred);

  const filteredItems = itemsJoinedWithVendor.filter(
    (item) => item.categoryId === selectedCategoryId && item.subcategoryId === selectedSubcategoryId
  );

  useEffect(() => {
    dispatch(getRentalItems());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Rental Items | Platform Solutions</title>
      </Helmet>

      <div style={{ width: '100%' }}>
        <header>
          <Divider
            flexItem
            sx={{
              height: '2px',
              opacity: 0.39,
              backgroundColor: '#4ca5e7',
              ml: 2,
              mb: 2,
              mt: 2,
            }}
          />
        </header>
      </div>

      <Scrollbar sx={{ height: 'calc(100vh - 300px)' }}>
        <Grid container spacing={4} sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          {(filteredItems.length > 0 ? filteredItems : itemsJoinedWithVendor).map((item) => (
            <RentalItem key={item.id} item={item} />
          ))}{' '}
        </Grid>
      </Scrollbar>
    </>
  );
}
