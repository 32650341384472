import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import { CatalogView } from '../../../components/dashboard/admin/catalog';
import { getCatalog } from '../../../redux/slices/catalog';

export default function Catalog() {
  const dispatch = useDispatch();
  const {
    data: { products },
  } = useSelector((state) => state.catalog);

  useEffect(() => {
    if (!products?.length) dispatch(getCatalog());
  }, [products, dispatch]);

  return (
    <>
      <Helmet>
        <title>Admin Catalog | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <CatalogView />
        </Grid>
      </Grid>
    </>
  );
}
