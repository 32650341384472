import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
// components
import { TicketView } from '../../../components/dashboard/tickets/ticket';

// ----------------------------------------------------------------------

export default function Ticket() {
  return (
    <>
      <Helmet>
        <title>Ticket | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <TicketView />
        </Grid>
      </Grid>
    </>
  );
}
