import { Box, Card, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderOccupantsData, postLeaveOrder, getStateAlerts } from '../../../../api/orders';
import { useSelector } from '../../../../redux/store';
import { CustomAvatar, CustomAvatarGroup } from '../../../../reusable-components/custom-avatar';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { fDateTime } from '../../../../utils/formatTime';
import { categoryIconLookup } from '../../../../utils/cssStyles';
import AltAddressDialog from './AltAddressDialog';

OrderInfo.propTypes = {
  setAltAddress: PropTypes.func,
  altAddress: PropTypes.object,
};

export default function OrderInfo({ setAltAddress, altAddress }) {
  const { id = '' } = useParams();
  const [copied, setCopied] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const { orderById } = useSelector((state) => state.orders || {});
  const { facility, orderNumber, submittedBy, dateAdded, onBehalfOf, department, facilityBedCount,facilityAddress } =
    orderById[id] || {};

  const { name, email } = submittedBy || { name: '', email: '' };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = (e) => {
    setCopied(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAltAddress({
      street: '',
      phoneNumber: '',
      city: '',
      state: '',
      zip: '',
    });
  };
  const [occupiedBy, setOccupiedBy] = useState([]);

  const [stateAlerts, setStateAlerts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getOrderOccupantsData(id);
      if (response.data.length) {
        setOccupiedBy(response.data);
      }
    };

    if (open) fetchData();
  }, [id, open]);

  // Handle postLeaveOrder when page exits or closes
  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      event.preventDefault();
      await postLeaveOrder(id);
    };

    window.addEventListener('pagehide', handleBeforeUnload);
    return () => {
      window.removeEventListener('pagehide', handleBeforeUnload);
    };
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stateAlert = await getStateAlerts(facilityAddress?.zip);
        setStateAlerts(stateAlert?.headlines ?? []);
      }
      catch (error) {
        console.error("Error fetching state alerts:", error);
        setStateAlerts([]);
      }
    }
      if (facilityAddress?.state) {
        fetchData()
      }
  }, [facility, facilityAddress]);

  return (
    <>
      <Box
        sx={{
          minWidth: '300px',
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ p: 1, width: '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="info.main">
              {facility || 'Loading...'}
              {stateAlerts?.length > 0 &&
                <Tooltip title={stateAlerts[0]}>
                  <IconButton color="error" onClick={() => window.open(`https://weather.com/weather/today/l/${facilityAddress?.zip}`, "_blank")}>
                      <Iconify icon={'typcn:weather-stormy'} width={24} height={16} />
                  </IconButton>
                </Tooltip>
              }
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer' }} color="info.main">
              <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
            </Typography>
          </Grid>
          {open && (
            <Box sx={{ ml: 3 }}>
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 1.5 }}>
                {/* Need backend for address */}
                {/* <Typography variant="body2" sx={{ py: 0.5 }}>
                  <Iconify
                    sx={{ mr: 0.5 }}
                    
                    icon="fa6-solid:address-card"
                    width={16}
                    height={16}
                    color="secondary"
                  />
                  Address:
                </Typography> */}

                <ScopeGuard scopes={['Edit-Order']} allowAdmin>
                  <Iconify sx={{ mr: 0.5 }} icon="fa6-regular:address-card" width={16} height={16} color="secondary" />
                  <Box sx={{ ml: 3, mt: -2 }}>
                    <Typography sx={{ py: 0.5 }} variant="body2" component="span">
                      {altAddress?.street && (
                        <Typography variant="caption" color="textSecondary" display="block">
                          {altAddress?.street}
                        </Typography>
                      )}
                    </Typography>
                    {(altAddress?.city || altAddress?.state || altAddress?.zip) && (
                      <Typography variant="caption" color="textSecondary" display="block">
                        {`${altAddress?.city || ''} ${altAddress?.state || ''} ${altAddress?.zip || ''}`.trim()}
                      </Typography>
                    )}
                    {altAddress?.phoneNumber && (
                      <Typography variant="caption" color="textSecondary" display="block">
                        {altAddress?.phoneNumber}
                      </Typography>
                    )}
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="secondary.main"
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={handleClickOpen}
                    >
                      Edit Delivery Address
                    </Typography>
                  </Box>
                </ScopeGuard>

                <Typography variant="body2" sx={{ py: 0.5 }}>
                  <Iconify sx={{ mr: 0.5 }} icon="mingcute:bed-line" width={16} height={16} color="secondary" />
                  Beds: {facilityBedCount}
                </Typography>

                <Typography variant="body2" sx={{ py: 0.5 }}>
                  <Iconify
                    sx={{ mr: 0.5 }}
                    icon={categoryIconLookup[department?.toLowerCase()] || 'mdi:calculator'}
                    width={16}
                    height={16}
                    color="secondary"
                  />
                  Department: {department}
                </Typography>

                <Box
                  sx={{
                    display: 'flex', // Use flexbox
                    alignItems: 'flex-bottom', // Vertically align items to center
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography sx={{ mt: 0.75, mr: 0.5 }} component="span" variant="body2">
                    <Iconify icon="mdi:paper-outline" width={16} height={16} color="secondary" />
                  </Typography>
                  <Typography
                    sx={{
                      py: 0.5,
                    }}
                    component="span"
                    variant="body2"
                  >
                    Submitted by:
                  </Typography>
                  <Tooltip title={copied ? `Copied!` : `Copy ${email || ''} to clipboard`}>
                    <Typography
                      component="span"
                      sx={{
                        ml: 0.5,
                        textDecoration: 'underline',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      variant="body2"
                      onClick={() => {
                        navigator.clipboard.writeText(email);
                        setCopied(true);
                      }}
                    >
                      {name} <Iconify icon={'ph:copy'} sx={{ pt: 0.5 }} />
                    </Typography>
                  </Tooltip>
                </Box>

                <Typography sx={{ py: 0.5 }} variant="body2" component="span">
                  <Iconify sx={{ mr: 0.5 }} icon="clarity:calendar-line" width={16} height={16} color="secondary" />
                  Submitted on: {fDateTime(dateAdded)}
                </Typography>
                <Typography sx={{ py: 0.5 }} variant="body2">
                  <Iconify sx={{ mr: 0.5 }} icon="mdi:user-outline" width={16} height={16} color="secondary" />
                  On behalf of: {onBehalfOf}
                </Typography>

                {/* <Divider sx={{ width: '100%' }} />
                <Typography variant="body2">Occupied by: </Typography>
                <Box>
                  <>
                    <CustomAvatarGroup max={100} size="small">
                      {occupiedBy?.map((participant, index) => (
                        <CustomAvatar name={participant} index={index} key={index} />
                      ))}
                    </CustomAvatarGroup>
                  </>
                </Box> */}
              </Grid>
            </Box>
          )}
        </Card>
      </Box>
      <AltAddressDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        handleClose={handleClose}
        altAddress={altAddress}
        setAltAddress={setAltAddress}
        handleCloseDialog={handleCloseDialog}
      />
    </>
  );
}
