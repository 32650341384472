import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Button, Paper } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector } from '../../../../redux/store';
import { VENDORS } from '../../../../reusable-components/datagrid/vendorColumns';
import { HOST_API_URL } from '../../../../config-global';
import Iconify from '../../../../reusable-components/iconify';
import NewVendorModal from './NewVendorModal';

VendorsTab.propTypes = {
  setTab: PropTypes.func,
  setSelectedRow: PropTypes.func,
};

export default function VendorsTab({ setSelectedRow, setTab }) {
  const {
    data: { vendors },
  } = useSelector((state) => state.vendors);
  const isLoading = useSelector((state) => state.vendors.isLoading);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(vendors);
  }, [vendors]);

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <NewVendorModal />
              <Button
                variant="outlined"
                sx={{ mr: 1 }}
                size="small"
                color="secondary"
                onClick={() => {
                  window.open(`${HOST_API_URL}Api/Vendor/DownloadVendors`, '_blank');
                }}
                startIcon={<Iconify icon="file-icons:microsoft-excel" />}
              >
                Download
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    ),
    []
  );

  return (
    <Paper sx={{ px: 2, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-vendors-vendors-tab"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={data}
          gridColumns={VENDORS}
          sort={[{ field: 'name', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          onRowClick={(row) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            setSelectedRow(row.row);
            setTab(1);
          }}
          isModal
        />
      </Grid>
    </Paper>
  );
}
