import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { AvatarGroup } from '@mui/material';

// ----------------------------------------------------------------------

const SIZES = {
  tiny: 16,
  small: 32,
  medium: 40,
  large: 56,
};

const CustomAvatarGroup = forwardRef(({ size = 'small', max = 100, children, sx, ...other }, ref) => {
  const isTiny = size === 'tiny';

  const isSmall = size === 'small';

  const isMedium = size === 'medium';

  const isLarge = size === 'large';

  return (
    <AvatarGroup
      ref={ref}
      max={max}
      spacing={((isTiny || isSmall) && 'medium') || 'small'}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiAvatar-root': {
          ...(isLarge && {
            width: SIZES.large,
            height: SIZES.large,
            '&:first-of-type': { fontSize: 16 },
          }),
          ...(isMedium && {
            width: SIZES.medium,
            height: SIZES.medium,
          }),
          ...(isSmall && {
            width: SIZES.small,
            height: SIZES.small,
            fontSize: 14,
          }),
          ...(isTiny && {
            width: SIZES.tiny,
            height: SIZES.tiny,
          }),
          position: 'relative', // make avatars relatively positioned
          marginLeft: '-8px', // negative margin for overlap (adjust as needed)
          zIndex: (index) => index, // to ensure correct stacking order
        },
        ...sx,
      }}
      {...other}
    >
      {children}
    </AvatarGroup>
  );
});

CustomAvatarGroup.propTypes = {
  sx: PropTypes.object,
  max: PropTypes.number,
  children: PropTypes.node,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
};

export default CustomAvatarGroup;
