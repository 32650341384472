import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import { WarehouseView } from '../../../components/dashboard/admin/warehouse';
import { getCatalog } from '../../../redux/slices/warehouse';

export default function Warehouse() {
  const dispatch = useDispatch();
  const {
    data: { products },
  } = useSelector((state) => state.warehouse);

  useEffect(() => {
    if (!products?.length) dispatch(getCatalog());
  }, [products, dispatch]);

  return (
    <>
      <Helmet>
        <title>Admin Item Warehouse | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <WarehouseView />
        </Grid>
      </Grid>
    </>
  );
}
