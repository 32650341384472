import PropTypes from 'prop-types';
import { useState } from 'react';
import { Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AccessTimeFilled } from '@mui/icons-material';
import { postTicketBoomerang } from '../../../../api/tickets';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { inOneHour, inThreeDays, tomorrow } from './RemindMeTimers';
import { fDateTime } from '../../../../utils/formatTime';

TicketQuickSetRemindMe.propTypes = {
  params: PropTypes.object,
  unpinTicket: PropTypes.func,
};

export default function TicketQuickSetRemindMe({ params, unpinTicket }) {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showCustomBoomerangDate, setShowCustomBoomerangDate] = useState(!!params.row.boomerangOn);
  const [removeBoomerangTooltip, setRemoveBoomerangTooltip] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const close = () => setMenuAnchor(null);

  const updateRemindMe = async (date) => {
    try {
      const response = await postTicketBoomerang({ date, ticketId: params.row.id });
      if (response.status === 200 || (response.status === 204 && !date)) {
        unpinTicket(date);
        if (enqueueSnackbar) enqueueSnackbar('Reminder set successfully', { variant: 'success' });
      } else if (enqueueSnackbar) {
        enqueueSnackbar('Failed to set reminder', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to set reminder', { variant: 'error' });
    }
    close();
  };

  return (
    <>
      <Tooltip
        arrow
        title={params.row.boomerangOn ? `Reminder set for ${fDateTime(params.row.boomerangOn)}` : 'Remind me'}
      >
        <IconButton
          color={params.row.boomerangOn ? 'info' : 'secondary'}
          onClick={(event) => {
            event.stopPropagation();
            setMenuAnchor(event.currentTarget);
          }}
          sx={{ opacity: 0.7, '&:hover': { opacity: 1 } }}
        >
          <AccessTimeFilled />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={close}>
        <MenuItem style={{ opacity: 1 }} disabled>
          <Typography>Remind me...</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => updateRemindMe(inOneHour())}>In one hour</MenuItem>
        <MenuItem onClick={() => updateRemindMe(tomorrow())}>Tomorrow</MenuItem>
        <MenuItem onClick={() => updateRemindMe(inThreeDays())}>In three days</MenuItem>
        <MenuItem onClick={() => setShowCustomBoomerangDate(true)}>Custom time</MenuItem>

        <Tooltip
          arrow
          title={
            params.row.boomerangOn && !removeBoomerangTooltip
              ? `Ticket will be moved above the ticket line at ${fDateTime(params.row.boomerangOn)}`
              : ''
          }
        >
          {showCustomBoomerangDate ? (
            <MenuItem>
              <DateTimePicker
                minDate={new Date()}
                slotProps={{
                  textField: { size: 'small', sx: { minWidth: 240 } },
                  actionBar: { actions: ['clear', 'cancel', 'accept'] },
                }}
                value={params.row.boomerangOn ? new Date(params.row.boomerangOn) : null}
                onAccept={(dateValue) => {
                  updateRemindMe(dateValue);
                  setRemoveBoomerangTooltip(false);
                }}
                onClose={() => setRemoveBoomerangTooltip(false)}
                onOpen={() => setRemoveBoomerangTooltip(true)}
              />
            </MenuItem>
          ) : (
            <></>
          )}
        </Tooltip>
      </Menu>
    </>
  );
}
