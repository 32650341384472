import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Badge, Avatar, Tooltip } from '@mui/material';
import { getInitials, stringToColor } from '../../utils/cssStyles';

// ----------------------------------------------------------------------

const CustomAvatar = forwardRef(
  ({ size = 'small', color, name, BadgeProps, children, sx, email, showTooltip = true, ...other }, ref) => {
    if (!name || name === '' || name === ' ') return null;
    const isTiny = size === 'tiny';
    const charAtName = getInitials(name);
    const colorByName = stringToColor(name);

    const colr = color || colorByName;

    const renderContent =
      colr === 'default' ? (
        <Tooltip
          arrow
          title={
            showTooltip ? (
              <div>
                {name && <div>{name}</div>}
                {email && <div>{email}</div>}
              </div>
            ) : (
              ''
            )
          }
          placement="top"
        >
          <Avatar
            ref={ref}
            sx={{
              '&:hover': {
                cursor: 'copy',
              },
              ...sx,
            }}
            {...other}
            onClick={() => {
              navigator.clipboard.writeText(`${email || name} `);
            }}
          >
            {isTiny ? name.split(' ')[0][0] : name && charAtName}
            {children}
          </Avatar>
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          title={
            showTooltip ? (
              <div>
                {name && <div>{name}</div>}
                {email && <div>{email}</div>}
              </div>
            ) : (
              ''
            )
          }
          placement="top"
        >
          <Avatar
            ref={ref}
            onClick={() => {
              navigator.clipboard.writeText(`${email || name} `);
            }}
            sx={{
              border: `1px solid ${colr}`,
              backgroundColor: colr,
              '&:hover': {
                cursor: 'copy',
              },
              ...sx,
            }}
            {...other}
          >
            {isTiny ? name.split(' ')[0][0] : name && charAtName}
            {children}
          </Avatar>
        </Tooltip>
      );

    return BadgeProps ? (
      <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} {...BadgeProps}>
        {renderContent}
      </Badge>
    ) : (
      renderContent
    );
  }
);

CustomAvatar.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.node,
  BadgeProps: PropTypes.object,
  showTooltip: PropTypes.bool,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  email: PropTypes.string,
};

export default CustomAvatar;
