import { Box } from '@mui/material';
import { fMonthDayYear } from '../../utils/formatTime';
import Iconify from '../iconify';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);

const facility = { field: 'facility', headerName: 'Facility', minWidth: 90, flex: 1.5, type: 'customSelect' };
const company = { field: 'company', headerName: 'Group', minWidth: 90, flex: 1.5, type: 'customSelect' };
const average = { field: 'average', headerName: 'Average', flex: 1 };
const current = { field: 'current', headerName: 'Current', flex: 1 };
const budgetDayCount = { field: 'budgetDayCount', headerName: 'Budget Day Count', flex: 1 };
const censusTotal = { field: 'censusTotal', headerName: 'Census Total', flex: 1 };

// addedBy// "System"
const addedBy = {
  field: 'addedBy',
  headerName: 'Inserted By',
  flex: 1,
  renderCell: (params) => `${params.row.addedBy}`,
};
// censusInputType "Unknown"
const censusInputType = {
  field: 'censusInputType',
  headerName: 'Type',
  minWidth: 90,
  flex: 1,
};
// date"2023-07-17T00:00:00-04:00"
const date = {
  field: 'date',
  headerName: 'Date',
  flex: 0.5,
  renderCell: (params) => renderIcon(fMonthDayYear(params.value), 'material-symbols:calendar-today-outline'),
};
// id 0
// modifiedBy null
// number 0

const FACILITIES_COLUMNS = [facility, company, current, average, budgetDayCount, censusTotal];

const FACILITY_COLUMNS = [facility, addedBy, censusInputType, date];

export { FACILITIES_COLUMNS, FACILITY_COLUMNS };
