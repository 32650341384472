import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Stack,
  Typography,
  Box,
  Button,
  TextField,
  DialogActions,
  Paper,
  Grid,
  DialogContentText,
} from '@mui/material';
import { postAddVendorFiles } from '../../../../api/vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import { AwaitButton } from '../../../../reusable-components/await-button';

NewFileModal.propTypes = {
  selectedRow: PropTypes.object,
  fileTypes: PropTypes.array,
  fetchFiles: PropTypes.func,
};

export default function NewFileModal({ fileTypes, selectedRow, fetchFiles }) {
  const fileInputRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    type: '',
  });

  const handleSave = async () => {
    const response = await postAddVendorFiles(selectedRow.id, value?.type.id, attachments);
    if (response) {
      enqueueSnackbar('Vendor files added successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to add vendor files', { variant: 'error' });
    }

    fetchFiles();
  };

  const handleClickOpen = () => {
    setValue({
      type: '',
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      type: '',
    });
    setOpen(false);
  };

  const removeFile = (file) => () => {
    setAttachments((prevAttachments) => prevAttachments.filter((x) => x !== file));
  };

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'application/pdf': [],
    },
  });

  const handleFileInputChange = (event) => {
    const maxAllowedFiles = 1;
    if (
      event.target.files.length > maxAllowedFiles ||
      event.target.files.length + attachments?.length > maxAllowedFiles
    ) {
      alert(`Only ${maxAllowedFiles} files can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    const { files } = event.target;
    const fileList = [];
    for (let i = 0; i < files.length; i++) {
      if (attachments?.some((x) => x.name === files[i].name)) {
        alert(`File ${files[i].name} already exists.`);
      } else fileList.push(files[i]);
    }
    setAttachments((prevAttachments) => {
      if (prevAttachments === undefined) {
        return fileList;
      }
      return [...prevAttachments, ...fileList];
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ mr: 1 }}
        size="small"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        Add Files
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Vendor Files
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>File Type</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                value={value?.type}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    type: newValue,
                  });
                }}
                options={fileTypes?.map((type) => ({ id: type.value, label: type.label }))}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>Files</DialogContentText>
              <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
                <input {...getInputProps()} />
                <Paper
                  elevation={isDragActive ? 6 : 1}
                  sx={{
                    p: 2,
                    bgcolor: 'background.paper',
                    color: 'info.main',
                    borderColor: 'info.main',
                    border: 1,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      bgcolor: 'background.default',
                      boxShadow: 3,
                    },
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    Click to Upload or Drag and Drop
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Stack
              direction="row"
              alignItems="flex-start"
              sx={{
                pl: 4,
              }}
            >
              {attachments?.map((file) => (
                <FileThumbnail
                  key={file.name}
                  file={file}
                  tooltip
                  sx={{ width: 64, height: 64 }}
                  onRemove={removeFile(file)}
                />
              ))}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value?.type === '' || attachments?.length === 0}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
    </>
  );
}
