import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
// ----------------------------------------------------------------------

const MinimalLogo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  // -------------------------------------------------------

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 200,
        ...sx,
      }}
      {...other}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="150 90 300 350"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              fill="#414042"
              d="M305.56,318.97c-0.04-2.46-1.32-4.74-3.31-5.88l-24.53-14.07c-3.92-2.25-8.54,0.72-8.55,5.53l-0.15,72.73    l37.75,21.25L305.56,318.97z"
            />
            <path
              fill="#6D6E71"
              d="M269,388.87c-0.01,2.68,1.33,5.12,3.44,6.29l25.74,14.31c4.07,2.26,8.75-1.23,8.67-6.44l-0.03-2.09    l-37.79-21.27L269,388.87z M283.52,394.99c0.53-0.91,1.71-1.23,2.62-0.7l4.05,2.34c0.91,0.53,1.23,1.71,0.7,2.62    c-0.53,0.91-1.71,1.23-2.62,0.7l-4.05-2.34C283.31,397.08,282.99,395.91,283.52,394.99z"
            />
            <path
              fill="#8DC63F"
              d="M342.69,315.36c-0.09-5.75-0.31-11.5-0.25-17.25c0.02-1.86-0.31-3.4-1.07-4.71    c-0.01-0.02-0.02-0.05-0.03-0.07l-0.01,0.01c-0.72-1.21-1.81-2.22-3.39-3.09c-10.3-5.69-20.49-11.58-30.65-17.52    c-2.69-1.58-4.92-1.46-7.5,0.1c-6.86,4.16-13.76,8.26-20.8,12.12c-4.3,2.36-3.51,4,0.23,6.09c9.79,5.49,19.45,11.23,29.29,16.61    c1.59,0.87,2.67,1.85,3.39,3.03c0.14,0.23,0.26,0.48,0.37,0.73c0.04,0.1,0.09,0.19,0.13,0.29c0.1,0.26,0.19,0.54,0.26,0.82    c0.03,0.1,0.06,0.2,0.09,0.3c0.07,0.3,0.11,0.62,0.15,0.94c0.01,0.1,0.04,0.2,0.05,0.31c0.04,0.43,0.07,0.88,0.07,1.36    c0.01,11.14,0.34,32.48,0.47,43.62c0.04,3.62,1.3,4.62,4.55,2.69c3.48-2.07,6.97-4.13,10.47-6.17l-0.22-7.78    c-0.04-1.54,0.74-2.99,2.04-3.79l0.24-0.15c0.91-0.56,2.02-0.58,2.97-0.06c0.95,0.52,1.53,1.47,1.56,2.55l0.15,5.33    c1.5-0.87,3-1.74,4.5-2.6c2.5-1.43,3.3-3.53,3.23-6.25c-0.05-1.9-0.09-4.92-0.12-8.31c0.1-0.59,0.14-1.22,0.12-1.89    C342.85,326.86,342.78,321.11,342.69,315.36z"
            />
            <path
              fill="#414042"
              d="M333.99,352.4l-0.17-6.04c-0.02-0.61-0.35-1.15-0.89-1.44c-0.25-0.14-0.54-0.21-0.81-0.21    c-0.3,0-0.6,0.09-0.86,0.25l-0.24,0.15c-0.91,0.56-1.46,1.58-1.43,2.66l0.2,7.07l0.08,2.81c0.01,0.42,0.24,0.79,0.61,1    c0.37,0.2,0.8,0.2,1.15-0.02l1.48-0.91c0.62-0.38,1-1.08,0.98-1.82L333.99,352.4z M331.37,346.41c0.59-0.4,1.26-0.16,1.5,0.52    s-0.04,1.56-0.62,1.95c-0.59,0.4-1.26,0.16-1.5-0.52C330.5,347.68,330.78,346.81,331.37,346.41z"
            />
            <path
              fill="#FFFFFF"
              d="M284.22,397.61l4.05,2.34c0.91,0.53,2.09,0.21,2.62-0.7c0.53-0.91,0.21-2.09-0.7-2.62l-4.05-2.34    c-0.91-0.53-2.09-0.21-2.62,0.7C282.99,395.91,283.31,397.08,284.22,397.61z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

MinimalLogo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default MinimalLogo;
