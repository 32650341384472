import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSelector } from '../../../redux/store';
import { ACTIVE_TICKETS } from '../../../reusable-components/datagrid/ticketColumns';
import { SkeletonDataGrid } from '../../../reusable-components/skeleton/SkeletonDataGrid';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../reusable-components/snackbar';
import CustomToolbar from './CustomToolbar';
import { postPinTicket, postMarkTicketAsNeedsResponse, postSetTicketMessagesRead } from '../../../api/tickets';
import { getActiveTickets, updateSingleTicket } from '../../../redux/slices/tickets';
import { getUnreadData } from '../../../redux/slices/dashboard';
import { createColumns, CustomFilter } from '../../../reusable-components/datagrid/custom-filters';
import { useFilterModel } from '../../../hooks/useFilterModel';
import { ScopeGuard, useScopeCheck } from '../../../reusable-components/scopes';
import { useTabs } from '../../../context/TabContext';
import ManagerChipsWrapper from '../../../reusable-components/datagrid/ManagerChipsWrapper';
import Iconify from '../../../reusable-components/iconify';
import UrgentToggle from '../../../reusable-components/datagrid/UrgentToggle';
import PinToggle from '../../../reusable-components/datagrid/PinToggle';
import TicketQuickSetRemindMe from './ticket/TicketQuickSetRemindMe';

export default function OpenTickets() {
  const hasPinnedGridAccess = useScopeCheck(['Facility-Manager']);
  const isFacilityManager = useScopeCheck(['Facility-Manager']);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { activeTab } = useTabs();
  const { addNewTabAndNavigate } = useTabs();

  const [searchParams] = useSearchParams();
  const [showSelection, setShowSelection] = useState('');
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [lastPinnedId, setLastPinnedId] = useState(null);
  const [selected, setSelected] = useState([]);
  const [sortModel, setSortModel] = useState([{}]);

  const [firstUnpinnedIndex, setFirstUnpinnedIndex] = useState(null);
  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const sessionStorageKey = `tab-${activeTab.key}-filters-${baseUrl}`;
  const loadFiltersFromSession = () => {
    const savedFilters = sessionStorage.getItem(sessionStorageKey);
    return savedFilters ? JSON.parse(savedFilters) : {};
  };
  const [filters, setFilters] = useState(loadFiltersFromSession());
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [quickFilter, setQuickFilter] = useState('');

  const urgent = {
    field: 'respondedTo',
    headerName: 'Urgent',
    renderHeader: () => (
      <>
        <Tooltip title="No manager activity in 24 hours" placement="top" arrow>
          <Iconify icon="fa-solid:exclamation" width={12} height={12} />
        </Tooltip>
      </>
    ),
    width: 1,
    alignItems: 'left',
    renderCell: (params) => <UrgentToggle value={params.value} />,
  };

  // const ticketCols = ACTIVE_TICKETS?.toSpliced(
  //   6,
  //   0,
  //   ManagerChipsWrapper(() => dispatch(getActiveTickets()), 'Tickets', {
  //     flex: 3,
  //     minWidth: 180,
  //     onCellClick: (params) => params.event.stopPropagation(),
  //   })
  // );

  const actions = {
    field: 'actions',
    headerName: '',
    width: 100,
    renderCell: (params) => (
      <>
        <PinToggle value={params.row.aboveLine} />
        <TicketQuickSetRemindMe
          params={params}
          unpinTicket={(date) => dispatch(updateSingleTicket({ ...params.row, boomerangOn: date, aboveLine: false }))}
        />
      </>
    ),
    type: 'boolean',
    filterable: false,
  };

  let ticketCols = isFacilityManager ? [...ACTIVE_TICKETS, actions] : [...ACTIVE_TICKETS]; // Copying the original array

  // Insert ManagerChipsWrapper for everyone at a specific index
  ticketCols = ticketCols?.toSpliced(
    6, // Inserting at index 6
    0, // Do not remove any elements
    ManagerChipsWrapper(() => dispatch(getActiveTickets()), 'Tickets', {
      flex: 3,
      minWidth: 180,
      onCellClick: (params) => params.event.stopPropagation(),
    })
  );

  // Conditionally add the urgent configuration at index 1 if isFacilityManager
  if (isFacilityManager) {
    ticketCols = ticketCols?.toSpliced(
      1, // Insert at index 1
      0, // Do not remove any elements
      urgent // Add the urgent configuration
    );
  }

  const { data: { activeTickets }, isLoading } = useSelector((state) => state.tickets);

  const handlePin = async (ticketId, action) => {
    try {
      const { data } = await postPinTicket({ ticketId });
      if (data) {
        enqueueSnackbar(`Ticket ${ticketId} ${action === 'pinned' ? 'pinned' : 'unpinned'} successfully`, {
          variant: 'success',
        });
        dispatch(updateSingleTicket(data));
      }
    } catch (error) {
      enqueueSnackbar(`Error: Could not ${action} ticket ${ticketId}`, {
        variant: 'error',
      });
    }
  };

  const handleMarkAsNeededResponse = async (ticketId, action) => {
    try {
      const { data } = await postMarkTicketAsNeedsResponse({ ticketId });
      if (data) {
        dispatch(updateSingleTicket(data));
      }
    } catch (error) {
      enqueueSnackbar(`Error: Could not ${action} label to ticket ${ticketId}`, {
        variant: 'error',
      });
    }
  };
  const handleMarkAsRead = async (ticketId, action) => {
    try {
      const response = await postSetTicketMessagesRead({ id: ticketId, markAsUnread: action });
      if (response?.status === 200) {
        dispatch(
          updateSingleTicket({
            id: ticketId,
            messageCount: action ? 1 : 0,
          })
        );
        dispatch(getUnreadData());
      }
    } catch (error) {
      enqueueSnackbar(`Error: Could not ${action} label to ticket ${ticketId}`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    let rawData = [];
    rawData = activeTickets;
    setColumns(ticketCols || []);

    const pinnedData = rawData.filter((row) => row.aboveLine === true);
    const unpinnedData = rawData.filter((row) => row.aboveLine !== true);

    // You might have other sorting logic for unpinnedData.
    // Just make sure to sort unpinnedData after the filtering.
    // unpinnedData.sort(yourSortFunction);

    setFirstUnpinnedIndex(pinnedData.length);
    setLastPinnedId(pinnedData[pinnedData.length - 1]?.id);

    // Always concat pinnedData first
    setData(pinnedData.concat(unpinnedData));
  }, [searchParams, activeTickets, lastPinnedId]);

  useEffect(() => {
    const handleMouseMove = (event) => handleDrag(event);
    const handleMouseUp = () => handleDragEnd();

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleStateChange = (params) => {
    if (params.filter.filterModel.quickFilterValues?.length) {
      const pinnedData = data.filter((row) => row.aboveLine === true);
      const unpinnedData = data.filter((row) => row.aboveLine !== true);

      setData([...pinnedData, ...unpinnedData]);
    }
  };

  const customToolbar = useCallback(() => <CustomToolbar
    setShowSelection={setShowSelection}
    showSelection={showSelection}
    selected={selected}
    setQuickFilter={setQuickFilter}
    setFilters={setFilters}
    setActiveFilter={setActiveFilter}
    filter={searchParams.get('filter')}
  />,[selected]);

  const customLoad = () => <SkeletonDataGrid shortGrid />
  
  const rowClick = (id) => {
    navigate(`/dashboard/tickets/${id}`);
  };

  const fieldValues = useMemo(() => {
    const values = {};

    columns.forEach((col) => {
      if (col?.type === 'customSelect' && data) {
        values[col.field] = Array.from(new Set(data.map((row) => row[col.field])))
          .filter((value) => value !== undefined && value !== null && value !== '')
          .map((value) => ({ value }));
      }
    });

    return values;
  }, [columns, data]);

  const dataColumns = createColumns(columns, filters, setAnchorEl, setActiveFilter);
  const filterModel = useFilterModel(filters);
  const filterRows = (rows, quickFilter) => {
    if (!quickFilter) return rows;
    const normalizeString = (str) => str?.replace(/[^a-z0-9\s]/gi, '')?.toLowerCase();
    const quickFilterWords = normalizeString(quickFilter).split(/\s+/);
    if (!quickFilterWords.length) return rows;
    return rows.filter((row) =>
      quickFilterWords.every((word) =>
        columns.some((col) => {
          const value = col?.valueGetter ? col.valueGetter({ row }) : row[col.field];
          if (Array.isArray(value)) {
            return value.some((element) => normalizeString(String(element))?.includes(word));
          }
          return normalizeString(String(value))?.includes(word);
        })
      )
    );
  };
  const pinnedData = data?.slice(0, firstUnpinnedIndex);
  const unpinnedData = data?.slice(firstUnpinnedIndex);
  const [topGridHeight, setTopGridHeight] = useState(400);
  const dragPositionRef = useRef(null);

  const handleDragStart = (event) => {
    dragPositionRef.current = event.clientY;
  };

  const handleDrag = (event) => {
    if (!dragPositionRef.current) return;

    const deltaY = event.clientY - dragPositionRef.current;
    setTopGridHeight((prevHeight) => Math.max(100, prevHeight + deltaY)); // Minimum height constraint
    dragPositionRef.current = event.clientY;
  };

  const handleDragEnd = () => {
    dragPositionRef.current = null;
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            width: 'calc(100% - 10px)',
            height: 'calc(100vh - 205px)',
          }}
        >
          <>
            <ScopeGuard scopes={['Facility-Manager']}>
              <PinnedDataGrid
                style={{ height: `${topGridHeight}px` }}
                getRowHeight={() => 'auto'}
                rows={filterRows(pinnedData, quickFilter, dataColumns)}
                columns={dataColumns}
                onStateChange={handleStateChange}
                getRowId={(row) => row.id}
                disableSelectionOnClick
                checkboxSelection={!!showSelection}
                getRowClassName={(params) =>
                  data?.findIndex((row) => row.id === params.id) === firstUnpinnedIndex ? 'row-divider' : ''
                }
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection);
                }}
                onRowClick={(params, e) => {
                  const selection = window.getSelection().toString();
                  if (selection.length > 0) return;
                  if (e.deleteChip || e.addChip) return;
                  if (e.navigateToOrders) {
                    addNewTabAndNavigate(`/dashboard/orders/${e.navigateToOrders}`);
                    return;
                  }
                  if (e.removePin || e.addPin) {
                    handlePin(params.id, e.addPin ? 'pinned' : 'unpinned');
                  } else if (e.unread || e.read) {
                    handleMarkAsRead(params.id, !e.read);
                  } else if (e.urgent) {
                    handleMarkAsNeededResponse(params.id);
                  } else rowClick(params.row.id);
                }}
                loading={isLoading}
                components={{
                  Toolbar: customToolbar,
                  LoadingOverlay: customLoad,
                }}
                filterModel={filterModel}
                sortModel={sortModel}
                onSortModelChange={(model) => {
                  setSortModel(model);
                }}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableColumnPinning
                disableColumnReorder
              />
              <Box
                sx={{
                  width: 'calc(100% - 6px)',
                  height: '8px',
                  backgroundColor: '#0078d4',
                  my: 1,
                  cursor: 'row-resize',
                }}
                onMouseDown={handleDragStart}
              />
            </ScopeGuard>
            {hasPinnedGridAccess ? (
              <StyledGrid
                getRowHeight={() => 'auto'}
                style={{
                  height: isFacilityManager ? `calc(100vh - 180px - ${topGridHeight}px - 10px)` : 'calc(100vh - 180px)',
                }}
                rows={filterRows(unpinnedData, quickFilter, dataColumns)}
                columns={dataColumns}
                onStateChange={handleStateChange}
                getRowId={(row) => row.id}
                disableSelectionOnClick
                checkboxSelection={!!showSelection}
                getRowClassName={(params) =>
                  data?.findIndex((row) => row.id === params.id) === firstUnpinnedIndex ? 'row-divider' : ''
                }
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection);
                }}
                onRowClick={(params, e) => {
                  const selection = window.getSelection().toString();
                  if (selection.length > 0) return;
                  if (e.deleteChip || e.addChip) return;
                  if (e.navigateToOrders) {
                    addNewTabAndNavigate(`/dashboard/orders/${e.navigateToOrders}`);
                    return;
                  }
                  if (e.removePin || e.addPin) {
                    handlePin(params.id, e.addPin ? 'pinned' : 'unpinned');
                  } else if (e.unread || e.read) {
                    handleMarkAsRead(params.id, !e.read);
                  } else if (e.urgent) {
                    handleMarkAsNeededResponse(params.id);
                  } else rowClick(params.row.id);
                }}
                loading={isLoading}
                components={{
                  Toolbar: !hasPinnedGridAccess && customToolbar,
                  LoadingOverlay: SkeletonDataGrid,
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => {
                  setSortModel(model);
                }}
                filterModel={filterModel}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableColumnPinning
                disableColumnReorder
              />
            ) : (
              <CustomDataGrid
                gridId="open-tickets"
                data={unpinnedData}
                gridColumns={ticketCols || []}
                CustomLeftToolbar={customToolbar}
                sortModel={sortModel}
                onSortModelChange={(model) => {
                  setSortModel(model);
                }}
                isLoading={isLoading}
                onRowClick={(params, e) => {
                  const selection = window.getSelection().toString();
                  if (selection.length > 0) return;
                  if (e.deleteChip || e.addChip) return;
                  if (e.navigateToOrders) {
                    addNewTabAndNavigate(`/dashboard/orders/${e.navigateToOrders}`);
                    return;
                  }
                  if (e.removePin || e.addPin) {
                    handlePin(params.id, e.addPin ? 'pinned' : 'unpinned');
                  } else if (e.unread || e.read) {
                    handleMarkAsRead(params.id, !e.read);
                  } else if (e.urgent) {
                    handleMarkAsNeededResponse(params.id);
                  } else rowClick(params.row.id);
                }}
              />
            )}
          </>
        </Box>
        <CustomFilter
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
          filters={filters}
          setFilters={setFilters}
          fieldValues={fieldValues}
          columns={columns}
        />
      </Grid>
    </>
  );
}
const PinnedDataGrid = styled(DataGridPro)(({ theme }) => ({
  // '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
  borderBottom: '1px solid #0078d4',
}));
const StyledGrid = styled(DataGridPro)(() => ({
  borderRadius: '0px',
  '& .MuiDataGrid-columnHeaders': { display: 'none' },
  //row border radius
  '& .MuiDataGrid-main': {
    borderRadius: '0px',
  },
}));
