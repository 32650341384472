import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  TextField,
  DialogActions,
  DialogContentText,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import {
  getCheckCategoryHasSubcategory,
  postDeleteCategory,
  postDeletePurchasingSubcategory,
} from '../../../../api/category';
import { AwaitButton } from '../../../../reusable-components/await-button';

DeleteCategory.propTypes = {
  row: PropTypes.object,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  fetchData: PropTypes.func,
};

export default function DeleteCategory({ row, categories, fetchData, subcategories }) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);

  const checkHasSubcategory = async () => {
    if (!row.mainCategoryId) {
      const response = await getCheckCategoryHasSubcategory(row.id);
      if (response.data) {
        enqueueSnackbar('You cannot delete a main category while it has subcategories', { variant: 'error' });
        return;
      }
      setOpen(true);
    } else {
      setOpen(true);
      setCategory(row?.mainCategoryId ? categories.find((c) => c.id === row.mainCategoryId) : null);
    }
  };

  const handleClickOpen = () => {
    checkHasSubcategory();
  };
  const handleClose = () => {
    setOpen(false);
    setCategory(null);
  };

  const handleDelete = async () => {
    if (row.mainCategoryId) {
      await postDeletePurchasingSubcategory({
        id: row.subId,
        substituteSubcategoryId: subCategory.id,
        substituteCategoryId: category.id,
      });
    } else {
      await postDeleteCategory(row.id);
    }
    fetchData();
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <DeleteOutlineIcon color="error" />
      </IconButton>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Delete {row?.mainCategoryId ? 'Subcategory' : 'Category'}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          {row.mainCategoryId ? (
            <>
              <Typography>Switch existing items from {row.name} *</Typography>
              <DialogContentText sx={{ mt: 2, fontWeight: 'bold' }}>Main Category</DialogContentText>
              <Autocomplete
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': 'none',
                }}
                size="small"
                fullWidth
                type="new-password"
                options={categories}
                autoHighlight
                getOptionLabel={(option) => option?.name}
                value={category}
                onChange={(event, newValue) => {
                  setCategory(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />
              <DialogContentText sx={{ mt: 2, fontWeight: 'bold' }}>Subcategory</DialogContentText>
              <Autocomplete
                sx={{
                  width: '100%',
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': 'none',
                }}
                size="small"
                type="new-password"
                options={subcategories.filter((s) => s.mainCategoryId === category?.id)}
                getOptionDisabled={(option) => option.id === row.subId}
                autoHighlight
                getOptionLabel={(option) => option?.name}
                value={subCategory}
                onChange={(event, newValue) => {
                  setSubCategory(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />
            </>
          ) : (
            <>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>
                Are you sure you want to delete {row.name}
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <AwaitButton
            variant="outlined"
            color="error"
            size="medium"
            sx={{ width: 150, mr: 2 }}
            onClick={handleDelete}
            disabled={row.mainCategoryId && (!category || !subCategory)}
          >
            Delete
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
