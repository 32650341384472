import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import { CensusView } from '../../../components/dashboard/admin';

export default function Census() {
  return (
    <>
      <Helmet>
        <title>Admin Census | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <CensusView />
        </Grid>
      </Grid>
    </>
  );
}
