import * as React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import { useSelector } from '../../../../redux/store';

export default function RentalCategoryDropdown() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const facilityId = searchParams.get('facilityId');
  const residentId = searchParams.get('residentId');
  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);

  const handleCategoryClick = (event) => {
    navigate(
      `/dashboard/rentals/new-rental?facilityId=${facilityId}&category=${event.target.value}&residentId=${residentId}`
    );
  };
  const uniqueCategories = categoryData.reduce((acc, category) => {
    if (!acc.find((item) => item.categoryName === category.categoryName)) {
      acc.push(category);
    }
    return acc;
  }, []);

  return (
    <>
      <Select
        label="Select Category"
        value={category}
        onChange={handleCategoryClick}
        sx={{
          color: '#8cc53f',
          fontWeight: '15px',
          '& .MuiSelect-icon': {
            color: '#8cc53f',
          },
        }}
      >
        {uniqueCategories.map((category) => (
          <MenuItem key={category.label} value={category.categoryName}>
            {category.categoryName}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
