import { createSlice } from '@reduxjs/toolkit';
import {
  getBudgetData,
  postCreateGLCode,
  postDeleteBudget,
  getBudgetsByFacility,
  postCopyBudget,
  postBudgetForm,
} from '../../api/budgets';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    agoraCategories: [],
    agoraSubcategories: [],
    budgets: [],
    facilities: [],
    glCodes: [],
  },
};

const slice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getBudgetSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    deleteBudgetSuccess(state, action) {
      state.isLoading = false;
      state.data.budgets = state.data.budgets.filter((item) => item.id !== action.payload);
    },
    updateBudgetSuccess(state, action) {
      state.isLoading = false;
      const updatedBudget = action.payload.budget;
      state.data.budgets = state.data.budgets.map((item) => (item.id === updatedBudget.id ? updatedBudget : item));
    },
    insertBudgetSuccess(state, action) {
      state.isLoading = false;
      const newBudget = action.payload.budget;
      state.data.budgets = [...state.data.budgets, newBudget];
    },
    copyBudgetSuccess(state, action) {
      state.isLoading = false;
      const newBudgets = action.payload;
      state.data.budgets = [...state.data.budgets, ...newBudgets];
    },
    insertGlCodeSuccess(state, action) {
      state.isLoading = false;
      const newGlCode = action.payload;
      state.data.glCodes = [...state.data.glCodes, newGlCode];
    },
  },
});

export default slice.reducer;

export function getBudget() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getBudgetData();
      dispatch(slice.actions.getBudgetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return null;
  };
}

export function deleteBudget(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postDeleteBudget(id);
      if (response.status === 200) {
        dispatch(slice.actions.deleteBudgetSuccess(id));
        return true;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return null;
  };
}
export function budgetForm(form, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postBudgetForm(form, id);
      if (response.status === 200) {
        const updatedBudget = response.data;
        if (id > 0) dispatch(slice.actions.updateBudgetSuccess(updatedBudget));
        else dispatch(slice.actions.insertBudgetSuccess(updatedBudget));
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    return null;
  };
}
export function copyBudget(facilityId, facilityCopyId, facilityCopyGLId, agoraCategoryIds) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postCopyBudget(facilityId, facilityCopyId, facilityCopyGLId, agoraCategoryIds);
      if (response.status === 200) {
        const newBudget = response.data;
        dispatch(slice.actions.copyBudgetSuccess(newBudget));
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return null;
  };
}

export function createGlCode(glCode, facilityBookingId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postCreateGLCode(glCode, facilityBookingId);
      if (response.status === 200) {
        dispatch(
          slice.actions.insertGlCodeSuccess({
            id: response.data,
            label: glCode,
            facilityBookingTypeId: facilityBookingId,
          })
        );
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
