import { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete, createFilterOptions,
  Typography,
  Button,
  TextField,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
  DialogContentText,
  Grid,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from '../../../../redux/store';
import { postFacilityVendorAccountForm, getAccountExtraFieldsData } from '../../../../api/vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { HOST_API_URL } from '../../../../config-global';

FacilityAccountModal.propTypes = {
  fetchFacilityData: PropTypes.func,
  edit: PropTypes.bool,
  row: PropTypes.object,
};

export default function FacilityAccountModal({ fetchFacilityData, edit, row }) {
  const {
    data: { vendors, facilities },
  } = useSelector((state) => state.vendors);
  const [open, setOpen] = useState(false);
  const [attachment, setAttachment] = useState(
    !edit
      ? null
      : row.fileName ? {
        attachment: row.fileName,
      } : null
  );
  const filter = createFilterOptions();

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    facility: '',
    vendor: '',
    account: '',
    isPPD: '',
    file: ''
  });
  const [extraFields, setExtraFields] = useState([]);

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const handleFileInputChange = (event) => {
    if (event.target.files.length > 1 || event.target.files.length + attachment?.length > 1) {
      alert(`Only 1 file can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    setAttachment(event.target.files[0]);
    setValue((prev) => ({ ...prev, file: event.target.files[0] }))
  };

  const { open: openDropzone } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const handleDeleteFile = () => {
    setAttachment();
    setValue((prev) => ({ ...prev, file: '' }))
  };

  const downloadFile = async () => {
    if (edit) {
      const link = document.createElement('a');
      link.href = `${HOST_API_URL}Api/Vendor/GetFacilityAccountPDF?id=${row.id}`;
      link.setAttribute('download', `${row.fileName}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  const handleSave = async () => {
    const response = await postFacilityVendorAccountForm(value, extraFields, edit ? row?.id : null);
    if (response.status === 200) {
      if (!response.data.error) {
        enqueueSnackbar(`Vendor account ${edit ? 'edited' : 'added'} successfully`, { variant: 'success' });
      } else {
        enqueueSnackbar(`Failed to add vendor account, - ${response.data.error} `, { variant: 'error' });
      }
      fetchFacilityData();
    } else {
      enqueueSnackbar('Failed to add vendor account', { variant: 'error' });
    }
    setOpen(false);
  };

  const getExtraFields = async () => {
    const response = await getAccountExtraFieldsData(value.vendor.id, edit ? row?.id : null);
    setExtraFields(response.data);
  };

  useEffect(() => {
    if (value.vendor && value.facility) {
      getExtraFields();
    }
  }, [value.vendor, value.facility]);

  const handleClickOpen = () => {
    setOpen(true);

    setAttachment(
      !edit
        ? null
        : row.fileName ? {
          attachment: row.fileName,
        } : null);

    setValue({
      facility: edit ? { id: row.facilityId, label: row.facility } : '',
      vendor: edit ? { id: row.vendorId, label: row.vendor } : '',
      account: edit ? row.account : '',
      paymentTerms: edit ? row.paymentTerms : '',
      isPPD: edit ? row.isPPD : '',
      accountInactive: edit ? row.accountInactive : '',
      file: !edit
        ? null
        : row.fileName ? {
          attachment: row.fileName,
        } : ''
    });
  };
  const handleClose = () => {
    setValue({
      facility: '',
      vendor: '',
      account: '',
      paymentTerms: '',
      isPPD: '',
      accountInactive: '',
      file: ''
    });
    setOpen(false);
  };


  return (
    <>
      {edit ? (
        <IconButton edge="end" color="secondary" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          size="small"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Facility Account
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {edit ? 'Edit' : 'Add'} Facility Account
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Facility Field */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Facility</Typography>
              <Autocomplete
                size="small"
                fullWidth
                value={value.facility}
                onChange={(event, newValue) => {
                  setExtraFields([]);
                  setValue((prev) => ({ ...prev, facility: newValue }));
                }}
                options={facilities.map((facility) => ({ id: facility.value, label: facility.label }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Vendor *</Typography>
              <Autocomplete
                size="small"
                fullWidth
                value={value.vendor}
                onChange={(event, newValue) => {
                  setExtraFields([]);
                  setValue((prev) => ({ ...prev, vendor: newValue }));
                }}
                options={vendors.map((vendor) => ({ id: vendor.id, label: vendor.name }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Payment Terms</Typography>
              <Autocomplete clearonBlur selectOnFocus handleHomeEndKeys
                size="small"
                fullWidth
                value={value.paymentTerms}
                onChange={(event, newValue) => {
                  setValue((prev) => ({ ...prev, paymentTerms: newValue }));
                }}
                options={["Net 7", "Net 14", "Net 15", "Net 30", "Net 60", "Net 90"]}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.label;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.label);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push(inputValue);
                  }
                  return filtered;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={value?.vendor?.label === 'Amazon' ? 'From Identity' : ''} placement="top" arrow>
                <Typography variant="subtitle1">Account Number *</Typography>
              </Tooltip>
              <TextField
                size="small"
                fullWidth
                value={value.account}
                onChange={(e) => setValue((prev) => ({ ...prev, account: e.target.value }))}
                label=""
              />
            </Grid>
            {extraFields.length > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                  {extraFields.map((field, index) => {
                    const { encrypt, hoverText, label, id, purchasingVendorId, required, type } = field;

                    return (
                      <Grid key={id} item xs={6}>
                        <Tooltip title={hoverText} placement="top" arrow>
                          <Typography variant="subtitle1">
                            {label}
                            {required ? '*' : ''}
                          </Typography>
                        </Tooltip>
                        <TextField
                          type={encrypt && 'password'}
                          size="small"
                          fullWidth
                          value={field.value}
                          onChange={(e) => {
                            const newExtraFields = [...extraFields];
                            newExtraFields[index].value = e.target.value;
                            setExtraFields(newExtraFields);
                          }}
                          label=""
                          required={required}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                sx={{ mr: 1 }}
                onClick={openDropzone}
                startIcon={<Iconify icon="material-symbols:upload" />}
              >
                Upload File
              </Button>
            </Grid>
            <Grid item>
              {attachment != null && value.file !== '' && (
                <Grid container alignItems="center">
                  <Grid item>
                    <Button
                      sx={{ mr: -3 }}
                      onClick={() => handleDeleteFile()}
                      startIcon={
                        <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
                      }
                    >
                      {' '}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography onClick={() => downloadFile()} color={'black'} sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      {attachment.attachment ?? attachment.path}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <DialogContentText>PPD</DialogContentText>
              <ToggleButtonGroup
                value={value.isPPD ? 'true' : 'false'}
                exclusive
                onChange={(event, newValue) => {
                  setValue((prev) => ({ ...prev, isPPD: newValue === 'true' }));
                }}
                aria-label="PPD selection"
              >
                <ToggleButton value="true">Yes</ToggleButton>
                <ToggleButton value="false">No</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <DialogContentText>Disabled</DialogContentText>
              <ToggleButtonGroup
                value={value.accountInactive ? 'true' : 'false'}
                exclusive
                onChange={(event, newValue) => {
                  setValue((prev) => ({ ...prev, accountInactive: newValue === 'true' }));
                }}
              >
                <ToggleButton value="true">Yes</ToggleButton>
                <ToggleButton value="false">No</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" variant="outlined">
            Cancel
          </Button>
          <AwaitButton
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={
              !value.vendor ||
              !value.account ||
              extraFields.some((field) => field.required && !field.value)
            }
          >
            {edit ? 'Save' : 'Add Account'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
