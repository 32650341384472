import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography, Checkbox } from '@mui/material';
import { updateUserSSO } from '../../../../../api/user';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../../reusable-components/await-button';

SSOTab.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  user: PropTypes.object,
};

export default function SSOTab({ edit, setEdit, user }) {
  const { enqueueSnackbar } = useSnackbar();

  const getFormDataFromUser = () => ({
    ssoPrimaryId: user?.ssoUser?.ssoPrimaryId || '',
    ssoAlternateId: user?.ssoUser?.ssoAlternateId || '',
    enabled: user?.ssoUser?.enabled || false,
    id: user?.id,
  });

  const [value, setValue] = useState(getFormDataFromUser());

  useEffect(() => { setValue(getFormDataFromUser()); }, [user]);

  const onSubmit = async () => { 
    try {
      const response = await updateUserSSO(value);
      if (response.data) enqueueSnackbar('User SSO information updated', { variant: 'success' });
      else enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
    catch { enqueueSnackbar('Something went wrong', { variant: 'error' }) }
    finally { setEdit(false); }
  };

  const handleCancel = () => {
    setValue(getFormDataFromUser());
    setEdit(false);
  };

  return (
    <Grid sx={{ mt: 3 }}>
      <Typography variant="subtitle1" >        
        SSO Enabled 
        <Checkbox checked={value.enabled} onChange={(e) => setValue({ ...value, enabled: e.target.checked })} disabled={!edit} />
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        SSO Primary Identity
      </Typography>
      <TextField
        fullWidth
        placeholder="SSO Primary Identity"
        value={value.ssoPrimaryId}
        onChange={(e) => setValue({ ...value, ssoPrimaryId: e.target.value })}
        InputProps={{ readOnly: !edit }}
      />
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        SSO Alternate Identity
      </Typography>
      <TextField
        fullWidth
        placeholder="SSO Alternate Identity"
        value={value.ssoAlternateId}
        onChange={(e) => setValue({ ...value, ssoAlternateId: e.target.value })}
        InputProps={{ readOnly: !edit }}
      />
      {edit && <Grid item sx={{ mt: 4 }}>
        <Button onClick={handleCancel} variant="outlined" color="error" size="medium" sx={{ width: 200, mr: 3 }}>
          Cancel
        </Button>
        <AwaitButton color="primary" size="medium" type="submit" variant="contained" sx={{ width: 200 }} onClick={onSubmit}>
          Save
        </AwaitButton>
      </Grid>}
    </Grid>
  );
}
