import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Button, Card, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../reusable-components/iconify';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector, useDispatch } from '../../../redux/store';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { updateOrderStatus } from '../../../redux/slices/rentals';
import { RENTAL_ORDER_ITEMS } from '../../../reusable-components/datagrid/rentalColumns';
import { postIsApproveEnabled } from '../../../api/rentals';

RentalOrderItemsView.propTypes = {
  orderId: PropTypes.number,
  orderStatus: PropTypes.string,
};

export default function RentalOrderItemsView({ orderId, orderStatus }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: { pendingRentals },
  } = useSelector((state) => state.rentals);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [isApproveAllowed, setIsApproveAllowed] = useState(false);
  const dispatch = useDispatch();

  const rejectOrder = () => {
    dispatch(updateOrderStatus(orderId, 'PREVIOUS'));
    enqueueSnackbar('Rental Order Rejected', { variant: 'error' });
    navigate(`/dashboard/home`);
  };

  const approveOrder = () => {
    dispatch(updateOrderStatus(orderId, 'CURRENT'));
    enqueueSnackbar('Rental Order Approved', { variant: 'success' });
    navigate(`/dashboard/home`);
  };

  useEffect(() => {
    const filteredRows = pendingRentals.filter((item) => item.orderId === orderId);
    const uniqueRows = filteredRows.reduce((acc, current) => {
      const exists = acc.some((item) => item.orderId === current.orderId && item.itemId === current.itemId);
      if (!exists) {
        acc.push(current);
      }
      return acc;
    }, []);

    setRows(uniqueRows);
    getApproved();
    setDataLoaded(true);
  }, [pendingRentals, orderId]);

  const selectedOrder = pendingRentals.find((order) => order.orderId === orderId);

  const getApproved = async () => {
    try {
      const response = await postIsApproveEnabled(selectedOrder.facilityId);
      if (response.data.success) {
        setIsApproveAllowed(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" spacing={1} marginBottom={1}>
        <Grid item>
          <Button
            variant="text"
            onClick={() => {
              navigate(`/dashboard/rentals/${orderStatus}-rentals`);
            }}
            startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
            sx={{
              color: '#454F5B',
              fontWeight: 'bold',
            }}
          >
            Back to {orderStatus} Rentals
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" sx={{ color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}>
            Rental Order AG{orderId}
          </Typography>
        </Grid>
        {orderStatus === 'Pending' && (
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              alignItems="center"
              sx={{ padding: 1 }}
            >
              <Grid item>
                <Button variant="outlined" color="secondary" disabled={!isApproveAllowed} onClick={rejectOrder}>
                  Reject Order
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" disabled={!isApproveAllowed} onClick={approveOrder}>
                  Approve Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container direction="row">
        <Grid item sx={{ width: '80%' }}>
          <CustomDataGrid
            gridId="pending-rentals-order"
            boxSX={{ width: '100%', height: 'calc(100vh - 260px)' }}
            data={rows}
            getRowId={(row) => row.itemId}
            gridColumns={RENTAL_ORDER_ITEMS}
            isLoading={!dataLoaded}
            sort={[{ field: 'name', sort: 'asc' }]}
          />
        </Grid>
        <Grid item>
          <Divider
            orientation="vertical"
            sx={{
              border: 'dashed .3px #a5a5a6',
              opacity: '.60',
              zIndex: 1,
              ml: 2,
              mr: 2,
              height: 'calc(100vh - 240px)',
            }}
          />
        </Grid>
        <Grid item justifyContent="flex-end" sx={{ width: '16%' }}>
          <Grid container justifyContent="flex-end" direction="column" sx={{ width: '100%' }}>
            <Card sx={{ padding: 3, justifyContent: 'flex-end', alignItems: 'center', ml: 4 }}>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ mb: 2, color: '#0075db', fontWeight: 'bold', fontSize: '15px' }}>
                  Rental Request Details
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 1, color: '#0075db', fontWeight: 'bold', fontSize: '14px' }}
                >
                  {'Rental Vendor'}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography sx={{ mr: 2, fontSize: '12px' }}>{selectedOrder.vendor}</Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 2, color: '#0075db', fontWeight: 'bold', fontSize: '14px' }}
                >
                  Facility Information
                </Typography>
                <Grid item sx={{ textAlign: 'left' }}>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>{selectedOrder.facilityName}</Typography>
                </Grid>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 1, color: '#0075db', fontWeight: 'bold', fontSize: '14px' }}
                >
                  Resident Info
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography sx={{ mr: 2, fontSize: '12px' }}>{selectedOrder.personFor}</Typography>
                <Typography sx={{ mr: 2, fontSize: '12px' }}>Room {selectedOrder.room}</Typography>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
