import { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, TextField, InputAdornment, IconButton } from '@mui/material';
import { DataGridPro, GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro';
import Iconify from '../../../../reusable-components/iconify';
import { getCategoriesData } from '../../../../api/category';
import { HOST_API_URL } from '../../../../config-global';
import EditCategory from './EditCategory';
import DeleteCategory from './DeleteCategory';
import AddCategory from './AddCategory';

export default function PurchasingCategoriesView() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const transformData = (categories, subcategories) => {
    const transformed = [];

    categories.forEach((category) => {
      // Initially add the category
      transformed.push({
        id: category.id.toString(),
        category: category.name,
        hierarchy: [category.name],
        name: category.name,
        icon: category.icon,
      });

      subcategories
        .filter((sub) => sub.mainCategoryId === category.id)
        .forEach((sub) => {
          // Then add subcategories of this category
          transformed.push({
            id: `${category.id}-${sub.id}`,
            category: category.name,
            hierarchy: [category.name, sub.name],
            mainCategoryId: category.id,
            name: sub.name,
            subId: sub.id,
            icon: category.icon,
          });
        });
    });

    return transformed;
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchValue(value);

    const filteredSubcategories = subcategories.filter((subcategory) =>
      subcategory.name.toLowerCase().includes(value.toLowerCase())
    );

    const filteredCategoriesIds = new Set(filteredSubcategories.map((sub) => sub.mainCategoryId));

    categories.forEach((category) => {
      if (category.name.toLowerCase().includes(value.toLowerCase())) {
        filteredCategoriesIds.add(category.id);
      }
    });

    const filteredCategories = categories.filter((category) => filteredCategoriesIds.has(category.id));

    const filteredData = transformData(filteredCategories, filteredSubcategories);

    setData(filteredData);
  };

  const fetchData = async () => {
    const response = await getCategoriesData();
    const { categories, subcategories } = response.data;
    setCategories(categories);
    setSubcategories(subcategories);
    const transformedData = transformData(categories, subcategories);
    setData(transformedData);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: 'category',
      headerName: 'Category',
      width: 250,
      renderCell: (params) => (
        <>
          <Iconify
            icon={params.row.icon}
            width={params.row.mainCategoryId ? 25 : 40}
            height={params.row.mainCategoryId ? 25 : 40}
          />{' '}
          <span>
            <Typography variant="subtitle2" sx={{ ml: params.row.mainCategoryId ? 3 : 1 }}>
              {params.value}
            </Typography>
          </span>
        </>
      ),
    },
    {
      field: GRID_TREE_DATA_GROUPING_FIELD,
      width: 300,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <>
          <EditCategory row={params.row} categories={categories} fetchData={fetchData} />
          <DeleteCategory
            row={params.row}
            categories={categories}
            subcategories={subcategories}
            fetchData={fetchData}
          />
        </>
      ),
      sortable: false,
      filterable: false,
    },
  ];

  const getTreeDataPath = (row) => row.hierarchy;

  return (
    <Box>
      <Grid container direction="row" alignItems="center" sx={{ mb: 1, ml: 1 }} spacing={1}>
        {/* Buttons on the left */}
        <Grid item>
          <AddCategory mainCategory categories={categories} fetchData={fetchData} />
          <AddCategory categories={categories} fetchData={fetchData} />
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 1 }}
            size="small"
            onClick={() => {
              window.open(`${HOST_API_URL}Api/Category/DownloadCategories`, '_blank');
            }}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download Categories
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 1 }}
            size="small"
            onClick={() => {
              window.open(`${HOST_API_URL}Api/Category/DownloadSubcategories`, '_blank');
            }}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download Subcategories
          </Button>
        </Grid>

        {/* Search bar on the right */}
        <Grid item sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            value={searchValue}
            onChange={handleSearch}
            size="small"
            placeholder="Search..."
            sx={{ mr: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ display: searchValue === '' ? 'none' : 'inline-flex' }}
                    onClick={() => {
                      setSearchValue('');
                      handleSearch({ target: { value: '' } });
                    }}
                  >
                    <Iconify
                      icon={'ri:close-fill'}
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
            }}
          />
        </Grid>
      </Grid>
      <DataGridPro
        treeData
        rows={data}
        columns={columns}
        getTreeDataPath={getTreeDataPath}
        initialState={{
          pinnedColumns: {
            left: ['category'],
          },
        }}
        // sx={{
        //   //prevent the grid from expanding its width to fit the container
        //   width: '100%',
        //   maxWidth: '100% !important',
        // }}
        autoHeight={data.length === 0}
        components={{
          NoRowsOverlay: () => (
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>No results found.</Typography>
            </Box>
          ),
        }}
        hideFooter
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableColumnPinning
        disableColumnReorder
      />
    </Box>
  );
}
