import { createSlice } from '@reduxjs/toolkit';
import {
  addInvoiceLogMessage, deleteInvoiceLogMessage, downloadInvoiceFile, facilityModeToggle, getGlConsolidationFacilities, getInvoiceBreakdownData, getInvoiceFormData, getInvoiceGlItems, getInvoiceItemsFiles, getInvoiceLogData, getInvoiceStatements, getInvoiceViewersList, getManualInvoices, getPendingInvoicesData, getSingleInvoice,
  getVendorsData, postAddInvoiceFile, postInvoiceForm2, postInvoiceGLItemsForm, removeGlItems, updateFacilitiesGlConsolidation, updateInvoiceField, updateInvoiceItems, uploadManualInvoices, viewInvoiceFile
  , getFacilityManagersApi
} from '../../api/invoicing';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: {
    manualInvoicing: [],
    pendingInvoicing: [],
    previousInvoicing: [],
    facilityManagers: [],
    invoicingVendors: [],
    statements: {
      vendors: [],
      statements: [],
    },
    invoiceData: [],
    invoiceLogData: [],
    invoiceItemData: [],
    invoiceGLItems: [],
    invoicePhysicalFile: null,
    invoicePhysicalAttachment: null,
    invoiceFiles: [],
    invoiceViewers: [],
    glConsolidationFacilities: [],
  },
  orderDateRange: {
    startDate: null,
    endDate: null,
  },
  pendingCount: 0,
};

const slice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    clearItemData(state) {
      state.data.previousInvoicing = [];
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    postInvoiceForm2Success(state, action) {
      state.isLoading = false;
      state.data.invoiceItemData.invoice = action.payload;
    },
    updateInvoiceFieldSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceItemData.invoice = action.payload;
    },
    updateInvoiceItemsSuccess(state, action) {
      state.isLoading = false;
      const addCatAndSubToItems = action.payload.invoiceItems?.map((item) => ({
        ...item,
        categoryId: item.agoraSubcategory.mainCategoryId,
        subcategoryId: item.agoraSubcategory.id,
      }));
      state.data.invoiceItemData = action.payload;
      state.data.invoiceItemData.invoiceItems = addCatAndSubToItems;
      state.data.invoiceGLItems = [];
    },

    postInvoiceGLItemsFormSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceGLItems = action.payload;
    },
    removeGlItemsSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceGLItems = [];
    },
    facilityModeToggleSuccess(state, action) {
      state.isLoading = false;
      const addCatAndSubToItems = action.payload.invoiceItems.map((item) => ({
        ...item,
        categoryId: item.agoraSubcategory.mainCategoryId,
        subcategoryId: item.agoraSubcategory.id,
      }));
      state.data.invoiceItemData = action.payload;
      state.data.invoiceItemData.invoiceItems = addCatAndSubToItems;
      state.data.invoiceGLItems = [];
    },

    getPendingInvoicingSuccess(state, action) {
      state.isLoading = false;

      const sortedByDates = action.payload.sort((a, b) => new Date(b.date) - new Date(a.date));

      const sortedByFMA = sortedByDates.sort((a, b) => {
        const aFma = a.facilityManagerAssignments.find((fma) => fma.currentUser);
        const bFma = b.facilityManagerAssignments.find((fma) => fma.currentUser);
        return (bFma ? 1 : 0) - (aFma ? 1 : 0); // if bFma is true, push b up
      });

      state.data.pendingInvoicing = sortedByFMA;
    },
    getManualInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.isLoading = false;
      const sortedByDates = action.payload.sort((a, b) => new Date(b.date) - new Date(a.date));
      state.data.manualInvoicing = sortedByDates;
    },
    uploadManualInvoicesSuccess(state, action) {
      state.isLoading = false;
      const sortedByDates = action.payload.sort((a, b) => new Date(b.date) - new Date(a.date));
      state.data.manualInvoicing = sortedByDates;
    },
    removeFromPending(state, action) {
      state.isLoading = false;
      state.data.pendingInvoicing = state.data.pendingInvoicing.filter((invoice) => invoice.id !== action.payload);
    },

    getStatementsSuccess(state, action) {
      state.isLoading = false;
      state.data.statements = action.payload;
    },
    getFacilityManagersSuccess(state, action) {
      state.isLoading = false;
      state.data.facilityManagers = action.payload;
    },

    getPreviousInvoicingSuccess(state, action) {
      state.isLoading = false;
      const sortedByDates = action.payload.sort((a, b) => new Date(b.date) - new Date(a.date));

      state.data.previousInvoicing = sortedByDates;
    },

    updateOrderDateRange(state, action) {
      state.isLoading = false;
      state.orderDateRange = action.payload;
    },
    getInvoiceItemsFilesSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceFiles = action.payload;
    },

    getInvoiceDataSuccess(state, action) {
      state.data.invoiceData = action.payload;
    },

    getInvoiceGlsSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceGLItems = action.payload;
    },

    getInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceItemData.invoice = action.payload;
    },

    getInvoiceBreakdownSuccess(state, action) {
      const addCatAndSubToItems = action.payload.invoiceItems?.invoiceItems?.map((item) => ({
        ...item,
        categoryId: item.agoraSubcategory.mainCategoryId,
        subcategoryId: item.agoraSubcategory.id,
      }));
      state.data.invoiceItemData = action.payload.invoiceItems;
      state.data.invoiceFiles = action.payload.invoiceFiles;
      state.data.invoiceLogData = action.payload.invoiceLog;
      state.data.invoiceGLItems = action.payload.invoiceGlItems;
      if (action.payload.invoiceItems != null) state.data.invoiceItemData.invoiceItems = addCatAndSubToItems;
      state.data.invoiceData = action.payload.invoiceData;
      state.data.invoiceViewers = action.payload.invoiceViewers;
      state.isLoading = false;
    },

    getInvoiceFileSuccess(state, action) {
      state.isLoading = false;
      state.data.invoicePhysicalFile = action.payload;
    },
    renderInvoiceFileSuccess(state, action) {
      state.isLoading = false;
      const blobUrl = URL.createObjectURL(
        new Blob([action.payload.data], { type: action.payload.headers['content-type'] })
      );
      state.data.invoicePhysicalFile = blobUrl;
    },

    getInvoiceAttachmentsSuccess(state, action) {
      state.isLoading = false;
      state.data.invoicePhysicalAttachment = action.payload;
    },

    renderInvoiceAttachmentsSuccess(state, action) {
      const blobUrl = URL.createObjectURL(
        new Blob([action.payload.data], { type: action.payload.headers['content-type'] })
      );
      state.data.invoicePhysicalAttachment = blobUrl;
      state.isLoading = false;
    },
    postAddInvoiceFileSuccess(state, action) {
      state.data.invoiceFiles = action.payload;
      state.isLoading = false;
    },
    getVendorsSuccess(state, action) {
      state.isLoading = false;
      state.data.invoicingVendors = action.payload;
    },
    addInvoiceLogSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceLogData = action.payload;
    },
    getInvoiceLogSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceLogData = action.payload;
    },

    deleteInvoiceLogMessageSuccess(state, action) {
      state.isLoading = false;
      state.data.invoiceLogData = action.payload;
    },

    getInvoiceViewersListSuccess(state, action) {
      state.data.invoiceViewers = action.payload;
    },

    getGlConsolidationFacilitiesSuccess(state, action) {
      state.data.glConsolidationFacilities = action.payload;
    },
    updateFacilitiesGlConsolidationSuccess(state, action) {
      state.data.glConsolidationFacilities = action.payload;
    },
    getPendingInvoicesCountSuccess(state, action) {
      const { pendingCount } = action.payload;
      state.isLoading = false;
      state.pendingCount = pendingCount;
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------
export function patchInvoiceItems(invoiceId, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateInvoiceItems(invoiceId, data);
      dispatch(slice.actions.updateInvoiceItemsSuccess(response.data));
      return response;
    } catch (error) {
      return error;
    }
  };
}
export function uploadInvoiceFiles(files) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await uploadManualInvoices(files);
      dispatch(slice.actions.uploadManualInvoicesSuccess(response.data));
      return response;
    } catch (error) {
      return error;
    }
  };
}
export function getManualInvoicing() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getManualInvoices();
      dispatch(slice.actions.getManualInvoicesSuccess(response.data));
      return response;
    } catch (error) {
      return error;
    }
  };
}
export function invoiceForm({ invoice }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postInvoiceForm2(invoice);
      dispatch(slice.actions.postInvoiceForm2Success(response.data));
      return 'success';
    } catch (error) {
      return error;
    }
  };
}
export function invoiceGLItemsForm({ invoiceGlItems }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postInvoiceGLItemsForm(invoiceGlItems);
      await dispatch(slice.actions.postInvoiceGLItemsFormSuccess(response.data));
      return 'success';
    } catch (error) {
      return error;
    }
  };
}
export function deleteGlItems(invoiceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await removeGlItems(invoiceId);
      await dispatch(slice.actions.removeGlItemsSuccess());
      return 'success';
    } catch (error) {
      return error;
    }
  };
}
export function facilityModeToggleChange(invoiceId, mode) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await facilityModeToggle(invoiceId, mode);
      await dispatch(slice.actions.facilityModeToggleSuccess(response.data));
      return 'success';
    } catch (error) {
      return error;
    }
  };
}

export function getPendingInvoicing({ pending }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPendingInvoicesData({ pending });
      dispatch(slice.actions.getPendingInvoicingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getFacilityManagers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFacilityManagersApi();
      dispatch(slice.actions.getFacilityManagersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStatementsInvoicing() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoiceStatements({ pending: false });
      dispatch(slice.actions.getStatementsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoiceDataFromRedux() {
  return async (dispatch) => {
    try {
      const response = await getInvoiceFormData();
      dispatch(slice.actions.getInvoiceDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoiceGls(invoiceId) {
  return async (dispatch) => {
    try {
      const response = await getInvoiceGlItems(invoiceId);
      await dispatch(slice.actions.getInvoiceGlsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoice(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getSingleInvoice(id);
      dispatch(slice.actions.getInvoiceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getInvoiceFiles(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoiceItemsFiles(id);
      dispatch(slice.actions.getInvoiceItemsFilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getInvoiceFile(id, mainFile) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await downloadInvoiceFile(id, mainFile);
      if (mainFile) dispatch(slice.actions.getInvoiceFileSuccess(response.data));
      else dispatch(slice.actions.getInvoiceAttachmentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function renderInvoiceFile(id, mainFile) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await viewInvoiceFile(id, mainFile);
      if (mainFile) dispatch(slice.actions.renderInvoiceFileSuccess(response));
      else dispatch(slice.actions.renderInvoiceAttachmentsSuccess(response));
      return 'success';
    } catch (error) {
      return 'error';
    }
  };
}
export function addInvoiceFile(id, file) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postAddInvoiceFile(id, file);
      dispatch(slice.actions.postAddInvoiceFileSuccess(response.data));
      return 'success';
    } catch (error) {
      return error;
    }
  };
}
export function getInvoiceBreakdown(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoiceBreakdownData(id);
      await dispatch(slice.actions.getInvoiceBreakdownSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeInvoiceField(invoiceId, field) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateInvoiceField(invoiceId, field);
      dispatch(slice.actions.updateInvoiceFieldSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getVendors() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getVendorsData();
      dispatch(slice.actions.getVendorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getInvoiceLog(invoiceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoiceLogData(invoiceId);
      dispatch(slice.actions.getInvoiceLogSuccess(response.data));
      return 'success';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
export function addInvoiceLog({
  invoiceId,
  messageType,
  extraInfo = '',
  mentions = null,
  parentCommentId = '',
  invoiceUrl,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await addInvoiceLogMessage(
        invoiceId,
        messageType,
        extraInfo,
        mentions,
        parentCommentId,
        invoiceUrl
      );
      dispatch(slice.actions.addInvoiceLogSuccess(response.data));
      return 'success';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
export function deleteInvoiceLog(logId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await deleteInvoiceLogMessage(logId);
      dispatch(slice.actions.deleteInvoiceLogMessageSuccess(response.data));
      return 'success';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
export function getInvoiceViewers(invoiceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoiceViewersList(invoiceId);
      dispatch(slice.actions.getInvoiceViewersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGLConsolidationFacilities() {
  return async (dispatch) => {
    try {
      const response = await getGlConsolidationFacilities();
      dispatch(slice.actions.getGlConsolidationFacilitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function patchFacilitiesGlConsolidation(facilities) {
  return async (dispatch) => {
    try {
      const response = await updateFacilitiesGlConsolidation(facilities);
      dispatch(slice.actions.updateFacilitiesGlConsolidationSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
export function removeInvoiceFromPending(id) {
  return async (dispatch) => {
    dispatch(slice.actions.removeFromPending(id));
  };
}

export function setPendingInvoiceCount(invoiceCount) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getPendingInvoicesCountSuccess({ pendingCount: invoiceCount }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
