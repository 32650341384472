import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { ORDER_HISTORY } from '../../../../reusable-components/datagrid/catalogColumns';
import { useSelector } from '../../../../redux/store';
import { useTabs } from '../../../../context/TabContext';

OrderHistory.propTypes = {
  data: PropTypes.array,
  preSearchVendorItemId: PropTypes.string,
};

export default function OrderHistory({ data, preSearchVendorItemId }) {
  const isLoading = useSelector((state) => state.catalog.isLoading);
  const { addNewTabAndNavigate } = useTabs();

  return (
    <div>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-catalog-order-history"
          boxSX={{ width: '100%', height: 'calc(100vh - 260px)' }}
          data={data}
          presetFilter={preSearchVendorItemId}
          gridColumns={ORDER_HISTORY}
          // sort={[{ field: 'dateApproved', sort: 'desc' }]}
          isModal
          isLoading={!data.length && isLoading}
          onRowClick={(params) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            addNewTabAndNavigate(`/dashboard/orders/${params.row.orderNumber.replace(/\D/g, '')}`);
          }}
        />
      </Grid>
    </div>
  );
}
