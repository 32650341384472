import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import useDebounce from '../../utils/useDebounce';

DebounceTextField.propTypes = {
  onChange: PropTypes.func,
  debounceTime: PropTypes?.number,
  debounceFunc: PropTypes.func,
};

export default function DebounceTextField({ onChange, debounceFunc, debounceTime, ...other }) {
  const debounce = useDebounce(debounceFunc, debounceTime || 300);

  return <TextField onChange={(e) => { onChange(e); debounce(e); }} {...other} />
}