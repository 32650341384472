import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Stack, Slider, Typography, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useSelector } from '../../../redux/store';

Download.propTypes = { blobUrl: PropTypes.blobUrl | undefined };
export default function Download({ blobUrl }) {
  const {
    data: { invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = `Invoice_PS #${invoiceItemData?.invoice?.vendorInvoiceId}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };
  return (
    <Box>
      <Tooltip title={'download'}>
        <IconButton color="secondary" sx={{ p: 0.5 }} onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
