import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { Box, Grid, IconButton, Tooltip, Button } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector, useDispatch } from '../../../../redux/store';
import { getCatalog, getAllSKUsData, deleteProduct } from '../../../../redux/slices/catalog';
import { FULL_CATALOG } from '../../../../reusable-components/datagrid/catalogColumns';
import CustomToolbar from './CustomToolbar';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { combineProductData } from '../../../../api/catalog';
import OrderGuideDialog from './OrderGuideDialog';
import OrderHistoryDialog from './OrderHistoryDialog';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import ViewAllSKUs from './ViewAllSKUs';

export default function CatalogView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [viewAllSKUs, setViewAllSKUs] = useState(new URLSearchParams(window.location.search).get('viewallskus'));
  const { enqueueSnackbar } = useSnackbar();

  const selectedRows = useMemo(() => data.filter((row) => selected.includes(row.productId)), [data, selected]);

  const { data: { products, allSKUsData }, isLoadingAllSKUsData, isLoading } = useSelector((state) => state.catalog);

  useEffect(() => {
    if (products.length > 0) setData(products);
  }, [products]);

  useEffect(() => {
    if (viewAllSKUs && allSKUsData.length === 0 && !isLoadingAllSKUsData) dispatch(getAllSKUsData());
  }, [viewAllSKUs]);

  const combineProducts = async () => {
    const response = await combineProductData({ connectTo: selected[0], connectProductIds: selected });
    if (response.status === 200) {
      enqueueSnackbar('Products combined', { variant: 'success' });
      dispatch(getCatalog());
      setSelected([]);
    }
  };

  const combineItems = (rowId) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(rowId)) {
        return prevSelected.filter((id) => id !== rowId);
      }
      return [...prevSelected, rowId];
    });
  };

  const customToolbar = () => <CustomToolbar viewAllSKUs={viewAllSKUs} setViewAllSKUs={setViewAllSKUs} />;

  const ActionButtons = (params) => {
    const { categoryId, productId, description } = params.row;
    return (
      <>
        <OrderHistoryDialog productId={productId} />

        <ScopeGuard scopes={['Edit-OrderGuide']} allowAdmin>
          <OrderGuideDialog productId={productId} agoraCategoryId={categoryId} />
        </ScopeGuard>
        <ScopeGuard scopes={['Item-Catalog-Edit']} allowAdmin>
          <Tooltip title="Combine Items">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.combine = true;
                combineItems(productId);
              }}
            >
              <MergeTypeIcon />
            </IconButton>
          </Tooltip>

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete ${description}?`}
            actionButton="Delete"
            action={async () => dispatch(deleteProduct(0, productId, false, enqueueSnackbar))}
            tooltip={`Delete`}
          />
        </ScopeGuard>
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  const rowClick = (id) => {
    navigate(`/dashboard/admin/catalog/${id}`);
  };

  const columns = useMemo(() => [...FULL_CATALOG, ...(selected.length > 0 ? [] : [actions])], [selected]);

  return (
    <>
      <Grid item xs={selected.length > 0 ? 10 : 12}>
        {viewAllSKUs ? <ViewAllSKUs viewAllSKUs={viewAllSKUs} setViewAllSKUs={setViewAllSKUs} />
          :
          <CustomDataGrid
            boxSX={{ height: 'calc(100vh - 235px)' }}
            checkboxSelection={selected.length > 0}
            CustomLeftToolbar={customToolbar}
            data={data}
            displayCheckboxRight
            getRowId={(row) => row.productId}
            gridColumns={columns}
            gridId="admin-catalog"
            isLoading={isLoading}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={(newSelection) => {
              setSelected(newSelection);
            }}
            onRowClick={(params, e) => {
              const selection = window.getSelection().toString();
              if (selection.length > 0) return;
              if (e.addToOrderGuide || e.itemHistory || e.combine || e.picture || e.confirmDialog) return;
              rowClick(params.row.productId);
            }}
            rowSelectionModel={selected}
            sort={[{ field: 'description', sort: 'asc' }]}
          />}
      </Grid>
      {selected.length > 0 && (
        <Grid item xs={2}>
          <Box
            sx={{
              ml: 2,
              p: 1.5,
              mt: 7,
            }}
          >
            <AwaitButton
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ mb: 1, width: '200px', boxShadow: 3, fontWeight: 'bold' }}
              startIcon={<MergeTypeIcon />}
              onClick={combineProducts}
            >
              Combine to {selected[0]}
            </AwaitButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {selectedRows.map((row, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: 'solid 2px #8dc63f',
                      width: '200px',
                      height: '35px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '20px',
                        height: '35px',
                        backgroundColor: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                      }}
                    >
                      {index + 1}
                    </Box>
                    <Box sx={{ ml: 1, color: 'primary.main' }}>
                      {row.productId}-{row.agoraCategory}
                    </Box>
                  </Box>
                  {index < selected.length - 1 && (
                    <Box
                      sx={{ width: '2px', height: '20px', backgroundColor: '#8dc63f', alignSelf: 'center', ml: 1 }}
                    />
                  )}
                </Box>
              ))}
            </Box>

            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item xs={6}>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{
                    mb: 1,
                    width: '100%',
                    textDecoration: 'underline',
                  }}
                  onClick={() => setSelected([])}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
}
