import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import { useScopeCheck } from '../../../../../reusable-components/scopes';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { updateUsersRolesInRedux } from '../../../../../redux/slices/users';

RolesCheckbox.propTypes = {
  role: PropTypes.string,
};

export default function RolesCheckbox({ role }) {
  const dispatch = useDispatch();
  const {
    data: { user },
  } = useSelector((state) => state.users);
  const { roles } = user;

  const hasEditAccess = useScopeCheck(['User-Edit'], true);

  const hasRole = roles.some((item) => item === role);
  const handleSwitchActiveRole = (e) => {
    e.stopPropagation();
    if (hasRole) {
      dispatch(updateUsersRolesInRedux(roles.filter((item) => item !== role)));
    } else {
      dispatch(updateUsersRolesInRedux([...roles, role]));
    }
  };

  return (
    <>
      <Checkbox checked={hasRole} onChange={handleSwitchActiveRole} disabled={!hasEditAccess} />
    </>
  );
}
