import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { budgetForm, createGlCode, getBudget } from '../../../../redux/slices/budget';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { formatMoney } from '../../../../utils/formatNumber';
import { AwaitButton } from '../../../../reusable-components/await-button';

BudgetModal.propTypes = {
  edit: PropTypes.bool,
  row: PropTypes.object,
  selectedRow: PropTypes.object,
};
const filter = createFilterOptions();

export default function BudgetModal({ edit, row }) {
  const {
    data: { agoraCategories, agoraSubcategories, facilities, glCodes },
  } = useSelector((state) => state.budget);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    facility: '',
    agoraCategory: '',
    agoraSubcategory: '',
    glCode: '',
    ppdBudget: '',
    excludeFromMain: false,
  });

  const handleSave = async () => {
    const response = await dispatch(budgetForm(value, edit ? row?.id : null));
    if (response) enqueueSnackbar('Budget updated successfully', { variant: 'success' });
    else enqueueSnackbar('Error updating budget', { variant: 'error' });
    dispatch(getBudget());
    setOpen(false);
  };
  const handleCreateGlCode = async (glCode) => {
    if (
      !value.facility.id ||
      value.facility.id === '' ||
      value.facility.id === null ||
      value?.facility?.id === undefined ||
      !glCode ||
      glCode === '' ||
      glCode === null ||
      glCode === undefined
    ) {
      enqueueSnackbar('You must select a facility', { variant: 'error' });
      setValue({
        ...value,
        glCode: '',
      });
    } else {
      const response = await dispatch(createGlCode(glCode, value.facility.facilityBookingTypeId));
      if (response) {
        setValue({
          ...value,
          glCode: {
            id: response,
            label: glCode,
            facilityBookingTypeId: value.facility.facilityBookingTypeId,
          },
        });
        enqueueSnackbar('GL code added successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to add GL code', { variant: 'error' });
      }
    }
  };

  const handleClickOpen = () => {
    setValue({
      facility: edit
        ? {
            id: row.facilityId,
            label: row.facility,
            facilityBookingTypeId: facilities.find((facility) => facility.id === row.facilityId)?.facilityBookingTypeId,
          }
        : '',
      agoraCategory: edit ? { id: row.agoraCategoryId, label: row.agoraCategory } : '',
      agoraSubcategory: edit ? { id: row.agoraSubcategoryId, label: row.agoraSubcategory } : '',
      glCode:
        edit && row.glCode
          ? {
              id: row.glId,
              label: row.glCode,
              facilityBookingTypeId: facilities.find((facility) => facility.id === row.facilityId)
                ?.facilityBookingTypeId,
            }
          : '',
      ppdBudget: edit ? row.ppdBudget : '',
      excludeFromMain: edit ? row.excludeFromMain : false,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      facility: '',
      agoraCategory: '',
      agoraSubcategory: '',
      glCode: '',
      ppdBudget: '',
      excludeFromMain: false,
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          size="small"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add New Budget
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth size="small">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {edit ? 'Edit' : 'Add'} Budget
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ p: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Facility
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value.facility}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      glCode: '',
                      facility: newValue,
                    });
                  }}
                  options={facilities?.map((type) => ({
                    id: type.id,
                    label: type.facilityName,
                    facilityBookingTypeId: type.facilityBookingTypeId,
                  }))}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  PS Category
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value.agoraCategory}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      agoraSubcategory: '',
                      agoraCategory: newValue,
                    });
                  }}
                  options={agoraCategories?.map((type) => ({ id: type.id, label: type.name }))}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  PS Subcategory
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value.agoraSubcategory}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      agoraSubcategory: newValue,
                    });
                  }}
                  options={agoraSubcategories
                    .filter((type) => type?.mainCategoryId === value?.agoraCategory?.id)
                    ?.map((type) => ({ id: type.id, label: type.name }))}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  GL Code
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  freeSolo
                  selectOnFocus
                  handleHomeEndKeys
                  value={value?.glCode || ''}
                  onChange={(event, newValue) => {
                    if (newValue?.inputValue) {
                      handleCreateGlCode(newValue.inputValue);
                    } else {
                      setValue({
                        ...value,
                        glCode: newValue || '',
                      });
                    }
                  }}
                  options={glCodes
                    .filter((type) => type?.facilityBookingTypeId === value?.facility?.facilityBookingTypeId)
                    .map((type) => ({ id: type.id, label: type.code }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.label);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => <TextField {...params} label="Select or add your own" />}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Budget
                </Typography>
                <TextField
                  value={value.ppdBudget}
                  type="number"
                  onChange={(e) =>
                    setValue({
                      ...value,
                      ppdBudget: formatMoney(e.target.value),
                    })
                  }
                  label=""
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.excludeFromMain}
                      onChange={(event) => {
                        setValue({
                          ...value,
                          excludeFromMain: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Exclude from main budget"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.facility === '' || value.agoraCategory === '' || value.agoraSubcategory === ''}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            {edit ? 'Save' : 'Add Budget'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
