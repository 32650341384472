// remember to import this file in index.js

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          backgroundColor: '#fff',
          border: 'solid 1px #c1c9d0',
          width: '100%',
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
        MuiCardHeader: {
          defaultProps: {
            titleTypographyProps: { variant: 'h6' },
            subheaderTypographyProps: { variant: 'body2' },
          },
        },
        MuiCardContent: {},
      },
    },
  };
}
