import { Box, Button, Grid } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { HOST_API_URL } from '../../../../config-global';
import NewItemDialog from '../catalog/NewItemDialog';

export default function CustomToolbar() {
  const downloadCatalog = async () => {
    //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
    window.open(`${HOST_API_URL}Api/WarehouseCatalog/DownloadCatalog`, '_blank');
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Box sx={{ display: 'inline-block', mr: 1 }}>
          <NewItemDialog isWarehouseItem />
        </Box>
        <Box sx={{ display: 'inline-block', mr: 1 }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 1 }}
            size="small"
            onClick={downloadCatalog}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download
          </Button>
        </Box>
      </Grid>
    </>
  );
}
