import axios from 'axios';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { objectToFormData } from '../../api/utils';
import { useSelector } from '../../redux/store';

export default function URLChangeTracker() {
  const location = useLocation();
  const sessionStorageKey = "urlTimeTracker";
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    try {
      const urlTimeTracker = JSON.parse(sessionStorage.getItem(sessionStorageKey)) ?? {};
      const now = new Date();
      if (urlTimeTracker.lastURL) {
        urlTimeTracker.lastURL.end = now;
        const urlValues = urlTimeTracker.urlValues ?? [];
        urlValues.push(urlTimeTracker.lastURL);
        urlTimeTracker.urlValues = urlValues;
      }
      urlTimeTracker.lastURL = {
        url: window.location.href,
        start: now,
        userId: user?.id,
        siteId: user?.siteId,
      };
      if (!urlTimeTracker.initDate) urlTimeTracker.initDate = now;
      const submitTime = user?.id && new Date(urlTimeTracker.initDate) < new Date(now.getTime() - 900000);
      if (submitTime && urlTimeTracker.urlValues?.length) {
        const urlValues = urlTimeTracker.urlValues.map(value => ({
          ...value,
          timeSpentInSeconds: new Date(value.end).getTime() - new Date(value.start).getTime(),
        }));
        axios.post("Api/URLTimeTracker", objectToFormData({
          initDate: urlTimeTracker.initDate,
          urlValues: Object.entries(urlValues).map(([key, value]) => ({ ...value, timeSpentInSeconds: value.timeSpentInSeconds > 0 ? value.timeSpentInSeconds / 1000 : 0 }))
        })).catch(() => { });
      }
      sessionStorage.setItem(sessionStorageKey, submitTime ? JSON.stringify({ lastURL: urlTimeTracker.lastURL }) : JSON.stringify(urlTimeTracker));
    } catch (err) { console.warn(err); }
  }, [location, user]);
}
