import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

ChevronBox.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.object,
  size: PropTypes.number,
  reviewed: PropTypes.bool,
  length: PropTypes.number,
  reviewerIndex: PropTypes.number,
};

export default function ChevronBox({
  size = 12,
  children = '',
  color = 'info.main',
  textColor = '#fff',
  reviewed = true, //by default fillit in and if the need to show it not filled in then pass false such as in the orders
  length,
  reviewerIndex,
}) {
  const boxBackgroundColor = reviewed ? color : '#fff'; // White background if not reviewed
  const boxBorderColor = reviewed ? 'none' : `2px solid ${color}`; // Border when not reviewed
  const textDisplayColor = reviewed ? textColor : color; // Use color for text when not reviewed
  const isFirst = reviewerIndex === 0;
  const isLast = reviewerIndex === length - 1;

  return (
    <Box sx={{ ml: isFirst ? 0.5 : 0, }}>
      <Grid container direction="row">
        {!reviewed ? (
          <Box
            sx={{
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                width: 17,
                height: 17,
                borderLeft: '2px solid',
                borderTop: '2px solid',
                transform: 'rotate(135deg) ',
                right: -10,
                top: 3.5,
                borderColor: color,
              },
            }}
          />
        ) : (
          <Grid item>
            <Box
              sx={{
                width: 0,
                height: 0,
                borderTop: `${0}px solid transparent`,
                borderRight: `${size}px  solid`,
                borderRightColor: color,
                borderBottom: `${size}px  solid transparent`,
              }}
            />
            <Box
              sx={{
                width: 0,
                height: 0,
                borderTop: `${size}px solid transparent`,
                borderRight: `${size}px solid`,
                borderRightColor: color,
                borderBottom: `${0}px  solid transparent`,
              }}
            />
          </Grid>
        )}

        <Box
          sx={{
            bgcolor: boxBackgroundColor,
            color: textDisplayColor, // Change text color based on reviewed status
            height: size * 2,
            border: boxBorderColor,
            pl: reviewed ? 1 : 2,
            pr: reviewed ? 1 : 0.5,
            borderRight: !isLast ? 'none' : '2px solid',
            borderLeft: 'none',
            ml: 0,
            borderTopRightRadius: isLast ? '4px' : '0px',
            borderBottomRightRadius: isLast ? '4px' : '0px',
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', width: '100%' }}
          >
            {children}
          </Grid>
        </Box>
        {!isLast && (
          <>
            {reviewed ? (
              <Box
                sx={{
                  mr: -1,
                  width: 0,
                  height: 0,
                  borderTop: `${size}px solid transparent`,
                  borderLeft: `${size}px  solid`,
                  borderLeftColor: color,
                  borderBottom: `${size}px  solid transparent`,
                }}
              />
            ) : (
              <Box
                sx={{
                  position: 'relative',
                  // width: '4px',
                  // height: '12px',

                  mr: 0.75,
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: 17,
                    height: 17,
                    borderLeft: '2px solid',
                    borderTop: '2px solid',
                    transform: 'rotate(135deg) ',
                    left: -8,
                    top: 3.5,
                    borderColor: color,
                  },
                }}
              />
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}
