import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { RULES } from '../../../../reusable-components/datagrid/vendorColumns';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters';
import { getVendorRulesData, postDeleteVendorRule } from '../../../../api/vendors';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import RulesModal from './RulesModal';

VendorRulesTab.propTypes = {
  selectedRow: PropTypes.object,
};

export default function VendorRulesTab({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [ruleTypes, setRuleTypes] = useState([]);
  const [ruleValueDefinitions, setRuleValueDefinitions] = useState([]);
  const [rules, setRules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getVendorRulesData();
      const { ruleTypes, ruleValueDefinitions, ruleValues, rules } = response.data;
      setRuleTypes(ruleTypes);
      setRuleValueDefinitions(ruleValueDefinitions);
      setRules(rules);
      const filteredVendorData = selectedRow ? ruleValues.filter((d) => d.value === selectedRow.id) : ruleValues;
      setData(filteredVendorData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching vendor data:', error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const deleteRule = async (id) => {
    try {
      const response = await postDeleteVendorRule(id);
      if (response.status === 200) {
        enqueueSnackbar('Rule deleted successfully', { variant: 'success' });
        fetchData();
      }
    } catch (error) {
      console.error('Error deleting rule', error);
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <RulesModal
            fetchData={fetchData}
            edit
            row={params.row}
            ruleTypes={ruleTypes}
            ruleValueDefinitions={ruleValueDefinitions}
            rules={rules}
            ruleValues={data}
          />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete rule?`}
            actionButton="Delete"
            action={() => deleteRule(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <RulesModal
                fetchData={fetchData}
                ruleTypes={ruleTypes}
                ruleValueDefinitions={ruleValueDefinitions}
                rules={rules}
                ruleValues={data}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    ),
    [ruleTypes, ruleValueDefinitions, rules, data]
  );

  const columns = createColumns([...RULES, actionButtons]);

  return (
    <Paper sx={{ px: 2, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-vendors-rules-tab"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          forceReRender={data}
          gridColumns={columns}
          sort={[{ field: 'rule', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </Paper>
  );
}
