import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Card, InputAdornment, Stack, Typography, Link, Box, Alert, IconButton } from '@mui/material';
import Iconify from '../../reusable-components/iconify';
import { MinimalLogo } from '../../reusable-components/logo';
import FormProvider, { RHFTextField } from '../../reusable-components/hook-form';
import { LOGIN_SCHEMA } from '../../reusable-components/form/authSchema';
import { useDispatch } from '../../redux/store';
import { persistLogin } from '../../redux/slices/user';
import { AwaitButton } from '../../reusable-components/await-button';

export default function LoginForm() {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const [returnUrl, setReturnUrl] = useState(searchParams.get('returnUrl'));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setReturnUrl(searchParams.get('returnUrl') || '');
  }, [searchParams]);

  const methods = useForm({
    resolver: yupResolver(LOGIN_SCHEMA),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true); // Set loading to true when submission starts
    const { email, password } = await data;
    try {
      const response = await dispatch(persistLogin({ email, password, returnUrl }));
      if (!response?.success) {
        enqueueSnackbar(response?.errorMessage ?? 'Login failed', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Login failed', { variant: 'error' });
      setError('afterSubmit', {
        type: 'manual',
        message: error.message || 'Login failed',
      });
    }
    // Always stop loading on completion or error
    setLoading(false);
    reset(
      { email, password },
      {
        keepErrors: true,
        keepDirty: true,
        keepIsSubmitted: false,
        keepSubmitting: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
  };

  return (
    <Card sx={{ m: 3, p: 3 }}>
      <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: -13 }}>
          <MinimalLogo disabledLink />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h3" color="primary">
            Login
          </Typography>
        </Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Email
              </Typography>
              <RHFTextField
                name="email"
                placeholder="Enter your email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:email-outline" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Password
              </Typography>
              <RHFTextField
                name="password"
                placeholder="Enter password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:lock-outline" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>

          <Stack alignItems="flex-start" sx={{ my: 2 }}>
            <Link to={`/auth/forgot-password?email=${watch('email') || ''}`} component={RouterLink}>
              Forgot password?
            </Link>
          </Stack>

          <AwaitButton fullWidth color="primary" size="large" type="submit" variant="contained" loading={loading}>
            Sign in
          </AwaitButton>
        </FormProvider>
      </Stack>
    </Card>
  );
}
