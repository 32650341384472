import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, IconButton, Paper, Divider, Tooltip, InputBase } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../../redux/store';
import { addOrderCommentForm, editOrderCommentForm, deleteOrderComment } from '../../../../api/orders';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { updateOrderCommentsInRedux, removeGlobalNote } from '../../../../redux/slices/orders';

VendorNote.propTypes = {
  item: PropTypes.object,
};

export default function VendorNote({ item }) {
  const dispatch = useDispatch();

  const { id = '' } = useParams();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const [openNote, setOpenNote] = useState(null);
  const [openNoteId, setOpenNoteId] = useState(null);
  const [currentNote, setCurrentNote] = useState('');

  const { orderById } = useSelector((state) => state.orders || {});
  const { orderComments, vendors, globalNote, removeGlobalNoteFromVendors } = orderById[id] || {};

  const addNote = async (vendorId) => {
    const data = {
      orderNumber: id,
      comment: currentNote,
      vendorIds: [vendorId],
    };

    const res = await addOrderCommentForm(data);
    if (res.status === 200) {
      dispatch(
        updateOrderCommentsInRedux({
          orderId: id,
          commentArray: [...orderComments, res.data],
        })
      );
      setCurrentNote('');
      setOpenNote(false);
      setOpenNoteId(null);
    }
  };
  const editNoteFunc = async (vendorId) => {
    const data = {
      id: openNoteId,
      orderNumber: id,
      comment: currentNote,
      vendorIds: [vendorId],
    };
    const res = await editOrderCommentForm(data);
    if (res.status === 200) {
      dispatch(
        updateOrderCommentsInRedux({
          orderId: id,
          commentArray: orderComments.map((note) => (note.id === openNoteId ? res.data : note)),
        })
      );
      setCurrentNote('');
      setOpenNote(false);
      setOpenNoteId(null);
    }
  };
  const deleteNote = async (noteId) => {
    const res = await deleteOrderComment(noteId);
    if (res.status === 200) {
      dispatch(updateOrderCommentsInRedux({
        orderId: id,
        commentArray: orderComments.filter((note) => note.id !== noteId),
      }));
      setCurrentNote('');
      setOpenNote(false);
      setOpenNoteId(null);
    }
  };

  const handleNoteClick = () => {
    setOpenNote(!openNote);
  };
  const handleEdit = (comment, noteId) => {
    setOpenNote(true);
    setOpenNoteId(noteId);
    setCurrentNote(comment);
  };

  const filterUniqueNotesByVendorId = (notes) => {
    const uniqueNotes = {};
    notes.forEach((note) => {
      const vendorId = note.vendorIds?.[0];
      if (vendorId) {
        if (!uniqueNotes[vendorId]) {
          uniqueNotes[vendorId] = [];
        }
        uniqueNotes[vendorId].push(note);
      }
    });
    return uniqueNotes;
  };

  const uniqueNotesLookup = useMemo(() => filterUniqueNotesByVendorId(orderComments || []), [orderComments]);
  const vendorAllowsNotes = vendors?.find((vendor) => vendor.id === item.vendorId)?.allowsMessages;

  return (
    <Box sx={{ mt: 1, ml: 2 }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ pl: 1 }}>
        <Grid container direction="row" alignItems="flex-end" justifyContent="flex-start">
          <Tooltip title={!vendorAllowsNotes && "Vendor doesn't accept notes."} placement="top" arrow>
            <Typography
              component="span" sx={{ color: (theme) => (vendorAllowsNotes ? theme.palette.secondary.main : theme.palette.grey[500]) }}>
              <Iconify icon="material-symbols:add-notes-outline-rounded" width={16} height={16} sx={{ mr: 0.5 }} />
            </Typography>
            <Typography variant="subtitle2" component="span" sx={{ color: (theme) => !vendorAllowsNotes && theme.palette.grey[500] }}>
              Vendor notes:
            </Typography>
          </Tooltip>
          {vendorAllowsNotes && !uniqueNotesLookup[item.vendorId] &&
            <ScopeGuard scopes={['Edit-Order']} allowAdmin>
              <Box
                onClick={() => handleNoteClick()}
                sx={{ display: 'flex', alignItems: 'flex-bottom', '&:hover': { cursor: 'pointer', }, ml: 1 }}>
                <Typography variant="subtitle2" color="secondary" component="span" sx={{ textDecoration: 'underline' }}/* Use span here to prevent nested paragraphs*/>
                  Add note
                </Typography>
              </Box>
            </ScopeGuard>}
        </Grid>
      </Grid>

      {vendorAllowsNotes && (
        <Box sx={{ pl: 1 }}>
          {globalNote && !removeGlobalNoteFromVendors?.includes(item.vendorId) && filter !== "rerun" && <Box sx={{ display: 'flex' }}>
            <Typography variant="subtitle2" component="span" sx={{ ml: 1 }}>
              <Typography
                component="span"
                sx={{ color: (theme) => theme.palette.error.main, '&:hover': { cursor: 'pointer' }, mr: 1 }}
                onClick={() => { dispatch(removeGlobalNote(id, item.vendorId)); }}>
                <Iconify icon="material-symbols:delete" width={16} height={16} />
              </Typography>
              <Box component="span" sx={{ textDecoration: "underline" }} >Global note:</Box> {globalNote}
            </Typography>
          </Box>}
          {uniqueNotesLookup[item.vendorId]?.map((note, index) => (
            <Grid container direction="row" key={index}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-bottom',
                  pl: 1, // Vertically align items to center
                }}
              >
                <ScopeGuard scopes={['Edit-Order']} allowAdmin>
                  <Typography
                    component="span"
                    sx={{ color: (theme) => theme.palette.error.main, '&:hover': { cursor: 'pointer' } }}
                    onClick={() => deleteNote(note.id)}
                  >
                    <Iconify icon="material-symbols:delete" width={16} height={16} />
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ color: (theme) => theme.palette.info.main, '&:hover': { cursor: 'pointer' }, pl: 1 }}
                    onClick={() => handleEdit(note.comment, note.id)}                  >
                    <Iconify icon="material-symbols:edit" width={15} height={15} />
                  </Typography>
                </ScopeGuard>
                <Typography
                  variant="subtitle2"
                  sx={{ ml: 1 }}>
                  {note.comment}
                </Typography>
              </Box>
            </Grid>
          ))}
          {uniqueNotesLookup[item.vendorId] &&
            <ScopeGuard scopes={['Edit-Order']} allowAdmin>
              <Box
                onClick={() => handleNoteClick()}
                sx={{ display: 'flex', alignItems: 'flex-bottom', '&:hover': { cursor: 'pointer', }, ml: 1 }}>
                <Typography variant="subtitle2" color="secondary" component="span" sx={{ textDecoration: 'underline' }}/* Use span here to prevent nested paragraphs*/>
                  Add note
                </Typography>
              </Box>
            </ScopeGuard>
          }
        </Box>
      )}
      {openNote && (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: 'solid 1px #b128bc',
            mt: 1,
          }}
        >
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              padding: 0,
              lineHeight: '16px',
            }}
            placeholder="Add vendor note"
            multiline
            value={currentNote}
            onChange={(e) => {
              setCurrentNote(e.target.value);
            }}
          />
          <Divider sx={{ height: 20, ml: 0.5 }} orientation="vertical" />

          <IconButton color="secondary" onClick={() => (openNoteId ? editNoteFunc(item.vendorId) : addNote(item.vendorId))}>
            <Iconify icon="mdi:send" width={20} height={20} />
          </IconButton>
        </Paper>
      )}
    </Box>
  );
}
