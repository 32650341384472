import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import { Card, InputAdornment, Stack, Typography, Box, Alert } from '@mui/material';
import Iconify from '../../reusable-components/iconify';
import { MinimalLogo } from '../../reusable-components/logo';
import FormProvider, { RHFTextField } from '../../reusable-components/hook-form';
import { FORGOT_PASSWORD_SCHEMA } from '../../reusable-components/form/authSchema';
import { useSnackbar } from '../../reusable-components/snackbar';
import { forgotPassword } from '../../api/account';
import { AwaitButton } from '../../reusable-components/await-button';

export default function ForgotPassword() {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    email: searchParams.get('email'),
  };
  const methods = useForm({
    resolver: yupResolver(FORGOT_PASSWORD_SCHEMA),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await forgotPassword(data.email);
      if (response) {
        enqueueSnackbar('Reset password email sent', { variant: 'success' });
      }
    } catch (error) {
      console.error(error);
      reset();
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });

      enqueueSnackbar(`Failed to reset password ${error.message}`, { variant: 'error' });
    }
  };
  return (
    <Card sx={{ m: 3, p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: -13 }}>
            <MinimalLogo disabledLink />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h3" color="primary">
              Reset Password
            </Typography>
          </Box>
          {isSubmitSuccessful && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="body2">
                Success! Please check your email and follow the link to complete the process.
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
              Email
            </Typography>
            <RHFTextField
              name="email"
              fullWidth
              placeholder="Enter your email "
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:email-outline" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <AwaitButton fullWidth color="primary" size="large" type="submit" variant="contained" loading={isSubmitting}>
            Reset Password
          </AwaitButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
