import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Iconify from '../iconify';

AddButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node
};

export default function AddButton({ onClick, children, ...other }) { 
  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{ mr: 1 }}
      size="small"
      onClick={onClick}
      startIcon={<Iconify icon="eva:plus-outline" />}
      {...other}
    >
      {children}
    </Button>
  );
}
