export function hasKey(obj, key) {
  return key in obj;
}

export function objectToFormData(obj, form, namespace) {
  const fd = form || new FormData();
  Object.keys(obj).forEach((property) => {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      const formKey = namespace ? `${namespace}[${property}]` : property;

      if (hasKey(obj, property)) {
        const value = obj[property];

        if (Array.isArray(value)) {
          value.forEach((item, i) => {
            if (typeof item === 'object' && !(item instanceof File)) {
              if (item instanceof Date) {
                fd.append(`${formKey}[${i}]`, new Date(item).toUTCString());
              } else if (item) objectToFormData(item, fd, `${formKey}[${i}]`);
            } else if (item !== undefined) {
              fd.append(`${formKey}[${i}]`, item);
            }
          });
        } else if (typeof value === 'object' && !(value instanceof File)) {
          if (value instanceof Date) {
            fd.append(formKey, new Date(value).toUTCString());
          } else if (value) {
            objectToFormData(value, fd, formKey);
          }
        } else if (value !== undefined) {
          fd.append(formKey, value);
        }
      }
    }
  });

  return fd;
}
