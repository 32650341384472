import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Button,
  TextField,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import EmojiPicker from './EmojiPicker';
import {
  getCheckDuplicateSubcategory,
  getCheckDuplicateCategory,
  postCategoryForm,
  postSubcategoryForm,
} from '../../../../api/category';
import { AwaitButton } from '../../../../reusable-components/await-button';

AddCategory.propTypes = {
  mainCategory: PropTypes.bool,
  categories: PropTypes.array,
  fetchData: PropTypes.func,
};

export default function AddCategory({ mainCategory, categories, fetchData }) {
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState('fluent-emoji-flat:plus');

  const { enqueueSnackbar } = useSnackbar();

  const [category, setCategory] = useState(null);
  const [name, setName] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCategory(null);
    setName('');
  };
  const handleSave = async () => {
    if (!name) {
      enqueueSnackbar('Name is required', 'error');
      return;
    }
    if (!mainCategory) {
      const duplicate = await getCheckDuplicateSubcategory(-1, category.id, name);
      if (duplicate.data) {
        enqueueSnackbar('Subcategory already exists', { variant: 'error' });
        return;
      }
      await postSubcategoryForm({ name, mainCategoryId: category.id });
    } else {
      const duplicate = await getCheckDuplicateCategory(-1, name);
      if (duplicate.data) {
        enqueueSnackbar('Category already exists', { variant: 'error' });
        return;
      }
      await postCategoryForm({ name, icon });
    }
    fetchData();
    enqueueSnackbar('Category added', { variant: 'success' });
    handleClose();
  };
  return (
    <>
      <Button
        variant="contained"
        sx={{ mr: 1 }}
        size="small"
        color="secondary"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="material-symbols:add" />}
      >
        Add New {mainCategory ? 'Category' : 'Subcategory'}
      </Button>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Add new {mainCategory ? 'Category' : 'Subcategory'}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          {!mainCategory ? (
            <>
              <DialogContentText sx={{ fontWeight: 'bold' }}>Select Category</DialogContentText>
              <Autocomplete
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': 'none',
                }}
                size="small"
                fullWidth
                type="new-password"
                options={categories}
                autoHighlight
                getOptionLabel={(option) => option?.name}
                value={category}
                onChange={(event, newValue) => {
                  setCategory(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />{' '}
            </>
          ) : (
            <>
              <DialogContentText sx={{ fontWeight: 'bold' }}>Add Icon</DialogContentText>
              <EmojiPicker icon={icon} setIcon={setIcon} />
            </>
          )}
          <>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Name</DialogContentText>{' '}
            <TextField
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
              label=""
              size="small"
              variant="outlined"
              sx={{ width: '100%' }}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150, mr: 2 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
