import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useGridApiRef } from '@mui/x-data-grid';
import { Checkbox, Link, Typography, Box, Button, Autocomplete, Stack, Grid, Alert, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from '../../../../redux/store';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { patchFacilitiesGlConsolidation } from '../../../../redux/slices/invoicing';

export default function InvoicingSettings() {
  const {
    data: { glConsolidationFacilities },
  } = useSelector((state) => state.invoicing);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const apiRef = useGridApiRef();
  const [unsavedData, setUnsavedData] = useState({});

  const handleSave = async () => {
    const facilitiesArrayWithIds = Object.entries(unsavedData).map(([id, data]) => ({
      id: Number(id),
      ...data,
    }));
    const response = await dispatch(patchFacilitiesGlConsolidation(facilitiesArrayWithIds));
    if (response.status === 200) {
      enqueueSnackbar('facilities settings updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('failed to update facilities settings', { variant: 'error' });
    }
    setUnsavedData({});
  };
  const handleCancel = () => {
    apiRef.current.updateRows(glConsolidationFacilities);
    setUnsavedData({});
  };
  const handleCheckAll = (event) => {
    const isChecked = event.target.checked;
    const updatedUnsavedData = { ...unsavedData };
    glConsolidationFacilities?.forEach((facility) => {
      const originalValue = !!facility.unconsolidatedGls;
      if (originalValue !== isChecked) {
        updatedUnsavedData[facility.id] = { ...facility, unconsolidatedGls: isChecked };
      } else {
        delete updatedUnsavedData[facility.id];
      }
      apiRef.current.updateRows([{ ...facility, unconsolidatedGls: isChecked }]);
    });
    setUnsavedData(updatedUnsavedData);
  };
  const handleCellChange = (id, field, isChecked) => {
    const originalValue = !!glConsolidationFacilities?.find((facility) => facility.id === id).unconsolidatedGls;
    setUnsavedData((prevUnsavedData) => {
      if (originalValue !== isChecked) {
        return {
          ...prevUnsavedData,
          [id]: {
            ...prevUnsavedData[id],
            [field]: isChecked,
          },
        };
      }
      const updatedUnsavedData = { ...prevUnsavedData };
      delete updatedUnsavedData[id];
      return updatedUnsavedData;
    });
    apiRef.current.updateRows([{ id, [field]: isChecked }]);
  };
  const name = {
    field: 'name',
    headerName: 'Facility name',
    type: 'customText',
    minWidth: 100,
    flex: 2,
  };
  const unconsolidatedGls = {
    field: 'unconsolidatedGls',
    headerName: 'Unconsolidated',
    minWidth: 100,
    flex: 2,
    renderHeader: () => {
      const allChecked = Array.from(apiRef.current.getRowModels().values()).every((row) => row.unconsolidatedGls);
      return (
        <>
          Unconsolidated&nbsp;
          <Tooltip title="Select all">
            <Checkbox
              checked={allChecked}
              sx={{
                mx: 0.5,
              }}
              color="secondary"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleCheckAll(event);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Tooltip>
        </>
      );
    },
    renderCell: ({ id, field, value }) => (
      <Checkbox
        color="secondary"
        checked={!!value}
        onChange={(event) => {
          handleCellChange(id, field, event.target.checked);
        }}
      />
    ),
  };
  const columns = [name, unconsolidatedGls];
  return (
    <>
      {glConsolidationFacilities?.length > 0 && (
        <Grid container m={3}>
          <Grid item xs={4.5}>
            <Box sx={{ backgroundColor: '#fff', padding: 4, boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)' }}>
              <Typography variant="h5" color="primary.main">
                Unconsolidated GLs
              </Typography>
              <Alert severity="info">
                <Typography color="info.main" fontSize={'0.875rem'}>
                  Enabling this feature allows for invoice to be submitted by individual GL item.
                </Typography>
              </Alert>
              <Box sx={{ maxHeight: '500px' }}>
                <CustomDataGrid
                  gridId={"invoice-tab-settings"}
                  forceReRender={glConsolidationFacilities}
                  hideFilterSettings
                  boxSX={{ width: '100%', height: '500px' }}
                  data={glConsolidationFacilities}
                  gridColumns={columns}
                  apiRef={apiRef}
                  sx={{
                    borderColor: '#fff',
                    '& .MuiDataGrid-cell': {
                      '&:focus-within': { outline: `none`, border: `none` },
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: '#fff',
                      color: '#208ee0 !important',
                    },
                    '& .MuiDataGrid-columnHeader': {
                      color: '#208ee0 !important',
                      fontWeight: 'bold',
                    },
                    '& .MuiDataGrid-main': {
                      borderRadius: 0,
                      boxShadow: 0,
                      backgroundColor: '#fff',
                    },

                    '& .MuiIconButton-root': {
                      color: '#208ee0 !important',
                    },
                    '&   .MuiDataGrid-columnHeaders .MuiButtonBase-root .MuiSvgIcon-root': {
                      height: '17px',
                      width: '17px',
                    },
                    '& .MuiDataGrid-sortIcon': {
                      color: '#208ee0 !important',
                    },
                  }}
                />
              </Box>
              {Object.values(unsavedData).length > 0 && (
                <Grid container justifyContent={'center'} paddingTop={2}>
                  <Button
                    variant="outlined"
                    color="error"
                    size="medium"
                    sx={{ width: 150, mr: 2 }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <AwaitButton
                    variant="contained"
                    color="primary"
                    size="medium"
                    sx={{ width: 150 }}
                    onClick={handleSave}
                  >
                    Save
                  </AwaitButton>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
