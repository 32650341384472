import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Grid, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import LoopIcon from '@mui/icons-material/Loop';
import { EMAIL_LIST } from '../../../../reusable-components/datagrid/emailColumns';
import { getSentEmailsData, postResendEmail } from '../../../../api/emails';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { DateRangePickerSelect } from '../../../../reusable-components/date-range-picker';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';

export default function EmailsView() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resendEmails, setResendEmails] = useState(false);
  const [selectedEmailsToResend, setSelectedEmailsToResend] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const getSentEmails = async () => {
    setIsLoading(true);
    const response = await getSentEmailsData({
      startDate: searchParams.get('start'),
      endDate: searchParams.get('end'),
    });
    if (response.status === 200) {
      setData(response.data);
    } else {
      enqueueSnackbar('Error fetching emails', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const handleResendEmails = async (ids) => {
    try {
      setIsLoading(true);
      setData([]);
      const res = await postResendEmail(ids);
      if (res.status !== 200) enqueueSnackbar('Something when wrong', { variant: 'error' });
    } catch (err) {
      console.log(err);
    } finally {
      setSelectedEmailsToResend([]);
      setResendEmails(false);
      getSentEmails();
    }
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => <>
      <IconButton color="info" onClick={() => window.open(`/Api/Admin/SentEmail/DownloadSentEmail?id=${params.id}`, '_blank')}>
        <DownloadIcon id="action-button" className="action-button" />
      </IconButton>
      <ConfirmDialog
        icon="refresh"
        title="Resend Email"
        color="warning"
        content="Are you sure you want to resend this email?"
        actionButton="Resend Email"
        tooltip="Resend Email"
        action={() => handleResendEmails([params.id])}
      />
    </>,
  };

  useEffect(() => {
    getSentEmails({ startDate: searchParams.get('start'), endDate: searchParams.get('end') });
  }, [searchParams]);

  const customToolbar = () => <Grid container direction="row" alignItems="flex-end" spacing={2}>
    <Grid item>
      <DateRangePickerSelect />
    </Grid>
    <Grid item>
      {selectedEmailsToResend.length > 0 ? <AwaitButton
        variant="outlined"
        size="small"
        color="secondary"
        onClick={async () => { await handleResendEmails(selectedEmailsToResend) }}
        startIcon={<LoopIcon />}
      >
        Confirm Resend Emails
      </AwaitButton> : <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={() => setResendEmails(show => !show)}
        startIcon={<LoopIcon />}
      >
        {resendEmails ? "Cancel Resend Emails" : "Resend Emails"}
      </Button>}
    </Grid>
  </Grid>;

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-email-view"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={data}
          gridColumns={[...EMAIL_LIST, actions]}
          sort={[{ field: 'dateAdded', sort: 'desc' }]}
          isLoading={isLoading}
          disableSelectionOnClick
          CustomLeftToolbar={customToolbar}
          checkboxSelection={resendEmails}
          displayCheckboxRight
          onRowSelectionModelChange={(newSelection) => {
            setSelectedEmailsToResend(newSelection.slice(0, 50));
          }}
          rowSelectionModel={selectedEmailsToResend}
        />
      </Grid>
    </>
  );
}
