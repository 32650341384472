import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  TextField,
  Grid,
  DialogContentText,
  Autocomplete,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { addManagers, getPossibleManagers, getPossibleFacilities } from '../../../api/facility-manager';
import { postInvoiceForm } from '../../../api/invoicing';
import { getPendingInvoicing, getInvoice } from '../../../redux/slices/invoicing';
import { getPendingOrders, getPreviousOrders } from '../../../redux/slices/orders';
import { getActiveTickets } from '../../../redux/slices/tickets';
import { useDispatch } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { useScopeCheck } from '../../../reusable-components/scopes';
import { AwaitButton } from '../../../reusable-components/await-button';
import { postEditTicketData } from '../../../api/tickets';

AddManagerModal.propTypes = {
  model: PropTypes.object,
  type: PropTypes.string,
  singleInvoice: PropTypes.bool,
  missingFacility: PropTypes.bool,
};

export default function AddManagerModal({ model, type, singleInvoice = false, missingFacility = false }) {
  const hasFacilityAccess = useScopeCheck(['Facility-Manager'], true);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [assignedManagers, setAssignedManagers] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(searchParams.get('start'));
  const [endDate, setEndDate] = useState(searchParams.get('end'));
  const [isLoading, setIsLoading] = useState(false);
  const [hasFacility, setHasFacility] = useState(!missingFacility);
  const [newFacility, setNewFacility] = useState(null);
  const [facilities, setFacilities] = useState(null);

  useEffect(() => {
    if (missingFacility && open && type) getPossibleFacilities(type).then(res => setFacilities(res.data));
  }, [type, missingFacility, open]);

  useEffect(() => {
    setStartDate(searchParams.get('start'));
    setEndDate(searchParams.get('end'));
  }, [searchParams]);


  const refetchData = () => {
    if (type === 'Orders') {
      dispatch(getPendingOrders());
    } else if (type === 'CompletedOrders') {
      dispatch(getPreviousOrders({ startDate, endDate }));
    } else if (type === 'Tickets') {
      dispatch(getActiveTickets());
    } else if (singleInvoice) {
      dispatch(getInvoice(model.id));
    } else if (type === 'Invoices') {
      dispatch(getPendingInvoicing({ pending: true }));
    }
  }

  const handleSave = async () => {
    const modelId = model.id;
    const response = await addManagers(
      modelId,
      type === 'CompletedOrders' ? 'Orders' : type, //'Orders',
      assignedManagers.map((row) => row.id)
    );

    if (response.status === 200) {
      refetchData();
      setOpen(false);
    }
  };

  const updateFacility = async () => {
    if (type !== 'Invoices' && !singleInvoice && type !== 'Tickets') return;
    const response = type === 'Tickets' ? await postEditTicketData({
      Id: model.id,
      value: newFacility.value,
      updateType: "FacilityId"
    }) : await postInvoiceForm({
      facilityId: newFacility.value,
      id: model.id,
      vendorId: model.vendorId,
      invoiceId: model.invoiceId,
      vendorInvoiceId: model.vendorInvoiceId,
      expectedPrice: model.expectedPrice,
      shipping: model.shipping,
      total: model.total,
      sent: model.sent,
      date: model.date,
      glPostingDate: model.date,
    });
    if (response.status === 200) {
      setIsLoading(true);
      setHasFacility(true);
      getPossibleManagers(model.id, type).then((response) => {
        if (response.data) {
          setRows(response.data);
          const alreadyAssigned = response.data.filter((manager) => manager.alreadyAssigned);
          setAssignedManagers(alreadyAssigned);
          setIsLoading(false);
        } else setIsLoading(false);
      });
      refetchData();
    }
  };

  const assignManager = (type, managerDetails) => {
    const updatedRows = [...rows];

    if (type === 'add') {
      setAssignedManagers((prev) => [...prev, managerDetails]);
      const rowIndex = updatedRows?.findIndex((row) => row.id === managerDetails.id);
      if (rowIndex !== -1) {
        updatedRows[rowIndex].alreadyAssigned = true;
      }
    } else if (type === 'remove') {
      setAssignedManagers((prev) => prev.filter((m) => m.id !== managerDetails.id));
      const rowIndex = updatedRows?.findIndex((row) => row.id === managerDetails.id);
      if (rowIndex !== -1) {
        updatedRows[rowIndex].alreadyAssigned = false;
      }
    }
    setRows(updatedRows);
  };

  const handleClickOpen = (event) => {
    event.addChip = true;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open && !missingFacility) {
      setIsLoading(true);
      getPossibleManagers(model.id, type === 'CompletedOrders' ? 'Orders' : type).then((response) => {
        if (response.data) {
          setRows(response.data);
          const alreadyAssigned = response.data.filter((manager) => manager.alreadyAssigned);
          setAssignedManagers(alreadyAssigned);
          setIsLoading(false);
        } else setIsLoading(false);
      });
    }
  }, [open, model.facilityId, model.id]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 75,
      flex: 1,
      type: 'customText',
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 120,
      flex: 2,
      type: 'customText',
    },
    {
      field: 'ableToView',
      headerName: 'Can view',
      renderCell: (params) => {
        const { value } = params;
        return value && <CheckCircleIcon color="primary" />;
      },
      flex: 1,
    },
    {
      field: 'ableToManage',
      headerName: 'Is manager',
      renderCell: (params) => {
        const { value } = params;
        return value && <CheckCircleIcon color="primary" />;
      },
      flex: 1,
    },
    {
      field: 'alreadyAssigned',
      headerName: 'Assigned',
      renderCell: (params) =>
        params.value ? (
          <IconButton onClick={() => assignManager('remove', params.row)}>
            <CheckCircleIcon color="primary" />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => assignManager('add', params.row)}
            sx={{
              border: 'solid 1px #4ca5e7',
              borderRadius: '5px',
            }}
          >
            <AddCircleOutlineIcon color="info" />
          </IconButton>
        ),
      flex: 1,
    },
  ];

  const typeName = type ? (type[type.length - 1] === 's' ? type.substring(0, type.length - 1) : type).replace(/([A-Z])/g, ' $1').trim() : "";

  return (
    <>
      {' '}
      {hasFacilityAccess && ( //hasFacilityAccess was just on tickets
        <IconButton
          className="add-manager-modal-button"
          onClick={handleClickOpen}
          sx={
            !singleInvoice
              ? {
                  visibility: model.facilityManagerAssignments?.length > 0 ? 'hidden' : 'visible',
                  opacity: model.facilityManagerAssignments?.length > 0 ? 0 : 1,
                  transition: 'visibility 0s, opacity 1s linear',
                  ml: -1,
                }
              : {}
          }
          size="small"
        >
          <AddCircleOutlineIcon color={!singleInvoice ? 'primary' : 'info'} width={20} height={20} />
        </IconButton>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Add Manager To {typeName}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {!hasFacility ? (
            <Box sx={{ color: 'red', textAlign: 'center', mb: 2 }}>
              <Box>
                <strong>Warning:</strong> This {typeName.toLowerCase()} does not have a facility assigned. Please assign a
                facility before adding a manager.
              </Box>
              <Grid item xs={12}>
                <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Facility</DialogContentText>
                <Autocomplete
                  size="small"
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabelRoot ': 'none',
                  }}
                  type="new-password"
                  options={facilities || []}
                  autoHighlight
                  getOptionLabel={(option) => option?.label}
                  value={newFacility || null}
                  onChange={(event, value) => {
                    setNewFacility(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Box>
          ) : (
            <Box sx={{ width: '100%', height: 'calc(100vh - 300px)' }}>
              <DataGridPro
                rows={rows}
                columns={columns}
                isLoading={isLoading}
                sort={[{ field: 'name', sort: 'asc' }]}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
              />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150 }}
            onClick={!hasFacility ? updateFacility : handleSave}
          >
            {!hasFacility ? 'Assign Facility' : 'Save'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
