import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton, Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useSnackbar } from '../../../reusable-components/snackbar';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';

import { RENTAL_FACILITY_ACCOUNTS } from '../../../reusable-components/datagrid/rentalColumns';
import { getFacilityAccounts, setAsPreferredVendor } from '../../../api/rentals';

// RentalVendorAccountsTab.propTypes = {
//   selectedRow: PropTypes.object,
// };

const RentalVendorAccountsTab = ({ selectedRow }) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const actionButtons = (params) => (
    <>
      {' '}
      <Tooltip title="Set as Preferred Vendor" arrow>
        <Box id="action-button" className="action-button">
          <IconButton
            color="info"
            onClick={() => setPreferredVendor(params.row.facilityVendorId, !params.row.isPreferred)}
            id="action-button"
            className="action-button"
          >
            {params.row.isPreferred ? (
              <CheckBoxIcon icon="mdi:checkbox-outline" color="primary" />
            ) : (
              <CheckBoxOutlineBlankIcon icon="bx:checkbox" color="primary" />
            )}
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );

  const setPreferredVendor = async (facilityVendorId, isPreferred) => {
    const response = await setAsPreferredVendor(facilityVendorId, isPreferred);
    if (response.data.success === true) {
      enqueueSnackbar('Preferred vendor updated successfully', { variant: 'success' });
      const row = apiRef.current.getRow(facilityVendorId);
      if (row) {
        const updatedRow = { ...row, isPreferred };
        apiRef.current.updateRows([updatedRow]);
      } else enqueueSnackbar('Error updating Preferred vendor', { variant: 'error' });
    }
  };

  const actions = {
    field: 'actions',
    headerName: 'Preferred Vendor',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => actionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  const fetchData = async () => {
    try {
      setDataLoaded(false);
      const response = await getFacilityAccounts();
      setRows(response);
      setDataLoaded(true);
    } catch (error) {
      console.error('Error fetching vendor data:', error);
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <CustomDataGrid
        apiRef={apiRef}
        gridId="rental-vendor-accounts"
        boxSX={{ width: '100%', height: 'calc(100vh - 260px)' }}
        data={rows}
        getRowId={(row) => row.facilityVendorId}
        gridColumns={[...RENTAL_FACILITY_ACCOUNTS, actions]}
        isLoading={!dataLoaded}
        sort={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
};

export default RentalVendorAccountsTab;
