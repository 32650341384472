import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { postSiteRuleForm } from '../../../../api/site-rules';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

SiteRuleModal.propTypes = {
  edit: PropTypes.bool,
  row: PropTypes.object,
  data: PropTypes.object,
  fetchData: PropTypes.func,
  existingRules: PropTypes.array,
};

export default function SiteRuleModal({ edit, row, data, fetchData, existingRules }) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [ruleDefVal, setRuleDefVal] = useState(null);
  const [value, setValue] = useState({
    id: null,
    ruleId: null,
    ruleTypeId: null,
    ruleValue: { definitionId: null, value: '' },
  });

  useEffect(() => {
    const ruleQuestionValue = data.ruleValueDefinitions?.find((s) => s.ruleId === value?.ruleId) ?? undefined;
    setRuleDefVal(ruleQuestionValue);
    setValue({ ...value, ruleValue: { definitionId: ruleQuestionValue?.id, value: value.ruleValue.value } });
  }, [value?.ruleTypeId, value?.ruleId]);

  const ruleTypeValue = useMemo(() => {
    const ruleType = data?.ruleTypes?.find((r) => r?.value === value.ruleTypeId) ?? null;
    return ruleType;
  }, [data?.ruleTypes, value?.ruleTypeId]);

  const ruleIdValue = useMemo(() => {
    const rule = data.rules?.find((s) => s.value === value.ruleId && s.typeId === value.ruleTypeId) ?? null;
    return rule;
  }, [ruleTypeValue, data?.rules, data?.ruleTypes, value?.ruleId, value.ruleTypeId]);

  const isDuplicateRule = useMemo(
    () =>
      !edit &&
      !!existingRules.find((ruleVal) => ruleVal.ruleTypeId === value.ruleTypeId && ruleVal.ruleId === value.ruleId),
    [existingRules, value]
  );

  const handleSave = async () => {
    const response = await postSiteRuleForm(value, edit ? row?.id : null);
    if (response.data) {
      enqueueSnackbar('Site Rule updated successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to update site rule', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      id: edit ? row?.id : null,
      ruleId: edit ? row?.ruleId : null,
      ruleTypeId: edit ? row?.ruleTypeId : null,
      ruleValue: edit
        ? { definitionId: row?.values[0]?.definitionId, value: row?.values[0]?.value }
        : { definitionId: null, value: '' },
    });
    setOpen(true);
  };

  const handleClose = () => {
    setValue({
      id: null,
      ruleId: null,
      ruleTypeId: null,
      ruleValue: { definitionId: null, value: '' },
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <Tooltip title="Edit">
          <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
          size="small"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Rule
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {edit ? 'Edit' : 'Add'} Site Rule
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          {isDuplicateRule && (
            <Alert severity="error" sx={{ width: '100%' }}>
              This rule already exists
            </Alert>
          )}
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ p: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">Type</Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={ruleTypeValue}
                  onChange={(event, newValue) => {
                    setValue({ ...value, ruleTypeId: newValue?.value, ruleId: null });
                  }}
                  options={data?.ruleTypes}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">Rule</Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={ruleIdValue}
                  onChange={(event, newValue) => {
                    setValue({ ...value, ruleId: newValue?.value });
                  }}
                  options={data?.rules?.filter((r) => r.typeId === value.ruleTypeId)}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            {ruleDefVal && ruleDefVal != null && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 1.5 }}
                spacing={2}
              >
                <Box sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">{ruleDefVal?.label}</Typography>
                  <TextField
                    type={
                      ruleDefVal.useDecimal || ruleDefVal.useCurrency || ruleDefVal.useNumber
                        ? 'number'
                        : ruleDefVal.useDate
                        ? 'date'
                        : 'text'
                    }
                    fullWidth
                    size="small"
                    value={value.ruleValue.value}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        ruleValue: { definitionId: value.ruleValue.definitionId, value: e.target.value },
                      });
                    }}
                    InputProps={
                      ruleDefVal.useCurrency && {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }
                    }
                    sx={{
                      mt: 1,
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      '& .MuiFormLabel-root ': { display: 'none' },
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150 }}
            onClick={handleSave}
            disabled={!value.ruleId || !value.ruleTypeId || isDuplicateRule}
          >
            {edit ? 'Save' : 'Add Rule'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
