import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';

function ClickOff(ref, onClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if(onClick) onClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside);  };
  }, [ref]);
}


OnClickOffWrapper.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node
};


export default function OnClickOffWrapper({ children, onClick }) {
  const wrapperRef = useRef(null);
  ClickOff(wrapperRef, onClick);

  return <div ref={wrapperRef}>{children}</div>;
}