import PropTypes from 'prop-types';
import { useState } from 'react';
import { TextField, IconButton, Tooltip } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';

import { useUpdateCartItemRedux } from '../../hooks/useUpdateCartItemRedux';
import { useScopeCheck } from '../scopes';

QuantityCartField.propTypes = {
  row: PropTypes.object,
};

export default function QuantityCartField({ row }) {
  const hasEditAccess = useScopeCheck(['View-Order'], true);
  const updateCartItemRedux = useUpdateCartItemRedux();
  const [quantity, setQuantity] = useState(row.quantity);

  return hasEditAccess ? (
    <>
      <TextField
        size="small"
        value={quantity || ''}
        onChange={(e) => {
          const newValue = Number(e.target.value);
          if (!Number.isNaN(newValue) && newValue >= 0) {
            if (row?.origQty === undefined) {
              updateCartItemRedux(row.id, 'origQty', row.quantity);
            }
            updateCartItemRedux(row.id, 'quantity', newValue);
            setQuantity(newValue);
          }
        }}
      />
      {row?.origQty !== undefined && row?.origQty !== quantity && (
        <Tooltip title={row?.origQty} placement="top">
          <IconButton
            onClick={() => {
              setQuantity(row?.origQty);
              updateCartItemRedux(row.id, 'quantity', row?.origQty);
            }}
            sx={{
              padding: '0px',
              margin: '0px',
              marginLeft: '5px',
            }}
            color={quantity === row?.origQty ? '' : 'secondary'}
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  ) : (
    <>
      <TextField size="small" value={quantity || ''} />
    </>
  );
}
