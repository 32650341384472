import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Typography, Button, TextField, DialogActions } from '@mui/material';
import { postAPContactForm } from '../../../../api/facility';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';

BookingEmailForm.propTypes = {
  fetchData: PropTypes.func,
  facilityRow: PropTypes.object,
  editRow: PropTypes.object,
  openForm: PropTypes.bool,
  close: PropTypes.func,
};

export default function BookingEmailForm({ fetchData, editRow, facilityRow, openForm, close })
{
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    email: '',
  });

  useEffect(() => setValue({
    email: editRow?.email || '',
  }), [editRow, openForm])

  const handleSave = async () => {
    const response = await postAPContactForm(facilityRow?.id, value.email, editRow?.id);
    if (response.data) {
      enqueueSnackbar(response.data?.message, {
        variant: response.data?.success ? 'success' : 'error',
      });
    } else {
      enqueueSnackbar('Failed to add email', { variant: 'error' });
    }
    fetchData();
    close();
  };

  return <Dialog open={openForm} onClose={close} fullWidth maxWidth = "sm" >
    <DialogTitle sx={{ textAlign: 'center' }} >
      {editRow ? 'Edit' : 'Add'} Booking Email
      <CloseIconButton onClick={close} />     
    </DialogTitle>
    <DialogContent>
      <Typography variant="subtitle1">Email</Typography>
      <TextField
        size="small"
        fullWidth
        value={value?.email ?? ''}
        onChange={(e) => {
          setValue({
            ...value,
            email: e.target.value,
          });
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={close}>
        Cancel
      </Button>
      <AwaitButton
        variant="contained"
        color="primary"
        size="medium"
        disabled={value.email === ''}
        sx={{ width: 150 }}
        onClick={handleSave}
      >
        {editRow ? 'Save' : 'Add Email'}
      </AwaitButton>
    </DialogActions>
  </Dialog>;
}
