import { Box, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../../reusable-components/iconify';
import { HOST_API_URL } from '../../../../config-global';
import NewItemDialog from './NewItemDialog';

CustomToolbar.propTypes = {
  setViewAllSKUs: PropTypes.func,
  viewAllSKUs: PropTypes.bool
};

export default function CustomToolbar({ viewAllSKUs, setViewAllSKUs })
{

  const downloadCatalog = async () => {
    //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
    window.open(`${HOST_API_URL}Api/DownloadCatalog`, '_blank');
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
        <Box sx={{ display: 'inline-block', mr: 1 }}>
          <NewItemDialog />
        </Box>
        <Box sx={{ display: 'inline-block' }}>
          <Button
            variant={viewAllSKUs ? "contained" : "outlined"}
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            onClick={() => {
              setViewAllSKUs(viewState => !viewState);
              const currentUrl = new URL(window.location.href);
              if (viewAllSKUs) currentUrl.searchParams.delete("viewallskus")
              else currentUrl.searchParams.set("viewallskus", "1");
              window.history.replaceState(null, document.title, currentUrl);
            }}
            startIcon={<Iconify icon="eva:search-fill" />}
          >
            View All {viewAllSKUs ? "Products" : "SKUs"}
          </Button>
        </Box>
        <Box sx={{ display: 'inline-block' }}>
          <Button
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            onClick={downloadCatalog}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download
          </Button>
        </Box>        
      </Grid>
    </>
  );
}
