import { createSlice } from '@reduxjs/toolkit';
import { getFacilityCensusData, getFacilityCensusDataByFacilityId, setFacilityCensusData } from '../../api/census';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: {
    facilities: [],
    facility: {},
  },
};

const slice = createSlice({
  name: 'census',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFacilitysDataSuccess(state, action) {
      state.isLoading = false;
      state.data.facilities = action.payload;
    },
    getFacilityCensusDataSuccess(state, action) {
      state.isLoading = false;
      state.data.facility = action.payload;
    },

    getUserSuccess(state, action) {
      state.isLoading = false;
      state.data.user = {
        ...state.data.users.find((user) => user.id === action.payload.userId),
        ...action.payload.user,
      };
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------
export function getFacilitys() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFacilityCensusData();
      dispatch(slice.actions.getFacilitysDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getFacilitysById(facilityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFacilityCensusDataByFacilityId(facilityId);
      dispatch(slice.actions.getFacilityCensusDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setFacilityCensus({ dates, facilityId, fetchDaysForBudgets }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await setFacilityCensusData({ dates, facilityId, fetchDaysForBudgets });
      dispatch(slice.actions.getFacilityCensusDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
