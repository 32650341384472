import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { useSelector } from '../../../../redux/store';
import { getRentalIconForSubcategory } from '../../../../utils/cssStyles';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function RentalSubcategorySelect() {
  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchParams] = useSearchParams();
  const selectedCategory = searchParams.get('category');
  const category = searchParams.get('category');
  const facilityId = searchParams.get('facilityId');
  const residentId = searchParams.get('residentId');
  const handleItemClick = (item) => {
    setSelectedItem(item);
    navigate(
      `/dashboard/rentals/new-rental?facilityId=${facilityId}&category=${category}&residentId=${residentId}&subcategory=${item}`
    );
  };
  const filteredSubcategories = categoryData
    .filter((item) => item.categoryName === selectedCategory)
    .map((item) => item.subcategoryName);

  return (
    <>
      <div style={{ width: '100%' }}>
        <header>
          {/* SubCategory selection*/}
          <Grid container spacing={3} sx={{}}>
            {selectedCategory &&
              filteredSubcategories.map((subcategoryName, index) => (
                <Grid item key={subcategoryName}>
                  <AwaitButton
                    sx={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      color: selectedCategory === subcategoryName ? '#8cc53f' : 'black',
                    }}
                    onClick={() => handleItemClick(subcategoryName)}
                    style={{
                      color: selectedItem === subcategoryName ? '#8cc53f' : 'black',
                    }}
                  >
                    <Iconify
                      icon={getRentalIconForSubcategory(subcategoryName)}
                      width={24}
                      height={24}
                      style={{ marginRight: '3px' }}
                    />{' '}
                    {subcategoryName}
                  </AwaitButton>
                </Grid>
              ))}
          </Grid>
        </header>
      </div>
    </>
  );
}
