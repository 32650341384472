import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Button, Menu, Typography, Grid, Avatar, Tooltip, Divider, Box } from '@mui/material';
// utils
import Iconify from '../../../reusable-components/iconify';
import { getCartFacilities } from '../../../api/order-sheet';

export default function OrderRentalGlobalCart() {
  const [carts, setCarts] = useState([]);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setCarts([]);
    setAnchorEl(null);
  };
  async function fetchCartFacilities() {
    const response = await getCartFacilities();
    if (response.data.length) {
      setCarts(response.data);
    } else {
      setCarts([{ facilityData: { label: 'No Carts', value: false }, purchasing: false }]);
    }
  }

  useEffect(() => {
    if (open && carts?.length === 0) {
      fetchCartFacilities();
    }
  }, [carts?.length, open]);

  useEffect(() => {}, [carts]);

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        size="small"
      >
        <Iconify icon="mdi:shopping-cart-outline" width={24} height={24} />
        <Iconify icon="mdi:chevron-down" width={24} height={24} />
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {carts.map((cart, index) => (
          <div key={index}>
            <Grid container direction="row" alignItems="center" sx={{ mb: 0.5, mt: 0.5 }}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ ml: 2, mr: 2 }}>
                  {cart?.facilityData?.label}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  {cart.purchasing && (
                    <Button
                      onClick={() => {
                        handleClose();
                        navigate(`/dashboard/orders/new-order/cart/${cart?.facilityData?.value}`);
                      }}
                      color="primary"
                      style={{ minWidth: '0', padding: '.5', marginLeft: '4px' }}
                    >
                      <Tooltip title={`Purchasing`}>
                        <Avatar
                          sx={{
                            height: 30,
                            width: 30,
                            backgroundColor: '#fff',
                            border: '1px solid #b128bc',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          <Iconify icon="clarity:store-line" height={20} width={20} style={{ color: '#b128bc' }} />
                        </Avatar>
                      </Tooltip>
                    </Button>
                  )}
                  {!cart.purchasing && (
                    <Button
                      onClick={() => {
                        handleClose();
                        navigate(`/dashboard/rentals/rental-orders/rental-cart/${cart?.facilityData?.value}`);
                      }}
                      color="primary"
                      style={{ minWidth: '0', padding: '.5', marginLeft: '4px' }}
                    >
                      <Tooltip title={`Rentals`}>
                        <Avatar
                          sx={{
                            height: 30,
                            width: 30,
                            backgroundColor: '#fff',
                            border: '1px solid #4ca5e7',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          <Iconify icon="carbon:hospital-bed" height={20} width={20} style={{ color: '#4ca5e7' }} />
                        </Avatar>
                      </Tooltip>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Box>
              {index < carts.length - 1 && (
                <Divider
                  variant="fullWidth"
                  sx={{
                    left: '40px',
                    right: '40px',
                    top: '4px',
                    bottom: '4px',
                    height: '1px',
                    backgroundColor: 'black',
                  }}
                />
              )}
            </Box>
          </div>
        ))}
      </StyledMenu>
    </>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
    sx={{
      ml: 10,
      mt: 0.5,
    }}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    overflow: 'auto',
    border: 'solid 1px #c1c9d0',
    minWidth: 230,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      borderBottom: '0.5px solid #637381',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
}));
