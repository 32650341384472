import { Helmet } from 'react-helmet-async';
import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserView } from '../../../../components/dashboard/admin/users/user';
import Iconify from '../../../../reusable-components/iconify';

export default function User() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Admin User | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              variant="text"
              onClick={() => {
                navigate(`/dashboard/admin/users`);
              }}
              startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
              sx={{
                color: '#454F5B',
                fontWeight: 'bold',
              }}
            >
              Back to users
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <UserView />
        </Grid>
      </Grid>
    </>
  );
}
