import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import {
  TextField,
  FormControl,
  MenuItem,
  Stack,
  Divider,
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useSelector, dispatch } from '../../../../redux/store';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { postAddResident } from '../../../../redux/slices/rentals';

const filter = createFilterOptions();

ResidentInformation.propTypes = {
  setResident: PropTypes.func,
  resident: PropTypes.object,
  facility: PropTypes.object,
};

export default function ResidentInformation({ resident, setResident, facility }) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { residentsList },
  } = useSelector((state) => state.rentals);

  const [open, toggleOpen] = useState(false);
  const [showSecondaryInsuranceControls, setShowSecondaryInsuranceControls] = useState(false);
  const handleSecondaryInsuranceControlsButtonClick = () => {
    setShowSecondaryInsuranceControls(true);
  };
  const handleClose = () => {
    setDialogValue({
      firstName: '',
      lastName: '',
      gender: '',
      dob: '',
      floor: '',
      unit: '',
      room: '',
      bed: '',
      height: '',
      weight: '',
      primaryInsurance: '',
      primaryInsuranceKind: '',
      primaryInsurancePolicyNum: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    firstName: '',
    lastName: '',
    gender: '',
    dob: '',
    floor: '',
    unit: '',
    room: '',
    bed: '',
    height: '',
    weight: '',
    primaryInsurance: '',
    primaryInsuranceKind: '',
    primaryInsurancePolicyNum: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await dispatch(postAddResident(dialogValue, facility.id));
    if (response) {
      setResident(response);
    } else {
      enqueueSnackbar(`Failed to create resident`, { variant: 'error' });
    }
    handleClose();
  };

  return (
    <>
      <DialogTitle
        sx={{
          textAlign: 'center',
          color: 'rgba(112, 112, 112, 0.5)',
        }}
      >
        Resident Information
      </DialogTitle>
      <DialogContentText sx={{ fontWeight: 'bold' }}>Resident Name</DialogContentText>{' '}
      <Autocomplete
        sx={{
          width: '100%',
          mt: 1,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiFormLabel-root ': 'none',
        }}
        value={resident || null}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                firstName: newValue,
                lastName: '',
                gender: '',
                dob: '',
                floor: '',
                unit: '',
                room: '',
                bed: '',
                height: '',
                weight: '',
                primaryInsurance: '',
                primaryInsuranceKind: '',
                primaryInsurancePolicyNum: '',
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              firstName: newValue.inputValue,
              lastName: '',
              gender: '',
              dob: '',
              floor: '',
              unit: '',
              room: '',
              bed: '',
              height: '',
              weight: '',
              primaryInsurance: '',
              primaryInsuranceKind: '',
              primaryInsurancePolicyNum: '',
            });
          } else {
            setResident(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              firstName: `Add "${params.inputValue}"`,
              lastName: '',
              dob: new Date(''),
              gender: '',
              floor: '',
              unit: '',
              room: '',
              bed: '',
              height: 0,
              weight: 0,
              primaryInsurance: '',
              primaryInsuranceKind: '',
              primaryInsurancePolicyNumber: '',
            });
          }

          return filtered;
        }}
        id="resident-search-dialog"
        options={residentsList}
        getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li
            {...props}
            style={{ color: option?.inputValue ? 'purple' : 'inherit' }}
          >{`${option?.firstName} ${option?.lastName}`}</li>
        )}
        freeSolo
        forcePopupIcon
        renderInput={(params) => <TextField {...params} title="Resident Name" />}
      />
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
            color: '#8cc53f',
            fontSize: '24px',
            fontWeight: 'bold',
            marginTop: 4,
            mb: -2,
          }}
        >
          New Rental
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              textAlign: 'center',
              color: 'rgba(112, 112, 112, 0.5)',
              fontWeight: 'medium',
            }}
          >
            New Resident
          </DialogTitle>
          <DialogContent>
            <Box>
              <Stack spacing={3}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box>
                    <FormControl fullWidth>
                      <TextField
                        label="First Name"
                        required
                        size="small"
                        autoFocus
                        type="text"
                        value={dialogValue.firstName}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            firstName: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl fullWidth>
                      <TextField
                        label="Last Name"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.lastName}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            lastName: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box>
                    <FormControl>
                      <TextField
                        label="Gender"
                        required
                        size="small"
                        fullWidth
                        select
                        value={dialogValue.gender}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            gender: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                          minWidth: '222px',
                          maxWidth: '222px',
                          ml: 4,
                        }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </TextField>
                    </FormControl>
                  </Box>
                  <Box>
                    {' '}
                    <FormControl fullWidth>
                      <Box
                        sx={{
                          width: '100%',
                          minWidth: '222px',
                          maxWidth: '222px',
                          mr: 4,
                        }}
                      >
                        <DatePicker
                          disableFuture
                          required
                          slotProps={{ textField: { size: 'small' } }}
                          label="Date of Birth*"
                          value={dialogValue.dob ? new Date(dialogValue.dob) : null}
                          onChange={(date) =>
                            setDialogValue({
                              ...dialogValue,
                              dob: date ? new Date(date.getFullYear(), date.getMonth(), date.getDate()) : null,
                            })
                          }
                          sx={{
                            width: '100%',
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box>
                    <FormControl fullWidth id="height">
                      <TextField
                        label="Height"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.height}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            height: parseFloat(event.target.value),
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    {' '}
                    <FormControl fullWidth id="weight">
                      <TextField
                        label="Weight"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.weight}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            weight: parseFloat(event.target.value),
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box>
                    <Box>
                      <Divider sx={{ width: '475px', borderColor: '#4ca5e7', borderStyle: 'dashed' }} />
                    </Box>
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box>
                    <FormControl fullWidth id="floor">
                      <TextField
                        label="Floor"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.floor}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            floor: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl fullWidth id="unit">
                      <TextField
                        label="Unit"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.unit}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            unit: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box>
                    <FormControl fullWidth id="room">
                      <TextField
                        label="Room"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.room}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            room: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl fullWidth id="bed">
                      <TextField
                        label="Bed"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.bed}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            bed: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box>
                    <Box>
                      <Divider sx={{ width: '475px', borderColor: '#4ca5e7', borderStyle: 'dashed' }} />
                    </Box>
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Box>
                    <FormControl fullWidth id="primaryInsurance">
                      <TextField
                        label="Primary Insurance"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.primaryInsurance}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            primaryInsurance: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl fullWidth id="primaryInsuranceKind">
                      <TextField
                        label="Primary Insurance Kind"
                        required
                        size="small"
                        type="text"
                        value={dialogValue.primaryInsuranceKind}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            primaryInsuranceKind: event.target.value,
                          })
                        }
                        sx={{
                          width: '100%',
                        }}
                      />
                    </FormControl>
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <Box>
                    <Box>
                      <FormControl fullWidth id="primaryInsurancePolicyNumber">
                        <TextField
                          label="Primary Insurance Policy Number"
                          required
                          size="small"
                          type="text"
                          value={dialogValue.primaryInsurancePolicyNum}
                          onChange={(event) =>
                            setDialogValue({
                              ...dialogValue,
                              primaryInsurancePolicyNum: event.target.value,
                            })
                          }
                          sx={{
                            width: '100%',
                            mr: 30,
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Stack>
                {showSecondaryInsuranceControls && (
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Box>
                      <Box>
                        <Divider sx={{ width: '475px', borderColor: '#4ca5e7', borderStyle: 'dashed' }} />
                      </Box>
                    </Box>
                  </Stack>
                )}
                {!showSecondaryInsuranceControls && (
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <Box>
                      <FormControl fullWidth id="secondaryInsuranceButton">
                        <Button
                          onClick={handleSecondaryInsuranceControlsButtonClick}
                          variant="outlined"
                          color="secondary"
                          sx={{ justifyContent: 'center', width: '100%', mr: 5, ml: 9 }}
                        >
                          + Add Secondary Insurance
                        </Button>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                      }}
                    />
                  </Stack>
                )}
                {showSecondaryInsuranceControls && (
                  <>
                    {' '}
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                      <Box>
                        <FormControl fullWidth id="secondaryInsurance">
                          <TextField
                            label="Secondary Insurance"
                            size="small"
                            type="text"
                            value={dialogValue.secondaryInsurance}
                            onChange={(event) =>
                              setDialogValue({
                                ...dialogValue,
                                secondaryInsurance: event.target.value,
                              })
                            }
                            sx={{
                              width: '100%',
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth id="secondaryInsuranceKind">
                          <TextField
                            label="Secondary Insurance Kind"
                            size="small"
                            type="text"
                            value={dialogValue.secondaryInsuranceKind}
                            onChange={(event) =>
                              setDialogValue({
                                ...dialogValue,
                                secondaryInsuranceKind: event.target.value,
                              })
                            }
                            sx={{
                              width: '100%',
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      <Box>
                        <FormControl fullWidth id="secondaryInsurancePolicyNumber">
                          <TextField
                            label="Secondary Insurance Policy Number"
                            size="small"
                            type="text"
                            value={dialogValue.secondaryInsurancePolicyNumber}
                            onChange={(event) =>
                              setDialogValue({
                                ...dialogValue,
                                secondaryInsurancePolicyNumber: event.target.value,
                              })
                            }
                            sx={{
                              width: '100%',
                              mr: 30,
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Stack>{' '}
                  </>
                )}
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            {' '}
            <Button
              type="submit"
              variant="contained"
              sx={{ display: 'flex', justifyContent: 'center', width: '60%', mt: 2, mb: 2 }}
            >
              Next
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
