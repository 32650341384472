import PropTypes from 'prop-types';
import 'quill-mention';
import { useCallback, useState, useEffect, useRef } from 'react';
import { Switch, Tooltip, FormControlLabel, FormGroup, Grid, IconButton } from '@mui/material';

import { useDropzone } from 'react-dropzone';
import ReactQuill from 'react-quill';
import { useTabs } from '../../context/TabContext';
import '../../utils/highlight';
import { StyledEditor } from './styles';
import { AwaitButton } from '../await-button';
import Iconify from '../iconify';

// ---------------------------------------------------------------------

CommentEditor.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  users: PropTypes.array,
};

export default function CommentEditor({ error = false, value, onChange, sx = {}, users }) {
  const inputRef = useRef(null);
  const handleChangeMessage = (e) => {
    onChange(e);
  };

  const modules = {
    toolbar: false,
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      isolateCharacter: true,
      mentionDenotationChars: ['@'],
      spaceAfterInsert: true,
      defaultMenuOrientation: 'top',
      positioningStrategy: 'fixed',
      showDenotationChar: false,
      source: useCallback((searchTerm, renderList) => {
        if (searchTerm.length > 0) {
          const matches = [];
          for (let i = 0; i < users?.length; i++) {
            if (~users[i].value?.toLowerCase().indexOf(searchTerm.toLowerCase())) {
              matches.push(users[i]);
              //stop searching when 5 results are found
              if (matches.length >= 5) break;
              renderList(matches, searchTerm);
            }
          }
        }
      }, []),
    },
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <div
    // style={{
    //   width: '100%',
    //   // border: isDragOver ? '2px dashed #1976d2' : '2px dashed transparent',
    //   // boxShadow: isDragOver ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
    //   transition: 'all 0.2s ease-in-out',
    //   position: 'relative', // For absolute positioning of the drop message
    // }}
    >
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
          width: '100%', // Ensure StyledEditor is full width
       
        }}
      >
        <ReactQuill
          ref={inputRef}
          value={value}
          onChange={handleChangeMessage}
          modules={modules}
          style={{ width: '100%', height: 'auto' }} // Ensure ReactQuill is full width
          placeholder="Hi..."
        />
      </StyledEditor>
    </div>
  );
}
