import { Autocomplete, Box, Chip, IconButton, Popover, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { editTicketData } from '../../../../redux/slices/tickets';
import { useSelector } from '../../../../redux/store';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import Iconify from '../../../../reusable-components/iconify';

NewUser.propTypes = {
  participants: PropTypes.array,
  setParticipants: PropTypes.func,
  ticketId: PropTypes.number,
  parentTicketId: PropTypes.number,
};

export default function NewUser({ participants, setParticipants, ticketId, parentTicketId }) {
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const theId = parentTicketId > 0 ? parentTicketId : ticketId;
  const { users } = useSelector((state) => state.tickets.data.ticketInfoById[theId]) || [];
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const editTicket = ({ value, updateType }) => {
    dispatch(editTicketData({ Id: ticketId, value, updateType, mainId: id }));
  };

  const handleUsers = (newValue) => {
    const updatedParticipants = newValue?.map((item) => {
      if (typeof item === 'string') {
        return { id: item, value: item };
      }
      return item;
    });
    setParticipants(updatedParticipants);
    editTicket({
      Id: ticketId,
      value: updatedParticipants.map((user) => user.id),
      updateType: 'Users',
    });
  };

  return (
    <>
      <Typography variant="subtitle2">
        {
          <Tooltip title="Add recipients" placement="top" arrow>
            <IconButton onClick={handleOpen} sx={{ padding: 0 }} color="secondary">
              <Iconify icon="material-symbols:add" width={20} height={20} sx={{ mr: 0.5 }} />
            </IconButton>
          </Tooltip>
        }
      </Typography>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { p: 0, mt: 1.5, ml: 0.75 } }}
      >
        <Box sx={{ p: 2, maxWidth: 400, minWidth: 400, overflow: 'hidden' }}>
          {' '}
          <Autocomplete
            multiple
            id="tags-outlined"
            options={users || []}
            getOptionLabel={(option) => `${option?.value} - ${option?.id}`}
            value={participants}
            onChange={(event, newValue) => {
              handleUsers(newValue);
            }}
            filterSelectedOptions
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add recipients"
                placeholder="Add user"
                fullWidth={false}
                sx={{ maxWidth: 400, minWidth: 350 }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Box sx={{ mb: 1, display: 'inline-block' }} key={index}>
                  <Chip
                    label={option?.value ?? option?.id}
                    {...getTagProps({ index })}
                    avatar={<CustomAvatar name={option?.value ?? option.id} />}
                    sx={{ mr: 1, mb: 1 }}
                  />
                </Box>
              ))
            }
          />
        </Box>
      </Popover>
    </>
  );
}
