import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTabs } from '../../context/TabContext';
import { useDispatch, useSelector } from '../../redux/store';
import { getTicketData } from '../../redux/slices/tickets';

const Logo = forwardRef(({ disabledLink = false, sx }, ref) => {
  const navigate = useNavigate();
  const { clearFiltersAndNavigate } = useTabs();
  const dispatch = useDispatch();
  const ticketId = 73350;

  const logo = <Box ref={ref} component="img" src="/assets/logo.png" sx={{ width: 120, ...sx }} />;

  if (disabledLink) {
    return <>{logo}</>;
  }
  return (
    <Box
      ref={ref}
      component="img"
      src="/assets/logo.png"
      sx={{ width: 120, cursor: 'pointer', ...sx }}
      onClick={() => clearFiltersAndNavigate('/dashboard/home')}
      //testing multiple dispatches
      // onClick={() => {
      //   dispatch(getTicketData(ticketId));
      //   setTimeout(() => {
      //     dispatch(getTicketData(ticketId + 1));
      //   }, 1);

      // }}
    />
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
