import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { ShoppingCart } from './index';
import { useSelector } from '../../../../../redux/store';
import { BudgetBox } from '../index';
import { ScopeGuard, useScopeCheck } from '../../../../../reusable-components/scopes';

CartView.propTypes = {
  items: PropTypes.object,
  setShowSelection: PropTypes.func,
  showSelection: PropTypes.string,
  showErrorMsg: PropTypes.bool,
};

export default function CartView({ items, setShowSelection, showSelection, showErrorMsg })
{
  const hasBudgetViewAccess = useScopeCheck(['View-Order'], true);
  const { data: { budgets } } = useSelector((state) => state.cart);
  const { facilityId = '' } = useParams();

  if (!items || items?.items.length === 0) return null;

  return (
    <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">  
      <Grid item xs={hasBudgetViewAccess ? 9 : 12}>
        <ShoppingCart
          items={items}
          showSelection={showSelection}
          showErrorMsg={showErrorMsg}
          setShowSelection={setShowSelection}
        />
      </Grid>
      <ScopeGuard scopes={['View-Order']} allowAdmin>
        <Grid item xs={3} sx={{ mt: 7 }}>
          <BudgetBox
            catId={budgets?.find((budget) => budget.category === items?.agoraCategory)?.agoraCategoryId}
            cart={items.items?.filter((item) => item.quantity > 0)}
            facId={facilityId}
          />
        </Grid>
      </ScopeGuard>
    </Grid>
  );
}
