import {
  Box, DialogContentText, Grid, Paper, Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';

NewVendorModalDocUpload.propTypes = {
  attachments: PropTypes.array,
  setAttachments: PropTypes.func,
  acceptedFileTypes: PropTypes.object,
  maxFiles: PropTypes.number,
};

export default function NewVendorModalDocUpload({ attachments, setAttachments, acceptedFileTypes, maxFiles = 10 }) {
  const fileInputRef = useRef(null);

  const removeFile = (file) => () => {
    setAttachments((prevAttachments) => prevAttachments.filter((x) => x !== file));
  };

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: acceptedFileTypes,
  });

  const handleFileInputChange = (event) => {
    const maxAllowedFiles = maxFiles;
    if (
      event.target.files.length > maxAllowedFiles ||
      event.target.files.length + attachments?.length > maxAllowedFiles
    ) {
      alert(`Only ${maxAllowedFiles} files can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    const { files } = event.target;
    const fileList = [];
    for (let i = 0; i < files.length; i++) {
      if (attachments?.some((x) => x.name === files[i].name)) {
        alert(`File ${files[i].name} already exists.`);
      } else fileList.push(files[i]);
    }
    setAttachments((prevAttachments) => {
      if (prevAttachments === undefined) {
        return fileList;
      }
      return [...prevAttachments, ...fileList];
    });
  };


  return (
    <>
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
        W9 Files
      </Typography>
      <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
        <input {...getInputProps()} />
        <Paper
          elevation={isDragActive ? 6 : 1}
          sx={{
            p: 1,
            bgcolor: 'background.paper',
            color: 'info.main',
            borderColor: 'info.main',
            border: 1,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              bgcolor: 'background.default',
              boxShadow: 3,
            },
          }}
        >
          <Typography variant="body2" textAlign="center">
            Click to Upload or Drag and Drop
          </Typography>
        </Paper>
      </Box>

      <Box>
        <Stack
          direction="row"
          alignItems="flex-start"
          sx={{ pl: 4 }}>
          {attachments?.map((file) => (
            <FileThumbnail
              key={file.name}
              file={file}
              tooltip
              sx={{ width: 64, height: 64 }}
              onRemove={removeFile(file)}
            />
          ))}
        </Stack>
      </Box>

      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
    </>
  );
}
