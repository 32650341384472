import { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  Tooltip,
  Box,
  Grid,
  Button,
  TextField,
  DialogContentText,
  Autocomplete,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Paper,
} from '@mui/material';
import Iconify from '../../../../../reusable-components/iconify';
import {
  fetchVendorProductData as catalogFetchVendorProductData,
  possibleProductUOMsData as catalogPossibleProductUOMsData,
  postAddProductImage as catalogPostAddProductImage,
} from '../../../../../api/catalog';
import {
  fetchVendorProductData as warehouseFetchVendorProductData,
  possibleProductUOMsData as warehousePossibleProductUOMsData,
  postAddProductImage as warehousePostAddProductImage,
} from '../../../../../api/warehouse';
import { useSelector } from '../../../../../redux/store';
import { UOM_TYPES } from '../../../../../utils/dropdowns';
import FileThumbnail from '../../../../../reusable-components/file-thumbnail';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import { cloudinaryFull } from '../../../../../utils/formatCloudinary';
import { formatMoney } from '../../../../../utils/formatNumber';

EditItem.propTypes = {
  allItems: PropTypes.array,
  setAllItems: PropTypes.func,
  id: PropTypes.number,
  setPpdOptions: PropTypes.func,
  ppdOptions: PropTypes.array,
  isWarehouseItem: PropTypes.bool,
};

export default function EditItem({ allItems, setAllItems, id, setPpdOptions, ppdOptions, isWarehouseItem = false }) {
  const {
    data: { vendors },
  } = useSelector((state) => (isWarehouseItem ? state.warehouse : state.catalog));

  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [integratedVendor, setIntegratedVendor] = useState(isWarehouseItem);
  const [uomTypes, setUomTypes] = useState(null);
  const [gettingItemInfo, setGettingItemInfo] = useState(false);
  const fileInputRef = useRef(null);

  const [textFieldUrl, setTextFieldUrl] = useState(''); // Separate state for TextField

  const handleChangeTextField = (newUrl) => {
    setTextFieldUrl(newUrl);
  };

  const handleAddImage = async () => {
    let newImageUrl = textFieldUrl;

    if (!textFieldUrl.includes('//res.cloudinary.com/agoraserv')) {
      const imgUrl = isWarehouseItem
        ? await warehousePostAddProductImage(textFieldUrl)
        : await catalogPostAddProductImage(textFieldUrl);
      if (imgUrl.data.length) {
        newImageUrl = imgUrl.data;
      } else {
        enqueueSnackbar('Invalid image url', { variant: 'error' });
        return;
      }
    }

    const updatedPictures = allItems.map((item) => {
      if (item.id === id) {
        return { ...item, pictures: [...item.pictures, newImageUrl] };
      }
      return item;
    });

    setAllItems(updatedPictures);
    setTextFieldUrl('');
  };

  const handleRemoveImage = (index) => {
    const removedPictures = allItems.map((item) => {
      if (item.id === id) {
        const newPictures = [...item.pictures];
        if (newPictures.length > 1) {
          newPictures?.splice(index, 1);
        } else {
          newPictures.length = 0;
        }

        return { ...item, pictures: newPictures };
      }
      return item;
    });
    setAllItems(removedPictures);
  };

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: true,
  });
  const handleFileInputChange = async (event) => {
    const { files } = event.target;

    const promiseArr = Array.from(files)
      ?.map((file) => {
        if (!file?.type.startsWith('image/')) {
          enqueueSnackbar('Invalid file type', { variant: 'error' });
          return;
        }
        // eslint-disable-next-line consistent-return
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function onFileLoad() {
            const base64Image = reader.result.split('base64,')[1];
            resolve(
              isWarehouseItem ? warehousePostAddProductImage(base64Image) : catalogPostAddProductImage(base64Image)
            );
          };
          reader.onerror = function onFileLoadError(error) {
            enqueueSnackbar('Error uploading image', { variant: 'error' });
            reject(error);
          };
        });
      })
      .filter(Boolean);

    const imageUrls = await Promise.all(promiseArr);

    const updatedDetails = allItems.map((item) => {
      if (item.id === id) {
        return { ...item, pictures: [...item.pictures, ...imageUrls?.map((imgUrl) => imgUrl?.data)] };
      }
      return item;
    });

    setAllItems(updatedDetails);
  };

  const getDetailsById = (field) => {
    const details = allItems.find((item) => item.id === id);
    return details?.[field] || null;
  };

  const ppdSelectOptions = useMemo(() => {
    if (isWarehouseItem) return [];
    const vendor = vendors.find((vendor) => vendor?.value === getDetailsById('vendorId'));
    return vendor?.ppdInputOptions || [];
  }, [getDetailsById('vendorId')]);

  const getProductDetailUOMById = (field) => {
    const details = allItems.find((item) => item.id === id);
    return details?.productDetailUOM?.[field] || null;
  };

  const updateDetailsById = (field, value) => {
    const updatedDetails = allItems.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setAllItems(updatedDetails);
  };

  const updateProductDetailsById = (field, value) => {
    const updatedDetails = allItems.map((item) => {
      if (item.id === id) {
        return { ...item, productDetailUOM: { ...item.productDetailUOM, [field]: value } };
      }
      return item;
    });
    setAllItems(updatedDetails);
  };

  const setOrRemoveAdditionalUOM = (value) => {
    if (value) {
      updateDetailsById('productDetailUOM', {
        price: '',
        uomType: '',
        uomAmount: '',
      });
    } else {
      updateDetailsById('productDetailUOM', null);
    }
  };
  const checkUOM = async (vendor, vendorId) => {
    if (!vendor || !vendorId) return;
    setFetching(true);    
    const response = isWarehouseItem
      ? await warehousePossibleProductUOMsData(vendor, vendorId)
      : await catalogPossibleProductUOMsData(vendor, vendorId);
    const data = response;
    if (data.status === 200) {
      if (data.data.length === 0) {
        setUomTypes(UOM_TYPES);
        return;
      }
      const filteredUOMTypes = UOM_TYPES.filter((uom) => data.data.includes(uom.value)).concat(
        data.data.filter((x) => !UOM_TYPES.some((t) => t.value === x)).map((uom) => ({ label: uom, value: uom }))
      );
      setUomTypes(filteredUOMTypes);
    } else {
      setUomTypes(UOM_TYPES);
    }
    setFetching(false);
  };
  useEffect(() => {
    checkUOM(getDetailsById('vendorId'), getDetailsById('vendorItemId'));
    setIntegratedVendor(vendors.find((vendor) => vendor?.value === getDetailsById('vendorId'))?.isIntegrated);
  }, [getDetailsById('vendorId'), getDetailsById('vendorItemId')]);

  const getProductData = async (newValue) => {
    if (gettingItemInfo) return;
    setGettingItemInfo(true);
    const response = isWarehouseItem
      ? await warehouseFetchVendorProductData(
          getDetailsById('vendorId'),
          getDetailsById('vendorItemId'),
          newValue ?? getDetailsById('uomType')
        )
      : await catalogFetchVendorProductData(
          getDetailsById('vendorId'),
          getDetailsById('vendorItemId'),
          newValue ?? getDetailsById('uomType')
        );
    const data = response;
    if (data.status === 200) {
      const {
        price,
        uom,
        uomAmount,
        manufacturer,
        manufacturerId,
        pictureUrls,
        baseUOMAmount,
        baseMinUOMAmount,
        baseUOMType,
        vendorItemDescription,
        vendorItemLink,
      } = data.data;
      const updatedDetails = allItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            price,
            uomType: uom ?? item.uomType,
            uomAmount,
            manufacturer,
            manufacturerId,
            baseUOMAmount,
            baseMinUOMAmount,
            baseUOMType,
            vendorItemDescription,
            vendorItemLink,
            pictures: pictureUrls ? [...pictureUrls] : item.pictures,
          };
        }
        return item;
      });
      setAllItems(updatedDetails);
      enqueueSnackbar(data?.data?.message || 'Item Auto Filled', { variant: 'info' });
    } else {
      const updatedDetails = allItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            uomType: newValue ?? getDetailsById('uomType'),
          };
        }
        return item;
      });
      setAllItems(updatedDetails);
      enqueueSnackbar(data?.data?.message || 'Item Not Found', { variant: 'info' });
    }
    setGettingItemInfo(false);
  };

  const getProductData2 = async (newValue) => {
    if (!gettingItemInfo) {
      setGettingItemInfo(true);
      const response = isWarehouseItem
        ? await warehouseFetchVendorProductData(getDetailsById('vendorId'), getDetailsById('vendorItemId'), newValue)
        : await catalogFetchVendorProductData(getDetailsById('vendorId'), getDetailsById('vendorItemId'), newValue);
      const data = response;
      if (data.status === 200) {
        const { price, uom, uomAmount } = data.data;
        const updatedDetails = allItems.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              productDetailUOM: {
                price,
                uomType: uom,
                uomAmount,
              },
            };
          }
          return item;
        });
        setAllItems(updatedDetails);
        enqueueSnackbar(data?.data?.message || 'UOM Auto Filled', { variant: 'info' });
      } else {
        const updatedDetails = allItems.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              productDetailUOM: {
                uomType: newValue,
              },
            };
          }
          return item;
        });
        setAllItems(updatedDetails);
        enqueueSnackbar(data?.data?.message || 'UOM Not Found', { variant: 'info' });
      }
      setGettingItemInfo(false);
    }
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <div>
            {!gettingItemInfo ? (
              <>
                {integratedVendor ? (
                  <Tooltip title={'Fetch Data'}>
                    <IconButton
                      sx={{ float: 'right' }}
                      color="primary"
                      onClick={(e) => {
                        getProductData();
                      }}
                    >
                      <Iconify icon={'material-symbols:send'} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={'Vendor is not integrated'}>
                    <IconButton sx={{ float: 'right', mr: 4, color: '#808080' }}>
                      <Iconify icon={'material-symbols:cancel-schedule-send-rounded'} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ) : (
              <Iconify sx={{ float: 'right', mr: 4 }} icon="svg-spinners:180-ring-with-bg" color="primary" />
            )}
          </div>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor *</DialogContentText>
          <Autocomplete
            size="small"
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabelRoot ': 'none',
            }}
            type="new-password"
            options={vendors}
            autoHighlight
            getOptionLabel={(option) => option?.label}
            value={vendors.find((vendor) => vendor?.value === getDetailsById('vendorId'))}
            onChange={(e, newValue) => {
              const updatedDetails = allItems.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    vendorId: newValue?.value,
                  };
                }
                return item;
              });

              setAllItems(updatedDetails);
              if (!isWarehouseItem) setPpdOptions([]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor Item ID *</DialogContentText>
          <TextField
            value={getDetailsById('vendorItemId') || ''}
            onChange={(e) => {
              const updatedDetails = allItems.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    vendorItemId: e.target.value,
                  };
                }
                return item;
              });
              setAllItems(updatedDetails);
            }}
            label=""
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>
            UOM Type *
            {fetching && getDetailsById('vendorItemId') && (
              <Iconify icon="svg-spinners:180-ring-with-bg" color="primary" />
            )}
          </DialogContentText>
          <Autocomplete
            size="small"
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabelRoot ': 'none',
            }}
            type="new-password"
            options={uomTypes?.filter((e) => e.value !== getProductDetailUOMById('uomType')) || []}
            autoHighlight
            disabled={!getDetailsById('vendorId') || !getDetailsById('vendorItemId') || !uomTypes}
            getOptionLabel={(option) => option?.label}
            onLoad={() => checkUOM(getDetailsById('vendorId'), getDetailsById('vendorItemId'))}
            value={uomTypes && uomTypes.find((uom) => uom.value === getDetailsById('uomType'))}
            onChange={(e, newValue) => {
              if (newValue) {
                const updatedDetails = allItems.map((item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      uomType: newValue?.value,
                    };
                  }
                  return item;
                });
                setAllItems(updatedDetails);
                if (integratedVendor) {
                  getProductData(newValue.value);
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>UOM Amount *</DialogContentText>
          <TextField
            type="number"
            value={getDetailsById('uomAmount') || ''}
            onChange={(e) => updateDetailsById('uomAmount', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Price *</DialogContentText>
          <TextField
            value={getDetailsById('price') || ''}
            onChange={(e) => updateDetailsById('price', formatMoney(e.target.value))}
            label=""
            type="number"
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Base UOM type</DialogContentText>
          <TextField
            value={getDetailsById('baseUOMType') || ''}
            onChange={(e) => updateDetailsById('baseUOMType', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Base UOM amount</DialogContentText>
          <TextField
            type="number"
            value={getDetailsById('baseUOMAmount') || ''}
            onChange={(e) => updateDetailsById('baseUOMAmount', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      {getDetailsById('productDetailUOM') && (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional UOM Type *</DialogContentText>
              <Autocomplete
                size="small"
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabelRoot ': 'none',
                }}
                type="new-password"
                options={uomTypes?.filter((e) => e.value !== getDetailsById('uomType')) || []}
                autoHighlight
                getOptionLabel={(option) => option?.label}
                value={uomTypes && uomTypes.find((uom) => uom.value === getProductDetailUOMById('uomType'))}
                onChange={(event, newValue) => {
                  if (newValue) {
                    updateProductDetailsById('uomType', newValue.value);
                    if (integratedVendor) {
                      getProductData2(newValue.value);
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label=""
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional UOM Amount *</DialogContentText>
              <TextField
                type="number"
                value={getProductDetailUOMById('uomAmount') || ''}
                onChange={(e) => updateProductDetailsById('uomAmount', e.target.value)}
                label=""
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional Price *</DialogContentText>
              <TextField
                value={getProductDetailUOMById('price') || ''}
                onChange={(e) => updateProductDetailsById('price', formatMoney(e.target.value))}
                label=""
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Button
          variant="text"
          color={getDetailsById('productDetailUOM') ? 'error' : 'primary'}
          sx={{
            mt: 2,
            textDecoration: 'underline',
          }}
          onClick={() => setOrRemoveAdditionalUOM(!getDetailsById('productDetailUOM'))}
        >
          {getDetailsById('productDetailUOM') ? 'Remove' : 'Add'} additional UOM
        </Button>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Manufacturer</DialogContentText>
          <TextField
            value={getDetailsById('manufacturer') || ''}
            onChange={(e) => updateDetailsById('manufacturer', e.target.value)}
            label=""
            size="small"
            multiline
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Manufacturer ID</DialogContentText>
          <TextField
            value={getDetailsById('manufacturerId') || ''}
            onChange={(e) => updateDetailsById('manufacturerId', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid
          item
          xs={
            isWarehouseItem ? 9 : getDetailsById('isPPD')?.toString() === 'true' && ppdSelectOptions.length ? 5 : 10.5
          }
        >
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor item link</DialogContentText>
          <TextField
            value={getDetailsById('vendorItemLink') || ''}
            onChange={(e) => updateDetailsById('vendorItemLink', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            sx={{ width: '95%' }}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={1.5}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>PPD</DialogContentText>
          <ToggleButtonGroup
            value={getDetailsById('isPPD')?.toString()}
            exclusive
            onChange={(e) => updateDetailsById('isPPD', e.target.value)}
          >
            <ToggleButton value="true">Yes</ToggleButton>
            <ToggleButton value="false">No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {!isWarehouseItem && getDetailsById('isPPD')?.toString() === 'true' && ppdSelectOptions.length ? (
          <Grid item xs={5.5}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>PPD for facilities</DialogContentText>
            <Autocomplete
              size="small"
              sx={{
                width: '90%',
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabelRoot ': 'none',
              }}
              multiple
              type="new-password"
              options={ppdSelectOptions}
              autoHighlight
              getOptionLabel={(option) => option?.facility}
              getOptionKey={(option) => option?.facilityId}
              onChange={(e, newValue) => {
                setPpdOptions(newValue.map((option) => option.facilityId) || []);
              }}
              value={ppdSelectOptions.filter((option) => ppdOptions.includes(option.facilityId))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor Item Description</DialogContentText>
          <TextField
            fullWidth
            multiline
            value={getDetailsById('vendorItemDescription') || ''}
            onChange={(e) => updateDetailsById('vendorItemDescription', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      <DialogContentText sx={{ fontWeight: 'bold', mt: 3, mb: -1 }}>Images</DialogContentText>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
        {getDetailsById('pictures')?.map((url, index) => (
          <Grid item xs={2} key={index}>
            <Box m={1} boxShadow={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <FileThumbnail
                file={{ id: index, name: url, type: 'image' }}
                sx={{ width: 128, height: 128 }}
                preview={/^https?:\/\//.test(url) ? url : cloudinaryFull(url)}
                displayImage
              />

              <IconButton onClick={() => handleRemoveImage(index)}>
                <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
        <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
          <input {...getInputProps()} />
          <Paper
            elevation={isDragActive ? 6 : 1}
            sx={{
              p: 2,
              bgcolor: 'background.paper',
              color: 'info.main',
              borderColor: 'info.main',
              border: 1,
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                bgcolor: 'background.default',
                boxShadow: 3,
              },
            }}
          >
            <Typography variant="body2" textAlign="center">
              Click to Upload or Drag and Drop
            </Typography>
          </Paper>
        </Box>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
        <Grid item xs={1}>
          <Typography variant="body2">Image url </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            value={textFieldUrl}
            onChange={(e) => handleChangeTextField(e.target.value)}
            label=""
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleAddImage} disabled={!textFieldUrl} color="primary">
            <Iconify icon="material-symbols:check" width={28} height={28} />
          </IconButton>
        </Grid>
      </Grid>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        accept="image/*"
        onChange={handleFileInputChange}
      />
    </>
  );
}
