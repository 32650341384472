import { Box, Button, Checkbox, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderAsExcel, getOrderAsPDF } from '../../../../api/orders';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';

export default function DownloadOrderDropdown() {
  const { id = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { orderById } = useSelector((state) => state.orders || {});
  const { items = [], address } = orderById[id] || {};

  const [anchorEl, setAnchorEl] = useState(null);
  const [vendorIds, setVendorIds] = useState([]);
  const [useAllVendors, setUseAllVendors] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState('pdf');
  const open = Boolean(anchorEl);

  const vendors = useMemo(() => {
    if (!items) return [];
    const vendorArray = items?.map((item) => ({ vendor: item.vendor, vendorId: item.vendorId }));

    const uniqueVendors = Array.from(new Set(vendorArray.map((v) => v.vendorId))).map((id) =>
      vendorArray.find((v) => v.vendorId === id)
    );

    return uniqueVendors;
  }, [orderById]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setVendorIds([]);
    setUseAllVendors(false);
    setDownloadFormat('pdf');
  };

  const downloadOrder = async () => {
    const itemsToAdd = items.filter((i) => vendorIds.includes(i.vendorId));

    if (itemsToAdd.length > 0) {
      const vendorId = vendorIds.length === 1 ? vendorIds[0] : 0;
      const res =
        downloadFormat === 'pdf'
          ? await getOrderAsPDF({
              orderId: id,
              items: itemsToAdd,
              address,
              vendorId,
            }).catch(() => enqueueSnackbar('Order PDF failed to download', { variant: 'error' }))
          : await getOrderAsExcel({ orderId: id, items: itemsToAdd, address }).catch(() =>
              enqueueSnackbar('Order Excel failed to download', { variant: 'error' })
            );

      setAnchorEl(null);

      if (res.status === 200) {
        const blob = new Blob([res.data], { type: `application/${downloadFormat}` });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id}.${downloadFormat}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
    } else {
      enqueueSnackbar('No vendor selected', { variant: 'warning' });
    }
  };

  useEffect(() => {
    if (useAllVendors) setVendorIds(vendors.map((vendor) => vendor.vendorId));
    else setVendorIds([]);
  }, [useAllVendors, vendors]);

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={handleClick}
        endIcon={<Iconify icon={'mdi:chevron-down'} />}
      >
        Download
      </Button>

      <Menu
        id="demo-customized-menu"
        MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MenuItem key={1} dense>
          <Stack>
            <Typography color="secondary"> Download by Vendor:</Typography>
            <Box>
              {
                <Checkbox
                  color="secondary"
                  style={{ height: 28, width: 28 }}
                  checked={useAllVendors}
                  onChange={() => setUseAllVendors((useAll) => !useAll)}
                />
              }{' '}
              All Vendors
            </Box>
            {vendors?.map((vendor) => (
              <Box key={vendor.vendorId}>
                {
                  <Checkbox
                    color="secondary"
                    style={{ height: 28, width: 28 }}
                    onChange={() =>
                      vendorIds?.includes(vendor.vendorId)
                        ? setVendorIds(vendorIds?.filter((v) => v !== vendor.vendorId))
                        : setVendorIds([...vendorIds, vendor.vendorId])
                    }
                    checked={vendorIds?.includes(vendor.vendorId)}
                  />
                }{' '}
                {vendor.vendor}
              </Box>
            ))}
          </Stack>
        </MenuItem>

        <MenuItem key={2} dense>
          <Stack>
            <Typography color="secondary"> Download as:</Typography>
            <Box>
              {
                <Checkbox
                  color="secondary"
                  style={{ height: 28, width: 28 }}
                  checked={downloadFormat === 'pdf'}
                  onChange={() => setDownloadFormat(downloadFormat === 'pdf' ? 'xlsx' : 'pdf')}
                />
              }{' '}
              PDF
            </Box>
            <Box>
              {' '}
              {
                <Checkbox
                  color="secondary"
                  style={{ height: 28, width: 28 }}
                  checked={downloadFormat === 'xlsx'}
                  onChange={() => setDownloadFormat(downloadFormat === 'pdf' ? 'xlsx' : 'pdf')}
                />
              }{' '}
              Excel
            </Box>
          </Stack>
        </MenuItem>

        <MenuItem key={3} sx={{ justifyContent: 'center' }}>
          <Button variant="outlined" color="secondary" onClick={() => downloadOrder()}>
            Download
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
