import { useEffect } from 'react'; import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { OrderGuidesView } from '../../../components/dashboard/admin';
import { useDispatch, useSelector } from '../../../redux/store';
import { getAllProducts } from '../../../redux/slices/orderGuides';

// components

// ----------------------------------------------------------------------

export default function OrderGuides() {
  const dispatch = useDispatch();
  const {
    data: { allProducts, isLoadingProducts },
  } = useSelector((state) => state.orderGuides);

  useEffect(() => {
    if (!allProducts?.length && !isLoadingProducts) dispatch(getAllProducts());
  }, [allProducts, dispatch]);

  return (
    <>
      <Helmet>
        <title>Admin Order Guides | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <OrderGuidesView />
      </Grid>
    </>
  );
}
