import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Menu, Typography, MenuItem, Button } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Iconify from '../../../reusable-components/iconify';
import { useSelector, useDispatch } from '../../../redux/store';
import {
  PURCHASING_ITEMS_ORDERED,
  FACILITY_COMPARISON_REPORT,
  VENDOR_COMPARISON_REPORT,
  TOP_LEVEL_BUDGET_REPORT,
  FACILITY_HISTORY_REPORT,
  PO_INVOICE_REPORT,
  FACILITIES_INVOICES_REPORT,
  INVOICES_ISSUES_REPORT,
  FACILITIES_INVOICES_DISCREPANCY,
  SUBSTITUTE_REPORT,
  ITEMS_ORDERED_FULL
} from '../../../reusable-components/datagrid/reportsColumns';
import { clearReportsInRedux } from '../../../redux/slices/reports';

ReportsDropdown.propTypes = {
  selectedOption: PropTypes.object,
  setSelectedOption: PropTypes.func,
  setTabValue: PropTypes.func,
  tabValue: PropTypes.number,
  setFirstPageView: PropTypes.func,
  title: PropTypes.string,
};

export default function ReportsDropdown({
  setFirstPageView,
  setTabValue,
  selectedOption,
  setSelectedOption,
  title,
  tabValue,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const purchasingOptions = [
    {
      title: 'Items Ordered Report',
      description: 'Shows all items ordered',
      reportId: 15,
      options: ['facilitySelector', 'facilitiesByGroup', 'date'],
      values: { date: [new Date(), new Date()] },
      columns: ITEMS_ORDERED_FULL,
    },
    {
      title: 'Items Ordered Report Consolidated',
      description: 'Shows all items ordered with total ordered.',
      reportId: 1,
      options: ['facilitySelector', 'facilitiesByGroup', 'date'],
      values: { date: [new Date(), new Date()] },
      columns: PURCHASING_ITEMS_ORDERED,
    },
    {
      title: 'Facility Comparison Report',
      description: 'Compares facilities order history against each other.',
      reportId: 2,
      options: ['facilitySelector', 'facilitiesByGroup', 'categories', 'date'],
      values: { categories: [], date: [new Date(), new Date()] },
      columns: FACILITY_COMPARISON_REPORT,
      // pinnedColumns: {
      //   left: ['description'],
      // },
      dynamicColumns: 'facilities',
      dynamicKey: ['facility'],
      dynamicFields: [
        {
          field: 'ppdPrice',
          headerName: 'PPD',
          isCurrency: true,
        },
        {
          field: 'quantity',
          headerName: 'QTY',
          isNumber: true,
        },
        {
          field: 'total',
          headerName: 'Total',
          isCurrency: true,
          tooltip: 'Price * Quantity',
        },
      ],
    },
    {
      title: 'Vendor Price Comparison Report',
      description: 'Compares vendor prices against each other.',
      reportId: 3,
      options: ['vendors', 'categories', 'date'],
      values: { vendors: [], categories: [], date: [new Date(), new Date()] },
      columns: VENDOR_COMPARISON_REPORT,
      // pinnedColumns: {
      //   left: ['description', 'uomAmount', 'baseUomAmount', 'ordered', 'itemTotal'],
      // },
      nestedDataArray: true,
      dynamicColumns: 'vendors',
      dynamicKey: ['vendor'],
      dynamicFields: [
        {
          field: 'manufacturer',
          headerName: 'Manufacturer',
        },
        {
          field: 'uom',
          headerName: 'UOM',
        },
        {
          field: 'baseUom',
          headerName: 'Base UOM',
          combineTwoFields: ['baseUomAmount', 'baseUomType'],
        },
        {
          field: 'perItem',
          headerName: 'Per Item',
          isCurrency: true,
        },
        {
          field: 'perUnit',
          headerName: 'Per Unit',
          isCurrency: true,
        },
        {
          field: 'price',
          headerName: 'Price',
          isCurrency: true,
        },
        {
          field: 'vendorItemId',
          headerName: 'SKU',
        },
      ],
    },
    {
      title: 'Top Level Budget Report',
      description: 'Top level list of budgets. (detail breakdown in the excel download)',
      reportId: 4,
      options: ['companies', 'companiesAndFacilities', 'categories', 'subcategories', 'date'],
      values: { companies: [], facilities: [], categories: [], subcategories: [], date: [new Date(), new Date()] },
      columns: TOP_LEVEL_BUDGET_REPORT,
    },
    {
      title: 'Facility History Report',
      description: 'Compares a facilities ordering history against itself by time period.',
      reportId: 5,
      options: ['facilityId', 'categories', 'dateSelect'],
      values: { facilityId: null, categories: [], date: [new Date(), new Date()] },
      columns: FACILITY_HISTORY_REPORT,
      nestedData: 'items',
      nestedDataKey: 'item',
      dynamicColumns: 'orderedItems',
      topLevelColumns: 'censusDateValues',
      topLevelColumnsKey: 'date',
      dynamicKey: ['date'],
      dynamicFields: [
        {
          field: 'total',
          headerName: 'Total',
          isCurrency: true,
        },
        {
          field: 'quantity',
          headerName: 'QTY',
          isNumber: true,
        },
        {
          field: 'ppdTotal',
          headerName: 'PPD Total',
          isCurrency: true,
        },
      ],
    },
    {
      title: 'PO Invoice Matchup Report',
      description: 'Matches orders against invoices to determine what got invoiced.',
      reportId: 6,
      options: ['facilitySelector', 'facilitiesByGroup', 'vendors', 'date'],
      values: { categories: [], date: [new Date(), new Date()] },
      columns: PO_INVOICE_REPORT,
      dynamicColumns: 'orderedItems',
      topLevelColumns: 'censusDateValues',
      topLevelColumnsKey: 'date',
      dynamicKey: ['date'],
    },
    {
      title: 'Substitute Report',
      description: 'Items ordered and what vendor subbed it out to.',
      options: ['companies', 'companiesAndFacilities', 'categories', 'subcategories', 'date'],
      values: { companies: [], facilities: [], categories: [], subcategories: [], date: [new Date(), new Date()] },
      reportId: 7,
      columns: SUBSTITUTE_REPORT,
    },
  ];

  const otherOptions = [
    {
      title: 'Facilities Invoice Report (Electronic Invoices Only)',
      description: 'Comprehensive list of invoice items and their current status. Only includes electronic invoices sent directly by the vendor.',
      options: ['facilitySelector', 'facilitiesByGroup', 'vendors', 'date'],
      values: { vendors: [], date: [new Date(), new Date()] },
      reportId: 11,
      columns: FACILITIES_INVOICES_REPORT,
    },
    {
      title: 'Check Invoices For Pdf Issues',
      description: 'Checks invoices pdfs for their invoice id and returns any errors found.',
      options: ['facilityId', 'vendors', 'skipAndTake'],
      values: { facilityId: null, vendors: [], skip: 0, take: 100 },

      reportId: 12,
      columns: INVOICES_ISSUES_REPORT,
    },
    {
      title: 'Invoice Discrepancy Report',
      description: 'List of vendor issues for review',
      options: ['facilitySelector', 'facilitiesByGroup', 'date'],
      values: { vendors: [], date: [new Date(), new Date()] },
      reportId: 13,
      columns: FACILITIES_INVOICES_DISCREPANCY,
      getRowId: (row) => `${row.id}_${row.account}_${row.facility}_${row.item}_${row.vendor}_${row.vendorInvoiceId}_${row.sent}_${row.sent}_${row.invoiceDate}_${row.issue}`,
    },
  ];

  const options = title === 'Purchasing' ? purchasingOptions : otherOptions;

  // useEffect(() => {
  //   if (!selectedOption) setSelectedOption(tabValue === 0 ? purchasingOptions[0] : otherOptions[0]);
  // }, [selectedOption]);

  return (
    <>
      <Button
        size="small"
        sx={{ ml: 3 }}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        color="primary"
        onClick={handleClick}
        endIcon={<Iconify icon="material-symbols:keyboard-arrow-down" width={24} height={24} />}
      >
        <Typography variant="subtitle1" color="primary" sx={{ textTransform: 'capitalize,' }}>
          {title} Reports
        </Typography>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((opt) => (
          <MenuItem
            key={opt.reportId}
            disableRipple
            onClick={() => {
              setFirstPageView(true);
              setTabValue(title === 'Purchasing' ? 0 : 1);
              dispatch(clearReportsInRedux());
              setSelectedOption(opt);
              handleClose();
            }}
          >
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
              <Typography variant="subtitle1" sx={{ textTransform: 'capitalize,' }}>
                {opt.title}
              </Typography>
              <Typography variant="caption">{opt.description}</Typography>
            </Grid>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{
      ml: 10,
      mt: -1,
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    border: '1px solid #637381',
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      borderBottom: '0.5px solid #637381',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
}));
