import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  DialogContentText,
  Box,
  Button,
  TextField,
  DialogActions,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from '../../../../redux/store';
import { postIntegrationForm } from '../../../../api/vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import IntegrationFormText from './IntegrationFormText';
import { AwaitButton } from '../../../../reusable-components/await-button';

IntegrationModal.propTypes = {
  fetchData: PropTypes.func,
  setDefinitions: PropTypes.func,
  edit: PropTypes.bool,
  row: PropTypes.object,
  bookingData: PropTypes.array,
  definitionTypes: PropTypes.array,
  definitions: PropTypes.array,
  typeId: PropTypes.number,
  name: PropTypes.string,
};

export default function IntegrationModal({
  fetchData,
  edit,
  row,
  definitions,
  definitionTypes,
  typeId,
  name,
  setDefinitions,
}) {
  const {
    data: { vendors },
  } = useSelector((state) => state.vendors);

  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    vendor: '',
    orderingDisabled: false,
  });

  const handleSave = async () => {
    const response = await postIntegrationForm({
      vendorId: value.vendor.id,
      integrationType: typeId,
      orderingDisabled: value.orderingDisabled,
      definitions,
    });
    if (response) {
      enqueueSnackbar(`${name} added successfully`, { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar(`Failed to add ${name}`, { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      vendor: edit ? { id: row.vendorId, label: vendors.find((vendor) => vendor.id === row.vendorId).name } : '',
      orderingDisabled: edit ? row.orderingDisabled : false,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      vendor: '',
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          sx={{ mr: 1 }}
          size="small"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          New {name} integration
        </Button>
      )}

      <Dialog open={open} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {edit ? 'Edit' : 'Add'} {name} Integration
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box>
              <Grid item xs={6}>
                <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor</DialogContentText>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value.vendor}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      vendor: newValue,
                    });
                  }}
                  options={vendors?.map((type) => ({ id: type.id, label: type.name }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 0.5,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
            {definitionTypes &&
              definitionTypes.length > 0 &&
              definitionTypes.map(
                (def) =>
                  !def.isBoolean &&
                  !def.isMulti && (
                    <Grid item xs={3} key={def.id}>
                      <IntegrationFormText
                        size="small"
                        definitions={definitions}
                        currDef={def}
                        title={def.name}
                        id={def.id}
                        setDefinitions={setDefinitions}
                        vendorId={value.vendor?.id}
                        type="text"
                      />
                    </Grid>
                  )
              )}
          </Grid>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center" sx={{ mt: 0.5 }}>
            {definitionTypes &&
              definitionTypes.length > 0 &&
              definitionTypes.map(
                (def, index) =>
                  !def.isBoolean &&
                  def.isMulti && (
                    <Grid item xs={3} key={index}>
                      <IntegrationFormText
                        size="small"
                        definitions={definitions}
                        currDef={def}
                        title={def.name}
                        id={def.id}
                        setDefinitions={setDefinitions}
                        vendorId={value.vendor?.id}
                        type="multi"
                      />
                    </Grid>
                  )
              )}
          </Grid>

          <Grid container spacing={2} justifyContent="flex-start" alignItems="center" sx={{ mt: 0.5 }}>
            {definitionTypes &&
              definitionTypes.length > 0 &&
              definitionTypes.map(
                (def) =>
                  def.isBoolean &&
                  !def.isMulti && (
                    <Grid item xs={3} key={def.id}>
                      <IntegrationFormText
                        size="small"
                        definitions={definitions}
                        title={def.name}
                        id={def.id}
                        setDefinitions={setDefinitions}
                        vendorId={value.vendor?.id}
                        type="checkBox"
                      />
                    </Grid>
                  )
              )}
          </Grid>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center" sx={{ mt: 0.5 }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.orderingDisabled}
                    onChange={(event) => {
                      setValue({
                        ...value,
                        orderingDisabled: event.target.checked,
                      });
                    }}
                  />
                }
                label="Ordering Disabled"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.vendor === '' || value.facility === '' || value.minimumTotal === ''}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            {edit ? 'Save' : 'Add Integration'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
