import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { ITEM_HISTORY } from '../../../../reusable-components/datagrid/noFlexCatalogColumns';
import { useSelector } from '../../../../redux/store';

ItemHistory.propTypes = {
  data: PropTypes.array,
};

export default function ItemHistory({ data }) {
  const isLoading = useSelector((state) => state.catalog.isLoading);
  return (
    <div>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-catalog-item-history"
          boxSX={{ width: '100%', height: 'calc(100vh - 260px)' }}
          data={data}
          gridColumns={ITEM_HISTORY}
          isModal
          isLoading={isLoading}
          sort={[{ field: 'description', sort: 'asc' }]}
        />
      </Grid>
    </div>
  );
}
