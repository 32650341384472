import { useState, useEffect, useCallback } from 'react';
import { Typography, Grid, Box, Button } from '@mui/material';
import { GROUPS } from '../../../../reusable-components/datagrid/groupColumns';
import { getCompanies, deleteCompany } from '../../../../api/companies';
import EditItemDialog from './EditItemDialog';
import AddItemDialog from './AddItemDialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import Iconify from '../../../../reusable-components/iconify';
import { HOST_API_URL } from '../../../../config-global';

export default function GroupView() {
  const [data, setData] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const getGroups = async () => {
    const response = await getCompanies();

    if (response.status === 200) {
      setData(response.data);
    } else {
      enqueueSnackbar('Error fetching groups', { variant: 'error' });
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteCompany(id);

    if (response.status === 200) {
      enqueueSnackbar('Group deleted successfully', { variant: 'success' });
      getGroups();
    } else {
      enqueueSnackbar('Error deleting group', { variant: 'error' });
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const ActionButtons = (params) => (
    <>
      <EditItemDialog id={params.row.id} row={params.row} getGroups={getGroups} />
      <ConfirmDialog
        icon="delete-outline"
        title="Delete"
        color="error"
        content={`Are you sure you want to delete this group?`}
        actionButton="Delete"
        action={() => handleDelete(params.row.id)}
      />
    </>
  );
  const actions = {
    field: 'action',
    headerName: 'Actions',
    width: 100,
    // Updated renderCell function to display the dynamic percent value based on activeTasks
    renderCell: (params) => ActionButtons(params),
  };
  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
        <>
          <AddItemDialog getGroups={getGroups} />

          <Button
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            onClick={() => {
              window.open(`${HOST_API_URL}Api/Company/DownloadCompanies`, '_blank');
            }}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download
          </Button>
        </>
      </Grid>
    ),
    []
  );
  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-group"
          boxSX={{ height: 'calc(100vh - 260px)' }}
          data={data}
          gridColumns={[...GROUPS, actions]}
          CustomLeftToolbar={CustomLeftToolbar}
          sort={[{ field: 'name', sort: 'Asc' }]}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </>
  );
}
