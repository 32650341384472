// remember to import this file in index.js

export default function SwitchTheme(theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
          },
        },
        switchBase: {
          // Set default color for the switch base
          color: '#efefef',
          // Add other styles as needed
        },
      },
    },
  };
}
