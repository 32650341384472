import { createSlice } from '@reduxjs/toolkit';
import { getDashboardData, getNavMessages } from '../../api/home';
import { setUnreadTicketCount } from './tickets';
import { setUnreadOrderCount } from './orders';
import { setPendingInvoiceCount } from './invoicing';
import { setUserSiteIdInRedux } from './user';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: {
    invoices: [],
    orders: [],
    tickets: [],
  },
  unreadSystemMessages: [],
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDashboardSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    setUnreadSystemMessages(state, action) {
      state.unreadSystemMessages = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getDashboard() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getDashboardData();
      dispatch(slice.actions.getDashboardSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUnreadData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getNavMessages();
      if (response.data) {
        dispatch(setUnreadTicketCount(response.data.unreadTicketCount));
        dispatch(setUnreadOrderCount(response.data.unreadOrderCount));
        dispatch(setPendingInvoiceCount(response.data.pendingInvoiceCount));
        dispatch(setUserSiteIdInRedux(response.data.userSiteId));
        dispatch(slice.actions.setUnreadSystemMessages(response.data.systemMessages || []));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
