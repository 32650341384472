import { useState } from 'react';
import { Box, Typography, Grid, Tabs, Tab, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import Iconify from '../../../../reusable-components/iconify';
import {
  VendorsTab,
  VendorTab,
  VendorAccountsTab,
  VendorMinimumsTab,
  VendorShipDateTab,
  VendorIntegrationsTab,
  VendorStatusCodeTab,
  VendorEmailPickupTab,
  VendorRulesTab,
  VendorItemsTab
} from '.';
import { useScopeCheck } from '../../../../reusable-components/scopes';

export default function VendorsView() {
  const isSuperAdmin = useScopeCheck(['Super-Admin']);
  const [tab, setTab] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setSelectedRow(null);
    }

    setTab(newValue);
  };

  const renderTabContent = () => {
    let caseValue;
    if (selectedRow) {
      caseValue = tab;
    } else if (tab > 0) {
      caseValue = tab + 1;
    } else {
      caseValue = 0;
    }

    switch (caseValue) {
      case 0:
        return <VendorsTab setSelectedRow={setSelectedRow} setTab={setTab} />;
      case 1:
        return <VendorTab selectedRow={selectedRow} setSelectedRow={setSelectedRow} setTab={setTab} />;
      case 2:
        return <VendorAccountsTab selectedRow={selectedRow} />;
      case 3:
        return <VendorMinimumsTab selectedRow={selectedRow} />;
      case 4:
        return <VendorShipDateTab selectedRow={selectedRow} />;
      case 5:
        return <VendorIntegrationsTab selectedRow={selectedRow} />;
      case 6:
        return <VendorStatusCodeTab selectedRow={selectedRow} />;
      case 7:
        return <VendorEmailPickupTab selectedRow={selectedRow} />;
      case 8:
        return <VendorRulesTab selectedRow={selectedRow} />;
      case 9:
        return <VendorItemsTab selectedRow={selectedRow} />;
      default:
        return <VendorsTab setSelectedRow={setSelectedRow} setTab={setTab} />;
    }
  };

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="transparent"
            sx={{
              borderRadius: '4px 4px 0px 0px',
            }}
          >
            <CustomTab
              label={
                <>
                  <Box>
                    <Typography variant="subtitle1" color="success.contrastText" component="span">
                      Vendors
                    </Typography>
                    {selectedRow && (
                      <Tooltip title="Remove selected vendor">
                        <Typography component="span" sx={{ color: 'info.main' }}>
                          <Iconify
                            icon="mdi:close"
                            id={tab.id}
                            onClick={() => setSelectedRow(null)}
                            width={20}
                            height={20}
                            sx={{ ml: '5px', mb: '-5px' }}
                          />{' '}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                </>
              }
            />
            {selectedRow && (
              <CustomVendorTab
                label={
                  <>
                    <Typography variant="subtitle1" color="info.main">
                      {selectedRow.name}
                    </Typography>
                  </>
                }
              />
            )}
            <CustomSmallTab
              label={
                <>
                  <Typography
                    variant="subtitle1"
                    color={selectedRow ? `info.${tab === 2 ? 'main' : 'light'}` : 'success.contrastText'}
                  >
                    Accounts
                  </Typography>
                </>
              }
            />
            <CustomSmallTab
              label={
                <>
                  <Typography
                    variant="subtitle1"
                    color={selectedRow ? `info.${tab === 3 ? 'main' : 'light'}` : 'success.contrastText'}
                  >
                    {' '}
                    Minimums
                  </Typography>
                </>
              }
            />
            <CustomSmallTab
              label={
                <>
                  <Typography
                    variant="subtitle1"
                    color={selectedRow ? `info.${tab === 4 ? 'main' : 'light'}` : 'success.contrastText'}
                  >
                    Ship date
                  </Typography>
                </>
              }
            />
            {isSuperAdmin && (
              <CustomSmallTab
                label={
                  <>
                    <Typography
                      variant="subtitle1"
                      color={selectedRow ? `info.${tab === 5 ? 'main' : 'light'}` : 'success.contrastText'}
                    >
                      Integrations
                    </Typography>
                  </>
                }
              />
            )}
            {isSuperAdmin && (
              <CustomTab
                label={
                  <>
                    <Typography
                      variant="subtitle1"
                      color={selectedRow ? `info.${tab === 6 ? 'main' : 'light'}` : 'success.contrastText'}
                    >
                      Status Code
                    </Typography>
                  </>
                }
              />
            )}
            {isSuperAdmin && (
              <CustomBigTab
                label={
                  <>
                    <Typography
                      variant="subtitle1"
                      color={selectedRow ? `info.${tab === 7 ? 'main' : 'light'}` : 'success.contrastText'}
                    >
                      Email PDF pickup
                    </Typography>
                  </>
                }
              />
            )}
            {isSuperAdmin && (
              <CustomSmallTab
                label={
                  <>
                    <Typography
                      variant="subtitle1"
                      color={selectedRow ? `info.${tab === 8 ? 'main' : 'light'}` : 'success.contrastText'}
                    >
                      Rules
                    </Typography>
                  </>
                }
              />
            )}

             <CustomSmallTab
                label={
                  <>
                    <Typography
                      variant="subtitle1"
                      color={selectedRow ? `info.${tab === 9 ? 'main' : 'light'}` : 'success.contrastText'}
                    >
                      Vendor Items
                    </Typography>
                  </>
                }
              />
          </Tabs>
        </Box>
      </Grid>

      {renderTabContent()}
    </Box>
  );
}

const CustomBigTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '170px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '130px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
const CustomSmallTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '100px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
const CustomVendorTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '170px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
