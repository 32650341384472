import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button } from '@mui/material';
import { useSelector } from '../../../../redux/store';
import { ORDER_GUIDES } from '../../../../reusable-components/datagrid/orderGuidesColumns';
import { getOrderGuides, getAllProducts } from '../../../../redux/slices/orderGuides';
import { HOST_API_URL } from '../../../../config-global';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../../reusable-components/iconify';
import NewOrderGuideDialog from './NewOrderGuideDialog';
import OrderGuideTemplate from './OrderGuideTemplate';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import ManageOrderGuides from './ManageOrderGuides';

export default function OrderGuidesView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: { orderGuideActivations },
  } = useSelector((state) => state.orderGuides);
  const isLoading = useSelector((state) => state.orderGuides.isLoading);

  useEffect(() => {
    dispatch(getOrderGuides());
  }, [dispatch]);

  const downloadGuides = async () => {
    //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
    window.open(`${HOST_API_URL}Api/DownloadOrderGuideActivations`, '_blank');
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <ScopeGuard scopes={['Edit-OrderGuide']} allowAdmin>
            <Box sx={{ display: 'inline-block', mr: 1 }}>
              <NewOrderGuideDialog />
            </Box>
          </ScopeGuard>
          <Box sx={{ display: 'inline-block', mr: 1 }}>
            <ManageOrderGuides />
          </Box>
          <Box sx={{ display: 'inline-block', mr: 1 }}>
            <OrderGuideTemplate />
          </Box>
          <Box sx={{ display: 'inline-block', mr: 1 }}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mr: 1 }}
              size="small"
              onClick={downloadGuides}
              startIcon={<Iconify icon="file-icons:microsoft-excel" />}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Grid>
    ),
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-order-guides-view"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={orderGuideActivations}
          gridColumns={ORDER_GUIDES}
          sort={[{ field: 'description', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          getRowId={(row) => row.id}
          onRowClick={(params, e) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            if (
              e.target.classList.contains('action-button') ||
              e.target.dataset.field === 'actions' ||
              e.target?.nearestViewportElement?.dataset?.field === 'actions' ||
              e.target?.nearestViewportElement?.classList.contains('action-button')
            ) {
              return;
            }
            navigate(`/dashboard/admin/order-guides/${params.id}`);
          }}
        />
      </Grid>
    </>
  );
}
