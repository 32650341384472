import { Box } from '@mui/material';
import { renderColorBox } from '../../utils/cssStyles';
import Iconify from '../iconify';
import { fCurrency } from '../../utils/formatNumber';

const subcategory = {
  field: 'agoraSubcategory',
  headerName: 'Subcategory',
  flex: 1,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),

  type: 'customSelect',
};
const category = {
  field: 'agoraCategory',
  headerName: 'Category',
  flex: 1,
  type: 'customSelect',
  renderCell: (params) => renderColorBox(params.row.agoraCategory, 'secondary'),
};

const glCode = {
  field: 'glCode',
  headerName: 'GL Code',
  flex: 1,
  type: 'customText',
};
const ppdBudget = {
  field: 'ppdBudget',
  headerName: 'Budget',
  flex: 1,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => parseFloat(params.value),
};

const facility = {
  field: 'facility',
  headerName: 'Facility',
  flex: 1,
  type: 'customSelect',
};

const group = {
  field: 'company',
  headerName: 'Group',
  flex: 1,
  type: 'customSelect',
};

const excludeFromMain = {
  field: 'excludeFromMain',
  headerName: 'Excluded',
  flex: 0.5,
  align: 'center',
  renderCell: (params) => {
    const { value } = params;
    return value ? <Iconify icon="material-symbols:check-circle-outline" color="primary" /> : <></>;
  },
};

const BUDGETS = [facility, group, category, subcategory, glCode, excludeFromMain, ppdBudget];

export { BUDGETS };
