import { Box } from '@mui/material';

const rule = {
  field: 'rule',
  headerName: 'Rule',
  flex: 0.8,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
};

const type = {
  field: 'ruleType',
  headerName: 'Type',
  flex: 0.8,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
};
const value = {
  field: 'values',
  headerName: 'Value',
  flex: 0.8,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value.map(
        (v) => (
          <span key={v.id}>{v.value}</span>
        ),
        ','
      )}
    </Box>
  ),
};

const SITE_RULES = [rule, type, value];

export { SITE_RULES };
