import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../redux/store';

// const SCOPES = ['Super-Admin', 'Admin', 'Purchaser', 'Tickets', 'Orders', 'Invoicing', 'Alerts'];
const ALLOWED_SCOPES = ['Super-Admin', 'Admin'];
// const SCOPES = [];
// const ALLOWED_SCOPES = [];

SettingsGuard.propTypes = {
  children: PropTypes.node.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.string),
  redirect: PropTypes.string,
  allowAdmin: PropTypes.bool,
};

/**
 * A component that protects its child components from being rendered if the user
 * doesn't have the required permissions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Node} props.children - The child components to be protected.
 * @param {Array.<string>} props.scopes - An array of role names that the user must have to render the child components.

 * @param {string} props.redirect - The URL to redirect to if access is denied.
 * @returns {Node} The protected child components, or null if the user doesn't have the required permissions.
 *
 * @example
 * <SettingsGuard scopes={['Tickets', 'Purchase']}>
 *   // child components here
 * </SettingsGuard>
 */
export default function SettingsGuard({ children, scopes, redirect, allowAdmin = false }) {
  const navigate = useNavigate();
  const { roles } = useSelector((state) => state.user.user);

  const hasPermissions = useMemo(() => {
    if (roles) {
      // const hasAllowedScope = ALLOWED_SCOPES.some((scope) => roles.includes(scope));
      const hasAllowedScope = allowAdmin ? ALLOWED_SCOPES.some((scope) => roles.includes(scope)) : false;
      const hasRequiredScope = scopes.some((scope) => roles.includes(scope));
      return hasAllowedScope || hasRequiredScope;
    }
    return false;
  }, [roles, allowAdmin, scopes]);

  if (hasPermissions) {
    return <>{children}</>;
  }

  if (redirect) {
    navigate(redirect);
  }

  return null;
}
