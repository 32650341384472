import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { ReportsView } from '../../../components/dashboard/reports';

// ----------------------------------------------------------------------

export default function Reports() {
  return (
    <>
      <Helmet>
        <title>Reports | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <ReportsView />
        </Grid>
      </Grid>
    </>
  );
}
