import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Stack, Slider, Typography, Tooltip } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

OpenInNew.propTypes = { blobUrl: PropTypes.blobUrl | undefined };
export default function OpenInNew({ blobUrl }) {
  const handlePrint = () => {
    const iframe = document.createElement('iframe'); 
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  };
  return (
    <Box>
      <Tooltip title={'print'}>
        <IconButton color="secondary" sx={{ p: 0.5 }} onClick={handlePrint}>
          <PrintIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
