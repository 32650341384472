import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { EmailRecipientsView } from '../../../components/dashboard/admin/email-recipients';
import { getEmailRecipients, getEmailRecipientTypes } from '../../../redux/slices/emailRecipient';
import { useDispatch, useSelector } from '../../../redux/store';

export default function EmailRecipients() {
  const dispatch = useDispatch();
  const {
    data: { emailRecipients, emailRecipientTypes },
  } = useSelector((state) => state.emailRecipient);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!emailRecipients?.length) dispatch(getEmailRecipients());
  }, [emailRecipients, dispatch]);

  useEffect(() => {
    if (!emailRecipientTypes?.length) dispatch(getEmailRecipientTypes(user.siteId));
  }, [emailRecipientTypes, dispatch, user.siteId]);

  return (
    <>
      <Helmet>
        <title>Admin Email Recipients | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <EmailRecipientsView />
        </Grid>
      </Grid>
    </>
  );
}
