import { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  IconButton,
  Paper,
  Box,
  Typography,
  Button,
  DialogActions,
} from '@mui/material';
import { useGridApiRef, DataGridPro } from '@mui/x-data-grid-pro';
import Iconify from '../../../../reusable-components/iconify';
import { useSelector } from '../../../../redux/store';
import { getFacilitysById, setFacilityCensus, getFacilitys } from '../../../../redux/slices/census';
import { FACILITY_COLUMNS } from '../../../../reusable-components/datagrid/censusColumns';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useScopeCheck } from '../../../../reusable-components/scopes';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { twoDecimal } from '../../../../utils/formatNumber';

CensusModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  facilityId: PropTypes.number,
  isDialog: PropTypes.bool,
};

export default function CensusModal({ setOpen, open, facilityId, isDialog = true }) {
  const dispatch = useDispatch();
  const hasEditAccess = useScopeCheck(['Edit-Census'], true);
  const [data, setData] = useState([]);
  const [changeAverage, setChangeAverage] = useState(false);
  const [average, setAverage] = useState(0);
  const [changedCensus, setChangedCensus] = useState([]);

  const {
    data: { facility, facilities },
  } = useSelector((state) => state.census);
  const isLoading = useSelector((state) => state.census.isLoading);

  const currentFacility = useMemo(() => facilities?.find((f) => f.facilityId === facilityId), [facilityId, facilities]);

  useEffect(() => {
    setAverage(currentFacility?.budgetDayCount);
  }, [currentFacility]);

  useEffect(() => {
    if (facilityId) dispatch(getFacilitysById(facilityId));
  }, [dispatch, facilityId]);

  useEffect(() => {
    if (facility?.censusList?.length > 0) setData(facility.censusList);
  }, [facility]);

  const handleClose = () => {
    setChangedCensus([]);
    setChangeAverage(false);
    setOpen(false);
    setData([]);
  };

  const updateCensus = () => {
    dispatch(setFacilityCensus({ facilityId, dates: changedCensus, fetchDaysForBudgets: average }));
    dispatch(getFacilitys());
    dispatch(getFacilitysById(facilityId));
    handleClose();
  };

  const columns = useMemo(
    () => [
      ...FACILITY_COLUMNS,
      {
        field: 'number',
        headerName: 'Amount',
        minWidth: 75,
        flex: 0.5,
        renderCell: (params) =>
          hasEditAccess ? (
            <TextField
              size="small"
              type={'number'}
              disabled={!hasEditAccess}
              // value={params?.row?.number}
              defaultValue={params?.row?.number}
              onChange={(e) => {
                const date = params?.row?.date;
                const number = parseInt(e.target.value, 10) || 0;

                const updatedCensus = changedCensus;
                const index = updatedCensus?.findIndex((census) => census.date === date);
                if (index !== -1) {
                  updatedCensus[index].number = number;
                } else {
                  updatedCensus.push({ date, number });
                }

                setChangedCensus(updatedCensus);
              }}
              sx={{
                mt: 0.5,
                mb: 0.5,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          ) : (
            params?.row?.number
          ),
      },
    ],
    [changedCensus, hasEditAccess]
  );
  const CustomLeftToolbar = useCallback(
    () => (
      <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ my: 1 }} spacing={2}>
          <Grid item>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                Current
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                {currentFacility?.current}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                Total
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                {currentFacility?.censusTotal}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                {currentFacility?.budgetDayCount} Day Avg
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'primary.main',

                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => setChangeAverage(!changeAverage)}
              >
                {twoDecimal(currentFacility?.average)}
                <Iconify icon={changeAverage ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={20} height={20} />
              </Typography>
            </Box>
          </Grid>

          {changeAverage && (
            <Grid item>
              <Box sx={{ textAlign: 'left' }}>
                <Grid item>
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    SET AVERAGE DAYS
                  </Typography>
                </Grid>

                <TextField
                  size="small"
                  type={'number'}
                  defaultValue={average}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10) || 0;
                    setAverage(value);
                  }}
                  sx={{
                    width: 100,
                  }}
                />
              </Box>
            </Grid>
          )}
          {(changedCensus.length > 0 || changeAverage) && (
            // {!isDialog && changedCensus.length > 0 && (
            <Grid item>
              <Button variant="outlined" color="error" size="small" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
                Cancel
              </Button>
              <AwaitButton
                variant="contained"
                color="primary"
                size="small"
                sx={{ width: 150 }}
                onClick={updateCensus}
                snackbarMessage="Census Updated"
              >
                Save
              </AwaitButton>
            </Grid>
          )}
        </Grid>
      </>
    ),
    [currentFacility, changedCensus.length, changeAverage, isDialog, average]
  );
  return (
    <>
      {isDialog ? (
        <Dialog open={!!open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle
            sx={{
              textAlign: 'center',
            }}
          >
            Update Census
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon="ic:sharp-close" width={28} height={28} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ height: '100%', width: '100%' }}>
            <DataGridPro
              rows={data}
              columns={columns}
              sort={[{ field: 'facility', sort: 'asc' }]}
              getRowId={(row) => row.date}
              isLoading={isLoading}
              components={{
                Toolbar: CustomLeftToolbar,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" size="small" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
              Cancel
            </Button>
            <AwaitButton
              variant="contained"
              color="primary"
              size="small"
              sx={{ width: 150 }}
              onClick={updateCensus}
              snackbarMessage="Census Updated"
            >
              Save
            </AwaitButton>
          </DialogActions>
        </Dialog>
      ) : (
        <Paper
          sx={{
            px: 4,
          }}
        >
          <DialogTitle
            sx={{
              textAlign: 'center',
            }}
          >
            Update Census
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon="ic:sharp-close" width={28} height={28} />
            </IconButton>
          </DialogTitle>

          <DataGridPro
            rows={data}
            columns={columns}
            sort={[{ field: 'facility', sort: 'asc' }]}
            getRowId={(row) => row.date}
            isLoading={isLoading}
            components={{
              Toolbar: CustomLeftToolbar,
            }}
          />
        </Paper>
      )}
    </>
  );
}
