import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

OpenInNew.propTypes = { blobUrl: PropTypes.blobUrl | undefined };
export default function OpenInNew({ blobUrl }) {
  const handleOpenInNewTab = () => {
    window.open(blobUrl);
  };
  return (
    <Box>
      <Tooltip title={'open in new tab'}>
        <IconButton color="secondary" sx={{ p: 0.5 }} onClick={handleOpenInNewTab}>
          <OpenInNewIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
