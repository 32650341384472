import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { StatementView } from '../../../components/dashboard/invoicing';
import { useDispatch, useSelector } from '../../../redux/store';
import CustomToolbar from '../../../components/dashboard/invoicing/CustomToolbar';
import { getStatementsInvoicing } from '../../../redux/slices/invoicing';

export default function Statements() {
  const dispatch = useDispatch();
  const location = useLocation();
  const section = location.pathname.split('/').pop();

  useEffect(() => {
    dispatch(getStatementsInvoicing());
  }, []);
  const [activeRows, setActiveRows] = useState([]);
  const customToolbar = useCallback(() => {
    const isFiltered = (filter) => filter === section;
    return <CustomToolbar isFiltered={isFiltered} activeRows={activeRows} />;
  }, [activeRows]);

  return (
    <>
      <Helmet>
        <title>Invoicing | Platform Solutions</title>
      </Helmet>
      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <StatementView CustomToolbar={customToolbar} setActiveRows={setActiveRows} />
        </Grid>
      </Grid>
    </>
  );
}
