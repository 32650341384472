import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Tooltip,
  TextField,
  Box,
} from '@mui/material';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { getInvoiceStatus } from '../../../api/invoicing';
import Iconify from '../../../reusable-components/iconify';
import FormTextWithValue from '../../../reusable-components/form/FormTextWithValue';
import FormDateWithValue from '../../../reusable-components/form/FormDateWithValue';
import { SpinningLogo } from '../../../reusable-components/logo';

InvoiceStatusModal.propTypes = {
  row: PropTypes.object,
};

export default function InvoiceStatusModal({ row }) {
  const { id, total, vendor, bookingVendorStatus } = row;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    id: 0,
    invoiceId: 0,
    state: '',
    vendor: '',
    description: '',
    dateCreatedByBookingVendor: '',
    glDate: '',
    dueDate: '',
    paidDate: null,
    onHold: false,
    totalPaid: 0,
    totalDue: 0,
    link: '',
    glItems: [],
    docOrCheckingNum: '',
    paymentType: '',
    paymentUrl: null,
  });

  const getData = async () => {
    const response = await getInvoiceStatus(id);
    if (response.data) {
      setValue(response.data);
    }
  };

  const getColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'primary';
      case 'Posted':
        return 'info';
      case 'Reversed':
        return 'error';
      case 'Selected':
        return 'warning';
      case 'Partially Paid':
        return 'warning';
      default:
        return '#000000';
    }
  };
  const getTooltip = (status) => {
    switch (status) {
      case 'Paid':
        return 'Paid';
      case 'Posted':
        return 'Posted';
      case 'Reversed':
        return 'Reversed';
      case 'Selected':
        return 'Selected for paymen';
      case 'Partially Paid':
        return 'Partially Paid';
      default:
        return 'No Data';
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={getTooltip(bookingVendorStatus)}>
        <IconButton onClick={handleClickOpen} color={getColor(bookingVendorStatus)}>
          <RequestQuoteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Invoice Status
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          {!value.invoiceId ? (
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
              <Box
                sx={{
                  zIndex: 999999999999999,
                  height: '100%',
                  minHeight: '60vh',
                }}
              >
                <SpinningLogo />
              </Box>
            </Grid>
          ) : (
            <Grid container direction="row" justifyContent="center" alignItems="flex-start">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 0.5 }}
                spacing={2}
              >
                <FormTextWithValue value={{ total }} title="Invoice Total" flex={4} name="total" disabled />
                <FormTextWithValue value={value} title="Total Paid" flex={4} name="totalPaid" disabled />
                <FormTextWithValue value={value} title="Total Due" flex={4} name="totalDue" disabled />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 0.5 }}
                spacing={2}
              >
                <FormTextWithValue value={value} title="Status" flex={4} name="state" disabled />
                <FormTextWithValue value={value} title="On Hold" flex={4} name="onHold" disabled />
                <FormTextWithValue value={value} title="Document/Check no" flex={4} name="docOrCheckingNum" disabled />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 0.5 }}
                spacing={2}
              >
                <FormTextWithValue value={{ vendor }} title="Invoice Vendor" flex={6} name="vendor" disabled />
                <FormTextWithValue value={value} title="Booked To Vendor" flex={6} name="vendor" disabled />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 0.5 }}
                spacing={2}
              >
                <FormDateWithValue value={value} title="GL Date" flex={3} name="glDate" disabled isdate />
                <FormDateWithValue value={value} title="Due Date" flex={3} name="dueDate" disabled isdate />
                <FormDateWithValue value={value} title="Paid Date" flex={3} name="paidDate" disabled isdate />
                <FormDateWithValue
                  value={value}
                  title="Date Submitted"
                  flex={3}
                  name="dateCreatedByBookingVendor"
                  disabled
                  isdate
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 0.5 }}
                spacing={2}
              >
                <FormTextWithValue value={value} title="Payment Type" flex={6} name="paymentType" disabled />
                <FormTextWithValue value={value} title="Payment URL" flex={6} name="paymentUrl" disabled />
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 0.5 }}
                spacing={2}
              >
                <FormTextWithValue value={value} title="Description" flex={12} name="description" disabled />
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 0.5 }}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                      Link
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: 'blue', cursor: 'pointer' }}
                      onClick={() => {
                        window.open(value.link, '_blank');
                      }}
                    >
                      {value.link ? value.link : ''}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

                {value.glItems && <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{ mt: 0.5 }}
                  spacing={2}
                >
                  <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    GL Items
                  </Typography>
                  {value.glItems.map((item, index) => (
                    <>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{ mt: 0.5 }}
                        spacing={2}
                      >
                        <FormTextWithValue value={item} title="Facility" flex={3} name="facility" disabled />
                        <FormTextWithValue value={item} title="Account" flex={3} name="glAccount" disabled />
                        <FormTextWithValue value={item} title="Department" flex={3} name="department" disabled />
                        <FormTextWithValue value={item} title="Amount" flex={3} name="amount" disabled />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{ mt: 0.5 }}
                        spacing={2}
                      >
                        <FormTextWithValue value={item} title="Memo" flex={12} name="memo" disabled />
                      </Grid>
                    </>
                  ))}
                </Grid>}

              {/* <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ mt: 0.5 }}
                spacing={2}
              >
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                    GL Items
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                    {value.glItems.map((item, index) => (
                      <>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="flex-start"
                          sx={{ mt: 0.5 }}
                          spacing={2}
                        >
                          <FormTextWithValue value={item} title="Facility" flex={3} name="facility" disabled />
                          <FormTextWithValue value={item} title="Account" flex={3} name="glAccount" disabled />
                          <FormTextWithValue value={item} title="Department" flex={3} name="department" disabled />
                          <FormTextWithValue value={item} title="Amount" flex={3} name="amount" disabled />
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="flex-start"
                          sx={{ mt: 0.5 }}
                          spacing={2}
                        >
                          <FormTextWithValue value={item} title="Memo" flex={12} name="memo" disabled />
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Box>
              </Grid> */}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
