import { Button, Grid, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getFacilitiesAndUsersList, getTicketData, getTicketMessages } from '../../../../redux/slices/tickets';
import { useDispatch } from '../../../../redux/store';
import Ticket from './Ticket';
import TicketActions from './TicketActions';
import TicketInfo from './TicketInfo';
import Iconify from '../../../../reusable-components/iconify';

export default function TicketView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const subticket = new URLSearchParams(location.search).get('subticket');
  const { id = '' } = useParams();

  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs')); // 0px - 599px

  const isSm = useMediaQuery((theme) => theme.breakpoints.only('sm')); // 600px - 959px
  const isMd = useMediaQuery((theme) => theme.breakpoints.only('md')); // 960px - 1279px
  const isLg = useMediaQuery((theme) => theme.breakpoints.only('lg')); // 1280px - 1919px
  const isXl = useMediaQuery((theme) => theme.breakpoints.only('xl')); // >= 1920px

  useEffect(() => {
    dispatch(getFacilitiesAndUsersList());
    dispatch(getTicketData(id));
    dispatch(getTicketMessages({ id: subticket || id }));
  }, [id, subticket]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item sx={{ mt: -4, mb: 1 }}>
            <Button
              variant="text"
              onClick={() => {
                navigate(`/dashboard/tickets?filter=active`);
              }}
              startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
              sx={{
                color: '#454F5B',
                fontWeight: 'bold',
              }}
            >
              Back to tickets
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={isXl ? 4 : 1}>
          <Grid item xs={12} sm={12} md={4} lg={2.5} xl={2.5}>
            {(isMd || isLg || isXl) && <TicketInfo />}
            {isMd && <TicketActions />}
            {(isSm || isXs) && (
              <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>
                <Grid item xs={6}>
                  <TicketActions />
                </Grid>
                <Grid item xs={6}>
                  <TicketInfo />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={7} xl={5}>
            <Ticket />
          </Grid>
          {(isLg || isXl) && (
            <Grid item lg={2.5} xl={2.5}>
              <TicketActions />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
