import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Stack, Slider, Typography, Tooltip } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Icon } from '@iconify/react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

Zoom.propTypes = { zoom: PropTypes.number, setZoom: PropTypes.func };
export default function Zoom({ zoom, setZoom }) {
  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2)); // Limit zoom-in to 200%
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Limit zoom-out to 50%
  };
  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };
  const handleResetZoom = () => {
    setZoom(1);
  };

  return (
    <Box>
      <Stack direction="row">
        <Tooltip title={'zoom out'}>
          <IconButton color="secondary" sx={{ p: 0.5 }} onClick={handleZoomOut}>
            <ZoomOutIcon />
          </IconButton>
        </Tooltip>
        <Slider value={zoom} color="secondary" onChange={handleZoomChange} step={0.1} min={0.5} max={2} />
        <Tooltip title={'zoom in'}>
          <IconButton color="secondary" sx={{ p: 0.5 }} onClick={handleZoomIn}>
            <ZoomInIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={'reset'}>
          <IconButton color="secondary" sx={{ p: 0.5 }} onClick={handleResetZoom}>
            <Icon icon="system-uicons:zoom-reset" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}
