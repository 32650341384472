import { useState, useEffect } from 'react';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { getTicketCategories, postDeleteTicketCategory } from '../../../api/tickets';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import EditIconButton from '../../../reusable-components/basic-buttons/EditIconButton';
import TicketCategoryFormModal from './ticket/TicketCategoryFormModal';
import AddButton from '../../../reusable-components/basic-buttons/AddButton';

export default function TicketCategories()
{
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [row, setRow] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTicketCategories()
      .then(res => setData(res.data))
      .catch(() => enqueueSnackbar('Failed to fetch ticket categories', { variant: 'error' }))
      .finally(() => setLoading(false));
  }, []);

  const deleteCategory = (id) => {
    postDeleteTicketCategory(id)
      .then(() => {
        setData(data => data.filter(row => row.id !== id));
        enqueueSnackbar('Ticket category deleted successfully', { variant: 'success' })
      })
      .catch(() => enqueueSnackbar('Failed to delete ticket category', { variant: 'error' }));
  };


  const gridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      type: 'customText',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      id: 'actions',
      renderCell: (params) => <>
        <EditIconButton onClick={() => { setRow(params.row); setOpenForm(true); }} />
        <ConfirmDialog
          content={`Are you sure you want to delete category - ${params.row.name}?`}
          action={() => deleteCategory(params.row.id)}
        />
      </>
    }
  ];

  return (
    <>
      <CustomDataGrid
        gridId="tickets-category-view"
        boxSX={{ height: 'calc(100vh - 300px)' }}
        data={data}
        gridColumns={gridColumns}
        CustomLeftToolbar={() => <AddButton onClick={() => { setRow(null); setOpenForm(true); }} >Add Category</AddButton> }
        loading={loading}
      />
      <TicketCategoryFormModal
        open={openForm}
        row={row}
        onClose={() => { setRow(null); setOpenForm(false); }}       
        onSave={newData => {
          setRow(null);
          setOpenForm(false);
          if (newData) setData(fullData => [...fullData.filter(dataRow => dataRow.id !== newData.id), newData]);
        }}
      />
    </>
  );
}
