import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { GroupView } from '../../../components/dashboard/admin/groups';

// ----------------------------------------------------------------------

export default function Groups() {
  return (
    <>
      <Helmet>
        <title>Admin Groups | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <GroupView />
        </Grid>
      </Grid>
    </>
  );
}
