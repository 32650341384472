import { AppBar, Box, Button, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useCallback } from 'react';
import { useTabs } from '../../../context/TabContext';
import { useSelector } from '../../../redux/store';
import Dnd from '../../../reusable-components/DragAndDrop/Dnd';
import Iconify from '../../../reusable-components/iconify';

const CustomAppBar = styled(AppBar)(() => ({
  flexGrow: 1,
  width: '100%',
  color: 'inherit',
  backgroundColor: '#DFE3E8',
  marginTop: '-20px',
  boxShadow: 'none',
  minHeight: '42px',
  height: '42px',
  maxHeight: '42px',
}));


const shortTitle = (text, url) => {
  // Add url parameter
  //remove the letters " | Platform Solutions" from the end of the title
  const title = text.replace(' | Platform Solutions', '');
  //split the title into an array of words
  const titleArray = title.split(' ');

  // Check if title is "Order" or "Ticket"
  if (['Order', 'Ticket'].includes(titleArray[0])) {
    const match = url.match(/\/(\d+)/); // Extract number from URL
    if (match) {
      return `${titleArray[0]} #${match[1]}`; // Append # followed by the number to the title
    }
  }

  //if the title is longer than 3 words, return the first 3 words with a ... at the end
  if (titleArray.length > 3) {
    return `${titleArray[0]} ${titleArray[1]} ${titleArray[2]}...`;
  }
  //otherwise return the title as is
  return title;
};

const CustomTabs = () => {
  const { ssoLogin } = useSelector((state) => state.user.user);

  const { setTabList, tabList, value, handleTabChange, addTab, deleteTab } = useTabs();

  const moveItem = useCallback((sourceItem, destinationItem) => {
    const sourceItemIndex = tabList?.findIndex(item => item.id === sourceItem?.id);
    const destinationItemIndex = tabList?.findIndex(item => item.id === destinationItem?.id);
    if (sourceItemIndex > -1 && destinationItemIndex > -1 && tabList[sourceItemIndex].url !== tabList[destinationItemIndex].url) {
      const newArr = [...tabList];
      newArr[sourceItemIndex] = destinationItem;
      newArr[destinationItemIndex] = sourceItem;
      setTabList(newArr);
    }
  }, [tabList]);

  return (
    <CustomAppBar position="static">
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        {ssoLogin && (
          <Grid item>
            <Button
              key="sso"
              onClick={() => window.open('https://app.agoraserv.com', '_self')}
              variant={'contained'}
              sx={{
                zIndex: 9999,
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
                borderRadius: '5px 5px 0px 0px',
                backgroundColor: '#DFE3E8',
                color: '#000',
                minHeight: '43px',
                height: '43px',
                maxHeight: '43px',
                bottomBorderRadius: '0px',
                marginLeft: '4px',
                mt: '-3px',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#F4F6F8',
                  color: '#000',
                  borderBottom: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderTop: 'none',
                },
              }}
            >
              <Box component="img" src="/assets/agora-logo.png" sx={{ height: 25 }} />
            </Button>
          </Grid>
        )}
        <Grid item>
          {tabList.map((tab, index) => (
            <Dnd key={tab.key} item={tab} moveItem={moveItem} sx={{ display: 'inline' }}>
              <Button
                key={tab.key.toString()}
                onClick={() => handleTabChange(tab.id)}
                variant={value === tab.id ? 'outlined' : 'contained'}
                sx={{
                  //remove bottom border from selected tab
                  zIndex: 9999,
                  borderBottom: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderTop: 'none',
                  borderRadius: '5px 5px 0px 0px',
                  backgroundColor: value === tab.id ? '#F4F6F8' : '#DFE3E8',
                  color: '#000',
                  minHeight: '43px',
                  height: '43px',
                  maxHeight: '43px',
                  bottomBorderRadius: '0px',
                  marginLeft: '4px',
                  mt: '-3px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#F4F6F8',
                    color: '#000',
                    borderBottom: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderTop: 'none',
                  },
                }}
                endIcon={
                  <Iconify icon="mdi:close" id={tab.id} onClick={(e) => deleteTab(e, tab.id)} width={20} height={20} />
                }
              >
                {shortTitle(tab.title, tab.url)}
              </Button>
            </Dnd>
          ))}
        </Grid>

        <Grid item>
          <IconButton
            onClick={addTab}
            sx={{ opacity: 0.8 }}>
            <Iconify icon="mdi:plus" width={28} height={28} />
          </IconButton>
        </Grid>
      </Grid>
    </CustomAppBar>
  );
};

export default CustomTabs;
