import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { PurchasingCategoriesView } from '../../../components/dashboard/admin/purchasing-categories';

// ----------------------------------------------------------------------

export default function PurchasingCategories() {
  return (
    <>
      <Helmet>
        <title>Admin Purchasing Categories | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <PurchasingCategoriesView />
      </Grid>
    </>
  );
}
