import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Iconify from '../../iconify';
import { useTabs } from '../../../context/TabContext';

CustomNumberPanel.propTypes = {
  activeFilter: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  gridId: PropTypes.string,
};

export default function CustomNumberPanel({ activeFilter, filters, setFilters, gridId }) {
  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const { activeTab } = useTabs();
  const sessionStorageKey = `tab-${activeTab.key}-filters-${baseUrl}-${gridId}`;
  const [value, setValue] = useState(filters[activeFilter]?.value[0] || '');

  const updateSessionStorage = (newFilters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(newFilters));
  };

  useEffect(() => {
    setValue(filters[activeFilter]?.value[0] || '');
  }, [activeFilter, filters]);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);

    if (!Number.isNaN(newValue)) {
      setValue(newValue);
      const newFilters = { ...filters, [activeFilter]: { value: [newValue], operator: 'contains' } };
      setFilters(newFilters);
      updateSessionStorage(newFilters);
    } else {
      setValue('');
      const newFilters = { ...filters };
      delete newFilters[activeFilter];
      setFilters(newFilters);
      updateSessionStorage(newFilters);
    }
  };

  const handleClear = () => {
    setValue('');
    const newFilters = { ...filters };
    delete newFilters[activeFilter];
    setFilters(newFilters);
    updateSessionStorage(newFilters);
  };

  return (
    <TextField
      type="number"
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <Iconify icon={'ri:close-fill'} width={20} height={20} sx={{ cursor: 'pointer' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
