export const UOM_TYPES = [
  { value: 'BG', label: 'BG - Bag' },
  { value: 'BKT', label: 'BKT - Bucket' },
  { value: 'BT', label: 'BT - Bottle' },
  { value: 'BX', label: 'BX - Box' },
  { value: 'CN', label: 'CN - Can' },
  { value: 'CA', label: 'CA - Case' },
  { value: 'CS', label: 'CS - Case' },
  { value: 'CT', label: 'CT - Carton' },
  { value: 'CTN', label: 'CTN - Carton' },
  { value: 'DZ', label: 'DZ - Dozen' },
  { value: 'EA', label: 'EA - Each' },
  { value: 'EZ', label: 'EZ' },
  { value: 'FT', label: 'FT - Feet' },
  { value: 'GA', label: 'GA - Gallon' },
  { value: 'GL', label: 'GL - Gallon' },
  { value: 'GR', label: 'GR - Gram' },
  { value: 'GROSS', label: 'GROSS - Gross' },
  { value: 'KT', label: 'KT - Kit' },
  { value: 'LOT', label: 'LOT - Lot' },
  { value: 'LB', label: 'LB - Pound' },
  { value: 'PK', label: 'PK - Pack' },
  { value: 'PK50', label: 'PK50 - Pack 50' },
  { value: 'PK100', label: 'PK100 - Pack 100' },
  { value: 'PKG', label: 'PKG - Package' },
  { value: 'PL', label: 'PL - Pail' },
  { value: 'NA', label: 'NA - Not Applicable' },
  { value: 'OZ', label: 'OZ - Ounce' },
  { value: 'PR', label: 'PR - Pair' },
  { value: 'RL', label: 'RL - Roll' },
  { value: 'RM', label: 'RM - Ream' },
  { value: 'ST', label: 'ST - Set' },
  { value: 'TB', label: 'TB - Tube' },
  { value: 'UN', label: 'UN - Unit' },
  { value: 'YD', label: 'YD - Yard' },
  { value: 'OT', label: 'OT - Other' },
];

export const FIFTY_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const TRUE_FALSE = [
  { value: true, label: 'YES' },
  { value: false, label: 'NO' },
];

export const FAC_STRUCTURE = [
  { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
  { value: 'Partnership', label: 'Partnership' },
  { value: 'LLC', label: 'LLC' },
  { value: 'C Corp', label: 'C Corp' },
  { value: 'S Corp', label: 'S Corp' },
  { value: 'Benefit Corporation', label: 'Benefit Corporation' },
  { value: 'NonProfit', label: 'NonProfit' },
];
export const PAY_METHOD = [
  { value: 'check', label: 'Check' },
  { value: 'ACH', label: 'ACH' },
  { value: 'CC', label: 'Credit Card' },
];
