import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import { ResetPassword } from '../../components/auth';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  return (
    <>
      <Helmet>
        <title>Reset Password | Platform Solutions</title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <ResetPassword />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
