import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------
// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};
//LIME GREEN
const PRIMARY = {
  lighter: '#ddeec6',
  light: '#A3D165',
  main: '#8dc63f',
  dark: '#628A2C',
  darker: '#44601E',
  contrastText: '#fff',
};
//PURPLE
const SECONDARY = {
  lighter: '#CC75D3',
  light: '#C053C9',
  main: '#b128bc',
  Dark: '#7B1C83',
  darker: '#56135B',
  contrastText: '#fff',
};
//BLUE
const INFO = {
  lighter: '#8BC5EF',
  light: '#6FB7EB',
  // main: '#4ca5e7',
  main: '#208ee0',
  Dark: '#3573A1',
  darker: '#255070',
  contrastText: '#fff',
};
//MINT GREEN
const SUCCESS = {
  lighter: '#93CFA0',
  light: '#79C489',
  main: '#58b66c',
  Dark: '#3D7F4B',
  darker: '#2A5834',
  contrastText: GREY[800],
};
//ORANGE
const WARNING = {
  lighter: '#F5C081',
  light: '#F3B162',
  main: '#f19e3b',
  Dark: '#A86E29',
  darker: '#754D1C',

  contrastText: GREY[800],
};
//RED
const ERROR = {
  lighter: '#FFA48D',
  light: '#FF3B33',
  main: '#ff0a00',
  dark: '#B20700',
  darker: '#7A0C2E',
  contrastText: '#fff',
};
const BLACK = {
  main: '#000000',
  contrastText: '#fff',
};
const WHITE = {
  main: '#ffffff',
  contrastText: GREY[800],
};

const palette = {
  mode: 'light',
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  black: BLACK,
  white: WHITE,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[200],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[900], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
