import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { possibleProductUOMsData, postAddProductImage } from '../../../../api/catalog';
import { getReplacementData, postOrderProductForm } from '../../../../api/orders';
import { addItemToAnOrderInRedux, removeItemFromErrorList } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { UOM_TYPES } from '../../../../utils/dropdowns';
import { formatMoney } from '../../../../utils/formatNumber';
import { AwaitButton } from '../../../../reusable-components/await-button';

AddItem.propTypes = {
  row: PropTypes.object,
  errorRow: PropTypes.object,
  handleClose: PropTypes.func,
  quantity: PropTypes.number,
};

export default function AddItem({ row, errorRow, handleClose, quantity }) {
  const { id = '' } = useParams();
  const dispatch = useDispatch();
  const {
    trigger,
    control,
    watch,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const {
    vendorItemLink,
    vendorItemDescription,
    vendorItemId,
    uomAmount,
    uom,
    price,
    pictureUrls,
    message,
    manufacturerNumber,
    manufacturer,
    description,
    baseUOMType,
    baseUOMAmount,
    baseMinUOMAmount,
  } = row;

  const { agoraCategory, agoraSubcategory, uomType, vendor, vendorId } = errorRow;

  const [productData, setProductData] = useState({
    vendor,
    vendorId,
    vendorItemLink,
    vendorItemDescription,
    vendorItemId,
    uomAmount,
    uom,
    price,
    pictureUrls,
    message,
    manufacturerId: manufacturerNumber,
    manufacturer,
    description,
    baseUOMType,
    baseUOMAmount,
    baseMinUOMAmount,
    notes: '',
    isPPD: false,
  });

  const { orderById } = useSelector((state) => state.orders || {});
  const { orderAgoraCategories, agoraSubcategories } = orderById[id] || {};
  const [fetching, setFetching] = useState(false);
  const [additionalUOM, setAdditionalUOM] = useState(false);
  const [uomType2, setUomType2] = useState(null);
  const [uomTypes, setUomTypes] = useState(uomAmount);
  const [attachedId, setAttachedId] = useState(errorRow?.productId);
  const [attachToItem, setAttachToItem] = useState('attach');
  const [replacementData, setReplacementData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);
  const [categories, setCategories] = useState([
    {
      agoraCategoryId: agoraCategory.id,
      agoraSubcategoryId: agoraSubcategory.id,
    },
  ]);

  useEffect(() => {
    if (attachToItem === 'separate') {
      setAttachedId(errorRow?.productId);
    } else {
      setAttachedId(errorRow?.productId);
      updateProductData('description', errorRow?.description);
      setCategories([
        {
          agoraCategoryId: errorRow?.agoraCategory?.id,
          agoraSubcategoryId: errorRow?.agoraSubcategory?.id,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachToItem]);

  useEffect(() => {
    trigger();
  }, []);

  const checkUOM = async (vendor, vendorId) => {
    setFetching(true);
    if (vendor && vendorId) {
      const response = await possibleProductUOMsData(vendor, vendorId);
      const data = response;
      if (data.status === 200) {
        if (data.data.length === 0) {
          setUomTypes(UOM_TYPES);
          return;
        }
        const filteredUOMTypes = UOM_TYPES.filter((uom) => data.data.includes(uom.value)).concat(
          data.data.filter((x) => !UOM_TYPES.some((t) => t.value === x)).map((uom) => ({ label: uom, value: uom }))
        );
        setUomTypes(filteredUOMTypes);
      } else {
        setUomTypes(UOM_TYPES);
      }
      setFetching(false);
    }
  };

  const replacementDataApi = async (orderId) => {
    const response = await getReplacementData(orderId);
    const data = response;
    if (data.status === 200) {
      setReplacementData(data.data.items);
    }
  };

  useEffect(() => {
    checkUOM(vendor, vendorId);
  }, [vendor, vendorId]);

  useEffect(() => {
    replacementDataApi(id);
  }, [id]);

  const updateProductData = (field, value) => {
    if (field === 'pictureUrls') {
      setProductData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else {
      setProductData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };
  const [textFieldUrl, setTextFieldUrl] = useState(''); // Separate state for TextField

  const handleChangeTextField = (newUrl) => {
    setTextFieldUrl(newUrl); // Update TextField's state independently
  };

  const handleAddImage = async () => {
    const newPictureUrls = [...(productData?.pictureUrls || [])];

    let imageUrlToAdd = textFieldUrl;

    if (!textFieldUrl.includes('//res.cloudinary.com/agoraserv')) {
      try {
        const imgUrl = await postAddProductImage(textFieldUrl);
        if (imgUrl.data.length) {
          imageUrlToAdd = imgUrl.data;
        } else {
          enqueueSnackbar('Invalid image url', { variant: 'error' });
          return;
        }
      } catch (error) {
        enqueueSnackbar('Failed to fetch image url', { variant: 'error' });
        return;
      }
    }

    newPictureUrls.push(imageUrlToAdd);
    updateProductData('pictureUrls', newPictureUrls);
    setTextFieldUrl('');
  };

  const handleRemoveImage = (index) => {
    const newPictureUrls = [...(productData?.pictureUrls || [])];
    newPictureUrls?.splice(index, 1);
    updateProductData('pictureUrls', newPictureUrls);
  };

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: true,
  });
  const handleFileInputChange = async (event) => {
    const { files } = event.target;

    const promiseArr = Array.from(files)
      .map((file) => {
        if (!file?.type.startsWith('image/')) {
          enqueueSnackbar('Invalid file type', { variant: 'error' });
          return;
        }
        // eslint-disable-next-line consistent-return
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function onFileLoad() {
            const base64Image = reader.result.split('base64,')[1];
            resolve(postAddProductImage(base64Image));
          };
          reader.onerror = function onFileLoadError(error) {
            enqueueSnackbar('Error uploading image', { variant: 'error' });
            reject(error);
          };
        });
      })
      .filter(Boolean);

    const imageUrls = await Promise.all(promiseArr);
    updateProductData(
      'pictureUrls',
      (productData?.pictureUrls ?? []).concat(imageUrls.map((x) => x?.data).filter((x) => x != null))
    );
  };

  const addProduct = async () => {
    let picUrls = productData?.pictureUrls ? [...productData?.pictureUrls] : [];
    const processedUrls = await Promise.all(
      picUrls.map(async (url) => {
        if (!url.includes('//res.cloudinary.com/agoraserv')) {
          const imgUrl = await postAddProductImage(url);
          return imgUrl.data.length ? imgUrl.data : null;
        }
        return url;
      })
    );

    // Filter out null values (where imgUrl.data had a length of 0)
    picUrls = processedUrls.filter((url) => url !== null);

    // update pictureUrls in productData before calling addProductToOrderGuides
    productData.pictureUrls = picUrls;

    //then add product
    const response = await postOrderProductForm({
      parentData: { description: productData.description, notes: productData.notes },
      productData,
      categories,
      vendorId: vendorItemId,
      vendorValue: vendorId,
    });
    if (response.status === 200) {
      dispatch(
        addItemToAnOrderInRedux({
          ...response.data,
          orderId: id,
          id: response.data.productId,
          approved: true,
          quantity,
          vendorId: errorRow.vendorId,
          vendor: errorRow.vendor,
        })
      );
      dispatch(
        addItemToAnOrderInRedux({
          ...errorRow,
          orderId: id,
          approved: false,
          errors: [],
          itemDirectReplacements: [],
          itemSubstitutes: [],
        })
      );
      dispatch(removeItemFromErrorList(id, errorRow.id));

      handleClose();
    }
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={2}>
            <DialogContentText sx={{ fontWeight: 'bold' }}>Attach to item</DialogContentText>
            <ToggleButtonGroup value={attachToItem} exclusive onChange={(e) => setAttachToItem(e.target.value)}>
              <ToggleButton size="small" value="attach">
                Yes
              </ToggleButton>
              <ToggleButton size="small" value="separate">
                No
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {attachToItem === 'attach' && (
            <Grid item xs={10}>
              <DialogContentText sx={{ fontWeight: 'bold' }}>Attach item to</DialogContentText>

              <Controller
                name="description"
                control={control}
                defaultValue={productData.description}
                rules={{ required: 'description' }}
                render={({ field }) => (
                  <Autocomplete
                    size="small"
                    sx={{
                      width: '100%',
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      '& .MuiFormLabelRoot ': 'none',
                    }}
                    type="new-password"
                    options={replacementData || []}
                    autoHighlight
                    getOptionLabel={(option) => option?.description}
                    value={
                      (replacementData.length > 0 && replacementData.find((item) => item.id === attachedId)) || null
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                      setAttachedId(newValue?.id);
                      updateProductData('description', newValue.description);
                      setCategories([
                        {
                          agoraCategoryId: newValue.agoraCategoryId,
                          agoraSubcategoryId: newValue.agoraSubcategoryId,
                        },
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Description *</DialogContentText>{' '}
          <TextField
            size="small"
            label=""
            fullWidth
            multiline
            disabled={attachToItem === 'attach'}
            value={productData?.description || ''}
            onChange={(e) => {
              updateProductData('description', e.target.value);
            }}
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Notes</DialogContentText>{' '}
          <TextField
            size="small"
            label=""
            fullWidth
            multiline
            minRows={2}
            value={productData?.notes || ''}
            onChange={(e) => {
              updateProductData('notes', e.target.value);
            }}
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      {categories.map((category, index) => (
        <Grid
          key={`${index}-${category.agoraCategoryId}-${category.agoraSubcategoryId}`}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={6}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Category *</DialogContentText>{' '}
            <Controller
              name="category"
              control={control}
              defaultValue={categories}
              rules={{ required: 'Category' }}
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabelRoot ': 'none',
                    width: '90%',
                  }}
                  disabled={attachToItem === 'attach'}
                  type="new-password"
                  options={orderAgoraCategories || []}
                  autoHighlight
                  getOptionLabel={(option) => option?.name}
                  value={orderAgoraCategories.find((cat) => cat.id === categories[index].agoraCategoryId)}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                    setCategories((prevCategories) => {
                      const newCategories = [...prevCategories];
                      newCategories[index].agoraCategoryId = newValue?.id;
                      return newCategories;
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label=""
                      variant="outlined"
                      inputProps={{ ...params.inputProps, autoComplete: 'new-password', form: { autocomplete: 'off' } }}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={attachToItem === 'separate' ? 5 : 6}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Subcategory *</DialogContentText>{' '}
            <Controller
              name="subcategory"
              control={control}
              defaultValue={agoraSubcategories.find((cat) => cat.id === categories[index].agoraSubcategoryId)}
              rules={{ required: 'Subcategory' }}
              render={({ field }) => (
                <Autocomplete
                  size="small"
                  disabled={!categories[index].agoraCategoryId || attachToItem === 'attach'}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabelRoot ': 'none',
                    width: '90%',
                  }}
                  type="new-password"
                  options={
                    agoraSubcategories.filter((cat) => cat.mainCategoryId === categories[index].agoraCategoryId) || []
                  }
                  autoHighlight
                  getOptionLabel={(option) => option?.name}
                  value={agoraSubcategories.find((cat) => cat.id === categories[index].agoraSubcategoryId)}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                    setCategories((prevCategories) => {
                      const newCategories = [...prevCategories];
                      newCategories[index].agoraSubcategoryId = newValue?.id;
                      return newCategories;
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label=""
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {attachToItem === 'separate' && (
            <Grid
              item
              xs={1}
              sx={{
                mt: 4,
              }}
            >
              <IconButton
                onClick={() => {
                  if (categories.length === 1) {
                    setCategories([{ agoraCategoryId: null, agoraSubcategoryId: null }]);
                  } else {
                    setCategories((prevCategories) => {
                      const newCategories = [...prevCategories];
                      newCategories?.splice(index, 1);
                      return newCategories;
                    });
                  }
                }}
              >
                <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      {attachToItem === 'separate' && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Button
            variant="text"
            color="primary"
            disabled={categories.some((category) => !category.agoraCategoryId || !category.agoraSubcategoryId)}
            sx={{
              mt: 2,
              textDecoration: 'underline',
            }}
            onClick={() => setCategories([...categories, { agoraCategoryId: null, agoraSubcategoryId: null }])}
          >
            Add additional category
          </Button>
        </Grid>
      )}

      {productData?.error && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography
            variant="body2"
            sx={{
              padding: 1,
              fontWeight: 'bold',
              mt: 2,
              color: '#8dc63f',
              backgroundColor: '#eef7e2',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {productData?.error}
          </Typography>
        </Grid>
      )}

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor *</DialogContentText>
          <Controller
            name="vendor"
            control={control}
            defaultValue={productData?.vendor || ''}
            rules={{ required: 'Vendor' }}
            render={() => (
              <TextField
                value={productData?.vendor || ''}
                disabled
                label=""
                size="small"
                variant="outlined"
                sx={{ width: '90%' }}
                type="text"
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor Item ID *</DialogContentText>
          <Controller
            name="vendorItemId"
            control={control}
            defaultValue={productData?.vendorItemId || ''}
            rules={{ required: 'Vendor item id' }}
            render={() => (
              <TextField
                value={productData?.vendorItemId || ''}
                disabled
                label=""
                size="small"
                variant="outlined"
                sx={{ width: '90%' }}
                type="text"
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>
            UOM Type *{fetching && <Iconify icon="svg-spinners:180-ring-with-bg" color="primary" />}
          </DialogContentText>
          <Controller
            name="uom"
            control={control}
            defaultValue={productData?.uom}
            rules={{ required: 'UOM type' }}
            render={({ field }) => (
              <Autocomplete
                size="small"
                sx={{
                  width: '90%',
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabelRoot ': 'none',
                }}
                type="new-password"
                options={uomTypes || []}
                autoHighlight
                getOptionLabel={(option) => option?.label}
                onLoad={() => checkUOM(vendor, vendorId)}
                value={uomTypes && uomTypes.find((uom) => uom.value === productData?.uom)}
                onChange={(event, newValue) => {
                  field.onChange(newValue.value);
                  updateProductData('uom', newValue.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>UOM Amount *</DialogContentText>
          <Controller
            name="uomAmount"
            control={control}
            rules={{ required: 'UOM Amount' }}
            render={({ field }) => (
              <TextField
                type="number"
                value={productData?.uomAmount}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  updateProductData('uomAmount', e.target.value);
                }}
                label=""
                size="small"
                variant="outlined"
                sx={{ width: '90%' }}
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Price *</DialogContentText>
          <Controller
            name="price"
            control={control}
            defaultValue={productData?.price || ''}
            rules={{ required: 'price' }}
            render={({ field }) => (
              <TextField
                value={productData?.price || ''}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  updateProductData('price', formatMoney(e.target.value));
                }}
                label=""
                type="number"
                size="small"
                variant="outlined"
                sx={{ width: '90%' }}
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Base UOM type</DialogContentText>
          <TextField
            value={productData?.baseUOMType || ''}
            onChange={(e) => updateProductData('baseUOMType', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            sx={{ width: '90%' }}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Base UOM amount</DialogContentText>
          <TextField
            type="number"
            value={productData?.baseUOMAmount || ''}
            onChange={(e) => updateProductData('baseUOMAmount', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            sx={{ width: '90%' }}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>

      {additionalUOM && (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional UOM Type *</DialogContentText>
              <Autocomplete
                size="small"
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabelRoot ': 'none',
                  width: '90%',
                }}
                type="new-password"
                options={uomTypes || []}
                autoHighlight
                getOptionLabel={(option) => option?.label}
                value={productData?.uomType2}
                onChange={(event, newValue) => {
                  setUomType2(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label=""
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional UOM Amount *</DialogContentText>
              <TextField
                type="number"
                value={productData?.uomAmount2 || ''}
                onChange={(e) => updateProductData('uomAmount2', e.target.value)}
                label=""
                size="small"
                variant="outlined"
                sx={{ width: '90%' }}
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional Price *</DialogContentText>
              <TextField
                value={productData?.price2 || ''}
                onChange={(e) => updateProductData('price2', formatMoney(e.target.value))}
                label=""
                size="small"
                variant="outlined"
                sx={{ width: '90%' }}
                type="text"
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Button
          variant="text"
          color={additionalUOM ? 'error' : 'primary'}
          sx={{
            mt: 2,
            textDecoration: 'underline',
          }}
          onClick={() => setAdditionalUOM(!additionalUOM)}
        >
          {additionalUOM ? 'Remove' : 'Add'} additional UOM
        </Button>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Manufacturer</DialogContentText>
          <TextField
            value={productData?.manufacturer || ''}
            onChange={(e) => updateProductData('manufacturer', e.target.value)}
            label=""
            size="small"
            multiline
            variant="outlined"
            sx={{ width: '90%' }}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Manufacturer ID</DialogContentText>
          <TextField
            value={productData?.manufacturerId || ''}
            onChange={(e) => updateProductData('manufacturerId', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            sx={{ width: '90%' }}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={9}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor item link</DialogContentText>
          <TextField
            value={productData?.vendorItemLink || ''}
            onChange={(e) => updateProductData('vendorItemLink', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            sx={{ width: '95%' }}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>PPD</DialogContentText>
          <ToggleButtonGroup
            value={productData?.isPPD}
            exclusive
            onChange={(e) => updateProductData('isPPD', e.target.value)}
          >
            <ToggleButton value="true">Yes</ToggleButton>
            <ToggleButton value="false">No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={9}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor Item Description</DialogContentText>
          <TextField
            value={productData?.vendorItemDescription || ''}
            onChange={(e) => updateProductData('vendorItemDescription', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            sx={{ width: '95%' }}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>

      <DialogContentText sx={{ fontWeight: 'bold', mt: 3, mb: -1 }}>Images</DialogContentText>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
        {productData?.pictureUrls?.map((url, index) => (
          <Grid item xs={2} key={index}>
            <Box m={1} boxShadow={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <FileThumbnail
                file={{ id: index, name: url, type: 'image' }}
                onRemove={() => handleRemoveImage(index)}
                sx={{ width: 128, height: 128 }}
                preview={url}
                displayImage
              />

              <IconButton onClick={() => handleRemoveImage(index)}>
                <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
        <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
          <input {...getInputProps()} />
          <Paper
            elevation={isDragActive ? 6 : 1}
            sx={{
              p: 2,
              bgcolor: 'background.paper',
              color: 'info.main',
              borderColor: 'info.main',
              border: 1,
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                bgcolor: 'background.default',
                boxShadow: 3,
              },
            }}
          >
            <Typography variant="body2" textAlign="center">
              Click to Upload or Drag and Drop
            </Typography>
          </Paper>
        </Box>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
        <Grid item xs={1}>
          <Typography variant="body2">Image url </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            value={textFieldUrl}
            onChange={(e) => handleChangeTextField(e.target.value)}
            label=""
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleAddImage} disabled={!textFieldUrl} color="primary">
            <Iconify icon="material-symbols:check" width={28} height={28} />
          </IconButton>
        </Grid>
      </Grid>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        accept="image/*"
        onChange={handleFileInputChange}
      />
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
        {!vendor || !vendorId || !uomType || !productData?.uomAmount || !productData?.price || additionalUOM
          ? (!productData?.uomAmount2 || !productData?.price2 || !uomType2) && (
              <Alert severity="error" sx={{ width: '100%' }}>
                Required fields:{' '}
                {Object.entries(errors).map(([type, { message, ref }], index, array) => (
                  <span key={type}>
                    {message}
                    {index < array.length - 1 && ',  '}
                  </span>
                ))}
              </Alert>
            )
          : null}
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
        <Box>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150 }}
            onClick={addProduct}
            disabled={
              !vendor || !vendorId || !uomType || !productData?.uomAmount || !productData?.price || additionalUOM
                ? !productData?.uomAmount2 || !productData?.price2 || !uomType2
                : false
            }
          >
            Save to catalog
          </AwaitButton>
        </Box>
      </Grid>
    </>
  );
}
