import { createSlice } from '@reduxjs/toolkit';
import {
  getCatalogData,
  getProductDetailsData,
  getProductHistoryData,
  getProductOrderedData,
  getProductOrderGuideData,
} from '../../api/warehouse';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    agoraCategories: [],
    agoraSubcategories: [],
    products: [],
    vendors: [],
    productDetails: [],
    productHistory: [],
    orderHistory: [],
    productOrderGuide: {
      agoraCategories: [],
      orderGuides: [],
    },
  },
};

const slice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCatalogSuccess(state, action) {
      state.isLoading = false;
      //spread this in to the state so not to overwrite the state
      state.data = { ...state.data, ...action.payload };
    },

    getProductDetailsSuccess(state, action) {
      state.isLoading = false;
      const { agoraCategories, agoraSubcategories, vendors } = action.payload;
      state.data.productDetails = action.payload;
      // agoraCategories is true
      if (agoraCategories) {
        state.data.agoraCategories = agoraCategories;
      }
      if (agoraSubcategories) {
        state.data.agoraSubcategories = agoraSubcategories;
      }
      if (vendors) {
        state.data.vendors = vendors;
      }
    },

    getProductHistorySuccess(state, action) {
      state.isLoading = false;
      state.data.productHistory = action.payload;
    },
    getOrderHistorySuccess(state, action) {
      state.isLoading = false;
      state.data.orderHistory = action.payload;
    },

    getProductOrderGuideSuccess(state, action) {
      state.isLoading = false;
      state.data.productOrderGuide = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getCatalog() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCatalogData();
      dispatch(slice.actions.getCatalogSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductDetails(productId, flag) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProductDetailsData(productId, flag);
      dispatch(slice.actions.getProductDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getProductHistory(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProductHistoryData(productId);
      dispatch(slice.actions.getProductHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderHistory(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProductOrderedData(productId);
      dispatch(slice.actions.getOrderHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductOrderGuide(productId, agoraCategoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProductOrderGuideData(productId, agoraCategoryId);
      dispatch(slice.actions.getProductOrderGuideSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
