import { Box, IconButton } from '@mui/material';
import { HOST_API_URL } from '../../config-global';
import Iconify from '../iconify';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);
const downloadEmail = async (rowId) => {
  //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
  window.open(`${HOST_API_URL}Api/Admin/SentEmail/DownloadSentEmail?id=${rowId}`, '_blank');
};
const actionButtons = (params) => (
  <>
    <Box id="action-button" className="action-button">
      <IconButton color="info" onClick={() => downloadEmail(params.id)} id="action-button" className="action-button">
        <Iconify icon={'mdi:tray-arrow-down'} id="action-button" className="action-button" />
      </IconButton>
    </Box>
  </>
);
const email = {
  field: 'email',
  headerName: 'Email',
  flex: 1,
  minWidth: 110,
  renderCell: (params) => (
    <Box component="div" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}  >
      {params.value}
    </Box>
  ),
};

const type = {
  field: 'emailRecipientType',
  headerName: 'Send For',
  flex: 1,
  minWidth: 90,
  renderCell: (params) => (
    <Box component="div"
      sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', }}  >
      {params.value}
    </Box>
  ),
};
const sendType = {
  field: 'sendType',
  headerName: 'Send Type',
  flex: 1,
  minWidth: 90,
  sortable: true,
  filterable: true,
  renderCell: (params) => (
    <Box component="div"
      sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', }}  >
      {params.value}
    </Box>
  ),
};
const site = {
  field: 'site',
  headerName: 'Site',
  flex: 1,
  minWidth: 90,
  valueGetter: (params) => params.value?.label,
  renderCell: (params) => (<Box component="div" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}  >
    {params.value}
  </Box>),
};


const EMAIL_RECIPIENTS = [email, site, type, sendType];

export { EMAIL_RECIPIENTS };
