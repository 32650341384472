import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPreviousOrderInfoData, getPendingOrderInfoData } from '../../../../api/orders';
import { postAddTicket, postAddTicketImage } from '../../../../api/tickets';
import NewUserAddTicket from '../../../../components/dashboard/tickets/ticket/NewUserAddTicket';
import { getOrdersList } from '../../../../redux/slices/orders';
import { getFacilitiesAndUsersList } from '../../../../redux/slices/tickets';
import { useDispatch, useSelector } from '../../../../redux/store';
import { CustomAvatar, CustomAvatarGroup } from '../../../../reusable-components/custom-avatar';
import { TICKET_ORDERS } from '../../../../reusable-components/datagrid/orderColumns';
import Editor from '../../../../reusable-components/editor';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import Iconify from '../../../../reusable-components/iconify';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import NewTicketTest from '../../../../components/dashboard/tickets/ticket/newTicket/NewTicketTest';

// ----------------------------------------------------------------------

export default function NewTicket() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { facilities, ticketTypes, ticketCategories } = useSelector((state) => state.tickets.data);
  const {
    data: { ordersList },
  } = useSelector((state) => state.orders);
  const { orderById } = useSelector((state) => state.orders || {});
  const { facilityId } = orderById[id] || {};

  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [ticketType, setTicketType] = useState(null);
  const [facility, setFacility] = useState(null);
  const [users, setUsers] = useState([{ value: `${user?.firstName} ${user?.lastName}`, id: user?.email }]);
  const [attachments, setAttachments] = useState([]);
  const [noFacilities, setNoFacilities] = useState(false);
  const [ticketVersion, setTicketVersion] = useState("Old");

  useEffect(() => {
    if (facilityId) {
      setFacility({ id: facilityId, facilityName: orderById[id]?.facility });
    }
  }, [facilityId]);

  const [orderNumber, setOrderNumber] = useState(
    location.pathname.includes('/orders/') ? { id, label: `Order# ${id}` } : null
  );
  const [selected, setSelected] = useState([]);
  const [subject, setSubject] = useState('');
  const [data, setData] = useState([]);
  const [reqLinks, setReqLinks] = useState([{ name: '', link: '', reason: '', price: '' }]);
  const [itemLinks, setItemLinks] = useState([{ name: '', id: '', qty: '' }]);

  useEffect(() => {
    setOrderNumber(location.pathname.includes('/orders/') ? { id, label: `Order# ${id}` } : null);
  }, [id, location.pathname]);

  useEffect(() => {
    if (open) dispatch(getFacilitiesAndUsersList((data) => setNoFacilities(data?.facilities?.length === 0)));
  }, [dispatch, open]);

  const [ticketHtml, setTicketHtml] = useState('');
  const [orderNumberFilter, setOrderNumberFilter] = useState('');
  const [filteredOrdersList, setFilteredOrdersList] = useState([]);

  useEffect(() => {
    if (!ordersList?.length) return;
    if (!orderNumberFilter) {
      setFilteredOrdersList(
        ordersList?.filter((ordersList) => noFacilities || ordersList.facilityId === facility?.id)?.slice(0, 100)
      );
      return;
    }
    const variations = ['order#', 'order ', 'orde', 'ord', 'or', 'o'];
    let filteredOrderNumber = orderNumberFilter?.toLowerCase();
    const matchFound = variations.some((variation) => {
      if (filteredOrderNumber?.toLowerCase().trim().match(variation)) {
        filteredOrderNumber = filteredOrderNumber
          ?.slice(filteredOrderNumber?.toLowerCase().trim().indexOf(variation) + variation.length)
          .trim();
        return true;
      }
      return false;
    });

    if (!matchFound) {
      filteredOrderNumber = filteredOrderNumber.trim();
    }

    const filteredOrdersList = ordersList.filter((order) =>
      order.orderNumber?.toLowerCase().includes(filteredOrderNumber)
    );

    setFilteredOrdersList(
      filteredOrdersList
        ?.filter((filtrdOrdrs) => noFacilities || filtrdOrdrs.facilityId === facility?.id)
        ?.slice(0, 100)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumberFilter, ordersList, facility, noFacilities]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUsers([{ value: `${user?.firstName} ${user?.lastName}`, id: user?.email }]);
    setTicketType(null);
    setFacility(null);
    setOrderNumber(location.pathname.includes('/orders/') ? { id, label: `Order# ${id}` } : null);
    setSelected([]);
    setSubject('');
    setReqLinks([{ name: '', link: '', reason: '', price: '' }]);
    setItemLinks([{ name: '', id: '', quantity: '' }]);
    setData([]);
    setTicketHtml('');
    setOrderNumberFilter('');
    setFilteredOrdersList([]);
    setAttachments([]);
    setErrors({});
    setSubjectMaxChar(false);
  };

  useEffect(() => {
    if (open && !!facility) dispatch(getOrdersList(facility?.id));
  }, [open, facility, orderNumber]);

  useEffect(() => {
    if (
      open &&
      noFacilities &&
      !ordersList &&
      (ticketType?.label === 'Order Assistance' || ticketType?.label === 'Add Item to Order')
    )
      dispatch(getOrdersList());
  }, [open, noFacilities, ticketType]);

  const getPreviousOrderInfo = async () => {
    try {
      if (orderNumber.approved) {
        const { data } = await getPreviousOrderInfoData(orderNumber?.id);
        if (data) {
          setData(data.items);
        }
      } else {
        const { data } = await getPendingOrderInfoData(orderNumber?.id);
        if (data) {
          setData(data.items);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!orderNumber?.id || !open) return;

    getPreviousOrderInfo();
  }, [orderNumber, open]);

  const [errors, setErrors] = useState({});
  const isValidURL = (string) => {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return !!pattern.test(string) || string === null || string === '';
  };

  const handleChangeItemsRequest = (e, index, key) => {
    let isValid = true;
    if (key === 'link') {
      isValid = isValidURL(e.target.value);
      const newErrors = { ...errors };
      newErrors[index] = !isValid;
      setErrors(newErrors);
    }
    if (isValid) {
      if (index === 0 && key === 'name')
        setSubject(
          `New Item Request - ${e.target.value.length < 10 ? e.target.value.split(' ').slice(0, 2).join(' ') : ''}`
        );

      setTicketHtml(null);
      const newLinks = [...reqLinks];
      if (!newLinks[index + 1]) newLinks.push({ name: '', link: '', reason: '', price: '' });
      newLinks[index + 1][key] = e.target.value;

      setReqLinks(newLinks);
    }
  };

  const handleChangeMessage = (value) => {
    value = value.replace(/<h1>/g, '<b>').replace(/<\/h1>/g, '</b>');
    value = value.replace(/<h2>/g, '<b>').replace(/<\/h2>/g, '</b>');
    value = value.replace(/<h3>/g, '<b>').replace(/<\/h3>/g, '</b>');
    value = value.replace(/<h4>/g, '<b>').replace(/<\/h4>/g, '</b>');
    value = value.replace(/<h5>/g, '<b>').replace(/<\/h5>/g, '</b>');
    value = value.replace(/<h6>/g, '<b>').replace(/<\/h6>/g, '</b>');

    if (value.includes('<img src=')) {
      const imgSrcRegex = /<img src="([^"]*)"/g;
      const imgSrcMatch = value.matchAll(imgSrcRegex);
      const imgSrcArray = Array.from(imgSrcMatch, (match) => ({ src: match[1] }));
      imgSrcArray.forEach(async (img) => {
        if (!img.src.includes('//res.cloudinary.com/agoraserv')) {
          const imgUrl = await postAddTicketImage(img.src);
          value = value.replace(img.src, imgUrl.data);
          setTicketHtml(value);
        }
      });
    }
    setTicketHtml(value);
  };
  const renderItemHtml = () => {
    const filteredLinks = reqLinks.filter((link) => link.name || link.link || link.reason || link.price);

    const html = `<p>Hi, I would like to request these items to be added to my catalog </p><br><ul>
      ${filteredLinks
        ?.map(
          (row) =>
            `<li>${
              row.link && row.name
                ? `<a href=${row.link}>${row.name}</a>`
                : row.link
                ? `<a href=${row.link}>${row.link}</a>`
                : row.name || ''
            } - ${row.price ? `Price: $${row.price}` : ''} ${row.reason ? `Reason: ${row.reason}` : ''}</li>`
        )
        .join('')}</ul><br><p>Thank you,</p><p>${user.firstName} ${user.lastName}</p>`;

    setTicketHtml(html);
  };

  const renderSomethingElseHtml = () => {
    const html = `<p></p><br><ul></ul><br><p>Thank you,</p><p>${user.firstName} ${user.lastName}</p>`;
    setTicketHtml(html);
    setSubject('General Assistance');
  };
  const [subjectMaxChar, setSubjectMaxChar] = useState(false);

  const handleSubjectChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length >= 65) setSubjectMaxChar(true);
    else setSubjectMaxChar(false);
    setSubject(newValue);
  };

  const subjectInput = (
    <>
      <DialogContentText
        sx={{
          mt: 3,
        }}
      >
        Subject:
      </DialogContentText>
      <Box sx={{ width: '100%', mb: 3 }}>
        <TextField
          size="small"
          placeholder="Subject"
          id="name"
          onChange={handleSubjectChange}
          error={subjectMaxChar}
          helperText={subjectMaxChar ? 'Subject cannot exceed 65 characters' : ''}
          value={subject}
          sx={{
            width: '100%',
            mt: 1,
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiFormLabel-root ': { display: 'none' },
          }}
        />
      </Box>
    </>
  );
  const submitItemRequestRequest = async () => {
    const response = await postAddTicket({
      ExtraInfo: subject,
      message: ticketHtml,
      FacilityId: facility?.id || null,
      files: attachments,
      Users: users?.map((t) => t.id),
      notify: true,
    });
    if (response.data?.id) {
      handleClose();
      navigate(`/dashboard/tickets/${response.data?.id}`);
    }
  };

  const submitSomethingElseRequest = async () => {
    const response = await postAddTicket({
      ExtraInfo: subject,
      message: ticketHtml,
      FacilityId: facility?.id || null,
      files: attachments,
      Users: users?.map((user) => user.id),
      notify: true,
    });
    if (response.data?.id) {
      handleClose();
      navigate(`/dashboard/tickets/${response.data?.id}`);
    }
  };

  const renderAddItemToOrderHtml = () => {
    const filteredLinks = itemLinks.filter((link) => link.name || link.id || link.quantity);

    const html = `<p>Hi, Can you please add these items to <a href=/dashboard/orders/${orderNumber?.id}>${
      orderNumber?.label || ''
    }</a></p><br><ul>
      ${filteredLinks
        ?.map(
          (row) =>
            `<li>${
              row.id && row.name
                ? `<a href=/dashboard/admin/catalog/${row.id}>${row.name}</a>`
                : row.id
                ? `<a href=/dashboard/admin/catalog/${row.id}>PS# ${row.id}</a>`
                : row.name || ''
            } - ${row.quantity ? `Qty: ${row.quantity}` : ''} </li>`
        )
        .join('')}</ul><br><p>Thank you,</p><p>${user.firstName} ${user.lastName}</p>`;

    setTicketHtml(html);
  };

  const submitAddItemRequest = async () => {
    const response = await postAddTicket({
      ExtraInfo: subject,
      message: ticketHtml,
      FacilityId: facility?.id || null,
      ticketOrderId: orderNumber.id,
      files: attachments,
      Users: users?.map((t) => t.id),
      notify: true,
    });
    if (response.data?.id) {
      handleClose();
      navigate(`/dashboard/tickets/${response.data?.id}`);
    }
  };

  const handleAddItemToOrderChange = (e, index, key) => {
    if (index === 0 && key === 'name')
      setSubject(
        `Add item to ${orderNumber?.id} - ${
          e.target.value.length < 10 ? e.target.value.split(' ').slice(0, 2).join(' ') : ''
        }`
      );
    setTicketHtml(null);
    const newLinks = [...itemLinks];
    if (!newLinks[index + 1]) newLinks.push({ name: '', id: '', quantity: '' });
    newLinks[index + 1][key] = e.target.value;

    setItemLinks(newLinks);
  };

  const renderHtml = () => {
    const filteredData = data.filter((row) => selected.includes(row?.id));
    const html = `<p>Hi, I need assistance with these items on <a href="/dashboard/orders/${orderNumber?.id}">${
      orderNumber?.label || ''
    }</a></p><br><ul>

  ${filteredData
    ?.map((row) => {
      const productInfo = row.productId !== 0 ? `PS# ${row.productId}` : '';
      return `<li><a href="/dashboard/admin/catalog/${row.productId}" rel="noopener noreferrer" target="_blank">${productInfo}</a>-${row.description} | Qty: ${row.quantity}</li>`;
    })
    .join('')}</ul><br><p>Thank you,</p><p>${user.firstName} ${user.lastName}</p>`;

    setTicketHtml(html);
  };

  const submitAssistanceRequest = async () => {
    const response = await postAddTicket({
      ExtraInfo: subject,
      message: ticketHtml,
      FacilityId: facility?.id || null,
      ticketOrderId: orderNumber.id,
      files: attachments,
      Users: users?.map((t) => t.id),
      notify: true,
    });
    if (response.data?.id) {
      handleClose();
      navigate(`/dashboard/tickets/${response.data?.id}`);
    }
  };

  const handleFileInputChange = (event) => {
    const maxAllowedFiles = 5;
    if (
      event.target.files.length > maxAllowedFiles ||
      event.target.files.length + attachments?.length > maxAllowedFiles
    ) {
      alert(`Only ${maxAllowedFiles} files can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    const { files } = event.target;
    const fileList = [];
    for (let i = 0; i < files.length; i++) {
      if (attachments?.some((x) => x.name === files[i].name)) {
        alert(`File ${files[i].name} already exists.`);
      } else fileList.push(files[i]);
    }
    setAttachments((prevAttachments) => {
      if (prevAttachments === undefined) {
        return fileList;
      }
      return [...prevAttachments, ...fileList];
    });
  };

  const removeFile = (file) => () => {
    setAttachments((prevAttachments) => prevAttachments.filter((x) => x !== file));
  };

  return (
    <div>
      <Button
        color="secondary"
        size="small"
        sx={{
          minWidth: 120,
        }}
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        New Ticket
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <ScopeGuard superAdminOnly>
          <ToggleButtonGroup value={ticketVersion} exclusive onChange={(event, newValue) => { if (newValue) setTicketVersion(newValue) }}>
            <ToggleButton value={"Old"}>Old Ticket Version</ToggleButton>
            <ToggleButton value={"New"}>New Ticket Version</ToggleButton>
          </ToggleButtonGroup>
        </ScopeGuard>
        {ticketVersion === "New" ? <NewTicketTest
          setOpen={setOpen}
          facilities={facilities ?? []}
          users={users ?? []}
          ticketTypes={ticketTypes ?? []}
          ticketCategories={ticketCategories ?? []}
        /> :
          <>
            <DialogTitle sx={{ textAlign: 'center' }} >
              New Ticket          
              <Box sx={{ m: 2 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box sx={{ width: '31%' }}>
                    <LinearProgress variant="determinate" value={100} sx={{ height: 8, borderRadius: 3 }} />
                  </Box>
                  <Box sx={{ width: '31%' }}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (ticketType?.label === 'Order Assistance' && orderNumber?.id) ||
                          (ticketType?.label === 'Add Item to Order' && orderNumber?.id) ||
                          ticketType?.label === 'Something Else' ||
                          ticketType?.label === 'New Item Request'
                          ? 100
                          : 0
                      }
                      sx={{ height: 8, borderRadius: 3 }}
                    />
                  </Box>
                  <Box sx={{ width: '31%' }}>
                    <LinearProgress
                      variant="determinate"
                      value={ticketHtml ? 100 : 0}
                      sx={{ height: 8, borderRadius: 3 }}
                    />
                  </Box>
                </Grid>
              </Box>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Iconify icon="ic:sharp-close" width={28} height={28} />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              {facilities?.length > 0 && (
                <Autocomplete
                  fullWidth
                  size="small"
                  options={facilities}
                  value={facility}
                  getOptionLabel={(option) => option.facilityName}
                  renderInput={(params) => <TextField {...params} label="Select a facility" />}
                  onChange={(event, newValue) => {
                    setFacility(newValue);
                  }}
                  sx={{
                    mt: 1,
                    mb: 3,
                  }}
                />
              )}

              <Autocomplete
                fullWidth
                size="small"
                options={options}
                value={ticketType}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => <TextField {...params} label="Choose a ticket type" />}
                onChange={(event, newValue) => {
                  setTicketType(newValue);
                  setSelected([]);
                  setSubject('');
                  setReqLinks([{ name: '', link: '', reason: '', price: '' }]);
                  setItemLinks([{ name: '', id: '', quantity: '' }]);
                  setTicketHtml('');
                  setOrderNumberFilter('');
                  setErrors({});
                  setSubjectMaxChar(false);
                }}
                sx={{
                  mt: 1,
                }}
              />

              {ticketType?.label === 'New Item Request' ? (
                <>
                  {' '}
                  {!ticketHtml && ticketType && (
                    <>
                      <DialogContentText
                        sx={{
                          mt: 3,
                        }}
                      >
                        Enter links and optional info for items you want to add to your catalog
                      </DialogContentText>
                      <Box sx={{ width: '100%', mb: 3 }}>
                        {reqLinks?.map((link, index) => (
                          <>
                            <TextField
                              size="small"
                              placeholder="Item Name"
                              id="name"
                              onChange={(e) => {
                                handleChangeItemsRequest(e, index, 'name');
                              }}
                              sx={{
                                width: '80%',
                                mt: 1,
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />
                            <TextField
                              size="small"
                              placeholder="Price"
                              type="number"
                              onChange={(e) => {
                                handleChangeItemsRequest(e, index, 'price');
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              sx={{
                                width: '20%',
                                mt: 1,
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />
                            <TextField
                              size="small"
                              placeholder="Link"
                              onChange={(e) => {
                                handleChangeItemsRequest(e, index, 'link');
                              }}
                              error={errors[index] || false}
                              helperText={errors[index] ? 'Please enter a valid link' : ''}
                              sx={{
                                width: '100%',
                                mt: 1,
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />
                            <TextField
                              size="small"
                              placeholder="Reason"
                              onChange={(e) => {
                                handleChangeItemsRequest(e, index, 'reason');
                              }}
                              sx={{
                                width: '100%',
                                mt: 1,
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />

                            <Divider
                              flexItem
                              sx={{
                                my: 1.9,
                                height: '2px',
                                opacity: 0.39,
                                backgroundColor: '  #707070',
                              }}
                            />
                          </>
                        ))}
                      </Box>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Button
                          variant="contained"
                          onClick={renderItemHtml}
                          sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
                          disabled={Object.values(errors).some((error) => error)}
                        >
                          Next
                        </Button>
                      </Grid>
                    </>
                  )}
                  {ticketHtml && (
                    <>
                      {subjectInput}

                      <Editor
                        simple
                        attachFile
                        removeHyperLink
                        id="new-item-ticket"
                        value={ticketHtml}
                        onChange={handleChangeMessage}
                        placeholder={`${ticketType.label} ticket...`}
                        sx={{ flexGrow: 1, borderColor: 'transparent', width: '100%' }}
                        handleFileInputChange={handleFileInputChange}
                      />
                      <Box sx={{ width: '100%' }}>
                        {' '}
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {attachments?.map((file) => (
                            <FileThumbnail
                              key={file.name}
                              file={file}
                              tooltip
                              sx={{ width: 64, height: 64 }}
                              onRemove={removeFile(file)}
                            />
                          ))}
                        </Stack>
                      </Box>
                    </>
                  )}
                  {ticketHtml && (
                    <>
                      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
                        <>
                          <Typography>Recipients:</Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                            <CustomAvatarGroup size="medium">
                              {users?.map((user, index) => (
                                <CustomAvatar name={user?.value || user?.id} email={user?.id} key={index} />
                              ))}
                              <NewUserAddTicket newUsers={users} setNewUsers={setUsers} />
                            </CustomAvatarGroup>
                          </Box>
                        </>
                      </Grid>
                      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                        <AwaitButton
                          variant="contained"
                          onClick={submitItemRequestRequest}
                          sx={{ display: 'flex', justifyContent: 'center', width: '50%' }}
                          disabled={!ticketType || subjectMaxChar}
                        >
                          Submit ticket
                        </AwaitButton>
                      </Grid>
                    </>
                  )}
                </>
              ) : ticketType?.label === 'Something Else' ? (
                <>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    {!ticketHtml && (
                      <Button
                        variant="contained"
                        onClick={renderSomethingElseHtml}
                        sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
                      >
                        Next
                      </Button>
                    )}
                  </Grid>

                  {ticketHtml && (
                    <>
                      {subjectInput}

                      <Editor
                        simple
                        attachFile
                        removeHyperLink
                        id="something-else-ticket"
                        value={ticketHtml}
                        onChange={handleChangeMessage}
                        placeholder={`${ticketType.label} ticket...`}
                        sx={{ flexGrow: 1, borderColor: 'transparent', width: '100%' }}
                        handleFileInputChange={handleFileInputChange}
                      />
                      <Box sx={{ width: '100%' }}>
                        {' '}
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {attachments?.map((file) => (
                            <FileThumbnail
                              key={file.name}
                              file={file}
                              tooltip
                              sx={{ width: 64, height: 64 }}
                              onRemove={removeFile(file)}
                            />
                          ))}
                        </Stack>
                      </Box>
                    </>
                  )}

                  {ticketHtml && (
                    <>
                      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
                        <>
                          <Typography>Recipients:</Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                            <CustomAvatarGroup size="medium">
                              {users?.map((user, index) => (
                                <CustomAvatar name={user?.value || user?.id} email={user?.id} key={index} />
                              ))}
                              <NewUserAddTicket newUsers={users} setNewUsers={setUsers} />
                            </CustomAvatarGroup>
                          </Box>
                        </>
                      </Grid>
                      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                        <AwaitButton
                          variant="contained"
                          onClick={submitSomethingElseRequest}
                          disabled={!ticketType || subjectMaxChar}
                          sx={{ display: 'flex', justifyContent: 'center', width: '50%' }}
                        >
                          Submit ticket
                        </AwaitButton>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  {((!ticketType && orderNumber) ||
                    ticketType?.label === 'Order Assistance' ||
                    ticketType?.label === 'Add Item to Order') && (
                      <Autocomplete
                        size="small"
                        fullWidth
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        options={filteredOrdersList?.map((order) => ({
                          id: order?.id,
                          label: `Order# ${order?.orderNumber}`,
                          facility: order?.facility || 'N/A',
                          department: order?.department || 'N/A',
                          submittedBy: order?.submittedBy || 'N/A',
                          onBehalfOf: order?.onBehalfOf || 'N/A',
                          approvedBy: order?.approvedBy || 'N/A',
                        }))}
                        value={orderNumber}
                        renderInput={(params) => <TextField {...params} label="Start typing your order #" />}
                        onChange={(event, newValue) => {
                          const order = filteredOrdersList?.find((o) => o.id === newValue?.id);
                          setUsers([{ value: '', id: order?.submittedBy }]);
                          setTicketHtml(null);
                          setOrderNumber(newValue);
                          setSubject(`${ticketType?.label} - ${newValue?.id}`);
                          if (!newValue) setData([]);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setOrderNumberFilter(newInputValue);
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <OptionWrapper>
                              <strong>{option.label}</strong>
                              <span>
                                <span style={{ fontWeight: 'bold' }}>Facility:</span>
                                {` ${option.facility}`}
                              </span>
                              <span>
                                <span style={{ fontWeight: 'bold' }}>Department:</span>
                                {` ${option.department}`}
                              </span>
                              <span>
                                <span style={{ fontWeight: 'bold' }}>Submitted By:</span>
                                {` ${option.submittedBy}`}
                              </span>
                              <span>
                                <span style={{ fontWeight: 'bold' }}>On Behalf Of:</span>
                                {` ${option.onBehalfOf}`}
                              </span>
                              <span>
                                <span style={{ fontWeight: 'bold' }}>Approved By:</span>
                                {` ${option.approvedBy}`}
                              </span>
                            </OptionWrapper>
                          </li>
                        )}
                        sx={{
                          mt: 3,
                        }}
                      />
                    )}
                  {ticketType?.label === 'Order Assistance' && orderNumber?.id ? (
                    <>
                      <DialogContentText
                        sx={{
                          mt: 3,
                        }}
                      >
                        Select items from order
                      </DialogContentText>
                      <Box sx={{ height: data.length * 44 + 88 > 300 ? 300 : data.length * 44 + 88, width: '100%', mb: 3 }}>
                        <DataGridPro
                          getRowHeight={() => 'auto'}
                          rows={data}
                          columns={TICKET_ORDERS}
                          checkboxSelection
                          getRowId={(row) => row?.id}
                          disableSelectionOnClick
                          rowHeight={44}
                          hideFooter
                          onRowSelectionModelChange={(newSelection) => {
                            setTicketHtml('');
                            setSelected(newSelection);
                          }}
                          rowSelectionModel={selected}
                        />
                      </Box>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        {!ticketHtml && (
                          <Button
                            variant="contained"
                            onClick={renderHtml}
                            sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
                          >
                            Next
                          </Button>
                        )}
                      </Grid>
                      {ticketHtml && (
                        <>
                          {subjectInput}

                          <Editor
                            simple
                            attachFile
                            removeHyperLink
                            id="order-assistance-ticket"
                            value={ticketHtml}
                            onChange={handleChangeMessage}
                            placeholder={`${ticketType.label} ticket...`}
                            sx={{ flexGrow: 1, borderColor: 'transparent', width: '100%' }}
                            handleFileInputChange={handleFileInputChange}
                          />
                          <Box sx={{ width: '100%' }}>
                            {' '}
                            <Stack direction="row" alignItems="center" spacing={1}>
                              {attachments?.map((file) => (
                                <FileThumbnail
                                  key={file.name}
                                  file={file}
                                  tooltip
                                  sx={{ width: 64, height: 64 }}
                                  onRemove={removeFile(file)}
                                />
                              ))}
                            </Stack>
                          </Box>
                        </>
                      )}
                      {ticketHtml && (
                        <>
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
                            <>
                              <Typography>Recipients:</Typography>
                              <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                                <CustomAvatarGroup size="medium">
                                  {users?.map((user, index) => (
                                    <CustomAvatar name={user?.value || user?.id} email={user?.id} key={index} />
                                  ))}
                                  <NewUserAddTicket newUsers={users} setNewUsers={setUsers} />
                                </CustomAvatarGroup>
                              </Box>
                            </>
                          </Grid>
                          <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                            <AwaitButton
                              variant="contained"
                              onClick={submitAssistanceRequest}
                              sx={{ display: 'flex', justifyContent: 'center', width: '50%' }}
                              disabled={!ticketType || subjectMaxChar}
                            >
                              Submit ticket
                            </AwaitButton>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : ticketType?.label === 'Add Item to Order' && orderNumber?.id ? (
                    <>
                      <DialogContentText
                        sx={{
                          mt: 3,
                        }}
                      >
                        Enter links and optional info for items you want to add
                      </DialogContentText>
                      <Box sx={{ width: '100%', mb: 3 }}>
                        {itemLinks?.map((link, index) => (
                          <>
                            <TextField
                              size="small"
                              placeholder="ID"
                              onChange={(e) => {
                                handleAddItemToOrderChange(e, index, 'id');
                              }}
                              type="number"
                              sx={{
                                width: '15%',
                                mt: 1,
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />
                            <TextField
                              size="small"
                              placeholder="Name"
                              id="name"
                              onChange={(e) => {
                                handleAddItemToOrderChange(e, index, 'name');
                              }}
                              sx={{
                                width: '70%',
                                mt: 1,
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />
                            <TextField
                              size="small"
                              placeholder="Qty"
                              onChange={(e) => {
                                handleAddItemToOrderChange(e, index, 'quantity');
                              }}
                              sx={{
                                width: '15%',
                                mt: 1,
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />

                            <Divider
                              flexItem
                              sx={{
                                my: 1.9,
                                height: '2px',
                                opacity: 0.39,
                                backgroundColor: '  #707070',
                              }}
                            />
                          </>
                        ))}
                      </Box>
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        {!ticketHtml && (
                          <Button
                            variant="contained"
                            onClick={renderAddItemToOrderHtml}
                            sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
                          >
                            Next
                          </Button>
                        )}
                      </Grid>

                      {ticketHtml && (
                        <>
                          {subjectInput}

                          <Editor
                            simple
                            attachFile
                            removeHyperLink
                            id="add-item-ticket"
                            value={ticketHtml}
                            onChange={handleChangeMessage}
                            placeholder={`${ticketType.label} ticket...`}
                            sx={{ flexGrow: 1, borderColor: 'transparent', width: '100%' }}
                            handleFileInputChange={handleFileInputChange}
                          />
                          <Box sx={{ width: '100%' }}>
                            {' '}
                            <Stack direction="row" alignItems="center" spacing={1}>
                              {attachments?.map((file) => (
                                <FileThumbnail
                                  key={file.name}
                                  file={file}
                                  tooltip
                                  sx={{ width: 64, height: 64 }}
                                  onRemove={removeFile(file)}
                                />
                              ))}
                            </Stack>
                          </Box>
                        </>
                      )}
                      {ticketHtml && (
                        <>
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
                            <>
                              <Typography>Recipients:</Typography>
                              <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                                <CustomAvatarGroup size="medium">
                                  {users?.map((user, index) => (
                                    <CustomAvatar name={user?.value || user?.id} email={user?.id} key={index} />
                                  ))}
                                  <NewUserAddTicket newUsers={users} setNewUsers={setUsers} />
                                </CustomAvatarGroup>
                              </Box>
                            </>
                          </Grid>
                          <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                            <AwaitButton
                              variant="contained"
                              onClick={submitAddItemRequest}
                              sx={{ display: 'flex', justifyContent: 'center', width: '50%' }}
                              disabled={!ticketType || subjectMaxChar}
                            >
                              Submit ticket
                            </AwaitButton>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </DialogContent> </>}
      </Dialog>
    </div>
  );
}
const options = [
  { code: 'icon-park-outline:order', label: 'Order Assistance' },
  { code: 'mdi:plus-box-outline', label: 'Add Item to Order' },
  { code: 'tabler:status-change', label: 'New Item Request' },
  { code: 'mdi:bookmark-box-outline', label: 'Something Else' },
];

const OptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  borderBottom: `1px solid #000`,
  borderTop: `1px solid #000`,
  padding: theme.spacing(1),
  width: '100%',
}));
