import { createSlice } from '@reduxjs/toolkit';
import { getEmailRecipientsData, getEmailRecipientTypesData } from '../../api/emailRecipients';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    emailRecipients: [],
    recipientTypes: [],
    sendTypes: [],
    sites: [],
  },
};

const slice = createSlice({
  name: 'emailRecipient',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getEmailRecipientsSuccess(state, action) {
      state.isLoading = false;
      //spread this in to the state so not to overwrite the state
      state.data = { ...state.data, ...action.payload }
    },

    getEmailRecipientTypesSuccess(state, action) {
      state.isLoading = false;
      const { recipientTypes, sendTypes } = action.payload;
      state.data.recipientTypes = recipientTypes;
      state.data.sendTypes = sendTypes;
    },

  },
});

// Reducer
export default slice.reducer;

export function getEmailRecipients() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getEmailRecipientsData();
      dispatch(slice.actions.getEmailRecipientsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEmailRecipientTypes(siteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getEmailRecipientTypesData(siteId);
      dispatch(slice.actions.getEmailRecipientTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


