import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import { FacilitiesView } from '../../../components/dashboard/admin/facility';
// ----------------------------------------------------------------------

export default function Facilities() {
  return (
    <>
      <Helmet>
        <title>Admin Facilities | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <FacilitiesView />
      </Grid>
    </>
  );
}
