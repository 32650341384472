import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { FACILITIES } from '../../../../reusable-components/datagrid/facilityColumns';
import { HOST_API_URL } from '../../../../config-global';
import Iconify from '../../../../reusable-components/iconify';
import NewFacilityModal from './NewFacilityModal';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { ScopeGuard } from '../../../../reusable-components/scopes';

FacilitiesTab.propTypes = {
  setTab: PropTypes.func,
  setSelectedRow: PropTypes.func,
  companies: PropTypes.array,
  data: PropTypes.array,
  fetchData: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default function FacilitiesTab({ setSelectedRow, setTab, companies, data, fetchData, isLoading }) {
  const CustomToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
        <Box>
          <ScopeGuard scopes={['Super-Admin']}>
            <NewFacilityModal companies={companies} data={data} fetchData={fetchData} />
          </ScopeGuard>
          <ScopeGuard scopes={['Admin']}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mr: 1 }}
              size="small"
              onClick={() => {
                window.open(`${HOST_API_URL}Api/Admin/Facility/DownloadFacilities`, '_blank');
              }}
              startIcon={<Iconify icon="file-icons:microsoft-excel" />}
            >
              Download
            </Button>
          </ScopeGuard>
        </Box>
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies, data]
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-facilities"
          boxSX={{ height: 'calc(100vh - 260px)' }}
          data={data}
          enableRowClick
          gridColumns={FACILITIES}
          CustomLeftToolbar={CustomToolbar}
          sort={[{ field: 'facilityName', sort: 'asc' }]}
          onRowClick={(row) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            setSelectedRow(row.row);
            setTab(1);
          }}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
