import PropTypes from 'prop-types';
import { Popover, Box } from '@mui/material';
import { CustomSelectPanel, CustomDatePanel, CustomTextPanel, CustomMoneyPanel, CustomNumberPanel } from './index';
import { useTabs } from '../../../context/TabContext';

CustomFilter.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  setActiveFilter: PropTypes.func,
  activeFilter: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  fieldValues: PropTypes.object,
  columns: PropTypes.array,
  maxValue: PropTypes.object,
  gridId: PropTypes.string,
};

export default function CustomFilter({
  anchorEl,
  setAnchorEl,
  activeFilter,
  setActiveFilter,
  columns,
  filters,
  setFilters,
  maxValue,
  fieldValues,
  gridId,
}) {
  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const { activeTab } = useTabs();
  const sessionStorageKey = `tab-${activeTab.key}-filters-${baseUrl}-${gridId}`;
  const updateSessionStorage = (newFilters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(newFilters));
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => {
        setAnchorEl(null);
        setActiveFilter(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        ml: -8,
        '& .MuiPopover-paper': {
          width: 'auto',
          maxWidth: '320px',
          maxHeight: '300px',
          padding: '8px 0',
          borderRadius: '4px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none !important',
          },
        },
      }}
    >
      {activeFilter && (
        <Box
          sx={{
            px: '4px',
          }}
        >
          {(() => {
            const columnType = columns.find((col) => col.field === activeFilter)?.type;
            switch (columnType) {
              case 'customNumber':
                return (
                  <CustomNumberPanel
                    activeFilter={activeFilter}
                    filters={filters}
                    setFilters={setFilters}
                    gridId={gridId}
                  />
                );
              case 'customMoney':
                return (
                  <Box
                    sx={{
                      width: '260px',
                      px: '4px',
                    }}
                  >
                    <CustomMoneyPanel
                      activeFilter={activeFilter}
                      filters={filters}
                      setFilters={setFilters}
                      maxValue={maxValue?.[activeFilter] || 10000}
                      gridId={gridId}
                    />
                  </Box>
                );
              case 'customText':
                return (
                  <Box
                    sx={{
                      width: '225px',
                      px: '4px',
                    }}
                  >
                    <CustomTextPanel
                      activeFilter={activeFilter}
                      filters={filters}
                      setFilters={setFilters}
                      gridId={gridId}
                    />
                  </Box>
                );
              case 'customDate':
                return (
                  <Box
                    sx={{
                      width: '320px',
                      px: '4px',
                    }}
                  >
                    <CustomDatePanel
                      activeFilter={activeFilter}
                      filters={filters}
                      setFilters={setFilters}
                      gridId={gridId}
                    />
                  </Box>
                );
              default:
                return (
                  <Box
                    sx={{
                      // maxHeight: '250px',
                      minHeight: '300px',
                      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
                    }}
                  >
                    <CustomSelectPanel
                      item={{ field: activeFilter, value: filters[activeFilter]?.value }}
                      applyValue={({ value }) => {
                        const updatedFilters = { ...filters, [activeFilter]: value };
                        setFilters(updatedFilters);
                        updateSessionStorage(updatedFilters);
                      }}
                      values={fieldValues[activeFilter] || []}
                      appliedFilters={filters[activeFilter]?.value || []}
                      onFilterChange={(newValue) => {
                        const newFilters = {
                          ...filters,
                          [activeFilter]: { value: newValue.map((v) => v.value), operator: 'isAnyOf' },
                        };
                        setFilters(newFilters);
                        updateSessionStorage(newFilters);
                      }}
                      gridId={gridId}
                    />
                  </Box>
                );
            }
          })()}
        </Box>
      )}
    </Popover>
  );
}
