import { createSlice } from '@reduxjs/toolkit';
import { getCartData, deleteCartItem, updateCartApi, cartCheckApi, bulkDeleteCartApi } from '../../api/cart';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    carts: [],
    facilities: [],
    user: {
      facility: {},
      personFor: null,
      personsFor: [],
    },
  },
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCartSuccess(state, action) {
      const { personFor, facility, facilities, cartSet } = action.payload;
      const { items } = cartSet;
      const carts = items.reduce((acc, item) => {
        const { agoraCategoryId, agoraCategory, icon } = item;
        const index = acc?.findIndex((cart) => cart.agoraCategoryId === agoraCategoryId);
        if (index === -1) {
          acc.push({
            agoraCategoryId, agoraCategory, icon, items: [{ ...item }], orderable: cartSet.orderableCategories === null ||
              cartSet.orderableCategories?.some(catId => catId === agoraCategoryId)});
        } else {
          acc[index].items.push({ ...item });
        }
        return acc;
      }, []);
      state.data = { ...state.data, ...cartSet, carts, personFor, facility, facilities, unsavedChanges: false };
      state.isLoading = false;
    },

    updateCartItemsSuccess(state, action) {
      const { orderableCategories, items } = action.payload;
      const carts = items.reduce((acc, item) => {
        const { agoraCategoryId, agoraCategory, icon } = item;
        const index = acc?.findIndex((cart) => cart.agoraCategoryId === agoraCategoryId);
        if (index === -1) {
          acc.push({
            agoraCategoryId, agoraCategory, icon, items: [{ ...item }], orderable: orderableCategories === null ||
              orderableCategories?.some(catId => catId === agoraCategoryId)
          });
        } else {
          acc[index].items.push({ ...item });
        }
        return acc;
      }, []);
      state.data = { ...state.data, carts, unsavedChanges: false };
      state.isLoading = false;
    },

    updateReduxCart(state, action) {
      const { id, quantity, bulkIds } = action.payload;
      const { carts } = state.data;

      if (bulkIds && Array.isArray(bulkIds)) {
        bulkIds.forEach((bulkId) => {
          carts.forEach((cart, index) => {
            const itemIndex = cart.items?.findIndex((item) => item.id === bulkId);

            if (itemIndex !== -1) {
              // Remove the item from the cart
              carts[index].items?.splice(itemIndex, 1);
            }
          });
        });
      } else {
        const index = carts?.findIndex((cart) => cart.items.some((item) => item.id === id));

        if (index !== -1) {
          const itemIndex = carts[index].items?.findIndex((item) => item.id === id);

          if (quantity !== undefined) {
            carts[index].items[itemIndex].quantity = quantity;

            if (quantity === 0) {
              carts[index].items[itemIndex].quantity = 0;
            }
          }
        }
      }

      state.data = { ...state.data, carts };
      state.isLoading = false;
    },

    updateReduxCartItem(state, action) {
      const { id, field, value } = action.payload;
      const { carts } = state.data;

      const index = carts?.findIndex((cart) => cart.items.some((item) => item.id === id));

      if (index !== -1) {
        const itemIndex = carts[index].items?.findIndex((item) => item.id === id);

        carts[index].items[itemIndex][field] = value;
        state.data.unsavedChanges = true;
      }

      state.data = { ...state.data, carts };
      state.isLoading = false;
    },

    removeItemFromReduxCart(state, action) {
      const { itemId } = action.payload;
      const { carts } = state.data;
      const index = carts?.findIndex((cart) => cart.items.some((item) => item.itemId === itemId));

      if (index !== -1) {
        const itemIndex = carts[index].items?.findIndex((item) => item.itemId === itemId);

        carts[index].items?.splice(itemIndex, 1);
      }

      state.data = { ...state.data, carts };
      state.isLoading = false;
    },

    updateCartErrors(state, action) {
      const { items } = action.payload;
      const { carts } = state.data;
      let noError = true;

      items.forEach((item) => {
        if (item.errors && item.errors.length) {
          noError = false;
          carts.forEach((cart) => {
            const cartItem = cart.items.find((cartItem) => cartItem.sku === item.vendorItemId);
            if (cartItem) {
              // If cart item is found, update its error information.
              cartItem.errors = item.errors;
            }
          });
        }
      });

      state.data.carts = carts; // Update the carts in the state.
      state.data.noError = noError;
      state.isLoading = false; // Set loading status to false.
    },

    resetShoppingCart(state) {
      state.data = initialState.data;
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getCart(facilityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCartData(facilityId);
      dispatch(slice.actions.getCartSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCartInRedux({ id, quantity, bulkIds }) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.updateReduxCart({ id, quantity, bulkIds }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetShoppingCart() {
  return (dispatch) => {
    dispatch(slice.actions.resetShoppingCart());
  };
}

export function removeItemFromCart({ facilityId, itemId, punchoutItemId }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await deleteCartItem(facilityId, itemId, punchoutItemId);
      if (response.status === 200) {
        dispatch(slice.actions.removeItemFromReduxCart({ itemId }));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bulkUpdateCart(facilityId) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const carts = getState().cart.data;
      const response = await updateCartApi({ carts: carts.carts, facilityId });
      dispatch(slice.actions.updateCartItemsSuccess({ items: response.data, orderableCategories: carts.orderableCategories }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function bulkDeleteCart(facilityId, selectedToDelete) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const carts = getState().cart.data;

      const response = await bulkDeleteCartApi({ carts: carts.carts, facilityId, selectedToDelete });
      dispatch(slice.actions.updateCartItemsSuccess({ items: response.data, orderableCategories: carts.orderableCategories }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function cartCheck(facilityId) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const carts = getState().cart.data;
      const response = await cartCheckApi({ carts: carts.carts, facilityId });
      dispatch(slice.actions.updateCartErrors(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return false;
  };
}

// updateCartItemInRedux
export function updateCartItemInRedux(id, field, value) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.updateReduxCartItem({ id, field, value }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
