import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton, Tooltip, Grid } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { useSelector } from '../../../redux/store';
import { useTabs } from '../../../context/TabContext';

RentalSearch.propTypes = {
  setQuickFilter: PropTypes.func,
  setActiveFilter: PropTypes.func,
  setFilters: PropTypes.func,
  sx: PropTypes.object,
  applyQuickFilter: PropTypes.bool,
  isModal: PropTypes.bool,
};

export default function RentalSearch({ setFilters, setQuickFilter, setActiveFilter, sx, applyQuickFilter, isModal }) {
  const { user } = useSelector((state) => state.user);

  const baseUrl = window.location.href;
  const savedSearch = JSON.parse(sessionStorage.getItem(baseUrl));
  const sessionStorageKey = `${baseUrl}-filters`;
  const isFilter = sessionStorage.getItem(sessionStorageKey);

  const isRecent = savedSearch && new Date().getTime() - savedSearch?.timestamp < 300000;
  //const hasFacilityAccess = useScopeCheck(['Facility-Manager']);

  const [searchValue, setSearchValue] = useState(isRecent && !isModal ? savedSearch.search : '');
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  const applyQuickFilterFunc = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      manager: { value: user.firstName, operator: 'contains' },
    }));
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    //     setQuickFilter(debouncedValue);
    //   }, [debouncedValue]);
  });

  const handleSearch = (event) => {
    setSearchValue(event?.target.value);
    const baseUrl = window.location.href;
    const searchValueWithTimestamp = {
      search: event?.target.value,
      timestamp: new Date().getTime(),
    };
    sessionStorage.setItem(baseUrl, JSON.stringify(searchValueWithTimestamp));
  };

  const handleClear = () => {
    setSearchValue('');
    setQuickFilter('');
    setActiveFilter(null);
    setFilters({});
    sessionStorage?.removeItem(baseUrl);
    sessionStorage?.removeItem(`${baseUrl}-filters`);
  };
  const handleClearText = () => {
    setSearchValue('');
    setQuickFilter('');
    sessionStorage?.removeItem(baseUrl);
  };

  return (
    <>
      <TextField
        value={searchValue}
        onChange={handleSearch}
        size="small"
        placeholder="Search Rentals..."
        sx={{
          ...sx,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),

          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClearText}>
                <Iconify
                  icon={'ri:close-fill'}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
          style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
        }}
      />
    </>
  );
}
