import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Iconify from '../iconify';

CloseIconButton.propTypes = {
  onClick: PropTypes.func,
};

export default function CloseIconButton({ onClick }) {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <Iconify icon="ic:sharp-close" width={28} height={28} />
    </IconButton>
  );
}
