import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { postTicketCategoryForm } from '../../../../api/tickets';

TicketCategoryFormModal.propTypes = {
  row: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

export default function TicketCategoryFormModal({ row, open, onClose, onSave })
{
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState("");

  useEffect(() => { setName(row?.name ?? ""); }, [open, row]);

  const handleSave = async () => {
    const response = await postTicketCategoryForm({ name, id: row?.id ?? 0 });
    if (response.status === 200 && response.data) {
      enqueueSnackbar(`Category ${row ? 'edited' : 'added'} successfully`, { variant: 'success' });
      onSave(response.data);
      return;
    }
    enqueueSnackbar('Failed to add category', { variant: 'error' });    
  };

  return <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>
      {row ? 'Edit' : 'Add'} Ticket Category
      <CloseIconButton onClick={onClose} />
    </DialogTitle>
    <DialogContent dividers>
      <Typography variant="subtitle1">Name *</Typography>
      <TextField
        size="small"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="error" variant="outlined">
        Cancel
      </Button>
      <AwaitButton onClick={handleSave} color="primary" variant="contained" disabled={!name}>
        {row ? 'Save' : 'Add Category'}
      </AwaitButton>
    </DialogActions>
  </Dialog>;
}
