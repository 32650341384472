// remember to import this file in index.js

import { Opacity } from '@mui/icons-material';

export default function TextField() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            // backgroundColor: '#e9ecef',
            cursor: 'not-allowed',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#637381',
            },
            //hover border color
            // '&:hover fieldset': {
            //   borderColor: '#ddd',
            // },
            //focused border color
            // '&.Mui-focused fieldset': {
            //   borderColor: '#ddd',
            // },
          },
        },
      },
    },
  };
}
