import PropTypes from 'prop-types';
import { Typography, Box, TextField, Autocomplete } from '@mui/material';

FormDropdown.propTypes = {
  value: PropTypes.object,
  title: PropTypes.string,
  width: PropTypes.string,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  options: PropTypes.array,
  isOptionEqualToValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  minWidth: PropTypes.string,
  multiple: PropTypes.bool,
  sx: PropTypes.object,
  disableClearable: PropTypes.bool,
};

export default function FormDropdown({
  value,
  title,
  width,
  disabled,
  fullWidth,
  size = 'small',
  onChange,
  options,
  isOptionEqualToValue,
  getOptionLabel,
  label,
  minWidth,
  multiple = false,
  sx,
  disableClearable = false,
}) {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
          {title}
        </Typography>

        <Autocomplete
          multiple={multiple}
          size={size}
          disabled={disabled}
          disableClearable={disableClearable}
          fullWidth={fullWidth}
          value={value}
          onChange={onChange}
          options={options}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => <TextField {...params} label={label} />}
          sx={{
            ...sx,
            width,
            minWidth,
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiFormLabel-root ': { display: 'none' },
          }}
        />
      </Box>
    </>
  );
}
