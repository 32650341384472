import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import { getVendors } from '../../../redux/slices/vendors';
import { VendorsView } from '../../../components/dashboard/admin/vendors';

// ----------------------------------------------------------------------

export default function Vendors() {
  const {
    data: { vendors },
  } = useSelector((state) => state.vendors);
  const dispatch = useDispatch();

  useEffect(() => {
    if (vendors?.length === 0) dispatch(getVendors());
  }, [dispatch, vendors?.length]);

  return (
    <>
      <Helmet>
        <title>Admin Vendors | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <VendorsView />
      </Grid>
    </>
  );
}
