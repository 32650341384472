import { useState } from 'react';
import { Autocomplete, Box, Chip, CircularProgress, Popover, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomAvatar } from '../../../../../reusable-components/custom-avatar';

AddUsersToTicket.propTypes = {
  newUsers: PropTypes.array,
  setNewUsers: PropTypes.func,
  possibleUsers: PropTypes.array,
};

export default function AddUsersToTicket({ newUsers, setNewUsers, possibleUsers })
{
  const [open, setOpen] = useState(null);

  const updateUsers = (newValue) => {
    const updatedUsers = newValue.map((item) => {
      if (typeof item === 'string') return { id: item, value: item };
      return item;
    });
    setNewUsers(updatedUsers);      
  };

  return (
    <>
      <Tooltip title="Add user">
        <CustomAvatar
          name={'+'}
          showTooltip={false}
          onClick={(event) => setOpen(event.currentTarget)}
          sx={{ cursor: 'pointer', backgroundColor: 'primary.main' }}
        />
      </Tooltip>

      <Popover
        open={open !== null}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { sx: { p: 0, mt: 1.5, ml: 0.75 } } }}
      >
        <Box sx={{ p: 2, maxWidth: 600, minWidth: 600, overflow: 'hidden' }}>
          {possibleUsers ? <Autocomplete
            multiple
            id="tags-outlined"
            options={possibleUsers || []}
            getOptionLabel={(option) => `${option.value} - ${option.id}`}
            value={newUsers}
            onChange={(event, newValue) => updateUsers(newValue)}
            freeSolo
            filterSelectedOptions
            renderInput={(params) => <TextField {...params}
              label="CC additional users"
              placeholder="Add user"
              fullWidth={false}
              sx={{ maxWidth: 570, minWidth: 570 }} />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Box sx={{ mb: 1, display: 'inline-block' }} key={index}>
                  <Chip
                    label={option.value ?? option.id}
                    {...getTagProps({ index })}
                    avatar={<CustomAvatar name={option.value ?? option.id} />}
                    sx={{ mr: 1, mb: 1 }}
                  />
                </Box>
              ))
            }
          /> : <CircularProgress />}
        </Box>
      </Popover>
    </>
  );
}
