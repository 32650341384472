import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
// components
import { BudgetsView } from '../../../components/dashboard/admin/budgets';

// ----------------------------------------------------------------------

export default function Budgets() {
  return (
    <>
      <Helmet>
        <title>Admin Budgets | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <BudgetsView />
      </Grid>
    </>
  );
}
