import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  getVendorContactPositions,
  getVendorContacts,
  getVendorDetailData,
  getVendorFilesData,
  postDeleteVendor,
  postDeleteVendorFile,
  postEditVendorForm,
} from '../../../../api/vendors';
import { HOST_API_URL } from '../../../../config-global';
import { getVendors } from '../../../../redux/slices/vendors';
import { useDispatch } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { FILES } from '../../../../reusable-components/datagrid/vendorColumns';
import FormTextWithValue from '../../../../reusable-components/form/FormTextWithValue';
import Iconify from '../../../../reusable-components/iconify';
import Lightbox from '../../../../reusable-components/lightbox';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import NewFileModal from './NewFileModal';
import VendorContact from './VendorContact';

VendorTab.propTypes = {
  selectedRow: PropTypes.object,
  setTab: PropTypes.func,
  setSelectedRow: PropTypes.func,
};

export default function VendorTab({ selectedRow, setTab, setSelectedRow }) {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [files, setFiles] = useState([]);
  const [edit, setEdit] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [positions, setPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState({
    name: '',
    emailTo: '',
    emailToCC: '',
    address: '',
    zip: '',
    city: '',
    state: '',
    phone: '',
  });
  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];

    if (file && file?.type.startsWith('image/')) {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64Image = reader.result; // Use the entire base64 data URL

          setValue({ ...value, logo: base64Image });
        };
        reader.onerror = () => {
          enqueueSnackbar('Error uploading image', { variant: 'error' });
        };
      } catch (error) {
        enqueueSnackbar('Invalid file type or error in uploading', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Invalid file type', { variant: 'error' });
    }
  };

  const onDrop = (acceptedFiles) => {
    if (edit) handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const fetchData = async () => {
    try {
      const response = await getVendorDetailData(selectedRow.id);
      const { data } = response;
      setData(data);
      setValue({
        name: data.name,
        emailTo: data.emailTo,
        emailToCC: data.emailToCC,
        address: data.address,
        zip: data.zip,
        city: data.city,
        state: data.state,
        phone: data.phone,
        logo: data.logo,
      });
    } catch (error) {
      enqueueSnackbar('Error fetching vendor data', { variant: 'error' });
    }
  };
  const fetchFiles = async () => {
    try {
      setIsLoading(true);
      const response = await getVendorFilesData(selectedRow.id);
      const { data } = response;
      setFiles(data);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Error fetching vendor files', { variant: 'error' });
      setIsLoading(false);
    }
  };
  const fetchPositions = async () => {
    const response = await getVendorContactPositions();
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      setPositions(response.data);
    }
  };
  useEffect(() => {
    fetchData();
    fetchFiles();
    fetchPositions();
    getContacts();
  }, [selectedRow]);

  const deleteVendorFile = async (id) => {
    const response = await postDeleteVendorFile(id);
    if (response) {
      fetchFiles();
      dispatch(getVendors());
    }
  };
  const getContacts = async () => {
    const response = await getVendorContacts(selectedRow.id);
    setContacts(response.data);
  };
  const handleEdit = () => {
    setEdit(true);
  };

  const handleEditSave = async () => {
    const response = await postEditVendorForm(value, selectedRow.id);
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      fetchData();
      enqueueSnackbar(`Successfully updated ${response.data.name}`, {
        variant: 'success',
      });
      setEdit(false);
    } else
      enqueueSnackbar(`Error: Could not save vendor`, {
        variant: 'error',
      });
  };
  const handleDelete = async () => {
    const response = await postDeleteVendor(selectedRow.id);
    if (response) {
      enqueueSnackbar(`Successfully deleted ${selectedRow.name}`, {
        variant: 'success',
      });
      dispatch(getVendors());
      setSelectedRow(null);
      setTab(0);
    }
  };
  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Box>
          {files.fileTypes && (
            <Box sx={{ display: 'inline-block', mr: 1 }}>
              <NewFileModal fileTypes={files.fileTypes} selectedRow={selectedRow} fetchFiles={fetchFiles} />
            </Box>
          )}
        </Box>
      </Grid>
    ),
    [files.fileTypes]
  );

  const { name } = data;

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { id, fileName } = params.row;
      return (
        <>
          <IconButton
            edge="end"
            color="secondary"
            onClick={() => {
              window.open(`${HOST_API_URL}Api/Vendor/DownloadVendorFile?id=${id}`, '_blank');
            }}
          >
            <Iconify icon="mdi:tray-arrow-down" />
          </IconButton>

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete file - ${fileName}?`}
            actionButton="Delete"
            action={() => deleteVendorFile(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  return (
    <>
      <Grid item xl={12} sx={{ pt: 0 }}>
        <Paper sx={{ p: 4, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
          <Grid item xs={8}>
            <Grid xs={8} container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <Grid container item direction="row" justifyContent="center" alignItems="center">
                  <Grid item sx={{ mb: 2 }} justifyContent="center" alignItems="center">
                    <Lightbox
                      index={0}
                      slides={[{ src: value.logo }]}
                      open={selectedImage >= 0}
                      close={handleCloseLightbox}
                    />
                    <Box
                      {...getRootProps()}
                      onClick={edit ? openDropzone : handleOpenLightbox}
                      sx={{
                        position: 'relative',
                        border: `${edit ? '1px solid' : '0px solid'}`,
                        borderColor: 'info.main',
                        borderRadius: `${edit ? '8px' : '2px'}`,
                        boxShadow: `${edit ? ' 1px 1px 2px 0px #00000020' : '0px'}`,
                      }}
                    >
                      {edit && <input {...getInputProps()} />}

                      {!!value.logo && (
                        <Box
                          component="img"
                          src={value.logo}
                          sx={{
                            color: 'white.main',
                            width: '200px',
                            height: '60px',
                            objectFit: 'contain',
                            borderRadius: `${edit ? '8px' : '2px'}`,
                          }}
                        />
                      )}

                      {!value.logo && edit && (
                        <Typography
                          sx={{ pt: 2, textAlign: 'center', color: 'info.main', width: '300px', height: '55px' }}
                        >
                          Click to Upload or Drag and Drop{' '}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item sx={{ ml: 2 }}>
                    <Typography variant="h3" color="primary" display="inline" sx={{ pl: 0 }}>
                      {!!value.logo && '| '}
                      {name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Box>
                  <>
                    {!files?.files?.length > 0 && (
                      <NewFileModal fileTypes={files.fileTypes} selectedRow={selectedRow} fetchFiles={fetchFiles} />
                    )}
                    {edit ? (
                      <>
                        <AwaitButton
                          variant="outlined"
                          size="small"
                          color="primary"
                          sx={{ mr: 1 }}
                          onClick={handleEditSave}
                        >
                          Save
                        </AwaitButton>
                        <AwaitButton
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={() => {
                            setEdit(false);
                            fetchData();
                          }}
                        >
                          Cancel
                        </AwaitButton>
                      </>
                    ) : (
                      <>
                        <AwaitButton
                          variant="outlined"
                          size="small"
                          startIcon={<EditIcon />}
                          sx={{ mr: 1 }}
                          onClick={handleEdit}
                        >
                          Edit
                        </AwaitButton>
                        <ConfirmDialog
                          icon="delete-outline"
                          title="Delete"
                          color="error"
                          content={`Are you sure you want to delete vendor - ${name}?`}
                          actionButton="Delete"
                          action={() => handleDelete()}
                        />
                      </>
                    )}
                  </>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Grid container direction="row" justifyContent="left" alignItems="flex-start" item sx={{ mt: 2 }}>
              <Grid item xs={8}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{ mt: 0.5 }}
                  spacing={2}
                >
                  <FormTextWithValue
                    value={value}
                    title="Name*"
                    flex={4}
                    name="name"
                    setValue={setValue}
                    disabled={!edit}
                  />
                  <FormTextWithValue
                    value={value}
                    title="Send PO to"
                    flex={4}
                    name="emailTo"
                    setValue={setValue}
                    disabled={!edit}
                  />
                  <FormTextWithValue
                    value={value}
                    title="Copy on PO"
                    flex={4}
                    name="emailToCC"
                    setValue={setValue}
                    disabled={!edit}
                  />
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{ mt: 0.5 }}
                  spacing={2}
                >
                  <FormTextWithValue
                    value={value}
                    title="Address"
                    flex={4}
                    name="address"
                    setValue={setValue}
                    disabled={!edit}
                  />
                  <FormTextWithValue
                    value={value}
                    title="City"
                    flex={3}
                    name="city"
                    setValue={setValue}
                    disabled={!edit}
                  />
                  <FormTextWithValue
                    value={value}
                    title="State"
                    flex={3}
                    name="state"
                    setValue={setValue}
                    disabled={!edit}
                  />
                  <FormTextWithValue
                    value={value}
                    title="Zip"
                    flex={2}
                    name="zip"
                    setValue={setValue}
                    disabled={!edit}
                  />
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mt: 1 }}>
                  <Grid item>
                    <FormTextWithValue
                      value={value}
                      title="Phone Number"
                      width="19vw"
                      name="phone"
                      setValue={setValue}
                      disabled={!edit}
                    />
                  </Grid>
                </Grid>
                {contacts?.length > 0 &&
                  contacts.map((item, index) => (
                    <VendorContact
                      vendorId={selectedRow.id}
                      key={index}
                      index={index}
                      contact={item}
                      getContacts={getContacts}
                      positions={positions}
                      setAddContact={setAddContact}
                    />
                  ))}
                {!addContact && (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={() => setAddContact(true)}
                    style={{ maxWidth: 100, marginTop: 50 }}
                  >
                    Add Contact
                  </Button>
                )}
                {addContact && (
                  <VendorContact
                    vendorId={selectedRow.id}
                    index={contacts.length}
                    getContacts={getContacts}
                    positions={positions}
                    setAddContact={setAddContact}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {files?.files?.length > 0 && (
                  <CustomDataGrid
                    gridId="admin-vendors-tab"
                    boxSX={{ width: '100%', height: '30%', ml: 3 }}
                    data={files?.files || []}
                    gridColumns={[...FILES, actionButtons]}
                    forceReRender={data}
                    sort={[{ field: 'fileName', sort: 'asc' }]}
                    getRowId={(row) => row.id}
                    CustomLeftToolbar={customToolbar}
                    isLoading={isLoading}
                    isModal
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </>
  );
}
