import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSelector } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { INVOICE } from '../../../reusable-components/datagrid/invoiceColumns';
import { useScopeCheck } from '../../../reusable-components/scopes';

export default function Invoicing() {
  const hasViewOrder = useScopeCheck(['View-Order'], true);
  const navigate = useNavigate();
  const { invoices } = useSelector((state) => state.dashboard.data);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" color="primary" sx={{ ml: 0.5 }}>
          Invoicing
        </Typography>
        <Button
          to="/dashboard/invoicing/pending"
          component={RouterLink}
          endIcon={<Iconify icon="eva:arrow-forward-fill" width={15} height={5} />}
        >
          View all
        </Button>
      </Grid>
      <Box sx={{ height: hasViewOrder ? '34vh' : '75vh', width: '100%' }}>
        <DataGridPro
          getRowHeight={() => 'auto'}
          rows={invoices}
          columns={INVOICE}
          getRowId={(row) => row.id}
          onRowClick={(params) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            navigate(`/dashboard/invoicing/pending/${params.row.id}`);
          }}
          disableSelectionOnClick
          hideFooter
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableColumnPinning
          disableColumnReorder
        />
      </Box>
    </>
  );
}
