import { Box, Grid, Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { deleteEmailRecipientData } from '../../../../api/emailRecipients';
import { getEmailRecipients } from '../../../../redux/slices/emailRecipient';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { EMAIL_RECIPIENTS } from '../../../../reusable-components/datagrid/emailRecipientColumns';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { HOST_API_URL } from '../../../../config-global';
import Iconify from '../../../../reusable-components/iconify';
import EmailRecipientModal from './EmailRecipientModal';

//Edit
export default function EmailRecipientsView() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const {
    data: { emailRecipients },
  } = useSelector((state) => state.emailRecipient);
  const isLoading = useSelector((state) => state.emailRecipient.isLoading);

  useEffect(() => {
    if (emailRecipients.length > 0) setData(emailRecipients);
  }, [emailRecipients]);

  const fetchData = async () => {
    try {
      await dispatch(getEmailRecipients());
    } catch (error) {
      console.error('Error fetching recipient data:', error);
    }
  };

  const handleDelete = async (emailId, siteId) => {
    const response = await deleteEmailRecipientData(emailId, siteId);
    if (response.status === 200) {
      fetchData();
      enqueueSnackbar('Email deleted successfully', { variant: 'success' });
    }
  };

  const downloadEmails = async () => {
    window.open(`${HOST_API_URL}Api/EmailRecipient/DownloadRecipients`, '_blank');
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
        <EmailRecipientModal />
        <Box sx={{ display: 'inline-block', mr: 1 }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 1 }}
            size="small"
            onClick={downloadEmails}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download
          </Button>
        </Box>
      </Grid>
    ),
    []
  );

  const ActionButtons = (params) => (
    <>
      <ScopeGuard scopes={['Admin']} allowAdmin>
        <EmailRecipientModal edit row={params.row} fetchData={fetchData} />

        <ConfirmDialog
          icon="delete-outline"
          title="Delete"
          color="error"
          content={`Are you sure you want to delete ${params.row.email}?`}
          actionButton="Delete"
          action={() => handleDelete(params.row.id, params.row.site.value)}
          tooltip={`Delete`}
        />
      </ScopeGuard>
    </>
  );

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-email-recipients"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={data}
          gridColumns={[...EMAIL_RECIPIENTS, actions]}
          CustomLeftToolbar={customToolbar}
          getRowId={(row) => `${row.id}_${row.site.value}`}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
