import PropTypes from 'prop-types';
import { IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import TicketEditor from '../ticket/newTicket/TicketEditor';
import OnClickOffWrapper from '../../../../reusable-components/on-click-off/OnClickOffWrapper';
import AvailibleFields from './AvailibleFields';
import CustomWidthTooltip from './CustomWidthTooltip';
import { ScopeGuard } from '../../../../reusable-components/scopes';

TicketTypeFieldConfig.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  group: PropTypes.object,
  field: PropTypes.object,
  setFieldValue: PropTypes.func,
  setInputGroups: PropTypes.func
};

export default function TicketTypeFieldConfig({ open, setOpen, group, field, setFieldValue, setInputGroups })
{
  return <CustomWidthTooltip open={open}
    placement="left"
    arrow
    classes={{ }}
    componentsProps={{     
      tooltip: {
        sx: {
          color: "black !important",
          backgroundColor: "white !important",
          borderRadius: "6px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px 0px",
          border: "1px solid rgb(193, 201, 208)"
        },
      }      
    }}
    title={open ? <OnClickOffWrapper onClick={() => setOpen(-1)}> 
      <Typography variant="subtitle1" sx={{ mt: 1 }}>Width</Typography>
      <ToggleButtonGroup value={field.width > 0 ? field.width : 100} exclusive onChange={(event, newValue) => setFieldValue("width", field.id, group.groupId, newValue)}>
        <ToggleButton value={25}>Small</ToggleButton>
        <ToggleButton value={50}>Medium</ToggleButton>
        <ToggleButton value={75}>Large</ToggleButton>
        <ToggleButton value={100}>Max</ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>Required</Typography>
      <ToggleButtonGroup value={field.required ? "true" : "false"} exclusive onChange={(event, newValue) => setFieldValue("required", field.id, group.groupId, newValue === "true")}>
        <ToggleButton value={"true"}>Yes</ToggleButton>
        <ToggleButton value={"false"}>No</ToggleButton>
      </ToggleButtonGroup>
      {group.miscellaneousFieldsGroup && <ScopeGuard superAdminOnly>
        <AvailibleFields fields={[field]} />
        <Typography variant="subtitle1" sx={{ mt: 1 }}>Ticket display</Typography>
        <TicketEditor
          value={field.displayHTMLValue ? field.displayHTMLValue : `${field.type?.defaultType ? `${field.name} - ` : ""}{${field.id} - ${field.name}}`}
          onChange={(value) => setFieldValue("displayHTMLValue", field.id, group.groupId, value)}
          editorProps={{ attachFile: false }}
        />
      </ScopeGuard>}
    </OnClickOffWrapper> : ""}>
    {field?.name && field?.ticketFieldTypeId > 0 ? <IconButton
      color="info"
      sx={{ ml: 1, mb: 1, p: 0, alignSelf: "end" }}
      onClick={() => setOpen(oldIndex => oldIndex !== field.id ? field.id : -1)}>
      <SettingsIcon />
    </IconButton> : <span />}
  </CustomWidthTooltip>;
}