import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Box, Grid, Button, Paper, Typography, Autocomplete, TextField } from '@mui/material';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import Iconify from '../../../../reusable-components/iconify';
import { useDispatch, useSelector } from '../../../../redux/store';
import CalendarPage from './CalendarPage';
import { getVendorDatesData, postVendorDateForm, postDeleteVendorDate } from '../../../../api/vendors';
import { onCloseModal } from '../../../../redux/slices/vendors';
import { HOST_API_URL } from '../../../../config-global';

VendorShipDateTab.propTypes = {
  selectedRow: PropTypes.object,
};

export default function VendorShipDateTab({ selectedRow }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { vendors, facilities },
  } = useSelector((state) => state.vendors);
  const { selectedEventId } = useSelector((state) => state.vendors);

  const [facility, setFacility] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [typesArray, setTypesArray] = useState([]);
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('dayGridMonth');

  const typeIdLookUp = (typeId, types) => {
    const type = types.find((t) => t.value === typeId);
    return type ? type.label : '';
  };
  function getDaysArray(start, end) {
    const arr = [];
    const dt = new Date(start);
    end = new Date(end);

    while (dt <= end) {
      arr.push(dt.getDay());
      dt.setDate(dt.getDate() + 1);
    }

    // Remove duplicates (if any) and return
    return [...new Set(arr)];
  }
  function updateWeeklyEvents(events, types) {
    return events.map((event) => {
      const title = typeIdLookUp(event.typeId, types);

      if (event.weekly) {
        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);

        // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
        const daysOfWeek = getDaysArray(startDate, endDate);

        // Format start and end times (e.g., '10:00')
        const startTime = event.startDate.split('T')[1].substring(0, 5);
        const endTime = event.endDate.split('T')[1].substring(0, 5);

        // Update the event
        return {
          ...event,
          daysOfWeek,
          startTime,
          endTime,
          title,
          color: '#1890FF',
        };
      }
      return {
        ...event,
        title,
        color: '#FFC107',
        start: new Date(event.startDate),
        end: new Date(event.endDate),
      };
    });
  }
  useEffect(() => {
    if (selectedRow) {
      setVendor({ id: selectedRow.id, label: selectedRow.name });
    }
  }, [selectedRow]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getVendorDatesData(facility?.id, vendor?.id);
        const { dates, types } = response.data;
        const updatedEvents = updateWeeklyEvents(dates, types);
        setEvents(updatedEvents);
        setTypesArray(types);
      } catch (error) {
        console.error('Error fetching vendor data:', error);
      }
    };
    if (vendor && facility) fetchData();
  }, [facility, vendor]);

  const handleDelete = async () => {
    const response = await postDeleteVendorDate(selectedEventId);
    if (response.status === 200) {
      try {
        const response = await getVendorDatesData(facility?.id, vendor?.id);
        const { dates, types } = response.data;
        const updatedEvents = updateWeeklyEvents(dates, types);
        setEvents(updatedEvents);
        setTypesArray(types);
      } catch (error) {
        console.error('Error fetching vendor data:', error);
      }
    }
    dispatch(onCloseModal());
  };

  const handleEditAddDate = async (data) => {
    const response = await postVendorDateForm(
      view === 'dayGridMonth' ? facility?.id : data.facilityId.id,
      view === 'dayGridMonth' ? vendor?.id : data.vendorId.id,
      data,
      selectedEventId
    );

    if (response.status === 200) {
      enqueueSnackbar('Date saved successfully', { variant: 'success' });
      try {
        const response = await getVendorDatesData(facility?.id, vendor?.id);
        const { dates, types } = response.data;
        const updatedEvents = updateWeeklyEvents(dates, types);
        setEvents(updatedEvents);
        setTypesArray(types);
      } catch (error) {
        console.error('Error fetching vendor data:', error);
      }
    }
    dispatch(onCloseModal());
  };

  return (
    <Paper sx={{ px: 2, mt: 1, minHeight: '50vh', boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1} sx={{ ml: 12 }}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ mr: 1 }}
          size="small"
          onClick={() => {
            window.open(`${HOST_API_URL}Api/Vendor/DownloadVendorDates`, '_blank');
          }}
          startIcon={<Iconify icon="file-icons:microsoft-excel" />}
        >
          Download All
        </Button>
        <Grid item>
          <Box>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Vendor
            </Typography>
            <Autocomplete
              size="small"
              value={vendor?.label || ''}
              onChange={(event, newValue) => {
                setVendor(newValue);
              }}
              options={vendors.map((vendor) => ({ id: vendor.id, label: vendor.name }))}
              // isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} label="Search or select" />}
              sx={{
                width: '20vw',
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Facility
            </Typography>
            <Autocomplete
              size="small"
              value={facility?.label || ''}
              onChange={(event, newValue) => {
                setFacility(newValue);
              }}
              options={facilities.map((facility) => ({ id: facility.value, label: facility.label }))}
              // isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} label="Search or select" />}
              sx={{
                width: '20vw',
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {vendor && facility && (
        <CalendarPage
          events={events}
          typesArray={typesArray}
          handleDelete={handleDelete}
          handleEditAddDate={handleEditAddDate}
          view={view}
          setView={setView}
        />
      )}
    </Paper>
  );
}
