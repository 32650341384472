import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button, Menu, MenuItem } from '@mui/material';
import { fCurrency } from '../../../../utils/formatNumber';
import Iconify from '../../../../reusable-components/iconify';
import { useSelector } from '../../../../redux/store';
import OrderTotalBar from './OrderTotalBar';

// ----------------------------------------------------------------------

export default function OrderTotal() {
  const { id = '' } = useParams();
  const [vendorTotals, setVendorTotals] = useState([]);
  const { orderById, tempOrderListById = [] } = useSelector((state) => state.orders);
  const { vendorMinimums, items = [] } = orderById[id] || {};

  useEffect(() => {
    if (items?.length > 0 || vendorMinimums?.length > 0) {
      const allItems = [...(items || []), ...(tempOrderListById[id] || [])];
      const cartTotals = allItems
        .filter((item) => item.approved) // filter only approved items
        .reduce((acc, item) => {
          const { vendorId, price, quantity, vendor } = item;
          const vendorMinimum = vendorMinimums?.find((min) => min.vendorId === vendorId);
          const total = price * quantity;
          const vendorTotal = acc.find((total) => total.vendorId === vendorId);
          if (vendorTotal) {
            vendorTotal.total += total;
          } else {
            acc.push({ vendorId, vendor, total, minimum: vendorMinimum?.minimum ?? 0 });
          }
          return acc;
        }, []);

      setVendorTotals(cartTotals);
    }
  }, [items, vendorMinimums, id]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        color="secondary"
        size="small"
        variant="outlined"
        endIcon={<Iconify icon="mdi:chevron-down" />}
        startIcon={
          vendorTotals.filter((vt) => vt.total < vt.minimum).length > 0 && <Iconify icon="jam:alert" color="error" />
        }
        onClick={handleClick}
      >
        Order total{' '}
        {fCurrency(
          [...items, ...(tempOrderListById[id] || [])]
            ?.filter((item) => item.approved && item.deleted !== true)
            .reduce((acc, item) => acc + item.price * item.quantity, 0)
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {vendorTotals.length > 0 ? (
          vendorTotals.map((vt, index) => (
            <MenuItem onClick={handleClose} key={index}>
              <OrderTotalBar vendorTotal={vt} onClick={handleClose} />             
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>
            <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
              There are no vendor minimums
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}