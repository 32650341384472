import { Box } from '@mui/material';
import Iconify from '../iconify';

const description = {
  field: 'description',
  headerName: 'Description',
  width: 400,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const name = {
  field: 'name',
  headerName: 'Name',
  width: 200,
  type: 'string',
};
const approxTimeTaken = {
  field: 'approxTimeTaken',
  headerName: 'Aprox. Time',
  width: 100,
  type: 'string',
  renderCell: (params) => {
    if (params.value === 0) {
      return '';
    }

    const time = params.value;
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return (
      <Box>
        <Iconify icon={'mdi:clock-time-five'} width={16} height={16} /> {hours}h {minutes}m
      </Box>
    );
  },
};
const TASKS = [name, description, approxTimeTaken];

export { TASKS };
