import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Grid, DialogTitle, DialogContent, IconButton, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getOrderGuides } from '../../../../redux/slices/orderGuides';
import Iconify from '../../../../reusable-components/iconify';
import { TEMPLATES } from '../../../../reusable-components/datagrid/orderGuidesColumns';
import NewOrderGuideTemplateDialog from './NewOrderGuideTemplateDialog';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { HOST_API_URL } from '../../../../config-global';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { deleteOrderGuide } from '../../../../api/order-guides';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
// import { AwaitButton } from '../../../../reusable-components/await-button';

export default function OrderGuideTemplate() {
  const {
    data: { orderGuideActivations, templates },
  } = useSelector((state) => state.orderGuides);
  const isLoading = useSelector((state) => state.orderGuides.isLoading);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (open && !data?.length) {
      const filteredData = templates.map((template) => {
        const filteredOrderGuideActivations = orderGuideActivations.filter(
          (orderGuideActivation) => orderGuideActivation.templateName === template.templateName
        );
        return {
          ...template,
          ...filteredOrderGuideActivations[0],
        };
      });
      setData(filteredData);
    }
  }, [open, data, orderGuideActivations, templates]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    console.log('addProduct');

    handleClose();
  };

  const handleDelete = async (id) => {
    const response = await deleteOrderGuide(id);
    if (response.status === 200) {
      setData([]);
      dispatch(getOrderGuides());
    }
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Box>
          <ScopeGuard scopes={['Edit-OrderGuide']} allowAdmin>
            <Box sx={{ display: 'inline-block', mr: 1 }}>
              <NewOrderGuideTemplateDialog />
            </Box>
          </ScopeGuard>
        </Box>
      </Grid>
    ),
    []
  );
  const actionButtons = (params) => {
    const { id } = params.row;
    return (
      <>
        {/* <VendorGuide agoraCategoryId={agoraCategoryId} facilityId={facilityId} extraCall /> */}
        <Box id="action-button" className="action-button">
          <IconButton
            color="info"
            onClick={() => {
              window.open(`${HOST_API_URL}/Api/DownloadOrderGuide?orderGuideActivationId=${id}`, '_blank');
            }}
            id="action-button"
            className="action-button"
          >
            <Iconify icon={'mdi:tray-arrow-down'} id="action-button" className="action-button" />
          </IconButton>
        </Box>
        <ScopeGuard scopes={['Edit-OrderGuide']} allowAdmin>
          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content="Are you sure want to delete?"
            actionButton="Delete"
            action={() => handleDelete(id)}
            tooltip={`Delete`}
          />
        </ScopeGuard>
      </>
    );
  };
  const actions = {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => actionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="entypo:popup" />}
      >
        Order guide templates
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          Order Guide Templates
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <>
            <Grid item xs={12}>
              <CustomDataGrid
                gridId="admin-order-guide-templates"
                boxSX={{ height: 'calc(100vh - 260px)' }}
                data={data}
                gridColumns={[...TEMPLATES, actions]}
                sort={[{ field: 'dateAdded', sort: 'desc' }]}
                isLoading={isLoading}
                disableSelectionOnClick
                isModal
                CustomLeftToolbar={customToolbar}
                getRowId={(row) => row.id}
                onRowClick={(params, e) => {
                  const selection = window.getSelection().toString();
                  if (selection.length > 0) return;
                  if (
                    e.target.classList.contains('action-button') ||
                    e.target.dataset.field === 'actions' ||
                    e.target?.nearestViewportElement?.dataset?.field === 'actions' ||
                    e.target?.nearestViewportElement?.classList.contains('action-button')
                  ) {
                    return;
                  }
                  navigate(`/dashboard/admin/order-guides/${params.id}`);
                }}
              />
            </Grid>
          </>
        </DialogContent>
        {/* <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave}>
            Save
          </AwaitButton>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
