// remember to import this file in index.js

export default function Link(theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          fontSize: 12,
          fontWeight: 600,
          textDecoration: 'none',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
  };
}
