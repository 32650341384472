export function inOneHour() {
  const date = new Date();
  date.setHours(date.getHours() + 1);
  return date;
};

export function inThreeDays() {
  const date = new Date();
  date.setDate(date.getDate() + 3);
  return date;
};

export function tomorrow() {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};