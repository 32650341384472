import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

export async function getSentEmailsData({ startDate, endDate }) {
  const response = await axios.get(`Admin/SentEmail/SentEmails?start=${startDate}&end=${endDate}`);
  return response;
}

export async function postResendEmail(ids) {
  const response = await axios.post(`Admin/SentEmail/ResendEmails`, objectToFormData({ ids }));
  return response;
}
