import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Stack, Slider, Typography, Tooltip } from '@mui/material';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

Rotate.propTypes = {  setRotation: PropTypes.func };
export default function Rotate({ setRotation }) {
  const handleRotate = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360); // Increment rotation by 90 degrees
  };

  return (
    <Box>
      <Tooltip title={'rotate'}>
        <IconButton color="secondary" sx={{ p: 0.5 }} onClick={handleRotate}>
          <RotateRightIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
