import { useState, useRef, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Grid,
  Button,
  TextField,
  DialogContentText,
  Autocomplete,
  IconButton,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../../reusable-components/iconify';
import {
  fetchVendorProductData as catalogFetchVendorProductData,
  postAddProductImage as catalogPostAddProductImage,
} from '../../../../api/catalog';
import {
  fetchVendorProductData as warehouseFetchVendorProductData,
  postAddProductImage as warehousePostAddProductImage,
} from '../../../../api/warehouse';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { formatMoney } from '../../../../utils/formatNumber';

Step3.propTypes = {
  productData: PropTypes.object,
  setProductData: PropTypes.func,
  additionalUOM: PropTypes.bool,
  setAdditionalUOM: PropTypes.func,
  uomType2: PropTypes.object,
  setUomType2: PropTypes.func,
  uomTypes: PropTypes.array,
  vendor: PropTypes.object,
  vendorId: PropTypes.string,
  uomType: PropTypes.object,
  setPpdOptions: PropTypes.func,
  ppdOptions: PropTypes.array,
  isWarehouseItem: PropTypes.bool,
  vendors: PropTypes.array,
};

export default function Step3({
  productData,
  setProductData,
  additionalUOM,
  setAdditionalUOM,
  vendor,
  vendorId,
  uomType,
  setUomType2,
  uomTypes,
  setPpdOptions = null,
  ppdOptions = null,
  isWarehouseItem = false,
  vendors,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const fileInputRef = useRef(null);
  const getProductData2 = async (newValue) => {
    const response = isWarehouseItem
      ? await warehouseFetchVendorProductData(vendor?.value, vendorId, newValue.value)
      : await catalogFetchVendorProductData(vendor?.value, vendorId, newValue.value);
    const data = response;
    if (data.status === 200) {
      const { price, uom, uomAmount } = data.data;
      setProductData((prevData) => ({
        ...prevData,
        price2: price,
        uom2: uom,
        uomAmount2: uomAmount,
      }));
    } else {
      setProductData((prevData) => ({
        ...prevData,
        price2: '',
        uom2: '',
        uomAmount2: '',
      }));
    }
  };

  const updateProductData = (field, value) => {
    if (field === 'pictureUrls') {
      setProductData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else {
      setProductData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };
  const [textFieldUrl, setTextFieldUrl] = useState(''); // Separate state for TextField

  const handleChangeTextField = (newUrl) => {
    setTextFieldUrl(newUrl); // Update TextField's state independently
  };

  const handleAddImage = async () => {
    const newPictureUrls = [...(productData?.pictureUrls || [])];

    let imageUrlToAdd = textFieldUrl;

    if (!textFieldUrl.includes('//res.cloudinary.com/agoraserv')) {
      try {
        const imgUrl = isWarehouseItem
          ? await warehousePostAddProductImage(textFieldUrl)
          : await catalogPostAddProductImage(textFieldUrl);
        if (imgUrl.data.length) {
          imageUrlToAdd = imgUrl.data;
        } else {
          enqueueSnackbar('Invalid image url', { variant: 'error' });
          return;
        }
      } catch (error) {
        enqueueSnackbar('Failed to fetch image url', { variant: 'error' });
        return;
      }
    }

    newPictureUrls.push(imageUrlToAdd);
    updateProductData('pictureUrls', newPictureUrls);
    setTextFieldUrl('');
  };

  const handleRemoveImage = (index) => {
    const newPictureUrls = [...(productData?.pictureUrls || [])];
    newPictureUrls?.splice(index, 1);
    updateProductData('pictureUrls', newPictureUrls);
  };

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const ppdSelectOptions = useMemo(() => {
    if (isWarehouseItem) {
      return [];
    }
    const options = vendors.find((vend) => vend.value === vendor?.value);
    return options?.ppdInputOptions || [];
  }, [vendor]);

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: true,
  });

  const handleFileInputChange = async (event) => {
    const { files } = event.target;

    const promiseArr = Array.from(files)
      .map((file) => {
        if (!file?.type.startsWith('image/')) {
          enqueueSnackbar('Invalid file type', { variant: 'error' });
          return;
        }
        // eslint-disable-next-line consistent-return
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function onFileLoad() {
            const base64Image = reader.result.split('base64,')[1];
            resolve(
              isWarehouseItem ? warehousePostAddProductImage(base64Image) : catalogPostAddProductImage(base64Image)
            );
          };
          reader.onerror = function onFileLoadError(error) {
            enqueueSnackbar('Error uploading image', { variant: 'error' });
            reject(error);
          };
        });
      })
      .filter(Boolean);

    const imageUrls = await Promise.all(promiseArr);
    updateProductData(
      'pictureUrls',
      (productData?.pictureUrls ?? []).concat(imageUrls.map((x) => x?.data).filter((x) => x != null))
    );
  };
  return (
    <>
      {productData?.error && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography
            variant="body2"
            sx={{
              padding: 1,
              fontWeight: 'bold',
              mt: 2,
              color: '#8dc63f',
              backgroundColor: '#eef7e2',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {productData?.error}
          </Typography>
        </Grid>
      )}

      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor *</DialogContentText>
          <TextField
            fullWidth
            value={vendor?.label || ''}
            disabled
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor Item ID *</DialogContentText>
          <TextField
            fullWidth
            value={vendorId || ''}
            disabled
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>UOM Type *</DialogContentText>
          <TextField
            fullWidth
            value={uomType?.label || ''}
            disabled
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>UOM Amount *</DialogContentText>
          <TextField
            fullWidth
            type="number"
            value={productData?.uomAmount || ''}
            onChange={(e) => updateProductData('uomAmount', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Price *</DialogContentText>
          <TextField
            fullWidth
            value={productData?.price || ''}
            onChange={(e) => updateProductData('price', formatMoney(e.target.value))}
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Base UOM type</DialogContentText>
          <TextField
            fullWidth
            value={productData?.baseUOMType || ''}
            onChange={(e) => updateProductData('baseUOMType', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Base UOM amount</DialogContentText>
          <TextField
            fullWidth
            type="number"
            value={productData?.baseUOMAmount || ''}
            onChange={(e) => updateProductData('baseUOMAmount', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>

      {additionalUOM && (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional UOM Type *</DialogContentText>
              <Autocomplete
                size="small"
                fullWidth
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabelRoot ': 'none',
                }}
                type="new-password"
                options={uomTypes || []}
                autoHighlight
                getOptionLabel={(option) => option?.label}
                // getOptionSelected={(option, value) => option?.label === value?.label}
                value={productData?.uomType2}
                onChange={(event, newValue) => {
                  setUomType2(newValue);
                  getProductData2(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label=""
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional UOM Amount *</DialogContentText>
              <TextField
                fullWidth
                type="number"
                value={productData?.uomAmount2 || ''}
                onChange={(e) => updateProductData('uomAmount2', e.target.value)}
                label=""
                size="small"
                variant="outlined"
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Additional Price *</DialogContentText>
              <TextField
                fullWidth
                value={productData?.price2 || ''}
                onChange={(e) => updateProductData('price2', formatMoney(e.target.value))}
                label=""
                size="small"
                variant="outlined"
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Button
          variant="text"
          color={additionalUOM ? 'error' : 'primary'}
          sx={{
            mt: 2,
            textDecoration: 'underline',
          }}
          onClick={() => setAdditionalUOM(!additionalUOM)}
        >
          {additionalUOM ? 'Remove' : 'Add'} additional UOM
        </Button>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Manufacturer</DialogContentText>
          <TextField
            fullWidth
            value={productData?.manufacturer || ''}
            onChange={(e) => updateProductData('manufacturer', e.target.value)}
            label=""
            size="small"
            multiline
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Manufacturer ID</DialogContentText>
          <TextField
            fullWidth
            value={productData?.manufacturerId || ''}
            onChange={(e) => updateProductData('manufacturerId', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={isWarehouseItem ? 9 : productData?.isPPD && ppdSelectOptions.length ? 5 : 10.5}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor item link</DialogContentText>
          <TextField
            fullWidth
            value={productData?.vendorItemLink || ''}
            onChange={(e) => updateProductData('vendorItemLink', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            sx={{ width: '95%' }}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={1.5}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>PPD</DialogContentText>
          <ToggleButtonGroup
            value={productData?.isPPD}
            exclusive
            onChange={(e) => updateProductData('isPPD', e.target.value)}
          >
            <ToggleButton value="true">Yes</ToggleButton>
            <ToggleButton value="false">No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {!isWarehouseItem && productData?.isPPD && ppdSelectOptions.length > 0 ? (
          <Grid item xs={5.5}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>PPD for facilities</DialogContentText>
            <Autocomplete
              size="small"
              sx={{
                width: '90%',
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabelRoot ': 'none',
              }}
              multiple
              type="new-password"
              options={ppdSelectOptions}
              autoHighlight
              getOptionLabel={(option) => option?.facility}
              getOptionKey={(option) => option?.facilityId}
              onChange={(e, newValue) => {
                setPpdOptions(newValue.map((option) => option.facilityId) || []);
              }}
              value={ppdSelectOptions.filter((option) => ppdOptions.includes(option.facilityId))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor Item Description</DialogContentText>
          <TextField
            fullWidth
            multiline
            value={productData?.vendorItemDescription || ''}
            onChange={(e) => updateProductData('vendorItemDescription', e.target.value)}
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>

      <DialogContentText sx={{ fontWeight: 'bold', mt: 3, mb: -1 }}>Images</DialogContentText>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
        {productData?.pictureUrls?.map((url, index) => (
          <Grid item xs={2} key={index}>
            <Box m={1} boxShadow={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <FileThumbnail
                file={{ id: index, name: url, type: 'image' }}
                sx={{ width: 128, height: 128 }}
                preview={url}
                displayImage
              />

              <IconButton onClick={() => handleRemoveImage(index)}>
                <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
        <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
          <input {...getInputProps()} />
          <Paper
            elevation={isDragActive ? 6 : 1}
            sx={{
              p: 2,
              bgcolor: 'background.paper',
              color: 'info.main',
              borderColor: 'info.main',
              border: 1,
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                bgcolor: 'background.default',
                boxShadow: 3,
              },
            }}
          >
            <Typography variant="body2" textAlign="center">
              Click to Upload or Drag and Drop
            </Typography>
          </Paper>
        </Box>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
        <Grid item xs={1}>
          <Typography variant="body2">Image url </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            value={textFieldUrl}
            onChange={(e) => handleChangeTextField(e.target.value)}
            label=""
            size="small"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleAddImage} disabled={!textFieldUrl} color="primary">
            <Iconify icon="material-symbols:check" width={28} height={28} />
          </IconButton>
        </Grid>
      </Grid>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        accept="image/*"
        onChange={handleFileInputChange}
      />
    </>
  );
}
