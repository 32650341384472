import { Box, Typography, Grid, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { fCurrency } from '../../../../utils/formatNumber';

OrderTotalBar.propTypes = {
  vendorTotal: PropTypes.object,
  onClick: PropTypes.func
};

export default function OrderTotalBar({ vendorTotal, onClick  }) {

  return (
    <Box sx={{ flexGrow: 1, position: 'relative', mb: 1 }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
          {vendorTotal.vendor}
        </Typography>
        <Typography variant="subtitle2" sx={{ textAlign: 'left', ml: 15 }}>
          Vendor minimum {fCurrency(vendorTotal.minimum)}
        </Typography>
      </Grid>
      <StyledBox onClick={onClick} style={onClick ? {} : { cursor: "default" }} >
        <Typography
          variant="subtitle2"
          component="span"
          sx={{ color: vendorTotal.total >= vendorTotal.minimum || vendorTotal.minimum <= 0 ? 'white' : 'inherit' }}
        >
          {vendorTotal.minimum > 0 ? `Total ${fCurrency(vendorTotal.total)} ` : `Total ${fCurrency(vendorTotal.total)}`}
        </Typography>
      </StyledBox>
      <StyledLinearProgress
        variant="determinate"
        value={(vendorTotal.total / (vendorTotal.minimum || vendorTotal.total)) * 100 > 100
          ? 100
          : (vendorTotal.total / (vendorTotal.minimum || vendorTotal.total)) * 100
        }
        color={vendorTotal.minimum > 0 ? (vendorTotal.total >= vendorTotal.minimum ? 'primary' : 'error') : 'primary'}
      />
    </Box>);
}

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: '4px',
  height: 23,
  border: `1px solid ${theme.palette.primary.light}`,
  backgroundColor: '#fff',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiLinearProgress-barColorError': {
    backgroundColor: theme.palette.error.main,
  },
  '& .MuiLinearProgress-barColorWarning': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  maxHeight: 23, // borderlinearprogress root.height
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '8px',
  '& span': {
    width: '100%',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  borderRadius: '4px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
}));
