import { Chip, Box, Tooltip } from '@mui/material';
import Iconify from '../iconify';
import { fDateTime, fMonthDayYear } from '../../utils/formatTime';
// facilityId(pin):11
// const facility = { field: 'facility', headerName: 'Facility', flex: 1.5, type: 'customSelect' };

const facility = {
  field: 'facility',
  headerName: 'Facility',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  flex: 1,
  minWidth: 90,
  type: 'customSelect',
};

const group = {
  field: 'company',
  headerName: 'Group',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  flex: 1,
  minWidth: 90,
  type: 'customSelect',
};

const lastLogin = {
  field: 'lastLoggedIn',
  headerName: 'Last Login',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {fDateTime(params.value, "MM/dd/yyyy p")}
    </Box>
  ),
  flex: 1,
  minWidth: 80,
  type: 'customDate',
};

const fullName = {
  field: 'fullName',
  headerName: 'Name',
  flex: 1.2,
  minWidth: 80,
  renderCell: (params) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
        }}
      >
        {params.row.firstName} {params.row.lastName}
      </Box>
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          fontSize: '0.7rem',
        }}
      >
        {params.row.email}
      </Box>
    </Box>
  ),
  valueGetter: (params) => {
    const { firstName, lastName, email } = params.row;
    return `${firstName} ${lastName} ${email}`;
  },

  type: 'customText',
};

const position = { field: 'position', headerName: 'Position', flex: 1, minWidth: 90, type: 'customSelect' };

const getStatus = (params) => {
  if (params.row.deleteFlag) return "Deleted";
  if (new Date(params.row.lockoutEnd) > new Date()) return "Disabled";
  if (params.row.emailConfirmed) return "Email Confirmed";
  return "Awaiting Email Confirmation";
}

const emailConfirmed = {
  field: 'emailConfirmed',
  headerName: 'Status',
  flex: 0.5,
  valueGetter: getStatus,
  renderCell: (params) => (
    <Tooltip title={params.row.deleteFlag ? `Deleted by ${params.row.deletedBy} on: ${fMonthDayYear(params.row.dateDeleted)}` :
      new Date(params.row.lockoutEnd) > new Date() ? `Disabled by ${params.row.disabledBy} on: ${fMonthDayYear(params.row.dateDisabled)}` :
        ''} >
      <Box sx={params.row.deleteFlag || new Date(params.row.lockoutEnd) > new Date() ? { color: 'error.main' } : {}} >
        {getStatus(params)}
      </Box>
    </Tooltip>
  ),
  type: 'customText',
};
const roleShort = {
  field: 'roleShort',
  headerName: 'Roles',
  flex: 3,
  renderCell: (params) => {
    const { value } = params;
    if (!value) return null;
    const roles = value.split(',');
    return (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          py: 0.5,
        }}
      >
        {roles.map((role, index) => (
          <Chip sx={{ mt: 0.5, mr: 0.5 }} key={index} label={role} size="small" />
        ))}
      </Box>
    );
  },
  valueGetter: (params) => params.row.roleShort,
  type: 'customText',
};

const name = {
  field: 'name',
  flex: 1,
  minWidth: 80,
  headerName: 'Name',
  renderCell: (params) => (
    <Box sx={{ wordWrap: 'break-word', overflow: 'hidden', whiteSpace: 'pre-wrap', textOverflow: 'ellipsis' }}>
      {params.value}
    </Box>
  ),
};

const useUserFacility = {
  field: 'useUserFacility',
  headerName: 'Use user facility',
  flex: 1,
  renderCell: (params) => {
    const { value } = params;
    return value ? (
      <Iconify icon="material-symbols:check-circle-outline" color="primary" />
    ) : (
      <Iconify icon="mdi:close" color="error" />
    );
  },
};

const label = { field: 'label', headerName: 'Role', flex: 1, minWidth: 70 };
const description = {
  field: 'description',
  headerName: 'Description',
  minWidth: 100,
  flex: 1.5,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Date Added',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {fDateTime(params.value, "MM/dd/yyyy p")}
    </Box>
  ),
  flex: 1,
  minWidth: 80,
  type: 'customDate',
};

const addedBy = {
  field: 'addedBy',
  headerName: 'Added By',
  flex: 1,
  minWidth: 70 ,
  type: 'customText',
};

const USERS_COLUMNS = [emailConfirmed, fullName, group, facility, position, lastLogin, dateAdded, addedBy, roleShort ];
const ROLES_COLUMNS = [label, description];
const TEMPLATES_COLUMNS = [name, useUserFacility];

export { USERS_COLUMNS, ROLES_COLUMNS, TEMPLATES_COLUMNS };
