import { createSlice } from '@reduxjs/toolkit';
import {
  getAllProductsData,
  getManageOrderGuideData,
  getOrderGuidesData,
  getOrderGuidesFormData,
  orderGuideVendorPreferencesForm,
  setOrderGuideItems
} from '../../api/order-guides';

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  isLoadingProducts: false,
  error: null,
  data: {
    categories: [],
    facilities: [],
    orderGuideActivations: [],
    orderGuide: {
      orderGuide: [],
      preferences: [],
      subcategories: [],
      vendors: []
    },
    subcategories: [],
    vendors: [],
    allProducts: [],
  },
};

const slice = createSlice({
  name: 'orderGuides',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    startLoadingProducts(state) {
      state.isLoadingProducts = true;
    },   
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isLoadingProducts = false;
      state.error = action.payload;
    },

    getDataSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, ...action.payload };
    },
    getOrderGuideDataSuccess(state, action) {
      state.isLoading = false;
      state.data.orderGuide = action.payload;
    },
    getManageOrderGuideDataSuccess(state, action) {
      state.isLoading = false;
      const { subcategories, vendors } = action.payload;
      state.data.vendors = vendors;
      state.data.subcategories = subcategories;
    },
    getAllProductsDataSuccess(state, action) {
      state.isLoadingProducts = false;
      state.data.allProducts = action.payload;
    },
    getUpdatedOrderGuide(state, action) {
      const index = state.data.orderGuideActivations?.findIndex(
        (item) =>
          item.agoraCategoryId === action.payload.agoraCategoryId && item.facilityId === action.payload.facilityId
      );
      state.data.orderGuideActivations[index] = action.payload;
      state.isLoading = false;
    },
    resetState(state) {
      state = initialState;
    },
    clearOrderGuides(state) {
      state.data.orderGuide = initialState.data.orderGuide;
    },
    clearVendorPreferences(state) {
      state.data.orderGuide = initialState.data.orderGuide;
      state.data.preferences = [];
      state.data.restricted = [];
      state.data.subcategories = [];
      state.data.vendors = [];
    },
  },
});

// Reducer
export default slice.reducer;

export function getOrderGuides() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getOrderGuidesData();
      dispatch(slice.actions.getDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderGuidesByFacilityAndCategory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.clearOrderGuides());
    try {      
      const response = await getOrderGuidesFormData(id);
      dispatch(slice.actions.getOrderGuideDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingProducts());
    try {
      const response = await getAllProductsData();
      dispatch(slice.actions.getAllProductsDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getManageOrderGuide() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getManageOrderGuideData();
      dispatch(slice.actions.getManageOrderGuideDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveOrderGuideVendorPreferences({ id, tempPreferences, tempRestricted, closeAndReloadOrderGuide }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await orderGuideVendorPreferencesForm({
        id,
        tempPreferences,
        tempRestricted,
      });
      dispatch(slice.actions.endLoading());
      if (response.status === 200) {
        closeAndReloadOrderGuide();
      } 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setOrderGuideItemsAction(id, active, permissionItemIds, navigate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await setOrderGuideItems(id, active, permissionItemIds);
      dispatch(slice.actions.getUpdatedOrderGuide(response.data));
      navigate('/dashboard/admin/order-guides');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async (dispatch) => {
    dispatch(slice.actions.resetState());
  };
}
