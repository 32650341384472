import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getVendorLinksData, postDeleteVendorLink } from '../../../../api/warehouse-vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';
import EditIconButton from '../../../../reusable-components/basic-buttons/EditIconButton';
import WarehouseVendorLinkModal from './WarehouseVendorLinkModal';

WarehouseVendorLinks.propTypes = {
  vendorList: PropTypes.array,
  fetchVendorData: PropTypes.func,
};

export default function WarehouseVendorLinks({ vendorList, fetchVendorData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [rowData, setRowData] = useState();

  const deleteWarehouseVendor = async (id) => {
    try {
      const response = await postDeleteVendorLink(id);
      if (response.status === 200) {
        enqueueSnackbar('Warehouse vendor link deleted successfully', { variant: 'success' });
        fetchVendorLinkData();
      } else {
        enqueueSnackbar('Problem deleting warehouse vendor link', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Problem deleting warehouse vendor link', { variant: 'error' });
      console.error('Error deleting warehouse vendor:', error);
    }
  };

  const fetchVendorLinkData = async () => {
    setIsLoading(true);
    const response = await getVendorLinksData();
    if (response.status === 200) {
      response.data.links = response.data.links.map(link => ({
        ...link,
        site: response.data.sites.find(site => site.value === link.siteId)?.label,
        siteVendor: response.data.siteVendors.find(siteVendor => siteVendor.siteId === link.siteId && siteVendor.vendorId === link.siteVendorId)?.vendorName,
        warehouseVendor: vendorList.find(warehouseVendor => warehouseVendor.id === link.warehouseVendorId)?.name
      }));
      setData({ ...response.data, vendorList });
      setTableData(response.data.links);
    } else {
      enqueueSnackbar('Error fetching warehouse vendors', { variant: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchVendorLinkData();
  }, []);

  //COLUMNS
  const mainVendorCol = {
    field: 'warehouseVendor',
    headerName: 'Warehouse Vendor',
    minWidth: 100,
    flex: 0.7
  };

  const subSiteCol = {
    field: 'site',
    headerName: 'Site',
    minWidth: 100,
    flex: 0.7
  };

  const subSiteVendorCol = {
    field: 'siteVendor',
    headerName: 'Connected to',
    minWidth: 100,
    flex: 0.7
  };

  const actionButtonCol = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <EditIconButton onClick={() => { setRowData(params.row); setOpenForm(true); }} />         

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete the ${params.row.warehouseVendor} link?`}
            actionButton="Delete"
            action={() => deleteWarehouseVendor(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  return (
    <>
      <CustomDataGrid
        gridId="admin-warehouse-vendor-links"
        boxSX={{ height: 'calc(100vh - 340px)' }}
        data={tableData}
        gridColumns={[mainVendorCol, subSiteCol, subSiteVendorCol, actionButtonCol]}
        pinnedColumns={{ right: ['actions'] }}
        sort={[{ field: 'warehouseVendor', sort: 'asc' }]}
        CustomLeftToolbar={() => <AddButton onClick={() => setOpenForm(true)}>Add Link</AddButton>}
        isLoading={isLoading}
      />
      <WarehouseVendorLinkModal
        open={openForm}
        row={rowData}
        data={data}
        fetchData={() => {
          fetchVendorLinkData();
          fetchVendorData();
        }}
        onClose={() => { setOpenForm(false); setRowData(null); }}
      />
    </>
  );
}
