import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Menu, MenuItem, Typography, Button, Grid, Box, Autocomplete, TextField, Divider } from '@mui/material';
import { useDispatch } from '../../../../../redux/store';
import Iconify from '../../../../../reusable-components/iconify';
import Image from '../../../../../reusable-components/image';
import { getOrderingPunchoutVendorsData } from '../../../../../api/order-sheet';
import { getPunchoutData } from '../../../../../api/punchout';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../../reusable-components/await-button';

export default function VendorOrder() {
  const dispatch = useDispatch();
  const { facilityId = '' } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const categoryId = urlParams.get('categoryId');
  const [data, setData] = useState([]);
  const [category, setCategory] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [noVendors, setNoVendors] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open && !data?.length) {
      getVendorToOrder();
    }
  }, [data?.length, dispatch, open]);

  const getVendorToOrder = async () => {
    const response = await getOrderingPunchoutVendorsData(categoryId);
    if (response.data) {
      setData(response.data);
      if (response.data?.categories?.length) {
        setCategory(response.data.categories[0]);
      }
      if (!response.data?.links?.length) {
        setNoVendors(true);
      }
    }
  };

  const handleGetVendorLink = async (purchasingVendorId) => {
    const response = await getPunchoutData(
      categoryId !== '-1' ? categoryId : category?.id,
      purchasingVendorId,
      facilityId
    );
    if (response.data) {
      const { errorMessage, successUrl } = response.data;
      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        setAnchorEl(null);
        window.open(successUrl, '_blank');
      }
    }
  };

  return (
    <div>
      <Button
        variant="text"
        disableElevation
        sx={{
          textDecoration: 'underline',
        }}
        onClick={handleClick}
        color="info"
        endIcon={<Iconify icon="mingcute:down-line" width={16} height={16} />}
      >
        Punchout Order
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box>
          {data.links?.length ? (
            <>
              {categoryId === '-1' && (
                <Box>
                  <Autocomplete
                    size="small"
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      '& .MuiFormLabelRoot ': 'none',
                      m: 1,
                    }}
                    type="new-password"
                    options={data.categories}
                    autoHighlight
                    blurOnSelect
                    getOptionLabel={(option) => option?.name}
                    value={data?.categories?.find((cat) => cat.id === category?.id) || null}
                    onChange={(event, newValue) => {
                      setCategory(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label=""
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              )}
              {data.links.map((link, index) => (
                <>
                  {index !== 0 && <Divider />}

                  <MenuItem key={index} disableRipple>
                    <AwaitButton onClick={() => handleGetVendorLink(link.purchasingVendorId)} >
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Image
                          alt="attachment"
                          src={link.imageUrl}
                          style={{
                            maxWidth: '100%',
                            maxHeight: 30,
                            objectFit: 'contain',
                          }}
                        />
                        <span style={{ marginRight: 8 }}>
                          <Typography variant="subtitle1" sx={{ ml: 1 }}>
                            {link.vendorName}
                          </Typography>
                        </span>
                        <Iconify color="secondary" icon="ion:open-outline" width={16} height={16} />
                      </Grid>
                    </AwaitButton>
                  </MenuItem>
                </>
              ))}
            </>
          ) : noVendors ? (
            <MenuItem>User does not have permission for this category</MenuItem>
          ) : (
            <MenuItem>Loading...</MenuItem>
          )}
        </Box>
      </StyledMenu>
    </div>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    // marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    overflow: 'auto',
    border: 'solid 1px #c1c9d0',
    '& .MuiMenuItem-root': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
