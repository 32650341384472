import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { SiteRulesView } from '../../../components/dashboard/admin/site-rules';

export default function EmailRecipients() {

  return (
    <>
      <Helmet>
        <title>Admin Site Rules | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <SiteRulesView />
        </Grid>
      </Grid>
    </>
  );
}
