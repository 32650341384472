import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { useDispatch, useSelector } from '../../../../redux/store';
import {
  invoiceGLItemsForm,
  getInvoice,
  removeInvoiceFromPending,
  addInvoiceLog,
} from '../../../../redux/slices/invoicing';
import { markInvoiceAsSent } from '../../../../api/invoicing';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import Iconify from '../../../../reusable-components/iconify';
import { useScopeCheck } from '../../../../reusable-components/scopes';

MoveToProcessed.propTypes = {};
export default function MoveToProcessed() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: { invoiceItemData, invoiceGLItems },
  } = useSelector((state) => state.invoicing);

  const { invoice } = invoiceItemData;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {}, []);
  const move = async () => {
    setIsSubmitting(true);
    const response = await markInvoiceAsSent(id);
    if (response.status === 200) {
      enqueueSnackbar('Invoice moved to processed status', { variant: 'success' });
    }
    setIsSubmitting(false);
  };
  return (
    <>
      <Box>
        <AwaitButton
          variant="outlined"
          color="secondary"
          onClick={move}
          loading={isSubmitting}
          loadingPosition="start"
          startIcon={<DriveFileMoveIcon />}
          disabled={!hasEditAccess}
        >
          Move to processed
        </AwaitButton>
      </Box>
    </>
  );
}
