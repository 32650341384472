import PropTypes from 'prop-types';
import { Typography, Box, TextField } from '@mui/material';

FormText.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  onChange: PropTypes.func,
};

export default function FormText({ value, title, width, name, disabled, fullWidth, onChange, size = 'small' }) {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
          {title}
        </Typography>
        <TextField
          size={size}
          fullWidth={fullWidth}
          name={name}
          disabled={disabled}
          value={value}
          onChange={onChange}
          sx={{
            width,
            mt: 1,
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiFormLabel-root ': { display: 'none' },
          }}
          inputProps={{
            autoComplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
        />
      </Box>
    </>
  );
}
