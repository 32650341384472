import axios from '../../utils/axios';

// GET / Api / VendorCatalog / Vendors;
export async function getVendors() {
  const response = await axios.get('/VendorCatalog/Vendors');
  return response;
}

// GET / Api / VendorCatalog / Categories; vendorId
export async function getCategories(vendorId) {
  const response = await axios.get(`/VendorCatalog/Categories?vendorId=${vendorId}`);
  return response;
}

// GET / Api / VendorCatalog / VendorItems; vendorId categoryId
export async function getVendorItems(vendorId, categoryId) {
  const response = await axios.get(`/VendorCatalog/VendorItems?vendorId=${vendorId}&categoryId=${categoryId}`);
  return response;
}
