import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import { InvoicingSettings } from '../../../components/dashboard/invoicing';
import { useDispatch, useSelector } from '../../../redux/store';
import CustomToolbar from '../../../components/dashboard/invoicing/CustomToolbar';
import { getGLConsolidationFacilities } from '../../../redux/slices/invoicing';

export default function Settings() {
  const dispatch = useDispatch();
  const [activeRows, setActiveRows] = useState([]);

  const customToolbar = useCallback(() => {
    const isFiltered = (filter) => filter === 'settings';
    return <CustomToolbar isFiltered={isFiltered} activeRows={activeRows} />;
  }, [activeRows]);
  useEffect(() => {
    dispatch(getGLConsolidationFacilities());
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoicing | Platform Solutions</title>
      </Helmet>
      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <InvoicingSettings CustomToolbar={customToolbar} setActiveRows={setActiveRows} />
        </Grid>
      </Grid>
    </>
  );
}
