/* eslint-disable react/prop-types */
import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteOrderGuide, updateOrderGuidePricing } from '../../api/order-guides';
import { HOST_API_URL } from '../../config-global';
import { renderColorBox } from '../../utils/cssStyles';
import { cloudinaryFull, cloudinaryThumbnail } from '../../utils/formatCloudinary';
import { fCurrency } from '../../utils/formatNumber';
import { fMonthDayYearShort, fMonthDayTimeYear } from '../../utils/formatTime';
import { ConfirmDialog } from '../confirm-dialog';
import Iconify from '../iconify';
import Image from '../image';
import Lightbox from '../lightbox';
import { ScopeGuard } from '../scopes';
import OrderGuideVendorPreferences from '../../components/dashboard/admin/order-guides/OrderGuideVendorPreferences';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);
const downloadGuide = async (rowId) => {
  //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
  window.open(`${HOST_API_URL}Api/DownloadOrderGuide?orderGuideActivationId=${rowId}`, '_blank');
};
const handleDelete = async (rowId) => {
  const response = await deleteOrderGuide(rowId);
  if (response.status === 200) {
    window.location.reload();
  }
};
const handleRefresh = async (rowId) => {
  await updateOrderGuidePricing(rowId);
};

const facility = {
  field: 'facility',
  headerName: 'Facility',
  flex: 1.5,
  minWidth: 90,
  type: 'customSelect',
};
const group = {
  field: 'group',
  headerName: 'Group',
  flex: 1.5,
  minWidth: 90,
  type: 'customSelect',
};

const active = {
  field: 'active',
  headerName: 'Active',
  minWidth: 50,
  flex: 0.5,
  renderCell: (params) => {
    const { value } = params;
    return value ? (
      <Iconify icon="material-symbols:check-circle-outline" color="primary" />
    ) : (
      <Iconify icon="mdi:close" color="error" />
    );
  },
  type: 'boolean',
  valueOptions: [true, false],
};

const department = {
  field: 'agoraCategory',
  headerName: 'Department',
  minWidth: 100,
  flex: 1,
  renderCell: (params) => renderColorBox(params.row.agoraCategory, 'secondary'),
  valueGetter: (params) => params.row.agoraCategory,
  type: 'customSelect',
};

const category = {
  field: 'category',
  headerName: 'Category',
  flex: 0.7,
  minWidth: 100,
  renderCell: (params) => renderColorBox(params.row.agoraCategory.name, 'secondary'),
  valueGetter: (params) => params.row.agoraCategory.name,
  type: 'customSelect',
  path: 'agoraCategory.name',
};
const agoraCategory = {
  field: 'agoraCategory',
  headerName: 'Category',
  minWidth: 100,
  flex: 0.7,
  renderCell: (params) => renderColorBox(params.row.agoraCategory, 'secondary'),
  valueGetter: (params) => params.row.agoraCategory.name,
  type: 'customSelect',
  path: 'agoraCategory.name',
};

const items = {
  field: 'count',
  headerName: 'Items',
  minWidth: 80,
  flex: 0.5,
};

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Date Created',
  flex: 1,
  minWidth: 80,
  renderCell: (params) => renderIcon(fMonthDayTimeYear(params.value), 'material-symbols:calendar-today-outline'),
  type: 'customDate',
  valueGetter: (params) => new Date(params.value),
};

const actionButtons = (params) => {
  const { locked, agoraCategoryId, facilityId } = params.row;
  return (
    <>
      {' '}
      <Tooltip title="Download" arrow>
        <Box id="action-button" className="action-button">
          <IconButton
            color="info"
            onClick={() => downloadGuide(params.id)}
            id="action-button"
            className="action-button"
          >
            <DownloadIcon id="action-button" className="action-button" />
          </IconButton>
        </Box>
      </Tooltip>
      <ScopeGuard scopes={[' Edit-OrderGuide']} allowAdmin>
        {!locked && (<OrderGuideVendorPreferences orderGuideId={params.row.id} />)}
        {!locked && (
          <ConfirmDialog
            icon="refresh"
            title="Refresh Pricing"
            color="warning"
            content="Are you sure you want to fetch prices for this order guide? This may take some time."
            actionButton="Refresh"
            tooltip="Refresh Pricing"
            action={() => handleRefresh(params.id)}
          />
        )}
        {!locked && (
          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content="Are you sure want to delete?"
            actionButton="Delete"
            action={() => handleDelete(params.id)}
            tooltip="Delete"
          />
        )}
      </ScopeGuard>
    </>
  );
};

const getProductDetails = (params, value) => {
  const productDetails = params.row.activeTempOrderGuide?.[value]
    ? params.row.activeTempOrderGuide
    : params.row.productDetails[0];
  return productDetails[value];
};
const RenderImage = ({ params }) => {
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  const pictures = params.row.activeProductDetail?.pictures || params.row.productDetails?.map((p) => p.pictures);

  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(pictures?.[0] || 'Item3.jpg')}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictures?.map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
};

const picture = {
  field: 'picture',
  headerName: 'Image',
  flex: 0.4,
  renderCell: (params) => <RenderImage params={params} />,
  sortable: false,
  filterable: false,
};

const AvailableCell = ({ id }) => {
  const {
    orderGuide: { orderGuide },
  } = useSelector((state) => state.orderGuides.data);
  const cellIsAvailable = (id, orderGuide) => {
    const available = orderGuide?.find((item) => item.productId === id && item.available);
    if (available) {
      return <Iconify icon="mdi:check" color="primary" width={28} height={28} />;
    }
    return null;
  };
  return cellIsAvailable(id, orderGuide);
};

const DateAdded = ({ id }) => {
  const {
    orderGuide: { orderGuide },
  } = useSelector((state) => state.orderGuides.data);

  const dateAdded = (id, orderGuide) => {
    const item = orderGuide?.find((item) => item.productId === id);
    if (item) {
      return fMonthDayYearShort(item.dateAdded);
    }
    return null;
  };
  return dateAdded(id, orderGuide);
};
const AddedBy = ({ id }) => {
  const {
    orderGuide: { orderGuide },
  } = useSelector((state) => state.orderGuides.data);
  const added = (id, orderGuide) => {
    const item = orderGuide?.find((item) => item.productId === id);
    if (item) {
      return item.addedBy;
    }
    return null;
  };
  return added(id, orderGuide);
};
const available = {
  field: 'available',
  headerName: 'Available',
  flex: 0.3,
  minWidth: 40,
  renderCell: (params) => <AvailableCell id={params.row.id} />,
  sortable: false,
};
const addedBy = {
  field: 'addedBy',
  headerName: 'Added By',
  flex: 1,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <AddedBy id={params.row.id} />
    </Box>
  ),
  sortable: false,
  filterable: false,
};
const addedDate = {
  field: 'dateAdded',
  headerName: 'Added Date',
  flex: 1,
  minWidth: 80,
  type: 'customDate',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <DateAdded id={params.row.id} />
    </Box>
  ),
  filterable: false,
};
const deletedBy = {
  field: 'deletedBy',
  headerName: 'Removed By',
  flex: 1,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <AddedBy id={params.row.id} />
      {params.row.activeTempOrderGuide?.deletedBy}
    </Box>
  ),
  sortable: false,
  filterable: false,
};
const deletedDate = {
  field: 'dateDeleted',
  headerName: 'Deleted Date',
  flex: 1,
  minWidth: 80,
  type: 'customDate',
  renderCell: (params) => (
    <Box component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', }}>
      {fMonthDayTimeYear(params.row.activeTempOrderGuide?.dateDeleted)}
    </Box>
  ),
  filterable: false,
};
const subcategory = {
  field: 'subcategory',
  headerName: 'Subcategory',
  flex: 0.7,
  minWidth: 90,
  renderCell: (params) => params.row.agoraSubcategory.name,
  valueGetter: (params) => params.row.agoraSubcategory.name,
  type: 'customSelect',
  path: 'agoraSubcategory.name',
};

const description = {
  field: 'description',
  headerName: 'Description',
  minWidth: 100,
  flex: 1.3,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const manufacturer = {
  field: 'manufacturer',
  headerName: 'Manufacturer',
  flex: 1,
  minWidth: 90,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {params.row.activeProductDetail?.manufacturer}
    </Box>
  ),
  valueGetter: (params) => params.row.activeProductDetail?.manufacturer,
};
const manufacturerId = {
  field: 'manufacturerId',
  headerName: 'Manufacturer Id',
  flex: 1,
  minWidth: 90,
  type: 'customText',
  renderCell: (params) => params.row.activeProductDetail?.manufacturerId,
  valueGetter: (params) => params.row.activeProductDetail?.manufacturerId,
};
const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  flex: 1,
  minWidth: 90,
  type: 'customText',
  renderCell: (params) => params.row.activeProductDetail?.vendor,
  valueGetter: (params) => params.row.activeProductDetail?.vendor,
};

const templateName = {
  field: 'templateName',
  headerName: 'Name',
  minWidth: 80,
  flex: 1.3,
  type: 'customText',
};

const sku = {
  field: 'vendorItemId',
  headerName: 'SKU',
  minWidth: 90,
  flex: 0.7,
  renderCell: (params) => getProductDetails(params, 'vendorItemId'),
  valueGetter: (params) => getProductDetails(params, 'vendorItemId'),
  type: 'customSelect',
};
const vendorSkus = {
  field: 'productDetails',
  headerName: 'Vendors/SKUs',
  minWidth: 90,
  flex: 0.7,
  renderCell: (params) => (
    <Tooltip
      title={
        <>
          {params.row.productDetails.map((prodDetails) => (
            <Box key={prodDetails.id}>
              {' '}
              {prodDetails.vendor} | {prodDetails.vendorItemId}{' '}
            </Box>
          ))}
        </>
      }
    >
      <Box>
        {params.row.productDetails.slice(0, 2).map((prodDetails) => (
          <Box key={prodDetails.id}>
            {' '}
            {prodDetails.vendor} | {prodDetails.vendorItemId}{' '}
          </Box>
        ))}
      </Box>
    </Tooltip>
  ),
  valueGetter: (params) =>
    params.row.productDetails.map((prodDetails) => `${prodDetails.vendor} | ${prodDetails.vendorItemId}`),
  type: 'customText',
};

const uom = {
  field: 'uom',
  headerName: 'UOM',
  flex: 0.3,
  minWidth: 70,
  renderCell: (params) => {
    const uomAmount = getProductDetails(params, 'uomAmount');
    const uomType = getProductDetails(params, 'uomType');
    return `${uomAmount}/${uomType}`;
  },
  valueGetter: (params) => {
    const uomAmount = getProductDetails(params, 'uomAmount');
    const uomType = getProductDetails(params, 'uomType');
    return `${uomAmount}/${uomType}`;
  },
};

const price = {
  field: 'price',
  headerName: 'Price',
  flex: 0.5,
  minWidth: 75,
  renderCell: (params) => fCurrency(getProductDetails(params, 'price')),
  valueGetter: (params) => fCurrency(getProductDetails(params, 'price')),
};

const actions = {
  field: 'actions',
  headerName: 'Actions',
  flex: 1,
  minWidth: 160,
  renderCell: (params) => actionButtons(params),
  id: 'actions',
  className: 'actions',
};
const id = {
  field: 'id',
  headerName: 'Item Id',
  flex: 0.3,
  minWidth: 60,
  type: 'customSelect',
};

const ORDER_GUIDES = [active, facility, group, department, items, dateAdded, actions];

const ALL_PRODUCTS = [
  picture,
  description,
  sku,
  uom,
  price,
  manufacturer,
  manufacturerId,
  vendor,
  subcategory,
  category,
  id,
  available,
  addedBy,
  addedDate,
];
const INACTIVE_PRODUCTS = [
  picture,
  description,
  sku,
  uom,
  price,
  manufacturer,
  manufacturerId,
  vendor,
  subcategory,
  category,
  id,
  available,
  deletedBy,
  deletedDate,
];
const MANAGE_ITEMS = [picture, description, vendorSkus, subcategory, id];
const TEMPLATES = [templateName, agoraCategory, items, dateAdded];

export { ORDER_GUIDES, INACTIVE_PRODUCTS, ALL_PRODUCTS, TEMPLATES, MANAGE_ITEMS };
