import { useMemo, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Stack,
  Box,
  FormControlLabel,
  Card,
  Switch,
  Grid,
  ListItemText,
  FormGroup,
  Tooltip,
} from '@mui/material';
import { useSelector, useDispatch } from '../../../../redux/store';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import Editor from '../../../../reusable-components/editor';
import { updateUserSettings } from '../../../../redux/slices/user';
import { AwaitButton } from '../../../../reusable-components/await-button';
import UserOrderEmailSettings from './UserOrderEmailSettings';
import { ScopeGuard } from '../../../../reusable-components/scopes';

export default function SettingsTab() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);
  const { settings } = user;

  const [userSettings, setUserSettings] = useState(settings || []);
  const [ticketChanges, setTicketChanges] = useState(false);
  const [gridChanges, setGridChanges] = useState(false);
  // const [resetGrid, setResetGrid] = useState(false);

  const getSettingByAreaId = useMemo(
    () => (areaId) =>
      userSettings?.find((setting) => setting?.areaId === areaId) ?? {
        areaId,
        field: '',
        show: false,
        style: '',
        value: '',
      },
    [userSettings]
  );
  const getGridSettingsByAreaId = useMemo(
    () => (areaId) =>
      userSettings?.find((setting) => setting?.areaId === areaId) ?? {
        areaId,
        field: '',
        show: true,
        style: '',
        value: '',
      },
    [userSettings]
  );

  const updateSettingByAreaId = (areaId, keyPair) => {
    if (!userSettings) return;
    const newSettings = [...userSettings]; // Create a new array by spreading the old one
    const index = newSettings.findIndex((setting) => setting.areaId === areaId);
    if (index >= 0) {
      // Update the found setting by creating a new object that includes the changes
      newSettings[index] = { ...newSettings[index], ...keyPair };
    } else {
      // If the setting doesn't exist, add a new one
      newSettings.push({ areaId, field: '', show: false, style: '', value: '', ...keyPair });
    }
    setUserSettings(newSettings); // Update the state with the new array
  };

  const submitTicketUpdates = async () => {
    const response = await dispatch(updateUserSettings([getSettingByAreaId('AutoSignatureOnTickets')]));
    if (response) {
      enqueueSnackbar('Ticket Settings updated', { variant: 'success' });
      setTicketChanges(false);
    } else enqueueSnackbar('Settings update failed', { variant: 'error' });
  };

  const submitGridUpdates = async () => {
    if (!getGridSettingsByAreaId('GridPersists')?.show) {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('dataGridState')) {
          localStorage.removeItem(key);
        }
      });
      if (!gridChanges) enqueueSnackbar('Grid Settings updated', { variant: 'success' });
    }
    if (gridChanges) {
      const response = await dispatch(updateUserSettings([getGridSettingsByAreaId('GridPersists')]));
      if (response) {
        enqueueSnackbar('Grid Settings updated', { variant: 'success' });
        setGridChanges(false);
      } else enqueueSnackbar('Settings update failed', { variant: 'error' });
    }
  };

  return (<Box>
    <Grid container spacing={3} >
      <Grid item spacing={3} xs={6} >
        <Stack spacing={3}>
          <Card sx={{ p: 3, minWidth: '35vw' }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
              <Grid item xs={4}>
                <ListItemText
                  primary="Grid Customization"
                  secondary="Persist columns and other grid settings"
                  primaryTypographyProps={{ typography: 'h6', mb: 0.5 }}
                  secondaryTypographyProps={{ component: 'span' }}
                />
                <Box>
                  <AwaitButton
                    color="primary"
                    size="medium"
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={submitGridUpdates}
                    disabled={!gridChanges}
                  >
                    Update
                  </AwaitButton>
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Stack spacing={1} sx={{ p: 3, borderRadius: 2, bgcolor: 'background.neutral' }}>
                  <FormGroup>
                    <FormControlLabel
                      label={
                        <>
                          {getGridSettingsByAreaId('GridPersists')?.show
                            ? 'Disable this to refresh grids'
                            : 'Persist Grid Changes'}
                          <Tooltip
                            placement="top"
                            arrow
                            // title=""
                            title={
                              getGridSettingsByAreaId('GridPersists')?.show
                                ? 'When disabled, all grid settings changes will revert back to default settings.'
                                : 'When changing the columns order, width or visibility on any grid, these changes will persist between sessions.'
                            }
                          >
                            <InfoOutlinedIcon color="info" fontSize="small" />
                          </Tooltip>
                        </>
                      }
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={getGridSettingsByAreaId('GridPersists')?.show}
                          onChange={(e) => {
                            setGridChanges(true);
                            updateSettingByAreaId('GridPersists', {
                              show: e.target.checked,
                            });
                          }}
                        />
                      }
                      sx={{
                        m: 0,
                        width: 1,
                        justifyContent: 'space-between',
                      }}
                    />
                    {/* <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Checkbox
                                color="error"
                                checked={resetGrid}
                                onChange={(e) => {
                                  setResetGrid(e.target.checked);
                                }}
                              />
                            }
                            label={
                              <>
                                Reset All Grids
                                <Tooltip
                                  placement="top"
                                  arrow
                                  title="This will reset all grid columns, widths and order back to default."
                                >
                                  <InfoOutlinedIcon color="error" fontSize="small" />
                                </Tooltip>
                              </>
                            }
                            sx={{
                              m: 0,
                              width: 1,
                              justifyContent: 'space-between',
                            }}
                          /> */}
                  </FormGroup>
                </Stack>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ p: 3, minWidth: '35vw' }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
              <Grid item xs={4}>
                <ListItemText
                  primary="Tickets"
                  secondary="Default settings for tickets"
                  primaryTypographyProps={{ typography: 'h6', mb: 0.5 }}
                  secondaryTypographyProps={{ component: 'span' }}
                />
                <Box>
                  <AwaitButton
                    color="primary"
                    size="medium"
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={submitTicketUpdates}
                    disabled={!ticketChanges}
                  >
                    Update
                  </AwaitButton>
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Stack spacing={1} sx={{ p: 3, borderRadius: 2, bgcolor: 'background.neutral' }}>
                  <>
                    <FormGroup>
                      <FormControlLabel
                        label={
                          <>
                            Auto signature on tickets
                            <Tooltip
                              placement="top"
                              arrow
                              title="Chose to automatically add the signature below to the end of all your ticket replies."
                            >
                              <InfoOutlinedIcon color="info" fontSize="small" />
                            </Tooltip>
                          </>
                        }
                        labelPlacement="start"
                        control={
                          <Switch
                            checked={getSettingByAreaId('AutoSignatureOnTickets')?.show || false}
                            onChange={(e) => {
                              setTicketChanges(true);
                              updateSettingByAreaId('AutoSignatureOnTickets', {
                                show: e.target.checked,
                              });
                            }}
                            sx={{
                              m: 0,
                              justifyContent: 'space-between',
                            }}
                          />
                        }
                        sx={{
                          m: 0,
                          width: 1,
                          justifyContent: 'space-between',
                        }}
                      />
                    </FormGroup>
                    <Editor
                      basic
                      simple
                      removeHyperLink
                      disabled={!getSettingByAreaId('AutoSignatureOnTickets')?.show}
                      id="ticket-reply"
                      value={
                        getSettingByAreaId('AutoSignatureOnTickets')?.value ??
                        `<p><br><br></p><p>Thanks,</p><p> ${user.firstName}</p>`
                      }
                      onChange={(e) => {
                        setTicketChanges(true);
                        updateSettingByAreaId('AutoSignatureOnTickets', { value: e });
                      }}
                      placeholder="Signature"
                      sx={{ flexGrow: 1, borderColor: 'transparent', height: '100%' }}
                    />
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Stack>
      </Grid>
      <Grid item spacing={3} xs={6} >
        <Stack spacing={3}>
          <ScopeGuard scopes={["Submit-Order", "Edit-Order"]} >
            <UserOrderEmailSettings user={user} />
          </ScopeGuard>
        </Stack>
      </Grid>
    </Grid>
  </Box>);
}
