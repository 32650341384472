import { useSelector } from 'react-redux';

/**
 * A custom hook that checks if the current user has any of the specified scopes.
 *
 * @param {string[]} scopes - An array of scopes to check for.
 * @param {boolean} allowAdmin - If true, returns true if the user is an Admin or Super-Admin.
 * @param {boolean} allowSuperAdmin - If true, returns true if the user is a Super-Admin.
 * @returns {boolean} - True if the user has any of the scopes, or is an Admin/Super-Admin when allowAdmin is true.
 */
export const useScopeCheck = (scopes, allowAdmin = false, allowSuperAdmin = false) => {
  const { roles } = useSelector((state) => state.user.user);

  if (allowSuperAdmin && roles.includes('Super-Admin')) {
    return true;
  }

  if (allowAdmin && (roles.includes('Admin') || roles.includes('Super-Admin'))) {
    return true;
  }

  return scopes.some((scope) => roles.includes(scope));
};
