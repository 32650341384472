// remember to import this file in index.js

export default function DataGrid() {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          fontWeight: '600',
          margin: 'auto',
          width: '100%',
          borderRadius: '10px 10px 0px 0px',
          '& .MuiDataGrid-columnHeaders': {
            position: 'sticky',
            zIndex: 999,
          },
          '& .MuiDataGrid-main': {
            boxShadow: '0px 1.5px 0px 1.5px #208ee05c',
            borderRadius: '10px 10px 0px 0px',
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '0!important',
            '&::-webkit-scrollbar': {
              width: '10px !important',
              height: '10px !important',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888 !important',
              borderRadius: '5px !important',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555 !important',
            },
          },
        },
        footerContainer: { display: 'none' },
        columnSeparator: { display: 'none' },
        columnHeaders: {
          border: 'none',
          maxHeight: '40px !important',
          minHeight: '40px !important',
          lineHeight: '40px !important',
          backgroundColor: '#208ee0',
          fontSize: '0.7rem',
          zIndex: 999,
          // MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-13fmiiq-MuiButtonBase-root-MuiIconButton-root
          '& .MuiButtonBase-root': {
            padding: '0px',
            minWidth: '0px',
            minHeight: '0px',
            width: '20px',
            height: '20px',
            borderRadius: '0px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          '& .MuiDataGrid-columnHeader': {
            position: 'relative', // Needed for absolute positioning of the separator
            // ... other styles ...
            '&:hover': {
              '& .MuiDataGrid-columnSeparator': {
                display: 'block', // Show the separator
                position: 'absolute',
                top: '80%', // Adjust this value as needed
                height: '1px',
                transform: 'translateY(-50%)', // Center it vertically
              },
            },
          },
        },
        pinnedColumnHeaders: {
          backgroundColor: '#208ee0',
          lineHeight: '40px !important',
          height: '40px !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        columnHeader: {
          color: '#fff',
          paddingRight: '16px',
        },
        sortIcon: {
          color: '#fff !important',
        },
        filterIcon: {
          color: '#b128bc !important',
        },
        row: {
          minHeight: '45px !important',
          backgroundColor: '#fff',
          borderRadius: 0.5,
          borderBottom: '2px solid #208ee05c',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          // width: 'calc(100% + 1px)',
          '&:hover': {
            // backgroundColor: (theme) => theme.palette.secondary.light,
            cursor: 'pointer',
            backgroundColor: 'rgba(32, 142, 224, 0.04)',
            borderRadius: 0.5,
            color: '#000',
          },
        },
        cell: {
          border: 'none',
          ':focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-cell--editing': {
            border: '1px solid #b128bc',
          },
        },
        toolbarContainer: {
          '& .MuiInputBase-root': {
            borderRadius: '4px',
            border: `solid 1px #404042`,
          },
        },
        main: {
          backgroundColor: '#f1f3f4CC',
        },
      },
    },
  };
}
