import { Box, Button, Card, Grid, IconButton, Tooltip, Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { postAddTicket, postAddTicketImage } from '../../../../api/tickets';
import { useTabs } from '../../../../context/TabContext';
import { addLinkedTicketsToOrder } from '../../../../redux/slices/orders';
import {
  getFacilitiesAndUsersList,
  getTicketData,
  getTicketMessages,
  sendTicketMessage,
} from '../../../../redux/slices/tickets';
import { useDispatch, useSelector } from '../../../../redux/store';
import { CustomAvatar, CustomAvatarGroup } from '../../../../reusable-components/custom-avatar';
import Editor from '../../../../reusable-components/editor';
import Iconify from '../../../../reusable-components/iconify';
import Scrollbar from '../../../../reusable-components/scrollbar';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import { useSettingsCheck } from '../../../../reusable-components/settings';
import NewUserAddTicket from '../../tickets/ticket/NewUserAddTicket';
import TicketMessageList from '../../tickets/ticket/TicketMessageList';

CompletedOrderTicket.propTypes = {
  setOpenTicket: PropTypes.func,
  openTicket: PropTypes.string,
  setAddItemsToTicket: PropTypes.func,
  addItemsToTicket: PropTypes.string,
  selectedRows: PropTypes.array,
  setSelected: PropTypes.func,
  setCurrentTicket: PropTypes.func,
  currentTicket: PropTypes.number,
};

export default function CompletedOrderTicket({
  setOpenTicket,
  openTicket,
  selectedRows,
  setAddItemsToTicket,
  addItemsToTicket,
  setSelected,
  currentTicket,
  setCurrentTicket,
}) {
  const fileInputRef = useRef(null);
  const { addNewTabAndNavigate } = useTabs();
  const { id = '' } = useParams();
  const dispatch = useDispatch();
  const { orderById } = useSelector((state) => state.orders || {});
  const { submittedBy = { name: '', email: '' }, linkedTickets = [], facilityId } = orderById[id] || {};
  const signature = useSettingsCheck('AutoSignatureOnTickets');
  const [currentTicketMessage, setCurrentTicketMessage] = useState('');
  const [ticketItems, setTicketItems] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const { user } = useSelector((state) => state.user);

  const { users: usersListById } = useSelector((state) => state.tickets.data.ticketInfoById[currentTicket] || []);

  const {
    data: { users: usersList },
  } = useSelector((state) => state.tickets);

  const { users: currentUsers } = useSelector((state) => state.tickets.data.ticketById[currentTicket]) || [];

  const [newUsers, setNewUsers] = useState();

  useEffect(() => {
    dispatch(getFacilitiesAndUsersList());
  }, []);

  useEffect(() => {
    if (currentTicket) {
      dispatch(getTicketData(currentTicket));
    }
  }, [currentTicket]);

  const usersListLookup = useMemo(() => {
    if (!usersList && !usersListById) return {};
    if (!usersList) {
      return usersListById.reduce((acc, user) => {
        acc[user.id.toLowerCase()] = user.value;
        return acc;
      }, {});
    }
    if (!usersListById) {
      return usersList.reduce((acc, user) => {
        acc[user.id.toLowerCase()] = user.value;
        return acc;
      }, {});
    }
    return [...usersList, ...usersListById].reduce((acc, user) => {
      acc[user.id.toLowerCase()] = user.value;
      return acc;
    }, {});
  }, [usersList, usersListById]);

  useEffect(() => {
    const uniqueUsers = currentUsers?.reduce((acc, user) => {
      if (!acc.some((u) => u.id === user.userEmail)) {
        acc.push({
          id: user.userEmail,
          value: `${
            user.userName || usersList?.find((ul) => ul.id.toLowerCase() === user.userEmail)?.value || user.userEmail
          }`,
        });
      }
      return acc;
    }, []);

    setNewUsers(
      uniqueUsers?.map((u) => ({
        value: usersListLookup[u.id.toLowerCase()] || u.value || u.id,
        id: u.id,
      }))
    );
  }, [currentTicket, currentUsers, usersList]);

  const handleSetOpenTicket = (newValue) => {
    setOpenTicket(newValue);
  };
  const listOfUsers = async (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const mentionElements = doc.querySelectorAll('span.mention');
    const users = Array.from(mentionElements).map((mention) => mention.getAttribute('data-id'));
    return users;
  };

  const handleSend = async () => {
    if (!currentTicketMessage && attachments?.length === 0) return;
    const usersList = await listOfUsers(currentTicketMessage);
    const currentUsers = newUsers?.map((t) => t.id);
    const combinedUsers = [...usersList, ...currentUsers];

    setNewUsers(
      combinedUsers?.map((u) => ({
        value: u?.value || usersListLookup[u?.id?.toLowerCase()] || usersListLookup[u?.toLowerCase() || u?.id || u],
        id: u?.id || u,
      }))
    );

    dispatch(
      sendTicketMessage({
        message: currentTicketMessage,
        ticketNumber: currentTicket,
        files: attachments,
        notify: true,
        users: combinedUsers?.map((t) => t),
        sentBy: user.email,
        fullName: `${user.firstName} ${user.lastName}`,
      })
    );

    setCurrentTicketMessage(signature?.show ? signature?.value : '');
    setAttachments([]);
  };

  const addSubticket = async () => {
    try {
      const response = await postAddTicket({
        ExtraInfo: `Ticket order ${id}`,
        message: '',
        ticketOrderId: id,
        FacilityId: facilityId,
        files: attachments,
        notify: true,
        Users: [user?.email, submittedBy?.email],
      });
      if (response.data.id) {
        dispatch(addLinkedTicketsToOrder({ orderId: id, idToAdd: response.data.id }));
        setCurrentTicketMessage(signature?.show ? signature?.value : '');
        setAttachments([]);
        handleSetOpenTicket('open');
        setCurrentTicket(response.data.id);
        setAddItemsToTicket(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedRows && selectedRows?.length) {
      const newItems = selectedRows
        .filter((row) => !ticketItems.some((item) => item.productId === row.productId || item.productId === row.id))
        .map((row) => ({
          productId: row.productId === 0 ? row.id : row.productId,
          hyperlink: `\n\n<li><a href="/dashboard/admin/catalog/${row.productId}" rel="noopener noreferrer" target="_blank">PS# ${row.productId}</a>-${row.description} | Qty: ${row.quantity}</li><br><br>`,
        }));

      // If there are any new items, add them to ticketItems state and append them to the currentTicket
      if (newItems.length) {
        setTicketItems((prevItems) => [...prevItems, ...newItems]);
        const newHyperlinks = newItems.map((item) => item.hyperlink).join('');
        setCurrentTicketMessage((prevTicket) => newHyperlinks + prevTicket);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    if (currentTicket !== 0) {
      dispatch(getTicketMessages({ id: currentTicket }));
    }
  }, [dispatch, id, currentTicket]);

  const handleChangeMessage = (value) => {
    value = value.replace(/<h1>/g, '<b>').replace(/<\/h1>/g, '</b>');
    value = value.replace(/<h2>/g, '<b>').replace(/<\/h2>/g, '</b>');
    value = value.replace(/<h3>/g, '<b>').replace(/<\/h3>/g, '</b>');
    value = value.replace(/<h4>/g, '<b>').replace(/<\/h4>/g, '</b>');
    value = value.replace(/<h5>/g, '<b>').replace(/<\/h5>/g, '</b>');
    value = value.replace(/<h6>/g, '<b>').replace(/<\/h6>/g, '</b>');

    if (value.includes('<img src=')) {
      const imgSrcRegex = /<img src="([^"]*)"/g;
      const imgSrcMatch = value.matchAll(imgSrcRegex);
      const imgSrcArray = Array.from(imgSrcMatch, (match) => ({ src: match[1] }));
      imgSrcArray.forEach(async (img) => {
        if (!img.src.includes('//res.cloudinary.com/agoraserv')) {
          const imgUrl = await postAddTicketImage(img.src);
          value = value.replace(img.src, imgUrl.data);
          setCurrentTicketMessage(value);
        }
      });
    }
    setCurrentTicketMessage(value);
  };

  const handleFileInputChange = (event) => {
    const maxAllowedFiles = 5;
    if (
      event.target.files.length > maxAllowedFiles ||
      event.target.files.length + attachments?.length > maxAllowedFiles
    ) {
      alert(`Only ${maxAllowedFiles} files can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    const { files } = event.target;
    const fileList = [];
    for (let i = 0; i < files.length; i++) {
      if (attachments?.some((x) => x.name === files[i].name)) {
        alert(`File ${files[i].name} already exists.`);
      } else fileList.push(files[i]);
    }
    setAttachments((prevAttachments) => {
      if (prevAttachments === undefined) {
        return fileList;
      }
      return [...prevAttachments, ...fileList];
    });
  };

  const removeFile = (file) => () => {
    setAttachments((prevAttachments) => prevAttachments.filter((x) => x !== file));
  };

  return (
    <Box
      sx={{
        mt: 2,
        ml: 3,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        border: openTicket !== 'close' && 'solid 1px #208ee0',
        borderTop: openTicket !== 'close' && 'solid 10px #208ee0',
        borderRadius: '7px',
        zIndex: 2,
      }}
    >
      {openTicket !== 'close' && (
        <Card sx={{ p: 0.5 }}>
          <Box>
            <IconButton
              onClick={() => handleSetOpenTicket(openTicket === 'minimize' ? 'open' : 'minimize')}
              sx={{
                position: 'absolute',
                right: 20,
                top: 0,
                color: (theme) => theme.palette.info.main,
                ':hover': {
                  color: (theme) => theme.palette.info.dark,
                  backgroundColor: (theme) => theme.palette.info.lighter,
                },
              }}
            >
              <Iconify icon={openTicket === 'minimize' ? 'eva:expand-fill' : 'mdi:minimize'} width={16} height={16} />
            </IconButton>

            <IconButton
              onClick={() => {
                handleSetOpenTicket('close');
                setAddItemsToTicket(false);
                setCurrentTicketMessage('');
                setTicketItems([]);
                setSelected([]);
              }}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                color: (theme) => theme.palette.error.main,
                ':hover': {
                  color: (theme) => theme.palette.error.dark,
                  backgroundColor: (theme) => theme.palette.error.lighter,
                },
              }}
            >
              <Iconify icon="ic:sharp-close" width={16} height={16} />
            </IconButton>
          </Box>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Typography variant="body2" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.primary.main }}>
              Ticket order {id}
            </Typography>
          </Grid>
          {openTicket !== 'minimize' && (
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
              <Tooltip title={addItemsToTicket ? 'Close selection boxes' : 'Add items to ticket'} placement="top">
                <IconButton
                  color={addItemsToTicket ? 'warning' : 'secondary'}
                  onClick={() => setAddItemsToTicket(!addItemsToTicket)}
                >
                  <Iconify icon="lucide:combine" width={16} height={16} />
                </IconButton>
              </Tooltip>
              {currentTicket !== 0 && (
                <Tooltip title="Open ticket in new tab" placement="top">
                  <IconButton color="info" onClick={() => addNewTabAndNavigate(`/dashboard/tickets/${currentTicket}`)}>
                    <Iconify icon="majesticons:open-line" width={16} height={16} sx={{ ml: -1 }} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}
          <Box
            sx={{ display: 'flex', flexDirection: 'column', maxHeight: openTicket === 'minimize' ? '20vh' : '70vh' }}
          >
            <CustomAvatarGroup size="small" sx={{ justifyContent: 'left' }}>
              {newUsers?.map((user, index) => (
                <CustomAvatar name={user?.value || user?.id} email={user?.id} key={index} />
              ))}
              <NewUserAddTicket newUsers={newUsers} setNewUsers={setNewUsers} currentTicket={currentTicket} />
            </CustomAvatarGroup>
            {currentTicket !== 0 && (
              <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <Scrollbar sx={{ maxHeight: openTicket === 'minimize' ? '10vh' : '60vh' }}>
                  <TicketMessageList hideAvatar ticketId={currentTicket} />
                </Scrollbar>
              </Box>
            )}

            {usersListById && usersListById?.length > 0 && (
              <Editor
                attachFile
                verySimple
                id="ticket-reply"
                value={currentTicketMessage}
                onChange={handleChangeMessage}
                placeholder="Type a message"
                sx={{ flexGrow: 1, borderColor: 'transparent' }}
                users={usersListById}
                sendIcon
                handleSend={handleSend}
                handleFileInputChange={handleFileInputChange}
              />
            )}
            <Stack direction="row" alignItems="flex-start">
              {attachments.length > 0 &&
                attachments?.map((file) => (
                  <FileThumbnail
                    key={file.name}
                    file={file}
                    tooltip
                    sx={{ width: 64, height: 64 }}
                    onRemove={removeFile(file)}
                  />
                ))}
            </Stack>
          </Box>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
        </Card>
      )}
      <Grid
        item
        sx={{
          backgroundColor: openTicket !== 'close' && '#fff',
        }}
      >
        {linkedTickets?.length > 0 &&
          linkedTickets?.map((ticket) => (
            <Button
              key={ticket}
              onClick={() => {
                handleSetOpenTicket('open');
                setCurrentTicket(ticket);
              }}
              size="small"
              sx={{ mr: 1, mt: 1 }}
              startIcon={<Iconify icon="icomoon-free:bubble" width={20} height={20} sx={{ color: '#fff' }} />}
              variant="contained"
              color={currentTicket === ticket ? 'info' : 'secondary'}
            >
              {ticket}
            </Button>
          ))}

        <Button
          onClick={addSubticket}
          size="small"
          sx={{ mr: 1, mt: 1 }}
          startIcon={<Iconify icon="fa6-solid:plus" width={20} height={20} sx={{ color: '#fff' }} />}
          variant="contained"
          color="secondary"
        >
          Ticket
        </Button>
      </Grid>
    </Box>
  );
}
