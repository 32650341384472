import { Box } from '@mui/material';
import { fMonthDayTimeYear } from '../../utils/formatTime';
import Iconify from '../iconify';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);


const message = {
  field: 'message',
  headerName: 'Type',
  flex: .7,
  minWidth: 40,

};
const vendor = {
  field: 'vendorName',
  headerName: 'Vendor',
  flex: 1,
  minWidth: 100,

};
const facility = {
  field: 'facility',
  headerName: 'Facility',
  flex: 1,
  minWidth: 100,

};

const shipDate = {
  field: 'date',
  headerName: 'Date',
  flex: 1,
  minWidth: 110,
  renderCell: (params) => renderIcon(fMonthDayTimeYear(params.value), 'material-symbols:calendar-today-outline'),
  type: 'customDate',
  valueGetter: (params) => new Date(params.value),
};

const SHIP_DAYS = [
  message,
  vendor,
  facility,
  shipDate,
];


export { SHIP_DAYS };
