import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import { useSelector } from '../../../redux/store';
import RentalOrdersView from '../../../components/dashboard/rentals/RentalOrdersView';

export default function CompletedRentals() {
  const {
    data: { cart },
  } = useSelector((state) => state.rentals);
  return (
    <>
      <Helmet>
        <title>Previous Rentals | Platform Solutions</title>
      </Helmet>

      <RentalOrdersView orderStatus="Previous" />
    </>
  );
}
