import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Menu, Typography, MenuItem, Button } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Iconify from '../../../../reusable-components/iconify';
import { getExcelTypeData } from '../../../../api/excel';

ExcelsDropdown.propTypes = {
  selectedOption: PropTypes.object,
  setSelectedOption: PropTypes.func,
  setTypeData: PropTypes.func,
  options: PropTypes.array,
};

export default function ExcelsDropdown({ selectedOption, setSelectedOption, setTypeData, options }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getExcelData = async (id) => {
    const response = await getExcelTypeData(selectedOption.id);
    if (response.status === 200) {
      setTypeData(response.data);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      getExcelData(selectedOption.id);
    }
  }, [selectedOption]);

  return (
    <>
      <Button
        size="small"
        sx={{ ml: 3 }}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        color="primary"
        onClick={handleClick}
        endIcon={<Iconify icon="material-symbols:keyboard-arrow-down" width={24} height={24} />}
      >
        <Typography variant="subtitle1" color="primary" sx={{ textTransform: 'capitalize,' }}>
          {selectedOption?.type || 'Loading Options...'}
        </Typography>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((opt) => (
          <MenuItem
            key={opt.reportId}
            disableRipple
            onClick={() => {
              setSelectedOption(opt);
              handleClose();
            }}
          >
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
              <Typography variant="subtitle1" sx={{ textTransform: 'capitalize,' }}>
                {opt.type}
              </Typography>
              <Typography variant="caption">{opt.description}</Typography>
            </Grid>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{
      ml: 10,
      mt: -1,
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.primary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
