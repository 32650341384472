import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useSelector } from '../../../redux/store';
import { COMPLETED_ORDERS } from '../../../reusable-components/datagrid/orderColumns';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import CustomToolbar from './CustomToolbar';
import TicketViewModal from './TicketViewModal';
import { useTabs } from '../../../context/TabContext';
import ManagerChipsWrapper from '../../../reusable-components/datagrid/ManagerChipsWrapper';

export default function CompletedOrdersView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showSelection, setShowSelection] = useState('');
  const { addNewTabAndNavigate } = useTabs();
  const [data, setData] = useState([]);

  const {
    data: { previousOrders },
  } = useSelector((state) => state.orders);
  const isLoading = useSelector((state) => state.orders.isLoading);
  const [activeRows, setActiveRows] = useState([]);
  useEffect(() => {
    setData(previousOrders);
  }, [searchParams, previousOrders]);

  const customToolbar = useCallback(() => {
    const isFiltered = (filter) => filter === searchParams.get('filter');
    return (
      <CustomToolbar
        setShowSelection={setShowSelection}
        showSelection={showSelection}
        isFiltered={isFiltered}
        activeRows={activeRows}
      />
    );
  }, [searchParams, showSelection, activeRows]);

  const messageCount = {
    field: 'messageCount',
    headerName: 'Notes',
    minWidth: 60,
    flex: 0.5,
    renderCell: (params) => <TicketViewModal params={params} />,
  };

  const manager = ManagerChipsWrapper(null, 'Orders');
  const columns = [...COMPLETED_ORDERS, manager, messageCount];

  const rowClick = (id) => {
    navigate(`/dashboard/orders/${id}?filter=${searchParams.get('filter')}`);
  };
  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="completed-orders-view"
          boxSX={{ height: 'calc(100vh - 240px)' }}
          applyQuickFilter
          data={data}
          gridColumns={columns}
          sort={[{ field: 'dateApproved', sort: 'desc' }]}
          getRowId={(row) => row.id}
          onRowClick={(params, e) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            if (e.deleteChip || e.addChip || e.openTicket || e.combine || e.confirmDialog) return;
            if (e.navigateToTickets) {
              addNewTabAndNavigate(`/dashboard/tickets/${e.navigateToTickets}`);
              return;
            }
            rowClick(params.row.id);
          }}
          disableRowSelectionOnClick
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          setActiveRows={setActiveRows}
        />
      </Grid>
    </>
  );
}
