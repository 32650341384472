import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import SplitPDF from './SplitPDF';
import { useSelector } from '../../../redux/store';
import Download from './Download';
import OpenInNew from './OpenInNew';
import PageNavigator from './PageNavigator';
import Print from './Print';
import Rotate from './Rotate';
import Zoom from './Zoom';

Toolbar.propTypes = {
  numPages: PropTypes.number,
  zoom: PropTypes.number,
  setZoom: PropTypes.func,
  setRotation: PropTypes.func,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  blobUrl: PropTypes.blobUrl | undefined,
};
export default function Toolbar({ numPages, zoom, setZoom, pageNumber, setPageNumber, setRotation, blobUrl }) {
  const { sourceMethod } = useSelector((state) => state.invoicing.data.invoiceItemData.invoice);


  return (
    <Box>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={7}>
          <Grid container alignItems={'center'}>
            <Grid item xs={5}>
              <Zoom zoom={zoom} setZoom={setZoom} />
            </Grid>
            <Grid item>
              <Rotate setRotation={setRotation} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Download blobUrl={blobUrl} />
            </Grid>
            <Grid item>
              <Print blobUrl={blobUrl} />
            </Grid>

            <Grid item>
              <OpenInNew blobUrl={blobUrl} />
            </Grid>
            {sourceMethod === "Manual" &&
              <Grid item>
                <SplitPDF blobUrl={blobUrl} />
              </Grid>
            }
            <Grid item>
              <PageNavigator numPages={numPages} pageNumber={pageNumber} setPageNumber={setPageNumber} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
}
