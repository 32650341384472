import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Box,
  Button,
  TextField,
  DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from '../../../../redux/store';
import { postVendorBookingAccountForm } from '../../../../api/vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import FormDropdown from '../../../../reusable-components/form/FormDropdown';
import FormText from '../../../../reusable-components/form/FormText';
import { AwaitButton } from '../../../../reusable-components/await-button';

BookingAccountModal.propTypes = {
  fetchData: PropTypes.func,
  edit: PropTypes.bool,
  row: PropTypes.object,
  bookingData: PropTypes.array,
};

export default function BookingAccountModal({ fetchData, edit, row, bookingData }) {
  const {
    data: { vendors },
  } = useSelector((state) => state.vendors);
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    bookingVendor: '',
    vendor: '',
    account: '',
  });

  const handleSave = async () => {
    const response = await postVendorBookingAccountForm(value, edit ? row?.id : null);
    if (response) {
      enqueueSnackbar('Booking account added successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to add booking account', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      bookingVendor: edit ? { id: row.bookingVendorId, label: row.bookingVendor } : '',
      vendor: edit ? { id: row.vendorId, label: row.vendor } : '',
      account: edit ? row.account : '',
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      bookingVendor: '',
      vendor: '',
      account: '',
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          sx={{ ml: 1, mr: 1 }}
          size="small"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Booking Account
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {edit ? 'Edit' : 'Add'} Booking Account
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormDropdown
                  title="Booking Vendor"
                  fullWidth
                  size="small"
                  value={value.bookingVendor}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      bookingVendor: newValue,
                    });
                  }}
                  options={bookingData?.map((type) => ({ id: type.value, label: type.label }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormDropdown
                  title="Vendor"
                  fullWidth
                  size="small"
                  value={value.vendor}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      vendor: newValue,
                    });
                  }}
                  options={vendors?.map((type) => ({ id: type.id, label: type.name }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormText
                  title="Account Number"
                  fullWidth
                  value={value.account}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      account: e.target.value,
                    })
                  }
                />
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.vendor === '' || value.bookingVendor === '' || value.account === ''}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            {edit ? 'Save' : 'Add Account'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
