import PropTypes from 'prop-types';
import { Typography, Box, TextField, Grid } from '@mui/material';
import { fMonthDayYearShort } from '../../utils/formatTime';

FormTextWithValue.propTypes = {
  value: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  flex: PropTypes.number,
  isDate: PropTypes.bool,
  required: PropTypes.bool,
};

export default function FormTextWithValue({
  value,
  title,
  name,
  setValue,
  disabled,
  flex,
  size = 'small',
  required = false,
}) {
  return (
    <>
      <Grid item xs={flex}>
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
            {title}
          </Typography>
          <TextField
            error={required ? !value[name] : false}
            size={size}
            fullWidth
            name={name}
            disabled={disabled}
            value={value[name]}
            onChange={(e) =>
              setValue({
                ...value,
                [name]: e.target.value,
              })
            }
            // sx={{
            //   '& .MuiInputBase-root.Mui-disabled': {
            //     color: 'black !important', //this is not working
            //   },
            // }}
          />
        </Box>
      </Grid>
    </>
  );
}
