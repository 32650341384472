import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from '../../../redux/store';
import { STATEMENTS } from '../../../reusable-components/datagrid/invoiceColumns';
import { SkeletonDataGrid } from '../../../reusable-components/skeleton/SkeletonDataGrid';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { getStatementsInvoicing } from '../../../redux/slices/invoicing';
import { createColumns, CustomFilter } from '../../../reusable-components/datagrid/custom-filters';
import { useFilterModel } from '../../../hooks/useFilterModel';
import { useSnackbar } from '../../../reusable-components/snackbar';
import StatementInfoViewModal from './StatementInfoViewModal';
import { ScopeGuard, useScopeCheck } from '../../../reusable-components/scopes';
import { deleteInvoiceStatement } from '../../../api/invoicing';
import { HOST_API_URL } from '../../../config-global';
import Iconify from '../../../reusable-components/iconify';

export default function StatementView({ CustomToolbar, setActiveRows }) {
  const hasFacilityAccess = useScopeCheck(['Facility-Manager'], true);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const section = location.pathname.split('/').pop();
  const [data, setData] = useState([]);

  const {
    data: { statements, vendors },
  } = useSelector((state) => state.invoicing);
  const isLoading = useSelector((state) => state.invoicing.isLoading);

  useEffect(() => {
    if (section === 'complete') {
      setData(statements.statements?.filter((st) => st.complete === true));
    } else if (section === 'incomplete') {
      setData(statements.statements?.filter((st) => st.complete === false));
    }
  }, [searchParams, statements, section]);

  const handleDelete = async (id) => {
    const response = await deleteInvoiceStatement(id);
    if (response.status === 200) {
      enqueueSnackbar('Statement deleted successfully', { variant: 'success' });
      dispatch(getStatementsInvoicing());
    }
  };
  const ActionButtons = (params) => {
    const { id, file } = params.row;
    const fileExtension = file?.split('.').pop();

    return (
      <>
        <>
          <StatementInfoViewModal row={params.row} />
          <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
            <ConfirmDialog
              icon="delete-outline"
              title="Delete"
              color="error"
              content={`Are you sure you want to delete this statement?`}
              actionButton="Delete"
              action={() => handleDelete(id)}
            />
          </ScopeGuard>

          <>
            <IconButton
              color="secondary"
              onClick={() => {
                window.open(
                  `${HOST_API_URL}Api/Invoices/DownloadInvoiceStatementFile?id=${id}&mainFile=true`,
                  '_blank'
                );
              }}
            >
              <Iconify icon={'mdi:tray-arrow-down'} />
            </IconButton>
            {fileExtension === 'pdf' && (
              <Tooltip arrow title="View Statement">
                <IconButton
                  color="warning"
                  onClick={() => {
                    const modal = document.createElement('div');
                    modal.style.position = 'fixed';
                    modal.style.zIndex = '100000';
                    modal.style.left = '0';
                    modal.style.bottom = '0';
                    modal.style.width = '100%';
                    modal.style.height = '100%';
                    modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    modal.style.display = 'flex';
                    modal.style.justifyContent = 'center';
                    modal.style.alignItems = 'center';

                    // Create an iframe for the PDF
                    const iframe = document.createElement('iframe');
                    iframe.style.width = '70%';
                    iframe.style.height = '100%';
                    iframe.src = `${HOST_API_URL}Api/Invoices/ViewStatementFile?id=${id}&mainFile=true`;

                    // Append iframe to modal
                    modal.appendChild(iframe);

                    // Add a close functionality to the modal
                    modal.addEventListener('click', (e) => {
                      if (e.target === modal) {
                        document.body.removeChild(modal);
                      }
                    });
                    document.body.appendChild(modal);
                  }}
                >
                  <Iconify icon={'mdi:eye-outline'} />
                </IconButton>
              </Tooltip>
            )}
          </>
        </>
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };
  const purchasingVendorId = {
    field: 'vendor',
    headerName: 'Vendor',
    minWidth: 100,
    flex: 0.5,
    type: 'customSelect',
    renderCell: (params) => vendors?.find((v) => v.value === params.row.purchasingVendorId)?.label,
    valueGetter: (params) => vendors?.find((v) => v.value === params.row.purchasingVendorId)?.label,
  };

  const columns = createColumns([purchasingVendorId, ...STATEMENTS]);
  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="invoicing-statement-view"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          gridColumns={[...columns, actions]}
          sort={[{ field: 'dateSaved', sort: 'desc' }]}
          CustomLeftToolbar={CustomToolbar}
          setActiveRows={setActiveRows}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
