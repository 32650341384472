import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Autocomplete, Typography, Button, DialogActions, TextField } from '@mui/material';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';
import { formatMoney } from '../../../../utils/formatNumber';
import { orderEmailRuleForm } from '../../../../api/account';

UserOrderEmailSettingForm.propTypes = {
  fetchData: PropTypes.func,
  editRow: PropTypes.object,
  vendors: PropTypes.array,
  facilities: PropTypes.array,
  ruleTypes: PropTypes.array,
  categories: PropTypes.array,
  openForm: PropTypes.bool,
  close: PropTypes.func,
};

export default function UserOrderEmailSettingForm({ fetchData, vendors, ruleTypes, facilities, categories, editRow, openForm, close }) {
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    ruleType: '',
    vendor: '',
    category: '',
    facility: '',
    totalOver: '',
    itemPriceOver: '',
  });

  useEffect(() => setValue({
    ruleType: editRow?.ruleTypeId ? { id: editRow.ruleTypeId, label: ruleTypes.find(r => r.value === editRow.ruleTypeId).label } : '',
    vendor: editRow?.vendorId ? { id: editRow.vendorId, label: editRow.vendor } : editRow ? { id: null, label: "All Vendors" } : '',
    facility: editRow?.facilityId ? { id: editRow.facilityId, label: editRow.facility } : editRow ? { id: null, label: "All Facilities" } : '',
    category: editRow?.categoryId ? { id: editRow.categoryId, label: editRow.category } : editRow ? { id: null, label: "All Categories" } : '',
    totalOver: editRow?.totalOver ? editRow.totalOver.toFixed(2) : '',
    itemPriceOver: editRow?.itemPriceOver ? editRow.itemPriceOver.toFixed(2) : '',
  }), [editRow, openForm])

  const handleSave = async () => {
    try {
      const response = await orderEmailRuleForm({
        ruleTypeId: value.ruleType?.id,
        facilityId: value.facility?.id,
        categoryId: value.category?.id,
        vendorId: value.vendor?.id,
        totalOver: value.totalOver,
        itemPriceOver: value.itemPriceOver,
        id: editRow?.id ?? 0
      });
      if (!response.data) {
        enqueueSnackbar("Something has gone wrong", { variant: "error" });
        return;
      }
      enqueueSnackbar(`Rule ${editRow ? "updated" : "added"}`, { variant: "success" });
      fetchData();
      close();
    } catch { enqueueSnackbar("Something has gone wrong", { variant: "error" }); }
  };


  return <Dialog open={openForm} onClose={close} maxWidth="sm" fullWidth>
    <DialogTitle sx={{ textAlign: 'center' }} >
      {editRow ? 'Edit' : 'Add'} Order Email Rule
      <CloseIconButton onClick={close} />
    </DialogTitle>
    <DialogContent sx={{ height: '100%', width: '100%' }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Rule Type
      </Typography>
      <Autocomplete
        fullWidth
        size="small"
        value={value.ruleType}
        onChange={(event, newValue) => setValue(value => ({ ...value, ruleType: newValue }))}
        options={[...(ruleTypes?.map((type) => ({ id: type.value, label: type.label })) ?? [])]}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => <TextField {...params} label="Search or select" />}
      />
      <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
        Facility
      </Typography>
      <Autocomplete
        fullWidth
        size="small"
        value={value.facility}
        onChange={(event, newValue) => setValue(value => ({ ...value, facility: newValue }))}
        options={[{ id: null, label: "All Facilities" }, ...(facilities?.map((type) => ({ id: type.value, label: type.label })) ?? [])]}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => <TextField {...params} label="Search or select" />}
      />
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        Category
      </Typography>
      <Autocomplete
        fullWidth
        size="small"
        value={value.category}
        onChange={(event, newValue) => setValue(value => ({ ...value, category: newValue }))}
        options={[{ id: null, label: "All Categories" }, ...(categories?.map((type) => ({ id: type.value, label: type.label })) ?? [])]}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => <TextField {...params} label="Search or select" />}
      />
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        Vendor
      </Typography>
      <Autocomplete
        fullWidth
        size="small"
        value={value.vendor}
        onChange={(event, newValue) => setValue(value => ({ ...value, vendor: newValue }))}
        options={[{ id: null, label: "All Vendors" }, ...(vendors?.map((type) => ({ id: type.value, label: type.label })) ?? [])]}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => <TextField {...params} label="Search or select" />}
      />
      <Typography variant="subtitle1" sx={{ mt: 2 }}>Total over</Typography>
      <TextField
        fullWidth
        value={value?.totalOver || ''}
        onChange={(e) => setValue(value => ({ ...value, totalOver: formatMoney(e.target.value) }))}
        size="small"
        variant="outlined"
      />
      <Typography variant="subtitle1" sx={{ mt: 2 }}>Item price over</Typography>
      <TextField
        fullWidth
        value={value?.itemPriceOver || ''}
        onChange={(e) => setValue(value => ({ ...value, itemPriceOver: formatMoney(e.target.value) }))}
        label=""
        size="small"
        variant="outlined"
      />
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={close}>
        Cancel
      </Button>
      <AwaitButton
        variant="contained"
        color="primary"
        size="medium"
        sx={{ width: 150 }}
        onClick={handleSave}
      >
        {editRow ? 'Save' : 'Add'}
      </AwaitButton>
    </DialogActions>
  </Dialog>;
}
