import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import TicketOrderSearch from './TicketOrderSearch';

OrderSearchOnly.propTypes = {
  group: PropTypes.object,
  field: PropTypes.object,
  setFieldValue: PropTypes.func,
  facilityId: PropTypes.number,
};

export default function OrderSearchOnly({ field, facilityId, setFieldValue, group })
{
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    setFieldValue(field, group, {
      value: selectedOrder?.orderNumber,
      htmlValue: selectedOrder ? `<a href="/dashboard/orders/${selectedOrder.id}">${selectedOrder.label}</a>` : null,
      addUser: selectedOrder?.submittedBy,
      orderId: selectedOrder?.id
    });      
  }, [field, selectedOrder]);

  return <TicketOrderSearch facilityId={facilityId} onChange={(order) => { setSelectedOrder(order);  }} />;
}