import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useDispatch } from '../../redux/store';
import { getPendingOrders, getPreviousOrders } from '../../redux/slices/orders';
import PendingOrdersView from '../../components/dashboard/orders/PendingOrdersView';
import CompletedOrdersView from '../../components/dashboard/orders/CompletedOrdersView';

// ----------------------------------------------------------------------

export default function Orders() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParams.get('filter'));
  const [startDate, setStartDate] = useState(searchParams.get('start'));
  const [endDate, setEndDate] = useState(searchParams.get('end'));
  useEffect(() => {
    setFilter(searchParams.get('filter'));
    setStartDate(searchParams.get('start'));
    setEndDate(searchParams.get('end'));
  }, [searchParams]);

  useEffect(() => {
    if (filter === 'pending') {
      dispatch(getPendingOrders());
    } else {
      dispatch(getPreviousOrders({ startDate, endDate }));
    }
  }, [filter, dispatch, startDate, endDate]);

  return (
    <>
      <Helmet>
        <title>Orders | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {filter === 'pending' ? <PendingOrdersView /> : <CompletedOrdersView />}
        </Grid>
      </Grid>
    </>
  );
}
