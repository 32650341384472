import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { CheckoutAllView } from '../../../../components/dashboard/orders/new-order/cart';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getCart } from '../../../../redux/slices/cart';

export default function CheckoutAll() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const facilityId = searchParams.get('facilityId') || null;

  const {
    data: { carts },
  } = useSelector((state) => state.cart);

  useEffect(() => {
    if (carts.length > 0) return;
    dispatch(getCart(facilityId));
  }, [carts.length, dispatch, facilityId]);
  return (
    <>
      <Helmet>
        <title>Checkout | Platform Solutions</title>{' '}
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <CheckoutAllView />
        </Grid>
      </Grid>
    </>
  );
}
