import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, InputAdornment, Stack, Typography, IconButton, Box, Alert } from '@mui/material';
import Iconify from '../../reusable-components/iconify';
import { MinimalLogo } from '../../reusable-components/logo';
import { useSnackbar } from '../../reusable-components/snackbar';
import FormProvider, { RHFTextField } from '../../reusable-components/hook-form';
import { NEW_PASSWORD_SCHEMA } from '../../reusable-components/form/authSchema';
import { resetPassword } from '../../api/account';
import { AwaitButton } from '../../reusable-components/await-button';

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm({
    resolver: yupResolver(NEW_PASSWORD_SCHEMA),
  });
  const code = searchParams.get('code');
  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await resetPassword({ email: data.email, code, password: data.password });

      if (response === 'Success') {
        enqueueSnackbar('Password reset successfully', { variant: 'success' });
        navigate(`/auth/login`);
      } else {
        enqueueSnackbar(`Failed to reset password ${response}`, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      reset();
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };
  return (
    <Card sx={{ m: 3, p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: -13 }}>
            <MinimalLogo disabledLink />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h3" color="primary">
              New Password
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Email
            </Typography>
            <RHFTextField
              name="email"
              placeholder="Enter your email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:email-outline" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
              New Password
            </Typography>
            <RHFTextField
              name="password"
              placeholder="Minimum 8 characters"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:lock-outline" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
              Confirm
            </Typography>

            <RHFTextField
              name="confirmPassword"
              placeholder="Confirm your password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:lock-outline" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <AwaitButton
            fullWidth
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitSuccessful || isSubmitting}
          >
            Submit
          </AwaitButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
