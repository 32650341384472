import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useDispatch } from '../../../redux/store';
import { CompletedOrderView, PendingOrderView } from '../../../components/dashboard/orders/order';
import { getPreviousOrderInfo, getPendingOrderInfo, getRerunOrderInfo } from '../../../redux/slices/orders';
import { getOrderWasApproved } from '../../../api/orders';
// ----------------------------------------------------------------------

export default function Order() {
  const { id = '' } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const vendorId = searchParams.get('vendorId');
  const navigate = useNavigate();

  const orderWasApproved = async () => {
    const response = await getOrderWasApproved(id);
    return response.data;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (filter === 'previous') {
        dispatch(getPreviousOrderInfo(id));
      } else if (filter === 'rerun') {
        dispatch(getRerunOrderInfo(id, vendorId));
      } else {
        const approved = await orderWasApproved();
        if (approved) {
          navigate(`/dashboard/orders/${id}?filter=previous`);
        } else {
          dispatch(getPendingOrderInfo(id));
        }
      }
    };

    fetchData();
  }, [filter, id, vendorId]);

  return (
    <>
      <Helmet>
        <title>Order | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {filter === 'previous' ? (
            <CompletedOrderView />
          ) : (
            <>
              <PendingOrderView filter={filter} />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
