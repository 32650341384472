import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { getEmailPickupsIgnoredData, postDeleteEmailIgnore } from '../../../../api/vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { IGNORED } from '../../../../reusable-components/datagrid/vendorColumns';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import IgnoreModal from './IgnoreModal';

Ignored.propTypes = {
  selectedRow: PropTypes.object,
};

export default function Ignored({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [ignoreAreasValues, setIgnoreAreasValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getEmailPickupsIgnoredData();
      const { ignoreAreas, ignoreValues } = response.data;
      setIgnoreAreasValues(ignoreAreas);
      const filteredVendorData = selectedRow ? ignoreValues.filter((d) => d.vendorId === selectedRow.id) : ignoreValues;
      setData(filteredVendorData);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Error fetching ignored', { variant: 'error' });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const deleteEmail = async (id) => {
    try {
      const response = await postDeleteEmailIgnore(id);
      if (response.status === 200) {
        enqueueSnackbar('Pickup deleted successfully', { variant: 'success' });
        fetchData();
      }
    } catch (error) {
      enqueueSnackbar('Error deleting pickup', { variant: 'error' });
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.7,
    minWidth: 50,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <IgnoreModal fetchData={fetchData} edit row={params.row} ignoreAreasValues={ignoreAreasValues} />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            tooltip="Delete email pickup ignore"
            color="error"
            content={`Are you sure you want to delete email ignore?`}
            actionButton="Delete"
            action={() => deleteEmail(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <IgnoreModal fetchData={fetchData} ignoreAreasValues={ignoreAreasValues} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    ),
    [ignoreAreasValues]
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-vendors-ignored"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          forceReRender={data}
          gridColumns={[...IGNORED, actionButtons]}
          sort={[{ field: 'vendorId', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </>
  );
}
