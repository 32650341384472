import React, { useMemo, useState, useEffect } from 'react';
import { Box, Skeleton, LinearProgress, styled } from '@mui/material';
import { useGridApiContext, gridColumnsTotalWidthSelector, gridColumnPositionsSelector } from '@mui/x-data-grid-pro';
import { SpinningLogo } from '../logo';

function mulberry32(a) {
  return () => {
    /* eslint-disable */
    let t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    /* eslint-enable */
  };
}

function randomBetween(seed, min, max) {
  const random = mulberry32(seed);
  return () => min + (max - min) * random();
}

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export function SkeletonDataGrid({shortGrid = false}) {
  const apiRef = useGridApiContext();
  const rowHeight = apiRef.current.unstable_getRowHeight();
  const totalWidth = gridColumnsTotalWidthSelector(apiRef);
  const positions = gridColumnPositionsSelector(apiRef);
  const inViewportCount = useMemo(
    () => positions.filter((value) => value <= totalWidth).length,
    [totalWidth, positions]
  );
  const columns = apiRef.current.getVisibleColumns()?.slice(0, inViewportCount);
  const random = randomBetween(12345, 25, 75);

  const rowsCount = apiRef.current.getRowsCount();
  const [loading, setLoading] = useState(false);
  const skeletonRows = Array.from({ length: 10 });
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);
  return rowsCount > 0 ? (
    <LinearProgress />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: shortGrid ? -200 : 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
          }}
        >
          <SpinningLogo />
        </Box>
      )}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `${columns.map(({ computedWidth }) => `${computedWidth}px`).join(' ')} 1fr`,
          gridAutoRows: rowHeight,
          position: 'relative', // Ensure this is here if you need to position other elements absolutely within
        }}
      >
        {skeletonRows.map((_, rowIndex) => (
          <React.Fragment key={`row-${rowIndex}`}>
            {columns
              .filter((column) => column.field !== undefined && column.field !== null)
              .map((column) => {
                const width = Math.round(random());
                return (
                  <SkeletonCell key={`column-${rowIndex}-${column.field}`} sx={{ justifyContent: column.align }}>
                    <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
                  </SkeletonCell>
                );
              })}
          </React.Fragment>
        ))}
      </div>
    </Box>
  );
}
