import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getActiveTasks, getTaskData, runTask } from '../../../../api/tasks';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { TASKS } from '../../../../reusable-components/datagrid/taskColumns';
import { useSnackbar } from '../../../../reusable-components/snackbar';

export default function TasksView() {
  const [data, setData] = useState([]);
  const [activeTasks, setActiveTasks] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const getTasks = async () => {
    const response = await getTaskData();
    if (response.status === 200) {
      setData(response.data.taskTypes);
      setActiveTasks(response.data.activeTasks);
    } else {
      enqueueSnackbar('Error fetching emails', { variant: 'error' });
    }
  };

  // Function to fetch active tasks
  const fetchActiveTasks = async () => {
    const response = await getActiveTasks();
    if (response.status === 200) {
      setActiveTasks(response.data); // Assuming the API returns an array of active tasks
    } else {
      console.error('Failed to fetch active tasks');
    }
  };

  useEffect(() => {
    getTasks(); // Initial fetch of tasks
    const intervalId = setInterval(fetchActiveTasks, 5000); // Fetch active tasks every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  ///

  const progress = {
    field: 'progress',
    headerName: 'Progress',
    width: 400,
    // Updated renderCell function to display the dynamic percent value based on activeTasks
    renderCell: (params) => {
      // Find the active task based on the row id
      const activeTask = activeTasks.find((task) => task.taskId === params.row.id);

      // Determine the percent value or default to "N/A" if not found
      const percentValue = activeTask ? `${activeTask.percent}%` : 'N/A';

      return activeTask ? (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <Grid container sx={{ alignItems: 'center' }} spacing={1}>
            <Grid item>
              <Typography variant="caption" component="div" color="text.secondary">
                {percentValue}
              </Typography>
            </Grid>
            <Grid item>
              <LinearProgress
                variant="determinate"
                value={activeTask?.percent}
                color="secondary"
                sx={{ width: '200px' }}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <ConfirmDialog
          icon="browser-updated"
          title="Confirm Task Run"
          color="info"
          content={`Are you sure you want to run: ${params.row.name}? Approximate time to run: ${params.row.approxTimeTaken} minutes?`}
          actionButton="Confirm"
          action={() => runTask(params.row.id)}
        />
      );
    },
  };

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-tasks-view"
          boxSX={{ height: 'calc(100vh - 260px)' }}
          data={data}
          gridColumns={[...TASKS, progress]}
          sort={[{ field: 'name', sort: 'Asc' }]}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </>
  );
}
