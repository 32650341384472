// remember to import this file in index.js

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#637381',
            },
            //hover border color
            // '&:hover fieldset': {
            //   borderColor: '#ddd',
            // },
            //focused border color
            // '&.Mui-focused fieldset': {
            //   borderColor: '#ddd',
            // },
          },
        },
        paper: {
          boxShadow: theme.customShadows.dropdown,
          // '&.MuiAutocomplete-paper': {
          //   borderRadius: '10px',
          // },
        },
        listbox: {
          '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '5px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
      },
    },
  };
}
