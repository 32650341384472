import PropTypes from 'prop-types';
import { IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import TicketEditor from '../ticket/newTicket/TicketEditor';
import OnClickOffWrapper from '../../../../reusable-components/on-click-off/OnClickOffWrapper';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import AvailibleFields from './AvailibleFields';
import CustomWidthTooltip from './CustomWidthTooltip';
import { ScopeGuard } from '../../../../reusable-components/scopes';

TicketFieldGroupConfig.propTypes = {
  group: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setGroups: PropTypes.func
};

export default function TicketFieldGroupConfig({ group, open, setOpen, setGroups })
{
  const { enqueueSnackbar } = useSnackbar();

  const setGroupValue = (id, groupId, inputValue) => {
    setGroups(groups => {
      groups.find(group => group.groupId === groupId)[id] = inputValue;
      return [...groups];
    });
  }

  const onRepeatGroupChange = (newValue) => {
    const repeatGroup = newValue === 'true';
    if (repeatGroup && group.fields.some(field => !field.type?.multiple)) {
      enqueueSnackbar(`Can't have multiples of ${group.fields.filter(field => !field.type?.multiple)[0].type.label}`, { variant: 'error' })
      return;
    }
    setGroupValue("repeatGroup", group.groupId, repeatGroup);
  }

  return <CustomWidthTooltip open={open}
    placement="left"
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          color: "black !important",
          backgroundColor: "white !important",
          borderRadius: "6px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px 0px",
          border: "1px solid rgb(193, 201, 208)"
        }
      }
    }}
    title={open && group ?
      <OnClickOffWrapper onClick={() => setOpen(-1)}>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>Group Name</Typography>
        <TextField fullWidth size="small" value={group.groupLabel ?? ""} onChange={(e) => setGroupValue("groupLabel", group.groupId, e.target.value)} />
        <Typography variant="subtitle1" sx={{ mt: 1 }}>Repeat Group</Typography>
        <ToggleButtonGroup value={group.repeatGroup ? 'true' : 'false'} exclusive onChange={(event, newValue) => onRepeatGroupChange(newValue)}>
          <ToggleButton value="true">On</ToggleButton>
          <ToggleButton value="false">Off</ToggleButton>
        </ToggleButtonGroup>
        <ScopeGuard superAdminOnly>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>Ticket display</Typography>
          <AvailibleFields fields={group.fields ?? []} />        
          <TicketEditor
            value={group?.displayHTMLValue || group.fields?.map(field => `<p>${field.name ?? ""} - {${field.id} - ${field.name ?? ""}}</p>`).join("")}          
            onChange={(value) => setGroupValue("displayHTMLValue", group.groupId, value)}
            editorProps={{ attachFile: false }}
          />
        </ScopeGuard>
      </OnClickOffWrapper> : ""}>
    <IconButton color="info" sx={{ ml: 1, mb: 1, p: 0, alignSelf: "end" }} onClick={() => setOpen(oldId => oldId !== group.groupId ? group.groupId : -1)}>
      <SettingsIcon />
    </IconButton>
  </CustomWidthTooltip>;
}