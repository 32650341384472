import PropTypes from 'prop-types';
import { Box, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import Iconify from '../../../../../reusable-components/iconify/Iconify';

IntegrationCheckboxFormField.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func,
};

export default function IntegrationCheckboxFormField({ checked, onChange, label, tooltip }) {
  return (
    <Box>
      <FormControlLabel control={<Checkbox checked={checked} onChange={onChange} />} label={label} />
      {tooltip && (
        <Tooltip title={tooltip}>
          <Iconify icon="material-symbols:info-outline" width={18} height={18} />
        </Tooltip>
      )}
    </Box>
  );
}
