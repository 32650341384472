import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Tabs, Tab } from '@mui/material';
import { TabPanel } from '../../../reusable-components/tab-panel';
import TicketCategories from './TicketCategories';
import TicketTypes from './TicketTypes';

export default function TicketSettingsView()
{
  const [tab, setTab] = useState(0);

  return <>
    <Helmet>
      <title>Ticket Settings | Platform Solutions</title>
    </Helmet>

    <Grid item xl={12}>
      <Tabs value={tab}>
        <Tab label={"Types"} onClick={() => setTab(0)} />
        <Tab label={"Categories"} onClick={() => setTab(1)} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <TicketTypes />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <TicketCategories />
      </TabPanel>
    </Grid>
  </>;
}
