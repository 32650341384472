import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Stack, Slider, Typography, Tooltip } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

PageNavigator.propTypes = { numPages: PropTypes.number, pageNumber: PropTypes.number, setPageNumber: PropTypes.func };
export default function PageNavigator({ numPages, pageNumber, setPageNumber }) {
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  return (
    <Box>
      <Stack direction="row">
        <IconButton color="secondary" onClick={() => previousPage()} disabled={pageNumber === 1}>
          <NavigateBeforeIcon />
        </IconButton>
        <Typography color="secondary" py={1}>
          {pageNumber}/{numPages}
        </Typography>
        <IconButton color="secondary" onClick={() => nextPage()} disabled={pageNumber === numPages}>
          <NavigateNextIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}
