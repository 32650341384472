import { useCallback, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { SITE_RULES } from '../../../../reusable-components/datagrid/siteRulesColumns';
import { GetSiteRulesData, DeleteSiteRuleData } from '../../../../api/site-rules';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import SiteRuleModal from './SiteRuleModal';

export default function SiteRulesView() {
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getSiteRules = async () => {
    setIsLoading(true);
    const response = await GetSiteRulesData();
    if (response.status === 200) {
      setTableData(response.data.ruleValues);
      setData(response.data);
    } else {
      enqueueSnackbar('Error fetching site rules', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    const response = await DeleteSiteRuleData(id);
    if (response.status === 200) {
      fetchData();
      enqueueSnackbar('Site rule deleted successfully', { variant: 'success' });
    }
  };

  useEffect(() => {
    getSiteRules();
  }, []);

  const fetchData = async () => {
    try {
      getSiteRules();
    } catch (error) {
      console.error('Error fetching rules:', error);
    }
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Box sx={{ mb: 1 }}>
          <SiteRuleModal data={data} fetchData={fetchData} existingRules={tableData} />
        </Box>
      </Grid>
    ),
    [data, tableData]
  );

  const ActionButtons = (params) => (
    <>
      <SiteRuleModal edit row={params?.row} data={data} fetchData={fetchData} existingRules={tableData} />

      <ConfirmDialog
        icon="delete-outline"
        title="Delete"
        color="error"
        content={`Are you sure you want to delete ${params.row.rule}?`}
        actionButton="Delete"
        action={() => handleDelete(params.row.id)}
        tooltip={`Delete`}
      />
    </>
  );

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-site-rules-view"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={tableData}
          gridColumns={[...SITE_RULES, actions]}
          sort={[{ field: 'rule', sort: 'asc' }]}
          isLoading={isLoading}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          CustomLeftToolbar={customToolbar}
          forceReRender={data}
        />
      </Grid>
    </>
  );
}
