import React, { createContext, useContext, useState, useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { useTabs } from './TabContext';
import { createStoreForTab } from '../redux/createStoreForTab';

const TabStoreContext = createContext();

export const TabStoreProvider = ({ children }) => {
  const { tabList, value: currentTabId, setTabList } = useTabs();
  const [stores, setStores] = useState({});
  const [persistors, setPersistors] = useState({});

  useEffect(() => {
    const isMissingStores = Object.keys(stores).length === 0 && tabList.length > 1 && tabList.some(tab => tab.id === currentTabId);
    const localTabList = isMissingStores ? [tabList.find(tab => tab.id === currentTabId)] : tabList;
    if (isMissingStores) setTabList(localTabList);
    localTabList.forEach((tab) => {
      if (!stores[tab.key]) {
        const newStore = createStoreForTab(tab.key);
        const newPersistor = persistStore(newStore);

        setStores((prevStores) => ({ ...prevStores, [tab.key]: newStore }));
        setPersistors((prevPersistors) => ({ ...prevPersistors, [tab.key]: newPersistor }));
      }
    });
    setStores((prevStores) => {
      const deletedTabs = Object.keys(prevStores).filter(storeKey => !localTabList.some(tab => `${tab.key}` === storeKey));
      deletedTabs.forEach(key => { prevStores[key] = null; });
      return prevStores;
    });
    setPersistors((prevPersistors) => {
      const deletedTabs = Object.keys(prevPersistors).filter(storeKey => !localTabList.some(tab => `${tab.key}` === storeKey));
      deletedTabs.forEach(key => { prevPersistors[key] = null; });
      return prevPersistors;
    });
  }, [tabList]);

  const currentStore = stores[currentTabId];
  const currentPersistor = persistors[currentTabId];

  if (!currentStore || !currentPersistor) {
    if (!tabList?.some(tab => tab.id === currentTabId)) console.error('Store or persistor is undefined for tab ID:', currentTabId);
    return null; // or some fallback UI
  }

  return (
    <TabStoreContext.Provider value={currentStore}>
      <Provider store={currentStore}>
        <PersistGate loading={null} persistor={currentPersistor}>
          {children}
        </PersistGate>
      </Provider>
    </TabStoreContext.Provider>
  );
};

export const useTabStore = () => useContext(TabStoreContext);
