import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Iconify from '../iconify';
import { addItemToAnOrderInRedux, removeItemFromErrorList } from '../../redux/slices/orders';
import { useDispatch, useSelector } from '../../redux/store';
import { fCurrency } from '../../utils/formatNumber';
import { cartCheckApiForOneItem } from '../../api/cart';

ReplaceItemQuantityField.propTypes = {
  row: PropTypes.object,
  errorRow: PropTypes.object,
  handleClose: PropTypes.func,
};

export default function ReplaceItemQuantityField({ row, errorRow, handleClose }) {
  const { id = '' } = useParams();
  const dispatch = useDispatch();
  const { orderById } = useSelector((state) => state.orders || {});
  const { facilityId, items } = orderById[id] || {};

  const [quantity, setQuantity] = useState(errorRow.quantity);
  const [errorArray, setErrorArray] = useState(null);
  const [checkedItem, setCheckedItem] = useState(false);
  const [loading, setLoading] = useState(false);

  const actionReplace = () => {
    dispatch(
      addItemToAnOrderInRedux({
        ...row,
        orderId: id,
        id: errorRow.id,
        itemId: row.id,
        // unsavedId: errorRow.id,
        previouslyOrdered: row.previouslyOrderedItems || [],
        approved: errorRow.approved,
        quantity,
        vendor: row.vendorName,
      })
    );

    dispatch(removeItemFromErrorList(id, errorRow.id));
    handleClose();
  };

  const cartCheckFunction = async () => {
    setLoading(true);
    setErrorArray([]);

    const itemsToCheck = [
      ...items?.filter((i) => i.id !== errorRow.id),
      {
        id: row.id,
        vendorId: row.vendorId,
        quantity,
        price: row.price,
        vendorItemId: row?.sku || row.vendorItemId,
        uomType: row.uomType,
      },
    ];

    const response = await cartCheckApiForOneItem(facilityId, itemsToCheck);

    setCheckedItem(true);

    if (response.data?.items?.find((cci) => cci.id === row.id)?.errors?.length > 0) {
      setErrorArray(response.data.items?.find((cci) => cci.id === row.id)?.errors);
    }

    setLoading(false);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            size="small"
            value={quantity || ''}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              if (!Number.isNaN(newValue) && newValue >= 0) {
                setQuantity(newValue);
                setCheckedItem(false);
              }
            }}
            sx={{ maxWidth: '70px', minWidth: '70px', width: '70px' }}
          />
        </Grid>
        <Grid item sx={{ maxWidth: '70px', minWidth: '70px', width: '70px' }}>
          <Typography variant="subtitle2"> {fCurrency(quantity * row.price)}</Typography>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            endIcon={
              loading ? (
                <Iconify icon="svg-spinners:270-ring-with-bg" />
              ) : (
                <Iconify icon="ph:list-magnifying-glass-bold" />
              )
            }
            onClick={cartCheckFunction}
            size="small"
          >
            Check
          </Button>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              errorArray?.length > 0
                ? errorArray.map((error) => (
                    <div key={error}>
                      {error}
                      <br />
                    </div>
                  ))
                : ''
            }
          >
            <Button
              variant="outlined"
              color={!checkedItem ? 'secondary' : errorArray?.length ? 'error' : 'primary'}
              onClick={actionReplace}
              size="small"
            >
              Select
            </Button>
          </Tooltip>
        </Grid>
        {row.uomType !== errorRow.uomType || row.uomAmount !== errorRow.uomAmount ? (
          <Grid item>
            <Tooltip
              title={
                <div>
                  <div>
                    UOM is different then current item {errorRow.uomAmount}/{errorRow.uomType}
                  </div>

                  {row.uomAmount !== errorRow.uomAmount && (
                    <div>
                      <div>
                        <span>{errorRow.uomAmount}</span> <span>=={'>'}</span> <span>{row.uomAmount}</span>
                      </div>
                    </div>
                  )}
                  {row.uomType !== errorRow.uomType && (
                    <div>
                      <span>{errorRow.uomType}</span> <span>=={'>'}</span> <span>{row.uomType}</span>
                    </div>
                  )}
                </div>
              }
            >
              <Iconify icon="material-symbols:info-outline" color="info" sx={{ mt: 1 }} />
            </Tooltip>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
