import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { Grid, Box } from '@mui/material';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { TEMPLATES_COLUMNS } from '../../../../../reusable-components/datagrid/permissionColumns';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import { userRoleData, setUserEmpty, getUsers } from '../../../../../redux/slices/users';
import { deleteTemplate } from '../../../../../api/user';
import { ConfirmDialog } from '../../../../../reusable-components/confirm-dialog';
import EditRoleModal from './EditRoleModal';

export default function RolesTemplateTab() {
  const dispatch = useDispatch();
  const { id = '' } = useParams();

  const {
    data: { userRoleTemplates },
  } = useSelector((state) => state.users);

  const handleDelete = async (templateId) => {
    await deleteTemplate(templateId);
    dispatch(setUserEmpty());
    dispatch(getUsers());
    dispatch(userRoleData(id, 1));
  };

  const ActionButtons = (params) => {
    const { id, isLocked } = params.row;
    return (
      <>
        <Box
          sx={{
            p: 6,
          }}
        >
          <EditRoleModal row={params.row} />
        </Box>
        <ConfirmDialog
          icon="delete-outline"
          title="Delete"
          color="error"
          content={`Are you sure you want to delete this template?`}
          actionButton="Delete"
          action={() => handleDelete(id)}
          disabled={isLocked}
        />
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  const CustomLeftToolbar = useCallback(
    () => (
      <>
        <Box>
          <EditRoleModal
            row={{
              id: undefined,
              name: 'New Template',
              isLocked: false,
              useUserFacility: false,
            }}
            newTemplate
          />
        </Box>
      </>
    ),
    []
  );

  return (
    <Grid item>
      <CustomDataGrid
        gridId="templates"
        boxSX={{ height: 'calc(100vh - 340px)' }}
        isModal
        data={userRoleTemplates}
        gridColumns={[...TEMPLATES_COLUMNS, actions]}
        CustomLeftToolbar={CustomLeftToolbar}
        dontGetRowHeight
      />
    </Grid>
  );
}
