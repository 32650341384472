import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Tooltip } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { fDayMonthDay, fDayMonthDy, hoursLeftToDate } from '../../../../utils/formatTime';
import { ScopeGuard } from '../../../../reusable-components/scopes';

VendorDate.propTypes = {
  item: PropTypes.object,
  allReqDates: PropTypes.array,
  setAllReqDates: PropTypes.func,
};

export default function VendorDate({ item, allReqDates, setAllReqDates }) {
  const { id = '' } = useParams();
  const { orderById } = useSelector((state) => state.orders || {});
  const { vendorDateMessages = [], vendors } = orderById[id] || {};

  const { vendorId } = item;

  const filterUniqueMessagesByVendorId = (messages) => {
    const uniqueMessages = {};
    messages.forEach((msg) => {
      const { vendorId, mainMessage } = msg;
      if (!uniqueMessages[vendorId]) {
        uniqueMessages[vendorId] = [];
      }
      if (mainMessage) {
        uniqueMessages[vendorId].unshift(msg);
      } else uniqueMessages[vendorId].push(msg);
    });
    return uniqueMessages;
  };
  const uniqueMessagesLookup = useMemo(
    () => filterUniqueMessagesByVendorId(vendorDateMessages || []),
    [vendorDateMessages]
  );

  const allowsRequestedShipDate = vendors?.find((vendor) => vendor.id === item.vendorId)?.allowsRequestedShipDate;

  const selectedDate = allReqDates?.find((date) => date.vendorId === vendorId);

  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <Box sx={{ pl: 2 }}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ pl: 1 }}>
        <Iconify icon="ic:outline-local-shipping" color="secondary" sx={{ mr: 0.5, mb: 0.4 }} width={16} height={16} />
        <Typography variant="subtitle2">Ship dates:</Typography>

        <ScopeGuard scopes={['Edit-Order']} allowAdmin>
          <Box sx={{ display: 'flex', '&:hover': { cursor: allowsRequestedShipDate ? 'pointer' : 'default', }, mt: 0.5, ml: 1 }}
            onClick={() => allowsRequestedShipDate && setCalendarOpen(o => !o)}
          >
            <Tooltip title={!allowsRequestedShipDate && "Vendor doesn't accept requested dates."} placement="top" arrow>
              <Typography variant="subtitle2" component="span"
                sx={{ textDecoration: 'underline', color: (theme) => (allowsRequestedShipDate ? theme.palette.secondary.main : theme.palette.grey[500]) }}
              >
                {selectedDate ? `${fDayMonthDy(selectedDate.date)} - Requested date` : 'Request different date'}
              </Typography>
            </Tooltip>
            {allowsRequestedShipDate && (<Iconify color="secondary" icon={calendarOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />)}
          </Box>
        </ScopeGuard>
      </Grid>
      {uniqueMessagesLookup[item.vendorId]?.length > 0 &&
        uniqueMessagesLookup[item.vendorId]?.map((item, index) => (
          <Box key={index}>
            {item.mainMessage ? (
              <Grid container direction="row" key={index} sx={{ pl: 1 }}>
                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[700] }}>
                  {item.message}
                </Typography>
              </Grid>
            ) : (
              <Grid container direction="row" key={index} sx={{ pl: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: (theme) => theme.palette.grey[700] }}>
                  {index + 1}) {item.relativeDate ? hoursLeftToDate(item.date) : fDayMonthDay(item.date)}
                </Typography>
              </Grid>
            )}
          </Box>
        ))}

      {calendarOpen && (
        <DateCalendar
          value={selectedDate?.date}
          onChange={(newDate) => {
            setAllReqDates((prevDates) => {
              const existingDateIndex = prevDates?.findIndex((dateEntry) => dateEntry.vendorId === vendorId);

              if (existingDateIndex !== -1) {
                return prevDates.map((dateEntry, index) =>
                  index === existingDateIndex ? { ...dateEntry, date: newDate } : dateEntry
                );
              }
              return [...prevDates, { vendorId, date: newDate }];
            });
            setCalendarOpen(false);
          }}
          onClose={() => setCalendarOpen(false)}
        />
      )}
    </Box>
  );
}
