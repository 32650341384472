import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import Iconify from '../iconify';
import { ScopeGuard } from '../scopes';

UrgentToggle.propTypes = {
  value: PropTypes.bool,
  dashboard: PropTypes.bool,
};

export default function UrgentToggle({ value, dashboard }) {
  return (
    <>
      <ScopeGuard scopes={['Facility-Manager']}>
        {value ? (
          <Tooltip title={!dashboard ? 'Add tag' : ''} placement="right" arrow>
            <IconButton
              onClick={(event) => {
                event.urgent = true;
              }}
              color="error"
              sx={{
                opacity: 0.1,
                '&:hover': {
                  opacity: 0.9,
                },
              }}
            >
              <Iconify icon="fa-solid:exclamation" />{' '}
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={!dashboard ? 'No manager activity in 24 hours' : ''} placement="right" arrow>
            <IconButton
              onClick={(event) => {
                event.urgent = true;
              }}
            >
              <Iconify icon="fa-solid:exclamation" color="error" />
            </IconButton>
          </Tooltip>
        )}
      </ScopeGuard>
    </>
  );
}
