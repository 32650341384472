import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';

import { useSelector, useDispatch } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { getOrderingFacilities } from '../../../redux/slices/orderSheet';
import AccountInformation from '../../../components/dashboard/rentals/newRental/AccountInformation';
import ResidentInformation from '../../../components/dashboard/rentals/newRental/ResidentInformation';
import CategorySelect from '../../../components/dashboard/rentals/newRental/CategorySelect';

NewRental.propTypes = {
  setOpenRentalDialog: PropTypes.func,
  openRentalDialog: PropTypes.string,
  setOpenNewOrderDialog: PropTypes.func,
  openNewOrderDialog: PropTypes.string,
};

export default function NewRental({
  setOpenRentalDialog,
  openRentalDialog,
  setOpenNewOrderDialog,
  openNewOrderDialog,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: { facilities, selectedFacilityId, categories },
  } = useSelector((state) => state.orderSheet);
  const [facility, setFacility] = useState({});
  const [resident, setResident] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [company, setCompany] = useState({});
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <AccountInformation facility={facility} setFacility={setFacility} company={company} setCompany={setCompany} />
        );
      case 1:
        return <ResidentInformation resident={resident} setResident={setResident} facility={facility} />;
      case 2:
        return <CategorySelect selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />;
      default:
        return null;
    }
  };
  const handleCloseRentalDialog = () => {
    setOpenRentalDialog(false);
    setOpen(false);
    setOpenNewOrderDialog(false);
  };

  useEffect(() => {
    if (activeStep === 3) {
      handleCloseRentalDialog();
      navigate(
        `/dashboard/rentals/new-rental?facilityId=${resident.facilityId}&category=${selectedCategory}&residentId=${resident.id}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, facility.value, navigate]);
  useEffect(() => {
    if (open && !facilities?.length) dispatch(getOrderingFacilities());
  }, [dispatch, facilities, open]);

  useEffect(() => {
    setValue(facilities.find((option) => option.value === selectedFacilityId) || null);
  }, [facilities, selectedFacilityId]);

  return (
    <div>
      <Dialog open={openRentalDialog} onClose={handleCloseRentalDialog} fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
            color: '#8cc53f',
            fontSize: '24px',
            mb: -3,
            fontWeight: 'bold',
          }}
        >
          <div>
            {/* back arrow */}
            {activeStep !== 0 && (
              <Button title="Back" onClick={handleBack} style={{ color: 'gray', maxWidth: '20' }}>
                <Iconify icon="ic:baseline-arrow-back" width={20} height={20} />
              </Button>
            )}
          </div>
          <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>New Rental</div>
          {activeStep !== 0 && (
            <div style={{ marginLeft: '60px' }}>
              {' '}
              {/* Add an empty div to maintain spacing on the right if needed */}
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          {renderStepContent()}

          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Button
              title="Next"
              variant="contained"
              onClick={handleNext}
              sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
            >
              Next
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
