import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

export async function getCartTotalData(facilityId) {
  const response = await axios.get(`/CartTotal?facilityId=${facilityId}`);
  return response;
}

// GET / Api / OrderSheet; categoryId (facilityId optinol)
export async function getOrderSheetData(categoryId, facilityId) {
  const response = await axios.get(`/OrderSheet?categoryId=${categoryId}&facilityId=${facilityId}`);
  return response;
}

// POST / Api / AddToCart;- items array
export async function addToCartApi(items, facilityId, categoryId) {
  console.log(items);

  const itemsToPost = items?.map((item) => ({
    itemId: item.productDetails[0].id,
    quantity: item.quantity,
    agoraCategoryId: item.agoraCategoryId,
    agoraSubcategoryId: item.agoraSubcategoryId,
    uomType: item.uomType,
  }));

  const formData = objectToFormData({ items: itemsToPost, facilityId, categoryId });
  const response = await axios.post('/AddToCart', formData);
  return response;
}

// GET / Api / OrderingCategories;
export async function getOrderingCategoriesData(facilityId) {
  const response = await axios.get(`/OrderingCategories?facilityId=${facilityId}`);
  return response;
}
// GET / Api / OrderingPunchoutVendors; categoryId
export async function getOrderingFacilitiesData() {
  const response = await axios.get(`/OrderingFacilities`);
  return response;
}

// GET / Api / OrderingPunchoutVendors; categoryId
export async function getOrderingPunchoutVendors(categoryId) {
  const response = await axios.get(`/OrderingPunchoutVendors?categoryId=${categoryId}`);
  return response;
}
///Api/OrdersForCopy categoryId facilityId
export async function getOrdersForCopy(categoryId, facilityId) {
  const response = await axios.get(`/OrdersForCopy?categoryId=${categoryId}&facilityId=${facilityId}`);
  return response;
}

///Api/CopyOrderItems; orderId
export async function copyOrderItems(orderId) {
  const response = await axios.get(`/CopyOrderItems?orderId=${orderId}`);
  return response;
}

///Api/CartFacilities
export async function getCartFacilities() {
  const response = await axios.get(`/FacilitiesWithCarts`);
  return response;
}

// /Api/OrderingPunchoutVendors
export async function getOrderingPunchoutVendorsData(categoryId) {
  const response = await axios.get(`/OrderingPunchoutVendors?categoryId=${categoryId}`);
  return response;
}

// getOrderingPunchoutVendorsLinkData;
