import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/Vendor/Vendors
export async function getVendorsData() {
  const response = await axios.get('Vendor/Vendors');
  return response;
}

// GET
// /Api/Vendor/VendorDetail vendorId
export async function getVendorDetailData(vendorId) {
  const response = await axios.get(`Vendor/VendorDetail?vendorId=${vendorId}`);
  return response;
}

// POST
// /Api/Vendor/VendorForm
export async function postVendorForm(form) {
  const files = form.files;

  const updatedForm = {
    Name: form.name,
    EmailTo: form.emailTo,
    EmailToCC: form.emailToCC,
    Address: form.address,
    Zip: form.zip,
    City: form.city,
    State: form.state,
    Phone: form.phone,
    Logo: form.logo,
    FileTypeId: form.fileTypeId, //W9 Files
  };

  const formData = objectToFormData({
    ...updatedForm,
    files,
  });

  const response = await axios.post('Vendor/VendorForm', formData);
  return response;
}
// POST
// /Api/Vendor/VendorForm
export async function postEditVendorForm(form, id) {
  const updatedForm = {
    Name: form.name,
    Id: id,
    EmailTo: form.emailTo,
    EmailToCC: form.emailToCC,
    Address: form.address,
    Zip: form.zip,
    City: form.city,
    State: form.state,
    Phone: form.phone,
    Logo: form.logo,
  };

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Vendor/VendorForm', formData);
  return response;
}

export async function postEditVendorContactForm(form, vendorId, contactId) {
  const updatedForm = {
    LastName: form.lastName,
    FirstName: form.firstName,
    Email: form.email,
    Phone: form.phone,
    PositionId: form.position === null ? 0 : form.position.value,
  };
  const formData = objectToFormData({
    ...updatedForm,
    vendorId,
    contactId,
  });

  const response = await axios.post(`Vendor/VendorContactForm`, formData);
  return response;
}

export async function postDeleteVendorContact(vendorId, contactId) {
  const formData = objectToFormData({
    vendorId,
    contactId,
  });
  const response = await axios.post(`Vendor/DeleteVendorContact`, formData);
  return response;
}
// GET
// /Api/Vendor/DownloadVendors
////Download Api is used in code bec we need the `${HOST_API_URL}

// POST
// /Api/Vendor/DeleteVendor id
export async function postDeleteVendor(id) {
  const response = await axios.post(`Vendor/DeleteVendor?id=${id}`);
  return response;
}

// GET
// /Api/Vendor/VendorFiles vendorId
export async function getVendorFilesData(vendorId) {
  const response = await axios.get(`Vendor/VendorFiles?vendorId=${vendorId}`);
  return response;
}

// POST
// /Api/Vendor/AddVendorFiles vendorId  fileTypeId files
export async function postAddVendorFiles(vendorId, fileTypeId, files) {
  const formData = objectToFormData({
    vendorId,
    fileTypeId,
    files,
  });
  const response = await axios.post('Vendor/AddVendorFiles', formData);
  return response;
}

// GET
// /Api/Vendor/DownloadVendorFile
////Download Api is used in code bec we need the `${HOST_API_URL}

// POST
// /Api/Vendor/DeleteVendorFile id
export async function postDeleteVendorFile(id) {
  const response = await axios.post(`Vendor/DeleteVendorFile?id=${id}`);
  return response;
}

// GET
// /Api/Vendor/FacilityVendorAccounts
export async function getFacilityVendorAccountsData() {
  const response = await axios.get('Vendor/FacilityVendorAccounts');
  return response;
}

// POST
// /Api/Vendor/FacilityVendorAccountForm
export async function postFacilityVendorAccountForm(form, extraFields, id) {
  const updatedForm = {
    FacilityId: form.facility.id,
    VendorId: form.vendor.id,
    Account: form.account,
    PaymentTerms: form.paymentTerms,
    PPD: form.isPPD || false,
    AccountInactive: form.accountInactive || false,
    File: form.file,
    FileName: form.file?.attachment ?? form.file?.name,
    ExtraValues: extraFields.map((field) => ({
      key: field.id,
      value: field.value,
    })),
  };
  if (id) {
    updatedForm.id = id;
  }

  const formData = objectToFormData({
    ...updatedForm
  });

  const response = await axios.post('Vendor/FacilityVendorAccountForm', formData);
  return response;
}

// POST
// /Api/Vendor/DeleteFacilityVendorAccount id
export async function postDeleteFacilityVendorAccount(id) {
  const response = await axios.post(`Vendor/DeleteFacilityVendorAccount?id=${id}`);
  return response;
}

// GET
// /Api/Vendor/DownloadFacilityAccountsExcel
////Download Api is used in code bec we need the `${HOST_API_URL}

// GET
// /Api/Vendor/VendorBookingAccounts
export async function getVendorBookingAccountsData() {
  const response = await axios.get('Vendor/VendorBookingAccounts');
  return response;
}

// POST
// /Api/Vendor/VendorBookingAccountForm
export async function postVendorBookingAccountForm(form, id) {
  const updatedForm = {
    VendorId: form.vendor.id,
    Account: form.account,
    BookingVendorId: form.bookingVendor.id,
  };
  if (id) {
    updatedForm.id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Vendor/VendorBookingAccountForm', formData);
  return response;
}

// POST
// /Api/Vendor/DeleteBookingVendorAccount id
export async function postDeleteBookingVendorAccount(id) {
  const response = await axios.post(`Vendor/DeleteBookingVendorAccount?id=${id}`);
  return response;
}

// GET
// /Api/Vendor/IntacctBookingTypes

// GET
// /Api/Vendor/SearchIntacctForVendor
export async function searchIntacctForVendor(search, bookingTypeId) {
  const response = await axios.get(`Vendor/SearchIntacctForVendor?vendor=${search}&bookingTypeId=${bookingTypeId}`);
  return response;
}

// GET
// /Api/Vendor/VendorMinimums
export async function getVendorMinimumsData() {
  const response = await axios.get('Vendor/VendorMinimums');
  return response;
}

// POST
// /Api/Vendor/VendorMinimumForm
export async function postVendorMinimumForm(form, id) {
  const updatedForm = {
    FacilityId: form.facility.id,
    VendorId: form.vendor.id,
    MinimumTotal: form.minimumTotal,
  };
  if (id) {
    updatedForm.id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Vendor/VendorMinimumForm', formData);
  return response;
}

// POST
// /Api/Vendor/DeleteVendorMinimum
export async function postDeleteVendorMinimum(id) {
  const response = await axios.post(`Vendor/DeleteVendorMinimum?id=${id}`);
  return response;
}

// GET
// /Api/Vendor/VendorDates facilityId vendorId
export async function getVendorDatesData(facilityId, vendorId) {
  const response = await axios.get(`Vendor/VendorDates?facilityId=${facilityId}&vendorId=${vendorId}`);
  return response;
}

// POST
// /Api/Vendor/VendorDateForm FacilityId VendorId form

function convertToUTC(dateString) {
  const date = new Date(dateString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}

export async function postVendorDateForm(facilityId, vendorId, form, selectedEventId) {
  const updatedForm = {
    VendorDateTypeId: form.typeId,
    StartDate: convertToUTC(form.start),
    EndDate: convertToUTC(form.end),
    Weekly: form.weekly,
  };
  if (selectedEventId) {
    updatedForm.id = selectedEventId;
  }

  console.log({ updatedForm });

  const formData = objectToFormData({
    facilityId,
    vendorId,
    id: selectedEventId || 0,
    ...updatedForm,
  });
  const response = await axios.post('Vendor/VendorDateForm', formData);
  return response;
}

// POST
// /Api/Vendor/DeleteVendorDate id
export async function postDeleteVendorDate(id) {
  const response = await axios.post(`Vendor/DeleteVendorDate?id=${id}`);
  return response;
}

// // GET
// // /Api/Vendor/DownloadVendorDates
////Download Api is used in code bec we need the `${HOST_API_URL}

// GET
// /Api/Vendor/VendorIntegrationsNotes
export async function getVendorIntegrationsNotesData() {
  const response = await axios.get('Vendor/VendorIntegrationsNotes');
  return response;
}

// GET
// /Api/Vendor/VendorEDIIntegrations
// export async function getVendorEDIIntegrationsData() {
//   const response = await axios.get('Vendor/VendorEDIIntegrations');
//   return response;
// }

// POST
// /Api/Vendor/VendorIntegrationNoteForm
export async function postVendorIntegrationNoteForm(form, id) {
  const updatedProgressUpdates = form.progressUpdates.map((progressUpdate) => {
    const updatedProgressUpdate = {
      message: progressUpdate.message,
    };
    return updatedProgressUpdate;
  });

  const updatedForm = {
    TypeId: form?.type.id,
    VendorId: form.vendor.id,
    Description: form.description,
    PrimaryContact: form.primaryContact,
    Complete: form.complete || false,
    ProgressUpdates: id && form.progressUpdates.length > 0 ? updatedProgressUpdates : form.progressUpdates || [],
  };
  if (id) {
    updatedForm.id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Vendor/VendorIntegrationNoteForm', formData);
  return response;
}

// POST
// /Api/Vendor/DeleteVendorIntegrationNote id
export async function postDeleteVendorIntegrationNote(id) {
  const response = await axios.post(`Vendor/DeleteVendorIntegrationNote?id=${id}`);
  return response;
}

// GET
// /Api/Vendor/EdiTestOrderDownloadOptions vendorId

// GET
// /Api/Vendor/DownloadTestEdiOrder first one

// GET
// /Api/Vendor/DownloadTestEdiByOrder second one

// GET
// /Api/Vendor/StatusCodes
export async function getStatusCodesData() {
  const response = await axios.get('Vendor/StatusCodes');
  return response;
}

// POST
// /Api/Vendor/StatusCodeForm id form
export async function postStatusCodeForm(form, id) {
  const updatedForm = {
    PurchasingVendorId: form.vendor.id,
    Code: form.code.label,
    AgoraCode: form.agoraCode.label,
  };
  if (id) {
    updatedForm.id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Vendor/StatusCodeForm', formData);
  return response;
}

// POST
// /Api/Vendor/DeleteStatusCode
export async function postDeleteStatusCode(id) {
  const response = await axios.post(`Vendor/DeleteStatusCode?id=${id}`);
  return response;
}

// GET
// /Api/Vendor/NewStatusCodes vendorId
export async function getNewStatusCodesData(vendorId) {
  const response = await axios.get(`Vendor/NewStatusCodes?vendorId=${vendorId}`);
  return response;
}

// GET
// /Api/Vendor/EmailPickups
export async function getEmailPickupsData() {
  const response = await axios.get('Vendor/EmailPickups');
  return response;
}

// GET
// /Api/Vendor/EmailPickupsIgnored
export async function getEmailPickupsIgnoredData() {
  const response = await axios.get('Vendor/EmailPickupsIgnored');
  return response;
}

// POST
// /Api/Vendor/EmailPickupForm id form
export async function postEmailPickupForm(form, id) {
  const updatedForm = {
    VendorId: form.vendor.id,
    Value: form.value,
    Type: form?.type.label,
    For: form.for.label,
  };
  if (id) {
    updatedForm.id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Vendor/EmailPickupForm', formData);
  return response;
}

// POST
// /Api/Vendor/EmailIgnoreForm
export async function postEmailIgnoreForm(form, id) {
  const updatedForm = {
    VendorId: form.vendor.id,
    AreaId: form.area.id,
    Value: form.value,
    AllSites: form.allSites || false,
  };
  if (id) {
    updatedForm.id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Vendor/EmailIgnoreForm', formData);
  return response;
}

// POST
// /Api/Vendor/DeleteEmailIgnore
export async function postDeleteEmailIgnore(id) {
  const response = await axios.post(`Vendor/DeleteEmailIgnore?id=${id}`);
  return response;
}

// POST
// /Api/Vendor/DeleteEmailPickup
export async function postDeleteEmailPickup(id) {
  const response = await axios.post(`Vendor/DeleteEmailPickup?id=${id}`);
  return response;
}

// POST
// /Api/Vendor/TriggerEmailPickup id
export async function postTriggerEmailPickup(id) {
  const response = await axios.post(`Vendor/TriggerEmailPickup?id=${id}`);
  return response;
}

// GET
// /Api/Vendor/VendorRules
export async function getVendorRulesData() {
  const response = await axios.get('Vendor/VendorRules');
  return response;
}


export async function getVendorItemsData() {
  const response = await axios.get(`ViewAllSKUs`);
  return response;
}
// POST
// /Api/Vendor/DeleteVendorRule id
export async function postDeleteVendorRule(id) {
  const response = await axios.post(`Vendor/DeleteVendorRule?id=${id}`);
  return response;
}

// POST
// /Api/Vendor/VendorRuleForm id form
export async function postVendorRuleForm(form, id) {
  const updatedForm = {
    VendorId: form.vendor.id,
    RuleTypeId: form?.type.id,
    RuleId: form.rule.id,
    Values: form.values,
  };
  if (id) {
    updatedForm.id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Vendor/VendorRuleForm', formData);
  return response;
}

// GET
// /Api/Vendor/IntegrationDefinitionsData integrationType
export async function getIntegrationDefinitionsData(integrationType) {
  const response = await axios.get(`Vendor/IntegrationDefinitionsData?integrationType=${integrationType}`);
  return response;
}

// POST
// /Api/Vendor/IntegrationForm  vendorId integrationType OrderingDisabled id definitions
export async function postIntegrationForm({ vendorId, integrationType, orderingDisabled, definitions }) {
  const formData = objectToFormData({
    vendorId,
    type: integrationType,
    orderingDisabled,
    definitions: definitions.filter((def) => def.vendorId === vendorId),
  });
  const response = await axios.post('Vendor/IntegrationForm', formData);
  return response;
}

// POST
// /Api/Vendor/DeleteIntegration integrationType id
export async function postDeleteIntegration(vendorId, integrationType) {
  const response = await axios.post(`Vendor/DeleteIntegration?vendorId=${vendorId}&integrationType=${integrationType}`);
  return response;
}

// GET
// /Api/Vendor/AccountExtraFields vendorId accountId
export async function getAccountExtraFieldsData(vendorId, accountId) {
  const response = await axios.get(`Vendor/AccountExtraFields?vendorId=${vendorId}&accountId=${accountId}`);
  return response;
}
export async function getVendorContacts(vendorId) {
  const response = await axios.get(`Vendor/VendorContacts?vendorId=${vendorId}`);
  return response;
}
export async function addContact(vendorId) {
  const response = await axios.get(`Vendor/VendorContacts?vendorId=${vendorId}`);
  return response;
}
export async function getVendorContactPositions() {
  const response = await axios.get(`Vendor/VendorContactPositions`);
  return response;
}
