import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {Paper, Grid} from '@mui/material'
import { CATALOG_ITEMS_PER_VENDOR } from '../../../../reusable-components/datagrid/catalogColumns';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters'
import { getVendorItemsData } from '../../../../api/vendors';

  export default function VendorItemsTab({ selectedRow }) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [selected, setSelected] = useState([]);

    const fetchData = async () => {
        try {
          setIsLoading(true);
          const response = await getVendorItemsData();
          const filteredVendorData = selectedRow ? response.data.filter((d) => d.vendorId === selectedRow.id) : response.data;
          setData(filteredVendorData);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching vendor data:', error);
          setIsLoading(false);
        }
      };

      const rowClick = (id) => {
        navigate(`/dashboard/admin/catalog/${id}`);
      };

      useEffect(() => {
        fetchData();
      }, [selectedRow]);

      const columns = createColumns([...CATALOG_ITEMS_PER_VENDOR]);
      return (
        <Paper sx={{ px: 2, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
          <Grid item xs={12}>
          <CustomDataGrid
            gridId="admin-vendors-items-tab"
            boxSX={{ height: 'calc(100vh - 280px)' }}
            data={data}
            forceReRender={data}
            gridColumns={columns}
            sort={[{ field: 'description', sort: 'asc' }]}
            isLoading={isLoading}
            onRowSelectionModelChange={(newSelection) => {
              setSelected(newSelection);
            }}
            onRowClick={(params, e) => {
              const selection = window.getSelection().toString();
              if (selection.length > 0) return;
              if ( e.picture ) return;
              rowClick(params.row.productId);
            }}
            rowSelectionModel={selected}
            disableSelectionOnClick
            isModal
          />
        </Grid>
        </Paper>
      
      );
  }
  