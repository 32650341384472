import { Box, Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { HOST_API_URL } from '../../../config-global';
import { DateRangePickerSelect } from '../../../reusable-components/date-range-picker';
import Iconify from '../../../reusable-components/iconify';
import { ScopeGuard } from '../../../reusable-components/scopes';
import { fCurrency } from '../../../utils/formatNumber';
import ManualInvoiceUploader from './ManualInvoices/ManualInvoiceUploader';
import ProcessManualInvoices from './ManualInvoices/ProcessManualInvoices';
import FindAndReplace from './FindAndReplace';
import UploadPDF from './UploadPDF';
import UploadStatementPDF from './UploadStatementPDF';
import NewVendorModal from './Vendors/NewVendorModal';

CustomToolbar.propTypes = {
  isFiltered: PropTypes.func,
  activeRows: PropTypes.array,
};
export default function CustomToolbar({ isFiltered, activeRows }) {
  const downloadFile = async () => {
    //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
    window.open(
      `${HOST_API_URL}Api/Invoices/DownloadInvoicesExcel?pending=${isFiltered('pending') ? 'true' : 'false'}`,
      '_blank'
    );
  };
  const downloadStatements = async () => {
    window.open(`${HOST_API_URL}Api/Invoices/DownloadInvoiceStatements`, '_blank');
  };
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" alignItems="flex-end" spacing={1} sx={{ mb: 1 }}>
            {!isFiltered('vendors') ? (
              <>
                {isFiltered('new') && (
                  <>
                    <Grid item>
                      <ManualInvoiceUploader />
                    </Grid>
                  </>
                )}
                {isFiltered('new') && (
                  <>
                    <Grid item>
                      <ProcessManualInvoices />
                    </Grid>
                  </>
                )}

                {isFiltered('previous') && (
                  <Grid item>
                    <DateRangePickerSelect />
                  </Grid>
                )}
                {isFiltered('previous') || isFiltered('pending') ? (
                  <Grid item>
                    <Box sx={{ display: 'inline-block', mr: 1 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={downloadFile}
                        startIcon={<Iconify icon="file-icons:microsoft-excel" />}
                      >
                        Download Invoices
                      </Button>
                    </Box>
                  </Grid>
                ) : (
                  !isFiltered('new') &&
                  !isFiltered('reports') &&
                  !isFiltered('pendingFilter') && (
                    <Grid item>
                      <Box sx={{ display: 'inline-block', mr: 1 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={downloadStatements}
                          startIcon={<Iconify icon="file-icons:microsoft-excel" />}
                        >
                          Download Statements
                        </Button>
                      </Box>
                      <UploadStatementPDF />
                    </Grid>
                  )
                )}
                {isFiltered('pending') && (
                  <>
                    <Grid item>
                      <UploadPDF />
                    </Grid>
                    <ScopeGuard scopes={['Facility-Manager']} allowAdmin>
                      <Grid item>
                        <FindAndReplace />
                      </Grid>
                    </ScopeGuard>
                  </>
                )}
                {!isFiltered('reports') && (
                  <Grid item>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                        Total
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'info.main' }}>
                        {isFiltered('pending') || isFiltered('new')
                          ? fCurrency(activeRows.map((item) => item.total).reduce((a, b) => a + b, 0))
                          : isFiltered('pendingFilter')
                            ? fCurrency(activeRows.map((item) => item.total).reduce((a, b) => a + b, 0))
                            : isFiltered('previous')
                              ? fCurrency(
                                activeRows
                                  .filter((item) => item.deleteFlag !== true)
                                  .map((item) => item.total)
                                  .reduce((a, b) => a + b, 0)
                              )
                              : isFiltered('incomplete')
                                ? fCurrency(
                                  activeRows
                                    ?.filter((st) => st.complete === false)
                                    ?.map((item) => item.total)
                                    ?.reduce((a, b) => a + b, 0)
                                )
                                : fCurrency(
                                  activeRows
                                    ?.filter((st) => st.complete === true)
                                    ?.map((item) => item.total)
                                    ?.reduce((a, b) => a + b, 0)
                                )}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item>
                <NewVendorModal />
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    window.open(`${HOST_API_URL}Api/Invoices/DownloadVendors`, '_blank');
                  }}
                  startIcon={<Iconify icon="file-icons:microsoft-excel" />}
                >
                  Download
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
