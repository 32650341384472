import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /ProductCatalog
export async function getCatalogData() {
  const response = await axios.get('/WarehouseCatalog/Catalog');
  return response;
}

// GET
// /ProductOrdered productId
export async function getProductOrderedData(productId) {
  const response = await axios.get(`/WarehouseCatalog/ProductOrdered?productId=${productId}`);
  return response;
}

// GET
// /ProductHistory productId
export async function getProductHistoryData(productId) {
  const response = await axios.get(`/WarehouseCatalog/ProductHistory?productId=${productId}`);
  return response;
}
// /Api/GetProductDetails
export async function getProductDetailsData(productId, flag) {
  const response = await axios.get(
    `/WarehouseCatalog/ProductDetails?productId=${productId}&categoriesAndVendors=${flag}`
  );
  return response;
}
// GET
// /ProductOrderGuideData productId agoraCategoryId
export async function getProductOrderGuideData(productId, agoraCategoryId) {
  const response = await axios.get(
    `/WarehouseCatalog/ProductOrderGuideData?productId=${productId}&agoraCategoryId=${agoraCategoryId}`
  );
  return response;
}

// GET
// /DownloadCatalog
export async function getDownloadCatalogData() {
  const response = await axios.get('/WarehouseCatalog/DownloadCatalog');
  return response;
}

// GET
// /FetchVendorProduct vendorId item, uom
export async function fetchVendorProductData(vendorId, item, uom) {
  const response = await axios.get(`/WarehouseCatalog/FetchVendorProduct?vendorId=${vendorId}&item=${item}&uom=${uom}`);
  return response;
}

// GET
// /PossibleProductUOMs vendorId item
export async function possibleProductUOMsData(vendorId, item) {
  const response = await axios.get(`/WarehouseCatalog/PossibleProductUOMs?vendorId=${vendorId}&item=${item}`);
  return response;
}

// POST
// /DeleteProduct productId
export async function deleteProductData(productId) {
  const response = await axios.post(`/WarehouseCatalog/DeleteProduct?productId=${productId}`);
  return response;
}
export async function combineProductData({ connectTo, connectProductIds }) {
  const response = await axios.post(
    `/WarehouseCatalog/CombineProducts`,
    objectToFormData({ connectTo, connectProductIds })
  );
  return response;
}

export async function postAddProductImage(picture) {
  const formData = new FormData();
  formData.append('picture', picture);
  const response = await axios.post(`/WarehouseCatalog/AddProductImage`, formData);
  return response;
}

// POST
export async function addProductToOrderGuides(permittedFacilities, productId, agoraCategoryId) {
  const response = await axios.post(
    `/WarehouseCatalog/AddProductToOrderGuides?productId=${productId}&agoraCategoryId=${agoraCategoryId}`,
    objectToFormData({
      permittedOrderGuideIds: permittedFacilities,
    })
  );
  return response;
}

export async function productsFormData({ parentData, productData, categories, vendorId, vendorValue }) {
  const { description, notes } = parentData;

  const { agoraCategoryId, agoraSubcategoryId } = categories[0];

  const productCategories =
    categories.length > 1
      ? categories?.slice(1).map((category) => ({
          AgoraCategoryId: category.agoraCategoryId,
          AgoraSubcategoryId: category.agoraSubcategoryId,
        }))
      : [];

  const formattedProductData = [productData].map((product) => ({
    vendorId: vendorValue,
    vendorItemId: vendorId,
    price: product.price,
    uomAmount: product.uomAmount,
    uomType: product.uomType,
    baseUOMAmount: product.baseUOMAmount,
    baseMinUOMAmount: product.baseMinUOMAmount,
    baseUOMType: product.baseUOMType,
    manufacturer: product.manufacturer,
    manufacturerId: product.manufacturerId,
    isPPD: product.isPPD,
    pictures: product.pictureUrls,
    splittable: !!product.uomAmount2,
    vendorItemLink: product.vendorItemLink,
    vendorItemDescription: product.vendorItemDescription,
    productDetailUOM: product.uomAmount2
      ? {
          price: product.price2,
          uomAmount: product.uomAmount2,
          uomType: product.uomType2,
        }
      : {},
  }));

  const submitData = {
    Id: 0,
    Description: description,
    AgoraCategoryId: agoraCategoryId,
    AgoraSubcategoryId: agoraSubcategoryId,
    Notes: notes ?? '',
    ProductCategories: productCategories,
    ProductDetails: formattedProductData,
  };
  const response = await axios.post(`/WarehouseCatalog/ProductForm`, objectToFormData(submitData));
  return response;
}

export async function updateProductFormData({
  categories,
  allItems,
  productId,
  description,
  notes,
  agoraCategoryId,
  agoraSubcategoryId,
}) {
  const submitData = {
    Id: productId,
    Description: description,
    AgoraCategoryId: agoraCategoryId,
    AgoraSubcategoryId: agoraSubcategoryId,
    Notes: notes,
    ProductCategories: categories,
    ProductDetails: allItems,
  };
  const response = await axios.post(`/WarehouseCatalog/ProductForm`, objectToFormData(submitData));
  return response;
}

//GET /Api/DuplicateItem\ vendorId vendorItemId itemId
export async function duplicateItemData(vendorId, vendorItemId, itemId) {
  const response = await axios.get(
    `/WarehouseCatalog/DuplicateItem?vendorId=${vendorId}&vendorItemId=${vendorItemId}&itemId=${itemId}`
  );
  return response;
}
// /Api/SplitProduct
export async function splitProductData({ productId, itemsToSplit }) {
  const submitData = {
    productId,
    itemsToSplit,
  };

  const response = await axios.post(`/WarehouseCatalog/SplitProduct`, objectToFormData(submitData));
  return response;
}
