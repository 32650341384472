import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Iconify from '../../../../../reusable-components/iconify';
import TemplatesTab from './TemplatesTab';

EditRoleModal.propTypes = {
  row: PropTypes.object,
  newTemplate: PropTypes.bool,
};

export default function EditRoleModal({ row, newTemplate }) {
  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpen = () => {
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpenEdit(false);
  };

  return (
    <div>
      {newTemplate ? (
        <Button
          variant="outlined"
          color="secondary"
          sx={{ mb: 1 }}
          size="small"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add new template
        </Button>
      ) : (
        <IconButton color="secondary" onClick={handleClickOpen} disabled={row.isLocked}>
          <EditIcon />
        </IconButton>
      )}
      <Dialog open={openEdit} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {newTemplate ? 'Add New Template' : `Edit ${row?.name} - Template`}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <TemplatesTab row={row} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
