import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, Grid, CircularProgress, Box, Stack } from '@mui/material';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { postTickeTypeForm, getTicketType, getTicketPageData } from '../../../../api/tickets';
import NewTicketTest from '../ticket/newTicket/NewTicketTest';
import TicketFieldGroupForm from './TicketFieldGroupForm';
import AvailibleFieldsTooltip from './AvailibleFieldsTooltip';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';
import DragDropArea from './DragDropArea';
import SpacedRow from '../../../../reusable-components/spaced-row/SpacedRow';
import GroupProgressTab from './GroupProgressTab';

TicketTypeFormModal.propTypes = {
  fieldType: PropTypes.object,
  fieldTypes: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

export default function TicketTypeFormModal({ fieldType, open, onClose, onSave, fieldTypes })
{
  const { enqueueSnackbar } = useSnackbar();

  const availibleFieldsTooltipRef = useRef(null);

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [inputGroups, setInputGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ticketData, setTicketData] = useState(null);
  const [openFieldConfig, setOpenFieldConfig] = useState(-1);
  const [openGroupConfig, setOpenGroupConfig] = useState(-1);
  const [showEndDropAreas, setShowEndDropAreas] = useState(false);
  const [addNewGroup, setAddNewGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");


  useEffect(() => {
    setName(fieldType?.name ?? "");
    setSubject(fieldType?.defaultSubject ?? "");
    setInputGroups([]);
    setOpenFieldConfig(-1);
    setOpenGroupConfig(-1);
    setAddNewGroup(false);
    setNewGroupName("");
    if (fieldType && open) {
      setLoading(true);
      getTicketType(fieldType.id)
        .then(res => {
          setInputGroups(res.data.length > 0 ?
            res.data.map(group => ({
              ...group,
              fields: group.fields.map(field => ({
                ...field,
                type: fieldTypes.find(opt => opt.value === field.ticketFieldTypeId),
                dropdown: [...field.dropdownOptions.map(value => ({ value, label: value })), {}]
              }))
            })) : []);
        })
        .catch(() => enqueueSnackbar('Failed to get input fields', { variant: 'error' }))
        .finally(() => setLoading(false));
    } else setLoading(false);
  }, [open, fieldType, enqueueSnackbar, fieldTypes]);

  useEffect(() => {
    if (open)
    {
      setTicketData(null)
      getTicketPageData()
        .then(res => setTicketData(res.data))
        .catch(() => enqueueSnackbar('Failed to get ticket data', { variant: 'error' }))
    } else setTicketData(null);
  }, [open, enqueueSnackbar]);

  const getFieldHTMLDisplay = (field, group) => !group.miscellaneousFieldsGroup ? `<p>${field.type?.defaultType ? `${field.name} - ` : ""} {${field.id} - ${field.name}}</p>` :
    field.displayHTMLValue || `<p>${field.type?.defaultType ? `${field.name} - ` : ""} {${field.id} - ${field.name}}</p>`;

  const handleSave = async () => {
    let fieldIndex = 0;
    let subjectValue = subject ?? "";
    const inputResult = inputGroups.map(group => {
      group.displayHTMLValue = group.miscellaneousFieldsGroup ? null : (group.displayHTMLValue || group.fields.filter(field => field.name && field.ticketFieldTypeId)
        .map(field => getFieldHTMLDisplay(field, group)).join(""));
      group.fields = group.fields.map(field => {
        fieldIndex++;
        subjectValue = subjectValue.replace(`{${field.id} - ${field.name}}`, `{${fieldIndex} - ${field.name}}`);
        if (group.displayHTMLValue) {
          group.displayHTMLValue = group.displayHTMLValue.replace(`{${field.id} - ${field.name}}`, `{${fieldIndex} - ${field.name}}`)
        }
        field.displayHTMLValue = group.displayHTMLValue ?? getFieldHTMLDisplay(field, group).replace(`{${field.id} - ${field.name}}`, `{${fieldIndex} - ${field.name}}`);
        field.sortPriority = fieldIndex;
        return field;
      });
      return group;
    });
  
    const response = await postTickeTypeForm({
      name,
      id: fieldType?.id ?? 0,
      subject: subjectValue,
      inputFields: inputResult.flatMap((group, index) => group.fields.filter(field => field.name && field.ticketFieldTypeId).map(field => ({
        groupId: index,
        headerGroup: index === 0 || group.headerGroup,
        groupLabel: group.groupLabel,
        miscellaneousFieldsGroup: group.miscellaneousFieldsGroup,
        repeatGroup: group.repeatGroup,
        sortPriority: field.sortPriority,
        name: field.name,
        required: field.required,
        displayHTMLValue: group.displayHTMLValue ?? field.displayHTMLValue,
        ticketFieldTypeId: field.ticketFieldTypeId,
        width: field.width,
        dropdownOptions: field.type?.label === "Dropdown" ? field.dropdown?.map(opt => opt.value) ?? [] : []

      })))
    });
    if (response.status === 200 && response.data) {
      enqueueSnackbar(`Ticket type ${fieldType ? 'edited' : 'added'} successfully`, { variant: 'success' });
      onSave(response.data);
      return;
    }
    enqueueSnackbar(`Failed to ${fieldType ? 'edit' : 'add'} ticket type`, { variant: 'error' });   
  };

  const setFieldValue = (objectKey, fieldId, groupId, inputValue) => {
    setInputGroups(groups => {
      try {
        groups.find(group => group.groupId === groupId).fields.find(field => field.id === fieldId)[objectKey] = inputValue;
        return [...groups];
      } catch {
        return [...groups];
      }
    });
  }

  const addNewFieldAndGroup = (groupName) => {
    setInputGroups(groups => {
      const newGroupId = (groups.reduce((prev, current) => (prev && prev.groupId > current.groupId) ? prev : current, null)?.groupId ?? 0) + 1;
      const newFieldId = (groups.flatMap(group => group.fields.map(field => field.id)).reduce((prev, current) => (prev > current) ? prev : current, 0)) + 1;
      return [...groups, { groupId: newGroupId, fields: [{ id: newFieldId }], miscellaneousFieldsGroup: !groupName, groupLabel: groupName ?? "" }];
    })
  }

  return <Dialog open={open} onClose={onClose} maxWidth={"xl"} fullWidth>
    {!loading ? <>
      <Grid container>
        <Grid item xs={6}>
          <DialogTitle>{fieldType ? 'Edit' : 'Add'} Ticket</DialogTitle>
        </Grid>
        <Grid item xs={6}>
          <DialogTitle>
            Test {(name ? `: ${name}` : "Ticket Input")}
            <CloseIconButton onClick={onClose} />
          </DialogTitle>
        </Grid>
        <Grid item xs={6}>
          <Divider />
          <DialogContent>
            <Typography variant="subtitle1">Type *</Typography>
            <TextField autoComplete={null} size="small" fullWidth value={name} onChange={(e) => setName(e.target.value)} />     
            <Typography mt={1} variant="subtitle1">Subject</Typography>
            <AvailibleFieldsTooltip availibleFieldsTooltipRef={availibleFieldsTooltipRef} fields={inputGroups?.flatMap(group => group.fields) ?? []}>
              <TextField name="ticketTypeSubject" inputRef={availibleFieldsTooltipRef} size="small" fullWidth value={subject} onChange={(e) => setSubject(e.target.value)} />
            </AvailibleFieldsTooltip>
            {inputGroups.length > 0 && <>
              <GroupProgressTab firstGroup groupProgressTab />
              <SpacedRow>
                <Typography variant="subtitle1">Field Name</Typography>
                <Typography variant="subtitle1">Field Type</Typography>
              </SpacedRow>
            </>}

            <DragDropArea setInputGroups={setInputGroups} position={"top"} show={showEndDropAreas} />
            {inputGroups.map((group, index) => <TicketFieldGroupForm
              key={index}
              editGroup={group}
              fieldTypes={fieldTypes}
              setFieldValue={setFieldValue}
              inputGroups={inputGroups}
              setInputGroups={setInputGroups}
              openFieldConfig={openFieldConfig}
              setOpenFieldConfig={setOpenFieldConfig}
              openGroupConfig={openGroupConfig}
              setOpenGroupConfig={setOpenGroupConfig}
              setSubject={setSubject}
              setShowEndDropAreas={setShowEndDropAreas}
              isFirstGroup={index === 0}
            />)}
           <DragDropArea setInputGroups={setInputGroups} position={"bottom"} show={showEndDropAreas} />

            {addNewGroup && <Box sx={{ backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "5px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
              <Stack sx={{ backgroundColor: "#fff", padding: "10px", borderRadius: "3px", boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)" }} spacing={1}>
                <Typography variant="subtitle1">Group Name</Typography>
                <TextField fullWidth size="small" sx={{ backgroundColor: "white !important" }} value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)} />
                <Box>
                  <AddButton disabled={!newGroupName}  onClick={() => { addNewFieldAndGroup(newGroupName); setNewGroupName(""); setAddNewGroup(false); }}>
                    Add field
                  </AddButton>
                  <Button variant="outlined" color="error" size="small" sx={{ backgroundColor: "white !important" }} onClick={() => { setNewGroupName(""); setAddNewGroup(false); }}>
                    Cancel
                  </Button>
                </Box>
              </Stack>
            </Box>}

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              <AddButton onClick={() => addNewFieldAndGroup(null)}>Add Field</AddButton>
              <AddButton disabled={addNewGroup} onClick={() => setAddNewGroup(true)}>New Group</AddButton>      
            </Typography>
          </DialogContent>
        </Grid>
        <Grid item><Divider orientation="vertical" sx={{ bgcolor: "darkgray" }} /></Grid>
        <Grid item xs>
          <Divider />
          <DialogContent>
            {ticketData && <NewTicketTest
              isTestTicket
              facilities={ticketData.facilities}
              users={ticketData.users}
              ticketCategories={ticketData.ticketCategories}
              ticketTypes={[...ticketData.ticketTypes, {
                id: -1,
                name: `${name} (Currently Editing)`,
                defaultSubject: subject,
                fieldGroups: inputGroups?.map((group, index) => ({
                  ...group,
                  headerGroup: index === 0 || group.headerGroup,
                  displayHTMLValue: group.miscellaneousFieldsGroup ? null : group.displayHTMLValue || group.fields.filter(field => field.name && field.ticketFieldTypeId)
                    .map(field => getFieldHTMLDisplay(field, group)).join(""),
                  fields: (group.fields?.filter(field => field?.name && field.type).map(field => ({
                    id: field.id,
                    type: field.type?.type,
                    width: field.width,
                    name: field.name,
                    displayHTMLValue: getFieldHTMLDisplay(field, group),
                    groupLabel: field.groupLabel,
                    required: field.required,
                    dropdownOptions: field.dropdown?.filter(opt => opt.value),
                    addUser: null
                  })) ?? []).filter(field => field.type && field.name)
                })).filter(group => group.fields)
              }]}
            />}
          </DialogContent>
        </Grid>
      </Grid>
    </> : <CircularProgress sx={{ alignSelf: "center", margin: "50px" }} />}
    <Divider />
    <DialogActions>
      <Button onClick={onClose} color="error" variant="outlined">
        Cancel
      </Button>
      <AwaitButton onClick={handleSave} color="primary" variant="contained" disabled={!name || loading}>
        {fieldType ? 'Save' : 'Add Ticket Type'}
      </AwaitButton>
    </DialogActions>
  </Dialog>;
}