import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Autocomplete, Stack, Typography, Box, Button, TextField, DialogActions, IconButton } from '@mui/material';
import { postFacilityBookingAccountForm } from '../../../../api/facility';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { searchIntacctForVendor } from '../../../../api/vendors';
import Iconify from '../../../../reusable-components/iconify/Iconify';

BookingAccountForm.propTypes = {
  fetchData: PropTypes.func,
  editRow: PropTypes.object,
  facilityRow: PropTypes.object,
  vendors: PropTypes.array,
  vendorBookingTypes: PropTypes.array,
  openForm: PropTypes.bool,
  close: PropTypes.func,
  bookingType: PropTypes.object
};

export default function BookingAccountForm({
  fetchData,
  vendors,
  vendorBookingTypes,
  editRow,
  facilityRow,
  openForm,
  close,
  bookingType
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    vendor: '',
    account: '',
  });
  const [searchIntacct, setSearchIntacct] = useState('');
  const [intacctData, setIntacctData] = useState([]);
  const [loadingIntacctData, setLoadingIntacctData] = useState(null);

  useEffect(() => {
    setValue({
      vendor: editRow ? { id: editRow.vendorId, label: editRow.vendor } : '',
      account: editRow ? { id: editRow.bookingVendorId, label: editRow.account } : ''
    });
    setLoadingIntacctData(null);
    setSearchIntacct('');
    setIntacctData([]);
  }, [editRow, openForm])

  const fetchIntacctVendorAccounts = async () =>
  {
    try {
      setLoadingIntacctData(true);
      const response = await searchIntacctForVendor(searchIntacct, bookingType.id);
      setIntacctData(response.data.map((data, index) => ({...data, id: index})));
    } catch {     
      enqueueSnackbar('Failed to fetch Intacct data', { variant: 'error' })
    } finally {
      setLoadingIntacctData(false);
    }
  }

  const handleSave = async () => {
    const response = await postFacilityBookingAccountForm(value, facilityRow?.id, editRow?.id);
    if (response.data?.message) {
      enqueueSnackbar(response.data?.message, {
        variant: response.data?.message === 'Account Added' || response.data?.message === 'Account Updated' ? 'success' : 'error',
      });
    } else {
      enqueueSnackbar('Failed to add booking account', { variant: 'error' });
    }
    fetchData();
    close();
  };

  return <Dialog open={openForm} onClose={close} maxWidth="md" fullWidth>
    <DialogTitle sx={{ textAlign: 'center' }} >
      {editRow ? 'Edit' : 'Add'} Booking Account
      <CloseIconButton onClick={close} />
    </DialogTitle>
    <DialogContent sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Stack spacing={3}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Vendor
            </Typography>
            <Autocomplete
              fullWidth
              size="small"
              value={value.vendor}
              onChange={(event, newValue) => {
                setValue({
                  vendor: newValue,
                  account: '',
                });
              }}
              options={vendors?.map((type) => ({ id: type.value, label: type.label }))}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} label="Search or select" />}
              sx={{
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>       
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Booking Vendor Account
            </Typography>
            <Autocomplete
              fullWidth
              size="small"
              value={value.account}
              onChange={(event, newValue) => {
                setValue({
                  ...value,
                  account: newValue,
                });
              }}
              options={vendorBookingTypes
                ?.filter((type) => type.vendorId === value?.vendor?.id)
                ?.map((type) => ({ id: type.id, label: type.account }))}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} label="Search or select" />}
              sx={{
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>   
          {bookingType?.useIntacct && <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }} >
              Search: {bookingType.label}
            </Typography>
            <TextField
              size="small"
              value={searchIntacct}
              onChange={(e) => setSearchIntacct(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: <IconButton
                  color="secondary"
                  sx={{ display: searchIntacct === '' ? 'none' : 'inline-flex' }}
                  onClick={fetchIntacctVendorAccounts}
                >
                  <Iconify icon={'fa6-brands:searchengin'} sx={{ cursor: 'pointer' }} />
                </IconButton>
              }}
            />
          </Box>}
          {loadingIntacctData != null &&<CustomDataGrid
              gridId="admin-booking-account-intacctSearch"
              data={intacctData}
              boxSX={{ height: '300px' }}
              scrollbarHeight={null}
              tableBoxSX={{height: 'calc(100% - 50px)' }}
              gridColumns={[{
                field: 'account',
                headerName: 'Account',
                flex: 0.5,
                type: 'customText',
              },
              {
                field: 'name',
                headerName: 'Name',
                flex: 0.5,
                type: 'customText',
              }]}
              isLoading={loadingIntacctData}
            />}
        </Stack>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={close}>
        Cancel
      </Button>
      <AwaitButton
        variant="contained"
        color="primary"
        size="medium"
        disabled={value.vendor === '' || value.account === ''}
        sx={{ width: 150 }}
        onClick={handleSave}
      >
        {editRow ? 'Save' : 'Add Account'}
      </AwaitButton>
    </DialogActions>
  </Dialog>;
}
