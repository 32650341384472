import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';
import { fCurrency, fNumber } from '../../../../../utils/formatNumber';
import { useSelector, useDispatch } from '../../../../../redux/store';
import { resetTempCart, addItemsToCart } from '../../../../../redux/slices/orderSheet';
import { CategoryDropdown, CopyOrder, VendorOrder } from './index';
import { ScopeGuard } from '../../../../../reusable-components/scopes';
import { AwaitButton } from '../../../../../reusable-components/await-button';

CategoryNavbar.propTypes = {
  setData: PropTypes.func,
  setSortModel: PropTypes.func,
  setCopiedCart: PropTypes.func
};

export default function CategoryNavbar({ setCopiedCart = () => {}, setData, setSortModel }) {
  const dispatch = useDispatch();
  const { facilityId = '' } = useParams();
  const {
    data: { cart },
  } = useSelector((state) => state.orderSheet);
  const urlParams = new URLSearchParams(window.location.search);
  const categoryId = urlParams.get('categoryId');
  const resetQuantity = (added) => {
    dispatch(resetTempCart(added));
  };

  const addCart = () => {
    dispatch(addItemsToCart(cart, facilityId, categoryId));
    resetQuantity(cart.map((item) => item.quantity).reduce((a, b) => a + b, 0));
    setData(items => items.map(item => ({ ...item, quantity: null })));
  };

  return (
    <Grid container direction="row" alignItems="center" justifyContent="space-between">
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <CategoryDropdown setData={setData} />
          <CopyOrder setCopiedCart = {setCopiedCart} setSortModel={setSortModel} />
          <ScopeGuard scopes={['Punchout']} allowAdmin>
            <VendorOrder />
          </ScopeGuard>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {cart.length ? (
            <>
              <Grid item>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    Total
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                    {fCurrency(cart.map((item) => item.price * item.quantity).reduce((a, b) => a + b, 0))}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <AwaitButton
                  onClick={addCart}
                  variant="outlined"
                  color="info"
                  sx={{
                    mr: 1,
                    mt: 1,
                  }}
                >
                  Add {fNumber(cart.length)} items to cart
                </AwaitButton>
              </Grid>{' '}
            </>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
