import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  DialogContentText,
  Button,
  TextField,
  DialogActions,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from '../../../../redux/store';
import { postStatusCodeForm, getNewStatusCodesData } from '../../../../api/vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

StatusModal.propTypes = {
  fetchData: PropTypes.func,
  edit: PropTypes.bool,
  row: PropTypes.object,
  agoraCodes: PropTypes.array,
};

const filter = createFilterOptions();

export default function StatusModal({ fetchData, edit, row, agoraCodes }) {
  const {
    data: { vendors, facilities },
  } = useSelector((state) => state.vendors);
  const [open, setOpen] = useState(false);
  const [vendorStatusCodes, setVendorStatusCodes] = useState([]);
  const [agoraStatusCodes, setAgoraStatusCodes] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    vendor: '',
    code: '',
    agoraCode: '',
  });
  const fetchVendorStatus = async (vendorId) => {
    try {
      const response = await getNewStatusCodesData(vendorId);
      const { data } = response;
      const keyPair = data?.map((status, index) => ({
        id: index,
        label: status,
      }));
      setVendorStatusCodes(keyPair);
    } catch (error) {
      console.error('Error fetching vendor codes:', error);
    }
  };

  useEffect(() => {
    if (value?.vendor?.id) fetchVendorStatus(value?.vendor?.id);
  }, [value?.vendor?.id]);

  useEffect(() => {
    const keyPair = agoraCodes.map((status, index) => ({
      id: index,
      label: status.agoraCode,
    }));
    setAgoraStatusCodes(keyPair);
  }, [agoraCodes]);

  const handleSave = async () => {
    const response = await postStatusCodeForm(value, edit ? row?.id : null);
    if (response) {
      enqueueSnackbar('Status code added successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to add status code', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      vendor: edit
        ? { id: row.purchasingVendorId, label: vendors?.find((vendor) => vendor?.id === row?.purchasingVendorId)?.name }
        : '',
      code: edit ? { id: -1, label: row.code } : '',
      agoraCode: edit ? { id: -1, label: row.agoraCode } : '',
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      vendor: '',
      code: '',
      agoraCode: '',
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
          size="small"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Status Code
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {edit ? 'Edit' : 'Add'} Status Code
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                value={value.vendor}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    vendor: newValue,
                  });
                }}
                options={vendors?.map((type) => ({ id: type.id, label: type.name }))}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor Code</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                value={value.code}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    code: newValue,
                  });
                }}
                options={vendorStatusCodes}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.label);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      label: inputValue,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.label;
                }}
                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                renderInput={(params) => <TextField {...params} label="Select or add your own" />}
                sx={{
                  mt: 0.5,
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>PS Code</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                freeSolo
                forcePopupIcon
                value={value.agoraCode}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    agoraCode: newValue,
                  });
                }}
                options={agoraStatusCodes}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Select or add your own" />}
                sx={{
                  mt: 0.5,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.vendor === '' || value.code === '' || value.agoraCode === ''}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            {edit ? 'Save' : 'Add Status'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
