import PropTypes from 'prop-types';
import { Typography, Box, Stack } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify/Iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';

AvailibleFields.propTypes = {
  fields: PropTypes.array,
  availibleFieldsTooltipRef: PropTypes.object
};

export default function AvailibleFields({ fields, availibleFieldsTooltipRef }) {
  const { enqueueSnackbar } = useSnackbar();

  return <Stack sx={{ fontSize: 15 }} spacing={1}>
    {fields.filter(field => field.name && field.ticketFieldTypeId && field.type?.type !== "No Input Text").map((field, index) =>
      <Box key={index}>
        {index === 0 && <Typography variant="subtitle1" sx={{ mt: 1 }}>Availible Fields</Typography>}
        <Box display="flex" alignItems="center" key={index}>
          {`{${field.id} - ${field.name}}`}
          <Iconify icon={'ph:copy'} color="secondary" sx={{ fontSize: 15 }} onClick={() => {
            navigator.clipboard.writeText(`{${field.id} - ${field.name}}`);
            enqueueSnackbar("Value Copied", { variant: "success" });
            availibleFieldsTooltipRef?.current.focus();
          }} />
        </Box>
      </Box>)}
  </Stack>;
}