import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Typography,
  Box,
  Button,
  TextField,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
  DialogContentText,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { addOrderGuide } from '../../../../api/order-guides';
import { getOrderGuides } from '../../../../redux/slices/orderGuides';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function NewOrderGuideTemplateDialog() {
  const {
    data: { orderGuideActivations, categories, templates },
  } = useSelector((state) => state.orderGuides);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    facility: '',
    category: '',
    facilityCopy: '',
    copyPreferences: false,
    copyFrom: 'Facility',
  });
  const handleClickOpen = () => {
    setValue({
      facility: '',
      category: '',
      facilityCopy: '',
      copyPreferences: false,
      copyFrom: 'Facility',
    });
    setOpen(true);
  };

  const handleClose = () => {
    setValue({
      facility: '',
      category: '',
      facilityCopy: '',
      copyPreferences: false,
      copyFrom: 'Facility',
    });

    setOpen(false);
  };

  const handleSave = async () => {
    const response = await addOrderGuide(value);
    if (response?.data?.id) {
      dispatch(getOrderGuides());
      navigate(`/dashboard/admin/order-guides/${response.data.id}`);
    } else if (response?.data?.error) {
      enqueueSnackbar(`Failed to add order guide. ${response?.data?.error}`, { variant: 'error' });
    } else {
      enqueueSnackbar('Failed to add order guide', { variant: 'error' });
    }
    handleClose();
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        Add Order Guide Template
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          New Order Guide Template
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ p: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">Template Name*</Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={value.templateName}
                  onChange={(event) => {
                    setValue({
                      ...value,
                      templateName: event.target.value,
                    });
                  }}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Category*
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value.category}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      category: newValue,
                    });
                  }}
                  options={categories?.map((type) => ({ id: type.value, label: type.label }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Grid item>
                  <DialogContentText sx={{ fontWeight: 'bold' }}>Copy From</DialogContentText>
                  <ToggleButtonGroup
                    value={value.copyFrom}
                    exclusive
                    onChange={(event, newValue) => {
                      // const boolValue = newValue === 'true';
                      setValue({
                        ...value,
                        facilityCopy: '',
                        copyFrom: newValue,
                      });
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="Facility">Facility</ToggleButton>
                    <ToggleButton value="Template">Template</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {value.copyFrom} to copy from
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value.facilityCopy}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      facilityCopy: newValue,
                    });
                  }}
                  options={
                    value.copyFrom === 'Facility'
                      ? orderGuideActivations
                          ?.filter((type) => type.agoraCategoryId === value.category.id)
                          .map((type) => ({ id: type.id, label: type.facility }))
                      : templates?.map((type) => ({ id: type.id, label: type.templateName }))
                  }
                  // options={templates?.map((type) => ({ id: type.value, label: type.label }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.templateName === '' || value.category === '' || value.facilityCopy === ''}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
