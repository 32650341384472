import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { forgotPassword } from '../../../../../api/account';
import { resendEmailConfirmation, updateUserSecurity } from '../../../../../api/user';
import { NEW_PASSWORD_SCHEMA } from '../../../../../reusable-components/form/authSchema';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../../reusable-components/await-button';

SecurityTab.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  user: PropTypes.object,
};

export default function SecurityTab({ edit, setEdit, user }) {
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    emailConfirmed: user.emailConfirmed,
    password: null,
    id: user.id,
  });

  useEffect(() => {
    setValue({
      emailConfirmed: user.emailConfirmed,
      password: null,
      id: user.id,
    });
  }, [user]);
  const [passwordError, setPasswordError] = useState('');

  const validatePassword = async () => {
    try {
      await NEW_PASSWORD_SCHEMA.validateAt('passwordOptional', { passwordOptional: value.password });
      setPasswordError(''); // Clear previous error message
      return true; // Validation passed
    } catch (error) {
      setPasswordError(error.message); // Set new error message
      return false; // Validation failed
    }
  };

  const onSubmit = async () => {
    const isValidPassword = await validatePassword();

    if (!isValidPassword) {
      if (passwordError) {
        enqueueSnackbar(passwordError, { variant: 'error' });
      }
      return; // Stop submission if password validation fails
    }

    const response = await updateUserSecurity(value);

    if (response) {
      setEdit(false);
      enqueueSnackbar('User information updated', { variant: 'success' });
    }
  };

  const handleCancel = () => {
    setValue({
      emailConfirmed: user.emailConfirmed,
      password: null,
      id: user.id,
    });

    setEdit(false);
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item sx={{ mt: 3 }}>
        <Typography variant="subtitle1" >
          Email confirmed
        </Typography>
        <FormControl>
          <RadioGroup row value={value.emailConfirmed} sx={{ width: '25vw' }}>
            <FormControlLabel
              value
              control={<Radio />}
              label="Confirmed"
              disabled={!edit}
              onChange={() => setValue({ ...value, emailConfirmed: true })}
              sx={{
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: '#212B36',
                },
                '& .MuiButtonBase-root.MuiRadio-root.Mui-disabled': {
                  color: value.emailConfirmed ? '#8dc63f' : '#212B36',
                },
              }}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Unconfirmed"
              disabled={!edit}
              onChange={() => setValue({ ...value, emailConfirmed: false })}
              sx={{
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: '#212B36',
                },
                '& .MuiButtonBase-root.MuiRadio-root.Mui-disabled': {
                  color: value.emailConfirmed ? '#212B36' : '#8dc63f',
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Password
        </Typography>
        <TextField
          name="password"
          fullWidth
          placeholder="Password"
          sx={{ width: '25vw' }}
          value={edit ? (value.password ? value.password : '') : '*************'}
          onChange={(e) => setValue({ ...value, password: e.target.value })}
          InputProps={{
            readOnly: !edit,
          }}
          error={!!passwordError}
          helperText={passwordError}
        />
      </Grid>

      <Grid item sx={{ mt: 1 }}>
        {user.emailConfirmed ? (
          <AwaitButton
            variant="outlined"
            size="medium"
            color="info"
            onClick={async () => {
              const response = await forgotPassword(user.email);
              if (response) {
                enqueueSnackbar('Reset password email sent', { variant: 'success' });
              }
            }}
          >
            {' '}
            Send reset password email
          </AwaitButton>
        ) : (
          <AwaitButton
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={async () => {
              await resendEmailConfirmation(user.id);
              enqueueSnackbar('Email confirmation sent', { variant: 'success' });
            }}
          >
            Resend confirmation email
          </AwaitButton>
        )}
      </Grid>

      {edit ? (
        <Grid item sx={{ mt: 2 }}>
          <Button onClick={handleCancel} variant="outlined" color="error" size="medium" sx={{ width: 200, mr: 3 }}>
            Cancel
          </Button>
          <AwaitButton color="primary" size="medium" type="submit" variant="contained" sx={{ width: 200 }} onClick={onSubmit}>
            Save
          </AwaitButton>
        </Grid>
      ) : null}
    </Grid>
  );
}
