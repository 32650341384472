import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { RENTAL_CATALOG } from '../../../reusable-components/datagrid/rentalColumns';
import { useSelector, useDispatch } from '../../../redux/store';
import { getRentalItems } from '../../../redux/slices/rentals';
// import CustomToolbar from '../../admin/catalog/CustomToolbar';

export default function RentalCatalog() {
  const dispatch = useDispatch();
  const {
    data: { items },
  } = useSelector((state) => state.rentals);

  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const columns = useMemo(() => [...RENTAL_CATALOG]);
  // const customToolbar = () => <CustomToolbar />;
  const rowClick = (id) => {
    // navigate(`/dashboard/admin/catalog/${id}`);
  };
  useEffect(() => {
    dispatch(getRentalItems());
  }, [dispatch]);
  useEffect(() => {
    if (items?.length > 0) setData(items);
  }, [items]);

  return (
    <>
      <Helmet>
        <title>Rentals Catalog | Platform Solutions</title>
      </Helmet>
      <Typography variant="subtitle1" sx={{ ml: 6, mt: 1, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}>
        Rental Items Catalog
      </Typography>
      <Grid item xs={selected.length > 0 ? 10 : 12}>
        <CustomDataGrid
          boxSX={{ height: 'calc(100vh - 235px)' }}
          checkboxSelection={selected.length > 0}
          //CustomLeftToolbar={customToolbar}
          data={data}
          displayCheckboxRight
          getRowId={(row) => row.itemId}
          gridColumns={columns}
          gridId="admin-rental-catalog"
          //isLoading={isLoading}
          keepNonExistentRowsSelected
          onRowSelectionModelChange={(newSelection) => {
            setSelected(newSelection);
          }}
          onRowClick={(params, e) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            if (e.addToOrderGuide || e.itemHistory || e.combine || e.picture || e.confirmDialog) return;
            rowClick(params.row.id);
          }}
          rowSelectionModel={selected}
          sort={[{ field: 'description', sort: 'asc' }]}
        />
      </Grid>
    </>
  );
}
