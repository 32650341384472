import { useLocation, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Divider, Typography, Badge } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import AccountPopover from './AccountPopover';
import CustomTabs from './CustomTabs';
import { fNumber } from '../../../utils/formatNumber';
import { Logo } from '../../../reusable-components/logo';
import { ScopeGuard, useScopeCheck } from '../../../reusable-components/scopes';
import NewTicket from './newTicket/NewTicket';
import NewOrder from './NewOrder';
import GlobalCart from './GlobalCart';
import OrderRentalGlobalCart from './OrderRentalGlobalCart';
import SiteSwitch from './SiteSwitch';
import { useSelector } from '../../../redux/store';
import { useTabs } from '../../../context/TabContext';

export default function Header() {
  const { pathname } = useLocation();
  const isSuperAdmin = useScopeCheck([], false, true);
  const { unreadCount } = useSelector((state) => state.tickets);
  const { unreadOrders } = useSelector((state) => state.orders);
  const { pendingCount } = useSelector((state) => state.invoicing);
  const checkPath = (path) => pathname.includes(`/dashboard/${path}`);


  // const handleGoBack = () => {
  //   navigate(-1); // Go back to the previous page
  // };

  const isAdmin = useScopeCheck([], true);
  const isItemCatalogView = useScopeCheck(['Item-Catalog-View'], true);
  const isViewOrderGuide = useScopeCheck(['View-OrderGuide'], true);
  const isReviewOrder = useScopeCheck(['Edit-ReviewWorkflow'], true);
  const isViewBudgets = useScopeCheck(['View-Budgets'], true);
  const isViewCensus = useScopeCheck(['View-Census'], true);

  const applyParams = (startDate, endDate) => {
    const formattedStartDate = startDate?.toISOString();
    const formattedEndDate = endDate?.toISOString();
    return `?start=${formattedStartDate}&end=${formattedEndDate}`;
  };

  const { clearFiltersAndNavigate } = useTabs();

  return (
    <>
      <StyledRoot position="fixed">
        <CustomTabs />
        <StyledToolbar>
          <Box>
            <Logo
              sx={{
                mr: 1.5,
                mt: 1,
                bgcolor: 'background.default',
              }}
            />
          </Box>
          <ScopeGuard scopes={['View-Order']} allowAdmin>
            <Typography
              sx={{ ml: 2, cursor: 'pointer' }}
              variant="subtitle1"
              color={checkPath('orders') ? 'primary' : 'text.secondary'}
              onClick={() => clearFiltersAndNavigate('/dashboard/orders?filter=pending')}
            >
              Orders {unreadOrders > 0 ? `(${fNumber(unreadOrders)})` : ''}
            </Typography>
          </ScopeGuard>

          <Typography
            sx={{ ml: 2, cursor: 'pointer' }}
            variant="subtitle1"
            color={checkPath('tickets') ? 'primary' : 'text.secondary'}
            onClick={() => clearFiltersAndNavigate('/dashboard/tickets?filter=active')}
          >
            Tickets {unreadCount > 0 ? `(${fNumber(unreadCount)})` : ''}
          </Typography>
          <ScopeGuard scopes={['Invoice-View']} allowAdmin>
            <Typography
              sx={{ ml: 2, cursor: 'pointer' }}
              variant="subtitle1"
              color={checkPath('invoicing') ? 'primary' : 'text.secondary'}
              onClick={() => clearFiltersAndNavigate('/dashboard/invoicing/pending')}
            >
              Invoicing {pendingCount > 0 ? `(${fNumber(pendingCount)})` : ''}
            </Typography>
          </ScopeGuard>
          <ScopeGuard
            scopes={['Item-Catalog-View', 'View-OrderGuide', 'Edit-ReviewWorkflow', 'View-Budgets', 'View-Census', 'User-View']}
            allowAdmin
          >
            <Typography
              sx={{ ml: 2, cursor: 'pointer' }}
              variant="subtitle1"
              color={checkPath('admin') ? 'primary' : 'text.secondary'}
              onClick={() =>
                clearFiltersAndNavigate(
                  `/dashboard/admin/${isAdmin || isItemCatalogView
                    ? 'catalog'
                    : isViewOrderGuide
                      ? 'order-guides'
                      : isViewBudgets
                        ? 'budgets'
                        : isViewCensus
                          ? 'census'
                          : isReviewOrder ?
                            'facilities'
                            : 'users'
                  }`
                )
              }
            >
              Admin
            </Typography>
          </ScopeGuard>
          <ScopeGuard scopes={['Super-Admin']}>
            <Typography
              sx={{ ml: 2, cursor: 'pointer' }}
              variant="subtitle1"
              color={checkPath('super-admin') ? 'primary' : 'text.secondary'}
              onClick={() => clearFiltersAndNavigate('/dashboard/super-admin/item-warehouse')}
            >
              Super Admin
            </Typography>
          </ScopeGuard>
          <ScopeGuard scopes={['View-PurchasingReports', 'View-OtherReports']} allowAdmin>
            <Typography
              sx={{ ml: 2, cursor: 'pointer' }}
              variant="subtitle1"
              color={checkPath('reports') ? 'primary' : 'text.secondary'}
              onClick={() =>
                clearFiltersAndNavigate(
                  `/dashboard/reports${applyParams(
                    new Date(new Date().setDate(new Date().getDate() - 29)),
                    new Date()
                  )}`
                )
              }
            >
              Reports
            </Typography>
          </ScopeGuard>

          {isSuperAdmin && (
            <Badge
              badgeContent="Beta"
              color="info"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiBadge-badge': {
                  right: 0,
                  top: -2,
                  border: '2px solid #fff',
                  padding: '0 4px',
                },
              }}
            >
              <Typography
                sx={{ ml: 2, cursor: 'pointer' }}
                variant="subtitle1"
                color={checkPath('rentals') ? 'primary' : 'text.secondary'}
                onClick={() => clearFiltersAndNavigate('/dashboard/rentals/pending-rentals')}
              >
                Rentals
              </Typography>
            </Badge>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {' '}
            <NewTicket />
            <ScopeGuard scopes={['View-Order']} allowAdmin>
              <NewOrder />
            </ScopeGuard>
            {/* <NotificationsPopover /> */}
            <ScopeGuard scopes={['View-Order']} allowAdmin>
              {isSuperAdmin ? <OrderRentalGlobalCart /> : <GlobalCart />}
            </ScopeGuard>
            <ScopeGuard scopes={['Super-Admin']}>
              <SiteSwitch />
            </ScopeGuard>
            <AccountPopover />
          </Stack>
        </StyledToolbar>
        <Divider
          sx={{
            ml: 34,
            mr: 14,
            height: '5px',
            opacity: 0.39,
            backgroundImage: `linear-gradient(269deg, #8dc63f 50%, #707070)`,
          }}
        />
      </StyledRoot>
    </>
  );
}
//

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default, opacity: 1 }),
  boxShadow: 'none',
  width: '100%',
  paddingTop: '20px',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: 92,
  marginLeft: 100,
  marginRight: 100,
  paddingLeft: '8px',
}));
