import PropTypes from 'prop-types';
import { Box, CircularProgress, DialogContentText } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { TICKET_ORDERS } from '../../../../../../reusable-components/datagrid/orderColumns';
import { useSnackbar } from '../../../../../../reusable-components/snackbar';
import { getOrderItemsForTicket } from '../../../../../../api/tickets';
import TicketOrderSearch from './TicketOrderSearch';

OrderSearchWithItemTable.propTypes = {
  group: PropTypes.object,
  field: PropTypes.object,
  setFieldValue: PropTypes.func,
  facilityId: PropTypes.number,
};

export default function OrderSearchWithItemTable({ field, facilityId, setFieldValue, group })
{
  const { enqueueSnackbar } = useSnackbar();

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderItems, setOrderItems] = useState(null);

  useEffect(() => {
    setOrderItems(null);
    if (selectedOrder) {
      getOrderItemsForTicket(selectedOrder.id)
        .then(res => setOrderItems(res.data.map((item, index) => ({ ...item, id: index }))))
        .catch(() => {
          enqueueSnackbar('Failed to get order items', { variant: 'error' });
          setOrderItems([]);
        });
    }
  }, [selectedOrder])

  return (
    <Box>
      <TicketOrderSearch facilityId={facilityId} onChange={(order) => setSelectedOrder(order)} />
      {orderItems ? <>
        <DialogContentText sx={{ mt: 3 }}>
          Select items from order
        </DialogContentText>
        <Box sx={{ height: orderItems.length * 44 + 88 > 300 ? 300 : orderItems.length * 44 + 88, width: '100%', mb: 3 }}>
          <DataGridPro
            getRowHeight={() => 'auto'}
            rows={orderItems}
            columns={TICKET_ORDERS}
            checkboxSelection
            getRowId={(row) => row?.id}
            disableSelectionOnClick
            rowHeight={44}
            hideFooter
            onRowSelectionModelChange={(newSelection) => {
              const itemList = orderItems.filter(item => newSelection.includes(item.id)).map(item => `<li>${item.psNumber ?
                `<a href="/dashboard/admin/catalog/${item.psNumber.replace("PS", "")}">${item.psNumber}</a> - ` : ``}` +
                `${item.description} | Qty: ${item.quantity}</li>`).join("");
              setFieldValue(field, group, {
                value: newSelection,
                htmlValue: selectedOrder ? "<p>Hi, I need assistance with these items on " +
                  `<a href="/dashboard/orders/${selectedOrder.id}">${selectedOrder.label}</a><p> </p>` +
                  `<ul>${itemList}</ul></p>`: "",
                subjectValue: selectedOrder?.orderNumber,
                addUser: selectedOrder?.submittedBy,
                orderId: selectedOrder?.id
              });     
            }}
            rowSelectionModel={field.value || []}
          />
        </Box>
      </> : selectedOrder  ? <Box sx={{ textAlign: "center", mt: 2 }}><CircularProgress sx={{ alignSelf: "center" }} /></Box> : ""}
    </Box>
  );
}