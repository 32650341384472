import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Slider, Box, Typography, TextField, InputAdornment } from '@mui/material';
import { useTabs } from '../../../context/TabContext';

CustomMoneyPanel.propTypes = {
  activeFilter: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  maxValue: PropTypes.number,
  gridId: PropTypes.string,
};

export default function CustomMoneyPanel({ activeFilter, filters, setFilters, maxValue, gridId }) {
  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const { activeTab } = useTabs();
  const sessionStorageKey = `tab-${activeTab.key}-filters-${baseUrl}-${gridId}`;
  const [value, setValue] = useState(filters[activeFilter]?.value || [0, maxValue]);

  const updateSessionStorage = (newFilters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(newFilters));
  };

  useEffect(() => {
    setValue(filters[activeFilter]?.value || [0, maxValue || 10000]);
  }, [activeFilter, filters, maxValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newFilters =
      newValue[0] === 0 && newValue[1] === maxValue
        ? { ...filters }
        : { ...filters, [activeFilter]: { value: newValue, operator: 'range' } };

    if (newValue[0] === 0 && newValue[1] === maxValue) {
      delete newFilters[activeFilter];
    }

    setFilters(newFilters);
    updateSessionStorage(newFilters);
  };

  const handleTextFieldChange = (index) => (event) => {
    const newValue = [...value];
    newValue[index] = Number(event.target.value);
    setValue(newValue);

    const newFilters =
      newValue[0] === 0 && newValue[1] === maxValue
        ? { ...filters }
        : { ...filters, [activeFilter]: { value: newValue, operator: 'range' } };

    if (newValue[0] === 0 && newValue[1] === maxValue) {
      delete newFilters[activeFilter];
    }

    setFilters(newFilters);
    updateSessionStorage(newFilters);
  };

  return (
    <Box>
      <Typography id="range-slider" gutterBottom>
        Price Range
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <TextField
          type="number"
          size="small"
          value={value[0]}
          onChange={handleTextFieldChange(0)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
          }}
        />
        <Box sx={{ mx: 1 }} />
        <TextField
          type="number"
          size="small"
          value={value[1]}
          onChange={handleTextFieldChange(1)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
          }}
        />
      </Box>
      <Box sx={{ mt: 1, mx: 1.5 }}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          min={0}
          max={maxValue || 10000}
          step={maxValue / 100}
        />
      </Box>
    </Box>
  );
}
