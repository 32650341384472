import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Typography, MenuItem, Popover, Chip } from '@mui/material';
import { CustomAvatar } from '../../../reusable-components/custom-avatar';
import { useSelector } from '../../../redux/store';
import { logout } from '../../../redux/slices/user';
import { useScopeCheck } from '../../../reusable-components/scopes';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const isUserView = useScopeCheck(['User-View'], true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { firstName, lastName, email, id, roles } = user;
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleLogout = async () => {
    dispatch(logout());
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <CustomAvatar
        name={`${firstName} ${lastName}`}
        showTooltip={false}
        onClick={handleOpen}
        sx={{
          cursor: 'pointer !important',
        }}
      />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
          },
        }}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <MenuItem
          onClick={() => {
            navigate(`/dashboard/home/user/${id}?tab=user-information`);
            setOpen(null);
          }}
          sx={{ mt: 1 }}
        >
          <Box>
            <Typography variant="subtitle2" noWrap>
              {firstName} {lastName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {email}
            </Typography>
          </Box>
        </MenuItem>
        {/* <MenuItem>
          <Box
            component="div"
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              py: 0.5,
            }}
            maxWidth="200px"
          >
            {roles.map((role, index) => (
              <Chip sx={{ mt: 0.5, mr: 0.5 }} key={index} label={role} size="small" />
            ))}
          </Box>
        </MenuItem> */}
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
