import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { findAndReplace } from '../../../api/invoicing';
import { getFacilityManagers, getPendingInvoicing } from '../../../redux/slices/invoicing';
import { useDispatch, useSelector } from '../../../redux/store';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import Iconify from '../../../reusable-components/iconify';

export default function FindAndReplace() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const openMenu = Boolean(anchorEl);

  const { data: { pendingInvoicing, facilityManagers } } = useSelector((state) => state.invoicing);

  const managerOptions = useMemo(() => facilityManagers.map(m => ({ id: m.id, label: `${m?.name} - ${m?.email}` })), [facilityManagers]);
  const invoiceIds = useMemo(() => pendingInvoicing.map(i => i.id), [pendingInvoicing]);

  const [value, setValue] = useState({
    replaceType: "Manager",
    find: "",
    replaceWith: "",
  });

  useEffect(() => {
    dispatch(getFacilityManagers());
  }, [dispatch]);

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleOpenDialog = (openType) => {
    setValue({ ...value, replaceType: openType });
    handleCloseMenu();
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setValue({
      replaceType: "Manager",
      find: "",
      replaceWith: "",
    })
    setOpenDialog(false);
  };

  const sendChangeManagerRequest = async () => {
    await findAndReplace(value, invoiceIds);
    await dispatch(getPendingInvoicing({ pending: true }));
    handleCloseDialog();
  }


  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={handleOpenMenu}
        endIcon={<ArrowDropDownIcon />}
      >
        Replace
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleOpenDialog("Manager")}>Manager</MenuItem>
        <MenuItem disabled onClick={() => handleOpenDialog("Reviewer")}>Reviewer</MenuItem>
      </Menu>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle sx={{ textAlign: 'flex-start' }}>
          <IconButton
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '90%', m: 2, mb: 0 }}>
            <Typography>Find</Typography>
            <Autocomplete options={managerOptions}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} />}
              value={value.find} onChange={(event, val) => setValue({ ...value, find: val })}
            />
          </Box>
          <Box sx={{ width: '90%', m: 2 }}>
            <Typography>Replace with</Typography>
            <Autocomplete
              options={managerOptions}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} />}
              value={value.replaceWith}
              onChange={(event, val) => setValue({ ...value, replaceWith: val })}
            />
          </Box>
          <Box sx={{ width: '90%', m: 2 }}>
            <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
              <ConfirmDialog
                icon=""
                title={`Replace ${value?.replaceType}`}
                color="primary"
                variant="contained"
                button
                size="medium"
                content={`Are you sure you want to replace all?`}
                actionButton={`Replace ${value?.replaceType}`}
                action={sendChangeManagerRequest}
              />
            </Grid>
          </Box>

        </DialogContent>
      </Dialog>
    </>
  );
}
