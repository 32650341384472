import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Button, Grid, Box, Card } from '@mui/material';
import { CustomAvatar } from '../../../../../reusable-components/custom-avatar';
import { useSelector, useDispatch } from '../../../../../redux/store';
import { ScopeGuard, useScopeCheck } from '../../../../../reusable-components/scopes';
import Scrollbar from '../../../../../reusable-components/scrollbar';
import CartItemView from './CartItemView';
import { deselectAllItems, postSubmitOrder } from '../../../../../redux/slices/rentals';

RentalCartView.propTypes = {
  setShowSelection: PropTypes.func,
};

export default function RentalCartView({ setShowSelection }) {
  const { facilityId = '' } = useParams();
  const hasBudgetViewAccess = useScopeCheck(['View-Order'], true);
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const {
    data: { cart },
  } = useSelector((state) => state.rentals);

  const newCart = useMemo(() => {
    if (facilityId != null) {
      return cart.filter((x) => x.facilityId === Number(facilityId));
    }
    return [];
  }, [cart, facilityId]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [checkout, setCheckout] = useState(false);
  const [showCartEditOptions, setShowCartEditOptions] = useState(true);
  const [showQuantity, setShowQuantity] = useState(false);

  const sortedCart = useMemo(() => (newCart.length > 0 ? newCart : cart), [cart, newCart]);

  const cartByResidentArrays = useMemo(() => {
    const cartByResident = sortedCart.reduce((acc, item) => {
      if (!acc[item.residentId]) {
        acc[item.residentId] = [];
      }
      acc[item.residentId].push(item);
      return acc;
    }, {});
    return Object.values(cartByResident);
  }, [sortedCart]);
  const handleClose = (e) => {
    setShowSelection(e?.target?.textContent || '');
    setAnchorEl(null);
  };
  const handleDeselect = (residentId) => {
    dispatch(deselectAllItems(residentId));
  };

  const handleSubmitOrder = async () => {
    const response = await dispatch(postSubmitOrder(cart));
    if (response.status === 200) {
      navigate(`/dashboard/rentals/new-rental/rental-order-summary/?orderNumber=${response.data}`);
    }
  };

  const getNameById = (id) => cart.find((r) => r.residentId === id);

  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{ ml: 6, mt: 1, mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}
      >
        Shopping Carts
      </Typography>

      <Grid container direction="row">
        <Grid item sx={{ width: '75%' }}>
          <Scrollbar sx={{ height: 'calc(100vh - 280px)' }}>
            {cartByResidentArrays.map((resident, index) => {
              const residentData = getNameById(resident[0].residentId);
              const cartResident = `${resident[0].residentFirstName} ${resident[0].residentLastName}`;
              return (
                <Box key={index}>
                  {((checkout && resident.some((item) => item.isSelected === true)) || !checkout) && (
                    <Card
                      sx={{ width: '90%', display: 'inline-flex', flexDirection: 'column', mb: 4, padding: 3, ml: 4 }}
                    >
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item container direction="row" spacing={1} alignItems="center">
                          <Grid item>
                            <CustomAvatar
                              name={cartResident}
                              size="large"
                              showTooltip={false}
                              sx={{
                                height: 40,
                                width: 40,
                                fontSize: 40 / 2,
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1" sx={{ color: '#707070', fontWeight: 'bold' }}>
                              {cartResident}
                            </Typography>
                          </Grid>
                          <Grid item sx={{ marginLeft: 'auto' }}>
                            <Typography variant="subtitle1" sx={{ color: '#8cc53f' }}>
                              Total: ${resident.reduce((acc, item) => acc + item.price * item.quantity, 0)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item sx={{ marginLeft: 'auto' }}>
                          {showCartEditOptions && (
                            <Typography
                              size="small"
                              color="primary"
                              sx={{
                                mb: 1.5,
                                marginLeft: 'auto',
                                textDecoration: 'underline',
                                fontSize: '15px',
                                color: '#4ca5e7',
                                '&:hover': {
                                  cursor: 'pointer',
                                },
                              }}
                              onClick={() => {
                                navigate(`/dashboard/rentals/new-rental/rental-checkout/${residentData.residentId}`);
                              }}
                            >
                              Checkout {cartResident}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        {showCartEditOptions && (
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: '#6d6e71',
                              textDecoration: 'underline',
                              '&:hover': {
                                cursor: 'pointer',
                              },
                            }}
                            onClick={() => {
                              handleDeselect(residentData.residentId);
                            }}
                          >
                            Deselect all
                          </Typography>
                        )}
                      </Grid>
                      {resident.map((cartItem, ind) => (
                        <>
                          {((checkout && cartItem.isSelected === true) || !checkout) && (
                            <CartItemView
                              cartItem={cartItem}
                              key={ind}
                              showDivider={resident.length - 1 !== ind}
                              showCartEditOptions={showCartEditOptions}
                              showQuantity={showQuantity}
                            />
                          )}
                        </>
                      ))}
                    </Card>
                  )}
                </Box>
              );
            })}
          </Scrollbar>
        </Grid>
        <Grid item sx={{ width: '25%' }}>
          <Grid container direction="column" sx={{ maxWidth: '70%', alignItems: 'center' }}>
            <Card sx={{ padding: 3, alignItems: 'center' }}>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ display: 'inline-flex', textAlign: 'center', color: '#6d6e71' }}>
                  Subtotal: (
                  {cart.length
                    ? `${cart.filter((item) => item.isSelected).reduce((acc, item) => acc + item.quantity, 0)} Item${
                        cart.reduce((acc, item) => acc + item.quantity, 0) !== 1 ? 's' : ''
                      }`
                    : 'Cart is empty'}
                  ) ${cart.reduce((acc, item) => acc + item.price * item.quantity, 0)}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'center' }}>
                {!checkout ? (
                  <Button
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 2,
                    }}
                    variant="contained"
                    onClick={() => {
                      setCheckout(true);
                      setShowCartEditOptions(false);
                      setShowQuantity(true);
                    }}
                  >
                    Proceed To Checkout
                  </Button>
                ) : (
                  <Button
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 2,
                    }}
                    variant="contained"
                    onClick={() => {
                      handleSubmitOrder();
                    }}
                  >
                    Submit Order for Approval
                  </Button>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
