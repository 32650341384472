import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// components
import { useDispatch, useSelector } from '../../../redux/store';
import { getCartsForUser } from '../../../redux/slices/rentals';
import { RentalCartView } from '../../../components/dashboard/rentals/newRental';

// ----------------------------------------------------------------------

export default function RentalCart() {
  const dispatch = useDispatch();
  const { facilityId = '' } = useParams();

  const {
    data: { cart },
  } = useSelector((state) => state.rentals);

  useEffect(() => {
    if (!cart.length) {
      dispatch(getCartsForUser());
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Rental Cart | Platform Solutions</title>
      </Helmet>
      <RentalCartView facilityId={facilityId} />
    </>
  );
}
