import { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import Iconify from '../../../../../reusable-components/iconify';

function LogContainer({ pointer, title, highlight, onClick, icon, children }) {
  const borderColor = '#4ca5e7';

  const styles = {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      borderLeft: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      borderRadius: '5px',
      marginTop: '1em',
      cursor: pointer ? 'pointer' : 'default',
      backgroundColor: highlight ? '#d8ebf9' : '#fff',
    },
    childrenContainer: {
      padding: '2px',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    headerBorderBefore: {
      borderTop: `1px solid ${borderColor}`,
      width: '1em',
      borderTopLeftRadius: '5px',
    },
    headerTitle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      gap: '0.25em',
      width: 'fit-content',
      height: '2em',
      margin: '-1em 0.5em 0em 0.5em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '12px',
    },
    headerBorderAfter: {
      borderTop: `1px solid ${borderColor}`,
      width: '1em',
      flexGrow: 2,
      borderTopRightRadius: '5px',
    },
  };

  return (
    <Box style={styles.mainContainer} onClick={onClick}>
      <div style={styles.header}>
        <div style={styles.headerBorderBefore} />
        {title && <div style={styles.headerTitle}>{title && <span>{title}</span>}</div>}
        <div style={styles.headerBorderAfter} />
      </div>
      <div style={styles.childrenContainer}>
        <Stack container direction="row" alignItems="center" spacing={1}>
          <Box>
            <Box sx={{ paddingLeft: '8px', display: 'flex', alignItems: 'center' }}>
              <Iconify icon={icon} color="info" height="18px" width="18px" />
            </Box>
          </Box>
          {children}
        </Stack>
      </div>
    </Box>
  );
}
export default LogContainer;
