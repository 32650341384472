import { useMemo } from 'react';

export const useFilterModel = (filters) =>
  useMemo(() => {
    const items = Object.keys(filters).map((field, index) => ({
      id: index,
      field,
      operator: filters[field].operator,
      value: filters[field].value,
    }));

    return { items };
  }, [filters]);
