import { useCallback, useState, useEffect } from 'react';
import { Box, Grid, Switch, Button } from '@mui/material';
import { NOTICES } from '../../../../reusable-components/datagrid/systemNoticesColumns';
import { getSystemNoticesData, postSystemNoticeCompleteToggle } from '../../../../api/system-notices';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import Iconify from '../../../../reusable-components/iconify';
import { HOST_API_URL } from '../../../../config-global';
import EditNoticeDialog from './EditNoticeDialog';
import AddNoticeDialog from './AddNoticeDialog';

export default function SystemNoticesView() {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getData = async () => {
    try {
      const res = await getSystemNoticesData();
      setData(res.data);
    } catch (error) {
      enqueueSnackbar('Error fetching System Notices', { variant: 'error' });
    }
  };

  const downloadNotices = async () => {
    window.open(`${HOST_API_URL}Api/SystemNotice/DownloadSystemNotices`, '_blank');
  };

  useEffect(() => {
    getData();
  }, []);

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <>
          <Box sx={{ mr: 1 }}>
            <AddNoticeDialog getData={getData} />
          </Box>
          <Box sx={{ mr: 1 }}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mr: 1 }}
              size="small"
              onClick={downloadNotices}
              startIcon={<Iconify icon="file-icons:microsoft-excel" />}
            >
              Download
            </Button>
          </Box>
        </>
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const ActionButtons = (params) => (
    <>
      <EditNoticeDialog row={params.row} getData={getData} />
      <Switch
        color="info"
        checked={!params.row.complete}
        onChange={async () => {
          const response = await postSystemNoticeCompleteToggle({ id: params.row.id, complete: !params.row.complete });
          if (response.status === 200) {
            enqueueSnackbar('Notice updated successfully', { variant: 'success' });
            getData();
          }
        }}
      />
    </>
  );

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-system-notices-view"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={data}
          gridColumns={[...NOTICES, actions]}
          sort={[{ field: 'dateAdded', sort: 'desc' }]}
          disableSelectionOnClick
          isModal
          CustomLeftToolbar={CustomLeftToolbar}
        />
      </Grid>
    </>
  );
}
