import PropTypes from 'prop-types';
// @mui
import { Stack, Button, Tooltip, Typography, IconButton, ToggleButton } from '@mui/material';
// utils
import { fDate } from '../../utils/formatTime';

// hooks
// components
import Iconify from '../iconify';
import { useDispatch } from '../../redux/store';
import { onOpenModal } from '../../redux/slices/vendors';
// ----------------------------------------------------------------------

const VIEW_OPTIONS = [
  { value: 'dayGridMonth', label: 'Month', icon: 'ic:round-view-module' },
  { value: 'listYear', label: 'List', icon: 'ic:round-view-agenda' },
];

// ----------------------------------------------------------------------

CalendarToolbar.propTypes = {
  onToday: PropTypes.func,
  onNextDate: PropTypes.func,
  onPrevDate: PropTypes.func,
  onChangeView: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  view: PropTypes.oneOf(['dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek']),
};

export default function CalendarToolbar({ date, view, onToday, onNextDate, onPrevDate, onChangeView }) {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(onOpenModal());
  };
  return (
    <Stack alignItems="center" justifyContent="space-between" direction={{ xs: 'column', sm: 'row' }} sx={{ p: 2.5 }}>
      <Stack direction="row" spacing={1}>
        {VIEW_OPTIONS.map((viewOption) => (
          <Tooltip key={viewOption.value} title={viewOption.label}>
            <ToggleButton
              size="small"
              value={view}
              selected={viewOption.value === view}
              onChange={() => onChangeView(viewOption.value)}
            >
              <Iconify icon={viewOption.icon} />
            </ToggleButton>
          </Tooltip>
        ))}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton onClick={onPrevDate}>
          <Iconify icon="eva:arrow-ios-back-fill" />
        </IconButton>

        <Typography variant="h5">{fDate(date)}</Typography>

        <IconButton onClick={onNextDate}>
          <Iconify icon="eva:arrow-ios-forward-fill" />
        </IconButton>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Button size="small" color="info" variant="contained" onClick={onToday}>
          Today
        </Button>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenModal} size="small">
          New Event
        </Button>
      </Stack>
    </Stack>
  );
}
