import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Typography, Grid, Box, IconButton, Divider, Tooltip, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../../../../reusable-components/iconify';
import { useDispatch, useSelector } from '../../../../../redux/store';
import Image from '../../../../../reusable-components/image';

import {
  addItemToCart,
  removeItemFromCart,
  clearBadgeFromRedux,
  updateSelectedItem,
} from '../../../../../redux/slices/rentals';

CartItemView.propTypes = {
  cartItem: PropTypes.object,
  key: PropTypes.number,
  showDivider: PropTypes.bool,
  showCartEditOptions: PropTypes.bool,
  showQuantity: PropTypes.bool,
};

export default function CartItemView({ cartItem, key, showDivider, showCartEditOptions, showQuantity }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const {
    data: { rentalVendors, cart },
  } = useSelector((state) => state.rentals);
  const [reduxCartItem, setReduxCartItem] = useState([]);

  useEffect(() => {
    updateCartItem(cart);
  }, [cart]);

  const updateCartItem = (cart) => {
    const cartItemMatch = cart.filter((item) => item.itemId === cartItem.itemId);
    setReduxCartItem(cartItemMatch);
  };
  const CustomIconButton = styled(Iconify)({
    cursor: 'pointer',
    color: '#fff',
  });

  const handleChange = (event, isSelected) => {
    dispatch(updateSelectedItem(cartItem, isSelected));
  };
  const handleAddCart = (cartItem) => {
    dispatch(addItemToCart(cartItem.itemId, cartItem.residentId, user.id, cartItem.cartId));
    dispatch(clearBadgeFromRedux());
  };
  const handleRemoveCart = (item, deleteAll) => {
    dispatch(removeItemFromCart(item.cartItemId, deleteAll));
  };

  const getVendorNameById = (vendorId) => rentalVendors.find((v) => v.id === parseInt(vendorId, 10));
  const currentVendor = getVendorNameById(cartItem.vendorId);
  const vendorName = currentVendor ? `${currentVendor.name}` : '';

  return (
    <>
      <Box key={key}>
        <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', width: '65%' }}>
          {showCartEditOptions && (
            <Checkbox
              checked={cartItem.isSelected}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              color="secondary"
            />
          )}
          <Image
            alt="attachment"
            src={cartItem.image}
            sx={{ borderRadius: 1.5, width: 120, maxHeight: '120px', mr: 6, ml: 4 }}
          />
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                }}
              >
                <Typography variant="subtitle2" sx={{ color: '#707070', overflow: 'hidden', maxWidth: '250px', mr: 4 }}>
                  {cartItem.description}
                </Typography>{' '}
                <Typography variant="subtitle2" sx={{ ml: 2, color: '#6d6e71' }}>
                  {vendorName}
                </Typography>
                <Typography variant="subtitle2" sx={{ ml: 2, color: '#6d6e71' }}>
                  87678
                </Typography>
              </Box>{' '}
            </Grid>
            {showCartEditOptions && (
              <Grid item sx={{ marginRight: 'auto' }}>
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <div style={{ padding: '20px' }}>
                      <QuantityChange>
                        <CustomIconButton
                          icon="ic:round-minus"
                          width={12}
                          height={12}
                          onClick={() => handleRemoveCart(cartItem, false)}
                        />
                        <span>&nbsp;&nbsp;</span>
                        <span
                          style={{
                            color: '#8dc63f',
                            backgroundColor: '#fff',
                            textAlign: 'center',
                            border: '1px solid #8cc53f',
                            width: '25px',
                            height: '22px',
                            fontSize: '14px',
                          }}
                        >
                          {' '}
                          {cartItem.quantity}
                        </span>
                        <span>&nbsp;&nbsp;</span>{' '}
                        <CustomIconButton
                          icon="iconamoon:sign-plus-bold"
                          width={12}
                          height={12}
                          onClick={() => handleAddCart(cartItem)}
                        />
                      </QuantityChange>
                    </div>
                  </Grid>

                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ border: 'solid .3px #707070', opacity: '.60' }}
                  />

                  <Grid item>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleRemoveCart(cartItem, true)} color="error">
                        <Iconify icon="mdi:trash-can-outline" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Typography
            sx={{ display: 'inline-block', color: '#8cc53f', fontSize: '15px', fontWeight: 'bold', padding: 4 }}
          >
            ${cartItem.price * cartItem.quantity}
          </Typography>
          {showQuantity && (
            <Typography
              variant="subtitle1"
              sx={{
                color: '#707070',
                overflow: 'hidden',
                maxWidth: '250px',
                mr: 4,
                fontSize: '12px',
              }}
            >
              {' '}
              Quantity: {cartItem.quantity}/$
              {cartItem.price} each
            </Typography>
          )}
        </Box>
      </Box>
      {showDivider && <Divider sx={{ mb: 2, mt: 2, width: '75%', ml: 4, opacity: 3, backgroundColor: '#707070' }} />}
    </>
  );
}

const QuantityChange = styled('div')(({ theme }) => ({
  height: 22,
  backgroundColor: '#8dc63f',
  color: '#8dc63f',
  padding: theme.spacing(0.5),
  margin: '2.5px 3px 2.5px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '2px',
}));
