import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, IconButton, Box, Tooltip } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { STATEMENT_LINES } from '../../../reusable-components/datagrid/invoiceColumns';
import { SkeletonDataGrid } from '../../../reusable-components/skeleton/SkeletonDataGrid';
import { getInvoiceStatementLines } from '../../../api/invoicing';
import Iconify from '../../../reusable-components/iconify';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';

StatementInfoViewModal.propTypes = {
  row: PropTypes.object,
};

export default function StatementInfoViewModal({ row }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const getStatement = async () => {
    try {
      setIsLoading(true);
      const response = await getInvoiceStatementLines(row.id);
      if (response.data) {
        setData(response.data);
      }
      setIsLoading(false);
    } catch {
      enqueueSnackbar('Error loading', { variant: 'error' });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      getStatement();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Get invoice statement info">
        <IconButton onClick={handleClickOpen} color="info">
          <Iconify icon="material-symbols:info-outline" width={22} height={22} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Statement Info
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CustomDataGrid
            gridId="invoicing-statement-invoice-view-modal"
            boxSX={{ height: 'calc(100vh - 200px)' }}
            data={data}
            gridColumns={STATEMENT_LINES}
            disableSelectionOnClick
            sort={[{ field: 'dateAdded', sort: 'desc' }]}
            isLoading={isLoading}
            isModal
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
