import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import RentalOrderItemsView from '../../../components/dashboard/rentals/RentalOrderItemsView';
// components

// ----------------------------------------------------------------------

export default function RentalOrder() {
  const { orderId = '' } = useParams();
  const { orderStatus = '' } = useParams();

  return (
    <>
      <Helmet>
        <title> Rental Order | Platform Solutions</title>
      </Helmet>

      <RentalOrderItemsView orderId={parseInt(orderId, 10)} orderStatus={orderStatus} />
    </>
  );
}
