import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../redux/store';
import { getCartsForUser } from '../../../redux/slices/rentals';
import RentalCheckoutView from '../../../components/dashboard/rentals/newRental/rentalCart/RentalCheckoutView';

export default function RentalCheckout() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.user);
  const facilityId = searchParams.get('facilityId') || null;

  const {
    data: { cart },
  } = useSelector((state) => state.rentals);

  useEffect(() => {
    if (!cart.length) {
      dispatch(getCartsForUser(user.Id));
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Rental Checkout | Platform Solutions</title>{' '}
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <RentalCheckoutView />
        </Grid>
      </Grid>
    </>
  );
}
