import { Grid } from '@mui/material';

export default function IntegrationFormRow({ children }) {
  return (
    <Grid container direction="row" spacing={3} display="flex">
      {Array.isArray(children)
        ? children.map((child, index) => (
            <Grid key={index} item xs={12 / children.length}>
              {child}
            </Grid>
          ))
        : children}
    </Grid>
  );
}
