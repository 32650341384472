import { useRef, useEffect, useCallback, useState } from 'react';
import { dropTargetForElements, monitorForElements, draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import PropTypes from 'prop-types';

const Dnd = ({ item, moveItem, onDrop, children, itemId, sx = {} }) => {
  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [draggingOver, setDraggingOver] = useState({});

  const handleDrag = useCallback(({ source, location }) => {
    if (!location.current.dropTargets.length) return;
    if (itemId) setDraggingOver(location.current.dropTargets.find(target => target.data.type === "ITEM")?.data.item[itemId]);
    if (!moveItem) return;
    const sourceData= location.initial.dropTargets.find(target => target.data.type === "ITEM")?.data.item;
    const destinationData = location.current.dropTargets.find(target => target.data.type === "ITEM")?.data.item;
    if (sourceData !== destinationData) {
      moveItem(sourceData, destinationData);
    }
  }, [moveItem]);

  const handleDrop = useCallback(({ source, location }) => {
    setDraggingOver({});
    if (!onDrop || !location.current.dropTargets.length) return;
    const sourceData = location.initial.dropTargets.find(target => target.data.type === "ITEM")?.data.item;
    const destinationData = location.current.dropTargets.find(target => target.data.type === "ITEM")?.data.item;
    if (sourceData !== destinationData) {
      onDrop(sourceData, destinationData);
    }
  }, [onDrop]);

  useEffect(() => {
    const columnEl = ref.current;

    return combine(draggable({
      element: columnEl,
      getInitialData: () => ({ type: "ITEM", item }),
      onDragStart: () => setIsDragging(true),
      onDrop: () => setIsDragging(false),
    }), dropTargetForElements({
      element: columnEl,
      getIsSticky: () => true,
      getData: ({ input, element }) => ({ type: "ITEM", item }),
      onDropTargetChange: () => handleDrag,
    }));
  }, [handleDrag, item, handleDrop]);

  useEffect(() => monitorForElements({ onDropTargetChange: handleDrag, onDrop: handleDrop }), [handleDrag, handleDrop]);

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0 : (itemId && draggingOver === item[itemId]) ? .5 : 1, ...sx }}>
      {children}
    </div>
  );
};

Dnd.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.any,
  sx: PropTypes.object,
  moveItem: PropTypes.func,
  onDrop: PropTypes.func,
  children: PropTypes.node,
}

export default Dnd;
