/* eslint-disable jsx-a11y/label-has-associated-control */
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Document, Page } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { splitPdf } from '../../../api/invoicing';
import { getInvoiceFiles, renderInvoiceFile } from '../../../redux/slices/invoicing';
import { useDispatch } from '../../../redux/store';
import palette from '../../../theme/palette';
import { AwaitButton } from '../../await-button';
import Iconify from "../../iconify";
import { useSnackbar } from '../../snackbar';

export default function SplitPDF({ blobUrl }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(blobUrl);
  const [numPages, setNumPages] = useState(null);
  const [cutLines, setCutLines] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    fetch(blobUrl)
      .then(res => res.blob())
      .then(blob => {
        const newFile = new File([blob], 'fileName.pdf', { type: blob.type });
        setPdfFile(newFile);
      });

  }, [blobUrl, open]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      setPdfFile(acceptedFiles[0]);
    },
  });

  const handleClickOpen = () => {
    setPdfFile(null);
    setCutLines([]);
    setOpen(true);
  };
  const handleClose = () => {
    setPdfFile(null);
    setCutLines([]);
    setOpen(false);
  };

  const split = async () => {
    const response = await splitPdf(cutLines, pdfFile, id);
    if (response.status === 200) {
      await dispatch(renderInvoiceFile(id, true));
      await dispatch(getInvoiceFiles(id));
      handleClose();
    } else {
      enqueueSnackbar('Error splitting pdf', { variant: 'error' });
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setPdfFile(file);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const toggleCutLine = (pageNumber) => {
    setCutLines((prevLines) =>
      prevLines.includes(pageNumber) ? prevLines.filter((page) => page !== pageNumber) : [...prevLines, pageNumber]
    );
  };

  const renderCutLines = (pageNumber) => (
    pageNumber !== 0 &&
    <div
      style={{
        position: 'absolute',
        top: -8,
        left: 0,
        width: '100%',
        borderWidth: '1px',
        borderStyle: 'dashed',
        color: cutLines.includes(pageNumber) ? palette.info.main : 'gray',
      }}
    />
  );

  const renderButton = (pageNumber) => (
    pageNumber !== 0 ? (
      <Button
        onClick={() => toggleCutLine(pageNumber)}
        startIcon={cutLines.includes(pageNumber) ? <UndoIcon /> : <ContentCutIcon />}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          color: cutLines.includes(pageNumber) ? 'info.main' : 'primary.main',
        }}
      >
        {/*{cutLines.includes(pageNumber) ? <UndoIcon /> : <ContentCutIcon />}*/}

        {cutLines.includes(pageNumber) ? 'Undo Cut Line' : 'Add Cut Line'}
      </Button>
    ) : null
  );

  const renderPages = () => {
    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pages.push(
        <Box
          key={i}
          sx={{
            mb: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            '& .react-pdf__Page__annotations': {
              display: 'none',
            },
          }}
        >
          <Page pageNumber={i + 1} renderTextLayer={false} />
          {renderCutLines(i)}
          {renderButton(i)}
        </Box>
      );
    }
    return pages;
  };


  return (
    <>
      <IconButton
        variant="outlined"
        size="small"
        color="secondary"
        onClick={handleClickOpen}
      >
        <Tooltip title="split pdf">
          <CallSplitIcon />
        </Tooltip>
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{ textAlign: 'center' }}>
          Split PDF
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
          {cutLines.length > 0 && (
            <AwaitButton
              variant="outlined"
              color="info"
              sx={{
                position: 'absolute',
                right: 48,
                top: 64,
              }}
              onClick={split}
              startIcon={<CallSplitIcon />}
            >
              Split PDF
            </AwaitButton>
          )}
        </DialogTitle>
        <DialogContent>
          {!pdfFile ? (
            <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
              <input {...getInputProps()} />
              <Paper
                elevation={isDragActive ? 6 : 1}
                sx={{
                  p: 2,
                  ml: 4,
                  bgcolor: 'background.paper',
                  color: 'info.main',
                  borderColor: 'info.main',
                  border: 1,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    bgcolor: 'background.default',
                    boxShadow: 3,
                  },
                }}
              >
                <Typography variant="body2" textAlign="center">
                  Click to Upload or Drag and Drop
                </Typography>
              </Paper>
            </Box>
          ) : (
            <Box sx={{ width: '100%', background: '#444' }}>
              <Document file={blobUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {renderPages()}
              </Document>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
    </>
  );
}
