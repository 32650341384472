import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { EDI, PUNCH_OUT, CXML } from '../../../../reusable-components/datagrid/vendorColumns';
import IntegrationModal from './IntegrationModal';
import { getIntegrationDefinitionsData, postDeleteIntegration } from '../../../../api/vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useSnackbar } from '../../../../reusable-components/snackbar';

Integrations.propTypes = {
  selectedRow: PropTypes.object,
};

export default function Integrations({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [edi, setEdi] = useState([]);
  const [ediDefinitions, setEdiDefinitions] = useState([]);
  const [ediDefinitionsTypes, setEdiDefinitionsTypes] = useState([]);

  const [cxml, setCxml] = useState([]);
  const [cxmlDefinitions, setCxmlDefinitions] = useState([]);
  const [cxmlDefinitionsTypes, setCxmlDefinitionsTypes] = useState([]);

  const [punchOut, setPunchOut] = useState([]);
  const [punchOutDefinitions, setPunchOutDefinitions] = useState([]);
  const [punchOutDefinitionsTypes, setPunchOutDefinitionsTypes] = useState([]);

  const fetchEdi = async () => {
    try {
      const response = await getIntegrationDefinitionsData(1);
      const { vendors, definitionTypes, definitions } = response.data;
      setEdiDefinitions(definitions);
      setEdiDefinitionsTypes(definitionTypes);
      const filteredVendorData = selectedRow ? vendors.filter((d) => d.vendorId === selectedRow.id) : vendors;
      setEdi(filteredVendorData);
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };
  const fetchCxml = async () => {
    try {
      const response = await getIntegrationDefinitionsData(2);
      const { vendors, definitionTypes, definitions } = response.data;
      setCxmlDefinitions(definitions);
      setCxmlDefinitionsTypes(definitionTypes);
      const filteredVendorData = selectedRow ? vendors.filter((d) => d.vendorId === selectedRow.id) : vendors;
      setCxml(filteredVendorData);
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };

  const fetchPunchout = async () => {
    try {
      const response = await getIntegrationDefinitionsData(3);
      const { vendors, definitionTypes, definitions } = response.data;
      setPunchOutDefinitions(definitions);
      setPunchOutDefinitionsTypes(definitionTypes);
      const filteredVendorData = selectedRow ? vendors.filter((d) => d.vendorId === selectedRow.id) : vendors;
      setPunchOut(filteredVendorData);
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };

  const asyncAllCalls = async () => {
    await fetchEdi();
    await fetchCxml();
    await fetchPunchout();
  };
  useEffect(() => {
    asyncAllCalls();
  }, [selectedRow]);

  const deleteRow = async ({ vendorId, typeId, fetch, name }) => {
    try {
      const response = await postDeleteIntegration(vendorId, typeId);
      if (response.status === 200) {
        enqueueSnackbar(`${name} CXML deleted successfully`, { variant: 'success' });
        fetch();
      }
    } catch (error) {
      console.error('Error deleting CXML:', error);
    }
  };

  const ediButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { vendorId } = params.row;
      return (
        <>
          <IntegrationModal
            fetchData={fetchCxml}
            edit
            row={params.row}
            definitions={ediDefinitions}
            setDefinitions={setEdiDefinitions}
            definitionTypes={ediDefinitionsTypes}
            typeId={1}
            name="EDI"
          />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete EDI?`}
            actionButton="Delete"
            action={() => deleteRow({ vendorId, typeId: 1, fetch: fetchEdi, name: 'EDI' })}
          />
        </>
      );
    },
    id: 'actions',
  };
  const cxmlButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { vendorId } = params.row;
      return (
        <>
          <IntegrationModal
            fetchData={fetchCxml}
            edit
            row={params.row}
            definitions={cxmlDefinitions}
            setDefinitions={setCxmlDefinitions}
            definitionTypes={cxmlDefinitionsTypes}
            typeId={2}
            name="CXML"
          />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete CXML?`}
            actionButton="Delete"
            action={() => deleteRow({ vendorId, typeId: 2, fetch: fetchCxml, name: 'CXML' })}
          />
        </>
      );
    },
    id: 'actions',
  };
  const punchOutButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { vendorId } = params.row;
      return (
        <>
          <IntegrationModal
            fetchData={fetchPunchout}
            edit
            row={params.row}
            definitions={punchOutDefinitions}
            setDefinitions={setPunchOutDefinitions}
            definitionTypes={punchOutDefinitionsTypes}
            typeId={3}
            name="Punchout"
          />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete Punchout?`}
            actionButton="Delete"
            action={() => deleteRow({ vendorId, typeId: 3, fetch: fetchCxml, name: 'Punchout' })}
          />
        </>
      );
    },
    id: 'actions',
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={4}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 1, mt: 3 }}>
              <Box>
                <IntegrationModal
                  fetchData={fetchEdi}
                  definitions={ediDefinitions}
                  setDefinitions={setEdiDefinitions}
                  definitionTypes={ediDefinitionsTypes}
                  typeId={1}
                  name="Edi"
                />
              </Box>
              <Box>
                <Typography variant="subtitle1" color="secondary">
                  EDI
                </Typography>
              </Box>
              <Box />
              <Box />
              <Box />
            </Grid>
            <Box sx={{ height: 'calc(100vh - 240px)' }}>
              <DataGridPro
                getRowHeight={() => 'auto'}
                rows={edi}
                columns={[...EDI, ediButtons]}
                disableSelectionOnClick
                getRowId={(row) => row.vendorId}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableColumnPinning
                disableColumnReorder
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 1, mt: 3 }}>
              <Box>
                <IntegrationModal
                  fetchData={fetchCxml}
                  definitions={cxmlDefinitions}
                  setDefinitions={setCxmlDefinitions}
                  definitionTypes={cxmlDefinitionsTypes}
                  typeId={2}
                  name="CXML"
                />
              </Box>
              <Box>
                <Typography variant="subtitle1" color="secondary">
                  CXML
                </Typography>
              </Box>
              <Box />
              <Box />
              <Box />
            </Grid>
            <Box sx={{ height: 'calc(100vh - 240px)' }}>
              <DataGridPro
                getRowHeight={() => 'auto'}
                rows={cxml}
                columns={[...CXML, cxmlButtons]}
                disableSelectionOnClick
                getRowId={(row) => row.vendorId}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableColumnPinning
                disableColumnReorder
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 1, mt: 3 }}>
              <Box>
                <IntegrationModal
                  fetchData={fetchPunchout}
                  definitions={punchOutDefinitions}
                  setDefinitions={setPunchOutDefinitions}
                  definitionTypes={punchOutDefinitionsTypes}
                  typeId={3}
                  name="punchout"
                />
              </Box>
              <Box>
                <Typography variant="subtitle1" color="secondary">
                  Punchout
                </Typography>
              </Box>
              <Box />
              <Box />
              <Box />
            </Grid>
            <Box sx={{ height: 'calc(100vh - 240px)' }}>
              <DataGridPro
                getRowHeight={() => 'auto'}
                rows={punchOut}
                columns={[...PUNCH_OUT, punchOutButtons]}
                disableSelectionOnClick
                getRowId={(row) => row.vendorId}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableColumnPinning
                disableColumnReorder
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
