import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { FACILITY } from '../../../../reusable-components/datagrid/vendorColumns';
import Iconify from '../../../../reusable-components/iconify';
import FacilityAccountModal from './FacilityAccountModal';
import { getFacilityVendorAccountsData, postDeleteFacilityVendorAccount } from '../../../../api/vendors';
import { HOST_API_URL } from '../../../../config-global';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';

FacilityAccount.propTypes = {
  selectedRow: PropTypes.object,
};

export default function FacilityAccount({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchFacilityData = async () => {
    try {
      setIsLoading(true);
      const response = await getFacilityVendorAccountsData();
      const { data } = response;
      const filteredFacilityData = selectedRow ? data.filter((d) => d.vendorId === selectedRow.id) : data;
      setData(filteredFacilityData);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Error fetching facility data:', error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchFacilityData();
  }, [selectedRow]);

  const deleteFacility = async (id) => {
    try {
      const response = await postDeleteFacilityVendorAccount(id);
      if (response.status === 200) {
        enqueueSnackbar('Facility deleted successfully', { variant: 'success' });
        fetchFacilityData();
      }
    } catch (error) {
      enqueueSnackbar('Error deleting facility', { variant: 'error' });
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { facility, id } = params.row;
      return (
        <>
          <FacilityAccountModal fetchFacilityData={fetchFacilityData} edit row={params.row} />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete facility - ${facility}?`}
            actionButton="Delete"
            action={() => deleteFacility(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <FacilityAccountModal fetchFacilityData={fetchFacilityData} />
              <Button
                variant="outlined"
                color="secondary"
                sx={{ mr: 1 }}
                size="small"
                onClick={() => {
                  window.open(`${HOST_API_URL}Api/Vendor/DownloadFacilityAccountsExcel`, '_blank');
                }}
                startIcon={<Iconify icon="file-icons:microsoft-excel" />}
              >
                Download
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="secondary">
            Facility
          </Typography>
        </Grid>
        <Grid item />
        <Grid item />
      </Grid>
    ),
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-vendors-facility-accounts-view"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          gridColumns={[...FACILITY, actionButtons]}
          forceReRender={data}
          sort={[{ field: 'facility', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </>
  );
}
