import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET / Report / Reports;
export async function getReports(type) {
  const response = await axios.get(`/Report/Reports?type=${type}`);
  return response;
}

// GET / Report / ItemsOrderedReport; StartDate EndDate
export async function getItemsOrderedReport({ startDate, endDate, facilities, allItems }) {
  const formData = objectToFormData({
    startDate,
    endDate,
    facilities: facilities?.map((f) => f.id),
    allItems
  });
  const response = await axios.post('/Report/ItemsOrderedReport', formData);

  return response;
}

export async function downloadItemsOrderedReport({ startDate, endDate, facilities, allItems = false }) {
  const formData = objectToFormData({
    startDate,
    endDate,
    facilities: facilities?.map((f) => f.id),
    allItems
  });
  const response = await axios.post('/Report/DownloadItemsOrderedReport', formData, {
    responseType: 'blob',
  });

  return response;
}

export async function getFacilityComparisonReport({ facilities, categories, startDate, endDate }) {
  const formData = objectToFormData({
    facilities: facilities?.map((f) => f.id),
    categories: categories?.map((f) => f.id),
    startDate,
    endDate,
  });
  const response = await axios.post('/Report/FacilityComparisonReport', formData);
  return response;
}

export async function downloadFacilityComparisonReport({ facilities, categories, startDate, endDate }) {
  const formData = objectToFormData({
    facilities: facilities?.map((f) => f.id),
    categories: categories?.map((f) => f.id),
    startDate,
    endDate,
  });
  const response = await axios.post('/Report/DownloadFacilityComparisonReport', formData, {
    responseType: 'blob',
  });

  return response;
}

// POST / Report / VendorComparisonReport;  Facilities array[integer] Categories array[integer] StartDate EndDate
export async function getVendorComparisonReport({ vendors, categories, startDate, endDate }) {
  const formData = objectToFormData({
    vendors: vendors?.map((f) => f.id),
    categories: categories?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/VendorComparisonReport', formData);
  return response;
}

export async function downloadVendorComparisonReport({ vendors, categories, startDate, endDate }) {
  const formData = objectToFormData({
    vendors: vendors?.map((f) => f.id),
    categories: categories?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/DownloadVendorComparisonReport', formData, {
    responseType: 'blob',
  });

  return response;
}

// POST / Report / TopLevelBudgetReport;  Categories array[integer] Companies array[integer] Facilities array[integer] Subcategories array[integer] StartDate EndDate
export async function getTopLevelBudgetReport({
  companies,
  facilities,
  categories,
  subcategories,
  startDate,
  endDate,
}) {
  const formData = objectToFormData({
    companies: companies?.map((f) => f.id),
    facilities: facilities?.map((f) => f.id),
    categories: categories?.map((f) => f.id),
    subcategories: subcategories?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/TopLevelBudgetReport', formData);
  return response;
}

export async function downloadTopLevelBudgetReport({
  companies,
  facilities,
  categories,
  subcategories,
  startDate,
  endDate,
}) {
  const formData = objectToFormData({
    companies: companies?.map((f) => f.id),
    facilities: facilities?.map((f) => f.id),
    categories: categories?.map((f) => f.id),
    subcategories: subcategories?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/DownloadTopLevelBudgetReport', formData, {
    responseType: 'blob',
  });

  return response;
}
/////////////////////////////////////////////////////////////

// POST / Api / Report / FacilityHistoryReport;  Categories array[integer] StartDate EndDate

export async function getFacilityHistoryReport({ facilityId, categories, dates }) {
  const formData = objectToFormData({
    categories: categories?.map((f) => f.id),
    facilityId,
    dates,
  });

  const response = await axios.post('/Report/FacilityHistoryReport', formData);
  return response;
}

// POST / Api / Report / DownloadFacilityHistoryReport;
export async function downloadFacilityHistoryReport({ facilityId, categories, dates }) {
  const formData = objectToFormData({
    categories: categories?.map((f) => f.id),
    facilityId,
    dates,
  });

  const response = await axios.post('/Report/DownloadFacilityHistoryReport', formData, {
    responseType: 'blob',
  });

  return response;
}
/////////////////////////////////////////////////////////////

// POST / Api / Report / POInvoiceMatchupReport;  Facilities array[integer]  Vendors array[integer] StartDate EndDate

export async function getPoInvoiceMatchupReports({ facilities, vendors, startDate, endDate }) {
  const formData = objectToFormData({
    facilities: facilities?.map((f) => f.id),
    vendors: vendors?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/POInvoiceMatchupReport', formData);
  return response;
}

export async function getSubstituteReport({companies,
facilities,
  categories,
  subcategories,
  startDate,
  endDate,
}) {
  const formData = objectToFormData({
    companies: companies?.map((f) => f.id),
    facilities: facilities?.map((f) => f.id),
    categories: categories?.map((f) => f.id),
    subcategories: subcategories?.map((f) => f.id),
    startDate,
    endDate,
  });
  const response = await axios.post('/Report/SubstituteReport',formData);
  return response;
}
export async function downloadSubstituteReport({companies,
  facilities,
  categories,
  subcategories,
  startDate,
  endDate,
}) {
  const formData = objectToFormData({
    companies: companies?.map((f) => f.id),
    facilities: facilities?.map((f) => f.id),
    categories: categories?.map((f) => f.id),
    subcategories: subcategories?.map((f) => f.id),
    startDate,
    endDate,
  })
  const response = await axios.post('/Report/DownloadSubstituteReport', formData,{
    responseType: 'blob',
  });

  return response;
}
// POST / Api / Report / DownloadPOInvoiceMatchupReport;
export async function downloadPoInvoiceMatchupReports({ facilities, vendors, startDate, endDate }) {
  const formData = objectToFormData({
    facilities: facilities?.map((f) => f.id),
    vendors: vendors?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/DownloadPOInvoiceMatchupReport', formData, {
    responseType: 'blob',
  });

  return response;
}

/////////////////////////////////////////////////////////////

// POST / Report / InvoicesReport;  Facilities array[integer] Vendors array[integer] StartDate EndDate

export async function getFacilitiesInvoiceReport({ facilities, vendors, startDate, endDate }) {
  const formData = objectToFormData({
    facilities: facilities?.map((f) => f.id),
    vendors: vendors?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/InvoicesReport', formData);
  return response;
}

// POST / Api / Report / DownloadFacilitiesInvoiceReport;
export async function downloadFacilitiesInvoiceReport({ facilities, vendors, startDate, endDate }) {
  const formData = objectToFormData({
    facilities: facilities?.map((f) => f.id),
    vendors: vendors?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/DownloadFacilitiesInvoiceReport', formData, {
    responseType: 'blob',
  });

  return response;
}
/////////////////////////////////////////////////////////////

// POST / Api / Report / InvoiceIssuesReport;  Facilities array[integer]  StartDate EndDate
export async function getInvoiceIssuesReport({ facilities, startDate, endDate }) {
  const formData = objectToFormData({
    facilities: facilities?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/InvoiceIssuesReport', formData);
  return response;
}
// POST / Api / Report / DownloadInvoiceIssuesReport;
export async function downloadInvoiceIssuesReport({ facilities, startDate, endDate }) {
  const formData = objectToFormData({
    facilities: facilities?.map((f) => f.id),
    startDate,
    endDate,
  });

  const response = await axios.post('/Report/DownloadInvoiceIssuesReport', formData, {
    responseType: 'blob',
  });

  return response;
}
/////////////////////////////////////////////////////////////

// POST / Report / CheckInvoicePDFsReport;  Facilities array[integer]  Vendors array[integer] Skip integer Take integer
export async function getCheckInvoicePDFsReport({ facilityId, vendors, skip, take }) {
  const formData = objectToFormData({
    vendors: vendors?.map((f) => f.id),
    facilityId,
    skip,
    take,
  });

  const response = await axios.post('/Report/CheckInvoicePDFsReport', formData);
  return response;
}

// POST / Api / Report / DownloadCheckInvoicePDFsReport;
export async function downloadCheckInvoicePDFsReport({ facilityId, vendors, skip, take }) {
  const formData = objectToFormData({
    facilityId,
    vendors: vendors?.map((f) => f.id),
    skip,
    take,
  });

  const response = await axios.post('/Report/DownloadCheckInvoicePDFsReport', formData, {
    responseType: 'blob',
  });

  return response;
}
