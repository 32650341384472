import PropTypes from 'prop-types';
import { useState } from 'react';
import { BOOKING } from '../../../../reusable-components/datagrid/facilityColumns';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { postDeleteFacilityBookingVendorAccount } from '../../../../api/facility';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';
import EditIconButton from '../../../../reusable-components/basic-buttons/EditIconButton';
import BookingAccountForm from './BookingAccountForm';

BookingAccountTable.propTypes = {
  bookingVendorAccounts: PropTypes.array,
  vendors: PropTypes.array,
  vendorBookingTypes: PropTypes.array,
  facilityRow: PropTypes.object,
  fetchData: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  bookingType: PropTypes.object
};

export default function BookingAccountTable({ bookingVendorAccounts, vendors, facilityRow, vendorBookingTypes, fetchData, isLoading,
  disabled, bookingType }) {
  const { enqueueSnackbar } = useSnackbar();
  const [openForm, setOpenForm] = useState(false);
  const [editRow, setEditRow] = useState();

  const deleteBooking = async (id) => {
    try {
      const response = await postDeleteFacilityBookingVendorAccount(id);
      if (response.status === 200) {
        enqueueSnackbar('Booking account deleted successfully', { variant: 'success' });
        fetchData();
      } else enqueueSnackbar('Something went wrong', { variant: 'error' });
    } catch (error) {
      console.error('Error deleting booking account:', error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    renderCell: (params) => {
      const { account, id, vendor } = params.row;
      return <ScopeGuard scopes={['Super-Admin']}>
        <EditIconButton onClick={() => { setEditRow(params.row); setOpenForm(true); }} />
        <ConfirmDialog
          content={`Are you sure you want to delete booking account: ${vendor} - ${account}?`}
          action={() => deleteBooking(id)}
        />
      </ScopeGuard>;
    },
  };

  return <>  
    <CustomDataGrid
      gridId="admin-booking-account"
      boxSX={{ height: bookingType?.useEmail ? 'calc(50vh - 240px)' : 'calc(100vh - 240px)' }}
      scrollbarHeight={bookingType?.useEmail ? 50 : 100}
      data={bookingVendorAccounts}
      gridColumns={[...BOOKING, actionButtons]}
      CustomLeftToolbar={() => <AddButton disabled={disabled} onClick={() => setOpenForm(true)}>Add New Account</AddButton>}
      isLoading={isLoading}
      sort={[{ field: 'facility', sort: 'asc' }]}
      disabled={disabled}
      />
    <BookingAccountForm
      close={() => { setEditRow(null); setOpenForm(false); }}
      facilityRow={facilityRow}
      editRow={editRow}
      vendors={vendors}
      vendorBookingTypes={vendorBookingTypes}
      fetchData={fetchData}
      openForm={openForm}
      bookingType={bookingType}
    />
  </>;
}
