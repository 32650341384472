import { useEffect } from 'react';
import { Grid, Button, TextField, DialogContentText, Autocomplete, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';

Step1.propTypes = {
  parentData: PropTypes.object,
  setParentData: PropTypes.func,
  categories: PropTypes.array,
  setCategories: PropTypes.func,
  fromOrder: PropTypes.bool,
  fromCart: PropTypes.bool,
  isWarehouseItem: PropTypes.bool,
  agoraCategories: PropTypes.array,
  agoraSubcategories: PropTypes.array,
};

export default function Step1({
  parentData,
  setParentData,
  categories,
  setCategories,
  isWarehouseItem = false,
  agoraCategories,
  agoraSubcategories,
}) {
  const updateProductData = (field, value) => {
    setParentData((prevData) => ({ ...prevData, [field]: value }));
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Description *</DialogContentText>{' '}
          <TextField
            size="small"
            label=""
            fullWidth
            multiline
            value={parentData?.description || ''}
            onChange={(e) => {
              updateProductData('description', e.target.value);
            }}
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Notes</DialogContentText>{' '}
          <TextField
            size="small"
            label=""
            fullWidth
            multiline
            minRows={2}
            value={parentData?.notes || ''}
            onChange={(e) => {
              updateProductData('notes', e.target.value);
            }}
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        </Grid>
      </Grid>
      {categories.map((category, index) => (
        <Grid
          key={`${index}-${category.agoraCategoryId}-${category.agoraSubcategoryId}`}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={6}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Category *</DialogContentText>{' '}
            <Autocomplete
              size="small"
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabelRoot ': 'none',
                width: '90%',
              }}
              type="new-password"
              options={agoraCategories || []}
              autoHighlight
              getOptionLabel={(option) => option?.name}
              value={agoraCategories?.find((cat) => cat.id === categories[index].agoraCategoryId)}
              onChange={(event, newValue) => {
                setCategories((prevCategories) => {
                  const newCategories = [...prevCategories];
                  newCategories[index].agoraCategoryId = newValue?.id;
                  return newCategories;
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label=""
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Subcategory *</DialogContentText>{' '}
            <Autocomplete
              size="small"
              disabled={!categories[index].agoraCategoryId}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabelRoot ': 'none',
                width: '90%',
              }}
              type="new-password"
              options={
                agoraSubcategories.filter((cat) => cat.mainCategoryId === categories[index].agoraCategoryId) || []
              }
              autoHighlight
              getOptionLabel={(option) => option?.name}
              value={agoraSubcategories.find((cat) => cat.id === categories[index].agoraSubcategoryId)}
              onChange={(event, newValue) => {
                setCategories((prevCategories) => {
                  const newCategories = [...prevCategories];
                  newCategories[index].agoraSubcategoryId = newValue?.id;
                  return newCategories;
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label=""
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              mt: 4,
            }}
          >
            <IconButton
              onClick={() => {
                if (categories.length === 1) {
                  setCategories([{ agoraCategoryId: null, agoraSubcategoryId: null }]);
                } else {
                  setCategories((prevCategories) => {
                    const newCategories = [...prevCategories];
                    newCategories?.splice(index, 1);
                    return newCategories;
                  });
                }
              }}
            >
              <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      {!isWarehouseItem && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Button
            variant="text"
            color="primary"
            disabled={categories.some((category) => !category.agoraCategoryId || !category.agoraSubcategoryId)}
            sx={{
              mt: 2,
              textDecoration: 'underline',
            }}
            onClick={() => setCategories([...categories, { agoraCategoryId: null, agoraSubcategoryId: null }])}
          >
            Add additional category
          </Button>
        </Grid>
      )}
    </>
  );
}
