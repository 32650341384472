import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector, useDispatch } from '../../../../redux/store';
import { SKU_ITEM } from '../../../../reusable-components/datagrid/catalogColumns';
import CustomToolbar from './CustomToolbar';
import OrderHistoryDialog from './OrderHistoryDialog';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import OrderGuideDialog from './OrderGuideDialog';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { deleteProduct } from '../../../../redux/slices/catalog';

ViewAllSKUs.propTypes = {
  viewAllSKUs: PropTypes.bool,
  setViewAllSKUs: PropTypes.func,
};

export default function ViewAllSKUs({ viewAllSKUs, setViewAllSKUs }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { allSKUsData },
    isLoadingAllSKUsData,
    isLoading,
  } = useSelector((state) => state.catalog);

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    id: 'actions',
    className: 'actions',
    renderCell: (params) => {
      const { categoryId, productId, vendorItemId, id } = params.row;
      return (
        <>
          <OrderHistoryDialog productId={productId} preSearchVendorItemId={vendorItemId} />
          <ScopeGuard scopes={['Edit-OrderGuide']} allowAdmin>
            <OrderGuideDialog productId={productId} agoraCategoryId={categoryId} />
          </ScopeGuard>
          <ScopeGuard scopes={['Item-Catalog-Edit']} allowAdmin>
            <ConfirmDialog
              content={`Are you sure you want to delete item ${vendorItemId}?`}
              action={async () => dispatch(deleteProduct(id, productId, true, enqueueSnackbar))}
              tooltip="Delete"
            />
          </ScopeGuard>
        </>
      );
    },
  };

  return (
    <CustomDataGrid
      gridId="admin-catalog-viewallskus"
      boxSX={{ height: 'calc(100vh - 235px)' }}
      sort={[{ field: 'description', sort: 'asc' }]}
      data={allSKUsData}
      gridColumns={[...SKU_ITEM, actions]}
      CustomLeftToolbar={() => <CustomToolbar viewAllSKUs={viewAllSKUs} setViewAllSKUs={setViewAllSKUs} />}
      isLoading={isLoadingAllSKUsData || isLoading}
      onRowClick={(params, e) => {
        if (window.getSelection().toString().length > 0) return;
        if (e.addToOrderGuide || e.itemHistory || e.picture || e.confirmDialog) return;
        navigate(`/dashboard/admin/catalog/${params.row.productId}?viewallskus=1`);
      }}
    />
  );
}
