import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Stack, Typography, Box, Card } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { fMonthDayTimeYear } from '../../../utils/formatTime';
import { stringToColor } from '../../../utils/cssStyles';
import Markdown from '../../../reusable-components/markdown';
import { CustomAvatar } from '../../../reusable-components/custom-avatar';
import { useTabs } from '../../../context/TabContext';

TicketsResults.propTypes = {
  searchResult: PropTypes.array,
  search: PropTypes.string,
};

export default function TicketsResults({ searchResult, search }) {
  const { addNewTabAndNavigate } = useTabs();

  const [open, setOpen] = useState(true);

  const highlightText = (text) => {
    const words = search.split(/\s+/);
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    const parts = text?.split(regex);
    return parts
      ?.map((part) => {
        if (regex.test(part)) {
          return `<span style="background-color: yellow;">${part}</span>`;
        }
        return part;
      })
      .join('');
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Card sx={{ px: 2, pt: 2, pb: 1, mb: 2, border: "1px #000 solid" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Grid item>
          <Box>
            <Typography variant="subtitle1" color="info.main">
              <Markdown children={`<s1>Ticket #${searchResult.id} | ${highlightText(searchResult.subject)}</s1>`} />
            </Typography>
          </Box>
          {searchResult.facility && (
            <Box>
              <Typography variant="subtitle2" color="info.main">
                <Markdown children={`<s2>Facility: ${highlightText(searchResult.facility)}</s2>`} />
              </Typography>
            </Box>
          )}
        </Grid>
        <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer' }} color="primary">
          <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
        </Typography>
      </Grid>
      {open && (
        <Box
          sx={{
            //onHover make it as if its a button
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: '#f5f5f5',
            },
          }}
          onClick={() => addNewTabAndNavigate(`/dashboard/tickets/${searchResult.id}`)}
        >
          <Divider sx={{ width: '100%', my: 1, backgroundColor: '#000' }} />
          {searchResult.messages?.map((message, index) => (
            <Grid item key={index}>
              <Stack direction="row" justifyContent={'flex-start'} sx={{ mt: 2 }}>
                <>
                  <CustomAvatar name={message.sentBy} sx={{ mr: 1 }} />
                  <Stack spacing={0.5} alignItems={'flex-start'}>
                    <>
                      <Typography noWrap variant="caption" sx={{ color: 'text.disabled' }}>
                        {fMonthDayTimeYear(message.sentOn)}
                      </Typography>
                    </>
                    <Box>
                      <Stack
                        sx={{
                          p: 1,
                          minWidth: 'fit-content',
                          maxWidth: '100%',
                          borderRadius: '9px',
                          wordWrap: 'break-word',
                          typography: 'body2',
                          border: () => `solid 1px ${stringToColor(message.sentBy)}`,
                          display: 'inline-block',
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                        }}
                      >
                        <Box
                          sx={{
                            '& img': {
                              cursor: 'pointer',
                              '&:hover': {
                                filter: 'brightness(0.9)',
                                padding: 0.3,
                                border: () => `solid 1px ${stringToColor(message.sentBy)}`,
                              },
                            },
                          }}
                        >
                          <Markdown children={highlightText(message.message)} />
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                </>
              </Stack>
            </Grid>
          ))}
        </Box>
      )}
    </Card>
  );
}
