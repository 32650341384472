import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { NOTES } from '../../../../reusable-components/datagrid/vendorColumns';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { getVendorIntegrationsNotesData, postDeleteVendorIntegrationNote } from '../../../../api/vendors';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import IntegrationNotesModal from './IntegrationNotesModal';

Notes.propTypes = {
  selectedRow: PropTypes.object,
};

export default function Notes({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [integrationTypes, setIntegrationTypes] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getVendorIntegrationsNotesData();
      const { vendorIntegrations, integrationTypes } = response.data;
      setIntegrationTypes(integrationTypes);
      const filteredVendorData = selectedRow
        ? vendorIntegrations.filter((d) => d.vendorId === selectedRow.id)
        : vendorIntegrations;
      setData(filteredVendorData);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Error fetching integration notes', { variant: 'error' });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const deleteNote = async (id) => {
    try {
      const response = await postDeleteVendorIntegrationNote(id);
      if (response.status === 200) {
        enqueueSnackbar('Note deleted successfully', { variant: 'success' });
        fetchData();
      }
    } catch (error) {
      enqueueSnackbar('Error deleting note', { variant: 'error' });
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    minWidth: 70,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <IntegrationNotesModal fetchData={fetchData} edit row={params.row} integrationTypes={integrationTypes} />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete note?`}
            actionButton="Delete"
            action={() => deleteNote(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <IntegrationNotesModal fetchData={fetchData} integrationTypes={integrationTypes} />{' '}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    ),
    [integrationTypes]
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-vendors-notes"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          gridColumns={[...NOTES, actionButtons]}
          forceReRender={data}
          sort={[{ field: 'vendorId', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </>
  );
}
