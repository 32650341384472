import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { postAddVendorFiles } from '../../../../api/warehouse-vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

WarehouseVendorFileAddModal.propTypes = {
  row: PropTypes.object,
  data: PropTypes.object,
  fetchData: PropTypes.func,
};

export default function WarehouseVendorFileAddModal({ row, data, fetchData }) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [attachments, setAttachments] = useState([]);
  const [fileType, setFileType] = useState(null);

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: true,
  });

  const handleSave = async () => {
    const response = await postAddVendorFiles(row?.id, fileType.value, attachments);
    if (response) {
      enqueueSnackbar('Vendor files added successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to add vendor files', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleFileInputChange = (event) => {
    const maxAllowedFiles = 1;
    if (
      event.target.files.length > maxAllowedFiles ||
      event.target.files.length + attachments?.length > maxAllowedFiles
    ) {
      alert(`Only ${maxAllowedFiles} files can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    const { files } = event.target;
    const fileList = [];
    for (let i = 0; i < files.length; i++) {
      if (attachments?.some((x) => x.name === files[i].name)) {
        alert(`File ${files[i].name} already exists.`);
      } else fileList.push(files[i]);
    }
    setAttachments((prevAttachments) => {
      if (prevAttachments === undefined) {
        return fileList;
      }
      return [...prevAttachments, ...fileList];
    });
  };

  return (
    <>
      {
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          size="small"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Files
        </Button>
      }

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Add files for {row?.name}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography>File Type</Typography>
            <Autocomplete
              value={fileType}
              onChange={(event, val) => {
                setFileType(val);
              }}
              options={[...data?.fileTypes]}
              renderInput={(params) => <TextField {...params} />}
            />

            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
              <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
                <input {...getInputProps()} />
                <Paper
                  elevation={isDragActive ? 6 : 1}
                  sx={{
                    p: 2,
                    bgcolor: 'background.paper',
                    color: 'info.main',
                    borderColor: 'info.main',
                    border: 1,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      bgcolor: 'background.default',
                      boxShadow: 3,
                    },
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    Click to Upload or Drag and Drop
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave}>
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
