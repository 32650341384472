import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { Autocomplete, IconButton, TextField, Typography, Grid, Box } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { attachClosestEdge, extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import TicketTypeFieldConfig from './TicketTypeFieldConfig';
import DragDropIndicator from './DragDropIndicator';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import SpacedRow from '../../../../reusable-components/spaced-row/SpacedRow';

TicketTypeFieldForm.propTypes = {
  group: PropTypes.object,
  field: PropTypes.object,
  index: PropTypes.number,
  fieldTypes: PropTypes.array,
  setFieldValue: PropTypes.func,
  openFieldConfig: PropTypes.number,
  setOpenFieldConfig: PropTypes.func,
  inputGroups: PropTypes.array,
  setInputGroups: PropTypes.func,
  setSubject: PropTypes.func,
  setShowEndDropAreas: PropTypes.func,
};

export default function TicketTypeFieldForm({ group, field, index, setFieldValue, fieldTypes, openFieldConfig, setOpenFieldConfig, inputGroups,
  setInputGroups, setSubject, setShowEndDropAreas})
{
  const { enqueueSnackbar } = useSnackbar();

  const rowRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [closestEdge, setClosestEdge] = useState(null);

  useEffect(() => {
    const rowEl = rowRef.current;

    return combine(draggable({
      element: rowEl,
      getInitialData: () => ({ type: "row", rowId: field.id, columnId: field.groupId }),
      onDragStart: () => { setIsDragging(true); setShowEndDropAreas(true); },
      onDrop: () => { setIsDragging(false); setShowEndDropAreas(false); },
    }), dropTargetForElements({
      element: rowEl,
      getData: ({ input, element }) => {
        const data = { type: "row", rowId: field.id };
        return attachClosestEdge(data, {
          input,
          element,
          allowedEdges: ["top", "bottom"],
        });
      },
      getIsSticky: () => true, 
      onDragEnter: (args) => {
        if (args.source.data.type !== "row" || args.source.data.rowId === field.id) return;
        setClosestEdge(extractClosestEdge(args.self.data));        
      },
      onDrag: (args) => {
        if (args.source.data.type !== "row" || args.source.data.rowId === field.id) return;
        setClosestEdge(extractClosestEdge(args.self.data));
      },
      onDragLeave: () => setClosestEdge(null),
      onDrop: () => setClosestEdge(null),
    }));
  }, [field, inputGroups, group]);

  const updateName = (newName) => {
    if (!group.miscellaneousFieldsGroup && group.displayHTMLValue) {
      setInputGroups(groups => {
        const updateGroup = groups.find(updateGroup => updateGroup.groupId === group.groupId);
        updateGroup.displayHTMLValue = updateGroup.displayHTMLValue.replace(`{${field.id} - ${field.name ?? ""}}`, `{${field.id} - ${newName}}`);
        return [...groups];
      });
    }
    if (field.displayHTMLValue) setFieldValue("displayHTMLValue", field.id, group.groupId, field.displayHTMLValue
      .replace(`{${field.id} - ${field.name ?? ""}}`, `{${field.id} - ${newName}}`));
    setSubject(subject => subject?.replace(`{${field.id} - ${field.name ?? ""}}`, `{${field.id} - ${newName}}`));
    setFieldValue("name", field.id, group.groupId, newName);
  }

  const updateType = (newValue) => {
    if (newValue && !newValue.multiple && (group.repeatGroup || inputGroups.some(ingroup =>
        ingroup.fields.some(field => field.ticketFieldTypeId === newValue.value)))) {
      enqueueSnackbar("Can't have multiples of this type", { variant: 'error' })
      return;
    }
    if (newValue?.label === "Dropdown") setFieldValue("dropdown", field.id, group.groupId, [{}, {}]);
    setFieldValue("ticketFieldTypeId", field.id, group.groupId, newValue?.value ?? 0);
    setFieldValue("type", field.id, group.groupId, newValue);
  }

  const deleteField = () => setInputGroups(groups => {
    const updateGroup = groups.find(updateGroup => updateGroup.groupId === group.groupId);
    updateGroup.fields = updateGroup.fields.filter(field => field !== updateGroup.fields[index]);
    if (updateGroup.fields.length === 0 && updateGroup.miscellaneousFieldsGroup) return [...groups.filter(updateGroup => updateGroup.groupId !== group.groupId)];
    return [...groups];
  })

  const updateDropdownOption = (name, dropdownIndex) => {
    field.dropdown[dropdownIndex] = { value: name, label: name };
    setFieldValue(`dropdown`, field.id, group.groupId, [...field.dropdown]);
  };

  return <Box>
    {closestEdge === "top" && <DragDropIndicator />}
    <Box ref={rowRef} sx={{
      cursor: "move",
      backgroundColor: "#fff",
      margin: "10px 0",
      padding: "10px",
      borderRadius: "3px",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
      position: "relative",
      opacity: isDragging ? .5 : 1
    }}>
      <SpacedRow key={index}>
        <Box display="flex">
          <TextField fullWidth size="small" value={field.name ?? ""} placeholder="Field name" onChange={(e) => updateName(e.target.value)} />
        </Box>
        <Grid display="flex">
          <Autocomplete
            fullWidth
            size="small"
            value={field.type ?? null}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            blurOnSelect
            onChange={(event, newValue) => updateType(newValue)}
            renderInput={(params) => <TextField {...params} placeholder="Field type" />}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': { display: 'none' },
            }}
            options={fieldTypes}
          />
          <TicketTypeFieldConfig
            group={group}
            field={field}
            open={openFieldConfig === field.id}
            setInputGroups={setInputGroups}
            setFieldValue={setFieldValue}
            setOpen={setOpenFieldConfig}
          />
          <IconButton color="error" sx={{ ml: 1, mb: 1, p: 0, alignSelf: "end" }} onClick={deleteField}>
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>
      </SpacedRow>

      {field.type?.label === "Dropdown" && <>
        <Typography fullWidth variant="subtitle1" sx={{ mt: 1, ml: 3, mb: 1 }}>Dropdown fields</Typography>
        <Grid container sx={{ pl: 3, pr: 11 }} spacing={2}>
          {field.dropdown?.map((opt, dropdownIndex) => <Grid item key={dropdownIndex} xs={6}>
            <TextField fullWidth size="small" value={opt?.value ?? ""} onChange={(e) => updateDropdownOption(e.target.value, dropdownIndex)} />
          </Grid>)}
          <Grid item xs={6} display="flex" alignItems="end" mb={1} >
            <AddButton onClick={() => { setFieldValue(`dropdown`, field.id, group.groupId, [...field.dropdown, {}]) }}>Add Dropdown Option</AddButton>
          </Grid>
        </Grid>
      </>}
    </Box>
    {closestEdge === "bottom" && <DragDropIndicator />}
  </Box>;
}