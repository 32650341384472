import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='75%' height='100%' viewBox='0 0 100% 100%' xml:space='preserve'%3E%3Cdesc%3ECreated with Fabric.js 5.2.4%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg transform='matrix(1 0 0 1 540 540)' id='d111e290-5e0e-42d2-b926-ae26f57f221d'%3E%3C/g%3E%3Cg transform='matrix(1 0 0 1 540 540)' id='5e70ea30-dd95-45f9-93a7-e4feb1d202c0'%3E%3Crect style='stroke: none%3B stroke-width: 1%3B stroke-dasharray: none%3B stroke-linecap: butt%3B stroke-dashoffset: 0%3B stroke-linejoin: miter%3B stroke-miterlimit: 4%3B fill: rgb(255 255 255)%3B fill-rule: nonzero%3B opacity: 0.517%3B visibility: hidden%3B' vector-effect='non-scaling-stroke' x='-540' y='-540' rx='0' ry='0' width='1080' height='1080'/%3E%3C/g%3E%3Cg transform='matrix(-4.87 0 0 -11.65 558.54 1163.55)'%3E%3Cpath style='stroke: none%3B stroke-width: 1%3B stroke-dasharray: none%3B stroke-linecap: butt%3B stroke-dashoffset: 0%3B stroke-linejoin: miter%3B stroke-miterlimit: 4%3B fill: rgb(164 202 101)%3B fill-rule: nonzero%3B opacity: 0.33%3B' transform=' translate(-250  -61.7)' d='M 0 92.27 C 216.83 192.92 304.3 8.39 500 109.03 L 500 0 L 0 0 Z' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E")`,
          backgroundPosition: 'center bottom',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
        },
        '#root': {
          width: '100%',
          height: '100%',
          position: 'relative',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  );

  return inputGlobalStyles;
}
