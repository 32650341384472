import { DialogContentText, TextField } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

SubjectInput.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default function SubjectInput ({ value, setValue }) 
{
  const [subjectMaxChar, setSubjectMaxChar] = useState(value?.length > 64);

  return (<>
    <DialogContentText>Subject:</DialogContentText>
    <TextField
      size="small"
      placeholder="Subject"
      id="subject"
      onChange={(e) => {
        setSubjectMaxChar(e.target.value?.length > 64);
        if (e.target.value?.length > 64) return;
        setValue(e.target.value);
      }}
      error={subjectMaxChar}
      helperText={subjectMaxChar ? 'Subject cannot exceed 65 characters' : ''}
      value={value ?? ""}
      sx={{
        width: '100%',
        mt: 1,
        mb: 3,
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        '& .MuiFormLabel-root ': { display: 'none' },
      }}
    />
  </>);
}