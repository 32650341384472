import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
// components

// ----------------------------------------------------------------------

export default function RentalSettings() {
  return (
    <>
      <Helmet>
        <title>Rentals Settings | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        RentalSettings
      </Grid>
    </>
  );
}
