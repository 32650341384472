import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Grid, Tabs, Tab } from '@mui/material';
import { TabPanel } from '../../../../reusable-components/tab-panel';
import { getUserData } from '../../../../api/account';
import GeneralInfoTab from './GeneralInfoTab';
import SettingsTab from './SettingsTab';
import FacilityManagerTab from './FacilityManagerTab';
import { useScopeCheck } from '../../../../reusable-components/scopes';

export default function UserView() {
  const TAB_CONFIG = {
    0: 'user-information',
    1: 'settings',
    2: 'facility-manager',
  };

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [value, setValue] = useState(tab ? Object.values(TAB_CONFIG).indexOf(tab) : 0);
  const [userData, setUserData] = useState(null);
  const hasFacilityAccess = useScopeCheck(['Facility-Manager']);
  const navigate = useNavigate();

  const getUser = async () => {
    const response = await getUserData();
    setUserData(response);
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    navigate(`?tab=${TAB_CONFIG[newValue]}`, { replace: true });
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ ml: 10, mt: 1 }}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="User information" />
          <Tab label="Settings" />
          {hasFacilityAccess && <Tab label="Facility Manager Information" />}
        </Tabs>
      </Grid>
      <TabPanel value={value} index={0} padding={6}>
        {userData && <GeneralInfoTab userData={userData} />}
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={1} padding={6}>
        {userData && <SettingsTab />}
      </TabPanel>

      <TabPanel value={value} index={2} padding={6}>
        {hasFacilityAccess && <FacilityManagerTab />}
      </TabPanel>
    </>
  );
}
