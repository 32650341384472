import { Box, Card, Grid, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { categoryIconLookup } from '../../../../utils/cssStyles';
import { fMonthDayTimeYear } from '../../../../utils/formatTime';

OrderInfo.propTypes = {
  overrideOrderId: PropTypes.string,
};

export default function OrderInfo({ overrideOrderId }) {
  const { id: orderId = '' } = useParams();
  const id = overrideOrderId || orderId;

  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  const { orderById } = useSelector((state) => state.orders || {});
  const {
    facility = '',
    orderNumber = '',
    items = [],
    submittedBy = { name: '', email: '' },
    onBehalfOf = '',
    approvedBy = '',
    department = '',
    dateAdded = '',
    dateApproved = '',
    requestedDates = [],
    orderComments = [],
    address = {},
    facilityBedCount = '',
  } = orderById[id] || {};
  const { name, email } = submittedBy || { name: '', email: '' };

  return (
    <>
      <Box sx={{ minWidth: '300px', width: '100%', mb: 2 }}>
        <Card sx={{ p: 1, width: '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            onClick={handleClick}
            sx={{ cursor: 'pointer' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="info.main">
              {facility || 'Loading...'}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer' }} color="info.main">
              <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
            </Typography>
          </Grid>
          {open && (
            <Box sx={{ ml: 3 }}>
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ pb: 1.5 }}>

                <Grid item container direction="row">
                  <Grid item xs={1}>
                    <Iconify sx={{ mr: 0.5, mt: 0.8 }} icon="fa6-regular:address-card" width={16} height={16} color="secondary" />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body2" sx={{ pt: 0.5, pb: 0 }}>
                      {address?.street}, {`${address?.city || ''} ${address?.state || ''} ${address?.zip || ''}`?.trim()}
                    </Typography>
                  </Grid>
                </Grid>
                {address?.phoneNumber && (
                  <Typography variant="body2" display="block">
                    <Iconify sx={{ mr: 0.5 }} icon="material-symbols:call" width={16} height={16} color="secondary" />
                    {address?.phoneNumber}
                  </Typography>
                )}
                <Typography variant="body2" sx={{ py: 0.5 }}>
                  <Iconify sx={{ mr: 0.5 }} icon="mingcute:bed-line" width={16} height={16} color="secondary" />
                  Beds: {facilityBedCount}
                </Typography>

                <Typography variant="body2" sx={{ py: 0.5 }}>
                  <Iconify
                    sx={{ mr: 0.5 }}
                    icon={categoryIconLookup[department?.toLowerCase()] || 'mdi:calculator'}
                    width={16}
                    height={16}
                    color="secondary"
                  />
                  Department: {department}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'flex-bottom', '&:hover': { cursor: 'pointer' } }}>
                  <Typography sx={{ mt: 0.75, mr: 0.5 }} component="span" variant="body2">
                    <Iconify icon="mdi:paper-outline" width={16} height={16} color="secondary" />
                  </Typography>
                  <Typography
                    sx={{ py: 0.5 }}
                    component="span"
                    variant="body2"
                  >
                    Submitted by:
                  </Typography>
                  <Tooltip title={copied ? `Copied!` : `Copy ${email || ''} to clipboard`}>
                    <Typography
                      component="span"
                      sx={{ ml: 0.5, textDecoration: 'underline', '&:hover': { cursor: 'pointer' } }}
                      variant="body2"
                      onClick={() => { navigator.clipboard.writeText(email); setCopied(true); }}>
                      {name} <Iconify icon={'ph:copy'} sx={{ pt: 0.5 }} />
                    </Typography>
                  </Tooltip>
                </Box>

                <Typography sx={{ py: 0.5 }} variant="body2" component="span">
                  <Iconify sx={{ mr: 0.5 }} icon="clarity:calendar-line" width={16} height={16} color="secondary" />
                  Submitted on: {fMonthDayTimeYear(dateAdded)}
                </Typography>

                <Typography sx={{ py: 0.5 }} variant="body2">
                  <Iconify sx={{ mr: 0.5 }} icon="mdi:user-outline" width={16} height={16} color="secondary" />
                  On behalf of: {onBehalfOf}
                </Typography>


                <Box sx={{ display: 'flex', alignItems: 'flex-bottom', '&:hover': { cursor: 'pointer' } }}>
                  <Typography sx={{ mt: 0.75, mr: 0.5 }} component="span" variant="body2">
                    <Iconify icon="mdi:tag-approve-outline" width={16} height={16} color="secondary" />
                  </Typography>
                  <Typography sx={{ py: 0.5 }} component="span" variant="body2">
                    Approved by:
                  </Typography>
                  <Tooltip title={copied ? `Copied!` : `Copy ${approvedBy?.toLowerCase() || ''} to clipboard`}>
                    <Typography
                      component="span"
                      sx={{ ml: 0.5, textDecoration: 'underline', '&:hover': { cursor: 'pointer' } }}
                      variant="body2"
                      onClick={() => { navigator.clipboard.writeText(approvedBy?.toLowerCase()); setCopied(true); }}>
                      {approvedBy?.toLowerCase()} <Iconify icon={'ph:copy'} sx={{ pt: 0.5 }} />
                    </Typography>
                  </Tooltip>
                </Box>

                <Typography variant="body2" sx={{ py: 0.5 }}>
                  <Iconify sx={{ mr: 0.5 }} icon="tdesign:calendar-2" width={16} height={16} color="secondary" />
                  Approved on: {fMonthDayTimeYear(dateApproved)}
                </Typography>

              </Grid>
            </Box>
          )}
        </Card>
      </Box>
    </>
  );
}
