import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
// components
import { SubscriptionsView } from '../../components/dashboard/subscriptions';

// ----------------------------------------------------------------------

export default function Subscriptions() {
  return (
    <>
      <Helmet>
        <title>Subscriptions | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <SubscriptionsView />
        </Grid>
      </Grid>
    </>
  );
}
