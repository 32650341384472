import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import AvailibleFields from './AvailibleFields';

AvailibleFieldsTooltip.propTypes = {
  fields: PropTypes.array,
  children: PropTypes.node,
  availibleFieldsTooltipRef: PropTypes.object
};

export default function AvailibleFieldsTooltip({ fields, children, availibleFieldsTooltipRef })
{
  return <Tooltip
    arrow
    leaveDelay={300}
    placement="top"
    componentsProps={{
      tooltip: {
        sx: {
          color: "black !important",
          backgroundColor: "white !important",
          borderRadius: "6px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px 0px",
          border: "1px solid rgb(193, 201, 208)"
        }
      }
    }}
    title={fields.filter(field => field.name && field.ticketFieldTypeId).length > 0 ?
      <AvailibleFields fields={fields} availibleFieldsTooltipRef={availibleFieldsTooltipRef} /> : ""}
  >
    {children}
  </Tooltip>;
}