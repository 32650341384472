import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { WarehouseVendorsView } from '../../../components/dashboard/admin/warehouse-vendors';

export default function WarehouseVendors() {
  
  return (
    <>
      <Helmet>
        <title>Admin Warehouse Vendors | Platform Solutions</title>
      </Helmet>

      <Grid>
        <WarehouseVendorsView />
      </Grid>
    </>
  );
}
