import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Menu, Typography, MenuItem, Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import Iconify from '../../../../../reusable-components/iconify';
import { useSelector, useDispatch } from '../../../../../redux/store';
import { resetTempCart, getOrderingCategories } from '../../../../../redux/slices/orderSheet';

CategoryDropdown.propTypes = {
  setData: PropTypes.func,
};

export default function CategoryDropdown({ setData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { facilityId = '' } = useParams();
  const {
    data: { categories, cart },
  } = useSelector((state) => state.orderSheet);

  useEffect(() => {
    if (!categories?.length) dispatch(getOrderingCategories(facilityId));
  }, [dispatch, facilityId, categories]);

  const handleCategoryClick = (categoryId, name) => {
    setData([]);
    dispatch(resetTempCart(0));
    setAnchorEl(null);
    const encodedName = encodeURIComponent(name);
    const url = `/dashboard/orders/new-order/${facilityId}?categoryId=${categoryId}&category=${encodedName}`;
    navigate(url);
  };

  const [searchParams] = useSearchParams();
  const [category, setCategory] = useState(searchParams.get('category'));
  useEffect(() => {
    setCategory(searchParams.get('category'));
  }, [searchParams]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = (id, name) => {
    setOpenConfirm({ id, name });
  };

  const handleCloseConfirm = () => {
    setAnchorEl(null);
    setOpenConfirm(false);
  };
  const handleAction = () => {
    setOpenConfirm(false);
    handleCategoryClick(openConfirm.id, openConfirm.name);
  };

  if (!categories) return null;

  return (
    <div>
      {categories.length > 1 ? (
        <Button
          size="large"
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="text"
          disableElevation
          color="primary"
          onClick={handleClick}
          disabled={categories.length === 1}
          endIcon={
            categories.length > 1 && <Iconify icon="material-symbols:keyboard-arrow-down" width={24} height={24} />
          }
        >
          {/* {category?.charAt(0)?.toUpperCase() + category?.slice(1)} */}
          <Typography variant="subtitle1" color="primary" sx={{ textTransform: 'capitalize,' }}>
            {category}
          </Typography>
        </Button>
      ) : (
        <Typography variant="subtitle1" color="primary" sx={{ textTransform: 'capitalize,' }}>
          {categories[0]?.name}
        </Typography>
      )}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {categories.map(
          (cat) =>
            // dont display the category that is already selected

            cat.name !== category && (
              <MenuItem
                key={cat.id}
                onClick={
                  cart.length > 0
                    ? () => handleOpenConfirm(cat.id, cat.name)
                    : () => handleCategoryClick(cat.id, cat.name)
                }
                disableRipple
              >
                {cat.name}
              </MenuItem>
            )
        )}
      </StyledMenu>
      <Dialog fullWidth maxWidth="xs" open={!!openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle sx={{ pb: 2 }}>Warning</DialogTitle>
        <DialogContent sx={{ typography: 'body2' }}>
          All unsaved changes will be lost. Are you sure you want to change the category?
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleCloseConfirm}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleAction(openConfirm.id, openConfirm.name)}>
            Proceed to {openConfirm?.name}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
