import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import PickUps from './PickUps';
import Ignored from './Ignored';

VendorEmailPickupTab.propTypes = {
  selectedRow: PropTypes.object,
};

export default function VendorEmailPickupTab({ selectedRow }) {
  return (
    <Paper sx={{ px: 2, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={7} sx={{ mt: 2 }}>
          <PickUps selectedRow={selectedRow} />
        </Grid>
        <Grid item xs={5} sx={{ mt: 2 }}>
          <Ignored selectedRow={selectedRow} />
        </Grid>
      </Grid>
    </Paper>
  );
}
