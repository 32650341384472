import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Menu, MenuItem, Typography, Button, Chip, Grid, Box, Badge } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { useSelector } from '../../../redux/store';
import { fNumber } from '../../../utils/formatNumber';

export default function CartDropdown() {
  const { facilityId = '' } = useParams();

  const {
    data: { added, cartInfo },
  } = useSelector((state) => state.orderSheet);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cartItems, setCartItems] = useState(null);

  useEffect(() => {
    setCartItems(cartInfo.cartItems);
  }, [cartInfo]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!cartItems?.length) {
    return (
      <>
        <Badge
          badgeContent={added > 0 ? `+${fNumber(added)}` : 0}
          color="info"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          max={9999999}
          sx={{
            '.MuiBadge-badge': {
              top: '25%', // Center vertically
              transform: 'translateY(-50%)', // Adjust for badge's height
              left: 9, // Stick to the left
            },
          }}
        >
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            sx={{ borderRadius: 22 }}
            startIcon={<Iconify icon="mdi:shopping-cart-outline" width={24} height={24} />}
            endIcon={<Iconify icon="mdi:chevron-down" width={24} height={24} />}
            color="secondary"
            size="small"
          >
            Cart is empty
          </Button>
        </Badge>
      </>
    );
  }
  return (
    <div>
      <Badge
        badgeContent={added > 0 ? `+${fNumber(added)}` : 0}
        color="info"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        max={9999999}
        sx={{
          '.MuiBadge-badge': {
            top: '25%', // Center vertically
            transform: 'translateY(-50%)', // Adjust for badge's height
            left: 9, // Stick to the left
          },
        }}
      >
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          sx={{ borderRadius: 22 }}
          startIcon={<Iconify icon="mdi:shopping-cart-outline" width={24} height={24} />}
          endIcon={<Iconify icon="mdi:chevron-down" width={24} height={24} />}
          size="small"
          color="secondary"
        >
          {cartItems.length ? `${cartItems.length} Cart${cartItems.length !== 1 ? 's' : ''}` : 'Cart is empty'}
        </Button>
      </Badge>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {cartItems.map((item, index) => (
          <MenuItem key={index} disableRipple>
            <Iconify icon={item.icon} width={35} height={35} />
            <span style={{ marginLeft: 8, marginRight: 8 }}>
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {item.categoryName}
              </Typography>
            </span>
            <Chip label={item.itemCount} sx={{ ml: 'auto', mr: 1 }} />
          </MenuItem>
        ))}

        <Grid container direction="row" justifyContent="center">
          <Button
            variant="text"
            onClick={() => {
              handleClose();
              navigate(`/dashboard/orders/new-order/cart/${facilityId}`);
            }}
          >
            Proceed to cart
          </Button>
        </Grid>
      </StyledMenu>
    </div>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    overflow: 'auto',
    border: 'solid 1px #c1c9d0',
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],

    '& .MuiMenuItem-root': {
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'transparent', // or set this to the normal background color of your menu items
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
