import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button, Typography, IconButton, Tooltip, Divider } from '@mui/material';
import Iconify from '../iconify';

CollapseSidebar.propTypes = {
  setOpenSidebar: PropTypes.func,
  openSidebar: PropTypes.bool,
  children: PropTypes.node,
};

export default function CollapseSidebar({ openSidebar, setOpenSidebar, children }) {
  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={1} sx={{ mt: 2 }}>
          <Grid
            container
            direction="column"
            sx={{ height: 'calc(100vh - 180px)' }}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Divider
              orientation="vertical"
              sx={{
                border: 'dashed .3px #a5a5a6',
                opacity: '.60',
                zIndex: 1,
                mr: openSidebar ? -2 : -1.8,
                ml: openSidebar ? 1 : 2,
              }}
            />
            <Tooltip title={openSidebar ? 'Collapse' : 'Expand'}>
              <IconButton
                onClick={() => setOpenSidebar(!openSidebar)}
                sx={{
                  mt: 1,
                  ml: openSidebar ? -1 : 0,
                  zIndex: 2,
                  backgroundColor: '#f4f6f8',
                  padding: 0,
                  '&:hover': {
                    color: 'info.main',
                    backgroundColor: '#f4f6f8',
                  },
                }}
              >
                <Iconify
                  icon={`iconamoon:arrow-${openSidebar ? 'right' : 'left'}-6-circle-bold`}
                  width={26}
                  height={26}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={11} sx={{ mt: 2, position: 'relative' }}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}
