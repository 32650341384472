import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useSettingsCheck = (areaId) => {
  const { settings } = useSelector((state) => state.user.user);

  const getSettingByAreaId = useMemo(
    () => (areaId) => settings?.find((setting) => setting?.areaId === areaId),
    [settings]
  );

  return getSettingByAreaId(areaId);
};
