import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // add this import
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

const TabContext = createContext();

export const useTabs = () => useContext(TabContext);

// eslint-disable-next-line react/prop-types
export const TabProvider = ({ children }) => {
  const navigate = useNavigate();
  const sessionStorageKey = 'myApp-tabList-new';
  const tabIndexStorageKey = 'myApp-tabIndex-new';
  const defaultId = uuidv4();

  const loadTabListFromSession = () => {
    const savedTabList = sessionStorage.getItem(sessionStorageKey);
    return savedTabList
      ? JSON.parse(savedTabList)
      : [
        {
          key: defaultId,
          id: defaultId,
          title: document.title,
          url: window.location.href,
        },
      ];
  };

  const loadTabIndexFromSession = () => {
    const savedTabIndex = sessionStorage.getItem(tabIndexStorageKey);
    return savedTabIndex?.toString() || defaultId;
  };

  const [value, setValue] = useState(loadTabIndexFromSession());
  const [tabList, setTabList] = useState(loadTabListFromSession().map(tab => ({ ...tab, id: tab?.id?.toString(), key: tab?.key?.toString() })));

  useEffect(() => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(tabList));
    sessionStorage.setItem(tabIndexStorageKey, value.toString());
    const titleObserver = new MutationObserver(() => {
      const currentTab = tabList.find((tab) => tab.id === value);
      if (currentTab && currentTab.title !== document.title) {
        setTabList((prevTabList) =>
          prevTabList.map((tab) => (tab.id === value ? { ...tab, title: document.title } : tab))
        );
      }
    });

    titleObserver.observe(document.querySelector('title'), {
      childList: true,
      subtree: true,
    });

    return () => {
      titleObserver.disconnect();
    };
  }, [tabList, value]);

  // Listen to changes in the docTitle state and update the current tab title
  useEffect(() => {
    const currentTab = tabList.find((tab) => tab.id === value);
    if (currentTab && currentTab.url !== window.location.href) {
      setTabList((prevTabList) =>
        prevTabList.map((tab) => (tab.id === value ? { ...tab, url: window.location.href } : tab))
      );
    }
  }, [window.location.href, tabList, value]);

  const removeHttps = (url) => {
    const textAfterDashboard = url.split('/dashboard')[1];
    const fromDashboard = textAfterDashboard || '';
    return `/dashboard${fromDashboard}`;
  };

  const handleTabChange = (id) => {
    // Update the selected tab before navigating
    setValue(id);
    const tab = tabList.find((tab) => tab.id === id);
    if (tab) {
      navigate(`${removeHttps(tab.url)}`);
      // Update the URL of the tab after navigating
      setTabList((prevTabList) =>
        prevTabList.map((tab) => (tab.id === id ? { ...tab, url: window.location.href } : tab))
      );
    }
  };
  const deleteTab = (e, id) => {
    e.stopPropagation();

    if (tabList.length === 1) {
      return;
    }

    let tabIDIndex = 0;

    const newTabList = tabList.filter((value, index) => {
      if (value.id === id) {
        tabIDIndex = index;
      }
      return value.id !== id;
    });

    let curValue = value;
    if (curValue === id) {
      if (tabIDIndex === 0) {
        curValue = tabList[tabIDIndex + 1]?.id || `${defaultId}0`;
      } else {
        curValue = tabList[tabIDIndex - 1]?.id || `${defaultId}0`;
      }
    }

    setValue(curValue);
    setTabList(newTabList);
    handleTabChange(curValue);
  };

  const addTab = () => {
    const newId = uuidv4();

    setTabList((prevTabList) => {
      const newTabList = cloneDeep(prevTabList);
      newTabList.push({
        key: newId,
        id: newId,
        title: document.title,
        copyReduxStore: value,
        url: '',
      });
      return newTabList;
    });
    handleTabChange(newId);
  };

  const addNewTabAndNavigate = (url) => {
    const newId = uuidv4();

    setTabList((prevTabList) => {
      const newTabList = cloneDeep(prevTabList);
      newTabList.push({
        key: newId,
        id: newId,
        title: document.title,
        // Temporary URL until the tab is clicked
        url: '',
      });
      return newTabList;
    });
    handleTabChange(newId);
    navigate(`${removeHttps(url)}`);
  };

  const activeTab = tabList.find((tab) => tab.id === value);

  const clearFiltersAndNavigate = (url) => {
    const deleteKey = `tab-${activeTab.key}`;

    const keys = Object.keys(sessionStorage);
    keys.forEach((key) => {
      if (key.includes(deleteKey)) {
        sessionStorage.removeItem(key);
      }
    });

    navigate(url);
  };

  const closeAllTabs = () => {
    setTabList([
      {
        key: `${defaultId}0`,
        id: `${defaultId}0`,
        title: document.title,
        url: '/dashboard',
      },
    ]);
    setValue(`${defaultId}0`);
  };

  return (
    <TabContext.Provider
      value={{
        tabList,
        setTabList,
        value,
        setValue,
        handleTabChange,
        addTab,
        deleteTab,
        addNewTabAndNavigate,
        activeTab,
        closeAllTabs,
        clearFiltersAndNavigate,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
