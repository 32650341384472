import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton, Tooltip, Grid, Box } from '@mui/material';
import Iconify from '../../iconify';
import { useScopeCheck } from '../../scopes';
import { useSelector } from '../../../redux/store';
import { useTabs } from '../../../context/TabContext';

CustomQuickFilter.propTypes = {
  setQuickFilter: PropTypes.func,
  setActiveFilter: PropTypes.func,
  setFilters: PropTypes.func,
  sx: PropTypes.object,
  applyQuickFilter: PropTypes.bool,
  isModal: PropTypes.bool,
  advancedFilter: PropTypes.bool,
  advancedSearchBase: PropTypes.string,
  gridId: PropTypes.string,
  presetFilter: PropTypes.string,
};

export default function CustomQuickFilter({
  setFilters,
  setQuickFilter,
  setActiveFilter,
  sx,
  applyQuickFilter,
  isModal,
  advancedFilter,
  advancedSearchBase,
  gridId,
  presetFilter,
}) {
  const navigate = useNavigate();
  const { activeTab } = useTabs();

  const { user } = useSelector((state) => state.user);

  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;

  const searchKey = `tab-${activeTab.key}-search-${baseUrl}-${gridId}`;
  const savedSearch = JSON.parse(sessionStorage.getItem(searchKey));
  const sessionStorageKey = `tab-${activeTab.key}-filters-${baseUrl}-${gridId}`;
  const isFilter = sessionStorage.getItem(sessionStorageKey);

  const isRecent = savedSearch && new Date().getTime() - savedSearch?.timestamp < 300000;

  const hasFacilityAccess = useScopeCheck(['Facility-Manager']);

  const [searchValue, setSearchValue] = useState(isRecent && !isModal ? savedSearch?.search : presetFilter || '');

  useEffect(() => {
    if (isRecent && !isModal) {
      if (savedSearch?.search !== searchValue) {
        setSearchValue(savedSearch?.search || searchValue || '');
      }
    } else {
      setSearchValue(searchValue || '');
    }
  }, [searchValue, savedSearch, isModal]);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  const applyQuickFilterFunc = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      manager: { value: user.firstName === 'Mr. Yeshaya' ? 'Shaya' : user.firstName, operator: 'contains' },
    }));
    setFilterIsActive(true);
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    setQuickFilter(debouncedValue);
  }, [debouncedValue]);

  const handleSearch = (event) => {
    setSearchValue(event?.target.value);
    const searchValueWithTimestamp = {
      search: event?.target.value,
      timestamp: new Date().getTime(),
    };
    sessionStorage.setItem(searchKey, JSON.stringify(searchValueWithTimestamp));
  };

  const handleClear = () => {
    setSearchValue('');
    setQuickFilter('');
    setActiveFilter(null);
    setFilterIsActive(false);
    setFilters({});
    sessionStorage?.removeItem(searchKey);
    sessionStorage?.removeItem(sessionStorageKey);
  };
  const handleClearText = () => {
    setSearchValue('');
    setQuickFilter('');
    sessionStorage?.removeItem(searchKey);
  };

  const handleAdvancedSearch = () => {
    navigate(`/dashboard/advanced-search?type=messageSearch&search=${searchValue}&base=${advancedSearchBase}`);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{
          mt: 1,
          display: 'flex', // Ensure it's a flex container
          flexWrap: 'nowrap', // Prevent wrapping of items
        }}
      >
        {hasFacilityAccess && applyQuickFilter && (
          <Grid item>
            <Tooltip title="Quick filter my name" arrow>
              <IconButton color="info" onClick={applyQuickFilterFunc}>
                <Iconify icon="tabler:filter-bolt" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {(searchValue || isFilter || filterIsActive) && (
          <Grid item>
            <Tooltip title="Clear all filters" arrow>
              <IconButton color="warning" onClick={handleClear}>
                <Iconify icon="tabler:filter-x" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item sx={{ flexGrow: 1, minWidth: 0, flexShrink: 1 }}>
          <TextField
            value={searchValue}
            onChange={handleSearch}
            size="small"
            placeholder="Search..."
            sx={{
              ...sx,
              width: '100%', // Use available space
              // minWidth: '100px', // Minimum width to prevent elements becoming too small
            }}
            type="text"
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton sx={{ display: searchValue === '' ? 'none' : 'inline-flex' }} onClick={handleClearText}>
                    <Iconify
                      icon={'ri:close-fill'}
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                  {advancedFilter && (
                    <Tooltip title="Advanced search" arrow>
                      <IconButton
                        color="secondary"
                        sx={{ display: searchValue === '' ? 'none' : 'inline-flex' }}
                        onClick={handleAdvancedSearch}
                      >
                        <Iconify
                          icon={'fa6-brands:searchengin'}
                          sx={{
                            cursor: 'pointer',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
              style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
