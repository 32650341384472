import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function twoDecimal(number) {
  return numeral(number).format('0.00');
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '$0.00';

  return format;
}

export function fifthDecimalCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00000') : '';
  return format;
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('%0') : '1%';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function formatMoney(value) {
  // Remove all non-numeric characters
  const onlyNums = value.replace(/[^0-9]/g, '');

  // Check the length of the number string to determine formatting
  if (onlyNums.length > 2) {
    // Divide by 100 and fix to two decimal places if length exceeds 2
    return (parseInt(onlyNums, 10) / 100).toFixed(2);
  }
  if (onlyNums.length === 2) {
    // Add a decimal point between the digits if exactly two digits
    return `0.${onlyNums}`;
  }
  if (onlyNums.length === 1) {
    // Prepend with '0.0' if only one digit
    return `0.0${onlyNums}`;
  }
  return '';
}
