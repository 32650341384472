import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Lightbox from '../../../../reusable-components/lightbox';
import { useSnackbar } from '../../../../reusable-components/snackbar';

FacilityLogoUpload.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setLogo: PropTypes.func,
};

export default function FacilityLogoUpload({ logo, setLogo, edit }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];

    if (file && file?.type.startsWith('image/')) {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64Image = reader.result; // Use the entire base64 data URL

          setLogo(base64Image);
        };
        reader.onerror = () => {
          enqueueSnackbar('Error uploading image', { variant: 'error' });
        };
      } catch (error) {
        enqueueSnackbar('Invalid file type or error in uploading', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Invalid file type', { variant: 'error' });
    }
  };

  const onDrop = (acceptedFiles) => {
    if (edit) handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  return (
    <Box>
      <Lightbox index={0} slides={[{ src: logo }]} open={selectedImage >= 0} close={handleCloseLightbox} />
      <Box
        {...getRootProps()}
        onClick={edit ? openDropzone : handleOpenLightbox}
        sx={{
          position: 'relative',
          border: `${edit ? '1px solid' : '0px solid'}`,
          borderColor: 'info.main',
          borderRadius: `${edit ? '8px' : '2px'}`,
          boxShadow: `${edit ? ' 1px 1px 2px 0px #00000020' : '0px'}`,
        }}
      >
        {edit && <input {...getInputProps()} />}

        {!!logo && (
          <Box
            component="img"
            src={logo}
            sx={{
              color: 'white.main',
              width: '200px',
              height: '60px',
              objectFit: 'contain',
              borderRadius: `${edit ? '8px' : '2px'}`,
            }}
          />
        )}

        {!logo && edit && (
          <Typography sx={{ p: 2, textAlign: 'center', color: 'info.main', width: '100%', height: '55px' }}>
            Click to Upload facility logo or Drag and Drop
          </Typography>
        )}
      </Box>
    </Box>
  );
}
