import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/SiteRules/EmailRecipients
export async function GetSiteRulesData() {
  const response = await axios.get('/SiteRules/SiteRules');
  return response;
}

// POST
// /Api/SiteRules/RuleForm form
export async function postSiteRuleForm(form) {
  const updatedForm = {
    Id: form.id, //int
    RuleId: form.ruleId,//int
    RuleTypeId: form.ruleTypeId,//int
    VendorId: form.vendorId,//int
    Values: form.ruleValue.definitionId ? [{DefinitionId: form.ruleValue.definitionId, Value:form.ruleValue.value}] : null //obj
  };

  const formData = objectToFormData({ ...updatedForm, });
  const response = await axios.post('SiteRules/RuleForm', formData);
  return response;
}

// POST
// /Api/SiteRules/DeleteSiteRule id
export async function DeleteSiteRuleData(id) {
  const formData = new FormData();
  formData.append('id', id);
  const response = await axios.post('/SiteRules/DeleteSiteRule', formData);
  return response;
}

