import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

export async function getDashboardData() {
  const response = await axios.get('/Dashboard');
  return response;
}

// /Api/SiteSwitch siteId post
export async function postSiteSwitch(siteId) {
  const response = await axios.post(`/SiteSwitch/?siteId=${siteId}`);
  return response;
}

// GET /Api/UnreadSystemMessages
export async function getUnreadSystemMessages() {
  const response = await axios.get('/UnreadSystemMessages');
  return response;
}
// GET /Api/NavMessages
export async function getNavMessages() {
  const response = await axios.get('/NavMessages');
  return response;
}

// POST /Api/MarkAsReadSystemMessages;  ids array[integer]
export async function markAsReadSystemMessages(ids) {
  const response = await axios.post(
    '/MarkAsReadSystemMessages',
    objectToFormData({
      ids,
    })
  );
  return response;
}
