export function cloudinaryThumbnail(picture) {
  if (!picture) picture = 'Item3';
  if (picture.includes('http')) return picture;
  return `https://res.cloudinary.com/agoraserv/c_fit,w_63,h_44,q_auto,f_auto,fl_progressive,e_bgremoval/Product%20Pictures/${picture}`;
}

export function cloudinaryFull(picture) {
  if (!picture) picture = 'Item3';
  if (picture.includes('http')) return picture;
  return `https://res.cloudinary.com/agoraserv/q_auto,f_auto,fl_progressive/Product%20Pictures/${picture}`;
}
