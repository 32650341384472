import { Autocomplete, Box, Button, CircularProgress, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate,  } from 'react-router-dom';
import LoopIcon from '@mui/icons-material/Loop';
import { useEffect, useState } from 'react';
import ProgressBar from '../../../../../reusable-components/progress-bar/ProgressBar';
import CloseIconButton from '../../../../../reusable-components/basic-buttons/CloseIconButton';
import { useSelector } from '../../../../../redux/store';
import TicketTypeFields from './TicketTypeFields';
import TicketEditor from './TicketEditor';
import SubjectInput from './SubjectInput';
import convertTicketFieldValuesToHTML from './SpecialFieldTypes/ConvertTicketFieldValuesToHTML';
import AddUsersToTicket from './AddUsersToTicket';
import { CustomAvatar, CustomAvatarGroup } from '../../../../../reusable-components/custom-avatar';
import { getPossibleTicketUsers, postAddTicket } from '../../../../../api/tickets';
import { AwaitButton } from '../../../../../reusable-components/await-button';

NewTicketTest.propTypes = {
  isTestTicket: PropTypes.bool,
  setOpen: PropTypes.func,
  facilities: PropTypes.array,
  users: PropTypes.array,
  ticketTypes: PropTypes.array,
  ticketCategories: PropTypes.array,
};

export default function NewTicketTest({ isTestTicket, setOpen, facilities, users, ticketTypes, ticketCategories })
{
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [ticketTypeId, setTicketTypeId] = useState(isTestTicket ? -1 : 0);
  const [ticketCategory, setTicketCategory] = useState(null);
  const [ticketTypeFieldGroups, setTicketTypeFieldGroups] = useState([]);
  const [headerGroups, setHeaderGroups] = useState([]);
  const [facility, setFacility] = useState(null);
  const [ticketHtml, setTicketHtml] = useState(null);
  const [subject, setSubject] = useState("");
  const [openTicketMessageEditer, setOpenTicketMessageEditer] = useState(false);
  const [resetFields, setResetFields] = useState(1);
  const [typeList, setTypeList] = useState(ticketTypes?.map(type => ({ value: type.id, label: type.name })) ?? []);
  const [confirmedCategoryFacilityAndType, setConfirmedCategoryFacilityAndType] = useState(false);
  const [newUsers, setNewUsers] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [possibleUsers, setPossibleUsers] = useState(null);

  const categoryFacilityAndTypeSet = (facility || facilities.length === 0) && ticketTypeId !== 0 && (ticketCategory || ticketCategories.length === 0);

  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  useEffect(() => {
    setTypeList(ticketTypes?.map(type => ({ value: type.id, label: type.name })) ?? []);
    const newFieldGroups = [...(ticketTypes.find(type => type.id === ticketTypeId)?.fieldGroups ?? []).map(group => ({
      ...group,
      complete: false,
      fields: group.fields.map(field => ({
        ...field,
        value: null,
        htmlValue: null,
        subjectValue: null,
        addUser: null
      }))
    })).filter(group => group.fields.length > 0)];
    const showFrom = newFieldGroups.findIndex(group => group.headerGroup && !group.complete);
    const showUntil = newFieldGroups.findIndex((group, index) => index > showFrom && group.headerGroup); 
    setHeaderGroups(newFieldGroups.filter(group => group.headerGroup).slice(0, -1).map(() => 0));
    setTicketTypeFieldGroups(newFieldGroups.map((group, index) => ({ ...group, active: showUntil === -1 || index < showUntil })));
  }, [ticketTypes, ticketTypeId])

  const setInitalTicketHtml = () => {
    setOpenTicketMessageEditer(true);
    let defaultSubject = ticketTypes.find(type => type.id === ticketTypeId)?.defaultSubject ?? "";
    ticketTypeFieldGroups.flatMap(group => group.fields).forEach(field => {
      defaultSubject = defaultSubject.replace(`{${field.id} - ${field.name}}`, field.subjectValue ?? field.value ?? "");
    });
    setSubject(defaultSubject);
    setNewUsers([{ value: `${user?.firstName} ${user?.lastName}`, id: user?.email },
      ...ticketTypeFieldGroups.flatMap(group => group.fields.map(field => field.addUser)).filter(user => user)])
    setTicketHtml(convertTicketFieldValuesToHTML(ticketTypeFieldGroups) + 
      (user.settings?.find(setting => setting.show && setting.areaId === "AutoSignatureOnTickets")?.value ||
        `<br><p>Thank you,</p><p>${user.firstName} ${user.lastName}</p>`));
  }

  const clearData = () => {
    setTicketHtml(null);
    setFacility(null);
    setTicketCategory(null);
    setSubject("");
    setOpenTicketMessageEditer(false);
    setConfirmedCategoryFacilityAndType(false);

    setResetFields(reset => ++reset)
    if (!isTestTicket) {
      setHeaderGroups([]);
      setTicketTypeFieldGroups([]);
      setTicketTypeId(0);
    } else {
      setTicketTypeFieldGroups(groups => {
        const showFrom = groups.findIndex(group => group.headerGroup);
        const showUntil = groups.findIndex((group, index) => index > showFrom && group.headerGroup);  
        const newGroups = [...groups.filter(group => !group.repeatedGroupId)
          .map((group, index) => ({
            ...group,
            complete: false,
            active: showUntil === -1 || index < showUntil,
            fields: [...group.fields.map(field => ({
              ...field,
              value: null,
              htmlValue: null,
              subjectValue: null,
              addUser: null,
              orderId: null
            }))]
          }))];
        setHeaderGroups(newGroups.filter(group => group.headerGroup).slice(-1).map(() => 0));
        return newGroups;
      });
    }
  }

  const resetTicket = () => {    
    clearData();
    setResetFields(reset => ++reset)
  }   

  const submitTicket = async () => {
    const mentionedUsers = Array.from(new DOMParser().parseFromString(ticketHtml, 'text/html').querySelectorAll('span.mention')).map((mention) => mention.getAttribute('data-id'));
    const orderNumber = ticketTypes.find(type => type.id === ticketTypeId)?.name?.toLowerCase().includes("order") ?
      ticketTypeFieldGroups.flatMap(group => group.fields.map(field => field.orderId)).find(id => id > 0) ?? 0 : 0;    
    const response = await postAddTicket({
      ExtraInfo: subject,
      message: ticketHtml,
      FacilityId: facility?.id || null,
      ticketOrderId: orderNumber,
      ticketCategoryId: ticketCategory?.value ?? 0,
      files: attachments,
      Users: [...users?.map((t) => t.id), ...mentionedUsers],
      notify: true,
    });
    if (response.data?.id) {
      handleClose();
      navigate(`/dashboard/tickets/${response.data?.id}`);
    }    
  }

  useEffect(() => {
    if (confirmedCategoryFacilityAndType && facility?.id) {
      setPossibleUsers(null);
      getPossibleTicketUsers(facility.id)
        .then(res => setPossibleUsers(res.data ?? []))
        .catch(() => setPossibleUsers([]))
    } else setPossibleUsers(null);
  }, [confirmedCategoryFacilityAndType, facility]);

  const changeActiveGroup = () => {
    setTicketTypeFieldGroups(groups => {
      const updateGroupIndex = groups.findIndex(group => group.headerGroup && !group.complete);
      groups[updateGroupIndex].complete = true;
      const showFrom = groups.findIndex(group => group.headerGroup && !group.complete);
      const showUntil = groups.findIndex((group, index) => index > showFrom && group.headerGroup && !group.complete);  
      setHeaderGroups(groups.filter(group => group.headerGroup).map(group => group.complete ? 100 : 0).slice(0, -1));   
      if (showFrom === -1 && showUntil === -1) {
        setInitalTicketHtml();
        return [...groups.map(group => ({ ...group, active: false }))];
      }
      return [...groups.map((group, index) => ({
        ...group,
        active: (showFrom === -1 || index >= showFrom) && (showUntil === -1 || index <= showUntil)
      }))]
    });
  }  

  return <>
    <DialogTitle sx={{ textAlign: 'center' }} >
      New Ticket
      <Tooltip title="Reset Ticket">
        <IconButton size="small" color={"secondary"} sx={{ ml: 1 }} onClick={resetTicket}>
          <LoopIcon />
        </IconButton>
      </Tooltip>
      <ProgressBar values={[100, confirmedCategoryFacilityAndType ? 100 : 0, ...headerGroups, ticketHtml ? 100 : 0]} />
      {setOpen && <CloseIconButton onClick={handleClose} />}
    </DialogTitle>

    <DialogContent>
      {!confirmedCategoryFacilityAndType && <Stack mt={1} spacing={3}>
        {facilities?.length > 0 && (
          <Autocomplete
            fullWidth
            size="small"
            options={facilities}
            value={facility}
            getOptionLabel={(option) => option.facilityName}
            renderInput={(params) => <TextField {...params} label="Select a facility" />}
            onChange={(event, newValue) => setFacility(newValue)}
          />
        )}
        {ticketCategories?.length > 0 && <Autocomplete
          fullWidth
          size="small"
          options={ticketCategories}
          value={ticketCategory}
          renderInput={(params) => <TextField {...params} label="Choose a ticket category" />}
          onChange={(event, newValue) => setTicketCategory(newValue)}
        />}
        <Autocomplete
          fullWidth
          size="small"
          options={typeList}
          value={typeList.find(type => type.value === ticketTypeId) || null}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => <TextField {...params} label="Choose a ticket type" />}
          onChange={(event, newValue) => setTicketTypeId(newValue?.value ?? 0)}
        />
      </Stack>}   
      {!openTicketMessageEditer && confirmedCategoryFacilityAndType && <TicketTypeFields
        key={resetFields}
        ticketTypeFieldGroups={ticketTypeFieldGroups.filter(group => group.active)}
        setTicketTypeFieldGroups={setTicketTypeFieldGroups}
        facility={facility}
        ticketType={ticketTypes.find(type => type.id === ticketTypeId)}
        headerGroups={headerGroups}
        setHeaderGroups={setHeaderGroups}
      />}

      {openTicketMessageEditer && confirmedCategoryFacilityAndType && <>
        <SubjectInput value={subject} setValue={setSubject} />
        {possibleUsers !== null ? <TicketEditor
          value={ticketHtml}
          attachments={attachments}
          setAttachments={setAttachments}
          onChange={(value) => setTicketHtml(value)}
          users={possibleUsers}          
          editorProps={{
            attachFile: true,
            id: "new-item-ticket",
            placeholder: `${typeList.find(type => type.value === ticketTypeId)?.label} ticket...`
          }}
        /> : <Box sx={{ textAlign: "center" }}><CircularProgress /></Box>}
      </>}
      {ticketHtml && <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
        <Typography mr={.5}>Recipients:</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'left' }}>
          <CustomAvatarGroup size="medium">
            {newUsers?.map((user, index) => <CustomAvatar name={user?.value || user?.id} email={user?.id} key={index} />)}
            <AddUsersToTicket newUsers={newUsers} setNewUsers={setNewUsers} possibleUsers={possibleUsers} />
          </CustomAvatarGroup>
        </Box>
      </Grid>}
      <Stack justifyContent="center" alignItems="center">
        {!confirmedCategoryFacilityAndType && <Button
          variant="contained"
          disabled={!categoryFacilityAndTypeSet}
          onClick={() => {
            setConfirmedCategoryFacilityAndType(true);
            if (!ticketTypeFieldGroups?.some(group => group?.fields.some(field => field.name && field.type && field.type !== "No Input Text"))) setInitalTicketHtml();
          }}
          sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
        >
          Next
        </Button>}
        {confirmedCategoryFacilityAndType && !ticketHtml && <Button
          variant="contained"
          disabled={ticketTypeFieldGroups.filter(group => group.active)?.some(group => !group.repeatedGroupId && group.fields?.some(field => field.required && !field.value))}
          onClick={changeActiveGroup}
          sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
        >
          Next
        </Button>}      
        {confirmedCategoryFacilityAndType && ticketHtml && <AwaitButton
          variant="contained"
          onClick={submitTicket}
          sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
          disabled={!categoryFacilityAndTypeSet || isTestTicket}
        >
          Submit ticket
        </AwaitButton>}
      </Stack>
    </DialogContent>
  </>;
}