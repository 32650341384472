import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Typography,
  Box,
  Button,
  TextField,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
  DialogContentText,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { addMultipleOrderGuides } from '../../../../api/order-guides';
import { getOrderGuides } from '../../../../redux/slices/orderGuides';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function NewOrderGuideDialog() {
  const {
    data: { facilities, categories, templates },
  } = useSelector((state) => state.orderGuides);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    facilities: [],
    categories: [],
    copy: '',
    copyPreferences: false,
    copyFrom: 'Facility',
  });
  const handleClickOpen = () => {
    setValue({
      copyFrom: 'Facility',
      facilities: [],
      categories: [],
      copy: '',
      copyPreferences: false,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setValue({
      copyFrom: 'Facility',
      facilities: [],
      categories: [],
      copy: '',
      copyPreferences: false,
    });

    setOpen(false);
  };

  const handleSave = async () => {
    const response = await addMultipleOrderGuides(value);
    if (response?.data?.id) {
      dispatch(getOrderGuides());
      navigate(`/dashboard/admin/order-guides/${response.data.id}`);
    }
    else if (response?.data?.success) {
      dispatch(getOrderGuides());
      enqueueSnackbar(`Successfully added the order guides.`, { variant: 'success' });
    } else {
      enqueueSnackbar(`${response?.data?.error ?? `Failed to add order guides`
}`, { variant: 'error' });
    }
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        Add Order Guide
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          New Order Guide
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ p: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Facilities*
                </Typography>
                <Autocomplete
                  multiple
                  fullWidth
                  value={value.facilities}
                  onChange={(event, newValue) => {
                    setValue((prevValue) => ({
                      ...prevValue,
                      facilities: [...newValue],
                    }));
                  }}
                  options={facilities?.map((type) => ({ id: type.value, label: type.label }))}
                  isOptionEqualToValue={(option, selected) => option.id === selected.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                  size="small"
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Categories*
                </Typography>
                <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  value={value.categories}
                  onChange={(event, newValue) => {
                    setValue((prevValue) => ({
                      ...prevValue,
                      categories: newValue.some((option) => option.id === 'selectAll') ? [...categories?.map((type) => ({ id: type.value, label: type.label }))] : [...newValue],
                    }));
                  }}
                  options={[{ id: 'selectAll', label: 'Select All' }, ...categories?.map((type) => ({ id: type.value, label: type.label }))]}
                  isOptionEqualToValue={(option, selected) => option.id === selected.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Grid item>
                  <DialogContentText sx={{ fontWeight: 'bold' }}>Copy From</DialogContentText>
                  <ToggleButtonGroup
                    value={value.copyFrom}
                    exclusive
                    onChange={(event, newValue) => {
                      // const boolValue = newValue === 'true';
                      setValue({
                        ...value,
                        copy: '',
                        copyFrom: newValue,
                      });
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton size="small" value="Facility">
                      Facility
                    </ToggleButton>
                    <ToggleButton size="small" value="Template">
                      Template
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {value.copyFrom} to copy from
                </Typography>
                <Autocomplete
                  size="small"
                  fullWidth
                  value={value.copy}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      copy: newValue,
                    });
                  }}
                  options={
                    value.copyFrom === 'Facility'
                      ? facilities?.map((type) => ({ id: type.value, label: type.label }))
                      : templates?.map((type) => ({ id: type.id, label: type.templateName }))
                  }
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.copyPreferences}
                      onChange={(event) => {
                        setValue({
                          ...value,
                          copyPreferences: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Copy vendor preferences"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.facilities.length === 0 || value.categories.length === 0}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
