import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const departmentColors = {
  Activity: '#FFB74D',
  Dietary: '#FF8A65',
  DME: '#b128bc',
  Facility: '#9575CD',
  'Housekeeping & Laundry': '#4DB6AC',
  Maintenance: '#A1887F',
  Medical: '#E57373',
  Office: '#7986CB',
  Therapy: '#4FC3F7',
};

export const renderOutlinedBox = (value) => (
  <Typography
    color={stringToColor(value)}
    variant="subtitle2"
    sx={{
      border: 1,
      minWidth: '20px',
      minHeight: '20px',
      borderRadius: '3px',
    }}
  >
    <Box sx={{ m: 0.5 }}>{value || ''}</Box>
  </Typography>
);
export const renderColorBox = (value, color) => (
  <Typography
    color={color || 'primary'}
    variant="subtitle2"
    sx={{
      border: 1,
      minWidth: '20px',
      minHeight: '20px',
      borderRadius: '3px',
      my: 0.5,
    }}
  >
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'hidden', // Hide overflow
        textOverflow: 'ellipsis', // Add ellipsis for overflow
        whiteSpace: 'nowrap', // Prevent wrapping
        m: 0.5,
      }}
    >
      {value || ''}
    </Box>
  </Typography>
);

export const renderInitialsBox = (value) =>
  value ? (
    <Typography
      color="primary"
      variant="subtitle2"
      sx={{
        border: 1,
        minWidth: '20px',
        minHeight: '20px',
        borderRadius: '3px',
      }}
    >
      <Box sx={{ m: 0.5 }}>{value}</Box>
    </Typography>
  ) : null;
// ----------------------------------------------------------------------

export function bgBlur(props) {
  const color = props?.color || '#000000';
  const blur = props?.blur || 6;
  const opacity = props?.opacity || 0.8;
  const imgUrl = props?.imgUrl;

  if (imgUrl) {
    return {
      position: 'relative',
      backgroundImage: `url(${imgUrl})`,
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9,
        content: '""',
        width: '100%',
        height: '100%',
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
      },
    };
  }

  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  };
}

// ----------------------------------------------------------------------

export function bgGradient(props) {
  const direction = props?.direction || 'to bottom';
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color}), url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}

// ----------------------------------------------------------------------

export function textGradient(value) {
  return {
    background: `-webkit-linear-gradient(${value})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
}

// ----------------------------------------------------------------------

export function filterStyles(value) {
  return {
    filter: value,
    WebkitFilter: value,
    MozFilter: value,
  };
}

export const priorityColor = (priority, theme) => {
  switch (priority) {
    case 'Low':
      return theme.palette.warning.main;
    case 'Medium':
      return theme.palette.info.main;
    case 'High':
      return theme.palette.secondary.main;
    case 'Maximum':
      return theme.palette.error.main;
    default:
      return theme.palette.success.main;
  }
};
export function getInitials(user) {
  user = user?.trim() || 'NA';
  return user && user?.trim().split(' ').length > 1
    ? user.split(' ')[0][0].toUpperCase() + user.split(' ')[user.split(' ').length - 1][0].toUpperCase()
    : user.split(' ')[0][0].toUpperCase();
}

export function stringToColor(string) {
  if (!string) return '#000000';
  let hash = 0;
  let i;

  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  // Use the new adjusted color based on brightness
  color = adjustColor(color);
  return color;
}

const colors = {
  Primary: `primary.main`,
  Info: `info.main`,
  Warning: 'warning.main',
};

export function stringToPrimaryColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Determine which color to use based on the hash value
  const colorKeys = Object.keys(colors);
  const colorIndex = Math.abs(hash) % colorKeys.length;
  const colorKey = colorKeys[colorIndex];

  return colors[colorKey];
}

function getBrightness(hexColor) {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  return (r * 299 + g * 587 + b * 114) / 1000;
}

function adjustColor(hexColor) {
  const brightness = getBrightness(hexColor);
  let factor;

  // Darken more significantly based on the brightness
  if (brightness > 180) {
    factor = 0.3; // Darken significantly if very bright
  } else if (brightness > 100) {
    factor = 0.5; // Darken moderately if moderately bright
  } else {
    factor = 0.7; // Darken slightly if already somewhat dark
  }

  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const newR = Math.floor(r * factor).toString(16);
  const newG = Math.floor(g * factor).toString(16);
  const newB = Math.floor(b * factor).toString(16);

  return `#${newR.padStart(2, '0')}${newG.padStart(2, '0')}${newB.padStart(2, '0')}`;
}

//https://icon-sets.iconify.design/fluent-emoji-flat/
export const categoryIcons = {
  Dietary: 'fluent-emoji-flat:spaghetti',
  Medical: 'fluent-emoji-flat:medical-symbol',
  'Housekeeping & Laundry': 'fluent-emoji-flat:broom',
  Housekeeping: 'fluent-emoji-flat:broom',
  'Other Expenses': 'fluent-emoji-flat:shopping-cart',
  DME: 'fluent-emoji-flat:bed',
  Maintenance: 'fluent-emoji-flat:hammer-and-wrench',
  Therapy: 'fluent-emoji-flat:crutch',
  Office: 'fluent-emoji-flat:pen',
  Activity: 'fluent-emoji-flat:pool-8-ball',
  Marketing: 'fluent-emoji-flat:megaphone',
  Catalog: 'fluent-emoji-flat:window',
};

export const listOfEmojis = [
  'fluent-emoji-flat:admission-tickets',
  'fluent-emoji-flat:artist-palette',
  'fluent-emoji-flat:christmas-tree',
  'fluent-emoji-flat:menorah',
  'fluent-emoji-flat:party-popper',
  'fluent-emoji-flat:ribbon',
  'fluent-emoji-flat:wrapped-gift',
  'fluent-emoji-flat:puzzle-piece',
  'fluent-emoji-flat:game-die',
  'fluent-emoji-flat:yarn',
  'fluent-emoji-flat:sunflower',
  'fluent-emoji-flat:bouquet',
  'fluent-emoji-flat:fish',
  'fluent-emoji-flat:chequered-flag',
  'fluent-emoji-flat:bagel',
  'fluent-emoji-flat:baguette-bread',
  'fluent-emoji-flat:bottle-with-popping-cork',
  'fluent-emoji-flat:broccoli',
  'fluent-emoji-flat:birthday-cake',
  'fluent-emoji-flat:fork-and-knife-with-plate',
  'fluent-emoji-flat:bowl-with-spoon',
  'fluent-emoji-flat:teapot',
  'fluent-emoji-flat:teacup-without-handle',
  'fluent-emoji-flat:shallow-pan-of-food',
  'fluent-emoji-flat:popcorn',
  'fluent-emoji-flat:cooking',
  'fluent-emoji-flat:sandwich',
  'fluent-emoji-flat:pizza',
  'fluent-emoji-flat:adhesive-bandage',
  'fluent-emoji-flat:accordion',
  'fluent-emoji-flat:basket',
  'fluent-emoji-flat:bathtub',
  'fluent-emoji-flat:battery',
  'fluent-emoji-flat:blue-book',
  'fluent-emoji-flat:briefs',
  'fluent-emoji-flat:bubbles',
  'fluent-emoji-flat:bucket',
  'fluent-emoji-flat:dna',
  'fluent-emoji-flat:chart-decreasing',
  'fluent-emoji-flat:dollar-banknote',
  'fluent-emoji-flat:electric-plug',
  'fluent-emoji-flat:laptop',
  'fluent-emoji-flat:light-bulb',
  'fluent-emoji-flat:lotion-bottle',
  'fluent-emoji-flat:keyboard',
  'fluent-emoji-flat:linked-paperclips',
  'fluent-emoji-flat:magnet',
  'fluent-emoji-flat:ladder',
  'fluent-emoji-flat:movie-camera',
  'fluent-emoji-flat:megaphone',
  'fluent-emoji-flat:musical-notes',
  'fluent-emoji-flat:pill',
  'fluent-emoji-flat:roll-of-paper',
  'fluent-emoji-flat:t-shirt',
  'fluent-emoji-flat:television',
  'fluent-emoji-flat:telephone',
  'fluent-emoji-flat:wastebasket',
  'fluent-emoji-flat:tear-off-calendar',
  'fluent-emoji-flat:biting-lip',
  'fluent-emoji-flat:eyes',
  'fluent-emoji-flat:ear-with-hearing-aid-light',
  'fluent-emoji-flat:health-worker-medium',
  'fluent-emoji-flat:man-biking-medium-light',
  'fluent-emoji-flat:man-lifting-weights-medium',
  'fluent-emoji-flat:nail-polish-medium-light',
  'fluent-emoji-flat:motor-scooter',
  'fluent-emoji-flat:manual-wheelchair',
  'fluent-emoji-flat:woman-in-manual-wheelchair-dark',
  'fluent-emoji-flat:woman-in-motorized-wheelchair-light',
  'fluent-emoji-flat:anger-symbol',
  'fluent-emoji-flat:heart-hands-medium-dark',
  'fluent-emoji-flat:collision',
  'fluent-emoji-flat:dizzy',
  'fluent-emoji-flat:hundred-points',
  'fluent-emoji-flat:money-mouth-face',
  'fluent-emoji-flat:robot',
  'fluent-emoji-flat:zzz',
  'fluent-emoji-flat:red-heart',
  'fluent-emoji-flat:plus',
  'fluent-emoji-flat:multiply',
  'fluent-emoji-flat:plus',
  'fluent-emoji-flat:infinity',
  'fluent-emoji-flat:check-mark',
  'fluent-emoji-flat:recycling-symbol',
  'fluent-emoji-flat:restroom',
  'fluent-emoji-flat:airplane',
  'fluent-emoji-flat:ambulance',
  'fluent-emoji-flat:bus',
  'fluent-emoji-flat:bank',
  'fluent-emoji-flat:spaghetti',
  'fluent-emoji-flat:medical-symbol',
  'fluent-emoji-flat:broom',
  'fluent-emoji-flat:bed',
  'fluent-emoji-flat:hammer-and-wrench',
  'fluent-emoji-flat:crutch',
  'fluent-emoji-flat:pen',
  'fluent-emoji-flat:pool-8-ball',
  'fluent-emoji-flat:loudspeaker',
  'fluent-emoji-flat:window',
  'fluent-emoji-flat:shopping-cart',
  'fluent-emoji-flat:lipstick',
  'fluent-emoji-flat:handshake',
  'fluent-emoji-flat:open-mailbox-with-raised-flag',
  'fluent-emoji-flat:soap',
  'fluent-emoji-flat:watch',
  'fluent-emoji-flat:card-index-dividers',
  'fluent-emoji-flat:snowflake',
  'fluent-emoji-flat:cyclone',
];

export const categoryIconLookup = {
  medical: 'mdi:shot',
  dietary: 'fluent:food-28-regular',
  'housekeeping & laundry': 'mdi:laundry-room',
  housekeeping: 'mdi:laundry-room',
  'other expenses': 'icon-park-outline:shopping',
  dme: 'carbon:hospital-bed',
  maintenance: 'f7:hammer',
  therapy: 'streamline:crutch',
  office: 'ph:pen',
  activity: 'fluent:board-games-20-regular',
  marketing: 'mdi:megaphone-outline',
  catalog: 'material-symbols:window-outline',
};

export const rentalCategoryIcons = {
  DME: 'openmoji:man-in-manual-wheelchair',
  Respiratory: 'icon-park:medical-files',
};

export const rentalSubcategoryIcons = {
  Beds: 'noto:bed',
  Mattresses: 'icon-park:double-bed',
  Wheelchairs: 'openmoji:person-in-manual-wheelchair',
  'Broda Chairs': 'fluent-emoji-flat:motorized-wheelchair',
  'Geri Chairs': 'twemoji:motorized-wheelchair',
};

export function getIconForCategory(categoryName) {
  return categoryIcons[categoryName?.trim()] || 'fluent-emoji-flat:plus';
}

export function getRentalIconForCategory(categoryName) {
  return rentalCategoryIcons[categoryName?.trim()] || 'fluent-emoji-flat:plus';
}

export function getRentalIconForSubcategory(subcategoryName) {
  return rentalSubcategoryIcons[subcategoryName?.trim()] || 'fluent-emoji-flat:plus';
}
