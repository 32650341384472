import axios from '../../utils/axios';
import { objectToFormData } from '../utils';
// /Admin/User/LoginAsUser done
// /Admin/User/GetUsers done
// /Admin/User/UserRoleData done
// /Admin/User/UpdateUserMain done
// /Admin/User/UpdateUserRoles done
// /Admin/User/UpdateUserSecurity done
// /Admin/User/TemplateValues done
// /Admin/User/UserRoleTemplateForm done
// /Admin/User/TemplateUsersForm done
// /Admin/User/DownloadUsersTable done
// /Admin/User/DeleteUser done
// /Admin/User/DeleteTemplate done
// /Admin/User/ResendEmailConfirmation done
// /Admin/User/NewUser done

//GET
export async function loginAsUser(userEmail) {
  const response = await axios.get(`/Admin/User/LoginAsUser?userEmail=${userEmail}`);
  return response;
}

export async function getUsersData() {
  const response = await axios.get('/Admin/User/GetUsers');
  return response;
}

export async function getUserRoleData(userId) {
  const response = await axios.get(`/Admin/User/UserRoleData?userId=${userId}`);
  return response;
}

export async function downloadUsersTable() {
  const response = await axios.get('/Admin/User/DownloadUsersTable');
  return response;
}
export async function getUserTemplateValuesData(id) {
  const response = await axios.get(`/Admin/User/TemplateValues?id=${id}`);
  return response;
}
//Post

export async function updateUserMain(data) {
  const formData = new FormData();
  formData.append('id', data.id);
  formData.append('firstName', data.firstName);
  formData.append('lastName', data.lastName);
  formData.append('position', data.position);
  formData.append('email', data.email);
  formData.append('facilityId', data.facilityId);
  const response = await axios.post('/Admin/User/UpdateUserMain', formData);
  return response;
}

export async function updateUserSecurity(data) {
  const formData = new FormData();
  formData.append('id', data.id);
  if (data.password) formData.append('password', data.password);
  formData.append('emailConfirmed', data.emailConfirmed);
  const response = await axios.post('/Admin/User/UpdateUserSecurity', formData);
  return response;
}

export async function updateUserSSO(data) {
  const response = await axios.post('/Admin/User/UpdateUserSSO', objectToFormData(data));
  return response;
}

export async function updateUserRoles({ id, templateId, roles, roleValues }) {
  const setOfPermissionTypes = Array.from(new Set(roleValues.map((role) => role.userPermissionTypeId)));

  const mappedRoleValues = setOfPermissionTypes.map((userPermissionTypeId) => ({
    permissionTypeId: userPermissionTypeId,
    values: roleValues
      .filter((x) => x.userPermissionTypeId === userPermissionTypeId)
      .map((y) => ({ key: y.modelId, value: y.modelAttachmentId ?? 0 })),
  }));
  const newObj = { id, roles, roleValues: mappedRoleValues, templateId };
  const response = await axios.post('/Admin/User/UpdateUserRoles', objectToFormData(newObj));

  return response;
}
// /Api/Admin/User/UserRoleTemplateForm id, name siteId useUserFacility roles values
export async function updateTemplateRoles({ id, name, siteId, useUserFacility, roles, roleValues }) {
  const setOfPermissionTypes = Array.from(new Set(roleValues.map((role) => role.userPermissionTypeId)));

  const mappedRoleValues = setOfPermissionTypes.map((userPermissionTypeId) => ({
    permissionTypeId: userPermissionTypeId,
    values: roleValues
      .filter((x) => x.userPermissionTypeId === userPermissionTypeId)
      .map((y) => ({ key: y.modelId, value: y.modelAttachmentId ?? 0 })),
  }));
  const newObj = {
    Template: {
      id,
      name,
      siteId,
      useUserFacility,
    },
    Roles: roles
      .map((role) => ({
        roleId: role?.roleId || role?.id,
        selectAll: role?.selectAll,
        // id: role?.id,
        userTemplateId: role?.userTemplateId,
      }))
      .filter((role) => role?.roleId > 0),
    
    RoleValues: mappedRoleValues,
  };
  const response = await axios.post('/Admin/User/UserRoleTemplateForm', objectToFormData(newObj));
  return response;
}

export async function updateTemplateUsers({ templateId, users }) {
  const newObj = { templateId, users };
  const response = await axios.post('/Admin/User/TemplateUsersForm', objectToFormData(newObj));
  return response;
}

// /Admin/User/SetUserEnabledState
export async function setUserEnabledState(id, enable) {
  const response = await axios.post(`/Admin/User/SetUserEnabledState?id=${id}&enable=${enable}`);
  return response;
}
// /Admin/User/EnableUsers
export async function enableUsers(ids) {
  const formData = new FormData();
  for (let index = 0; index < ids.length; ++index) {
    const id = ids[index];
    formData.append(`ids[${index}]`, id);
  }
  const response = await axios.post(`/Admin/User/EnableUsers`, formData);
  return response;
}

// /Admin/User/DisableUsers
export async function disableUsers(ids) {
  const formData = new FormData();
  for (let index = 0; index < ids.length; ++index) {
    const id = ids[index];
    formData.append(`ids[${index}]`, id);
  }
  const response = await axios.post(`/Admin/User/DisableUsers`, formData);
  return response;
}

// /Admin/User/DeleteUser
export async function deleteUser(id) {
  const response = await axios.post(`/Admin/User/DeleteUser?id=${id}`);
  return response;
}

// /Admin/User/DeleteUsers
export async function deleteUsers(ids) {
  const formData = new FormData();

  for (let index = 0; index < ids.length; ++index) {
    const id = ids[index];
    formData.append(`ids[${index}]`, id);
  }

  const response = await axios.post(`/Admin/User/DeleteUsers`, formData);
  return response;
}

export async function restoreUser(id) {
  const response = await axios.post(`/Admin/User/RestoreUsers?ids=${id}`);
  return response;
}

export async function deleteTemplate(id) {
  const response = await axios.post(`/Admin/User/DeleteTemplate?id=${id}`);
  return response;
}
export async function resendEmailConfirmation(userId) {
  const response = await axios.post(`/Admin/User/ResendEmailConfirmation?userId=${userId}`);
  return response;
}

// /Admin/User/NewUser firstName lastName position email facilityId password
export async function newUser({ firstName, lastName, position, email, facilityId, password }) {
  const formData = new FormData();
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('position', position);
  formData.append('email', email);
  formData.append('facilityId', facilityId);
  formData.append('password', password);

  const response = await axios.post('/Admin/User/NewUser', formData);
  return response;
}
