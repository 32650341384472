import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/DownloadOrderGuide orderGuideActivationId
// //Download Api is used in code bec we need the `${HOST_API_URL}

// GET
// /Api/DownloadOrderGuideActivations

// GET
// /Api/ManageOrderGuideData
export async function getManageOrderGuideData() {
  const response = await axios.get('/ManageOrderGuideData');
  return response;
}
// POST
// /Api/VendorPreferencesBulkSubmit
export async function vendorPreferencesBulkSubmit(value) {
  const isRestrictedVendor = value?.orderGuideType === 'Vendor restrictions';
  const preferredVendors = value?.preferredSubcategories?.map((sub) => ({
    VendorId: sub?.vendor?.value,
    AgoraCategoryId: sub.mainCategoryId,
    AgoraSubcategoryId: sub?.id,
    IsRestricted: isRestrictedVendor,
  }));
  const restrictedVendors = value?.restrictedSubcategories
    ?.map((sub) =>
      sub?.vendor?.map((vendor) => ({
        VendorId: vendor?.value,
        AgoraCategoryId: sub.mainCategoryId,
        AgoraSubcategoryId: sub?.id,
        IsRestricted: isRestrictedVendor,
      }))
    )
    .flat(1);

  const newObj = {
    facilities: value?.facilities?.map((f) => f.id || f.value),
    categories: value?.categories?.map((c) => c.value),
    vendorPreferences: isRestrictedVendor ? restrictedVendors : preferredVendors,
    isRestricted: isRestrictedVendor,
    mode: value?.mode,
  };

  const response = await axios.post('/VendorPreferencesBulkSubmit', objectToFormData(newObj));
  return response;
}
// POST
// /Api/OrderGuideBulkSubmit
export async function orderGuideBulkSubmit(value) {
  const newObj = {
    facilities: value.facilities?.map((f) => f.id || f.value),
    categoryId: value.categories?.[0]?.value,
    productIds: value.items,
    mode: value.mode,
  };
  const response = await axios.post('/OrderGuideBulkSubmit', objectToFormData(newObj));
  return response;
}

export async function getOrderGuidesData() {
  const response = await axios.get('/OrderGuidePermissionsPageData');
  return response;
}
export async function getOrderGuidesFormData(id) {
  const response = await axios.get(`/GetOrderGuideFormData?id=${id}`);
  return response;
}

export async function getAllProductsData() {
  const response = await axios.get('/AllProducts');
  return response;
}

// POST
// /Api/DeleteOrderGuide?id=
export async function deleteOrderGuide(id) {
  const response = await axios.post(`/DeleteOrderGuide?id=${id}`);
  return response;
}

// POST
// /Api/AddOrderGuide
export async function addOrderGuide(value) {
  const AddGuide = {
    FacilityId: value.facility.id,
    CategoryId: value.category.id,
    TemplateName: value.templateName,
  };
  const newObj = {
    AddGuide,
    CopyVendorPreferences: value.copyPreferences || false,
    CopyGuideId: value?.facilityCopy?.id || value.copy.id,
  };
  const response = await axios.post('/AddOrderGuide', objectToFormData(newObj));
  return response;
}
// POST
// /Api/AddMultipleOrderGuides
export async function addMultipleOrderGuides(value) {
  const newObj = {
    FacilityIds: value.facilities?.map(facility => facility.id),
    CategoryIds: value.categories?.map(category => category.id),
    CopyVendorPreferences: value.copyPreferences || false,
    CopyFacilityId: value.copyFrom === 'Facility' ? value.copy?.id : "0",
    CopyGuideId: value.copyFrom === 'Facility' ?  "0" : value.copy?.id,
  };
  const response = await axios.post('/AddOrderGuides', objectToFormData(newObj));
  return response
}
// POST
// /Api/SetOrderGuideItems facilityId agoraCategoryId activate permissionItemIds[ ]
export async function setOrderGuideItems(id, activate, permissionItemIds) {
  const newObj = {
    id,
    activate,
    permissionItemIds,
  };
  const response = await axios.post('/SetOrderGuideItems', objectToFormData(newObj));
  return response;
}

// POST
// /Api/OrderGuideVendorPreferencesForm facilityId agoraCategoryId vendors[]
export async function orderGuideVendorPreferencesForm({ id, tempPreferences, tempRestricted }) {
  const objFilter = (arr) =>
    arr
      ?.filter((obj) => obj.vendorId && obj.agoraSubcategoryId)
      .map((obj) => ({
        vendorId: obj.vendorId,
        agoraSubcategoryId: obj.agoraSubcategoryId,
        isRestricted: false,
      }));
  const objRestrictedFilter = (arr) =>
    arr
      ?.filter((obj) => obj.vendorId && obj.agoraSubcategoryId)
      .map((obj) => ({
        vendorId: obj.vendorId,
        agoraSubcategoryId: obj.agoraSubcategoryId,
        isRestricted: true,
      }));
  const preferredVendors = [];
  if (tempPreferences) {
    preferredVendors.push(...objFilter(tempPreferences));
  }
  if (tempRestricted) {
    preferredVendors.push(...objRestrictedFilter(tempRestricted));
  }

  const newObj = {
    id,
    preferredVendors,
  };

  const response = await axios.post('/OrderGuideVendorPreferencesForm', objectToFormData(newObj));
  return response;
}

// GET
// /Api/getOrderGuideVendorPreferencesData facilityId agoraCategoryId
export async function getOrderGuideVendorPreferencesData(id) {
  const response = await axios.get(`/GetOrderGuideVendorPreferences?id=${id}`);
  return response;
}

// POST

// POST
// /Api/UpdateOrderGuidePricing id
export async function updateOrderGuidePricing(id) {
  const response = await axios.post(`/UpdateOrderGuidePricing?id=${id}`);
  return response;
}
