import { Autocomplete,  TextField, Box, Button, Card, Grid, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { postSetTicketMessagesRead } from '../../../../api/tickets';
import { editTicketData, setBoomerangDate } from '../../../../redux/slices/tickets';
import { getUnreadData } from '../../../../redux/slices/dashboard';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';
import { priorityColor } from '../../../../utils/cssStyles';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { fDateTime } from '../../../../utils/formatTime';
import { inOneHour, inThreeDays, tomorrow } from './RemindMeTimers';

export default function TicketActions() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id = '' } = useParams();
  const subticketId = new URLSearchParams(location.search).get('subticket');
  const isManager = useScopeCheck(["Facility-Manager"]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { ticketNumber } = useSelector((state) => state.tickets.data.ticketById[subticketId || id]) || {};
  const {  ticketCategories } = useSelector((state) => state.tickets.data);
  const { mainTicket, boomerang } = useSelector((state) => state.tickets.data.ticketInfoById[id]) || {};
  const [priority, setPriority] = useState(mainTicket?.importance || 'Medium');
  const [showCustomBoomerangDate, setShowCustomBoomerangDate] = useState(false);
  const [removeBoomerangTooltip, setRemoveBoomerangTooltip] = useState(false);

  const editTicket = async ({ Id, value, updateType }) => {
    const response = await dispatch(editTicketData({ Id: ticketNumber || Id, value, updateType }));
    console.log(response);
    if (response) {
      enqueueSnackbar('Ticket updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Ticket failed to update', { variant: 'error' });
    }
    return response;
  };
  const handleComplete = (value) => {
    editTicket({ Id: ticketNumber || id, value, updateType: 'Complete' });
    navigate(`/dashboard/tickets?filter=active`);
  };

  const editTicketCategory = async (value) => {
    const response = await dispatch(editTicketData({ Id: mainTicket?.id, value, updateType: "TicketCategoryId" }));
    if (response) enqueueSnackbar('Category updated successfully', { variant: 'success' });   
    else enqueueSnackbar('Category failed to update', { variant: 'error' });
    return response;
  };

  const handleUnread = async () => {
    const response = await postSetTicketMessagesRead({ id: ticketNumber || id, markAsUnread: true });
    if (response.status === 200) {
      dispatch(getUnreadData());
    }
    navigate(`/dashboard/tickets?filter=active`);
  };

  const handleChange = (event) => {
    editTicket({ Id: ticketNumber || id, value: event.target.value, updateType: 'Importance' });
    setPriority(event.target.value);
  };
  const updateBoomerangDate = async (date) => {
    await dispatch(setBoomerangDate(date, id, enqueueSnackbar));   
  };

  useEffect(() => {
    setPriority(mainTicket?.importance || 'Medium');
  }, [mainTicket]);

  useEffect(() => {
    setShowCustomBoomerangDate(!!boomerang);
  }, [boomerang]);

  return (
    <>
      <Card sx={{ height: '100%', mb: 2, p: 2 }}>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            {mainTicket?.complete ? (
              <Button variant="outlined" size="small" onClick={() => handleComplete(false)}>
                Reopen ticket
              </Button>
            ) : (
              <AwaitButton variant="contained" size="small" onClick={() => handleComplete(true)} color="secondary">
                Mark as complete
              </AwaitButton>
            )}
          </Grid>
          <Grid item>
            <Tooltip title="This is for the main ticket" arrow placement="top">
              <Box>
                <Select
                  value={priority}
                  onChange={handleChange}
                  displayEmpty
                  sx={{
                    borderRadius: '5px',
                    maxHeight: '30px',
                    border: (theme) => `solid 1px ${priorityColor(priority, theme)}`,
                    color: (theme) => priorityColor(priority, theme),
                    '& .MuiSelect-icon': {
                      color: (theme) => priorityColor(priority, theme),
                    },
                  }}
                >
                  <MenuItem value={'Low'}>Low Priority</MenuItem>
                  <MenuItem value={'Medium'}>Medium Priority</MenuItem>
                  <MenuItem value={'High'}>High Priority</MenuItem>
                  <MenuItem value={'Maximum'}>Maximum Priority</MenuItem>
                </Select>
              </Box>
            </Tooltip>
          </Grid>         
          <ScopeGuard scopes={['Tickets-Edit']} allowAdmin>
            <Grid item>
              <AwaitButton variant="outlined" size="medium" onClick={handleUnread} color="secondary">
                Mark as unread
              </AwaitButton>
            </Grid>
          </ScopeGuard>
        <Grid item sx = {{width: '100%'}}>
          <Typography sx={{ textAlign: "center",  color: "#208ee0", borderTop: "1px solid" }} color="info.main" variant="h6"/>
        </Grid>
        <Grid item sx  = {{width: '50%'}}>
          <Typography sx={{ textAlign: "center", mb: 1, pt: 1, color: "#208ee0" }} color="info.main" variant="h6">Category:</Typography>
          <Autocomplete
            fullWidth
            size="small"
            options={ticketCategories || []}
            value={ticketCategories?.find(val => val.value === mainTicket?.ticketCategoryId) || null}
            renderInput={(params) => <TextField {...params} placeholder={"Select a category"} />}
            onChange={(event, newValue) => editTicketCategory(newValue?.value)}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': 'none',
            }}
          /> 
          </Grid>

          {isManager && <Grid item>
            <Typography variant="subtitle1" sx={{ textAlign: "center", mb: 1, pt: 1, color: "#208ee0", borderTop: "1px solid" }} >
              Remind me
            </Typography>
            <Grid justifyContent="center" alignItems="center" container spacing={2}>
              <Grid item>
                <AwaitButton sx={{ width: "120px" }} variant="outlined" size="small" color="secondary" onClick={async () => {
                  await updateBoomerangDate(inOneHour());
                }}>
                  In one hour
                </AwaitButton>
              </Grid>
              <Grid item>
                <AwaitButton sx={{ width: "120px" }} variant="outlined" size="small" color="secondary" onClick={async () => {
                  await updateBoomerangDate(inThreeDays());
                }}>
                  In three days
                </AwaitButton>
              </Grid>
              <Grid item>
                <AwaitButton sx={{ width: "120px" }} variant="outlined" size="small" color="secondary" onClick={async () => {
                  await updateBoomerangDate(tomorrow());
                }}>
                  Tomorrow
                </AwaitButton>
              </Grid>
              <Grid item>
                <Button sx={{ width: "120px" }} variant="outlined" size="small" color="secondary" onClick={() => {
                  setShowCustomBoomerangDate(true);
                }}>
                  Custom time
                </Button>
              </Grid>
              <Tooltip arrow title={boomerang?.boomerangOn && !removeBoomerangTooltip ?
                `Ticket will be moved above the ticket line at ${fDateTime(boomerang.boomerangOn)}` : ""}>
                {showCustomBoomerangDate ? <Grid item>
                  <DateTimePicker
                    minDate={new Date()}
                    slotProps={{ textField: { size: "small" }, actionBar: { actions: ['clear', "cancel", "accept"] } }}
                    value={boomerang?.boomerangOn ? new Date(boomerang.boomerangOn) : null}
                    onAccept={(dateValue) => { updateBoomerangDate(dateValue); setRemoveBoomerangTooltip(false); }}
                    onClose={() => setRemoveBoomerangTooltip(false)}
                    onOpen={() => setRemoveBoomerangTooltip(true)}
                  />
                </Grid> : <></>}
              </Tooltip>
            </Grid>
          </Grid>}
        </Grid>
      </Card>
    </>
  );
}
