import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getVendorItemsForReplacement, setItemPPD } from '../../../../api/orders';
import { getCatalogReplacementListData, removeItemFromErrorList, updatePrice } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import {
  ADD_CATALOG,
  ADD_ITEM,
  CURRENT_ERROR_ITEM,
  REPLACE_ITEM,
} from '../../../../reusable-components/datagrid/pendingOrderColumns';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import AddUseItemQuantityField from './AddUseItemQuantityField';
import ReplaceItemQuantityField from './ReplaceItemQuantityField';

ActionButtons.propTypes = {
  row: PropTypes.object,
};

export default function ActionButtons({ row }) {
  const { id = '' } = useParams();
  const { orderById, catalogReplacementList } = useSelector((state) => state.orders);
  const { facilityId } = orderById[id] || {};
  const { price, updatedPrice, itemDirectReplacements, itemSubstitutes, vendorId, uomType } = row;
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [showCatalog, setShowCatalog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [substituteData, setSubstituteData] = useState([]);
  const [replacementData, setReplacementData] = useState([]);
  const replacementItems = replacementData?.vendorItems ?? [];
  const substituteItems = substituteData?.vendorItems ?? [];

  useEffect(() => {
    replacementData?.missingItems?.forEach((err) => {
      enqueueSnackbar(`${row.vendor} - ${err}`, { variant: 'error', autoHideDuration: 5000 });
    });
    substituteData?.missingItems?.forEach((err) => {
      enqueueSnackbar(`${row.vendor} - ${err}`, { variant: 'error', autoHideDuration: 5000 });
    });
  }, [replacementData, substituteData]);

  const handleClose = () => {
    setOpenModal(false);
    setShowCatalog(false);
  };

  const handleItemAction = async (type) => {
    if (type === 'Substitute / Replacement') {
      const response = await getVendorItemsForReplacement({
        vendorId,
        facilityId,
        uoms: itemSubstitutes?.map((item) => ({ vendorItemId: item, uomType })),
      });

      setSubstituteData(response.data);

      const response2 = await getVendorItemsForReplacement({
        vendorId,
        facilityId,
        uoms: itemDirectReplacements?.map((item) => ({ vendorItemId: item, uomType })),
      });

      setReplacementData(response2.data);
    } else if (type === 'Substitute') {
      const response = await getVendorItemsForReplacement({
        vendorId,
        facilityId,
        uoms: itemSubstitutes?.map((item) => ({ vendorItemId: item, uomType })),
      });

      setSubstituteData(response.data);
    } else if (type === 'PPD') {
      const response = await setItemPPD({
        facilityId,
        itemId: row.itemId,
        isPPD: true,
      });

      if (response.status === 200) {
        enqueueSnackbar('Item made PPD', { variant: 'success' });
        dispatch(removeItemFromErrorList(id, row.id));
      } else {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else if (type === 'Replacement') {
      const response = await getVendorItemsForReplacement({
        vendorId,
        facilityId,
        uoms: itemDirectReplacements?.map((item) => ({ vendorItemId: item, uomType })),
      });

      setReplacementData(response.data);
    }
  };

  const handleClickOpen = (type) => async () => {
    await handleItemAction(type);
    setModalType(type);
    setOpenModal(true);
  };

  const qtyTotalAction = {
    field: 'qtyTotalAction',
    headerName: 'Qty',
    width: 450,
    renderCell: (params) => <ReplaceItemQuantityField errorRow={row} row={params.row} />,
    id: 'qty',
    className: 'qty',
  };
  const qtyTotalAddAction = {
    field: 'qty',
    headerName: 'Qty',
    width: 500,
    renderCell: (params) => <AddUseItemQuantityField errorRow={row} row={params.row} />,
    id: 'qty',
    className: 'qty',
  };
  const hasSubstitutes = itemSubstitutes?.length > 0;
  const hasReplacements = itemDirectReplacements?.length > 0;

  let buttonText = 'Substitute / Replacement';
  if (hasSubstitutes && hasReplacements) {
    buttonText = 'Substitute / Replacement';
  } else if (hasSubstitutes) {
    buttonText = 'Substitute';
  } else if (hasReplacements) {
    buttonText = 'Replacement';
  }

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item>
          <AwaitButton
            variant="outlined"
            size="small"
            color="black"
            disabled={!hasSubstitutes && !hasReplacements}
            onClick={handleClickOpen(buttonText)}
            sx={{
              minWidth: '180px',
              opacity: !hasSubstitutes && !hasReplacements ? 0 : 1,
              //this hides the button if there are no substitutes or replacements so the buttons are aligned properly on each row
            }}
          >
            {buttonText}
          </AwaitButton>
        </Grid>

        <Grid item sx={{ mt: 1, mb: 1 }}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <AwaitButton
                variant="outlined"
                size="small"
                color="black"
                onClick={async () => {
                  setOpenModal(true);
                  setShowCatalog(true);
                  setModalType('All Catalog');
                  dispatch(getCatalogReplacementListData(facilityId, -1));
                }}
              >
                Replace from Catalog
              </AwaitButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          {updatedPrice !== price && (
            <AwaitButton
              variant="outlined"
              color="black"
              disabled={updatedPrice === price}
              size="small"
              onClick={async () => {
                dispatch(updatePrice(id, facilityId, [row]));
              }}
            >
              Update Price
            </AwaitButton>
          )}
        </Grid>
        <Grid item>
          {row.errors.findIndex((e) => e.includes('PPD mismatch')) > -1 && (
            <AwaitButton
              variant="outlined"
              size="small"
              color="black"
              onClick={() => handleItemAction('PPD')}
              sx={{
                minWidth: '180px',
              }}
            >
              Make item PPD
            </AwaitButton>
          )}
        </Grid>
      </Grid>
      {/* ////////////////ADD CATALOG////////////////////// */}
      <Dialog open={openModal} onClose={handleClose} maxWidth="full" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {modalType} Items
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            Current item
          </Typography>
          <div style={{ width: '100%' }}>
            <StyledDataGrid
              getRowHeight={() => 'auto'}
              rows={[row]}
              columns={CURRENT_ERROR_ITEM}
              getEstimatedRowHeight={() => 44}
              disableSelectionOnClick
            />
          </div>
          {showCatalog ? (
            <>
              <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                {modalType} items
              </Typography>
              <div style={{ width: '100%' }}>
                <Box sx={{ width: '100%', height: 'calc(100vh - 300px)' }}>
                  <CustomDataGrid
                    gridId="orders-catalog-replacement"
                    isModal
                    dontGetRowHeight
                    data={catalogReplacementList}
                    gridColumns={[...ADD_CATALOG, qtyTotalAction]}
                    pinnedColumns={{
                      left: ['picture'],
                      right: ['qtyTotalAction'],
                    }}
                    isLoading={catalogReplacementList.length === 0}
                    presetFilter={`PS${row.productId}`}
                  />
                </Box>
              </div>
            </>
          ) : (
            <>
              {substituteData?.productDetails?.length > 0 || substituteData?.missingItems?.length > 0 ? (
                <>
                  <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                    Substitute Items in Catalog
                  </Typography>
                  {/* {substituteData?.missingItems?.map((err, index) => (
                    <Typography key={index} variant="subtitle2" color="error" sx={{ mt: 0.5, textAlign: 'center' }}>
                      {row.vendor} - {err}
                    </Typography>
                  ))} */}
                  {substituteData?.productDetails?.length > 0 && (
                    <div style={{ width: '100%' }}>
                      <DataGridPro
                        getRowHeight={() => 'auto'}
                        rows={substituteData?.productDetails}
                        columns={[...REPLACE_ITEM, qtyTotalAction]}
                        disableSelectionOnClick
                      />
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {replacementData?.missingItems?.length > 0 || replacementData?.productDetails?.length > 0 ? (
                <>
                  <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                    Replacement Items in Catalog
                  </Typography>
                  {/* {replacementData?.missingItems?.map((err, index) => (
                    <Typography key={index} variant="subtitle2" color="error" sx={{ mt: 0.5, textAlign: 'center' }}>
                      {row.vendor} - {err}
                    </Typography>
                  ))} */}
                  {replacementData?.productDetails?.length > 0 && (
                    <div style={{ width: '100%' }}>
                      <DataGridPro
                        getRowHeight={() => 'auto'}
                        rows={replacementData?.productDetails}
                        columns={[...REPLACE_ITEM, qtyTotalAction]}
                        disableSelectionOnClick
                      />
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              {replacementItems.length > 0 && (
                <>
                  <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'center' }}>
                    Add Replacement Items from Vendor
                  </Typography>
                  <div style={{ width: '100%' }}>
                    <DataGridPro
                      getRowHeight={() => 'auto'}
                      rows={[...replacementItems]}
                      columns={[...ADD_ITEM, qtyTotalAddAction]}
                      disableSelectionOnClick
                      getRowId={(row) => row.vendorItemId}
                    />
                  </div>
                </>
              )}
              {substituteItems.length > 0 && (
                <>
                  <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'center' }}>
                    Add Substitute Items from Vendor
                  </Typography>
                  <div style={{ width: '100%' }}>
                    <DataGridPro
                      getRowHeight={() => 'auto'}
                      rows={[...substituteItems]}
                      columns={[...ADD_ITEM, qtyTotalAddAction]}
                      disableSelectionOnClick
                      getRowId={(row) => row.vendorItemId}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const StyledDataGrid = styled(DataGridPro)(() => ({
  '& .MuiDataGrid-main': {
    // borderRadius: '10px',
    backgroundColor: '#fff',
  },
  //   '& .MuiDataGrid-columnHeaders': {
  //     display: 'none',
  //   },
  '& .MuiDataGrid-row': {
    border: 'none',
    backgroundColor: '#fff',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'default',
    backgroundColor: '#fff',
  },
}));
