import { useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Menu,
  MenuItem,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from '@mui/material';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { checkVendorOrderSent } from '../../../../api/orders';

export default function ReRunDropdown() {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { orderById } = useSelector((state) => state.orders || {});
  const { items = [] } = orderById[id] || {};

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [vendId, setVendId] = useState(null);
  const [vendorsSent, setVendorsSent] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDialogOpen(false);
  };
  const vendors = useMemo(() => {
    if (!items) return [];
    const vendorArray = items?.map((item) => ({
      vendor: item.vendor,
      vendorId: item.vendorId,
    }));

    const uniqueVendors = Array.from(new Set(vendorArray.map((v) => v.vendorId))).map((id) =>
      vendorArray.find((v) => v.vendorId === id)
    );
    return uniqueVendors;
  }, [items]);

  const checkVendorOrder = async (vendorId) => {
    const response = await checkVendorOrderSent(id, [vendorId]);
    if (response.status === 200) {
      if (response.data.length > 0) {
        setVendorsSent(response.data);
        setVendId(vendorId);
        setDialogOpen(true);
      } else {
        navigate(`/dashboard/orders/${id}?filter=rerun&vendorId=${vendorId}`);
      }
    }
  };

  const checkAllVendorOrders = async (vendorIds) => {
    const response = await checkVendorOrderSent(id, vendorIds);
    if (response.status === 200) {
      if (response.data.length > 0) {
        setVendorsSent(response.data);
        setVendId(0);
        setDialogOpen(true);
      } else {
        navigate(`/dashboard/orders/${id}?filter=rerun&vendorId=all`);
      }
    }
  };

  return (
    <>
      <ScopeGuard scopes={['Edit-Order']} allowAdmin>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClick}
          endIcon={<Iconify icon={'mdi:chevron-down'} />}
          size="small"
        >
          Rerun order by vendor
        </Button>
      </ScopeGuard>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        //add shadows and border radius
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '5px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            border: '1px solid #E0E0E0',
          },
        }}
      >
        {vendors.length > 1 && (
          <MenuItem
            onClick={() => {
              checkAllVendorOrders(vendors.map((vendor) => vendor.vendorId));
            }}
          >
            All Vendors
          </MenuItem>
        )}
        {vendors?.map((vendor) => (
          <MenuItem
            onClick={() => {
              checkVendorOrder(vendor.vendorId);
            }}
            key={vendor.vendorId}
          >
            {vendor.vendor}
          </MenuItem>
        ))}
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {vendorsSent.map((vendor) => vendor).join(', ')} order{vendorsSent.length > 1 && 's'} has been marked as
            sent are you sure you want to rerun?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150 }}
            onClick={() => {
              navigate(`/dashboard/orders/${id}?filter=rerun&vendorId=${vendId}`);
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
