import { Helmet } from 'react-helmet-async';
import { useMemo } from 'react';
import { Grid, Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { TicketSearch } from '../../../components/dashboard/advanced-search';

// ----------------------------------------------------------------------

export default function AdvancedSearch() {
  const [searchParams] = useSearchParams();
  const base = useMemo(() => searchParams.get('base'), [searchParams]);

  return (
    <>
      <Helmet>
        <title>Advanced {base} Search | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {base === 'tickets' ? <TicketSearch /> : null}
        </Grid>
      </Grid>
    </>
  );
}
