import { useMemo, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Typography, Box, Card, Stepper, Step, StepLabel, Paper } from '@mui/material';
import { useSelector } from '../../../../redux/store';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import Image from '../../../../reusable-components/image';
import CartItemView from './rentalCart/CartItemView';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { getFacilityInfo } from '../../../../api/rentals';

export default function RentalMultipleOrderSummary() {
  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get('orderNumber');

  const {
    data: { cart, residentsList, rentalVendors },
  } = useSelector((state) => state.rentals);

  const cartByResidentArrays = useMemo(() => {
    const cartByResident = cart.reduce((acc, item) => {
      if (!acc[item.residentId]) {
        acc[item.residentId] = [];
      }
      if (item.isSelected) {
        acc[item.residentId].push(item);
      }
      return acc;
    }, {});

    return Object.values(cartByResident);
  }, [cart]);

  const getNameById = (residentId) => cart.find((r) => r.residentId === residentId);
  const steps = ['Rental Created', 'Rental Placed', 'Rental Approved', 'Rental Sent', 'Rental Returned'];
  const [facilityInfo, setFacilityInfo] = useState({});
  const [vendorNames, setVendorNames] = useState([]);

  const displayVendorNames = () => {
    const vendors = [];
    cart.forEach((cartItem) => {
      const newVendor = rentalVendors.find((vendor) => vendor.id === cartItem.vendorId);
      if (newVendor && !vendors.includes(newVendor.name)) {
        vendors.push(newVendor.name);
      }
      setVendorNames(vendors);
    });
  };
  useEffect(() => {
    displayVendorNames();
  }, [cart]);

  const residentNames = () => {
    const cartResidents = cartByResidentArrays.map((resident, index) => {
      const residentData = getNameById(resident[0].residentId);
      return residentData ? (
        <span key={index}>
          {residentData.residentFirstName} {residentData.residentLastName}
          <br />
          Room {residentData.room}
        </span>
      ) : null;
    });
    return cartResidents;
  };

  const fetchFacilityData = async () => {
    try {
      const response = await getFacilityInfo(residentsList[0].facilityId);
      setFacilityInfo(response.data);
    } catch (error) {
      console.error('Error fetching facility data:', error);
    }
  };

  useEffect(() => {
    fetchFacilityData();
  }, []);

  const displayResidents = () => {
    const residents = residentNames();
    return residents.map((resident, ind) => (
      <Typography key={ind} sx={{ mr: 2, mb: 2, fontSize: '12px' }}>
        {resident}
      </Typography>
    ));
  };

  return (
    <>
      <Scrollbar sx={{ height: 'calc(100vh - 200px)' }}>
        <Typography variant="subtitle1" sx={{ mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}>
          Order Confirmation
        </Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={3}>
            <Card sx={{ padding: 3, alignItems: 'center' }}>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}>
                  Rental Request {orderNumber}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'center' }}>
                <Box>
                  <Stepper activeStep={1} alternativeLabel>
                    {steps.map((label) => (
                      <Step
                        sx={{
                          '& .MuiStepLabel-root .Mui-active ': {
                            color: '#4ca5e7',
                          },
                          '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                            color: '#6d6e71', // Just text label (ACTIVE)
                            fontSize: '10px',
                            marginTop: 1,
                          },
                          '& .MuiStepLabel-root .Mui-completed': {
                            color: '#4ca5e7',
                          },
                          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                            color: '#6d6e71', // Just text label (ACTIVE)
                            fontSize: '10px',
                            marginTop: 1,
                          },

                          '& .MuiStepLabel-root .Mui-disabled ': {
                            color: '#4ca5e7',
                          },
                          '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel': {
                            color: '#6d6e71', // Just text label (ACTIVE)
                            fontSize: '10px',
                            marginTop: 1,
                          },
                        }}
                        key={label}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 1, color: '#8dc63f', fontWeight: 'bold', fontSize: '14px' }}
                >
                  {vendorNames.length > 1 ? 'Rental Vendors' : 'Rental Vendor'}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography sx={{ mr: 2, fontSize: '12px' }}>
                  {vendorNames.map((vendorName, ind) => (
                    <Typography key={ind} sx={{ mr: 2, fontSize: '12px' }}>
                      {vendorName}
                    </Typography>
                  ))}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '14px' }}
                >
                  Facility Information
                </Typography>
                <Grid item sx={{ textAlign: 'left' }}>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>{facilityInfo?.facilityName}</Typography>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>{facilityInfo?.streetAddress}</Typography>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>
                    {facilityInfo?.city}, {facilityInfo?.state} {facilityInfo?.zip}
                  </Typography>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>{facilityInfo?.phoneNumber}</Typography>
                </Grid>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '14px' }}
                >
                  Resident Information
                </Typography>
              </Grid>

              <Grid item sx={{ textAlign: 'left' }}>
                {displayResidents()}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Grid container direction="column">
              {cartByResidentArrays.map((resident, index) => {
                const cartResident = `${resident[0].residentFirstName} ${resident[0].residentLastName}`;
                return (
                  <Grid item key={index}>
                    {resident.some((item) => item.isSelected === true) && (
                      <Card sx={{ flexDirection: 'column', mb: 4, padding: 3 }}>
                        <Grid container direction="row" spacing={1} alignItems="center">
                          <Grid item>
                            <CustomAvatar
                              name={cartResident}
                              size="large"
                              showTooltip={false}
                              sx={{
                                height: 40,
                                width: 40,
                                fontSize: 40 / 2,
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1" sx={{ color: '#707070', fontWeight: 'bold' }}>
                              {cartResident}
                            </Typography>
                          </Grid>
                          <Grid item sx={{ marginLeft: 'auto' }} key={index}>
                            <Typography variant="subtitle1" sx={{ color: '#8cc53f' }}>
                              Total: ${resident.reduce((acc, item) => acc + item.price * item.quantity, 0)}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container direction="row" alignItems="center">
                          {resident.map((cartItem, ind) => (
                            <>
                              {resident.length === 1 && cartItem.isSelected ? (
                                <CartItemView
                                  cartItem={cartItem}
                                  key={ind}
                                  showDivider={resident.length - 1 !== ind}
                                  showCartEditOptions={false}
                                  showQuantity
                                />
                              ) : (
                                cartItem.isSelected && (
                                  <Grid item>
                                    <Grid
                                      item
                                      key={cartItem}
                                      xs={3}
                                      style={{ maxWidth: '100px', maxHeight: '150px', alignItems: 'center' }}
                                    >
                                      <Paper
                                        key={cartItem.id}
                                        elevation={1}
                                        sx={{
                                          padding: '4px',
                                          width: '100px',
                                          height: '150px',
                                          overflow: 'hidden',
                                          alignItems: 'center',
                                          display: 'grid',
                                        }}
                                      >
                                        <Image
                                          alt="attachment"
                                          src={cartItem.image}
                                          sx={{
                                            borderRadius: 1.5,
                                            width: 50,
                                            height: 50,
                                            display: 'flex',
                                            objectFit: 'contain',
                                          }}
                                        />{' '}
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: '100px',
                                          }}
                                        >
                                          <Typography
                                            sx={{ fontSize: '12px', color: '#8cc53f', fontWeight: 'bold', mt: 1 }}
                                          >
                                            ${cartItem.price}
                                          </Typography>
                                          <Typography sx={{ fontSize: '10px' }}>{cartItem.description}</Typography>
                                        </Box>
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </>
                          ))}
                        </Grid>
                      </Card>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Scrollbar>
    </>
  );
}
