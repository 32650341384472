import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Stack, Button, Tooltip, TextField, IconButton, DialogActions, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Iconify from '../iconify';
import { useSelector } from '../../redux/store';
import FormProvider, { RHFSwitch, RHFAutocomplete } from '../hook-form';

// ----------------------------------------------------------------------
const startOfToday = new Date();
startOfToday.setHours(0, 0, 0, 0); // startOfToday.setHours(0, 0, 0, 0);
const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 999); // endOfToday.setHours(23, 59, 59, 999);

const getInitialValues = (event, range) => {
  const initialEvent = {
    typeId: { id: '', label: '' },
    weekly: true,
    start: startOfToday,
    end: endOfToday,
  };

  if (event) {
    initialEvent.start = new Date(event.startDate);
    initialEvent.end = new Date(event.endDate);
    initialEvent.weekly = event.weekly;
    initialEvent.typeId = { id: event.typeId, label: event.title };
  }
  if (range) {
    const endOfDay = new Date(range.start);
    endOfDay.setHours(23, 59, 59, 999);
    initialEvent.start = new Date(range.start);
    initialEvent.end = endOfDay;
  }
  return initialEvent;
};

// ----------------------------------------------------------------------

CalendarForm.propTypes = {
  event: PropTypes.object,
  range: PropTypes.object,
  onCancel: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onCreateUpdateEvent: PropTypes.func,
  typesArray: PropTypes.array,
  view: PropTypes.string,
};

export default function CalendarForm({ event, range, onDeleteEvent, onCancel, typesArray, onCreateUpdateEvent, view }) {
  const {
    data: { vendors, facilities },
  } = useSelector((state) => state.vendors);

  const hasEventData = !!event;

  const methods = useForm({
    defaultValues: getInitialValues(event, range),
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const [dateTimeOptions, setDateTimeOptions] = useState(['year', 'month', 'day']);

  useEffect(() => {
    if (values.typeId.singleDate) {
      setDateTimeOptions(['year', 'month', 'day']);
    } else {
      setDateTimeOptions(['year', 'month', 'day', 'hours', 'minutes']);
    }
  }, [values.typeId]);

  const onSubmit = async (data) => {
    try {
      const newEvent = {
        typeId: data.typeId.id,
        weekly: data.weekly,
        start: data.start,
        end: values.typeId.singleDate ? data.start : data.end,
        facilityId: data.facilityId,
        vendorId: data.vendorId,
      };
      onCreateUpdateEvent(newEvent);
      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ px: 3 }}>
        {view === 'listYear' && (
          <>
            <Box>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                Vendor
              </Typography>
              <RHFAutocomplete
                name="vendorId"
                options={vendors.map((vendor) => ({ id: vendor.id, label: vendor.name }))}
                getOptionLabel={(option) => option?.label}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
                //remove clear option  make it required
                disableClearable
              />
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                Facility
              </Typography>
              <RHFAutocomplete
                name="facilityId"
                options={facilities.map((facility) => ({ id: facility.value, label: facility.label }))}
                getOptionLabel={(option) => option?.label}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
                //remove clear option  make it required
                disableClearable
              />
            </Box>
          </>
        )}
        <Box>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            Type
          </Typography>
          <RHFAutocomplete
            name="typeId"
            label="Type"
            options={
              typesArray.map((type) => ({ id: type.value, label: type.label, singleDate: type.singleDate })) || []
            }
            getOptionLabel={(option) => option?.label}
            renderInput={(params) => <TextField {...params} label="Search or select" />}
            sx={{
              mt: 0.5,
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': { display: 'none' },
            }}
            //remove clear option  make it required
            disableClearable
          />
        </Box>
        <RHFSwitch name="weekly" label="Weekly" />
        <Controller
          name="start"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              defaultValue={values.start}
              views={dateTimeOptions}
              onChange={(newValue) => field.onChange(newValue)}
            />
          )}
        />
        {!values.typeId.singleDate && (
          <Controller
            name="end"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                defaultValue={values.end}
                views={dateTimeOptions}
                onChange={(newValue) => field.onChange(newValue)}
              />
            )}
          />
        )}
      </Stack>

      <DialogActions>
        {hasEventData && (
          <Tooltip title="Delete Event">
            <IconButton onClick={onDeleteEvent} color="error">
              <Iconify icon="eva:trash-2-outline" />
            </IconButton>
          </Tooltip>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={values.typeId.id === 0 || values.typeId.id === ''}
        >
          {hasEventData ? 'Update' : 'Add'}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
