// remember to import this file in index.js

export default function Typography(theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          zIndex: 1,
          position: 'relative',
        },
        paragraph: {
          marginBottom: `${theme.spacing(0)} !important`,
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
