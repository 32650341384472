import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { useSelector } from '../../../redux/store';
import { fNumber } from '../../../utils/formatNumber';

TicketTabs.propTypes = {
  onTabChange: PropTypes.func,
  filter: PropTypes.string,
  children: PropTypes.element
};
export default function TicketTabs({ onTabChange, filter, children }) {
  const navigate = useNavigate();
  const { unreadCount } = useSelector((state) => state.tickets);

  const handleTabChange = (event, newValue) => {
    if (onTabChange) onTabChange(newValue);
    switch (newValue) {
      case 0:
        navigate(`/dashboard/tickets?filter=active`);
        break;
      case 1:
        navigate(`/dashboard/tickets?filter=completed&start=${new Date(
            new Date().setDate(new Date().getDate() - 29)
          ).toISOString()}&end=${new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()}`);
        break;
      default:
        break;
    }
  };

  const isActive = filter === 'active';
  const isSettings = filter === 'settings';

  return <Tabs value={isActive ? 0 : isSettings ? 2 : 1} onChange={handleTabChange}>
    <Tab label={`Open Tickets${unreadCount > 0 ? ` (${fNumber(unreadCount)})` : ''}`} />
    <Tab label="Completed Tickets" />
    {children}
  </Tabs>;
}
