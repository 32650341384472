import { Helmet } from 'react-helmet-async';
import { useSelector } from '../../../redux/store';
import RentalOrdersView from '../../../components/dashboard/rentals/RentalOrdersView';
// components

// ----------------------------------------------------------------------

export default function PendingRentals() {
  const {
    data: { cart },
  } = useSelector((state) => state.rentals);
  return (
    <>
      <Helmet>
        <title>Pending Rentals | Platform Solutions</title>
      </Helmet>

      <RentalOrdersView orderStatus="Pending" />
    </>
  );
}
