import { Box } from '@mui/material';
import { fMonthDayYear } from '../../utils/formatTime';
import Iconify from '../iconify';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Date Added',
  type: 'customDate',
  flex: 0.5,
  renderCell: (params) => renderIcon(fMonthDayYear(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => new Date(params.value),
};

const title = {
  field: 'title',
  headerName: 'Title',
  flex: 1.5,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
};

const NOTICES = [title, dateAdded];

export { NOTICES };
