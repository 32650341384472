import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Stack, Typography, Box, TextField, Button } from '@mui/material';
import { useDispatch } from '../../../../redux/store';
import { updateUserInRedux } from '../../../../redux/slices/user';
import { updateUser } from '../../../../api/account';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { NEW_PASSWORD_SCHEMA } from '../../../../reusable-components/form/authSchema';
import { AwaitButton } from '../../../../reusable-components/await-button';

GeneralInfoTab.propTypes = {
  userData: PropTypes.object,
};

export default function GeneralInfoTab({ userData }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { positions, user } = userData;
  const [openPassword, setOpenPassword] = useState(false);
  const [error, setError] = useState('');

  const [value, setValue] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    position: user.position,
    email: user.email,
  });

  useEffect(() => {
    setValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      position: user.position,
    });
  }, [user]);
  const validatePassword = async () => {
    try {
      await NEW_PASSWORD_SCHEMA.validateAt('password', { password: value.password });
      setError('');
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    }
  };
  const onSubmit = async () => {
    if (openPassword) {
      const isValid = await validatePassword();
      if (!isValid) {
        console.error('Validation failed:', error);
        return;
      }
    }

    const response = await updateUser(value);
    if (response) {
      enqueueSnackbar('User updated', { variant: 'success' });
      setOpenPassword(false);
      dispatch(updateUserInRedux(value));
    } else enqueueSnackbar('User update failed', { variant: 'error' });
  };

  return (
    <>
      <Box>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                First name
              </Typography>
              <TextField
                name="firstName"
                placeholder="First name"
                sx={{
                  width: '25vw',
                }}
                value={value.firstName}
                onChange={(e) => setValue({ ...value, firstName: e.target.value })}
              />
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Last name
              </Typography>
              <TextField
                name="lastName"
                placeholder="Last name"
                sx={{
                  width: '25vw',
                }}
                value={value.lastName}
                onChange={(e) => setValue({ ...value, lastName: e.target.value })}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Email
              </Typography>
              <TextField
                name="email"
                fullWidth
                placeholder="email"
                sx={{ width: '25vw' }}
                value={value.email}
                onChange={(e) => setValue({ ...value, email: e.target.value })}
                disabled
              />
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Position
              </Typography>
              <Autocomplete
                fullWidth
                value={value.position}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                options={positions.map((position, index) => ({ id: index + 1, label: position }))}
                onChange={(e, newValue) => setValue({ ...value, position: newValue?.label })}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  width: '25vw',
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
            <Box>
              {!openPassword ? (
                <Button variant="outlined" onClick={() => setOpenPassword((openPass) => !openPass)}>
                  Change password
                </Button>
              ) : (
                <Button
                  sx={{ marginBottom: '10px' }}
                  color="secondary"
                  size="medium"
                  type="button"
                  variant="outlined"
                  onClick={() => setOpenPassword(false)}
                >
                  Cancel
                </Button>
              )}
              {openPassword && (
                <>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Password
                  </Typography>
                  <TextField
                    name="password"
                    fullWidth
                    type="password"
                    placeholder="Password"
                    sx={{ width: '25vw' }}
                    value={value.password}
                    onChange={(e) => setValue({ ...value, password: e.target.value })}
                    error={!!error}
                    helperText={error}
                  />
                </>
              )}
            </Box>
            <Box>
              <AwaitButton
                color="primary"
                size="medium"
                type="button" // Changed to "button" to prevent form submission if wrapped in a form
                variant="contained"
                sx={{ width: 200 }}
                onClick={onSubmit}
              >
                Save
              </AwaitButton>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
