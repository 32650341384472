import { Box } from '@mui/material';

const facilityName = {
  field: 'facilityName',
  headerName: 'Name',
  minWidth: 100,
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const group = {
  field: 'company',
  headerName: 'Group',
  minWidth: 100,
  flex: 1,
  renderCell: (params) =>  ( 
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        {params.value}
      </Box>
    ),
  type: 'customText',
};
const facility = {
  field: 'facility',
  headerName: 'Name',
  minWidth: 100,
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const streetAddress = {
  field: 'streetAddress',
  headerName: 'Address',
  minWidth:90,
  flex: 1,
  type: 'customText',
};
const zip = {
  field: 'zip',
  headerName: 'Zip',
  minWidth: 65,
  flex: 1,
  type: 'customText',
};
const city = {
  field: 'city',
  headerName: 'City',
  minWidth:80,
  flex: 1,
  type: 'customText',
};
const state = {
  field: 'state',
  headerName: 'State',
  flex: 1,
  type: 'customText',
};
const phoneNumber = {
  field: 'phoneNumber',
  headerName: 'Phone',
  minWidth:60,
  flex: 1,
  type: 'customText',
};
const beds = {
  field: 'beds',
  headerName: 'Beds',
  flex: 1,
  type: 'customText',
};
const account = {
  field: 'account',
  headerName: 'Account',
  flex: 1,
  type: 'customText',
};
const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  minWidth: 100,
  flex: 1,
  type: 'customSelect',
};

const FACILITIES = [facilityName, group, streetAddress, zip, city, state, phoneNumber, beds];
const BOOKING = [facility, vendor, account];

export { FACILITIES, BOOKING };
