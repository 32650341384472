import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { postVendorRuleForm } from '../../../../api/vendors';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

RulesModal.propTypes = {
  fetchData: PropTypes.func,
  edit: PropTypes.bool,
  row: PropTypes.object,
  ruleTypes: PropTypes.array,
  ruleValueDefinitions: PropTypes.array,
  rules: PropTypes.array,
  ruleValues: PropTypes.array,
};

export default function RulesModal({ fetchData, edit, row, ruleTypes, ruleValueDefinitions, rules, ruleValues }) {
  const {
    data: { vendors },
  } = useSelector((state) => state.vendors);
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    vendor: edit ? { id: row.vendorId, label: row.vendor } : '',
    type: edit ? { id: row.ruleTypeId, label: row.ruleType } : '',
    rule: edit ? { id: row.ruleId, label: row.rule } : '',
    values: edit ? row.values : [],
  });

  const isDuplicateRule = useMemo(
    () =>
      !edit &&
      !!ruleValues.find(
        (ruleVal) =>
          ruleVal.vendorId === value.vendor?.id &&
          ruleVal.ruleTypeId === value.type?.id &&
          ruleVal.ruleId === value.rule?.id
      ),
    [ruleValues, value]
  );

  const handleSave = async () => {
    const response = await postVendorRuleForm(value, edit ? row?.id : null);
    if (response.data) {
      enqueueSnackbar('Rule added successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to add rule', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      vendor: edit ? { id: row.vendorId, label: row.vendor } : '',
      type: edit ? { id: row.ruleTypeId, label: row.ruleType } : '',
      rule: edit ? { id: row.ruleId, label: row.rule } : '',
      values: edit ? row.values : [],
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      vendor: '',
      type: '',
      rule: '',
      values: [],
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
          size="small"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add New Rule
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {edit ? 'Edit' : 'Add'} Rule
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              {isDuplicateRule && (
                <Alert severity="error" sx={{ width: '100%' }}>
                  This rule already exists
                </Alert>
              )}
              <Grid item xs={12}>
                <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor</DialogContentText>
                <Autocomplete
                  size="small"
                  fullWidth
                  value={value.vendor}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      vendor: newValue,
                    });
                  }}
                  options={vendors?.map((type) => ({ id: type.id, label: type.name }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 0.5,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={12}>
                <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Type</DialogContentText>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={value?.type}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      type: newValue,
                      rule: '',
                      values: [],
                    });
                  }}
                  options={ruleTypes?.map((type) => ({ id: type.value, label: type.label }))}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 0.5,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Grid>
            </Grid>

            <Box>
              {value?.type?.id > 0 ? (
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                    <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Rule</DialogContentText>
                    <Autocomplete
                      size="small"
                      fullWidth
                      value={value.rule}
                      onChange={(event, newValue) => {
                        setValue({
                          ...value,
                          rule: newValue,
                          values: [],
                        });
                      }}
                      options={rules
                        .filter((rule) => rule.typeId === value?.type.id)
                        .map((type) => ({ id: type.value, label: type.label }))}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => <TextField {...params} label="Search or select" />}
                      sx={{
                        mt: 0.5,
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& .MuiFormLabel-root ': { display: 'none' },
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {ruleValueDefinitions
                .filter((def) => def.ruleId === value?.rule?.id)
                .map((type) => (
                  <Grid key={type.id} container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Grid item xs={12}>
                      <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>
                        {type.label}
                        {type.required ? '*' : ''}
                      </DialogContentText>
                      <TextField
                        size="small"
                        fullWidth
                        value={value.values?.find((v) => v.definitionId === type.id)?.value || ''}
                        onChange={(e) => {
                          setValue((prevValue) => {
                            // Ensure prevValue.values is treated as an array
                            const valuesArray = prevValue.values || [];

                            const existingValueIndex = valuesArray?.findIndex((v) => v.definitionId === type.id);

                            let updatedValues;
                            if (existingValueIndex >= 0) {
                              // If the object exists, update it
                              updatedValues = [...valuesArray];
                              updatedValues[existingValueIndex] = {
                                ...updatedValues[existingValueIndex],
                                value: e.target.value,
                              };
                            } else {
                              // If the object doesn't exist, add a new one
                              updatedValues = [
                                ...valuesArray,
                                {
                                  definitionId: type.id,
                                  value: e.target.value,
                                },
                              ];
                            }

                            return {
                              ...prevValue,
                              values: updatedValues,
                            };
                          });
                        }}
                        label=""
                        sx={{
                          mt: 0.5,
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                          '& .MuiFormLabel-root ': { display: 'none' },
                        }}
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
            </Box>
          </>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150 }}
            disabled={isDuplicateRule}
            onClick={handleSave}
          >
            {edit ? 'Save' : 'Add Rule'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
