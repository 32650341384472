import PropTypes from 'prop-types';
import { Box, Tooltip } from "@mui/material";

GroupProgressTab.propTypes = {
  miscellaneousFieldsGroup: PropTypes.bool,
  groupProgressTab: PropTypes.bool,
  firstGroup: PropTypes.bool,
  onClick: PropTypes.func,
};

export default function GroupProgressTab({ firstGroup, groupProgressTab, miscellaneousFieldsGroup, onClick })
{
  return <Tooltip enterDelay={300} title={firstGroup ? "" : <Box>{groupProgressTab ? "Remove" : "Add"}</Box>} arrow placement="top">
    <Box onClick={onClick} sx={{
      display: "flex",
      pt: firstGroup ? 1 : .5,
      pb: firstGroup ? 1 : miscellaneousFieldsGroup ? .5 : 1.5,
      alignItems: "center",
      opacity: groupProgressTab ? 1 : .1,
      ":hover": {
        opacity: groupProgressTab ? ".8" : ".5"
      }
    }}>
      <Box color="primary" sx={{ width: "8px", height: "8px", border: "2px solid #8dc63f", borderRadius: "50%" }} />
      <Box sx={{ backgroundColor: "#1D4ED8", border: "5px solid #8dc63f", borderRadius: "5px", pointerEvents: "none", height: "5px", width: "calc(100% - 12px)" }} />
      <Box color="primary" sx={{ width: "8px", height: "8px", border: "2px solid #8dc63f", borderRadius: "50%" }} />
    </Box>
  </Tooltip>;
}
