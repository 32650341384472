import { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Grid,
  FormControlLabel,
  Typography,
  Box,
  Checkbox,
} from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { advancedSearchTickets } from '../../../api/tickets';
import { fMonthDayTimeYear } from '../../../utils/formatTime';
import Scrollbar from '../../../reusable-components/scrollbar';
import { stringToColor } from '../../../utils/cssStyles';
import Markdown from '../../../reusable-components/markdown';
import { CustomAvatar } from '../../../reusable-components/custom-avatar';
import { SpinningLogo } from '../../../reusable-components/logo';
import TicketsResults from './TicketsResults';
import { AwaitButton } from '../../../reusable-components/await-button';

export default function TicketSearch() {
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const searchType = useMemo(() => searchParams.get('type'), [searchParams]);
  const base = useMemo(() => searchParams.get('base'), [searchParams]);
  const [searchValue, setSearchValue] = useState(search);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const handleSearch = async (allResults) => {
    setLoading(true);

    if (!search || !searchType) {
      return;
    }
    if (searchType === 'all') {
      const response = await advancedSearchTickets({
        messageSearch: search,
        userSearch: search,
        baseTicketSearch: search,
        allResults,
      });

      setSearchData(response.data);
      setLoading(false);
    } else {
      const response = await advancedSearchTickets({
        [searchType]: search,
        allResults,
      });

      setSearchData(response.data);
      setLoading(false);
    }
  };

  const handleClearText = () => {
    setSearchValue('');
  };

  const handleAdvancedSearch = ({ searchType, searchValue, base }) => {
    navigate(`/dashboard/advanced-search?type=${searchType}&search=${searchValue}&base=${base}`);
  };

  useEffect(() => {
    if (search && searchType) {
      handleSearch(false);
    }
  }, [search, searchType]);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1, mr: 3 }}>
        <Grid item xs={1}>
          <Button
            variant="text"
            onClick={() => {
              navigate(`/dashboard/tickets?filter=active`);
            }}
            startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
            sx={{
              color: '#454F5B',
              fontWeight: 'bold',
            }}
          >
            Back to tickets
          </Button>
        </Grid>
        <Grid item xs={11}>
          <TextField
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' && searchValue !== '') {
                handleAdvancedSearch({ searchType, searchValue, base });
              }
            }}
            fullWidth
            size="small"
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <Tooltip title="Advanced search" arrow>
                  <IconButton
                    color="secondary"
                    sx={{ display: searchValue === '' ? 'none' : 'inline-flex' }}
                    onClick={
                      searchValue !== ''
                        ? () => {
                            handleAdvancedSearch({ searchType, searchValue, base });
                          }
                        : null
                    }
                  >
                    <Iconify
                      icon={'fa6-brands:searchengin'}
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <IconButton sx={{ display: searchValue === '' ? 'none' : 'inline-flex' }} onClick={handleClearText}>
                    <Iconify
                      icon={'ri:close-fill'}
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mr: 3 }}>
        <Grid item xs={1}>
          <AwaitButton
            variant="text"
            size="small"
            onClick={() => {
              handleSearch(true);
            }}
            sx={{ ml: 3, textDecoration: 'underline', color: 'text.secondary' }}
          >
            Show all results
          </AwaitButton>
        </Grid>

        <Grid item xs={11}>
          <AwaitButton
            variant={searchType === 'messageSearch' || searchType === 'all' ? 'contained' : 'outlined'}
            color="secondary"
            size="small"
            onClick={() => {
              handleAdvancedSearch({ searchType: 'messageSearch', searchValue, base });
            }}
            sx={{ mr: 1 }}
          >
            Messages
          </AwaitButton>
          <AwaitButton
            variant={searchType === 'userSearch' || searchType === 'all' ? 'contained' : 'outlined'}
            color="secondary"
            size="small"
            onClick={() => {
              handleAdvancedSearch({ searchType: 'userSearch', searchValue, base });
            }}
            sx={{ mr: 1 }}
          >
            Users
          </AwaitButton>
          <AwaitButton
            variant={searchType === 'baseTicketSearch' || searchType === 'all' ? 'contained' : 'outlined'}
            color="secondary"
            size="small"
            onClick={() => {
              handleAdvancedSearch({ searchType: 'baseTicketSearch', searchValue, base });
            }}
            sx={{ mr: 1 }}
          >
            Base Tickets
          </AwaitButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={searchType === 'all'}
                onChange={(event) => {
                  handleAdvancedSearch({
                    searchType: event.target.checked ? 'all' : 'messageSearch',
                    searchValue,
                    base,
                  });
                }}
              />
            }
            label="Search all fields"
          />
        </Grid>
      </Grid>
      {loading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
          <Box
            sx={{
              zIndex: 999999999999999,
              height: '100%',
            }}
          >
            <SpinningLogo />
          </Box>
        </Grid>
      ) : (
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          {searchData?.length > 0 ? (
            <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
              {searchData?.map((searchResult, ind) => (
                <TicketsResults searchResult={searchResult} search={search} key={ind} />
              ))}
            </Scrollbar>
          ) : (
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
              <Grid item>
                <Typography variant="subtitle1" color="text.secondary">
                  No search results found
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}
