import { updateCatalogItemInRedux } from '../redux/slices/orderSheet';
import { useDispatch } from '../redux/store';

export const useUpdateCatalogItemRedux = () => {
  const dispatch = useDispatch();

  return (id, field, value) => {
    dispatch(updateCatalogItemInRedux(id, field, value));
  };
};
