import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { updateUserMain } from '../../../../../api/user';
import { useSelector } from '../../../../../redux/store';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../../reusable-components/snackbar';

GeneralInfoTab.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  user: PropTypes.object,
};

export default function GeneralInfoTab({ edit, setEdit, user }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: { facilities, positions, users },
  } = useSelector((state) => state.users);

  const [value, setValue] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    facilityId: user.facilityId,
    facility: user.facility,
    position: user.position,
    roleShort: user.roleShort,
    id: user.id,
  });

  useEffect(() => {
    setValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      facilityId: user.facilityId,
      facility: user.facility,
      position: user.position,
      roleShort: user.roleShort,
      id: user.id,
    });
  }, [user]);

  const onSubmit = async () => {
    if (users?.some(x => x.id !== user.id && (x.firstName + x.lastName) === (user.firstName + user.lastName)))
      enqueueSnackbar('An existing user has this name', { variant: 'info' });
    const response = await updateUserMain(value);
    if (response) {
      window.location.href = `/dashboard/admin/users/${user.id}`;
    }
  };

  const handleCancel = () => {
    setValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      facilityId: user.facilityId,
      facility: user.facility,
      position: user.position,
      roleShort: user.roleShort,
      id: user.id,
    });
    setEdit(false);
  };

  return (
    <Grid container direction="row" alignItems="center">
      <Grid container sx={{ mt: 1 }} spacing={2}>
        <Grid item>
          <Typography variant="subtitle1">First name</Typography>
          <TextField
            name="firstName"
            placeholder="First name"
            size="small"
            sx={{ width: '25vw', }}
            value={value.firstName}
            onChange={(e) => setValue({ ...value, firstName: e.target.value })}
            InputProps={{
              readOnly: !edit,
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">Last name</Typography>
          <TextField
            name="lastName"
            placeholder="Last name"
            size="small"
            sx={{ width: '25vw', }}
            value={value.lastName}
            onChange={(e) => setValue({ ...value, lastName: e.target.value })}
            InputProps={{
              readOnly: !edit,
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1 }} spacing={2}>
        <Grid item>
          <Typography variant="subtitle1">Email</Typography>
          <TextField
            name="email"
            fullWidth
            size="small"
            placeholder="email"
            sx={{ width: '25vw' }}
            value={value.email}
            onChange={(e) => setValue({ ...value, email: e.target.value })}
            InputProps={{
              readOnly: !edit,
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">Position</Typography>
          <Autocomplete
            fullWidth
            size="small"
            value={value.position}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            options={positions.map((position, index) => ({ id: index + 1, label: position }))}
            onChange={(e, newValue) => setValue({ ...value, position: newValue?.label })}
            renderInput={(params) => <TextField {...params} label="Search or select" />}
            readOnly={!edit}
            sx={{
              width: '25vw',
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': { display: 'none' },
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1 }} spacing={2}>
        <Grid item>
          <Typography variant="subtitle1">Facility</Typography>
          <Autocomplete
            fullWidth
            size="small"
            value={value.facility}
            onChange={(e, newValue) => setValue({ ...value, facilityId: newValue?.id, facility: newValue?.label })}
            options={facilities.map((facility) => ({ id: facility.value, label: facility.label }))}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => <TextField {...params} label="Search or select" />}
            readOnly={!edit}
            sx={{
              width: '25vw',
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': { display: 'none' },
            }}
          />
        </Grid>
      </Grid>
      {edit ? (
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item>
            <Button onClick={handleCancel} variant="outlined" color="error" size="medium" sx={{ width: 200, mr: 3 }}>
              Cancel
            </Button>
            <AwaitButton
              color="primary"
              size="medium"
              type="submit"
              variant="contained"
              sx={{ width: 200 }}
              onClick={onSubmit}>
              Save
            </AwaitButton>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}
