import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Image from '../image';
import { fCurrency, fifthDecimalCurrency } from '../../utils/formatNumber';

export default function RenderImage({ image }) {
  RenderImage.propTypes = {
    image: PropTypes.string,
  };

  if (!image) return null;
  return (
    <>
      <Image
        alt="attachment"
        src={image}
        sx={{
          my: 1,
          height: 64,
          width: 'auto',
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
      />
    </>
  );
}
const name = {
  field: 'name',
  headerName: 'Name',
  width: 200,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.row.name,
  type: 'customText',
};
const logo = {
  field: 'logo',
  headerName: 'Logo',
  width: 200,
  renderCell: (params) => <RenderImage image={params.row.logo} />,
  sortable: false,
  filterable: false,
};

const picture = {
  field: 'picture',
  headerName: 'Image',
  width: 72,
  renderCell: (params) => <RenderImage image={params.row.picUrl} />,
  sortable: false,
  filterable: false,
};

const description = {
  field: 'description',
  headerName: 'Description',
  width: 200,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.row.description,
  type: 'customText',
};
const vendorItemId = {
  field: 'vendorItemId',
  headerName: 'Item ID',
  width: 150,
  type: 'customText',
};

const category = {
  field: 'category',
  headerName: 'Category',
  width: 150,
  type: 'customText',
};

const manufacturer = {
  field: 'manufacturer',
  headerName: 'Manufacturer',
  width: 150,
  type: 'customText',
};

const manufacturerNumber = {
  field: 'manufacturerNumber',
  headerName: 'Manufacturer Number',
  width: 150,
  type: 'customText',
};

const price = {
  field: 'listPrice',
  headerName: 'Price',
  width: 150,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.row.listPrice,
};

const uom = {
  field: 'uom',
  headerName: 'UOM',
  renderCell: (params) => `${params.row.uomAmount}/${params.row.uomType}`,
  valueGetter: (params) => `${params.row.uomAmount}/${params.row.uomType}`,
  flex: 0.4,
  minWidth: 100,
  type: 'customText',
};

const VENDOR_CATALOG = [logo, name];
const VENDOR_CATALOG_ITEMS = [name];
const FULL_CATALOG = [picture, vendorItemId, description, category, manufacturer, manufacturerNumber, price, uom];

export { VENDOR_CATALOG, VENDOR_CATALOG_ITEMS, FULL_CATALOG };
