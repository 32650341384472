import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

SelectAll.propTypes = {
  options: PropTypes.array,
  title: PropTypes.string,
  setValues: PropTypes.func,
  id: PropTypes.string,
};

export default function SelectAll({ options = [], title, setValues, id }) {
  const [value, setValue] = useState([]);

  const updateValuesById = (newValue) => {
    setValue(newValue);
    setValues((prevValues) => {
      const newValues = { ...prevValues, [id]: newValue };
      return newValues;
    });
  };

  return (
    <Autocomplete
      multiple
      size="small"
      value={value}
      filterOptions={(options, params) => {
        const filter = createFilterOptions();
        const filtered = filter(options, params);
        return [{ name: 'Select All...', all: true }, ...filtered];
      }}
      onChange={(event, newValue) => {
        if (newValue.some((option) => option.all)) {
          const isSelectAllNewlyAdded = newValue.length === 1 || newValue[newValue.length - 2].all !== true;
          updateValuesById(isSelectAllNewlyAdded ? options : []);
        } else {
          updateValuesById(newValue);
        }
      }}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            style={{ marginRight: 8, padding: 0 }}
            checked={option.all ? !!(value.length === options.length) : selected}
          />
          {option.name}
        </li>
      )}
      style={{ minWidth: '90vw', width: '100%' }}
      renderInput={(params) => <TextField {...params} label={title} placeholder="Select" />}
    />
  );
}
