import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HOST_API_URL } from '../../../config-global';
import { useSelector } from '../../../redux/store';
import { DateRangePickerSelect } from '../../../reusable-components/date-range-picker';
import Iconify from '../../../reusable-components/iconify';
import { fCurrency } from '../../../utils/formatNumber';

CustomToolbar.propTypes = {
  setShowSelection: PropTypes.func,
  showSelection: PropTypes.string,
  isFiltered: PropTypes.func,
  setSelected: PropTypes.func,
  selected: PropTypes.array,
  activeRows: PropTypes.array,
};
export default function CustomToolbar({
  setShowSelection,
  showSelection,
  isFiltered,
  setSelected,
  selected,
  activeRows,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get('start');
  const endDate = searchParams.get('end');

  const { unreadOrders } = useSelector((state) => state.orders);

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate(`/dashboard/orders?filter=pending`);
        break;
      case 1:
        navigate(
          `/dashboard/orders?filter=previous&start=${new Date(
            new Date().setDate(new Date().getDate() - 29)
          ).toISOString()}&end=${new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()}`
        );
        break;
      default:
        break;
    }
  };
  const handleCancel = () => {
    setSelected([]);
    setShowSelection('');
  };

  const downloadPrevOrders = async () => {
    window.open(`${HOST_API_URL}Api/DownloadPreviousOrders?Start=${startDate}&End=${endDate}`, '_blank');
  };

  const downloadPendingOrders = async () => {
    window.open(`${HOST_API_URL}Api/DownloadPendingOrders?Start=${startDate}&End=${endDate}`, '_blank');
  };
  // /Api/DownloadPreviousOrders

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: -2 }}>
        <Box>
          <Box sx={{ width: '25vw' }}>
            <Tabs value={isFiltered('pending') ? 0 : 1} onChange={handleTabChange}>
              <Tab label={`Pending Orders${unreadOrders > 0 ? ` (${unreadOrders})` : ''}`} />
              <Tab label="Previous Orders" />
            </Tabs>
          </Box>
        </Box>
      </Grid>

      <Grid container alignItems="flex-end" justifyContent="flex-start">
        {!isFiltered('pending') && (
          <Grid item>
            <DateRangePickerSelect />
          </Grid>
        )}
        {isFiltered('pending') ? (
          <Grid item>
            <Box sx={{ display: 'inline-block', ml: 2 }}>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={downloadPendingOrders}
                startIcon={<Iconify icon="file-icons:microsoft-excel" />}
              >
                Download Pending Orders
              </Button>
            </Box>
          </Grid>
        ) : (
          <Grid item>
            <Box sx={{ display: 'inline-block', ml: 2 }}>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={downloadPrevOrders}
                startIcon={<Iconify icon="file-icons:microsoft-excel" />}
              >
                Download Prev Orders
              </Button>
            </Box>
          </Grid>
        )}
        <Grid item sx={{ ml: 2 }}>
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              Total
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'info.main' }}>
              {fCurrency(activeRows.filter(item => item.deleteFlag !== true).map((item) => item.total).reduce((a, b) => a + b, 0))}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 1 }}>
        {!!showSelection && (
          <>
            <Button variant="contained" sx={{ mr: 1 }} disabled={!selected.length}>
              {showSelection}
            </Button>
            <Button sx={{ mr: 1 }} onClick={handleCancel} variant="outlined" color="error">
              Cancel
            </Button>
          </>
        )}
      </Grid>
    </>
  );
}
