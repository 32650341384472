import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Grid, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import LoopIcon from '@mui/icons-material/Loop';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { DateRangePickerSelect } from '../../../../reusable-components/date-range-picker';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import axiosInstance from '../../../../utils/axios';
import Iconify from '../../../../reusable-components/iconify';
import { fMonthDayTimeYear } from '../../../../utils/formatTime';
import { objectToFormData } from '../../../../api/utils';

export default function ReceivedEmailsView()
{
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReprocessingEmails, setIsReprocessingEmails] = useState(false);
  const [reprocessEmails, setReprocessEmails] = useState(false);
  const [selectedEmailsToReprocess, setSelectedEmailsToReprocess] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();  

  const columns = [
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1,     
      type: 'customText'
    },
    {
      field: 'sentFrom',
      headerName: 'Sent From',
      width: 200,
      type: 'customText',
    },
    {
      field: 'sentTo',
      headerName: 'Sent To',
      width: 200,
      type: 'customText',
    },
    {
      field: 'emailSentOn',
      headerName: 'Sent On',
      renderCell: (params) => params.value ? <> <Iconify clickable={false} icon={'material-symbols:calendar-today-outline'} width={13} height={13} />{fMonthDayTimeYear(params.value)}</> : "-",
      width: 140,
      type: 'customDate',
    },
    {
      field: 'emailReceivedOn',
      headerName: 'Received On',
      width: 140,
      renderCell: (params) => params.value ? <> <Iconify clickable={false} icon={'material-symbols:calendar-today-outline'} width={13} height={13} />{fMonthDayTimeYear(params.value)}</> : "-",   
      type: 'customDate',
    },   
    {
      field: 'possibleRepeatMessage',
      headerName: 'Possible Repeat',
      width: 120,
      renderCell: (params) => params.row.possibleRepeatMessage ? <Iconify clickable={false} icon="material-symbols:check-circle-outline" color="primary" /> : "",
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      valueGetter: (params) => params.row.emailBodySearchShortText,
      renderCell: (params) => <>
        <IconButton color="info" onClick={() => window.open(`/Api/Admin/ReceivedEmail/DownloadReceivedEmail?id=${params.id}`, '_blank')}>
          <DownloadIcon id="action-button" className="action-button" />
        </IconButton>
        <ConfirmDialog
          icon="refresh"
          title="Reprocess Email"
          color="warning"
          content="Are you sure you want to reprocess this email?"
          actionButton="Reprocess Email"
          tooltip="Reprocess Email"
          action={() => handleReprocessEmails([params.id])}
        />
      </>,
    }
  ]

  const getReceivedEmails = () => {
    setIsLoading(true);
    axiosInstance.get(`/Admin/ReceivedEmail/ReceivedEmails?start=${searchParams.get('start')}&end=${searchParams.get('end') }`)
      .then((res) => setData(res.data))
      .catch(() => enqueueSnackbar('Error fetching emails', { variant: 'error' }))
      .finally(() => setIsLoading(false));
  };

  const handleReprocessEmails = (ids) => {    
    setIsReprocessingEmails(true);
    axiosInstance.post(`/Admin/ReceivedEmail/ReprocessEmails`, objectToFormData({ ids }))
      .catch(() => enqueueSnackbar('Something when wrong', { variant: 'error' }))
      .finally(() => {
        setSelectedEmailsToReprocess([]);
        setReprocessEmails(false);
        setIsReprocessingEmails(false);
      });
  };

  useEffect(() => {
    getReceivedEmails();
  }, [searchParams]);

  const customToolbar = () => <Grid container direction="row" alignItems="flex-end" spacing={2}>
    <Grid item>
      <DateRangePickerSelect />
    </Grid>
    <Grid item>
      {selectedEmailsToReprocess.length > 0 ? <LoadingButton
        variant="outlined"
        size="small"
        color="secondary"
        loading={isReprocessingEmails}
        onClick={() => handleReprocessEmails(selectedEmailsToReprocess)}
        startIcon={<LoopIcon />}
      >
        Confirm Reprocess Emails
      </LoadingButton> : <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={() => setReprocessEmails(show => !show)}
        startIcon={<LoopIcon />}
      >
        {reprocessEmails ? "Cancel Reprocess Emails" : "Reprocess Emails"}
      </Button>}
    </Grid>
  </Grid>;

  return <Grid item xs={12}>
    <CustomDataGrid
      gridId="admin-email-view"
      boxSX={{ height: 'calc(100vh - 225px)' }}
      key={isReprocessingEmails ? 1 : 0}
      data={data}
      gridColumns={columns}
      sort={[{ field: 'dateAdded', sort: 'desc' }]}
      isLoading={isLoading}
      disableSelectionOnClick
      CustomLeftToolbar={customToolbar}
      checkboxSelection={reprocessEmails}
      displayCheckboxRight
      onRowSelectionModelChange={(newSelection) => {
        setSelectedEmailsToReprocess(newSelection.slice(0, 50));
      }}
      rowSelectionModel={selectedEmailsToReprocess}
    />
  </Grid>;
}
