import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useSelector } from '../../redux/store';

VendorIdToName.propTypes = {
  vendorId: PropTypes.number,
  raw: PropTypes.bool,
};

export default function VendorIdToName({ vendorId, raw }) {
  const {
    data: { vendors },
  } = useSelector((state) => state.vendors);

  const vendorIdToName = () => {
    const vendor = vendors.find((v) => v.id === vendorId);
    return vendor ? vendor.name : '';
  };
  return (
    <>
      {raw ? (
        vendorIdToName()
      ) : (
        <Box
          component="div"
          sx={{
            fontSize: '0.7rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
        >
          {vendorIdToName()}
        </Box>
      )}
    </>
  );
}
