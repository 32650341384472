import axios from 'axios';
import { objectToFormData } from '../utils';

export async function login({ email, password, returnUrl, rememberMe = false }) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  formData.append('returnUrl', returnUrl);
  formData.append('rememberMe', rememberMe);
  const response = await axios.post(`api/Login`, formData);
  return response.data;
}

export async function logoutUser() {
  const response = await axios.get(`Api/Logout`);
  return response.data;
}

// GET / Api / ConfirmEmail;

// GET / Api / ForgotPassword / { email };
export async function forgotPassword(email) {
  const response = await axios.get(`Api/ForgotPassword?email=${email}`);
  return response.data;
}

// POST / Api / ResetPassword; email code password
export async function resetPassword({ email, code, password }) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('code', code);
  formData.append('password', password);
  const response = await axios.post(`Api/ResetPassword`, formData);
  return response.data;
}

export async function updateUser({ firstName, lastName, position, password }) {
  const formData = new FormData();
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('position', position);
  if (password) formData.append('password', password);
  const response = await axios.post(`Api/UserForm`, formData);
  return response.data;
}

export async function getUserData() {
  const response = await axios.get(`Api/UserData`);
  return response.data;
}

export async function getFacilityManagerData() {
  const response = await axios.get(`Api/FacilityManager`);
  return response.data;
}

export async function updateFacilityManager(value) {
  const formData = new FormData();
  formData.append('name', value?.name);
  formData.append('autoCloseTicketsIn', value?.autoCloseTicketsIn);
  formData.append('receiveInvoiceEmails', value?.receiveInvoiceEmails);
  formData.append('receiveTicketEmails', value?.receiveTicketEmails);
  // formData.append('useAllOrdersForPendingNumber', value?.useAllOrdersForPendingNumber);

  const response = await axios.post(`Api/FacilityManagerForm`, formData);
  return response.data;
}

///Api/RefreshLoginData
export async function refreshLoginData() {
  const response = await axios.get(`Api/RefreshLoginData`);
  return response.data;
}
///Api/UserConfigForm
export async function updateUserConfig(userSettings) {
  const input = userSettings;

  const formData = objectToFormData({
    input,
  });
  const response = await axios.post(`Api/UserConfigForm`, formData);
  return response.data;
}

export async function getOrderEmailRules() {
  const response = await axios.get(`Api/OrderEmailRules`);
  return response.data;
}

export async function orderEmailRuleForm(orderEmailRule) {
  const response = await axios.post(`Api/OrderEmailRuleForm`, objectToFormData(orderEmailRule));
  return response;
}

export async function deleteOrderEmailRule(id) {
  const response = await axios.post(`Api/DeleteOrderEmailRule?id=${id}`);
  return response;
}