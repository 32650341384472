import { Box, Grid, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useSelector } from '../../../redux/store';
import { SHIP_DAYS } from '../../../reusable-components/datagrid/shipDayColumns';
import { ScopeGuard } from '../../../reusable-components/scopes';

export default function DashboardShipDays() {
  const { shipDays } = useSelector((state) => state.dashboard.data);
  const ships = useMemo(() => shipDays?.filter(s => new Date(s.date) >= new Date()).sort((a, b) => b - a), [shipDays]);

  return (
    <ScopeGuard scope={['Submit-Order']} allowAdmin>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" color="primary" sx={{ ml: 0.5, mt: 1, mb: .5 }}>
          Upcoming Ship Days
        </Typography>
      </Grid>
      <Box sx={{ height: '34vh', width: '100%', mb: 3 }}>
        <DataGridPro
          rows={ships ?? []}
          columns={SHIP_DAYS}
          getRowId={(row) => row.id}
          hideFooter
        />
      </Box>
    </ScopeGuard>
  );
}
