import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Typography, Box, Card, Stepper, Step, StepLabel } from '@mui/material';
import { useSelector } from '../../../../redux/store';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import CartItemView from './rentalCart/CartItemView';
import { getFacilityInfo } from '../../../../api/rentals';

export default function RentalOrderSummary() {
  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get('orderNumber');
  const residentId = searchParams.get('residentId');
  const [cartItems, setCartItems] = useState([]);
  const [facilityInfo, setFacilityInfo] = useState({});
  const [vendorNames, setVendorNames] = useState([]);

  const {
    data: { cart, residentsList, rentalVendors },
  } = useSelector((state) => state.rentals);

  useEffect(() => {
    setCartItems(cart.filter((item) => item.residentId === Number(residentId)));
  }, [residentId, cart]);

  const getNameById = (residentId) => cart.find((res) => res.residentId === parseInt(residentId, 10));
  const residentData = getNameById(residentId);

  const cartResident = residentData ? `${residentData.residentFirstName} ${residentData.residentLastName}` : '';

  const roomNumber = residentData ? `${residentData.room}` : '';
  const steps = ['Rental Created', 'Rental Placed', 'Rental Approved', 'Rental Sent', 'Rental Returned'];

  const displayVendorNames = () => {
    const vendors = [];
    cartItems.forEach((cartItem) => {
      const newVendor = rentalVendors.find((vendor) => vendor.id === cartItem.vendorId);
      if (newVendor && !vendors.includes(newVendor.name)) {
        vendors.push(newVendor.name);
      }
      setVendorNames(vendors);
    });
  };
  useEffect(() => {
    displayVendorNames();
  }, [cartItems]);

  const fetchFacilityData = async () => {
    try {
      const response = await getFacilityInfo(residentsList[0].facilityId);
      setFacilityInfo(response.data);
    } catch (error) {
      console.error('Error fetching facility data:', error);
    }
  };
  useEffect(() => {
    fetchFacilityData();
  }, []);

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ ml: 9, mt: 3, mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}
      >
        Order Confirmation
      </Typography>
      <Grid container direction="row">
        <Grid item sx={{ width: '30%' }}>
          <Grid container direction="column" sx={{ maxWidth: '70%', ml: 9 }}>
            <Card sx={{ padding: 3, alignItems: 'center' }}>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}>
                  Rental Request {orderNumber}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'center' }}>
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={1} alternativeLabel>
                    {steps.map((label) => (
                      <Step
                        sx={{
                          '& .MuiStepLabel-root .Mui-active ': {
                            color: '#4ca5e7',
                          },
                          '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                            color: '#6d6e71', // Just text label (ACTIVE)
                            fontSize: '10px',
                            marginTop: 1,
                          },
                          '& .MuiStepLabel-root .Mui-completed': {
                            color: '#4ca5e7',
                          },
                          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                            color: '#6d6e71', // Just text label (ACTIVE)
                            fontSize: '10px',
                            marginTop: 1,
                          },

                          '& .MuiStepLabel-root .Mui-disabled ': {
                            color: '#4ca5e7',
                          },
                          '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel': {
                            color: '#6d6e71', // Just text label (ACTIVE)
                            fontSize: '10px',
                            marginTop: 1,
                          },
                        }}
                        key={label}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 1, color: '#8dc63f', fontWeight: 'bold', fontSize: '14px' }}
                >
                  {vendorNames.length > 1 ? 'Rental Vendors' : 'Rental Vendor'}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography sx={{ mr: 2, fontSize: '12px' }}>
                  {vendorNames.map((vendorName, ind) => (
                    <Typography key={ind} sx={{ mr: 2, fontSize: '12px' }}>
                      {vendorName}
                    </Typography>
                  ))}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '14px' }}
                >
                  Facility Information
                </Typography>
                <Grid item sx={{ textAlign: 'left' }}>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>{facilityInfo?.facilityName}</Typography>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>{facilityInfo?.streetAddress}</Typography>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>
                    {facilityInfo?.city}, {facilityInfo?.state} {facilityInfo?.zip}
                  </Typography>
                  <Typography sx={{ mr: 2, fontSize: '12px' }}>{facilityInfo?.phoneNumber}</Typography>
                </Grid>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 1, color: '#8dc63f', fontWeight: 'bold', fontSize: '14px' }}
                >
                  Resident Info
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <Typography sx={{ mr: 2, fontSize: '12px' }}>{cartResident}</Typography>
                <Typography sx={{ mr: 2, fontSize: '12px' }}>Room {roomNumber}</Typography>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid item sx={{ width: '70%' }}>
          <Box key={cartResident}>
            <Card sx={{ width: '90%', display: 'inline-flex', flexDirection: 'column', mb: 4, padding: 3, ml: 4 }}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <CustomAvatar
                      name={cartResident}
                      size="large"
                      showTooltip={false}
                      sx={{
                        height: 40,
                        width: 40,
                        fontSize: 40 / 2,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" sx={{ color: '#707070', fontWeight: 'bold' }}>
                      {cartResident}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <Typography variant="subtitle1" sx={{ color: '#8cc53f' }}>
                      Total: ${cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {cartItems.map((cartItem, ind) => (
                <>
                  {cartItem.isSelected && (
                    <CartItemView
                      cartItem={cartItem}
                      key={ind}
                      showDivider={cartItems.length - 1 !== ind}
                      showQuantity
                    />
                  )}{' '}
                </>
              ))}
            </Card>
          </Box>{' '}
        </Grid>
      </Grid>
    </>
  );
}
