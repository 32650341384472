import { Card, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { markAsRead } from '../../../../redux/slices/tickets';
import TicketMessageInput from './TicketMessageInput';
import TicketMessageList from './TicketMessageList';
import TicketSubject from './TicketSubject';

export default function Ticket() {
  const { id = '' } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(markAsRead(id));
  }, [id]);

  return (
    <Card sx={{ height: '100%', display: 'flex', px: '20px', pb: '20px' }}>
      <Stack flexGrow={1}>
        <TicketSubject />

        <Stack
          direction="column"
          justifyContent="flex-end"
          flexGrow={1}
          sx={{
            overflow: 'hidden',
            border: '2px solid #208ee0',
            borderRadius: '5px',
            boxShadow: '0px 3px 5px 2px grey',
          }}
        >
          <Stack
            flexGrow={1}
            sx={{
              px: 2,
            }}
          >
            <TicketMessageList />
          </Stack>
          <Stack flexGrow={1}>
            <Scrollbar>
              <TicketMessageInput />
            </Scrollbar>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
