import React from "react";
import PropTypes from 'prop-types';
import { Box } from "@mui/material";

FileDropWrapper.propTypes = {
  onClick: PropTypes.func,
  onFileDropped: PropTypes.func,
  accept: PropTypes.string,
  children: PropTypes.node,
  singleFile: PropTypes.bool
};

export default function FileDropWrapper({ onFileDropped, children, accept = null, onClick = null, singleFile = true })
{
  const onDropFile = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (!ev.dataTransfer.items) {
      onFileDropped(null);
      return;
    }
    if (singleFile) {
      onFileDropped(ev.dataTransfer.items.length > 0 ? ev.dataTransfer.items[0].getAsFile() : null);
      return;
    }
    onFileDropped(ev.dataTransfer.items.map(x => x.getAsFile()));      
  }   

  if (!onClick) onClick = () => {
    const inputFile = document.createElement("input");
    inputFile.type = "file";
    inputFile.accept = accept;
    inputFile.click();
    inputFile.onchange = (ev) => onFileDropped(ev.target.files.length > 0 ? ev.target.files[0] : null)
    inputFile.remove();
  };

  const prevent = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
  }

  return (<Box onDrop={onDropFile} onClick={onClick} onDragEnter={prevent} onDragLeave={prevent} onDragOver={prevent} >
    {children}
  </Box>)
}

