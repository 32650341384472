import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Box, Grid, Button, TextField, Typography, Autocomplete } from '@mui/material';
import BookingFacilityCopy from './BookingFacilityCopy';
import { getBookingVendorAccountsData, postUpdateFacilityBookingType } from '../../../../api/facility';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import BookingEmailTable from './BookingEmailTable';
import BookingAccountTable from './BookingAccountTable';

BookingAccount.propTypes = {
  selectedRow: PropTypes.object,
};

export default function BookingAccount({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [bookingData, setBookingData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorBookingTypes, setVendorBookingTypes] = useState([]);
  const [facilityBookingTypes, setFacilityBookingType] = useState([]);
  const [apContacts, setAPContacts] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [bookingType, setBookingType] = useState('');
  const [editBookingType, setEditBookingType] = useState('');
  const [account, setAccount] = useState('');
  const [editAccount, setEditAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getBookingVendorAccountsData(selectedRow.id);
      const { account, vendors, facilities, facilityBookingTypes, facilityBookingType, facilityVendorBookingAccounts,
        vendorBookingTypes, apContacts } = response.data;
      setFacilities(facilities);
      setAPContacts(apContacts);
      setBookingData(facilityVendorBookingAccounts);
      setBookingType(facilityBookingType);
      setEditBookingType(facilityBookingType);
      setAccount(account || '');
      setEditAccount(account || '');
      setVendors(vendors);
      setFacilityBookingType(facilityBookingTypes);
      setVendorBookingTypes(vendorBookingTypes);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching booking data:', error);
      enqueueSnackbar('Failed to load data', { variant: 'error' });
      setIsLoading(false);
    }
  };

  useEffect(() => { fetchData(); }, [selectedRow]);
 
  const handleSave = async () => {
    try {
      const response = await postUpdateFacilityBookingType(selectedRow.id, editBookingType?.id, editAccount);
      enqueueSnackbar(response?.data?.success ? 'Booking type updated successfully' : response.data?.message || "Something went wrong",
        { variant: response?.data?.success ? 'success' : 'error' });
      if (response?.data?.success) fetchData();
    } catch (error) {
      console.error('Error updating booking type:', error);
      enqueueSnackbar("Something went wrong", { variant: 'error' });
    }
  };

  const edit = bookingType !== editBookingType || account !== editAccount;

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mt: 2 }}>
          <Box sx={{ mr: 1 }}>
            <Typography variant="subtitle1">Booking Type</Typography>
            <Autocomplete
              fullWidth
              value={editBookingType}
              onChange={(event, newValue) => setEditBookingType(newValue)}
              size="small"
              options={facilityBookingTypes}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} label="Search or select" />}
              sx={{
                width: '20vw',
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>

          {editBookingType?.useFacilityAccount && <Box>
            <Typography variant="subtitle1">Booking Account #</Typography>
            <TextField
              size="small"
              sx={{ width: '15vw' }}
              value={editAccount}
              onChange={(e) => setEditAccount(e.target.value)}
            />
            </Box>}
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mt: 2 }}>
          {!edit && <BookingFacilityCopy bookingType={bookingType} facilities={facilities} facilityRow={selectedRow} fetchData={fetchData} />}
          {edit && <>
            <Button
              size="small"
              sx={{ mr: 1 }}
              variant="outlined"
              color="error"
              onClick={() => { setEditAccount(account); setEditBookingType(bookingType); }}
            >
              Cancel
            </Button>
            <AwaitButton size="small" disabled={!edit} variant="outlined" onClick={handleSave}>
              Save
            </AwaitButton>
           </>}
        </Grid>
        {editBookingType?.useFacilityAccount && <BookingAccountTable 
          bookingVendorAccounts={bookingData}
          facilityRow={selectedRow}
          isLoading={isLoading}
          vendors={vendors}
          vendorBookingTypes={vendorBookingTypes}
          fetchData={fetchData}
          disabled={edit}
          bookingType={editBookingType}
          />}
        {editBookingType?.useEmail && <BookingEmailTable 
          apContacts={apContacts}
          facilityRow={selectedRow}
          fetchData={fetchData}
          isLoading={isLoading}
          disabled={edit}
          bookingType={editBookingType}
          />}
      </Grid>
    </>
  );
}
