import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Box, Button, Grid, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { HOST_API_URL } from '../../../../config-global';
import AwaitButton from '../../../../reusable-components/await-button/AwaitButton';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import NewUserModal from './NewUserModal';

CustomToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  setShowSelection: PropTypes.func,
  showSelection: PropTypes.bool,
  deleteSelection: PropTypes.func,
  selectionType: PropTypes.string,
  setSelectionType: PropTypes.func,
};

export default function CustomToolbar({
  selected,
  setSelected,
  showSelection,
  setShowSelection,
  deleteSelection,
  selectionType,
  setSelectionType,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const downloadUsers = async () => {
    //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
    window.open(`${HOST_API_URL}Api/Admin/User/DownloadUsersTable`, '_blank');
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <ScopeGuard scopes={['User-Edit']} allowAdmin>
            <NewUserModal />
          </ScopeGuard>

          <Button
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            onClick={downloadUsers}
            startIcon={<Iconify icon="mdi:tray-arrow-down" />}
          >
            Download
          </Button>

          <ScopeGuard scopes={['User-Edit']} allowAdmin>
            {!showSelection && (
              <Button
                color="secondary"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
                size="small"
                sx={{ mr: 1 }}
                startIcon={<Iconify icon="mdi:dots-horizontal" width={24} height={24} sx={{ ml: 1 }} />}
              />
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
              <ScopeGuard scopes={['Edit-Order']} allowAdmin>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setShowSelection(() => true);
                    setSelectionType('Enable');
                  }}
                >
                  Enable Users
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setShowSelection(() => true);
                    setSelectionType('Disable');
                  }}
                >
                  Disable Users
                </MenuItem>
                <MenuItem
                  sx={{ color: 'error.main' }}
                  onClick={() => {
                    setAnchorEl(null);
                    setSelectionType('Delete');
                    setShowSelection(() => true);
                  }}
                >
                  Delete Users
                </MenuItem>
              </ScopeGuard>
            </Menu>
            {showSelection && (
              <>
                {selectionType === 'Disable' && (
                  <AwaitButton
                    color="warning"
                    variant="contained"
                    size="small"
                    startIcon={<PersonOffIcon />}
                    onClick={() => {
                      deleteSelection(selected);
                      setShowSelection(false);
                      setSelected([]);
                    }}
                  >
                    Disable
                  </AwaitButton>
                )}

                {selectionType === 'Enable' && (
                  <AwaitButton
                    color="primary"
                    variant="contained"
                    size="small"
                    startIcon={<PersonIcon />}
                    onClick={() => {
                      deleteSelection(selected);
                      setShowSelection(false);
                      setSelected([]);
                    }}
                  >
                    Enable
                  </AwaitButton>
                )}
                {selectionType === 'Delete' && (
                  <ConfirmDialog
                    button="contained"
                    icon="delete-outline"
                    title="Delete"
                    color="error"
                    content={`Are you sure you want to delete ${selected.length} users?`}
                    actionButton="Delete"
                    action={() => {
                      deleteSelection(selected);
                      setShowSelection(false);
                      setSelected([]);
                    }}
                    cancelAction={() => {
                      setShowSelection(false);
                      setSelected([]);
                    }}
                  />
                )}
                <Button
                  color="primary"
                  sx={{ textDecoration: 'underline' }}
                  onClick={() => {
                    setShowSelection(false);
                    setSelected([]);
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </ScopeGuard>
        </Box>
      </Grid>
    </>
  );
}
