import { React, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, Grid, IconButton, Typography, Button, Tooltip, Divider, Stack, Skeleton } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { useDispatch, useSelector } from '../../../../redux/store';
import { addInvoiceLog, invoiceForm, invoiceGLItemsForm } from '../../../../redux/slices/invoicing';
import { useScopeCheck, ScopeGuard } from '../../../../reusable-components/scopes';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import ReviewInvoice from './ReviewInvoice';
import InvoiceForm from './InvoiceForm';
import MultiFacilityToggle from './MultiFacilityToggle';
import { InvoiceLog } from './InvoiceLog';
import GlItemsForm from './GlItemsForm';
import GlItemsDataGrid from './GlItemsDataGrid';
import InvoiceAttachments from './InvoiceAttachments';
import SubmitInvoice from './SubmitInvoice';
import MoveToProcessed from './MoveToProcessed';
import { fDateTime } from '../../../../utils/formatTime';
import { twoDecimal } from '../../../../utils/formatNumber';
import { InvoiceChat } from './InvoiceChat';

export default function InvoiceView() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const {
    data: { invoiceData, invoiceItemData, invoiceGLItems },
  } = useSelector((state) => state.invoicing);
  const { subcategories } = invoiceData;
  const { invoice } = invoiceItemData || {};
  const { shipping } = invoice || {};
  const [openSidebar, setOpenSidebar] = useState(true);
  const [openGlCodeSummary, setOpenGlCodeSummary] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(0);
  const [glRows, setGlRows] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState(null);
  const [highlightMissingGls, setHighlightMissingGls] = useState(false);
  const [enableMarkAsSent, setEnableMarkAsSent] = useState(false);
  const [unsavedData, setUnsavedData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const scrollbarRef = useRef(null);
  const ref = useRef(null);

  const rejectInvoice = () => {
    dispatch(addInvoiceLog({ invoiceId: id, messageType: 'Reject', extraInfo: 'Reject Message' }));
  };
  const approveInvoice = () => {
    dispatch(addInvoiceLog({ invoiceId: id, messageType: 'Approve' }));
  };
  const initializeGlItems = async () => {
    const glItems =
      invoiceGLItems?.length === 0
        ? await glItemsWithAdditional(invoice?.unconsolidatedGls ? unconsolidatedGls() : await groupByGls(), false)
        : await glItemsWithAdditional(invoiceGLItems, true);
    setGlRows(glItems);
  };

  const glItemsWithAdditional = (items, savedGls) => {
    const shippingAmount = shipping || 0;
    const shippingAmountInCents = Math.round(shippingAmount * 100);
    let remainder = shippingAmountInCents % items?.length;
    const baseShippingAmountInCents = (shippingAmountInCents - remainder) / items?.length;
    return items?.map((item, index) => {
      let shippingForThisItemInCents = baseShippingAmountInCents;
      if (remainder > 0) {
        shippingForThisItemInCents += 1;
        remainder -= 1;
      }
      return {
        ...item,
        id: index,
        shipping: savedGls ? twoDecimal(item.shipping) : shippingForThisItemInCents / 100,
        amount: twoDecimal(item.amount),
      };
    });
  };

  const groupByGls = () => {
    const groupedItems = invoiceItemData?.invoiceItems?.reduce((group, item, index) => {
      const { agoraSubcategory } = item;

      const subcategory = subcategories
        ?.filter((x) => x.facilityId === invoiceItemData?.invoice?.facilityId)
        ?.find((x) => x.subcategoryId === agoraSubcategory.id);

      const { glCode } = subcategory || {};
      if (glCode !== null && glCode !== undefined) {
        const newAmount = item?.price * item?.quantity + item?.taxAmount;
        group[glCode] = group[glCode] ?? {
          id: index,
          invoiceId: invoiceItemData?.invoice?.id,
          facilityId: invoiceItemData?.invoice?.facilityId,
          agoraCategoryId: item?.agoraCategory?.id,
          agoraSubcategoryId: item?.agoraSubcategory?.id,
          agoraCategory: item?.agoraCategory?.name,
          agoraSubcategory: item?.agoraSubcategory?.name,
          glCode,
          shipping: 0,
          amount: 0,
          glItemId: 0,
          memo: '',
          referenceNumber: '',
        };
        group[glCode].amount += newAmount;
      }
      return group;
    }, {});
    return groupedItems !== undefined ? Object.values(groupedItems) : groupedItems;
  };
  const unconsolidatedGls = () => {
    const groupedItems = invoiceItemData?.invoiceItems
      ?.map((item, index) => {
        const { agoraSubcategory } = item;
        const subcategory = subcategories
          ?.filter((x) => x.facilityId === invoiceItemData?.invoice?.facilityId)
          ?.find((x) => x.subcategoryId === agoraSubcategory.id);
        const { glCode } = subcategory || {};
        return {
          id: index,
          invoiceId: invoiceItemData?.invoice?.id,
          facilityId: invoiceItemData?.invoice?.facilityId,
          agoraCategoryId: item?.agoraCategory?.id,
          agoraSubcategoryId: item?.agoraSubcategory?.id,
          agoraCategory: item?.agoraCategory?.name,
          agoraSubcategory: item?.agoraSubcategory?.name,
          glCode,
          shipping: 0,
          amount: item?.price * item?.quantity + item?.taxAmount,
          glItemId: 0,
          memo: item?.description ?? '',
          referenceNumber: '',
        };
      })
      .filter((x) => x.glCode);
    return groupedItems !== undefined ? Object.values(groupedItems) : groupedItems;
  };
  useEffect(() => {
    if (invoiceData?.length !== 0) {
      initializeGlItems();
    }
  }, [invoiceGLItems, invoiceItemData, invoiceData]);
  useEffect(() => {
    if (invoiceItemData?.invoice?.id === Number(id)) setDataLoaded(true);
  }, [id, invoiceItemData]);
  useEffect(() => {
    if (highlightedRows !== null) {
      const timeout = setTimeout(() => {
        setHighlightedRows(null);
      }, 500);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [highlightedRows]);
  useEffect(() => {
    if (highlightMissingGls) {
      const timeout = setTimeout(() => {
        setHighlightMissingGls(false);
      }, 700);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [highlightMissingGls]);
  useEffect(() => {
    if (invoiceItemData?.invoice?.multiFacility) setOpenGlCodeSummary(true);
  }, [invoiceItemData?.invoice?.multiFacility]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [highlightMissingGls]);

  return (
    <div ref={ref}>
      <Grid container direction="column" spacing={2}>
        <Grid item sx={{}}>
          {dataLoaded ? (
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                borderRadius: '4px',
                boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
                backgroundColor: '#fff',
                padding: '15px',
                overflow: 'auto',
                border: 'solid 1px #c1c9d0',
              }}
            >
              <Grid item>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                  <Typography color="secondary" sx={{ fontWeight: 'bold' }}>
                    Invoice:
                  </Typography>
                  <Typography color="secondary">#{invoiceItemData?.invoice?.vendorInvoiceId}</Typography>
                </Stack>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                  <Typography color="secondary" sx={{ fontWeight: 'bold' }}>
                    PO:
                  </Typography>
                  <Typography color="secondary">#{invoiceItemData?.invoice?.invoiceId}</Typography>
                </Stack>
              </Grid>
              <Grid item>
                {invoice?.sent ? (
                  <Box
                    sx={{
                      color: 'secondary',
                      border: '1px solid #b128bc',
                      borderRadius: '5px',
                      padding: '3px 9px',
                    }}
                  >
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      <Typography color="secondary" sx={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Submitted on:
                      </Typography>
                      <Typography color="secondary" sx={{ fontSize: '13px' }}>
                        {fDateTime(invoice?.invoiceBookedTo?.dateAdded)}
                      </Typography>
                    </Stack>
                  </Box>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item> {enableMarkAsSent && <MoveToProcessed />}</Grid>
                    <Grid item>
                      <SubmitInvoice
                        glRows={glRows}
                        setEnableMarkAsSent={setEnableMarkAsSent}
                        unsavedData={unsavedData}
                        setUnsavedData={setUnsavedData}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : (
            <Skeleton variant="rectangular" height={80} />
          )}
        </Grid>
        <Grid item>
          <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={2.5}>
              {dataLoaded ? (
                <>
                  <InvoiceForm
                    glRows={glRows}
                    setHighlightedGls={setHighlightedRows}
                    setOpenGlCodeSummary={setOpenGlCodeSummary}
                  />
                  {!invoiceItemData?.invoice?.multiFacility && (
                    <GlItemsForm glRows={glRows} setHighlightMissingGls={setHighlightMissingGls} />
                  )}
                  <MultiFacilityToggle />
                </>
              ) : (
                <Skeleton variant="rectangular" height={550} />
              )}
            </Grid>
            <Grid item xs={openSidebar ? 7.1 : 8.9}>
              {dataLoaded ? (
                <Box>
                  <ReviewInvoice
                    selectedFileId={selectedFileId}
                    highlightMissingGls={highlightMissingGls}
                    setOpenSidebar={setOpenSidebar}
                    unsavedData={unsavedData}
                    setUnsavedData={setUnsavedData}
                    vendor={invoice?.vendor}
                  />
                  {invoiceItemData?.invoice?.multiFacility && (
                    <GlItemsDataGrid
                      highlightedRows={highlightedRows}
                      rows={glRows}
                      openGlCodeSummary={openGlCodeSummary}
                      setOpenGlCodeSummary={setOpenGlCodeSummary}
                    />
                  )}
                  {/* <InvoicePdf /> */}
                </Box>
              ) : (
                <Skeleton variant="rectangular" height={550} />
              )}
            </Grid>
            <Grid
              item
              xs={0.1}
              style={{
                alignSelf: 'stretch',
                position: 'relative',
                //  marginRight: openSidebar ? 0 : '15px'
              }}
            >
              <Grid container direction="row" sx={{ zIndex: 9999 }}>
                <Tooltip title={openSidebar ? 'Collapse' : 'Expand'}>
                  <IconButton
                    onClick={() => setOpenSidebar(!openSidebar)}
                    sx={{
                      position: 'absolute',
                      top: 50,
                      right: -13,
                      zIndex: 2,
                      backgroundColor: '#f4f6f8',
                      padding: 0,
                      '&:hover': {
                        color: 'info.main',
                        backgroundColor: '#f4f6f8',
                      },
                    }}
                  >
                    <Iconify
                      icon={`iconamoon:arrow-${openSidebar ? 'right' : 'left'}-6-circle-bold`}
                      width={26}
                      height={26}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Divider orientation="vertical" sx={{ border: 'solid .3px #707070', opacity: '.60', zIndex: 1 }} />
            </Grid>
            {openSidebar ? (
              <Grid
                item
                xs={2.2}
                sx={{
                  position: 'relative',
                }}
              >
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ pb: '50px' }}>
                  <Grid container spacing={2} direction="column">
                    {dataLoaded ? (
                      <>
                        <Grid item>
                          <InvoiceChat />
                        </Grid>
                        <Grid item>
                          <InvoiceLog />
                        </Grid>
                        <Grid item>
                          <InvoiceAttachments setSelectedFileId={setSelectedFileId} />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item>
                          <Skeleton variant="rectangular" height={100} />
                        </Grid>
                        <Grid item>
                          <Skeleton variant="rectangular" height={200} />
                        </Grid>
                        <Grid item>
                          <Skeleton variant="rectangular" height={220} />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={0.4}>
                <Box
                  sx={{
                    transform: 'rotate(90deg)',
                    display: 'flex',
                    marginTop: '5px',
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ width: '135px', borderRadius: 0 }}
                      onClick={() => setOpenSidebar(true)}
                      startIcon={<Iconify icon={'bi:chat'} />}
                    >
                      Chat
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ width: '135px', borderRadius: 0 }}
                      onClick={() => setOpenSidebar(true)}
                      startIcon={<Iconify icon={'pajamas:log'} />}
                    >
                      Invoice log
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ width: '135px', borderRadius: 0 }}
                      onClick={() => setOpenSidebar(true)}
                      startIcon={<Iconify icon={'material-symbols:upload'} />}
                    >
                      Attachments
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mainBoxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  padding: '25px',
  overflow: 'auto',
  border: 'solid 1px #c1c9d0',
  //maxHeight: '450px',
};
