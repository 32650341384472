import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

PdfPopupButton.propTypes = {
  url: PropTypes.string,
  tooltipText: PropTypes.string,
  icon: PropTypes.node
};

export default function PdfPopupButton({ url, icon, tooltipText })
{
  return <Tooltip title={tooltipText} arrow>
    <IconButton
      color="warning"
      onClick={() => {
        const modal = document.createElement('div');
        modal.style.position = 'fixed';
        modal.style.zIndex = '100000';
        modal.style.left = '0';
        modal.style.bottom = '0';
        modal.style.width = '100%';
        modal.style.height = '100%';
        modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        modal.style.display = 'flex';
        modal.style.justifyContent = 'center';
        modal.style.alignItems = 'center';

        const iframe = document.createElement('iframe');
        iframe.style.width = '70%';
        iframe.style.height = '100%';
        iframe.src = url;

        modal.appendChild(iframe);

        modal.addEventListener('click', (e) => {
          if (e.target === modal) {
            document.body.removeChild(modal);
          }
        });
        document.body.appendChild(modal);
      }}
    >
      {icon}
    </IconButton>
  </Tooltip>;
}
