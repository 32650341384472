import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Tooltip, Grid, Button, Stack } from '@mui/material';
import { InvoiceView } from '../../../components/dashboard/invoicing';
import { useDispatch, useSelector } from '../../../redux/store';
import { getInvoiceBreakdown } from '../../../redux/slices/invoicing';
import Iconify from '../../../reusable-components/iconify';

export default function Invoice() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const section = location.pathname.split('/')[3];

  useEffect(() => {
    async function fetchData() {
      await dispatch(getInvoiceBreakdown(id));
    }
    fetchData();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Invoicing | Platform Solutions</title>
      </Helmet>
      <Stack direction={'column'}>
        <Box sx={{ justifyContent: 'start', mb: '5px' }}>
          <Button
            variant="text"
            onClick={() => {
              if (section === 'previous')
                navigate(
                  `/dashboard/invoicing/previous?start=${new Date(
                    new Date().setDate(new Date().getDate() - 29)
                  ).toISOString()}&end=${new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()}`
                );
              else navigate(`/dashboard/invoicing/${section}`);
            }}
            startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
            sx={{
              color: '#454F5B',
              fontWeight: 'bold',
            }}
          >
            Back to invoices
          </Button>
        </Box>
        <InvoiceView />
      </Stack>
    </>
  );
}
