import { styled } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import { markAsReadSystemMessages } from '../../api/home';
import { getUnreadData } from '../../redux/slices/dashboard';
import { useDispatch, useSelector } from '../../redux/store';
import { useSnackbar } from '../../reusable-components/snackbar';

import Header from './header';
import { Navbar } from './navbar';
import useInterval from '../../reusable-components/use-interval/UseInterval';

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  marginLeft: 16,
  marginRight: 16,
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  paddingTop: 88,
  paddingBottom: theme.spacing(1),
}));
const NavStyles = styled('div')(() => ({
  paddingBottom: 4,
  marginBottom: 4,
  paddingTop: 64,
  marginLeft: 100,
  marginRight: 100,
}));

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

const OutletStyles = styled('div')(() => {
  const screenWidth = useWindowWidth();
  let margin;

  if (screenWidth >= 1920) {
    margin = 100;
  } else if (screenWidth <= 1720) {
    margin = 16;
  } else {
    margin = (screenWidth - 1720) / 2;
  }

  return {
    marginLeft: `${margin}px`,
    marginRight: `${margin}px`,
  };
});

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { enqueueSnackbar } = useSnackbar();
  const isVisible = usePageVisibility();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { unreadSystemMessages } = useSelector((state) => state.dashboard);

  if (user) {
    Sentry.setUser({
      username: `${user.firstName} ${user.lastName}`,
      id: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
  } else {
    Sentry.setUser(null);
  }

  const markAsRead = async (ids) => {
    const response = await markAsReadSystemMessages(ids);
    if (response.status === 200) {
      dispatch(getUnreadData());
    }
  };

  useInterval(() => {
    if (user && isVisible && process.env.NODE_ENV === 'production') dispatch(getUnreadData());
  }, [Math.floor(Math.random() * 30000) + 60000]);

  useEffect(() => {
    if (user && isVisible) dispatch(getUnreadData());
  }, [dispatch, user, isVisible]);

  useEffect(() => {
    if (unreadSystemMessages?.length > 0) {
      unreadSystemMessages.forEach((unreadMessage) => {
        const formattedMessages = unreadMessage.messages.map((msg) => `| ${msg}`).join('\n');
        const fullMessage = `${unreadMessage.title} - \n${formattedMessages}`;

        enqueueSnackbar({
          message: fullMessage,
          persist: true,
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          onClose: () => {
            markAsRead([unreadMessage.id]);
          },
          ContentProps: {
            style: {
              whiteSpace: 'pre-line',
            },
          },
        });
      });
    }
  }, [unreadSystemMessages]);

  return (
    <StyledRoot>
      <Main>
        <Header />
        <NavStyles>
          <Navbar />
        </NavStyles>
        <OutletStyles>
          <Outlet />
        </OutletStyles>
      </Main>
    </StyledRoot>
  );
}
