import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useGridApiRef, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid-pro';
import { Checkbox, Box, Dialog, DialogTitle, DialogContent, IconButton, Button, DialogActions } from '@mui/material';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import Iconify from '../../../../../reusable-components/iconify';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { updateUsersRoleValuesInRedux } from '../../../../../redux/slices/users';
import { useSnackbar } from '../../../../../reusable-components/snackbar';

RolesModal.propTypes = {
  params: PropTypes.object,
};

export default function RolesModal({ params }) {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    data: { user },
  } = useSelector((state) => state.users);
  const { roleValues } = user;

  const [sortModel, setSortModel] = useState([{ field: 'active', sort: 'desc' }]);
  const [tempRoles, setTempRoles] = useState([]);
  const { optionValues, innerOptionValues, id } = params;
  const [initialSort, setInitialSort] = useState(true);
  const [open, setOpen] = useState(false);
  const [allValues, setAllValues] = useState([]);

  useEffect(() => {
    if (roleValues?.length > 0 && initialSort) {
      setSortModel([{ field: 'active', sort: 'desc' }]);
      setInitialSort(false);
    }
  }, [roleValues, initialSort]);

  useEffect(() => {
    setTempRoles(roleValues);
  }, [roleValues]);

  const roleIsActive = useMemo(
    () =>
      tempRoles?.reduce((result, role) => {
        const key = `${role?.userPermissionTypeId}-${role?.modelId}-${role?.modelAttachmentId || 'undefined'}`;
        result[key] = true;
        return result;
      }, {}),
    [tempRoles]
  );
  const rows = useMemo(
    () =>
      allValues?.map(({ option, innerOption }, index) => ({
        id: `${id}-${option.key}-${innerOption ? innerOption.key : 'undefined'}`,
        key: index,
        facility: option.value,
        company: option?.subOptions ? option?.subOptions[0]?.value : '',
        category: innerOption ? innerOption.value : '',
      })),
    [allValues, id]
  );

  const allRolesActive = useMemo(() => {
    const allActive = rows?.every((row) => roleIsActive[row.id]);
    return allActive;
  }, [rows, roleIsActive]);

  useEffect(() => {
    if (innerOptionValues) {
      const combinedValues = optionValues?.flatMap((option) =>
        innerOptionValues?.map((innerOption) => ({
          option,
          innerOption,
        }))
      );
      setAllValues(combinedValues);
    } else {
      setAllValues(optionValues?.map((option) => ({ option })));
    }
  }, [optionValues, innerOptionValues]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTempRoles(roleValues);
    setOpen(false);
  };
  const handleSave = () => {
    dispatch(updateUsersRoleValuesInRedux(tempRoles));
    enqueueSnackbar('Roles updated you must still press save to save user roles changes ', { variant: 'success' });
    setOpen(false);
  };

  const handleCheck = (event, rowId) => {
    event.stopPropagation();
    const parts = rowId.split('-');
    const id = parts[0];
    const option = parts[1];
    const innerOption = parts[2] !== 'undefined' ? parts[2] : undefined;

    const roleIndex = tempRoles.findIndex((role) => {
      const roleString = `${role.userPermissionTypeId}-${role.modelId}-${
        role.modelAttachmentId ? role.modelAttachmentId : 'undefined'
      }`;
      return roleString === rowId;
    });

    if (roleIndex !== -1) {
      const removeRole = tempRoles.filter((role) => {
        const roleString = `${role.userPermissionTypeId}-${role.modelId}-${
          role.modelAttachmentId ? role.modelAttachmentId : 'undefined'
        }`;

        return roleString !== rowId;
      });

      setTempRoles(removeRole);
    } else {
      const newRole = {
        userPermissionTypeId: parseInt(id, 10),
        modelId: parseInt(option, 10),
        modelAttachmentId: parseInt(innerOption, 10) || undefined,
      };
      setTempRoles([...tempRoles, newRole]);
    }
  };

  const handleCheckAll = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const visibleRowIds = gridFilteredSortedRowIdsSelector(apiRef?.current?.state);

    event.stopPropagation();
    if (allRolesActive) {
      const removedRoles = tempRoles.filter((role) => {
        const roleString = `${role.userPermissionTypeId}-${role.modelId}-${
          role.modelAttachmentId ? role.modelAttachmentId : 'undefined'
        }`;
        const row = rows.find((row) => row.id === roleString);
        return !row || !visibleRowIds.includes(row.id);
      });

      setTempRoles(removedRoles);
    } else {
      const newRoles = rows
        .filter((row) => visibleRowIds.includes(row.id))
        .map((row) => {
          const parts = row.id.split('-');
          const id = parts[0];
          const option = parts[1];
          const innerOption = parts[2] !== 'undefined' ? parts[2] : undefined;

          return {
            userPermissionTypeId: parseInt(id, 10),
            modelId: parseInt(option, 10),
            modelAttachmentId: parseInt(innerOption, 10) || undefined,
          };
        });
      setTempRoles([...tempRoles, ...newRoles]);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'facility',
        headerName: params.modelLabel,
        valueGetter: (params) =>
          user.facility === params.row.facility ? `0${params.row.facility}` : `1${params.row.facility}`,
        flex: 1,
        type: 'customText',
        renderCell: (params) =>
          params.row.facility === user.facility ? (
            <Box
              sx={{
                color: 'info.main',
              }}
            >
              {params.row.facility}
            </Box>
          ) : (
            params.row.facility
          ),
      },
      ...(allValues?.some(({ option }) => option.subOptions && option.subOptions?.length > 0)
        ? [{ field: 'company', headerName: 'Company', flex: 1, type: 'customSelect' }]
        : []),
      ...(allValues?.some(({ innerOption }) => innerOption)
        ? [{ field: 'category', headerName: 'Category', type: 'customSelect', flex: 1 }]
        : []),
      {
        field: 'active',
        headerName: 'Active',
        flex: 1,
        renderHeader: () => (
          <>
            <Checkbox
              checked={allRolesActive}
              sx={{
                mr: 1,
                color: '#fff',
              }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleCheckAll(event);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />{' '}
            Active
          </>
        ),
        renderCell: (params) => (
          <Checkbox
            checked={roleIsActive[params.row.id] || false}
            onChange={(event) => handleCheck(event, params.row.id)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        ),
        valueGetter: (params) =>
          roleIsActive[params.row.id]
            ? `${params.row.facility === user.facility ? '1' : '0'}1${params.row.label}`
            : `${params.row.facility === user.facility ? '1' : '0'}0${
                params.row.facility || params.row.company || params.row.category
              }`,
      },
    ],
    [allValues, roleIsActive, user.facility, allRolesActive]
  );

  if (!optionValues) return null;

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <OpenInNewIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {params.label}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
            <CustomDataGrid
              gridId="admin-roles-modal"
              getRowHeight={() => 'auto'}
              data={rows}
              gridColumns={columns}
              disableSelectionOnClick
              sort={sortModel}
              isModal
              apiRef={apiRef}
              forceReRender={tempRoles}
            />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave}>
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
