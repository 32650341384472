import EditIcon from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { postEmailRecipientForm } from '../../../../api/emailRecipients';
import { getEmailRecipientTypes } from '../../../../redux/slices/emailRecipient';
import { useDispatch, useSelector } from '../../../../redux/store';
import { FORGOT_PASSWORD_SCHEMA } from '../../../../reusable-components/form/authSchema';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

EmailRecipientModal.propTypes = {
  edit: PropTypes.bool,
  row: PropTypes.object,
  fetchData: PropTypes.func,
};

export default function EmailRecipientModal({ edit, row, fetchData }) {
  const {
    data: { sites, sendTypes, recipientTypes },
  } = useSelector((state) => state.emailRecipient);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [emailError, setEmailError] = useState('');
  const [value, setValue] = useState({
    email: null,
    id: null,
    emailRecipientType: null,
    sendType: null,
    siteId: null,
  });

  useEffect(() => {
    if (value.siteId > 0) dispatch(getEmailRecipientTypes(value.siteId));
  }, [dispatch, value.siteId, open]);

  const validateEmail = async () => {
    try {
      await FORGOT_PASSWORD_SCHEMA.validateAt('email', { email: value.email });
      setEmailError('');
      return true;
    } catch (error) {
      setEmailError(error.message);
      return false;
    }
  };
  const sendTypeValue = useMemo(() => {
    const s = sendTypes?.find((s) => s?.value === value.sendType) ?? null;
    return s;
  }, [sendTypes, value.sendType]);

  const sendForValue = useMemo(() => {
    const s = recipientTypes.find((r) => r?.value === value.emailRecipientType) ?? null;
    return s;
  }, [recipientTypes, value.emailRecipientType]);

  const handleSave = async () => {
    const isValidEmail = await validateEmail();
    if (!isValidEmail) {
      setEmailError('Please write a correct email address');
      return;
    }
    const response = await postEmailRecipientForm(value, edit ? row?.id : null);
    if (response) {
      enqueueSnackbar('Email updated successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to updated email', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      email: edit ? row.email : null,
      id: edit ? row.id : null,
      emailRecipientType: edit ? row.emailRecipientTypeId : null,
      sendType: edit ? row.sendTypeId : null,
      siteId: edit ? row.site?.value : user.siteId,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setValue({
      email: null,
      id: null,
      emailRecipientType: null,
      sendType: null,
      siteId: user?.siteId ?? null,
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <Tooltip title="Edit">
          <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
          size="small"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Recipient
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {edit ? 'Edit' : 'Add'} Email Recipient
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ p: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">Email</Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={value.email}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      email: e.target.value,
                    })
                  }
                  label=""
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                  inputProps={{ autoComplete: 'new-password', form: { autocomplete: 'off' } }}
                  error={!!emailError}
                  helperText={emailError}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">Site</Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={sites.find((s) => s?.value === value.siteId)}
                  onChange={(event, newValue) => {
                    setValue({ ...value, siteId: newValue?.value });
                  }}
                  options={sites}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">Send For</Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={sendForValue}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      emailRecipientType: newValue?.value,
                    });
                  }}
                  options={recipientTypes}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">Send Type</Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={sendTypeValue}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      sendType: newValue?.value ?? null,
                    });
                  }}
                  options={sendTypes}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150 }}
            onClick={handleSave}
            disabled={!value.email || !value.emailRecipientType || !value.sendType || !value.siteId}
          >
            {edit ? 'Save' : 'Add Recipient'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
