import { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Checkbox,
  DialogContentText,
  FormControlLabel,
} from '@mui/material';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { ROLES_COLUMNS } from '../../../../../reusable-components/datagrid/permissionColumns';
import {
  updateUsersRoleValuesInRedux,
  updateUsersRolesInRedux,
  updateTemplateIdInRedux,
  getUsers,
} from '../../../../../redux/slices/users';
import TemplateRolesCheckbox from './TemplateRolesCheckbox';
import RolesModal from './RolesModal';
import { getUserTemplateValuesData, updateTemplateRoles } from '../../../../../api/user';
import { useScopeCheck } from '../../../../../reusable-components/scopes';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../../reusable-components/snackbar';

TemplatesTab.propTypes = {
  row: PropTypes.object,
  handleClose: PropTypes.func,
};

export default function TemplatesTab({ row, handleClose }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { userRoleTypeList, user, userRoleTemplates, unsavedChanges },
  } = useSelector((state) => state.users);
  const { roles, roleValues } = user;
  const sortCompare = (a, b) => (a > b) - (a < b)
  const roleIds = roles?.map(role => role.roleId) ?? [];

  const isCheckedLookup = useMemo(() => {
    const lookup = {};
    roles.forEach((role) => {
      lookup[role.id] = role.selectAll;
    });
    return lookup;
  }, [roles]);

  const hasEditAccess = useScopeCheck(['User-Edit'], true);
  const [isLoading, setIsLoading] = useState(false);
  const [templateName, setTemplateName] = useState(row.name);
  const [templateId, setTemplateId] = useState(row.id);
  const [unsavedChangesInTemplate, setUnsavedChangesInTemplate] = useState(false);
  const [useUserFacility, setUserFacility] = useState(row.useUserFacility);

  useEffect(() => {
    dispatch(updateTemplateIdInRedux(row.id));
    setTemplateId(row.id);
    setTemplateName(row.name);
    setUserFacility(row.useUserFacility);
    setUnsavedChangesInTemplate(false);
    handleTemplateChange(row.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.id]);

  const handleSelectAllChange = (event, params) => {
    const { optionValues, innerOptionValues, id } = params.row;

    const { checked } = event.target;
    const allValues = innerOptionValues
      ? optionValues?.flatMap((option) =>
          innerOptionValues?.map((innerOption) => ({
            option,
            innerOption,
          }))
        )
      : optionValues?.map((option) => ({ option }));

    const rows = allValues?.map(({ option, innerOption }, index) => ({
      id: `${id}-${option.key}-${innerOption ? innerOption.key : 'undefined'}`,
      key: index,
      facility: option.value,
      company: option?.subOptions ? option?.subOptions[0]?.value : '',
      category: innerOption ? innerOption.value : '',
    }));

    const newRoles = rows?.map((row) => {
      const { id } = row;
      const parts = id.split('-');
      const roleId = parseInt(parts[0], 10);
      const modelId = parseInt(parts[1], 10);
      const modelAttachmentId = parseInt(parts[2], 10) || undefined;

      return {
        userPermissionTypeId: roleId,
        modelId,
        modelAttachmentId,
      };
    });

    // const findRole = roles.find((role) => role.id === id);

    if (checked) {
      // findRole.selectAll = true;
      dispatch(updateUsersRoleValuesInRedux([...roleValues, ...newRoles]));
      //change selectAll value to true and add the role to the roles array and send all roles to redux
    }
    if (!checked) {
      // findRole.selectAll = false;
      const removeRole = [...roleValues].filter((role) => {
        const roleString = `${role.userPermissionTypeId}-${role.modelId}-${
          role.modelAttachmentId ? role.modelAttachmentId : 'undefined'
        }`;
        return !rows?.some((row) => row.id === roleString);
      });

      dispatch(updateUsersRoleValuesInRedux(removeRole));
    }

    const updatedRoles = roles.map((role) => {
      if (role.id === id) {
        return {
          ...role,
          selectAll: checked,
        };
      }
      return role;
    });

    dispatch(updateUsersRolesInRedux(updatedRoles));
  };

  const active = {
    field: 'active',
    headerName: 'Active',
    headerAlign: 'right',
    align: 'right',
    flex: 0.5,
    renderCell: (params) => <TemplateRolesCheckbox row={params?.row} />,
    valueGetter: (params) => {
      const roleIncluded = roles.some((item) => item.role === params?.row?.role);
      return roleIncluded ? `0${params?.row?.label}` : `1${params?.row?.label}`;
    },
  };

  const selectAll = {
    field: 'selectAll',
    renderHeader: () => (
      <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
        <div>Select All Permissions</div>
        <div style={{ fontSize: 'smaller' }}>(For now and future use)</div>
      </div>
    ),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: (params) => {
      const { optionValues, id } = params.row;
      return (
        optionValues && (
          <Checkbox
            checked={!!isCheckedLookup[id]}
            onChange={(event) => handleSelectAllChange(event, params)}
            disabled={!roles.find((role) => role.id === id)}
          />
        )
      );
    },
  };

  const actions = {
    field: 'actions',
    headerName: 'Permissions',
    flex: 0.5,
    renderCell: (params) => <RolesModal params={params.row} />,
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await updateTemplateRoles({
      id: templateId,
      name: templateName,
      roles,
      roleValues,
      siteId: 0,
      useUserFacility,
    });
    if (response) {
      setUnsavedChangesInTemplate(false);
      enqueueSnackbar('Template roles updated', { variant: 'success' });
      dispatch(getUsers());
      handleClose();
    }
  };

  const handleTemplateChange = async (id) => {
    dispatch(updateTemplateIdInRedux(id));
    try {
      const response = await getUserTemplateValuesData(id);
      if (response.data) {
        const { roles, values } = response.data;
        const allRoles = roles.map((role) => {
          const roleType = userRoleTypeList.find((roleType) => roleType.id === role.roleId);
          return { ...role, ...roleType };
        });
        const allRolesValues = values.map((role) => ({
          userPermissionTypeId: role.roleId,
          modelId: role.modelId,
          modelAttachmentId: role.modelAttachmentId,
        }));
        dispatch(updateUsersRolesInRedux(allRoles));

        dispatch(updateUsersRoleValuesInRedux(allRolesValues));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const customToolbar = useCallback(
    () => (
      <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mb: 0.5 }}>
          <Grid item sx={{ mr: 4 }}>
            <DialogContentText sx={{ fontWeight: 'bold', mb: -1 }}>Copy Template</DialogContentText>
            <Autocomplete
              fullWidth
              options={userRoleTemplates}
              getOptionLabel={(option) => option?.name || ''}
              size="small"
              value={userRoleTemplates.find((option) => option.id === templateId)}
              onChange={(event, newValue) => {
                setUnsavedChangesInTemplate(true);
                if (newValue) {
                  handleTemplateChange(newValue?.id);
                }
              }}
              disabled={!hasEditAccess}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
              sx={{
                width: '25vw',
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': 'none',
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useUserFacility}
                  onChange={(event) => {
                    setUnsavedChangesInTemplate(true);
                    setUserFacility(!useUserFacility);
                  }}
                />
              }
              label="Use User Facility"
            />
          </Grid>
        </Grid>
      </>
    ),
    [hasEditAccess, templateId, userRoleTemplates, useUserFacility]
  );

  return (
    <>
      <Box
        sx={{
          // width: '70vw',
          height: 'calc(100vh - 205px)',
        }}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mb: 1 }}>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mb: 0.5 }}>
              <Grid item sx={{ mr: 4 }}>
                <>
                  <DialogContentText sx={{ fontWeight: 'bold', mb: -1 }}>Template Name</DialogContentText>
                  <TextField
                    size="small"
                    value={templateName}
                    onChange={(event) => {
                      setUnsavedChangesInTemplate(true);
                      setTemplateName(event.target.value);
                    }}
                    sx={{ width: '25vw', mt: 1 }}
                  />
                </>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {(unsavedChanges || unsavedChangesInTemplate) && (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ width: 150, mr: 1 }}
                  onClick={() => {
                    dispatch(getUsers());
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <AwaitButton
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ width: 150 }}
                  onClick={handleSave}
                  loading={isLoading}
                >
                  Save
                </AwaitButton>
              </>
            )}
          </Grid>
        </Grid>

        <CustomDataGrid
          gridId="admin-user-template-roles-tab"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          dontGetRowHeight
          data={[...userRoleTypeList].sort((a, b) => sortCompare(roleIds.includes(b.id), roleIds.includes(a.id)) || sortCompare(a.label, b.label))}
          gridColumns={[...ROLES_COLUMNS, active, selectAll, hasEditAccess && actions]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          isModal
          getRowId={(row) => row.id}
          forceReRender={roles}
        />
      </Box>
    </>
  );
}
