import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { postEmailPickupForm } from '../../../../api/vendors';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

PickUpModal.propTypes = {
  fetchData: PropTypes.func,
  edit: PropTypes.bool,
  row: PropTypes.object,
  emailTypeList: PropTypes.array,
  emailForList: PropTypes.array,
};

export default function PickUpModal({ fetchData, edit, row, emailTypeList, emailForList }) {
  const {
    data: { vendors },
  } = useSelector((state) => state.vendors);
  const [open, setOpen] = useState(false);
  const [emailType, setEmailType] = useState([]);
  const [emailFor, setEmailFor] = useState([]);

  useEffect(() => {
    if (emailTypeList) {
      const keyPairType = emailTypeList.map((status, index) => ({
        id: index,
        label: status,
      }));
      setEmailType(keyPairType);
    }
  }, [emailTypeList]);

  useEffect(() => {
    if (emailForList) {
      const keyPairType = emailForList.map((status, index) => ({
        id: index,
        label: status,
      }));
      setEmailFor(keyPairType);
    }
  }, [emailForList]);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    vendor: '',
    type: '',
    for: '',
    value: '',
  });

  const handleSave = async () => {
    const response = await postEmailPickupForm(value, edit ? row?.id : null);
    if (response) {
      enqueueSnackbar('Email pickup added successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to add email pickup', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      vendor: edit ? { id: row.vendorId, label: vendors.find((vendor) => vendor.id === row.vendorId).name } : '',
      value: edit ? row.value : '',
      type: edit ? { id: -1, label: row?.type } : '',
      for: edit ? { id: -1, label: row.for } : '',
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      vendor: '',
      value: '',
      type: '',
      for: '',
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <Tooltip title="Edit email pickup">
          <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          size="small"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Email Pickup
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth size="small">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {edit ? 'Edit' : 'Add'} Email Pickup
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                value={value.vendor}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    vendor: newValue,
                  });
                }}
                options={vendors?.map((type) => ({ id: type.id, label: type.name }))}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Search type</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                value={value?.type}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    type: newValue,
                  });
                }}
                options={emailType}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Search For</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                value={value.for}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    for: newValue,
                  });
                }}
                options={emailFor}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Search Value</DialogContentText>
              <TextField
                fullWidth
                size="small"
                value={value.value}
                onChange={(e) =>
                  setValue({
                    ...value,
                    value: e.target.value,
                  })
                }
                label=""
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave}>
            {edit ? 'Save' : 'Add Pickup'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
