import PropTypes from 'prop-types';
import { Box } from '@mui/material';

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  padding: PropTypes.number,
};

export default function TabPanel(props) {
  const { children, value, index, padding = 0, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: padding, minWidth: '100%' }}>{children}</Box>}
    </div>
  );
}
