import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Typography,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Iconify from '../../../../reusable-components/iconify';
import { getSystemMessagesData, postSystemNoticeForm } from '../../../../api/system-notices';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

EditNoticeDialog.propTypes = {
  row: PropTypes.object,
  getData: PropTypes.func,
};

export default function EditNoticeDialog({ row, getData }) {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState(row?.title);

  const { enqueueSnackbar } = useSnackbar();

  const getNoticeData = async (id) => {
    try {
      const res = await getSystemMessagesData(id);
      setMessages(res.data);
    } catch (error) {
      enqueueSnackbar('Error fetching System Notices', { variant: 'error' });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    getNoticeData(row.id);
  };

  const handleClose = () => {
    setOpen(false);
    setTitle('');
    setMessages([]);
  };

  const handleSave = async () => {
    postSystemNoticeForm({ id: row.id, title, messages, complete: row.complete }).then((res) => {
      if (res.status === 200) {
        getData();
        enqueueSnackbar('Notice updated successfully', { variant: 'success' });
        setOpen(false);
        setTitle('');
        setMessages([]);
      }
    });
  };

  return (
    <div>
      <IconButton color="info" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          Edit Notice
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ p: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Title
                </Typography>
                <TextField
                  value={title}
                  type="number"
                  onChange={(e) => setTitle(e.target.value)}
                  label=""
                  multiline
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </Box>
            </Grid>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: -2, ml: -2, mt: 2 }}>
                Messages
                <IconButton
                  color="primary"
                  onClick={() => setMessages([...messages, { id: messages.length * -1 - 1, message: '' }])}
                >
                  <Iconify icon="eva:plus-outline" />
                </IconButton>
              </Typography>
              {messages?.map((message) => (
                <Grid
                  container
                  key={message.id}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ my: 2 }}
                >
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    <TextField
                      value={message.message}
                      type="number"
                      onChange={(e) =>
                        setMessages(
                          messages.map((m) => {
                            if (m.id === message.id) {
                              return { ...m, message: e.target.value };
                            }
                            return m;
                          })
                        )
                      }
                      label=""
                      multiline
                      size="small"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        autoComplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleClose}
            sx={{ width: '200px', boxShadow: 3, fontWeight: 'bold' }}
          >
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ width: '200px', boxShadow: 3, fontWeight: 'bold' }}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
