import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { Card, Container, DialogTitle, Dialog } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { selectEvent, selectRange, onCloseModal } from '../../../../redux/slices/vendors';
import { CalendarForm, StyledCalendar, CalendarToolbar } from '../../../../reusable-components/calendar';
import ListViewGrid from './ListVewGrid';

// ----------------------------------------------------------------------

CalendarPage.propTypes = {
  events: PropTypes.array,
  typesArray: PropTypes.array,
  handleEditAddDate: PropTypes.func,
  handleDelete: PropTypes.func,
  view: PropTypes.string,
  setView: PropTypes.func,
};

export default function CalendarPage({ events, typesArray, handleEditAddDate, handleDelete, setView, view }) {
  const dispatch = useDispatch();

  const calendarRef = useRef(null);

  const { openModal, selectedRange, selectedEventId } = useSelector((state) => state.vendors);

  const selectedEvent = useSelector(() => {
    if (selectedEventId) {
      return events.find((event) => event.id === parseInt(selectedEventId, 10));
    }

    return null;
  });

  // const picker = useDateRangePicker(null, null);

  const [date, setDate] = useState(new Date());

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
    }
    setView(newView);
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    dispatch(
      selectRange({
        start: arg.start,
        end: arg.end,
      })
    );
  };

  const handleSelectEvent = (arg) => {
    dispatch(selectEvent(arg.event.id));
  };

  const handleCloseModal = () => {
    dispatch(onCloseModal());
  };

  return (
    <>
      <Container maxWidth={'xl'}>
        <Card>
          <StyledCalendar>
            <CalendarToolbar
              date={date}
              view={view}
              onNextDate={handleClickDateNext}
              onPrevDate={handleClickDatePrev}
              onToday={handleClickToday}
              onChangeView={handleChangeView}
            />
            {view === 'dayGridMonth' ? (
              <>
                <FullCalendar
                  weekends
                  editable
                  droppable
                  selectable
                  allDayMaintainDuration
                  eventResizableFromStart
                  events={events}
                  initialEvents={events}
                  ref={calendarRef}
                  initialDate={date}
                  initialView={view}
                  dayMaxEventRows={3}
                  eventDisplay="block"
                  headerToolbar={false}
                  select={handleSelectRange}
                  // eventDrop={handleDropEvent}
                  eventClick={handleSelectEvent}
                  // eventResize={handleResizeEvent}
                  height={'auto'}
                  plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
                />
              </>
            ) : (
              <>
                <ListViewGrid />
              </>
            )}
          </StyledCalendar>
        </Card>
      </Container>

      <Dialog fullWidth maxWidth="xs" open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{selectedEvent ? 'Edit Event' : 'Add Event'}</DialogTitle>

        <CalendarForm
          event={selectedEvent}
          range={selectedRange}
          onCancel={handleCloseModal}
          onCreateUpdateEvent={handleEditAddDate}
          onDeleteEvent={handleDelete}
          typesArray={typesArray}
          view={view}
        />
      </Dialog>
    </>
  );
}
