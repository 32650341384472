import PropTypes from 'prop-types';
import { Box, TextField, DialogContentText, FormControlLabel, Checkbox } from '@mui/material';

IntegrationFormText.propTypes = {
  // value: PropTypes.object,
  title: PropTypes.string,
  setDefinitions: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.number,
  vendorId: PropTypes.number,
  definitions: PropTypes.array,
  currDef: PropTypes.object,
};

export default function IntegrationFormText({
  definitions,
  vendorId,
  title,
  id,
  setDefinitions,
  disabled,
  fullWidth,
  size,
  type,
  currDef,
}) {
  const idToNameFromDefinitions = () => {
    let value = '';
    definitions.forEach((definition) => {
      if (definition.definitionTypeId === id && definition.vendorId === vendorId) {
        // eslint-disable-next-line prefer-destructuring
        value = definition.value;
      }
    });
    return value;
  };

  const updateDefinitions = (value) => {
    setDefinitions((prevDefinitions) => {
      const exists = prevDefinitions.some(
        (definition) => definition.definitionTypeId === id && definition.vendorId === vendorId
      );

      if (exists) {
        return prevDefinitions.map((definition) => {
          if (definition.definitionTypeId === id && definition.vendorId === vendorId) {
            return { ...definition, value };
          }
          return definition;
        });
      }
      return [
        ...prevDefinitions,
        {
          vendorId,
          definitionTypeId: id,
          value,
        },
      ];
    });
  };

  return (
    <>
      {type === 'text' ? (
        <Box>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>{title}</DialogContentText>
          <TextField
            size={size}
            sx={{ mt: 0.5 }}
            fullWidth
            disabled={disabled}
            value={idToNameFromDefinitions()}
            onChange={(event) => {
              updateDefinitions(event.target.value);
            }}
          />
        </Box>
      ) : type === 'checkBox' ? (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={idToNameFromDefinitions()}
                name={title}
                onChange={(event) => {
                  updateDefinitions(event.target.checked);
                }}
              />
            }
            label={title}
          />
        </Box>
      ) : type === 'multi' ? (
        <Box>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>{title}</DialogContentText>
          <TextField
            size={size}
            sx={{ mt: 0.5 }}
            fullWidth
            disabled={disabled}
            value={idToNameFromDefinitions()}
            onChange={(event) => {
              updateDefinitions(event.target.value);
            }}
          />
        </Box>
      ) : null}
    </>
  );
}
