import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Box, Menu, MenuItem, Button, Grid } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { useDispatch } from '../../../redux/store';
import { toggleTicketsComplete } from '../../../redux/slices/tickets';
import { CustomQuickFilter } from '../../../reusable-components/datagrid/custom-filters';
import { DateRangePickerSelect } from '../../../reusable-components/date-range-picker';
import { useScopeCheck } from '../../../reusable-components/scopes';
import TicketTabs from './TicketTabs';

CustomToolbar.propTypes = {
  setShowSelection: PropTypes.func,
  showSelection: PropTypes.string,
  filter: PropTypes.string,
  selected: PropTypes.array,
  setQuickFilter: PropTypes.func,
  setFilters: PropTypes.func,
  setActiveFilter: PropTypes.func,
};
export default function CustomToolbar({
  setFilters,
  setShowSelection,
  showSelection,
  filter,
  selected,
  setQuickFilter = () => {},
  setActiveFilter = () => {},
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const hasPinnedGridAccess = useScopeCheck(['Facility-Manager']);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setShowSelection(e.target.textContent);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setShowSelection('');
    setAnchorEl(null);
  };

  const toggleComplete = (value) => {
    setShowSelection('');
    setAnchorEl(null);
    dispatch(toggleTicketsComplete({ ticketIds: selected, isComplete: value }));
  };

  const isActive = filter === 'active';

  return <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
    <Box>
      <TicketTabs onTabChange={() => { setShowSelection(''); setAnchorEl(null); }} filter={filter}>
        <>
          <Box sx={{ mx: 2, mt: 2 }}>
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant="outlined"
              size="small"
              startIcon={<Iconify icon="mdi:dots-horizontal" width={24} height={24} sx={{ ml: 1 }} />}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {searchParams.get('filter') === 'active' ? (
                <MenuItem onClick={handleClose}>Mark as complete</MenuItem>
              ) : (
                <MenuItem onClick={handleClose}>Mark as incomplete</MenuItem>
              )}
            </Menu>
          </Box>
          {!isActive && (
            <Grid container alignItems="flex-end" justifyContent="space-between">
              <Grid item>
                <DateRangePickerSelect />
              </Grid>
            </Grid>
          )}
        </>
      </TicketTabs>
    </Box>
    {isActive && hasPinnedGridAccess && (
      <Grid item>
        <CustomQuickFilter
          setActiveFilter={setActiveFilter}
          setQuickFilter={setQuickFilter}
          setFilters={setFilters}
          applyQuickFilter
          advancedFilter
          advancedSearchBase="tickets"
        />
      </Grid>
    )}

    <Grid item xs={12} sx={{ mt: 1.5 }}>
      {showSelection && (
        <>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={() => toggleComplete(showSelection === 'Mark as complete')}
          >
            {showSelection}
          </Button>
          <Button sx={{ mr: 1 }} onClick={handleCancel}>
            Cancel
          </Button>
        </>
      )}
    </Grid>
  </Grid>;
}
