import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Box,
  TextField,
} from '@mui/material';

import Iconify from '../../../../reusable-components/iconify';

AltAddressDialog.propTypes = {
  open: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  altAddress: PropTypes.object,
  setAltAddress: PropTypes.func,
  handleCloseDialog: PropTypes.func,
};

export default function AltAddressDialog({ open, setOpenDialog, altAddress, setAltAddress, handleCloseDialog }) {
  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          Alternative address
          <IconButton
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
          {/* next row */}
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Box
              sx={{
                display: 'flex', // Use flexbox
                alignItems: 'flex-bottom', // Vertically align items to center
                '&:hover': {
                  cursor: 'default',
                },
                mt: 1,
              }}
            >
              <DialogContentText component="span" sx={{ color: '#208ee0', mr: 0.5 }}>
                <Iconify icon="material-symbols:info-outline" width={16} height={16} />
              </DialogContentText>
              <DialogContentText
                variant="subtitle2"
                sx={{ color: '#208ee0' }}
                component="span" // Use span here to prevent nested paragraphs
              >
                Note not all vendors support alternative addresses
              </DialogContentText>
            </Box>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Address</DialogContentText>
              <TextField
                name="street"
                placeholder="Address"
                fullWidth
                size="small"
                sx={{
                  mt: 0.5,
                }}
                value={altAddress.street}
                onChange={(e) => setAltAddress({ ...altAddress, street: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Phone number</DialogContentText>
              <TextField
                fullWidth
                size="small"
                name="phoneNumber"
                placeholder="Phone number"
                sx={{
                  mt: 0.5,
                }}
                value={altAddress.phoneNumber}
                onChange={(e) => setAltAddress({ ...altAddress, phoneNumber: e.target.value })}
              />
            </Grid>
          </Grid>
          <Grid justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>City</DialogContentText>
              <TextField
                name="city"
                fullWidth
                size="small"
                placeholder="City"
                sx={{ mt: 0.5 }}
                value={altAddress.city}
                onChange={(e) => setAltAddress({ ...altAddress, city: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>State</DialogContentText>
              <TextField
                name="state"
                fullWidth
                size="small"
                placeholder="State"
                sx={{ mt: 0.5 }}
                value={altAddress.state}
                onChange={(e) => setAltAddress({ ...altAddress, state: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Zip</DialogContentText>
              <TextField
                name="zip"
                fullWidth
                size="small"
                placeholder="Zip"
                sx={{ mt: 0.5 }}
                value={altAddress.zip}
                onChange={(e) => setAltAddress({ ...altAddress, zip: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150 }}
            onClick={() => setOpenDialog(false)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
