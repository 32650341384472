import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import FacilityAccount from './FacilityAccount';
import BookingAccount from './BookingAccount';

VendorAccountsTab.propTypes = {
  selectedRow: PropTypes.object,
};

export default function VendorAccountsTab({ selectedRow }) {
  return (
    <>
      <Paper sx={{ px: 2, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={6} sx={{ mt: 2 }}>
            <FacilityAccount selectedRow={selectedRow} />
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <BookingAccount selectedRow={selectedRow} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
