import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Menu, MenuItem } from '@mui/material';
import Iconify from '../iconify';
import { useUpdateOrderItemRedux } from '../../hooks/useUpdateOrderItemRedux';
import { useScopeCheck } from '../scopes';

UOMDropdown.propTypes = {
  row: PropTypes.object,
};

export default function UOMDropdown({ row }) {
  const hasEditAccess = useScopeCheck(['Edit-Order'], true);
  const { productDetailUOMs, uomAunt, uomType, id } = row;
  const updateOrderItemRedux = useUpdateOrderItemRedux();

  const currentSelectedUOM = useMemo(
    () => productDetailUOMs.find((uom) => uom.uomType === uomType),
    [productDetailUOMs, uomType]
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  return hasEditAccess ? (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          border: 1,
          width: '125px',
          borderRadius: '3px',
          position: 'relative',
          display: 'flex', // Use flexbox
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: `#C4CDD5`,
          },
          my: '4px',
          py: '2px',
          px: 0.5,
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden', // Hide overflow
            textOverflow: 'ellipsis', // Add ellipsis for overflow
            whiteSpace: 'nowrap', // Prevent wrapping
            // pr: 1,
            px: 0.5,
          }}
        >
          {currentSelectedUOM?.uomAmount}/{currentSelectedUOM?.uomType}
        </Box>
        <Box>
          <Iconify icon={'mdi:chevron-down'} />
        </Box>
      </Typography>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {row?.productDetailUOMs.map((uom, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              updateOrderItemRedux(id, 'uomAmount', uom.uomAmount);
              updateOrderItemRedux(id, 'uomType', uom.uomType);
              updateOrderItemRedux(id, 'price', uom.price);
              handleClose();
            }}
          >
            <Typography variant="body2">
              {uom.uomAmount}/{uom.uomType}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : (
    <Typography variant="subtitle2">
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'hidden', // Hide overflow
          textOverflow: 'ellipsis', // Add ellipsis for overflow
          whiteSpace: 'nowrap', // Prevent wrapping
          // pr: 1,
          px: 0.5,
        }}
      >
        {currentSelectedUOM?.uomAmount}/{currentSelectedUOM?.uomType}
      </Box>
    </Typography>
  );
}
