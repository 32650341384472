import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip, IconButton, Box, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useSelector } from '../../redux/store';
import Iconify from '../iconify';
import { CatalogItem } from '../../pages/dashboard';
import { useScopeCheck } from '../scopes';

ProductItem.propTypes = {
  row: PropTypes.object,
};

export default function ProductItem({ row }) {
  const { id = '' } = useParams();
  const hasAccess = useScopeCheck(['Edit-Order'], true);
  const { orderById } = useSelector((state) => state.orders || {});
  const { unsavedChanges } = orderById[id] || {};

  const [open, setOpen] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          row.notes || unsavedChanges ? (
            <>
              {unsavedChanges && <div>You must save changes before editing a item </div>}
              {unsavedChanges && row.notes && <br />}

              <div>Notes: {row.notes}</div>
            </>
          ) : null
        }
      >
        {hasAccess && row.itemId > 0 ? (
          <Box
            sx={{
              display: 'flex', // Use flexbox
              alignItems: 'flex-bottom', // Vertically align items to center
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => handleClickOpen(row.productId)}
          >
            <Typography
              variant="subtitle2"
              // color="secondary"
              component="span" // Use span here to prevent nested paragraphs
              sx={{
                textDecoration: 'underline',
              }}
            >
              PS{row.productId}
            </Typography>
            {row.notes ? (
              <Typography component="span" sx={{ color: 'secondary.main' }}>
                <Iconify icon="clarity:note-solid" width={16} height={16} />
              </Typography>
            ) : (
              <Typography component="span">
                <Iconify icon="clarity:note-line" width={16} height={16} />
              </Typography>
            )}
          </Box>
        ) : (
          <Box
            component="div"
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              '&:hover': {
                cursor: 'copy',
              },
            }}
            onClick={() => {
              navigator.clipboard.writeText(row.productId);
            }}
          >
            PS{row.productId}
          </Box>
        )}
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Item Info - PS{row.productId}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CatalogItem row={row} />
        </DialogContent>
      </Dialog>
    </>
  );
}
