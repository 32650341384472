import { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  Button,
  Tabs,
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import ExcelJS from 'exceljs';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Iconify from '../../../../reusable-components/iconify';
import ExcelsDropdown from './ExcelsDropdown';
import {
  getExcelTypes,
  itemLookup,
  siteItemUpload,
  priceUpdate,
  warehouseItemUpload,
  uploadUsers,
  facilityBudgetsUpload,
  vendorDatesUpload,
  vendorAccountsUpload,
  siteItemUploadWithPSNumber,
} from '../../../../api/excel';
import { HOST_API_URL } from '../../../../config-global';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import FileDropWrapper from '../../../../reusable-components/DragAndDrop/FileDropWrapper';

export default function ExcelView() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOption, setSelectedOption] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [typeData, setTypeData] = useState([]);
  const [options, setOptions] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([1]);
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState('');
  const [values, setValues] = useState({});
  const [file, setFile] = useState(null);

  const updateValuesByKey = (key, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const updateNestedValuesByKey = (key, nestedKey, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: {
        ...prevValues[key],
        [nestedKey]: value,
      },
    }));
  };

  const getValuesByKey = (key) => values[key];

  const getNestedValuesByKey = (key, nestedKey) => values?.[key]?.[nestedKey];

  const getOptions = async () => {
    const response = await getExcelTypes();
    if (response.status === 200) {
      setOptions(response.data);
      setSelectedOption(response.data[0]);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    setValues({});
  }, [selectedOption]);

  const handleSubmit = async (testRun) => {
    setIsLoading(true);
    try {
      const vendorId = typeData?.excelUploadType?.useVendor && values.vendor?.value;
      const siteId = typeData?.excelUploadType?.useSites && values.sites.value;
      const attachVendorIds = values?.attachedVendors?.map((vendor) => vendor?.value);
      const excelUploadInputs =
        values?.excelUploadInputs &&
        Object.keys(values?.excelUploadInputs)?.map((key) => ({
          excelInputTypeId: key,
          col: values.excelUploadInputs[key]?.value ? values.excelUploadInputs[key].value.charCodeAt(0) - 64 : 0,
        })).filter(x => x.col !== 0);
      const ignoreRows = selectedRows;
      const sheetName = selectedSheet;

      let response;
      switch (selectedOption.id) {
        case 1:
          response = await itemLookup({
            vendorId,
            attachVendorIds,
            excelUploadInputs,
            ignoreRows,
            sheetName,
            testRun,
            file,
          });
          break;
        case 2:
          response = await siteItemUpload({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file });
          break;
        case 3:
          response = await priceUpdate({ vendorId, siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file });
          break;
        case 4:
          response = await warehouseItemUpload({ vendorId, excelUploadInputs, ignoreRows, sheetName, testRun, file });
          break;
        case 5:
          response = await uploadUsers({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file });
          break;
        case 7:
          response = await facilityBudgetsUpload({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file });
          break;
        case 9:
          response = await vendorDatesUpload({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file });
          break;
        case 10:
          response = await vendorAccountsUpload({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file });
          break;
        case 11:
          response = await siteItemUploadWithPSNumber({
            siteId,
            excelUploadInputs,
            ignoreRows,
            sheetName,
            testRun,
            file,
          });
          break;

        default:
          throw new Error('Invalid operation selected.');
      }

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ExcelUpload_${selectedOption.type}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        enqueueSnackbar('Download started.', { variant: 'success' });
      } else {
        enqueueSnackbar('Unexpected response from server.', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    }
    setIsLoading(false);
  };

  const handleFileUpload = async (file) => {
    setIsLoading(true);
    setFile(file);
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(await file.arrayBuffer(), {
      ignoreNodes: ['hyperlinks'],
    });
    const sheetNames = workbook.worksheets.map((sheet) => sheet.name);
    setSheets(sheetNames);
    setSelectedSheet(sheetNames[0]);
    loadSheetData(workbook, sheetNames[0]);
  };  

  const loadSheetData = async (workbook, sheetName) => {
    setIsLoading(true);
    const worksheet = workbook.getWorksheet(sheetName);
    const rawData = [];
    const headerMappings = {}; 
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        const rowData = { row: rowNumber };
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          const cellHeader = String.fromCharCode(64 + colNumber);
          rowData[cellHeader] = typeof cell.value === 'object' && cell.value ? cell.text : cell.value;

          if (rowNumber === 1) {
            typeData.reqTypes?.forEach((reqType) => {
              reqType.rules.forEach((rule) => {
                if (rule.label === cell.value) {
                  headerMappings[rule.id] = cellHeader;
                }
              });
            });
          }
        });
        rawData.push(rowData);
      });

      Object.keys(headerMappings).forEach((ruleId) => {
        updateNestedValuesByKey('excelUploadInputs', ruleId, {
          label: headerMappings[ruleId],
          value: headerMappings[ruleId],
        });
      });
    const hasEmptyRow = rawData.some(row =>
      Object.keys(row)
        .filter(key => key !== 'row') // Exclude the 'row' key
        .every(key => row[key] === undefined || row[key] === null || row[key] === '') 
    );
    if (hasEmptyRow) {
      enqueueSnackbar('One or more rows are empty. Please check the data.', { variant: 'error' });
    }
    setExcelData(rawData);
    setIsLoading(false);
  };

  const handleSheetChange = async (event) => {
    setValues({});
    setSelectedSheet(event.target.value);
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(await file.arrayBuffer(), {
      ignoreNodes: ['hyperlinks'],
    });
    loadSheetData(workbook, event.target.value);
  };

  const headerRow = excelData?.find(x => Object.keys(x).length > 1);
  const columns = headerRow ? [
      { field: 'row', headerName: '', width: 50, cellClassName: 'cell-ignored' },
        ...Object.keys(headerRow)
          .filter((key) => key !== 'row')
          .map((key) => ({
            field: key,
            headerName: key,
            width: 150,
            // cellClassName: parentColumIsSelected(key) ? 'cell-ignored' : '',
          })),
      ]
    : [];

  const handleRowSelectionChange = (event, value) => {
    setSelectedRows(value);
  };

  const getRowClassName = (params) => (selectedRows.includes(params.row.row) ? 'row-ignored' : '');

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ my: 1 }}>
        <Box>
          <Tabs value={0}>
            <ExcelsDropdown
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setTypeData={setTypeData}
              options={options}
            />
          </Tabs>
        </Box>
        <FileDropWrapper onFileDropped={handleFileUpload} accept='.xlsx, .xls'  >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<Iconify icon="material-symbols:upload" />}
            sx={{ ml: 2 }}
          >
            Upload Excel
          </Button>
        </FileDropWrapper>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => {
            window.open(`${HOST_API_URL}Api/ExcelUpload/ExcelUploadTemplate?typeId=${selectedOption.id}`, '_blank');
          }}
          startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          sx={{ ml: 2 }}
        >
          Download Template
        </Button>
      </Grid>
    ),
    [selectedOption, options, excelData, selectedSheet, sheets]
  );
  // Dynamically generate column options from excelData
  let dynamicColumnOptions = [];
  if (excelData?.length > 0) {
    const firstRow = excelData[0];
    dynamicColumnOptions = Object.keys(firstRow)
      .filter((key) => key !== 'row')
      .map((key) => ({
        label: key,
        value: key,
      }));
  }

  const missingRequiredFields = useMemo(() => {
    let missing = false;
    typeData.reqTypes?.forEach((reqType) => {
      reqType.rules.forEach((rule) => {
        if (rule.required && !getNestedValuesByKey('excelUploadInputs', rule.id)) {
          missing = true;
        }
      });
    });
    return missing;
  }, [typeData, values]);

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ mt: 1 }}>
        <Grid item xs={2}>
          {excelData?.length > 0 && (
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ mt: 2 }}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="sheet-select-label">Tab</InputLabel>
                <Select
                  labelId="sheet-select-label"
                  id="sheet-select"
                  value={selectedSheet}
                  label="Sheet"
                  onChange={handleSheetChange}
                  sx={{ mt: -1 }}
                >
                  {sheets.map((sheet) => (
                    <MenuItem key={sheet} value={sheet}>
                      {sheet}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                size="small"
                multiple
                value={selectedRows}
                options={excelData?.map((data) => data.row)}
                getOptionLabel={(option) => option.toString()}
                onChange={handleRowSelectionChange}
                renderInput={(params) => <TextField {...params} label="Select Rows to Ignore" placeholder="Rows" />}
                sx={{ width: '95%', mb: 2 }}
                disableCloseOnSelect
              />
              {typeData?.excelUploadType?.useVendor && (
                <Autocomplete
                  size="small"
                  value={getValuesByKey('vendor')}
                  onChange={(event, value) => updateValuesByKey('vendor', value)}
                  options={typeData?.vendors}
                  renderInput={(params) => (
                    <TextField error={!getValuesByKey('vendor')} {...params} label="Vendor *" placeholder="Vendor" />
                  )}
                  sx={{ width: '95%', mb: 2 }}
                  disableCloseOnSelect
                />
              )}
              {typeData?.excelUploadType?.useAttachedVendors && (
                <Autocomplete
                  size="small"
                  multiple
                  options={typeData.vendors}
                  value={getValuesByKey('attachedVendors')}
                  onChange={(event, value) => updateValuesByKey('attachedVendors', value)}
                  renderInput={(params) => <TextField {...params} label="Attached Vendors" placeholder="Vendors" />}
                  sx={{ width: '95%', mb: 2 }}
                  disableCloseOnSelect
                />
              )}
              {typeData?.excelUploadType?.useSites && (
                <Autocomplete
                  size="small"
                  options={typeData.sites}
                  value={getValuesByKey('sites')}
                  onChange={(event, value) => updateValuesByKey('sites', value)}
                  renderInput={(params) => (
                    <TextField error={!getValuesByKey('sites')} {...params} label="Site *" placeholder="Site" />
                  )}
                  sx={{ width: '95%', mb: 2 }}
                  disableCloseOnSelect
                />
              )}
              {typeData?.reqTypes?.length > 0 &&
                typeData.reqTypes.map((reqType, index) => (
                  <Box key={reqType.id}>
                    <Typography variant="subtitle1" sx={{ mt: 1, ml: 1 }}>
                      {reqType.label}
                    </Typography>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                      {reqType.rules.map((rule) => (
                        <Grid item xs={index === 0 ? 6 : 12} key={rule.id}>
                          <Autocomplete
                            key={rule.id}
                            size="small"
                            options={dynamicColumnOptions || []}
                            value={getNestedValuesByKey('excelUploadInputs', rule.id) || []}
                            onChange={(event, value) => updateNestedValuesByKey('excelUploadInputs', rule.id, value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${rule.label} ${rule.required ? ' *' : ''}`}
                                error={rule.required && !getNestedValuesByKey('excelUploadInputs', rule.id)}
                                placeholder={rule.label}
                              />
                            )}
                            sx={{ width: '93%', mb: 2 }}
                            disableCloseOnSelect
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}

              {typeData?.excelUploadType?.hasTestCall && (
                <AwaitButton
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1, width: '95%' }}
                  color="warning"
                  onClick={() => handleSubmit(true)}
                  disabled={
                    missingRequiredFields ||
                    (typeData?.excelUploadType?.useVendor && !getValuesByKey('vendor')) ||
                    (typeData?.excelUploadType?.useSites && !getValuesByKey('sites'))
                  }
                >
                  Test Excel
                </AwaitButton>
              )}
              <AwaitButton
                fullWidth
                variant="contained"
                size="small"
                sx={{ mt: 1, width: '95%' }}
                onClick={() => handleSubmit(false)}
                disabled={
                  missingRequiredFields ||
                  (typeData?.excelUploadType?.useVendor && !getValuesByKey('vendor')) ||
                  (typeData?.excelUploadType?.useSites && !getValuesByKey('sites'))
                }
              >
                Submit Excel
              </AwaitButton>
            </Grid>
          )}
        </Grid>

        <Grid item xs={excelData?.length > 0 ? 10 : 12}>
          <Box sx={{ height: 'calc(100vh - 280px)' }}>
            <DataGridPro
              gridId="admin-excel"
              rows={excelData || []}
              columns={columns}
              getRowId={(row) => row.row}
              components={{
                Toolbar: CustomLeftToolbar,
              }}
              isLoading={isLoading}
              //there is a bug where the loading spinner is showing  below the data grid when the data is empty i think
              getRowClassName={getRowClassName}
              sx={{
                '& .row-ignored': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
                '& .cell-ignored': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
