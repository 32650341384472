import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
// utils
import Iconify from '../../../reusable-components/iconify';
import { getCartFacilities } from '../../../api/order-sheet';

export default function GlobalCart() {
  const [carts, setCarts] = useState([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setCarts([]);
    setAnchorEl(null);
  };
  async function fetchCartFacilities() {
    const response = await getCartFacilities();
    if (response.data.length) {
      setCarts(response.data);
    } else {
      setCarts([{ facilityData: { label: 'No Carts', value: false }, purchasing: false }]);
    }
  }

  useEffect(() => {
    if (open && carts?.length === 0) {
      fetchCartFacilities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carts?.length, open]);

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        size="small"
      >
        <Iconify icon="mdi:shopping-cart-outline" width={24} height={24} />
        <Iconify icon="mdi:chevron-down" width={24} height={24} />
      </Button>
      {carts.length > 0 && (
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {carts.map((cart, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                if (cart?.facilityData?.value)
                  navigate(`/dashboard/orders/new-order/cart/${cart?.facilityData?.value}`);
              }}
              disableRipple
            >
              <span style={{ marginLeft: 8, marginRight: 8 }}>
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                  {cart?.facilityData?.label}
                </Typography>
              </span>
              {/* <Chip label={item.itemCount} /> */}
              {cart?.facilityData?.value && (
                <Iconify icon="mdi:chevron-right" width={24} height={24} sx={{ ml: 'auto', mr: 1 }} />
              )}
            </MenuItem>
          ))}
        </StyledMenu>
      )}
    </>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
    sx={{
      ml: 10,
      mt: 0.5,
    }}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    overflow: 'auto',
    border: 'solid 1px #c1c9d0',
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      borderBottom: '0.5px solid #637381',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
}));
