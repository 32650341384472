// remember to import this file in index.js

export default function Select() {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
          },
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        },
      },
    },
  };
}
