import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Grid,
  DialogContentText,
  Button,
  TextField,
  DialogActions,
  InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from '../../../../redux/store';
import { postVendorMinimumForm } from '../../../../api/vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { formatMoney } from '../../../../utils/formatNumber';

MinimumsModal.propTypes = {
  fetchData: PropTypes.func,
  edit: PropTypes.bool,
  row: PropTypes.object,
  bookingData: PropTypes.array,
};

export default function MinimumsModal({ fetchData, edit, row }) {
  const {
    data: { vendors, facilities },
  } = useSelector((state) => state.vendors);
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    facility: '',
    vendor: '',
    minimumTotal: '',
  });

  const handleSave = async () => {
    const response = await postVendorMinimumForm(value, edit ? row?.id : null);
    if (response) {
      enqueueSnackbar('Booking minimum added successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to add booking minimum', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      facility: edit ? { id: row.facilityId, label: row.facility } : '',
      vendor: edit ? { id: row.vendorId, label: row.vendor } : '',
      minimumTotal: edit ? row.minimumTotal : '',
      isPPD: edit ? row.isPPD : '',
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      facility: '',
      vendor: '',
      minimumTotal: '',
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          size="small"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Vendor Minimum
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {edit ? 'Edit' : 'Add'} Vendor Minimum
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Facility</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                value={value.facility}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    facility: newValue,
                  });
                }}
                options={[
                  { id: 0, label: 'All Facilities' },
                  ...facilities?.map((type) => ({ id: type.value, label: type.label })),
                ]}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor</DialogContentText>
              <Autocomplete
                fullWidth
                size="small"
                value={value.vendor}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    vendor: newValue,
                  });
                }}
                options={vendors?.map((type) => ({ id: type.id, label: type.name }))}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Grid>
          </Grid>
          <Grid direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor Minimum</DialogContentText>
              <TextField
                fullWidth
                size="small"
                value={value.minimumTotal}
                onChange={(e) =>
                  setValue({
                    ...value,
                    minimumTotal: formatMoney(e.target.value),
                  })
                }
                InputProps={{
                  ...{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  },
                }}
                label=""
                sx={{
                  mt: 0.5,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.vendor === '' || value.facility === '' || value.minimumTotal === ''}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            {edit ? 'Save' : 'Add Minimum'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
