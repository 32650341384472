import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Grid, Typography, Box, Card } from '@mui/material';
import { useSelector, useDispatch } from '../../../../../redux/store';
import { CustomAvatar } from '../../../../../reusable-components/custom-avatar';
import CartItemView from './CartItemView';
import { postSubmitOrder } from '../../../../../redux/slices/rentals';

export default function RentalCheckoutView() {
  const { residentId = '' } = useParams();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const dispatch = useDispatch();

  const handleSubmitOrder = async () => {
    const response = await dispatch(postSubmitOrder(cartItems));
    if (response.status === 200) {
      navigate(
        `/dashboard/rentals/new-rental/rental-order-summary?residentId=${residentId}&orderNumber=${response.data}`
      );
    }
  };

  const {
    data: { cart },
  } = useSelector((state) => state.rentals);

  useEffect(() => {
    setCartItems(cart.filter((item) => item.residentId === Number(residentId)));
  }, [residentId, cart]);

  const getNameById = (residentId) => cart.find((res) => res.residentId === parseInt(residentId, 10));
  const residentData = getNameById(residentId);

  const cartResident = residentData ? `${residentData.residentFirstName} ${residentData.residentLastName}` : '';

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ ml: 9, mt: 3, mb: 2, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}
      >
        Checkout {cartResident}
      </Typography>
      <Grid container direction="row">
        <Grid item sx={{ width: '75%' }}>
          <Box key={cartResident}>
            <Card sx={{ width: '90%', display: 'inline-flex', flexDirection: 'column', mb: 4, padding: 3, ml: 8 }}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <CustomAvatar
                      name={cartResident}
                      size="large"
                      showTooltip={false}
                      sx={{
                        height: 40,
                        width: 40,
                        fontSize: 40 / 2,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" sx={{ color: '#707070', fontWeight: 'bold' }}>
                      {cartResident}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <Typography variant="subtitle1" sx={{ color: '#8cc53f' }}>
                      Total: ${cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {cartItems.map(
                (cartItem, ind) =>
                  cartItem.isSelected === true && (
                    <>
                      <CartItemView
                        cartItem={cartItem}
                        key={ind}
                        showDivider={cartItems.length - 1 !== ind}
                        showQuantity
                      />{' '}
                    </>
                  )
              )}
            </Card>
          </Box>{' '}
        </Grid>
        <Grid item sx={{ width: '25%' }}>
          <Grid container direction="column" sx={{ maxWidth: '70%', alignItems: 'center' }}>
            <Card sx={{ padding: 3, alignItems: 'center' }}>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ display: 'inline-flex', textAlign: 'center', color: '#6d6e71' }}>
                  Subtotal: (
                  {cartItems.length
                    ? `${cartItems
                        .filter((item) => item.isSelected)
                        .reduce((acc, item) => acc + item.quantity, 0)} Item${
                        cartItems.reduce((acc, item) => acc + item.quantity, 0) !== 1 ? 's' : ''
                      }`
                    : 'Cart is empty'}
                  ) ${cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'center' }}>
                <Button
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                  }}
                  variant="contained"
                  onClick={() => {
                    handleSubmitOrder();
                  }}
                >
                  Submit Order for Approval
                </Button>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
