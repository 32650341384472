import axios from '../../utils/axios';

// GET  BackgroundTask / TaskData;
export async function getTaskData() {
  const response = await axios.get('BackgroundTask/TaskData');
  return response;
}

// GET  BackgroundTask / ActiveTasks;
export async function getActiveTasks() {
  const response = await axios.get('BackgroundTask/ActiveTasks');
  return response;
}

// POST  BackgroundTask / RunTask; id
export async function runTask(id) {
  const response = await axios.post(`BackgroundTask/RunTask?id=${id}`);
  return response;
}
