import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Checkbox } from '@mui/material';
import { useScopeCheck } from '../../../../../reusable-components/scopes';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { updateUsersRolesInRedux } from '../../../../../redux/slices/users';

TemplateRolesCheckbox.propTypes = {
  row: PropTypes.object,
};

export default function TemplateRolesCheckbox({ row }) {
  const dispatch = useDispatch();
  const {
    data: { user },
  } = useSelector((state) => state.users);
  const { roles } = user;

  const hasEditAccess = useScopeCheck(['User-Edit'], true);

  // const hasRole = roles.some((item) => item.role === row.role);
  const isCheckedLookup = useMemo(() => {
    const lookup = {};
    roles.forEach((item) => {
      lookup[item.id] = true;
    });
    return lookup;
  }, [roles]);

  const handleSwitchActiveRole = (e) => {
    e.stopPropagation();
    if (isCheckedLookup[row.id]) {
      const removeRoles = roles.filter((item) => item.id !== row.id);
      dispatch(updateUsersRolesInRedux(removeRoles));
    } else {
      dispatch(updateUsersRolesInRedux([...roles, row]));
    }
  };

  return (
    <>
      {' '}
      <Checkbox checked={!!isCheckedLookup[row.id]} onChange={handleSwitchActiveRole} disabled={!hasEditAccess} />
    </>
  );
}
