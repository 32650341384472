import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import _ from 'lodash';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled, alpha } from '@mui/material/styles';
import {
  Switch,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Tooltip,
  Box,
  Divider,
} from '@mui/material';
import { useSelector } from '../../../redux/store';
import { useTabs } from '../../../context/TabContext';
import Dnd from '../../DragAndDrop/Dnd';

CustomSettings.propTypes = {
  columnsState: PropTypes.object,
  setColumnsState: PropTypes.func,
  resetGridState: PropTypes.func,
  gridColumns: PropTypes.array,
  dataGridStateStorage: PropTypes.string,
};

export default function CustomSettings({
  columnsState,
  setColumnsState,
  gridColumns,
  resetGridState,
  dataGridStateStorage,
}) {
  const { addNewTabAndNavigate } = useTabs();

  const [anchorElement, setAnchorElement] = useState(null);
  const { user } = useSelector((state) => state.user);
  const { settings } = user;

  const gridPersists = settings?.find((setting) => setting?.areaId === 'GridPersists')?.show ?? true;

  const open = Boolean(anchorElement);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorElement(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorElement(null);
  };

  // const saveToStorage = useCallback(
  //   (currentState) => {
  //     gridPersists && localStorage.setItem(dataGridStateStorage, JSON.stringify(currentState));
  //   },
  //   [dataGridStateStorage, gridPersists]
  // );

  const saveToStorage = (currentState) => {
    if (gridPersists) {
      localStorage.setItem(dataGridStateStorage, JSON.stringify(currentState));
    }
  };   

  const dropItem = useCallback(_.debounce((sourceItem, destinationItem) => {
    setColumnsState(prevState => {
      const sourceItemIndex = prevState?.columns?.orderedFields?.findIndex(item => item === sourceItem?.field);
      const destinationItemIndex = prevState?.columns?.orderedFields?.findIndex(item => item === destinationItem?.field);
      if (sourceItemIndex === -1 || destinationItemIndex === -1) return prevState;
      prevState.columns.orderedFields[sourceItemIndex] = destinationItem.field;
      prevState.columns.orderedFields[destinationItemIndex] = sourceItem.field;
      saveToStorage({ ...prevState });
      return { ...prevState };
    });
  }, 5), []);

  const switchCol = (field, checked) => {
    setColumnsState((prevState) => {
      const newColumns = { ...prevState?.columns };
      newColumns.columnVisibilityModel[field] = checked;
      saveToStorage({
        ...columnsState,
        columns: newColumns,
      });

      return { ...prevState, columns: newColumns };
    });
  };

  return (
    <>
      <Tooltip title="Grid Settings" arrow placement="top">
        <IconButton color="info" sx={{ pb: 0.2, pl: 0.2 }} onClick={handleClick}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>

      <StyledMenu id="basic-menu" anchorEl={anchorElement} open={open} onClose={handleClose}>
        {columnsState && (
          <FormGroup>
            {columnsState?.columns?.orderedFields.map((field, index) => {
              const col = gridColumns.find((col) => col?.field === field);
              return (
                <Dnd key={field} item={col} itemId="field" onDrop={dropItem}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        size="small"
                        checked={columnsState?.columns?.columnVisibilityModel[field] !== false}
                        onChange={(e) => {
                          e.stopPropagation(); // Prevents the switch from toggling when the row is dragged

                          switchCol(field, e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', py: 0.5 }}>
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body1"> {col?.headerName}</Typography>
                      </Box>
                    }
                    sx={{
                      m: 0,
                      width: 1,
                      justifyContent: 'space-between',
                      cursor: 'move', // Indicates this element is draggable
                    }}
                  />
                </Dnd>
              );
            })}
          </FormGroup>
        )}
        <Divider
          sx={{
            mt: 1,
            mb: 1,
          }}
        />

        <MenuItem
          onClick={() => {
            resetGridState();
            handleClose();
          }}
        >
          <SettingsBackupRestoreIcon
            sx={{
              mr: 1.5,
            }}
          />
          <Typography
            variant="body1"
            sx={{
              mr: 1.5,
            }}
          >
            Reset Grid
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            addNewTabAndNavigate(`/dashboard/home/user/${user.id}?tab=settings`);
          }}
        >
          <DisplaySettingsIcon
            sx={{
              mr: 1.5,
            }}
          />
          <Typography variant="body1" sx={{}}>
            Grid Settings{' '}
            <OpenInNewIcon
              sx={{
                mr: 1.5,
              }}
            />
          </Typography>
        </MenuItem>
      </StyledMenu>
    </>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 150,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    border: 'solid 2px #c1c9d0',
    overflow: 'auto',
    '& .MuiMenuItem-root': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
