import { Alert, Badge, Box, Card, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { fDate } from '../../../../utils/formatTime';
import VendorDate from './VendorDate';
import VendorNote from './VendorNote';

VendorInfo.propTypes = {
  allReqDates: PropTypes.array,
  setAllReqDates: PropTypes.func,
};

export default function VendorInfo({ allReqDates, setAllReqDates }) {
  const { id = '' } = useParams();

  const [open, setOpen] = useState(true);
  const [currentVendorId, setCurrentVendorId] = useState(true);
  const { orderById, tempOrderListById = [] } = useSelector((state) => state.orders);

  const { items = [], orderComments = [], requestedShipDate } = orderById[id] || {};

  const handleClick = () => {
    setOpen(!open);
  };

  const getUniqueItemsByVendorId = (items) => {
    const uniqueVendorIds = new Set();
    return items.filter((item) => {
      if (!uniqueVendorIds.has(item.vendorId)) {
        uniqueVendorIds.add(item.vendorId);
        return true;
      }
      return false;
    });
  };

  const uniqueItems = useMemo(
    () => getUniqueItemsByVendorId([...items, ...(tempOrderListById[id] || [])] || []),
    [items, id]
  );

  useEffect(() => {
    if (uniqueItems.length > 0) {
      setCurrentVendorId(uniqueItems[0].vendorId);
    }
  }, [uniqueItems]);

  useEffect(() => {
    if (requestedShipDate) {
      setAllReqDates((prevReqDates) => {
        const newReqDates = uniqueItems
          .filter((i) => !orderById[id].vendorDateMessages.some((m) => m.vendorId === i.vendorId))
          .map((i) => {
            const vendorDate = prevReqDates.some((x) => x.vendorId === i.vendorId)
              ? prevReqDates.find((x) => x.vendorId === i.vendorId)?.date
              : new Date(requestedShipDate);
            return {
              vendorId: i.vendorId,
              date: vendorDate,
            };
          });

        return [...newReqDates];
      });
    }
  }, [orderById]);

  return (
    <>
      <Box sx={{ minWidth: '300px', width: '100%' }}>
        <Card sx={{ p: 1, width: '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="info.main">
              Vendors
            </Typography>          
            <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer' }} color="info.main">
              <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
            </Typography>        
            {requestedShipDate && (
              <Grid container sx={{ width: '100%' }} justifyContent="center">
                <Alert sx={{ width: '100%', height: '35px', fontSize: 12, py: 0, px: 1, m: 0 }} severity="info">
                  User requested ship date: {fDate(requestedShipDate)}
                </Alert>
              </Grid>
            )}
          </Grid>
          {open && (
            <>             
              <Box>
                {uniqueItems.map((item) => 
                  <Badge
                    key={item.vendorId}
                    color="primary"
                    variant="dot"
                    invisible={
                      !orderComments.find((c) => c.vendorIds?.includes(item.vendorId)) ||
                      currentVendorId === item.vendorId
                    }
                  >
                    <Typography
                      component="span"
                      sx={{
                        ml: 1,
                        textDecoration: currentVendorId === item.vendorId ? 'underline' : 'none',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                        fontWeight: currentVendorId === item.vendorId ? 'bold' : 'normal',
                      }}
                      variant="subtitle2"
                      color="info.main"
                      onClick={() => {
                        setCurrentVendorId(item.vendorId);
                      }}
                    >
                      {item.vendor}
                    </Typography>
                  </Badge>
                )}
                <Divider
                  sx={{
                    mb: 1,
                    width: '100%',
                  }}
                />
              </Box>            
              <Box>
                {uniqueItems.filter(item => currentVendorId === item.vendorId).map((item) => (             
                  <Box key={item.vendorId}>
                    <VendorDate item={item} allReqDates={allReqDates} setAllReqDates={setAllReqDates} />
                    <VendorNote item={item} />
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
