import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from '../../../../redux/store';

export default function ProcessManualInvoices() {
  const navigate = useNavigate();
  const {
    data: { manualInvoicing },
  } = useSelector((state) => state.invoicing);
  return (
    <Button
      variant="contained"
      size="small"
      color="secondary"
      disabled={manualInvoicing?.length === 0}
      onClick={() => {
        const nextId = manualInvoicing?.[0]?.id;
        navigate(`/dashboard/invoicing/new/${nextId}`);
      }}
    >
      Process all
    </Button>
  );
}
