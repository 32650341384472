import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { getOrderSheet } from '../../../../redux/slices/orderSheet';
import { ShopView, CatalogView } from '../../../../components/dashboard/orders/new-order';

export default function Shop() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('categoryId') || null;
  const dispatch = useDispatch();
  const { facilityId = '' } = useParams();
  const {
    data: { agoraCategory, items, hasNoItems, currFacilityId },
  } = useSelector((state) => state.orderSheet);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (agoraCategory?.id !== parseInt(id, 10) || currFacilityId !== facilityId || (!items.length && !hasNoItems)) {
      setData([]);
      dispatch(getOrderSheet(id, facilityId));
    }
  }, [id, agoraCategory, items, currFacilityId, facilityId, hasNoItems, dispatch]);

  useEffect(() => {
    setData(items.map(item => ({ ...item, quantity: null })));
  }, [items]);

  return (
    <>
      <Helmet>
        <title>New Order | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {id === '-1' ? <CatalogView data={data} setData={setData} /> : <ShopView data={data} setData={setData} />}
        </Grid>
      </Grid>
    </>
  );
}
