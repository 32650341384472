/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  TextField,
  Typography,
  Box,
  Button,
  Autocomplete,
  Grid,
  DialogActions,
} from '@mui/material';
import { uploadStatement } from '../../../api/invoicing';
import Iconify from '../../../reusable-components/iconify';
import { useSnackbar } from '../../../reusable-components/snackbar';
import FileThumbnail from '../../../reusable-components/file-thumbnail';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { getStatementsInvoicing } from '../../../redux/slices/invoicing';
import { UPLOAD_STATEMENTS } from '../../../reusable-components/datagrid/invoiceColumns';
import { useSelector, useDispatch } from '../../../redux/store';
import { AwaitButton } from '../../../reusable-components/await-button';

export default function UploadStatementPDF() {
  const {
    data: { statements },
  } = useSelector((state) => state.invoicing);

  const isLoading = useSelector((state) => state.invoicing.isLoading);
  const dispatch = useDispatch();

  const { vendors } = statements;

  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadData, setUploadData] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'application/pdf': [],
    },
  });

  const handleClickOpen = () => {
    setPdfFile(null);
    setOpen(true);
  };
  const handleClose = () => {
    setPdfFile(null);
    setOpen(false);
    setShowUploadModal(false);
    setUploadData([]);
  };

  const handleFileInputChange = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setPdfFile(files[0]);
    }
  };

  const handleRemoveImage = () => {
    setPdfFile(null);
  };

  const upload = async () => {
    const response = await uploadStatement(vendorId, pdfFile);
    if (response.status === 200) {
      setUploadData(response.data);
      setShowUploadModal(true);
      dispatch(getStatementsInvoicing());
    } else {
      enqueueSnackbar('Error uploading PDF', { variant: 'error' });
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="mdi:tray-arrow-up" />}
        color="secondary"
      >
        Upload Statement
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Upload Statement to Vendor
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Vendor
            </Typography>
            <Autocomplete
              fullWidth
              size="small"
              onChange={(event, newValue) => {
                if (newValue) setVendorId(newValue.value);
              }}
              options={vendors}
              renderInput={(params) => <TextField {...params} label="Search or select" />}
              sx={{
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
            {pdfFile && (
              <Grid item xs={2}>
                <Box
                  m={1}
                  boxShadow={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FileThumbnail key={pdfFile} file={pdfFile} tooltip sx={{ width: 32, height: 32 }} />

                  <IconButton onClick={() => handleRemoveImage()}>
                    <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>
          <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
            <input {...getInputProps()} />
            <Paper
              elevation={isDragActive ? 6 : 1}
              sx={{
                p: 2,
                mt: 4,
                bgcolor: 'background.paper',
                color: 'info.main',
                borderColor: 'info.main',
                border: 1,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  bgcolor: 'background.default',
                  boxShadow: 3,
                },
              }}
            >
              <Typography variant="body2" textAlign="center">
                Click to Upload or Drag and Drop
              </Typography>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={!pdfFile || !vendorId}
            sx={{ width: 150 }}
            onClick={upload}
          >
            Upload
          </AwaitButton>
        </DialogActions>
      </Dialog>
      {/* ////showUploadModal//// */}
      <Dialog open={showUploadModal} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {uploadData?.file}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CustomDataGrid
            gridId="invoicing-upload-statement-pdf"
            boxSX={{ width: '100%', height: 'calc(100vh - 250px)' }}
            data={uploadData?.invoiceStatementLines || []}
            gridColumns={UPLOAD_STATEMENTS}
            isLoading={isLoading}
            isModal
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        accept="pdf/*"
        onChange={handleFileInputChange}
      />
    </>
  );
}
