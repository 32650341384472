import { updateCartItemInRedux } from '../redux/slices/cart';
import { useDispatch } from '../redux/store';

export const useUpdateCartItemRedux = () => {
  const dispatch = useDispatch();

  return (id, field, value) => {
    dispatch(updateCartItemInRedux(id, field, value));
  };
};
