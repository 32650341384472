import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import {
  Autocomplete, Box, Button, Dialog, DialogActions,
  DialogContent,
  DialogContentText, DialogTitle, Grid, IconButton, TextField, Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { addProductToOrderGuides } from '../../../../api/catalog';
import { getProductOrderGuide } from '../../../../redux/slices/catalog';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../../reusable-components/iconify';

OrderHistoryDialog.propTypes = {
  productId: PropTypes.number,
  agoraCategoryId: PropTypes.number,
};

export default function OrderHistoryDialog({ productId, agoraCategoryId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const { data: { productOrderGuide, agoraCategories } } = useSelector((state) => state.catalog);
  const isLoading = useSelector((state) => state.catalog.isLoading);
  const [value, setValue] = useState(agoraCategories.filter((item) => agoraCategoryId === item.id)[0]);

  useEffect(() => {
    setData(productOrderGuide.orderGuides);
    setSelected(productOrderGuide.orderGuides?.filter(og => og.hasItem)?.map(og => og.orderGuideId) ?? [])
  }, [productOrderGuide.orderGuides]);

  useEffect(() => {
    if (open) {
      dispatch(getProductOrderGuide(productId, agoraCategoryId));
    }
  }, [agoraCategoryId, dispatch, open, productId]);

  const handleClickOpen = (e) => {
    e.addToOrderGuide = true;
    setOpen(true);
  };

  const handleClose = () => {
    setData([]);
    setOpen(false);
  };

  const handleSave = async () => {
    const response = await addProductToOrderGuides(selected, productId, value.id);
    if (response.status === 200) {
      handleClose();
    }
  };

  const handleChangeCategory = (newValue) => {
    setValue(newValue);
    dispatch(getProductOrderGuide(productId, newValue.id));
  };

  const ORDER_GUIDE = [
    {
      field: 'facility',
      headerName: 'Facility',
      flex: 2,
      renderCell: (params) => (
        <Box
          component="div"
          sx={{
            fontSize: '0.7rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
        >
          {params.value}
        </Box>
      ),
      type: 'customSelect',
    },
    {
      field: 'company',
      headerName: 'Group',
      flex: 2,
      renderCell: (params) => (
        <Box
          component="div"
          sx={{
            fontSize: '0.7rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
        >
          {params.value}
        </Box>
      ),
      type: 'customSelect',
    },
  ];
  const CustomLeftToolbar = () => (
    <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Add/remove a facility</DialogContentText>
  );

  return (
    <div>
      <Tooltip title="Add To Order Guide" placement="bottom" arrow>
        <IconButton color="info" size="small" onClick={handleClickOpen}>
          <AddToQueueIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Add To Order Guides
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <DialogContentText sx={{ fontWeight: 'bold' }}>Select a category</DialogContentText>{' '}
          <Autocomplete
            sx={{
              mt: 1,
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabelRoot ': 'none',
            }}
            type="new-password"
            size="small"
            disableClearable
            options={agoraCategories.filter((item) => productOrderGuide.agoraCategories.includes(item.id))}
            autoHighlight
            getOptionLabel={(option) => option?.name}
            getOptionSelected={(option, value) => option.name === value.name}
            value={value}
            onChange={(event, newValue) => {
              handleChangeCategory(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name=""
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            )}
          />
          <Grid item xs={12} sx={{ mt: 1 }}>
            <CustomDataGrid
              boxSX={{ width: '100%', height: 'calc(100vh - 310px)' }}
              checkboxSelection
              CustomLeftToolbar={CustomLeftToolbar}
              data={data}
              displayCheckboxRight
              forceReRender={data}
              getRowId={(row) => row.orderGuideId}
              getRowClassName={(params) => (params.row.facilityRestricted ? 'disabled' : '')}
              gridColumns={ORDER_GUIDE}
              gridId="admin-catalog-order-guide"
              isModal
              isLoading={isLoading}
              keepNonExistentRowsSelected
              onRowSelectionModelChange={(newSelection) => {
                const newOrderGuidesWithOutDisabled = data?.filter((item) => !item.facilityRestricted)
                  .map((item) => ({ ...item, hasItem: newSelection.includes(item.orderGuideId) }));
                setData(newOrderGuidesWithOutDisabled);
                setSelected(newSelection);
              }}
              rowSelectionModel={selected}
              sort={[{ field: 'facility', sort: 'asc' }]}
              sx={{
                '& .disabled': {
                  pointerEvents: 'none',
                  color: 'rgba(0, 0, 0,0.65)',
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave}>
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
