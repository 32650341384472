// src/utils/cancelToken.js
import axios from 'axios';

const cancelTokenMap = new Map();

const getBaseUrl = (url) => {
  const urlObj = new URL(url, window.location.origin);
  return `${urlObj.origin}${urlObj.pathname}`;
};

export const getCancelTokenSource = (key) => {
  if (cancelTokenMap.has(key)) {
    const cancelTokenSource = cancelTokenMap.get(key);
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }
  const newCancelTokenSource = axios.CancelToken.source();
  cancelTokenMap.set(key, newCancelTokenSource);
  return newCancelTokenSource;
};

export const removeCancelTokenSource = (key) => {
  cancelTokenMap.delete(key);
};

export const isRequestCanceled = (error) => {
  return axios.isCancel(error);
};

export const generateRequestKey = (config) => {
  const baseURL = getBaseUrl(config.url);
  return `${config.method}:${baseURL}`;
};
