import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Button } from '@mui/material';
import { useSelector } from '../../../../redux/store';
import { FACILITIES_COLUMNS } from '../../../../reusable-components/datagrid/censusColumns';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import CensusModal from './CensusModal';
import { getFacilitys } from '../../../../redux/slices/census';
import { HOST_API_URL } from '../../../../config-global';
import Iconify from '../../../../reusable-components/iconify';

export default function CensusView() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const [open, setOpen] = useState(null);

  const {
    data: { facilities },
  } = useSelector((state) => state.census);
  const isLoading = useSelector((state) => state.census.isLoading);

  useEffect(() => {
    dispatch(getFacilitys());
  }, [dispatch]);

  useEffect(() => {
    if (facilities?.length > 0) {
      setData(facilities);
    }
  }, [facilities]);

  const downloadCensus = async () => {
    window.open(`${HOST_API_URL}Api/Admin/FacilityCensus/DownloadFacilityCensus`, '_blank');
  };

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Box sx={{ display: 'inline-block', mr: 1 }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 1 }}
            size="small"
            onClick={downloadCensus}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download
          </Button>
        </Box>
      </Grid>
    ),
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-census-view"
          boxSX={{ width: '100%', height: 'calc(100vh - 220px)' }}
          data={data}
          gridColumns={FACILITIES_COLUMNS}
          sort={[{ field: 'facility', sort: 'asc' }]}
          id={'facilityId'}
          onRowClick={(params) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            setOpen(params.row.facilityId);
          }}
          enableRowClick
          isLoading={isLoading}
          CustomLeftToolbar={CustomLeftToolbar}
        />
        <CensusModal open={!!open} setOpen={setOpen} facilityId={open} />
      </Grid>
    </>
  );
}
