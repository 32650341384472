import { Tooltip, styled, tooltipClasses } from '@mui/material';

const CustomWidthTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))
  ({
    [`& .${tooltipClasses.tooltip}`]: {
      minWidth: 500,
      maxWidth: 500,
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: "1px solid rgb(193, 201, 208)"
      },
      width: "17px !important",
      height: "24px !important",
      color: "white"
    },
    [`&[data-popper-placement*="left"] .${tooltipClasses.arrow}`]: {
      marginRight: "-17px !important",
    },
    [`&[data-popper-placement*="right"] .${tooltipClasses.arrow}`]: {
      left: "-9px",
    },
    [`&[data-popper-placement*="bottom"]  .${tooltipClasses.arrow}`]: {
      marginTop: "-17px !important"
    },
    [`&[data-popper-placement*="top"] .${tooltipClasses.tooltipPlacementTop} .${tooltipClasses.arrow}`]: {
      marginBottom: "-17px !important"
    },
  });

export default CustomWidthTooltip;