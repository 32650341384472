import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddManagerModal from '../../components/dashboard/orders/AddManagerModal';
import { removeManager } from '../../api/facility-manager';

ManagerChips.propTypes = {
  params: PropTypes.any,
  onDeleteSuccess: PropTypes.func,
  type: PropTypes.string,
  hasFacilityAccess: PropTypes.bool
};

export default function ManagerChips({ params, onDeleteSuccess, type, hasFacilityAccess }) {
  const [submit, setSubmit] = useState(false);
  const [facilityManagerAssignments, setFacilityManagerAssignments] = useState(params.row.facilityManagerAssignments);

  useEffect(() => setFacilityManagerAssignments(params.row.facilityManagerAssignments), [params.row])

  const handleDeleteManager = async (manager) => {
    if (submit) return;
    try {
      setSubmit(true);
      const response = await removeManager(manager.modelId, type, manager.facilityManagerId);
      if (response.status === 200) {
        setFacilityManagerAssignments(fma => fma.filter(x => x.facilityManagerId !== manager.facilityManagerId));
        params.api.updateRows([{
          ...params.row,
          facilityManagerAssignments: params.row.facilityManagerAssignments.filter(x => x.facilityManagerId !== manager.facilityManagerId)
        }]);
        if (onDeleteSuccess) await onDeleteSuccess();
      }
    }
    finally { setSubmit(false); }
  };

  return <Box
    component="div"
    sx={{
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& .MuiChip-deleteIcon': {
        display: 'none',
      },
      '&:hover .MuiChip-deleteIcon': {
        display: 'block',
      },
      '&:hover .add-manager-modal-button': {
        visibility: 'visible',
        opacity: 1,
      },
    }}
  >
    {!submit && facilityManagerAssignments.map((manager, index) =>
        hasFacilityAccess ? (
          <Chip
            key={index}
            label={manager.manager}
            variant="filled"
            onDelete={onDeleteSuccess == null ? null : async (event) => {
              event.deleteChip = true;
              await handleDeleteManager(manager);
            }}
            color={manager.currentUser ? 'info' : 'primary'}
            sx={{
              opacity: manager.deletedManager ? .5 : 1,
              marginRight: 1,
              border: () => `solid 1px`,
            }}
            size="small"
          />
        ) : (
          <Box
            key={index}
            sx={{
              px: 0.5,
              marginRight: 0.5,
              border: () => `solid 1px`,
              color: 'primary.main',
              borderRadius: '50px',
            }}
          >
            {manager.manager}
          </Box>
        )
      )}
    {submit && <LoadingButton loading={submit} />}
    {hasFacilityAccess && onDeleteSuccess != null && <AddManagerModal model={params.row} type={type} missingFacility={params.row.facilityId === 0} />}
  </Box>
}

