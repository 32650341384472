import { createSlice } from '@reduxjs/toolkit';
import { cartCheckApiFromArrayOfObj } from '../../api/cart';

import {
  getAddToOrderListData,
  getOrdersListData,
  getOrdersUnreadData,
  getPendingOrderInfoData,
  getPendingOrdersData,
  getPreviousOrderInfoData,
  getPreviousOrderInfoDataByNumber,
  getPreviousOrdersData,
  postUpdateOrderGuidePrices,
  changeBudgetDate,
  saveManualBudgetItemData,
  setReviewOrder,
} from '../../api/orders';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  cartChecked: false,
  firstTime: true,
  cartDialog: false,
  openErrorDialog: false,
  error: null,
  data: {
    pendingOrders: [],
    previousOrders: [],
  },
  orderDateRange: {
    startDate: null,
    endDate: null,
  },
  ordersList: [],
  orderById: {},
  cartCheckItemsById: {},
  addToOrderListById: [],
  catalogReplacementList: [],
  tempOrderListById: {},
  unreadOrders: 0,
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    clearItemData(state) {
      state.orderById = {};
      state.cartCheckItemsById = {};
      state.addToOrderListById = {};
      state.tempOrderListById = {};
    },
    clearItemDataById(state, action) {
      const { id } = action.payload;
      if (state.orderById?.[id]) {
        state.orderById[id] = {};
      }
    },

    startCart(state) {
      state.cartDialog = true;
      state.cartChecked = true;
    },
    closeDialog(state) {
      state.openErrorDialog = false;
    },
    openDialog(state) {
      state.openErrorDialog = true;
    },
    removeCartChecked(state) {
      state.cartChecked = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getOrdersListSuccess(state, action) {
      state.isLoading = false;
      state.data.ordersList = action.payload;
    },
    getPendingOrdersSuccess(state, action) {
      state.isLoading = false;
      state.data.pendingOrders = action.payload;
    },

    getPreviousOrdersSuccess(state, action) {
      state.isLoading = false;
      state.data.previousOrders = action.payload;
    },

    updateOrderDateRange(state, action) {
      state.orderDateRange = action.payload;
    },
    updateBudgetDateSuccess(state, action) {
      const { orderId, budgetDate } = action.payload;
      state.orderById[orderId].budgetDate = budgetDate;
    },
    saveManualBudgetItemDataSuccess(state, action) {
      const { orderId, budget } = action.payload;
      const { itemQuantityId, unitPrice, qty, addedBy } = budget;

      state.isLoading = false;
      const { orderById } = state;
      const item = orderById[orderId]?.items.find((x) => x.id === itemQuantityId);
      item.budgetPrice = unitPrice;
      item.budgetQty = qty;
      item.budgetSource = 'MANUAL';
      item.budgetSourceData = [addedBy];
    },
    getOrdersInfoSuccessNoCartCheck(state, action) {
      const { id, items } = action.payload;
      items.forEach((item) => {
        if (item.deleted) {
          item.approved = false;
        }
      });
      state.isLoading = false;
      state.orderById[id] = action.payload;
    },
    getOrdersInfoSuccess(state, action) {
      const { id, items } = action.payload;
      state.firstTime = true;
      items.forEach((item) => {
        if (item.deleted) {
          item.approved = false;
        }
      });
      state.isLoading = false;
      state.orderById[id] = action.payload;
    },
    getRerunOrdersInfoSuccess(state, action) {
      const { id, items, vendorId } = action.payload;
      items.forEach((item) => {
        if (vendorId !== '0') item.deleted = item.vendorId !== parseInt(vendorId, 10);
        if (item.deleted) {
          item.approved = false;
        }
      });
      state.isLoading = false;
      state.orderById[id] = action.payload;
    },

    getCompletedOrdersSuccess(state, action) {
      state.isLoading = false;
      const { orderId, data } = action.payload;
      state.orderById[orderId] = data;
    },

    getAddToOrderSuccess(state, action) {
      state.isLoading = false;
      const { orderId, data } = action.payload;
      state.addToOrderListById[orderId] = data;
    },

    getReplacementSuccess(state, action) {
      state.isLoading = false;
      state.catalogReplacementList = action.payload;
    },

    updateOrder(state, action) {
      const { orderId, id, field, value } = action.payload;
      state.orderById[orderId].unsavedChanges = true;
      const { orderById } = state;
      const order = orderById[orderId];
      const { items } = order;
      const item = items.find((item) => item.id === id);
      item[field] = value;
      state.orderById[orderId] = order;
      state.isLoading = false;
    },

    addItemToOrder(state, action) {
      const { orderById } = state;
      const { item } = action.payload;
      state.orderById[item.orderId].unsavedChanges = true;
      const order = orderById[item.orderId];
      const { items } = order;
      const existingItemIndex = items?.findIndex((existing) => existing.id === item.id);

      if (existingItemIndex !== -1) {
        if (item.quantity > 0) {
          items[existingItemIndex] = { ...items[existingItemIndex], ...item };
        } else {
          items?.splice(existingItemIndex, 1);
        }
      } else if (item.quantity > 0) {
        items.push(item);
      }

      state.orderById[item.orderId].items = items;
      state.isLoading = false;
    },

    addArrayOfItems(state, action) {
      const { arrayOfItems, orderId } = action.payload;
      const { orderById } = state;
      const order = orderById[orderId];
      const { items } = order;

      arrayOfItems.forEach((item) => {
        const existingItemIndex = items?.findIndex((existing) => existing.id === item.id);

        if (existingItemIndex !== -1) {
          if (item.quantity > 0) {
            items[existingItemIndex] = { ...items[existingItemIndex], ...item };
          } else {
            items?.splice(existingItemIndex, 1);
          }
        } else if (item.quantity > 0) {
          items.push(item);
        }
      });

      state.orderById[orderId].items = items;
      state.orderById[orderId].unsavedChanges = true;
      state.isLoading = false;
    },

    removeItemFromCartCheck(state, action) {
      const { orderId, id } = action.payload;
      const { cartCheckItemsById } = state;
      const cartCheckItems = cartCheckItemsById[orderId];
      const existingItemIndex = cartCheckItems?.findIndex((existing) => existing.id === id);
      if (existingItemIndex !== -1) {
        cartCheckItems?.splice(existingItemIndex, 1);
      }
      state.cartCheckItemsById[orderId] = cartCheckItems;
      state.openErrorDialog = cartCheckItems?.some((item) => item.errors?.length > 0);
      state.isLoading = false;
    },

    addTempItemToOrder(state, action) {
      const { item } = action.payload;
      const { tempOrderListById } = state;
      const tempOrderList = tempOrderListById[item.orderId] || [];

      const existingItemIndex = tempOrderList?.findIndex((existing) => existing.id === item.id);

      if (existingItemIndex !== -1) {
        if (item.quantity > 0) {
          tempOrderList[existingItemIndex] = { ...tempOrderList[existingItemIndex], ...item };
        } else {
          tempOrderList?.splice(existingItemIndex, 1);
        }
      } else if (item.quantity > 0) {
        tempOrderList.push(item);
      }
      state.tempOrderListById[item.orderId] = tempOrderList;
      state.isLoading = false;
    },

    clearTempItemData(state, action) {
      const { orderId } = action.payload;
      state.tempOrderListById[orderId] = [];
      state.isLoading = false;
    },

    updateMessageCount(state, action) {
      const { id } = action.payload;
      const { data } = state;
      const { pendingOrders, previousOrders } = data;
      const pendingOrderIndex = pendingOrders.findIndex((order) => order.id === id);
      const previousOrderIndex = previousOrders.findIndex((order) => order.id === id);
      if (pendingOrderIndex !== -1) {
        pendingOrders[pendingOrderIndex].messageCount = 1;
      }
      if (previousOrderIndex !== -1) {
        previousOrders[previousOrderIndex].messageCount = 1;
      }
      state.data.pendingOrders = pendingOrders;
      state.data.previousOrders = previousOrders;
      state.isLoading = false;
    },

    addLinkedTickets(state, action) {
      const { orderId, idToAdd } = action.payload;
      state.orderById[orderId].linkedTickets = [...state.orderById[orderId]?.linkedTickets, idToAdd];
      state.isLoading = false;
    },

    updateCatalogQty(state, action) {
      const { row } = action.payload;
      const { addToOrderListById } = state;
      const addToOrderList = addToOrderListById[row.orderId];
      const existingItemIndex = addToOrderList?.findIndex((existing) => existing.id === row.id);
      if (existingItemIndex !== -1) {
        addToOrderList[existingItemIndex] = { ...addToOrderList[existingItemIndex], ...row };
      }
      state.addToOrderListById[row.orderId] = addToOrderList;
      state.isLoading = false;
    },

    updateItemsWithPrice(state, action) {
      const { items, orderId } = action.payload;
      const { cartCheckItemsById } = state;
      const cartCheckItems = cartCheckItemsById[orderId];
      const { items: orderItems } = state.orderById[orderId];
      items.forEach((item) => {
        const existingItemIndex = cartCheckItems?.findIndex((existing) => existing.vendorItemId === item.vendorItemId);
        if (existingItemIndex !== -1) {
          cartCheckItems?.splice(existingItemIndex, 1);
        }
        const orderItemIndex = orderItems?.findIndex((existing) => existing.vendorItemId === item.vendorItemId);
        if (orderItemIndex !== -1) {
          orderItems[orderItemIndex] = { ...orderItems[orderItemIndex], price: item.updatedPrice };
        }
      });

      state.cartCheckItemsById[orderId] = cartCheckItems;
      state.openErrorDialog = cartCheckItems?.some((item) => item.errors?.length > 0);
      state.isLoading = false;
    },

    updateOrderComments(state, action) {
      const { orderById } = state;
      const { commentArray, orderId } = action.payload;
      const order = orderById[orderId];
      order.orderComments = commentArray;
      state.orderById[orderId] = order;
      state.isLoading = false;
    },

    updateCartWithVendorData(state, action) {
      const { data, orderId } = action.payload;
      const { items: incomingItems } = data;

      if (state.firstTime) {
        state.firstTime = false;
      } else {
        const hasItemError = incomingItems.some((item) => item.errors && item.errors.length > 0);
        state.openErrorDialog = hasItemError;
      }

      state.cartCheckItemsById[orderId] = incomingItems;
      state.isLoading = false;
    },

    getUnreadOrdersCountSuccess(state, action) {
      const { unreadOrders } = action.payload;
      state.isLoading = false;
      state.unreadOrders = unreadOrders;
    },

    removeGlobalNote(state, action) {
      const { orderId, vendorId } = action.payload;
      state.orderById[orderId].removeGlobalNoteFromVendors = [...(state.orderById[orderId].removeGlobalNoteFromVendors ?? []), vendorId];
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getPendingOrders() {
  return async (dispatch) => {
    // const { data } = getState().orders;
    // if (data.pendingOrders.length > 0) {
    //   return;
    // }
    dispatch(slice.actions.clearItemData());
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPendingOrdersData();
      dispatch(slice.actions.getPendingOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPreviousOrders({ startDate, endDate }) {
  startDate = new Date(startDate).toISOString().split('T')[0];
  endDate = new Date(endDate).toISOString().split('T')[0];
  return async (dispatch) => {
    dispatch(slice.actions.clearItemData());
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPreviousOrdersData({ startDate, endDate });
      dispatch(slice.actions.getPreviousOrdersSuccess(response.data));
      dispatch(slice.actions.updateOrderDateRange({ startDate, endDate }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrdersList(facilityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getOrdersListData(facilityId);
      dispatch(slice.actions.getOrdersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAddToOrderList(id, facilityId, categoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getAddToOrderListData(facilityId, categoryId);
      dispatch(slice.actions.getAddToOrderSuccess({ orderId: id, data: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCatalogReplacementListData(facilityId, categoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getAddToOrderListData(facilityId, categoryId);
      dispatch(slice.actions.getReplacementSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function saveBudgetBreakdownData(id, itemQuantityId, unitPrice, qty) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await saveManualBudgetItemData(itemQuantityId, unitPrice, qty);
      dispatch(slice.actions.saveManualBudgetItemDataSuccess({ orderId: id, budget: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPreviousOrderInfo(orderId) {
  return async (dispatch) => {
    await dispatch(slice.actions.clearItemDataById({ id: orderId }));
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPreviousOrderInfoData(orderId);
      dispatch(slice.actions.getCompletedOrdersSuccess({ orderId, data: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeCartCheckedFromRedux() {
  return async (dispatch) => {
    dispatch(slice.actions.removeCartChecked());
  };
}

export function getPreviousOrderInfoByNumber(orderId) {
  return async (dispatch) => {
    await dispatch(slice.actions.clearItemDataById({ id: orderId }));
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPreviousOrderInfoDataByNumber(orderId);
      dispatch(slice.actions.getCompletedOrdersSuccess({ orderId: response.data.id, data: response.data }));
      return response.data.id;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return null;
  };
}

export function getPendingOrderInfo(orderId, skipCartCheck = false) {
  return async (dispatch) => {
    // await dispatch(slice.actions.clearItemDataById({ id: orderId }));
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPendingOrderInfoData(orderId);
      if (response.status === 200) {
        if (skipCartCheck) {
          dispatch(slice.actions.getOrdersInfoSuccessNoCartCheck(response.data));
        } else {
          dispatch(slice.actions.getOrdersInfoSuccess(response.data));
        }
        dispatch(getUnreadOrderCount());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getRerunOrderInfo(orderId, vendorId) {
  return async (dispatch) => {
    await dispatch(slice.actions.clearItemDataById({ id: orderId }));
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPendingOrderInfoData(orderId, vendorId);
      if (response.status === 200) {
        dispatch(slice.actions.getRerunOrdersInfoSuccess({ ...response.data, vendorId }));
        dispatch(getUnreadOrderCount());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cartCheckWithData(orderId, facilityId, data) {
  const approvedItems = data?.filter((item) => item.approved && !item.deleted);

  return async (dispatch, getState) => {
    try {
      const response = await cartCheckApiFromArrayOfObj({ carts: approvedItems, facilityId });
      dispatch(slice.actions.updateCartWithVendorData({ data: response.data, orderId }));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return true;
    }
  };
}

export function updateOrderItemInRedux(orderId, id, field, value) {
  return async (dispatch) => {
    dispatch(slice.actions.updateOrder({ orderId, id, field, value }));
  };
}

export function removeItemFromErrorList(orderId, id) {
  return async (dispatch) => {
    dispatch(slice.actions.removeItemFromCartCheck({ orderId, id }));
  };
}

export function addItemToAnOrderInRedux(item) {
  return async (dispatch) => {
    dispatch(slice.actions.addItemToOrder({ item }));
  };
}

export function updatePrice(orderId, facilityId, items) {
  return async (dispatch, getState) => {
    try {
      await postUpdateOrderGuidePrices({ facilityId, items });
      dispatch(slice.actions.updateItemsWithPrice({ orderId, items }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addItemsToAnOrderInRedux({ orderId, arrayOfItems }) {
  return async (dispatch) => {
    dispatch(slice.actions.addArrayOfItems({ orderId, arrayOfItems }));
  };
}
export function addTempItemToAnOrderInRedux(item) {
  return async (dispatch) => {
    dispatch(slice.actions.addTempItemToOrder({ item }));
  };
}
export function updateCatalogQtyInRedux(row) {
  return async (dispatch) => {
    dispatch(slice.actions.updateCatalogQty({ row }));
  };
}

export function updateOrderCommentsInRedux({ orderId, commentArray }) {
  return async (dispatch) => {
    dispatch(slice.actions.updateOrderComments({ orderId, commentArray }));
  };
}

export function clearTempOrderList(id) {
  return async (dispatch) => {
    dispatch(slice.actions.clearTempItemData({ orderId: id }));
  };
}
export function clearOrderState() {
  return async (dispatch) => {
    dispatch(slice.actions.clearItemData());
  };
}

export function addLinkedTicketsToOrder({ orderId, idToAdd }) {
  return async (dispatch) => {
    dispatch(slice.actions.addLinkedTickets({ orderId, idToAdd }));
  };
}

export function updateMessageCountById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.updateMessageCount({ id }));
  };
}

export function getUnreadOrderCount() {
  return async (dispatch) => {
    try {
      const response = await getOrdersUnreadData();
      dispatch(slice.actions.getUnreadOrdersCountSuccess({ unreadOrders: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setUnreadOrderCount(unreadOrderCount) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getUnreadOrdersCountSuccess({ unreadOrders: unreadOrderCount }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBudgetDate(id, budgetDate) {
  return async (dispatch) => {
    try {
      const response = await changeBudgetDate(id, budgetDate.toISOString());
      if (response.status === 200 && response.data === true) {
        dispatch(slice.actions.updateBudgetDateSuccess({ orderId: id, budgetDate }));
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return null;
  };
}

export function closeErrorDialog() {
  return async (dispatch) => {
    dispatch(slice.actions.closeDialog());
  };
}

export function openErrorDialog() {
  return async (dispatch) => {
    dispatch(slice.actions.openDialog());
  };
}

export function removeGlobalNote(orderId, vendorId) {
  return async (dispatch) => {
    dispatch(slice.actions.removeGlobalNote({ orderId, vendorId }));
  };
}
