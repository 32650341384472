import PropTypes from 'prop-types';
import { Typography, Box, Grid } from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import { fMonthDayYearShort } from '../../utils/formatTime';

FormDateWithValue.propTypes = {
  value: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  flex: PropTypes.number,
  required: PropTypes.bool,
};

export default function FormDateWithValue({ value, title, name, flex, size = 'small', required = false }) {
  return (
    <>
      <Grid item xs={flex}>
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
            {title}
          </Typography>
          {/* <DatePicker
            error={required ? !value[name] : false}
            size={size}
            fullWidth
            name={name}
            disabled
            value={value[name] ? new Date(value[name]) : null}
            sx={{
              lineHeight: '23px !important',
            }}
          /> */}
          <DateField
            error={required ? !value[name] : false}
            size={size}
            fullWidth
            name={name}
            disabled
            // defaultValue={value[name] ? new Date(value[name]) : null}
            value={value[name] ? new Date(value[name]) : null}
            format="MMMM dd, yyyy"
          />
        </Box>
      </Grid>
    </>
  );
}
