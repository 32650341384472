import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/EmailRecipient/EmailRecipients
export async function getEmailRecipientsData() {
  const response = await axios.get('/EmailRecipient/EmailRecipients');
  return response;
}

// GET
// /Api/EmailRecipient/EmailRecipientTypes
export async function getEmailRecipientTypesData(siteId) {
  const response = await axios.get(`/EmailRecipient/EmailRecipientTypes?siteId=${siteId}`);
  return response;
}

// POST
// /Api/EmailRecipient/EmailRecipientForm form
export async function postEmailRecipientForm(form) {
  const updatedForm = {
    Email: form.email, //string
    Id: form.id,//int
    EmailRecipientTypeId: form.emailRecipientType,//int
    SendTypeId: form.sendType,//int
    SiteId: form?.siteId, //int
  };

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('EmailRecipient/EmailRecipientForm', formData);
  return response;
}

// POST
// /Api/EmailRecipient/DeleteEmailRecipient
export async function deleteEmailRecipientData(id, siteId) {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('siteId', siteId);
  const response = await axios.post('/EmailRecipient/DeleteEmailRecipient', formData);
  return response;
}

