import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { VendorCatalogView } from '../../../components/dashboard/admin/vendor-catalog';

// ----------------------------------------------------------------------

export default function VendorCatalog() {
  return (
    <>
      <Helmet>
        <title>Admin Vendor Catalog | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <VendorCatalogView />
        </Grid>
      </Grid>
    </>
  );
}
