import { PendingOrders, Invoicing } from './index';
import { ScopeGuard } from '../../../reusable-components/scopes';
// ----------------------------------------------------------------------

export default function DashboardDataView() {
  return (
    <>
      <ScopeGuard scopes={['View-Order']} allowAdmin>
        <PendingOrders />
      </ScopeGuard>
      <ScopeGuard scopes={['Invoice-View']} allowAdmin>
        <Invoicing />
      </ScopeGuard>
    </>
  );
}
