import { Box, Grid, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { getEmailPickupsData, postDeleteEmailPickup, postTriggerEmailPickup } from '../../../../api/vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { EMAIL } from '../../../../reusable-components/datagrid/vendorColumns';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import PickUpModal from './PickUpModal';

PickUps.propTypes = {
  selectedRow: PropTypes.object,
};

export default function PickUps({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [emailTypeList, setEmailTypeList] = useState([]);
  const [emailForList, setEmailForList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getEmailPickupsData();
      const { vendorEmailPickups, vendorEmailTypeList, vendorEmailForList } = response.data;
      setEmailTypeList(vendorEmailTypeList);
      setEmailForList(vendorEmailForList);
      const filteredVendorData = selectedRow
        ? vendorEmailPickups.filter((d) => d.vendorId === selectedRow.id)
        : vendorEmailPickups;
      setData(filteredVendorData);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Error fetching vendor data', { variant: 'error' });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const deleteEmail = async (id) => {
    try {
      const response = await postDeleteEmailPickup(id);
      if (response.status === 200) {
        enqueueSnackbar('Pickup deleted successfully', { variant: 'success' });
        fetchData();
      }
    } catch (error) {
      enqueueSnackbar('Error deleting pickup', { variant: 'error' });
    }
  };
  const triggerPickup = async (id) => {
    try {
      const response = await postTriggerEmailPickup(id);
      if (response.status === 200) {
        enqueueSnackbar('Pickup completed successfully', { variant: 'success' });
        fetchData();
      }
    } catch (error) {
      enqueueSnackbar('Error completing pickup', { variant: 'error' });
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.7,
    minWidth: 100,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <ConfirmDialog
            icon="email-search"
            title="Email Pickup"
            tooltip="Run email pickup"
            color="info"
            content={`Are you sure you want to trigger an email pickup? (this may take some time to complete)?`}
            actionButton="Ok"
            action={() => triggerPickup(id)}
          />
          <PickUpModal
            fetchData={fetchData}
            edit
            row={params.row}
            emailTypeList={emailTypeList}
            emailForList={emailForList}
          />
          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            tooltip="Delete email pickup"
            color="error"
            content={`Are you sure you want to delete email pickup?`}
            actionButton="Delete"
            action={() => deleteEmail(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <PickUpModal fetchData={fetchData} emailTypeList={emailTypeList} emailForList={emailForList} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    ),
    [emailForList, emailTypeList]
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-vendors-pickups"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          gridColumns={[...EMAIL, actionButtons]}
          forceReRender={data}
          sort={[{ field: 'vendorId', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </>
  );
}
