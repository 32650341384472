import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Box } from '@mui/material';

const SpinningLogo = forwardRef(({ sx }, ref) => {
  const logo = (
    <Box ref={ref} component="img" src="/assets/logoIcon.png" sx={{ width: 75, cursor: 'pointer', ...sx }} />
  );

  return (
    <Box
      component="span"
      sx={{
        zIndex: 999999999999999,
        display: 'inline-block',
        animation: 'spin 1.03s linear infinite',
        '@keyframes spin': {
          from: { transform: 'rotate(0deg)' },
          to: { transform: 'rotate(360deg)' },
        },
        transformOrigin: 'center',
      }}
    >
      {logo}
    </Box>
  );
});

SpinningLogo.propTypes = {
  sx: PropTypes.object,
};

export default SpinningLogo;
