import { format, getTime, formatDistanceToNow, formatISO } from 'date-fns';

// ----------------------------------------------------------------------

//13 Aug 2024
export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}
// Aug 13 2024
export function fDateMonth(date, newFormat) {
  // 'MMM dd yyyy';
  const fm = newFormat || 'MMM dd yyyy';

  return date ? format(new Date(date), fm) : '';
}
// Aug 13 2024 11:47 am
export function fDateTime(date, newFormat) {
  const fm = newFormat || ' MMM dd yyyy p';

  return date ? format(new Date(date), fm) : '';
}
// Aug 13
export function fMonthDay(date, newFormat) {
  const fm = newFormat || 'MMM dd';
  return date ? format(new Date(date), fm) : '';
}
// Aug 13, 2024
export function fMonthDayYear(date, newFormat) {
  const fm = newFormat || 'MMM dd, yyyy';
  return date ? format(new Date(date), fm) : '';
}
// recive a date in this style 2023-10-31T15:10:45.9185634
// return a date in this style 10/31/2023
export function fMonthDayYearShort(date, newFormat = 'MM/dd/yyyy') {
  if (!date || Number.isNaN(new Date(date).getTime())) {
    // Return an empty string or some other fallback if the date is invalid
    return '1';
  }
  return format(new Date(date), newFormat);
}

// date in this style   MAY 3, 10:00am
export function fMonthDayTime(date, newFormat) {
  const fm = newFormat || 'MMM dd, p';
  return date ? format(new Date(date), fm) : '';
}
// date in this style   MAY 3, 10:00am
export function fMonthDayTimeEST(date, newFormat) {
  //append est to the end
  const fm = newFormat || 'MMM dd, p';
  return date ? `${format(new Date(date), fm)} EST` : '';
}

export function fMonthDayTimeYearEST(date, newFormat) {
  //append est to the end
  const fm = newFormat || 'MMM dd, yyyy p';
  return date ? `${format(new Date(date), fm)} EST` : '';
}

export function fDayMonthDay(date, newFormat) {
  const fm = newFormat || 'EEEE, MMM dd';
  return date ? format(new Date(date), fm) : '';
}
export function fDayMonthDy(date, newFormat) {
  const fm = newFormat || 'EEE, MMM dd';
  return date ? format(new Date(date), fm) : '';
}

export function hoursLeftToDate(targetDate) {
  if (!targetDate) return '';

  const now = new Date();
  const target = new Date(targetDate);
  const diffInMilliseconds = target - now;
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

  const formattedTime = format(target, 'p EEEE MM/dd');

  if (Math.abs(diffInHours) <= 1) {
    if (diffInHours < 0) {
      return `Less than one hour ago ${formattedTime}`;
    }
    return `Less than one hour remaining ${formattedTime}`;
  }
  if (diffInHours < 0) {
    return `${Math.ceil(Math.abs(diffInHours))} hours ago ${formattedTime}`;
  }
  return `${Math.ceil(diffInHours)} hours left ${formattedTime}`;
}

/**
 *  date in this style MAY 3, 10:00am if its more then 1 year away add the year
 *
 * @param {date} date
 */
export function fMonthDayTimeYear(date, newFormat) {
  const ym = newFormat || 'MMM dd, yyyy p';
  const fm = newFormat || 'MMM dd, p';
  const now = new Date();
  if (date) {
    const d = new Date(date);
    if (d.getFullYear() === now.getFullYear()) {
      return format(d, fm);
    }
  }
  return date ? format(new Date(date), ym) : '';
}
export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

//yyyy-dd-mm
export function timestamp(date, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd';
  return date ? format(new Date(date), fm) : '';
}

export const convertToUnix = (date) => {
  const dateObj = new Date(date);
  const unixTime = dateObj.getTime();
  return unixTime;
};

export function fISODateTime(date) {
  const fm = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  return date ? format(new Date(date), fm) : '';
}

// return a date in this style 2024-02-09T00:00:00
export function fISODate(date) {
  return date ? formatISO(new Date(date)) : '';
}
