import PropTypes from 'prop-types';
import { Autocomplete, TextField, Typography, IconButton } from '@mui/material';
import Iconify from '../../../../../reusable-components/iconify/Iconify';

IntegrationFieldsForm.propTypes = {
  value: PropTypes.object,
  fieldTypes: PropTypes.array,
  setValue: PropTypes.func,
};

export default function IntegrationFieldsForm({ value, setValue, fieldTypes }) {

  return (fieldTypes.length > 0 ? <>
    <Typography variant="subtitle1" sx={{ mt: 1 }}>
      Integration Fields <IconButton onClick={() => { setValue({ ...value, fields: [...(value.fields || [{}]), {}] }) }}>
        <Iconify icon="mdi:plus-circle-outline" color="primary" width={28} height={28} />
      </IconButton>
    </Typography>
    {(value.fields?.length > 0 ? value.fields : [{}])?.map((field, index) => <Autocomplete
      fullWidth
      key={index}
      size="small"
      value={fieldTypes.find(fieldType => fieldType.value === field.integrationFieldTypeId) || null}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, newValue) => {
        setValue(value => {
          const fields = value.fields?.length > 0 ? value.fields : [{}];
          fields[index].integrationFieldTypeId = newValue?.value ?? 0;
          return { ...value, fields };
        })
      }}
      renderInput={(params) => <TextField {...params} label="Search or select" />}
      sx={{
        mt: 0.5,
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        '& .MuiFormLabel-root ': { display: 'none' },
      }}
      options={fieldTypes}
    />)}
  </> : "");
}
