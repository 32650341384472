import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Grid, Box, Typography, Button, Stack, TextField } from '@mui/material';
import { postEditVendorContactForm, postDeleteVendorContact } from '../../../../api/vendors';
import Iconify from '../../../../reusable-components/iconify';
import FormTextWithValue from '../../../../reusable-components/form/FormTextWithValue';
import FormDropdown from '../../../../reusable-components/form/FormDropdown';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { AwaitButton } from '../../../../reusable-components/await-button';

VendorContact.propTypes = {
  vendorId: PropTypes.any,
  index: PropTypes.number,
  contact: PropTypes.object,
  getContacts: PropTypes.func,
  positions: PropTypes.array,
  setAddContact: PropTypes.func,
};

export default function VendorContact({ vendorId, index, contact = null, getContacts, positions, setAddContact }) {
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);

  const [value, setValue] = useState({
    firstName: '',
    lastName: '',
    position: '',
    email: '',
    phone: '',
    cell: '',
  });

  useEffect(() => {
    setValue({
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      phone: contact?.phone,
      cell: contact?.cell,
      position: {
        value: contact?.positionId ? value.position.value : 0,
        label: contact?.positionId ? positions.find((f) => f.id === contact.positionId)?.position : '',
      },
    });
  }, [contact]);

  useEffect(() => {
    if (contact === null) {
      setEdit(true);
    }
  }, []);

  const handleEdit = () => {
    setEdit(true);
  };
  const handleEditSave = async () => {
    if (value.position === null) {
      value.position = { value: 0, label: '' };
    } else if (value.position?.value === undefined || value.position?.value === 0) {
      value.position.value = contact?.positionId || 0;
    }
    const response = await postEditVendorContactForm(value, vendorId, contact?.contactId ?? 0);
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      setAddContact(false);
      getContacts();
      setEdit(false);

      enqueueSnackbar(`Successfully updated Contact ${index + 1}`, {
        variant: 'success',
      });
    } else
      enqueueSnackbar(`Error: Email Required`, {
        variant: 'error',
      });
  };
  const handleDelete = async () => {
    const response = await postDeleteVendorContact(vendorId, contact?.contactId ?? 0);
    if (response.data) {
      setAddContact(false);
      getContacts();
      enqueueSnackbar(`Successfully deleted Contact ${index + 1}`, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(`Error deleting Contact ${index + 1}`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Grid item xs={8} key={index}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ mt: 4 }}>
          <Grid item>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Grid>
                <Typography variant="h3" color="primary" display="inline">
                  Contact {index + 1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box>
              <>
                {edit ? (
                  <>
                    <AwaitButton
                      variant="outlined"
                      size="small"
                      color="primary"
                      sx={{ mr: 1 }}
                      onClick={handleEditSave}
                      disabled={!value.email}
                    >
                      Save
                    </AwaitButton>
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={() => {
                        setEdit(false);
                        setValue({
                          firstName: contact?.firstName,
                          lastName: contact?.lastName,
                          email: contact?.email,
                          phone: contact?.phone,
                          cell: contact?.cell,
                          position: {
                            value: contact?.positionId ? value.position.value : 0,
                            label: contact?.positionId
                              ? positions.find((f) => f.id === contact.positionId).position
                              : '',
                          },
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <AwaitButton
                      variant="outlined"
                      size="small"
                      startIcon={<Iconify icon="material-symbols:edit" />}
                      sx={{ mr: 1 }}
                      onClick={handleEdit}
                    >
                      Edit
                    </AwaitButton>
                    <ConfirmDialog
                      icon="delete-outline"
                      title="Delete"
                      color="error"
                      content={`Are you sure you want to delete Contact ${index + 1}?`}
                      actionButton="Delete"
                      action={() => handleDelete()}
                    />
                  </>
                )}
              </>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Grid container direction="row" justifyContent="left" alignItems="flex-start">
          <Grid item xs={8}>
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue
                  value={value}
                  title="First Name"
                  flex={4}
                  name="firstName"
                  setValue={setValue}
                  disabled={!edit}
                />
                <FormTextWithValue
                  value={value}
                  title="Last Name"
                  flex={4}
                  name="lastName"
                  setValue={setValue}
                  disabled={!edit}
                />
                <FormDropdown
                  title="Position"
                  disabled={!edit}
                  value={value.position}
                  flex={4}
                  options={positions?.map((pos) => ({ value: pos.id, label: pos.position }))}
                  name="position"
                  setValue={setValue}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  getOptionLabel={(option) => option?.label || ''}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  onChange={(event, newValue) => {
                    setValue({
                      ...value,
                      position: newValue,
                    });
                  }}
                />
              </Stack>

              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue
                  value={value}
                  title="Email*"
                  flex={4}
                  name="email"
                  setValue={setValue}
                  disabled={!edit}
                />
                <FormTextWithValue
                  value={value}
                  title="Phone"
                  flex={4}
                  name="phone"
                  setValue={setValue}
                  disabled={!edit}
                />
                <FormTextWithValue
                  value={value}
                  title="Cell"
                  flex={4}
                  name="cell"
                  setValue={setValue}
                  disabled={!edit}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
