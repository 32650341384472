import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, Paper, Menu, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteUser, restoreUser } from '../../../../../api/user';
import { loginAsUserReq } from '../../../../../redux/slices/user';
import { updateEnableStatus, userRoleData } from '../../../../../redux/slices/users';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { ConfirmDialog } from '../../../../../reusable-components/confirm-dialog';
import Iconify from '../../../../../reusable-components/iconify';
import { ScopeGuard, useScopeCheck } from '../../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import { TabPanel } from '../../../../../reusable-components/tab-panel';
import GeneralInfoTab from './GeneralInfoTab';
import RolesTab from './RolesTab';
import RolesTemplateTab from './RolesTemplateTab';
import SecurityTab from './SecurityTab';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import SSOTab from './SSOTab';
import { SpinningLogo } from '../../../../../reusable-components/logo';

export default function UserView() {
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const [value, setValue] = useState(0);
  const [edit, setEdit] = useState(false);
  const [userEnabled, setUserEnabled] = useState(false);
  const { user, users } = useSelector((state) => state.users.data);
  const { user: account } = useSelector((state) => state.user);
  const { isLoading } = useSelector((state) => state.users);
  const { enqueueSnackbar } = useSnackbar();
  const hasEditAccess = useScopeCheck(['User-Edit'], true);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setUserEnabled(new Date(user.lockoutEnd) < new Date());
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { firstName, lastName, email, facility, position } = user;

  useEffect(() => {
    dispatch(userRoleData(id, users.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setEdit(false);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const loginAsUser = () => {
    dispatch(loginAsUserReq(email));
  };

  const handleDelete = async () => {
    const response = await deleteUser(id);
    if (response) window.location.href = '/dashboard/admin/users';
  };

  const handleRestoreUser = async () => {
    const response = await restoreUser(id);
    if (response) window.location.href = '/dashboard/admin/users';
  };

  const handleDisable = async () => {
    const enable = new Date(user.lockoutEnd) >= new Date();
    const response = await dispatch(updateEnableStatus(id, enable));

    if (response.data) enqueueSnackbar(`User ${enable ? 'en' : 'dis'}abled`, { variant: 'success' });
    else enqueueSnackbar(`User could not be ${enable ? 'en' : 'dis'}abled`, { variant: 'error' });

    setAnchorEl(null);
  };

  return user && users?.length > 0 && !isLoading ? (
    <Paper sx={{ mt: 3, px: 10, pt: 2, pb: 6, width: '100%', height: '100%' }}>
      <Grid container direction="column" justifyContent="center" alignItems="flex-start">
        <Grid item>
          <Typography variant="subtitle1" color="secondary" display="inline">
            {firstName} {lastName}
          </Typography>
          <Typography display="inline"> | {facility}</Typography>
        </Grid>
        <Grid item>
          <Typography display="inline">{position}</Typography>
          <Typography display="inline"> | {email}</Typography>
        </Grid>

        <Grid sx={{ mb: 1 }} container direction="row" justifyContent="space-between" alignItems="center" spacing={7}>
          <Grid item>
            <Box>
              <Tabs value={value} onChange={handleTabChange}>
                <Tab label="General information" />
                <Tab label="Security" />
                <Tab label="Roles" />
                {hasEditAccess && <Tab label="Role Templates" />}
                <Tab label="SSO" />
              </Tabs>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              {(value < 2 || value === 4) && (
                <>
                  <ScopeGuard scopes={['User-Edit']} allowAdmin>
                    <AwaitButton
                      color="secondary"
                      variant="outlined"
                      size="small"
                      startIcon={<EditIcon />}
                      sx={{ mr: 2 }}
                      onClick={handleEdit}
                    >
                      Edit
                    </AwaitButton>
                    <AwaitButton
                      color="secondary"
                      variant="outlined"
                      size="small"
                      startIcon={<Iconify icon="ic:outline-log-in" />}
                      sx={{ mr: 2 }}
                      onClick={loginAsUser}
                      disabled={account.id === id || user.deleteFlag}
                    >
                      Log in as
                    </AwaitButton>
                    <Button
                      color="secondary"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      variant="outlined"
                      size="small"
                      sx={{ mr: 1 }}
                      startIcon={<Iconify icon="mdi:dots-horizontal" width={24} height={24} sx={{ ml: 1 }} />}
                    />
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                    >
                      <MenuItem gridId="Disable">
                        <AwaitButton
                          variant="text"
                          size="small"
                          sx={{ px: 3 }}
                          color="black"
                          onClick={handleDisable}
                          disabled={account.id === id}
                        >
                          {userEnabled ? 'Disable' : 'Enable'}
                        </AwaitButton>
                      </MenuItem>
                      <MenuItem>
                        <ConfirmDialog
                          button="text"
                          sx={{ px: 3 }}
                          title={user?.deleteFlag ? 'Restore' : 'Delete'}
                          color={user?.deleteFlag ? 'primary' : 'error'}
                          content={`Are you sure you want to ${user?.deleteFlag ? 'restore' : 'delete'} this user?`}
                          actionButton={user?.deleteFlag ? 'Restore' : 'Delete'}
                          action={user?.deleteFlag ? handleRestoreUser : handleDelete}
                          icon={user?.deleteFlag ? 'refresh' : 'delete-outline'}
                          tooltip={user?.deleteFlag ? 'Restore' : 'Delete'}
                        />
                      </MenuItem>
                    </Menu>
                  </ScopeGuard>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <TabPanel value={value} index={0}>
        {user && <GeneralInfoTab edit={edit} setEdit={setEdit} user={user} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {user && <SecurityTab edit={edit} setEdit={setEdit} user={user} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {user && <RolesTab edit={edit} setEdit={setEdit} user={user} />}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {user && <RolesTemplateTab edit={edit} setEdit={setEdit} user={user} />}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {user && <SSOTab edit={edit} setEdit={setEdit} user={user} />}
      </TabPanel>
    </Paper>
  ) : (
    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
      <Box
        sx={{
          zIndex: 999999999999999,
          height: '100%',
          minHeight: '60vh',
        }}
      >
        <SpinningLogo />
      </Box>
    </Grid>
  );
}
