import { Box, Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { getStatusCodesData, postDeleteStatusCode } from '../../../../api/vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { STATUS } from '../../../../reusable-components/datagrid/vendorColumns';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import StatusModal from './StatusModal';

VendorStatusCodeTab.propTypes = {
  selectedRow: PropTypes.object,
};

export default function VendorStatusCodeTab({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getStatusCodesData();
      const { data } = response; // Filter booking data if a row is selected
      const filteredVendorData = selectedRow ? data.filter((d) => d.purchasingVendorId === selectedRow.id) : data;
      setData(filteredVendorData);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Error fetching status codes', { variant: 'error' });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const deleteStatusCode = async (id) => {
    try {
      const response = await postDeleteStatusCode(id);
      if (response.status === 200) {
        enqueueSnackbar('Status code deleted successfully', { variant: 'success' });
        fetchData();
      }
    } catch (error) {
      enqueueSnackbar('Error deleting status code', { variant: 'error' });
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <StatusModal fetchData={fetchData} edit row={params.row} agoraCodes={data} />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete this status?`}
            actionButton="Delete"
            action={() => deleteStatusCode(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <StatusModal fetchData={fetchData} agoraCodes={data} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    ),
    [data]
  );

  return (
    <Paper sx={{ px: 2, boxShadow: ' 0px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-vendors-status-code-tab"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          gridColumns={[...STATUS, actionButtons]}
          forceReRender={data}
          sort={[{ field: 'purchasingVendorId', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </Paper>
  );
}
