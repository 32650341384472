import { useEffect, useState } from 'react';
import { Box, TextField, DialogContentText, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { UOM_TYPES } from '../../../../utils/dropdowns';
import { possibleProductUOMsData as catalogUOM } from '../../../../api/catalog';
import { possibleProductUOMsData as warehouseUOM } from '../../../../api/warehouse';
import DebounceTextField from '../../../../reusable-components/debounce-text-field/DebounceTextField';

Step2.propTypes = {
  vendor: PropTypes.object,
  setVendor: PropTypes.func,
  vendorId: PropTypes.string,
  setVendorId: PropTypes.func,
  uomType: PropTypes.object,
  setUomType: PropTypes.func,
  uomTypes: PropTypes.array,
  setUomTypes: PropTypes.func,
  setPpdOptions: PropTypes.func,
  isWarehouseItem: PropTypes.bool,
  fromOrder: PropTypes.bool,
  vendors: PropTypes.array,
};

export default function Step2({
  vendor,
  setVendor,
  vendorId,
  setVendorId,
  uomType,
  setUomType,
  uomTypes,
  setUomTypes,
  setPpdOptions = null,
  isWarehouseItem = false,
  fromOrder = false,
  vendors,
}) {
  const [fetching, setFetching] = useState(false);

  const checkUOM = async (vendor, vendorId) => {
    setUomType(null);
    setFetching(true);
    if (vendor && vendorId) {
      const response = isWarehouseItem ? await warehouseUOM(vendor, vendorId) : await catalogUOM(vendor, vendorId);
      const data = response;
      if (data.status === 200) {
        if (data.data.length === 0) {
          setUomTypes(UOM_TYPES);
          return;
        }
        const filteredUOMTypes = UOM_TYPES.filter((uom) => data.data.includes(uom.value))
          .concat(data.data.filter(x => !UOM_TYPES.some(t => t.value === x)).map(uom => ({ label: uom, value: uom })));
        setUomTypes(filteredUOMTypes);
      } else {
        setUomTypes(UOM_TYPES);
      }
      setFetching(false);
    }
  };

  useEffect(() => {
    if (fromOrder) {
      checkUOM(vendor?.value, vendorId);
    }
  }, [fromOrder]);

  return (
    <>
      <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor *</DialogContentText>
      <Autocomplete
        size="small"
        sx={{
          width: '100%',
          mt: 1,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiFormLabelRoot ': 'none',
        }}
        type="new-password"
        options={vendors}
        autoHighlight
        getOptionLabel={(option) => option?.label}
        value={vendor}
        onChange={(event, newValue) => {
          setVendor(newValue);
          checkUOM(newValue?.value, vendorId);
          if (!isWarehouseItem) {
            setPpdOptions([]);
          }
        }}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
      />
      <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor item ID *</DialogContentText>
      <DebounceTextField
        onChange={(e) => setVendorId(e.target.value)}
        debounceFunc={() => checkUOM(vendor?.value, vendorId)}
        size="small"
        fullWidth
        value={vendorId || ''}
        variant="outlined"
        inputProps={{
          autoComplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
      />
      <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>
        UOM type *{fetching && vendor && vendorId && <Iconify icon="svg-spinners:180-ring-with-bg" color="primary" />}
      </DialogContentText>

      <Autocomplete
        size="small"
        sx={{
          width: '100%',
          mt: 1,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiFormLabelRoot ': 'none',
        }}
        type="new-password"
        options={uomTypes || []}
        autoHighlight
        disabled={!vendor || !vendorId || (!uomTypes && !fromOrder)}
        getOptionLabel={(option) => option?.label}
        // getOptionSelected={(option, value) => option.label === value.label}
        value={uomType}
        onChange={(event, newValue) => {
          setUomType(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
      />
    </>
  );
}
