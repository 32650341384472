import * as Yup from 'yup';

// ----------------------------------------------------------------------

const email = Yup.string().required('Email is required').email('Email must be a valid email address');
// - At least one uppercase letter
// - At least one lowercase letter
// - At least one digit
// - At least one special character
// - At least 8 characters in length
const passwordValidationRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[\S]{8,}$/;

const password = Yup.string()
  .required('Password is required')
  .matches(
    passwordValidationRegex,
    'Password must contain 8 characters, a number, a capital letter, a lowercase letter, and a special character.'
  );

const confirmPassword = Yup.string()
  .required('Confirm password is required')
  .oneOf([Yup.ref('password')], "Password's not match");

const passwordOptional = Yup.string()
  .matches(passwordValidationRegex, {
    excludeEmptyString: true,
    message: 'Password must contain 8 characters, a number, a capital letter, a lowercase letter, and a special character.'
  }).nullable();

export const LOGIN_SCHEMA = Yup.object().shape({ email, password });
export const FORGOT_PASSWORD_SCHEMA = Yup.object().shape({ email });
export const NEW_PASSWORD_SCHEMA = Yup.object().shape({ password, confirmPassword, passwordOptional });
