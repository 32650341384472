import { useEffect, useState } from 'react';
import { Stack, Typography, Box, TextField, FormControlLabel, Checkbox, Chip, Tooltip } from '@mui/material';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { getFacilityManagerData, updateFacilityManager } from '../../../../api/account';
import Iconify from '../../../../reusable-components/iconify';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function FacilityManagerTab() {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState({});
  const [facilities, setFacilities] = useState([]);

  const getData = async () => {
    const response = await getFacilityManagerData();
    setValue(response.facilityManager);
    setFacilities(response.facilities);
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = async () => {
    const response = await updateFacilityManager(value);
    if (response) {
      enqueueSnackbar('Facility Manager updated', { variant: 'success' });
    } else enqueueSnackbar('Facility Manager update failed', { variant: 'error' });
  };

  if (!value) return null;
  return (
    <>
      <Box>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Display name
              </Typography>
              <TextField
                size="small"
                name="Name"
                placeholder="Display name"
                sx={{
                  width: '25vw',
                }}
                value={value.name || ''}
                onChange={(e) => setValue({ ...value, name: e.target.value })}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {facilities.map((facility, index) => (
                <Chip key={index} size="small" label={facility} />
              ))}
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Auto close tickets after this many days without a response
              </Typography>
              <TextField
                size="small"
                name="autoCloseTicketsIn"
                fullWidth
                type="number"
                placeholder="30 '"
                sx={{ width: '50px' }}
                value={value.autoCloseTicketsIn || ''}
                onChange={(e) => setValue({ ...value, autoCloseTicketsIn: e.target.value })}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.receiveTicketEmails || false}
                      onChange={(event) => {
                        setValue({
                          ...value,
                          receiveTicketEmails: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Receive ticket emails"
                />
                <Tooltip title="Receive a email when tickets from your facility are updated.">
                  <Iconify icon="material-symbols:info-outline" width={18} height={18} />
                </Tooltip>
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.receiveInvoiceEmails || false}
                      onChange={(event) => {
                        setValue({
                          ...value,
                          receiveInvoiceEmails: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Receive invoice update emails"
                />
                <Tooltip title="Receive a email when automated invoices are processed.">
                  <Iconify icon="material-symbols:info-outline" width={18} height={18} />
                </Tooltip>
              </Box>
              {/* <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.useAllOrdersForPendingNumber || false}
                      onChange={(event) => {
                        setValue({
                          ...value,
                          useAllOrdersForPendingNumber: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Use all orders for pending number"
                />
              </Box> */}
            </Stack>
          </Stack>
          <Box>
            <AwaitButton
              color="primary"
              size="medium"
              type="button"
              variant="contained"
              sx={{ width: 200 }}
              onClick={onSubmit}
            >
              Save
            </AwaitButton>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
