import { Box } from '@mui/material';
import { renderColorBox, renderInitialsBox } from '../../utils/cssStyles';
import { fMonthDayYear } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import Iconify from '../iconify';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);
const description = {
  field: 'description',
  headerName: 'Description',
  width: 400,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const name = {
  field: 'name',
  headerName: 'Name',
  width: 300,
  type: 'string',
};
const shortName = {
  field: 'shortName',
  headerName: 'Short Name',
  width: 200,
  type: 'string',
};

// address: null;
const address = {
  field: 'address',
  headerName: 'Address',
  width: 200,
  type: 'string',
};
const city = {
  field: 'city',
  headerName: 'City',
  width: 200,
  type: 'string',
};
const state = {
  field: 'state',
  headerName: 'State',
  width: 200,
  type: 'string',
};
const zip = {
  field: 'zip',
  headerName: 'Zip',
  width: 200,
  type: 'string',
};

const GROUPS = [name, shortName, address, city, state, zip];

export { GROUPS };
