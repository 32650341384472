import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { OrderGuide } from '../../../../components/dashboard/admin/order-guides';
import { getAllProducts } from '../../../../redux/slices/orderGuides';

export default function OrderGuideParent() {
  const dispatch = useDispatch();
  const {
    data: { allProducts, isLoadingProducts },
  } = useSelector((state) => state.orderGuides);

  useEffect(() => {
    if (!allProducts?.length && !isLoadingProducts) dispatch(getAllProducts());
  }, [allProducts, dispatch]);

  return (
    <>
      <Helmet>
        <title>Admin Order guide | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <OrderGuide />
        </Grid>
      </Grid>
    </>
  );
}
