import { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import {
  Menu,
  MenuItem,
  Typography,
  Button,
  Chip,
  Grid,
  Box,
  Badge,
  IconButton,
  Collapse,
  Divider,
} from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { useSelector } from '../../../redux/store';
import Image from '../../../reusable-components/image';
import { fNumber } from '../../../utils/formatNumber';

export default function RentalCartDropdown() {
  const { facilityId = '' } = useParams();
  const buttonRef = useRef(null);
  const {
    data: { badge, cart, residentsList },
  } = useSelector((state) => state.rentals);

  useEffect(() => {
    if (badge) {
      setAnchorEl(buttonRef.current);
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [badge]);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const cartByResidentArrays = useMemo(() => {
    const cartByResident = cart.reduce((acc, item) => {
      if (!acc[item.residentId]) {
        acc[item.residentId] = [];
      }
      acc[item.residentId].push(item);
      return acc;
    }, {});
    return Object.values(cartByResident);
  }, [cart]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openCollapse, setOpenCollapse] = useState(null);

  const handleOpenCollapse = (index) => {
    setOpenCollapse(index === openCollapse ? null : index);
  };

  if (!cartByResidentArrays?.length) {
    return (
      <>
        <Badge
          badgeContent={badge > 0 ? `+${fNumber(badge)}` : 0}
          color="info"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          max={9999999}
          sx={{
            '.MuiBadge-badge': {
              top: '25%', // Center vertically
              transform: 'translateY(-50%)', // Adjust for badge's height
              left: 9, // Stick to the left
            },
          }}
        >
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            sx={{ borderRadius: 22 }}
            startIcon={<Iconify icon="mdi:shopping-cart-outline" width={24} height={24} />}
            endIcon={<Iconify icon="mdi:chevron-down" width={24} height={24} />}
            color="secondary"
            size="small"
          >
            Cart
          </Button>
        </Badge>
      </>
    );
  }
  return (
    <div>
      <Badge
        badgeContent={badge > 0 ? `+${fNumber(badge)}` : 0}
        color="info"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        max={9999999}
        sx={{
          '.MuiBadge-badge': {
            top: '25%', // Center vertically
            transform: 'translateY(-50%)', // Adjust for badge's height
            left: 9, // Stick to the left
          },
        }}
      >
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          ref={buttonRef}
          sx={{ borderRadius: 22 }}
          startIcon={<Iconify icon="mdi:shopping-cart-outline" width={24} height={24} />}
          endIcon={<Iconify icon="mdi:chevron-down" width={24} height={24} />}
          size="small"
          color="secondary"
        >
          {cartByResidentArrays.length
            ? `${cartByResidentArrays.length} Cart${cartByResidentArrays.length !== 1 ? 's' : ''}`
            : 'Cart'}
        </Button>
      </Badge>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {cartByResidentArrays.length &&
          residentsList?.length &&
          cartByResidentArrays.map((resident, index) => (
            <>
              <MenuItem key={index} disableRipple>
                <span style={{ marginLeft: 8, marginRight: 8 }}>
                  <Typography variant="subtitle1" sx={{ ml: 1, color: '#707070', fontWeight: 'bold' }}>
                    {resident[0].residentFirstName} {resident[0].residentLastName}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ ml: 1, color: '#8cc53f' }}>
                    ${resident.reduce((acc, item) => acc + item.price, 0)}
                  </Typography>
                  {resident.map((res, ind) => (
                    <Box key={ind}>
                      <Collapse
                        in={index === openCollapse}
                        style={{ padding: '10px', borderRadius: '5px', maxWidth: '250px' }}
                      >
                        <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Image
                            alt="attachment"
                            src={res.image}
                            sx={{ borderRadius: 1.5, width: 30, maxHeight: '30px' }}
                          />
                          <Box sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'baseline' }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ ml: 1, color: '#707070', maxWidth: '200px', overflow: 'hidden' }}
                            >
                              {res.description}
                            </Typography>{' '}
                            <Typography variant="subtitle2" sx={{ ml: 2, color: '#707070' }}>
                              ${res.price}
                            </Typography>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
                </span>
                <Chip label={resident.reduce((acc, item) => acc + item.quantity, 0)} sx={{ ml: 'auto', mr: 1 }} />
                <IconButton onClick={() => handleOpenCollapse(index)}>
                  <Iconify icon={openCollapse ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={24} height={24} />
                </IconButton>
              </MenuItem>{' '}
              <Divider sx={{ width: '80%', ml: 4, opacity: 0.61, backgroundColor: '#707070' }} />
            </>
          ))}
        <Grid container direction="row" justifyContent="center">
          <Button
            variant="text"
            onClick={() => {
              handleClose();
              navigate(`/dashboard/rentals/new-rental/rental-cart/${facilityId}`);
            }}
          >
            Proceed to cart
          </Button>
        </Grid>
      </StyledMenu>
    </div>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'transparent', // or set this to the normal background color of your menu items
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
