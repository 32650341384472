import { createSlice } from '@reduxjs/toolkit';
import {
  getReports,
  getItemsOrderedReport,
  getFacilityComparisonReport,
  getVendorComparisonReport,
  getTopLevelBudgetReport,
  getFacilityHistoryReport,
  getPoInvoiceMatchupReports,
  getFacilitiesInvoiceReport,
  getInvoiceIssuesReport,
  getCheckInvoicePDFsReport,
  getSubstituteReport,
} from '../../api/reports';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    firstPageView: true,
    formValues: {
    },
    dateValues: [],
    selectedReport: null,
    purchasing: {
      categories: [],
      companies: [],
      facilities: [],
      reports: [],
      subcategories: [],
      vendors: [],
    },
    other: {
      categories: [],
      companies: [],
      facilities: [],
      reports: [],
      subcategories: [],
      vendors: [],
    },
    reports: [],
  },
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setReportTypeSuccess(state, action) {
      state.isLoading = false;
      state.data.selectedReport = action.payload;
    },
    setFormValuesSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, formValues: { ...action.payload } };

    },  
    setFirstPageViewSuccess(state, action) {
      state.isLoading = false;
      state.data.firstPageView = action.payload;
    },
    getReportsDataSuccess(state, action) {
      state.isLoading = false;
      state.data.purchasing = action.payload;
    },
    getOtherReportsSuccess(state, action) {
      state.isLoading = false;
      state.data.other = action.payload;
    },
    getReportSuccess(state, action) {
      state.isLoading = false;
      state.data.reports = action.payload;
    },
    clearReports(state) {
      state.isLoading = false;
      state.data.reports = [];
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getReportsData(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getReports(type);
      const updatedResponse = {
        ...response.data,
        // vendors: response.data.vendors.map(({ value, label }) => { id: value, name: label }),
        vendors: response.data.vendors.map(({ value, label }) => ({ id: value, name: label })),
        companies: response.data.companies.map(({ value, label }) => ({ id: value, name: label })),
      };
      if (type === 'Purchasing') {
        dispatch(slice.actions.getReportsDataSuccess(updatedResponse));
      } else {
        dispatch(slice.actions.getOtherReportsSuccess(updatedResponse));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOtherReports() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getReports('Other');
      dispatch(slice.actions.getPreviousOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearReportsInRedux() {
  return async (dispatch) => {
    dispatch(slice.actions.clearReports());
  };
}

export function setFormValuesInRedux(newValue) {
  return async (dispatch) => {
    try {
      await dispatch(slice.actions.setFormValuesSuccess(newValue));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setDateValuesInRedux(newValue) {
  console.log("reduxnew value", newValue);

  return async (dispatch) => {
    try {
      await dispatch(slice.actions.setDateValuesSuccess(newValue));

    } catch (error) {
      console.log("err", error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setFirstPageViewAction(isFirstPageView) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setFirstPageViewSuccess(isFirstPageView));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setReportTypeAction(reportType) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setReportTypeSuccess(reportType));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setFormValuesAction(reportType) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setReportTypeSuccess(reportType));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getItemsOrderedReportAction({ startDate, endDate, facilities, allItems = false }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getItemsOrderedReport({ startDate, endDate, facilities, allItems });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFacilityComparisonReportAction({ facilities, categories, startDate, endDate }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFacilityComparisonReport({ facilities, categories, startDate, endDate });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVendorComparisonReportAction({ vendors, categories, startDate, endDate }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getVendorComparisonReport({ vendors, categories, startDate, endDate });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//topLevelBudgetReportAction
export function getTopLevelBudgetReportAction({
  companies,
  facilities,
  categories,
  subcategories,
  startDate,
  endDate,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getTopLevelBudgetReport({
        companies,
        facilities,
        categories,
        subcategories,
        startDate,
        endDate,
      });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFacilityHistoryReportAction({ facilityId, categories, dates }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFacilityHistoryReport({ facilityId, categories, dates });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPoInvoiceMatchupReportsAction({ facilities, vendors, startDate, endDate }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPoInvoiceMatchupReports({ facilities, vendors, startDate, endDate });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSubstituteReportAction({ companies,
  facilities,
  categories,
  subcategories,
  startDate,
  endDate }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getSubstituteReport({
        companies,
        facilities,
        categories,
        subcategories,
        startDate,
        endDate
      });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFacilitiesInvoiceReportAction({ facilities, vendors, startDate, endDate }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFacilitiesInvoiceReport({ facilities, vendors, startDate, endDate });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoiceIssuesReportAction({ facilities, startDate, endDate }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoiceIssuesReport({ facilities, startDate, endDate });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCheckInvoicePDFsReportAction({ facilityId, vendors, skip, take }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCheckInvoicePDFsReport({ facilityId, vendors, skip, take });
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
