import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import {
  getResidentsForFacility,
  getRentalVendors,
  getCartsForUser,
  getFacilityVendors,
} from '../../../redux/slices/rentals';
import { RentalItemsSelect } from '../../../components/dashboard/rentals/newRental';

export default function Rental() {
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get('facilityId') || null;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const {
    data: { residentsList, rentalVendors, cart, facilityVendors },
  } = useSelector((state) => state.rentals);

  useEffect(() => {
    if (!facilityVendors.length) {
      dispatch(getFacilityVendors(facilityId));
    }
    if (!residentsList.length) {
      dispatch(getResidentsForFacility(facilityId));
    }
  }, [facilityId]);

  useEffect(() => {
    if (!rentalVendors.length) {
      dispatch(getRentalVendors());
    }
    if (!cart.length) {
      dispatch(getCartsForUser(user.Id));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Rental | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <RentalItemsSelect />
        </Grid>
      </Grid>
    </>
  );
}
