import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useDispatch } from '../../redux/store';
import { getActiveTickets, getCompletedTickets } from '../../redux/slices/tickets';
import CompletedTickets from '../../components/dashboard/tickets/CompletedTickets';
import OpenTickets from '../../components/dashboard/tickets/OpenTickets';

// ----------------------------------------------------------------------

export default function Tickets() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParams.get('filter'));
  const [startDate, setStartDate] = useState(searchParams.get('start'));
  const [endDate, setEndDate] = useState(searchParams.get('end'));
  useEffect(() => {
    setFilter(searchParams.get('filter'));
    setStartDate(searchParams.get('start'));
    setEndDate(searchParams.get('end'));
  }, [searchParams]);

  useEffect(() => {
    if (filter === 'active') {
      dispatch(getActiveTickets());
    } else {
      dispatch(getCompletedTickets({ startDate, endDate }));
    }
  }, [filter, dispatch, startDate, endDate]);

  return (
    <>
      <Helmet>
        <title>Tickets | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        {filter === 'active' ? <OpenTickets /> : <CompletedTickets />}
      </Grid>
    </>
  );
}
