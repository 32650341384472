import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, IconButton, Button } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { getOrdersSent } from '../../../../api/orders';
import { VIEW_ORDER_HISTORY } from '../../../../reusable-components/datagrid/orderColumns';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import { HOST_API_URL } from '../../../../config-global';

export default function OrdersSent() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { id = '' } = useParams();
  const [data, setData] = useState([]);

  const getSent = async () => {
    const response = await getOrdersSent(id);
    if (response.status === 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (id && open) {
      getSent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const FileType = (params) => {
    const { fileName, fileExtension } = params.row;
    return (
      <>
        <FileThumbnail key={fileName} file={fileName} tooltip sx={{ width: 32, height: 32 }} />
      </>
    );
  };
  const ActionButtons = (params) => {
    const { id } = params.row;
    return (
      <>
        <IconButton
          color="info"
          onClick={() => {
            window.open(`${HOST_API_URL}Api/DownloadOrderSent?id=${id}`, '_blank');
          }}
        >
          <Iconify icon={'mdi:tray-arrow-down'} />
        </IconButton>
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };
  const fileExtension = {
    field: 'File Type',
    headerName: 'File Type',
    flex: 0.5,
    renderCell: (params) => FileType(params),
    id: 'fileExtension',
    className: 'fileExtension',
  };
  const sent = {
    field: 'sent',
    headerName: 'Sent',
    flex: 0.5,
    id: 'main',
    className: 'main',
    renderCell: (params) => {
      const { sent } = params.row;
      return sent ? <Iconify icon="material-symbols:check-circle-outline" color="primary" /> : <></>;
    },
  };

  return (
    <>
      <ScopeGuard scopes={['Edit-Order']} allowAdmin>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClickOpen}
          // sx={{ mb: 1, width: '200px', boxShadow: 3, fontWeight: 'bold' }}
          endIcon={<Iconify icon={'mdi:eye'} />}
          size="small"
        >
          View History
        </Button>
      </ScopeGuard>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          View History
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
            <DataGridPro
              getRowHeight={() => 'auto'}
              rows={data}
              columns={[...VIEW_ORDER_HISTORY, fileExtension, sent, actions]}
              disableSelectionOnClick
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
