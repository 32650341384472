import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Tooltip } from '@mui/material';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import { useDispatch } from '../../../../../redux/store';
import { updateReduxShoppingCart } from '../../../../../redux/slices/orderSheet';
import Iconify from '../../../../../reusable-components/iconify';
import { COPY_ORDER } from '../../../../../reusable-components/datagrid/orderColumns';
import { getOrdersForCopy } from '../../../../../api/order-sheet';
import PreviousOrderInfo from './PreviousOrderInfo';

CopyOrder.propTypes = {
  setSortModel: PropTypes.func,
  setCopiedCart: PropTypes.func,

};
export default function CopyOrder({ setCopiedCart = () => {}, setSortModel }) {
  const dispatch = useDispatch();
  const { facilityId = '' } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const categoryId = urlParams.get('categoryId');
  const [data, setData] = useState([]);

  const [open, setOpen] = useState(false);

  const getOrdersToCopy = async () => {
    const response = await getOrdersForCopy(categoryId, facilityId);
    if (response.data) {
      setData(response.data);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = async (orderId) => {
    const success = await dispatch(updateReduxShoppingCart(orderId, categoryId, facilityId));
    if (success) {
      setSortModel([{ field: 'quantity', sort: 'desc' }]);
      setCopiedCart(true)
      handleClose();
    }
    handleClose();
  };

  useEffect(() => {
    if (open) {
      getOrdersToCopy();
    }
  }, [data?.length, open]);

  const ActionButtons = (params) => (
    <>
      <PreviousOrderInfo row={params.row} />
      <Tooltip title="Copy Order" arrow>
        <IconButton onClick={() => handleCopy(params.row.orderId)} color="secondary">
          <BrowserUpdatedIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  return (
    <div>
      <Button
        variant="text"
        disableElevation
        sx={{
          textDecoration: 'underline',
        }}
        onClick={handleClickOpen}
        color="secondary"
        endIcon={<Iconify icon="ic:round-file-copy" width={13} height={13} />}
      >
        Copy Previous Order
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Select Order to Copy
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CustomDataGrid
            gridId="orders-new-order-copy"
            boxSX={{ height: 'calc(100vh - 170px)' }}
            data={data}
            gridColumns={[...COPY_ORDER, actions]}
            // CustomLeftToolbar={customToolbar}
            getRowId={(row) => row.orderId}
            isLoading={data.length === 0}
            sort={[{ field: 'orderedOn', sort: 'desc' }]}
            isModal
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
