import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET  Company/Companies;
export const getCompanies = async () => {
  const response = await axios.get('/Company/Companies');
  return response;
};

// POST  Company/DeleteCompany; id
export const deleteCompany = async (id) => {
  const response = await axios.post(`/Company/DeleteCompany?id=${id}`);
  return response;
};

// POST  Company/CompanyForm;
export const postCompanyForm = async ({ value }) => {
  const formData = objectToFormData({
    Name: value.name || '',
    ShortName: value.shortName || '',
    Address: value?.address || '',
    City: value.city || '',
    State: value.state || '',
    Zip: value.zip || '',
  });

  if (value.id) {
    formData.append('Id', value.id);
  }
  const response = await axios.post('Company/CompanyForm', formData);

  return response;
};

// GET  Company/CompanyNameAlreadyExists; name id
export const checkCompanyName = async (name, id) => {
  const response = await axios.get(`/Company/CompanyNameAlreadyExists?name=${name}&id=${id}`);
  return response;
};

// GET  Company/DownloadCompanies;
