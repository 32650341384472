import { Box } from '@mui/material';
import { fCurrency } from '../../utils/formatNumber';
import { renderColorBox } from '../../utils/cssStyles';
import { fMonthDayYear } from '../../utils/formatTime';

const description = {
  field: 'description',
  headerName: 'Description',
  width: 300,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const subcategory = {
  field: 'agoraSubcategory',
  headerName: 'Subcategory',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),

  type: 'customSelect',
};
const category = {
  field: 'agoraCategory',
  headerName: 'Category',
  width: 120,
  type: 'customSelect',
  renderCell: (params) => renderColorBox(params.row.agoraCategory, 'secondary'),
};
const price = {
  field: 'price',
  headerName: 'Price',
  width: 120,
  renderCell: (params) => fCurrency(params.row.price),
  valueGetter: (params) => params.row.price,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
};

const vendorName = {
  field: 'vendorName',
  headerName: 'Vendor',
  width: 180,
  type: 'customSelect',
};
const addedBy = {
  field: 'addedBy',
  headerName: 'Added By',
  width: 180,
  type: 'customSelect',
};

const id = {
  field: 'productId',
  headerName: 'PS ID',
  width: 100,
  renderCell: (params) => `PS${params.row.productId}`,
  valueGetter: (params) => `PS${params.row.productId}`,
  type: 'customText',
};
const attachedItemIds = {
  field: 'attachedItemIds',
  headerName: 'Vendor Ids',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const vendorItemId = {
  field: 'vendorItemId',
  headerName: 'Vendor Item Id',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const manufacturer = {
  field: 'manufacturer',
  headerName: 'Manufacturer',
  width: 200,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const itemId = {
  field: 'itemId',
  headerName: 'Id',
  width: 80,
  type: 'customSelect',
};

const vendorCategory = {
  field: 'category',
  headerName: 'Vendor Category',
  width: 180,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),

  type: 'customSelect',
};
const manufacturerId = {
  field: 'manufacturerId',
  headerName: 'Manufacturer Id',
  width: 180,
  type: 'customSelect',
};

const uom = {
  field: 'uom',
  headerName: 'UOM',
  renderCell: (params) =>
    `${params.row.uomAmount ? params.row.uomAmount : ''}/${params.row.uomType ? params.row.uomType : ''}`,
  width: 100,
  type: 'customSelect',
};
const baseUOMAmount = {
  field: 'baseUOMAmount',
  headerName: 'Base UOM',
  renderCell: (params) =>
    `${params.row.baseUOMAmount ? params.row.baseUOMAmount : ''}${params.row.baseUOMType ? '/' : ''}${
      params.row.baseUOMType ? params.row.baseUOMType : ''
    }`,

  width: 180,
  type: 'customText',
};

const isPPD = {
  field: 'isPPD',
  headerName: 'PPD',
  renderCell: (params) => (params.value ? 'Yes' : ''),
  width: 80,
  type: 'customSelect',
};

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Date Added',
  type: 'customDate',
  width: 150,
  renderCell: (params) => fMonthDayYear(params.value),
  valueGetter: (params) => new Date(params.value),
};
const dateModified = {
  field: 'dateModified',
  headerName: 'Date Modified',
  type: 'customDate',
  width: 150,
  renderCell: (params) => fMonthDayYear(params.value),
  valueGetter: (params) => new Date(params.value),
};
const modifiedBy = {
  field: 'modifiedBy',
  headerName: 'Modified By',

  width: 200,
  type: 'customSelect',
};

const deleteFlag = {
  field: 'deleteFlag',
  headerName: 'Deleted',
  width: 100,
  type: 'customSelect',
};

const ITEM_HISTORY = [
  itemId,
  description,
  category,
  subcategory,
  vendorCategory,
  id,
  price,
  attachedItemIds,
  vendorItemId,
  manufacturer,
  manufacturerId,
  uom,
  baseUOMAmount,
  vendorName,
  isPPD,
  dateAdded,
  addedBy,
  dateModified,
  modifiedBy,
  deleteFlag,
];

export { ITEM_HISTORY };
