import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { addTempItemToAnOrderInRedux, updateCatalogQtyInRedux } from '../../../../redux/slices/orders';

AddItemQuantityField.propTypes = {
  row: PropTypes.object,
  isIdFound: PropTypes.bool,
};

export default function AddItemQuantityField({ row, isIdFound }) {
  const dispatch = useDispatch();
  const { id: orderId = '' } = useParams();

  const { tempOrderListById } = useSelector((state) => state.orders || {});
  const [cartItems, setCartItems] = useState([]);
  const [quantity, setQuantity] = useState();

  useEffect(() => {
    setCartItems(tempOrderListById[orderId] || []);
  }, [orderId]);

  const {
    pictures,
    description,
    vendorName,
    vendorItemId,
    isPPD,
    previouslyOrderedItems,
    id,
    price,
    uomAmount,
    uomType,
    agoraCategory,
    agoraSubcategory,
    vendorId,
    productId,
  } = row;

  const handleAddCart = (quantity) => {
    dispatch(
      addTempItemToAnOrderInRedux({
        orderId,
        pictures,
        productId,
        description,
        vendorName,
        vendor: vendorName,
        vendorItemId,
        isPPD,
        previouslyOrderedItems,
        id,
        itemId: id,
        temporary: true,
        approved: true,
        unsavedId: 0,
        quantity,
        price,
        uomAmount,
        uomType,
        agoraCategory,
        agoraSubcategory,
        vendorId,
      })
    );
    dispatch(
      updateCatalogQtyInRedux({
        orderId,
        ...row,
        quantity,
      })
    );
  };

  useEffect(() => {
    const product = cartItems.find((item) => item.id === id);
    if (product) {
      setQuantity(product.quantity);
    } else {
      setQuantity(0);
    }
  }, [cartItems, id]);


  return (
    <>
      {isIdFound ? (
        <Box
          component="div"
          sx={{
            fontSize: '0.7rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
        >
          <Typography variant="caption" sx={{ color: 'error.main' }}>
            Item already exists in cart
          </Typography>
        </Box>
      ) : (
        <TextField
          size="small"
          value={quantity || ''}
          onChange={(e) => {
            const newValue = Number(e.target.value);
            if (!Number.isNaN(newValue) && newValue >= 0) {
              setQuantity(newValue);
              handleAddCart(newValue);
            }
          }}
          sx={{ maxWidth: '70px', minWidth: '70px' }}
        />
      )}
    </>
  );
}
