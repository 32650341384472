import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import CustomToolbar from '../../../components/dashboard/invoicing/CustomToolbar';
import { ReportsView } from '../../../components/dashboard/reports';

export default function Reports() {
  const [activeRows, setActiveRows] = useState([]);
  const customToolbar = useCallback(() => {
    const isFiltered = (filter) => filter === 'reports';
    return <CustomToolbar isFiltered={isFiltered} activeRows={activeRows} />;
  }, [activeRows]);

  return (
    <>
      <Helmet>
        <title>Invoicing | Platform Solutions</title>
      </Helmet>
      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <ReportsView CustomToolbar={customToolbar} invoiceView />
        </Grid>
      </Grid>
    </>
  );
}
