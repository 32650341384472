import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Grid, CardContent, Box, IconButton } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';

const StyledIcon = styled('div')(() => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '5px',
  alignItems: 'center',
  width: '51px',
  height: '51px',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function DashboardAlerts() {
  const arrowButtonForward = () => {
    // console.log('forward');
  };

  const arrowButtonBack = () => {
    // console.log('back');
  };
  return (
    <>
      <Grid item lg={12}>
        <Typography variant="subtitle1" color="primary">
          Alerts
          <IconButton color="primary" onClick={arrowButtonBack}>
            <Iconify icon="eva:arrow-back-fill" width={15} height={5} />
          </IconButton>
          <IconButton color="primary" onClick={arrowButtonForward}>
            <Iconify icon="eva:arrow-forward-fill" width={15} height={5} />
          </IconButton>
        </Typography>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={12} md={6} lg={3}>
          <Card
            sx={{
              mr: 1,
              display: 'flex',
              direction: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxHeight: '5em',
            }}
          >
            <Box sx={{ pl: 1, mr: -2 }}>
              <StyledIcon
                sx={{
                  color: 'primary.main',
                  backgroundImage: (theme) =>
                    `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
                      theme.palette.primary.dark,
                      0.24
                    )} 100%)`,
                }}
              >
                <Iconify icon="eva:message-circle-outline" width={24} height={24} />
              </StyledIcon>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <div style={{ overflow: 'hidden', lineHeight: '1.5em', maxHeight: '3.2em' }}>
                  <Typography variant="subtitle1" color="text.secondary">
                    New Ticket:
                  </Typography>
                </div>
                <div
                  style={{
                    overflow: 'hidden',
                    lineHeight: '1.5em',
                    maxHeight: '3.2em',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    @Dovi Geretz
                  </Typography>
                </div>
                <div style={{ overflow: 'hidden', lineHeight: '1.5em', maxHeight: '3.2em' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Message that the person wrote
                  </Typography>
                </div>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            sx={{
              mr: 1,
              display: 'flex',
              direction: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxHeight: '5em',
            }}
          >
            <Box sx={{ pl: 1, mr: -2 }}>
              <StyledIcon
                sx={{
                  color: 'primary.main',
                  backgroundImage: (theme) =>
                    `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
                      theme.palette.primary.dark,
                      0.24
                    )} 100%)`,
                }}
              >
                <Iconify icon="eva:message-circle-outline" width={24} height={24} />
              </StyledIcon>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <div style={{ overflow: 'hidden', lineHeight: '1.5em', maxHeight: '3.2em' }}>
                  <Typography variant="subtitle1" color="text.secondary">
                    New Ticket:
                  </Typography>
                </div>
                <div
                  style={{
                    overflow: 'hidden',
                    lineHeight: '1.5em',
                    maxHeight: '3.2em',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    @Dovi Geretz
                  </Typography>
                </div>
                <div style={{ overflow: 'hidden', lineHeight: '1.5em', maxHeight: '3.2em' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Message that the person wrote
                  </Typography>
                </div>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            sx={{
              mr: 1,
              display: 'flex',
              direction: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxHeight: '5em',
            }}
          >
            <Box sx={{ pl: 1, mr: -2 }}>
              <StyledIcon
                sx={{
                  color: 'primary.main',
                  backgroundImage: (theme) =>
                    `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
                      theme.palette.primary.dark,
                      0.24
                    )} 100%)`,
                }}
              >
                <Iconify icon="eva:message-circle-outline" width={24} height={24} />
              </StyledIcon>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <div style={{ overflow: 'hidden', lineHeight: '1.5em', maxHeight: '3.2em' }}>
                  <Typography variant="subtitle1" color="text.secondary">
                    New Ticket:
                  </Typography>
                </div>
                <div
                  style={{
                    overflow: 'hidden',
                    lineHeight: '1.5em',
                    maxHeight: '3.2em',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    @Dovi Geretz
                  </Typography>
                </div>
                <div style={{ overflow: 'hidden', lineHeight: '1.5em', maxHeight: '3.2em' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Message that the person wrote
                  </Typography>
                </div>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            sx={{
              mr: 1,
              display: 'flex',
              direction: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxHeight: '5em',
            }}
          >
            <Box sx={{ pl: 1, mr: -2 }}>
              <StyledIcon
                sx={{
                  color: 'primary.main',
                  backgroundImage: (theme) =>
                    `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
                      theme.palette.primary.dark,
                      0.24
                    )} 100%)`,
                }}
              >
                <Iconify icon="eva:message-circle-outline" width={24} height={24} />
              </StyledIcon>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <div style={{ overflow: 'hidden', lineHeight: '1.5em', maxHeight: '3.2em' }}>
                  <Typography variant="subtitle1" color="text.secondary">
                    New Ticket:
                  </Typography>
                </div>
                <div
                  style={{
                    overflow: 'hidden',
                    lineHeight: '1.5em',
                    maxHeight: '3.2em',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    @Dovi Geretz
                  </Typography>
                </div>
                <div style={{ overflow: 'hidden', lineHeight: '1.5em', maxHeight: '3.2em' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Message that the person wrote
                  </Typography>
                </div>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
