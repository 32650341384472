import { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../snackbar';

AwaitButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  snackbarMessage: PropTypes.string,
  refreshData: PropTypes.func,
  children: PropTypes.node,
};

export default function AwaitButton({ refreshData, snackbarMessage, onClick, children, ...other }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handlePromise = () => {
    setTimeout(() => setIsLoading(false), 1000);
    if (snackbarMessage) enqueueSnackbar(snackbarMessage, { variant: 'success' });
    if (refreshData) refreshData();
  };

  const handleClick = async (e) => {
    e.stopPropagation();
    if (isLoading || !onClick) return;
    setIsLoading(true);
    const res = onClick();
    if (res instanceof Promise) {
      try {
        await res;
        handlePromise();
        return;
      } catch {
        enqueueSnackbar('Something has gone wrong', { variant: 'error' });
      }
    }
    handlePromise();
  };

  return (
    <LoadingButton loading={isLoading} onClick={handleClick} {...other}>
      {children}
    </LoadingButton>
  );
}
