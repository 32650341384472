import PropTypes from 'prop-types';
import { Autocomplete, TextField, Typography } from '@mui/material';
import TextFormField from './IntegrationTextFormField';
import CheckboxFormField from './IntegrationCheckboxFormField';
import Row from './IntegrationFormRow';

CartCheckIntegration.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  dataImports: PropTypes.array
};

export default function CartCheckIntegration({ value, setValue, dataImports }) {

  return <>
    <TextFormField
      header={"Cart Check URL"}
      value={value.cartCheckUrl}
      onChange={(e) => setValue({ ...value, cartCheckUrl: e.target.value })}
    />
    <CheckboxFormField
      label={"Retrieves Data From Database"}
      checked={value.retrievesDataFromDB ?? false}
      onChange={(e) => setValue({ ...value, retrievesDataFromDB: e.target.checked })}
    />   
    {value.retrievesDataFromDB && <>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>Database data imported from</Typography>
      <Autocomplete
        fullWidth
        size='small'
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        renderInput={(params) => <TextField {...params} label="Search or select" />}
        sx={{
          mt: 0.5,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiFormLabel-root ': { display: 'none' },
        }}
        value={dataImports.find((opt) => opt.value === value.importsDBDataFrom) ?? null}
        onChange={(event, newValue) => {
          setValue((value) => ({ ...value, importsDBDataFrom: newValue?.value ?? 0 }));
        }}
        options={dataImports}
      />
    </>}
    <Row>
      <CheckboxFormField
        label={"Available"}
        checked={value.available ?? false}
        onChange={(e) => setValue({ ...value, available: e.target.checked })}
      />
      <CheckboxFormField
        label={"Inventory"}
        checked={value.inventory ?? false}
        onChange={(e) => setValue({ ...value, inventory: e.target.checked })}
      />
      <CheckboxFormField
        label={"PPD"}
        checked={value.ppd ?? false}
        onChange={(e) => setValue({ ...value, ppd: e.target.checked })}
      />       
    </Row>
    <Row>   
      <CheckboxFormField
        label={"Status"}
        checked={value.status ?? false}
        onChange={(e) => setValue({ ...value, status: e.target.checked })}
      />
      <CheckboxFormField
        label={"Subsitutes"}
        checked={value.subsitutes ?? false}
        onChange={(e) => setValue({ ...value, subsitutes: e.target.checked })}
      />
      <CheckboxFormField
        label={"Direct Replacements"}
        checked={value.directReplacements ?? false}
        onChange={(e) => setValue({ ...value, directReplacements: e.target.checked })}
      />
    </Row>
    <CheckboxFormField
      label={"Price"}
      checked={value.price ?? false}
      onChange={(e) => setValue({ ...value, price: e.target.checked })}
    />   
  </>;
}
