import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Dialog, DialogTitle, DialogContent, IconButton, Tooltip } from '@mui/material';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../../../reusable-components/iconify';
import { COPY_ORDER_VIEW } from '../../../../../reusable-components/datagrid/noFlexOrderColumns';
import { getPreviousOrderInfoData } from '../../../../../api/orders';

PreviousOrderInfo.propTypes = {
  row: PropTypes.object,
};
export default function PreviousOrderInfo({ row }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const getPrevOrder = async () => {
    const response = await getPreviousOrderInfoData(row.orderId);
    setData(response.data?.items || []);
    setLoading(false);
    return response.data;
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      getPrevOrder();
    }
  }, [open]);

  return (
    <div>
      <Tooltip title="View Order" arrow>
        <IconButton onClick={handleClickOpen} color="warning">
          <VisibilityIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Viewing Order {row.orderNumber}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <Iconify icon="material-symbols:arrow-back" width={28} height={28} />{' '}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CustomDataGrid
            gridId="orders-new-order-order-navbar-previous-order-info"
            boxSX={{ height: 'calc(100vh - 170px)' }}
            data={data}
            gridColumns={COPY_ORDER_VIEW}
            // CustomLeftToolbar={customToolbar}
            // getRowId={(row) => row.orderId}
            isLoading={loading}
            // sort={[{ field: 'orderedOn', sort: 'desc' }]}
            isModal
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
