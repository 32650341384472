/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  TextField,
  Typography,
  Box,
  Button,
  Autocomplete,
  Grid,
  DialogActions,
} from '@mui/material';
import { uploadInvoicePdf } from '../../../api/invoicing';
import Iconify from '../../../reusable-components/iconify';
import { useSnackbar } from '../../../reusable-components/snackbar';
import FileThumbnail from '../../../reusable-components/file-thumbnail';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { UPLOAD_PDF } from '../../../reusable-components/datagrid/invoiceColumns';
import { getPendingInvoicing } from '../../../redux/slices/invoicing';
import { useSelector, useDispatch } from '../../../redux/store';
import { AwaitButton } from '../../../reusable-components/await-button';

export default function UploadPDF() {
  const dispatch = useDispatch();
  const {
    data: { invoiceData },
  } = useSelector((state) => state.invoicing);

  const isLoading = useSelector((state) => state.invoicing.isLoading);

  const { vendors } = invoiceData;

  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [pdfFiles, setPdfFiles] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadData, setUploadData] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const onDrop = (acceptedFiles) => {
    handleFileInputChange({ target: { files: acceptedFiles } });
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'application/pdf': [],
    },
    multiple: true,
  });

  const handleClickOpen = () => {
    setPdfFiles(null);
    setOpen(true);
  };
  const handleClose = () => {
    setPdfFiles(null);
    setOpen(false);
    setShowUploadModal(false);
    setUploadData([]);
  };
  const handleCloseAndDispatch = () => {
    setPdfFiles(null);
    setOpen(false);
    setShowUploadModal(false);
    setUploadData([]);
    dispatch(getPendingInvoicing({ pending: true }));
  };

  const handleFileInputChange = (event) => {
    const { files } = event.target;
    if (files) {
      const newFiles = Array.from(files);
      const mergedFiles = pdfFiles ? [...pdfFiles, ...newFiles] : newFiles;

      setPdfFiles(mergedFiles);
    }
  };

  const handleRemoveImage = (index) => {
    const removedPictures = [...pdfFiles];
    removedPictures.splice(index, 1);
    setPdfFiles(removedPictures);
  };

  const upload = async () => {
    const response = await uploadInvoicePdf(vendorId, pdfFiles);
    if (response.status === 200) {
      setUploadData(response.data);
      setShowUploadModal(true);
    } else {
      enqueueSnackbar('Error uploading PDF', { variant: 'error' });
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="mdi:tray-arrow-up" />}
      >
        Upload PDF
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Upload PDF to Vendor
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Vendor
            </Typography>
            <Autocomplete
              fullWidth
              size="small"
              onChange={(event, newValue) => {
                if (newValue) setVendorId(newValue.value);
              }}
              value={vendors?.find((vendor) => vendor?.value === vendorId) || null}
              getOptionLabel={(option) => option.label}
              options={vendors || []}
              renderInput={(params) => <TextField {...params} label="Search or select" />}
              sx={{
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': { display: 'none' },
              }}
            />
          </Box>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
            {pdfFiles?.map((file, index) => (
              <Grid item xs={2} key={index}>
                <Box
                  m={1}
                  boxShadow={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FileThumbnail key={file} file={file} tooltip sx={{ width: 32, height: 32 }} />

                  <IconButton onClick={() => handleRemoveImage(index)}>
                    <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
            <input {...getInputProps()} />
            <Paper
              elevation={isDragActive ? 6 : 1}
              sx={{
                p: 2,
                mt: 4,
                bgcolor: 'background.paper',
                color: 'info.main',
                borderColor: 'info.main',
                border: 1,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  bgcolor: 'background.default',
                  boxShadow: 3,
                },
              }}
            >
              <Typography variant="body2" textAlign="center">
                Click to Upload or Drag and Drop
              </Typography>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={!pdfFiles || !vendorId}
            sx={{ width: 150 }}
            onClick={upload}
          >
            Upload
          </AwaitButton>
        </DialogActions>
      </Dialog>
      {/* ////showUploadModal//// */}
      <Dialog open={showUploadModal} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Files Uploaded
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CustomDataGrid
            gridId="invoicing-upload-pdf"
            boxSX={{ width: '100%', height: 'calc(100vh - 250px)' }}
            data={uploadData}
            gridColumns={UPLOAD_PDF}
            id={'invoiceVendorId'}
            isModal
            isLoading={isLoading}
            sort={[{ field: 'invoiceVendorId', sort: 'asc' }]}
          />
        </DialogContent>
        <DialogActions>
          <AwaitButton
            variant="outlined"
            color="error"
            size="medium"
            sx={{ width: 150, mr: 2 }}
            onClick={handleCloseAndDispatch}
          >
            Close
          </AwaitButton>
        </DialogActions>
      </Dialog>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        accept="pdf/*"
        onChange={handleFileInputChange}
      />
    </>
  );
}
