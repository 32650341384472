import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Autocomplete, Typography, Box, Button, TextField, DialogActions } from '@mui/material';
import { postCopyFacilityVendorBookingAccounts } from '../../../../api/facility';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';

BookingFacilityCopy.propTypes = {
  fetchData: PropTypes.func,
  facilityRow: PropTypes.object,
  facilities: PropTypes.array,
  bookingType: PropTypes.object,
};

export default function BookingFacilityCopy({ fetchData, bookingType, facilityRow, facilities })
{
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState({ facility: '' });
  const [open, setOpen] = useState(false);

  useEffect(() => setValue({ facility: '' }), [open])

  const handleSave = async () => {
    try {
      const response = await postCopyFacilityVendorBookingAccounts(value.facility.id, facilityRow.id);
      if (response.status === 200) {
        enqueueSnackbar('Copied successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to copy', { variant: 'error' });
      }
      fetchData();
      setOpen(false)
    } catch (error) {
      console.error('Error deleting booking account:', error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return <>
    <Button
      variant="outlined"
      sx={{ mr: 1 }}
      size="small"
      onClick={() => setOpen(true)}
      startIcon={<Iconify icon="eva:plus-outline" />}
    >
      Copy Facility
    </Button>
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }} >
        Copy Facility
        <CloseIconButton onClick={() => setOpen(false)} />
      </DialogTitle>
      <DialogContent sx={{ height: '100%', width: '100%' }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Facility to copy from
        </Typography>
        <Autocomplete
          fullWidth
          size="small"
          value={value.facility}
          onChange={(event, newValue) => setValue({ facility: newValue })}
          options={facilities
            ?.filter((type) => type?.facilityBookingTypeId === bookingType?.id)
            ?.map((type) => ({ id: type?.id, label: type.facility }))}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => <TextField {...params} label="Search or select" />}
          sx={{
            mt: 1,
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiFormLabel-root ': { display: 'none' },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <AwaitButton
          variant="contained"
          color="primary"
          size="medium"
          disabled={value.facility === ''}
          sx={{ width: 150 }}
          onClick={handleSave}
        >
          Save
        </AwaitButton>
      </DialogActions>
    </Dialog>
  </>;
}
