import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { addToCart } from '../../../../redux/slices/orderSheet';

QuantityFieldValue.propTypes = {
  product: PropTypes.object,
};

export default function QuantityFieldValue({ product }) {
  const dispatch = useDispatch();
  const {
    data: { cart },
  } = useSelector((state) => state.orderSheet);
  const { id, price, uomAmount } = product;

  const [quantity, setQuantity] = useState();

  useEffect(() => {
    const product = cart.find((item) => item.id === id);
    if (product) {
      setQuantity(product.quantity);
    }
  }, [cart, id]);

  return quantity;
}
