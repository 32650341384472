import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

export async function getCartData(facilityId) {
  const response = await axios.get(`/Cart?facilityId=${facilityId}`);
  return response;
}
export async function deleteCartApi(facilityId) {
  const response = await axios.post(`/DeleteCart?facilityId=${facilityId}`);
  return response;
}

export async function deleteCartItem(facilityId, itemId, punchoutId) {
  const response = await axios.post(
    `/DeleteCartItem?facilityId=${facilityId}&itemId=${itemId}&punchoutId=${punchoutId}`
  );
  return response;
}

export async function updateCartApi({ carts, facilityId }) {
  const cartItemUpdates = carts.flatMap((cart) =>
    cart.items?.map((item) => ({
      quantity: item.quantity || 0,
      punchoutItemId: item.punchoutItemId,
      cartId: item.id,
      uomType: item.uomType,
    }))
  );
  const formData = objectToFormData({ cartItemUpdates, facilityId });
  const response = await axios.post('/UpdateCart', formData);
  return response;
}
export async function bulkDeleteCartApi({ carts, facilityId, selectedToDelete }) {
  const cartItemUpdates = carts.flatMap((cart) =>
    cart.items
      .filter((item) => !selectedToDelete.includes(item.id))
      .map((item) => ({
        quantity: item.quantity || 0,
        punchoutItemId: item.punchoutItemId,
        cartId: item.id,
        uomType: item.uomType,
      }))
  );

  const formData = objectToFormData({ cartItemUpdates, facilityId });
  const response = await axios.post('/UpdateCart', formData);
  return response;
}

export async function cartCheckApi({ carts, facilityId }) {
  const items = carts.flatMap((cart) =>
    cart.items?.map((item) => ({
      id: item.id,
      vendorId: item.vendorId,
      itemId: item.itemId,
      quantity: item.quantity,
      price: item.price,
      vendorItemId: item.sku,
      uomType: item.uomType,
    }))
  );
  const formData = objectToFormData({ items, facilityId, fromCart: true });
  const response = await axios.post('/CartCheck', formData);
  return response;
}

export async function cartCheckApiFromArrayOfObj({ carts, facilityId }) {
  if (!carts || !Array.isArray(carts)) {
    console.error('carts is not a valid array');
    return Promise.reject(new Error('Invalid carts array'));
  }

  const items = carts.map((cart) => ({
    id: cart.id,
    vendorId: cart.vendorId,
    quantity: cart.quantity,
    price: cart.price,
    vendorItemId: cart?.sku || cart.vendorItemId,
    uomType: cart.uomType,
    isPPD: cart.isPPD,
  }));

  const formData = objectToFormData({ items, facilityId });
  const response = await axios.post('/CartCheck', formData);
  return response;
}
export async function cartCheckApiForOneItem(facilityId, itemArray) {
  const items = itemArray.map((itm) => ({
    id: itm.id,
    vendorId: itm.vendorId,
    quantity: itm.quantity,
    price: itm.price,
    vendorItemId: itm?.sku || itm.vendorItemId,
    uomType: itm.uomType,
    isPPD: itm?.isPPD,
  }));

  const formData = objectToFormData({ items, facilityId });
  const response = await axios.post('/CartCheck', formData);
  return response;
}

export async function SubmitOrderApi({ facilityId, personFor, items, requestedDate, agoraCategoryId }) {
  const updateItems = items?.map((item) => ({
    cartId: item.id,
    punchoutItemId: item.punchoutItemId,
    itemId: item.itemId,
    quantity: item.quantity,
    vendorItemId: item.sku,
    uomType: item.uomType,
  }));

  const formData = objectToFormData({ facilityId, personFor, items: updateItems, requestedDate, categoryId: agoraCategoryId });

  const response = await axios.post('/SubmitOrder', formData);
  return response;
}
