import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

export async function getFacilityCensusData() {
  const response = await axios.get('/Admin/FacilityCensus/FacilitiesCensus');
  return response;
}
export async function getFacilityCensusDataByFacilityId(facilityId) {
  const response = await axios.get(`/Admin/FacilityCensus/CensusData?facilityId=${facilityId}`);
  return response;
}

export async function setFacilityCensusData({ dates, facilityId, fetchDaysForBudgets }) {
  const formData = objectToFormData({ dates, facilityId, fetchDaysForBudgets });

  const response = await axios.post('/Admin/FacilityCensus/SetFacilityCensus', formData);
  return response;
}
