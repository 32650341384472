import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { CompletedOrderView } from '../../orders/order';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getPreviousOrderInfoByNumber } from '../../../../redux/slices/orders';

CompletedOrderInformation.propTypes = {
  expandBottom: PropTypes.bool,
  vendor: PropTypes.string,
};

export default function CompletedOrderInformation({ expandBottom, vendor }) {
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState(null);

  const {
    data: { invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const { invoice } = invoiceItemData || {};

  const getPrevOrderInfo = async (invoiceId) => {
    const id = await dispatch(getPreviousOrderInfoByNumber(invoiceId));
    setOrderId(id);
    return id;
  };

  useEffect(() => {
    if (invoice?.invoiceId) getPrevOrderInfo(invoice?.invoiceId);
  }, [invoice?.invoiceId]);

  return (
    <>
      <Box sx={{ maxHeight: expandBottom ? '630px': '430px' }}>
        <CompletedOrderView expandBottom={expandBottom} newInvoiceView overrideOrderId={orderId} vendor={vendor} />
      </Box>
    </>
  );
}
