import { createSlice } from '@reduxjs/toolkit';
import {
  getCatalogData,
  getProductDetailsData,
  getProductHistoryData,
  getProductOrderedData,
  getProductOrderGuideData,
  getAllSKUs,
  deleteProductItem,
  deleteProductData
} from '../../api/catalog';

const initialState = {
  isLoading: false,
  isLoadingAllSKUsData: false,
  error: null,
  data: {
    agoraCategories: [],
    agoraSubcategories: [],
    products: [],
    vendors: [],
    productDetails: [],
    allSKUsData: [],
    productHistory: [],
    orderHistory: [],
    productOrderGuide: {
      agoraCategories: [],
      orderGuides: [],
    },
  },
};

const slice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingAllSKUsData(state) {
      state.isLoadingAllSKUsData = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCatalogSuccess(state, action) {
      state.isLoading = false;
      //spread this in to the state so not to overwrite the state
      state.data = { ...state.data, ...action.payload };
    },

    getAllSKUsDataSuccess(state, action) { 
      state.isLoadingAllSKUsData = false;
      state.data.allSKUsData = action.payload;
    },

    getProductDetailsSuccess(state, action) {
      state.isLoading = false;
      const { agoraCategories, agoraSubcategories, vendors } = action.payload;
      state.data.productDetails = action.payload;
      // agoraCategories is true
      if (agoraCategories) {
        state.data.agoraCategories = agoraCategories;
      }
      if (agoraSubcategories) {
        state.data.agoraSubcategories = agoraSubcategories;
      }
      if (vendors) {
        state.data.vendors = vendors;
      }
    },

    getProductHistorySuccess(state, action) {
      state.isLoading = false;
      state.data.productHistory = action.payload;
    },
    getOrderHistorySuccess(state, action) {
      state.isLoading = false;
      state.data.orderHistory = action.payload;
    },

    getProductOrderGuideSuccess(state, action) {
      state.isLoading = false;
      state.data.productOrderGuide = action.payload;
    },  

    deleteItem(state, action) {
      state.isLoading = false;
      state.data.allSKUsData = state.data.allSKUsData.filter(sku => action.payload.isItem ? sku.id !== action.payload.id :
        sku.productId !== action.payload.productId);
      const deleteMainProduct = !action.payload.isItem || !state.data.allSKUsData.some(sku => sku.productId === action.payload.productId);    
      if (deleteMainProduct) state.data.products = state.data.products.filter(product => product.productId !== action.payload.productId);
    },  

    clearBoth(state, action) {
      state.data.productHistory = [];
      state.data.orderHistory = [];
    },
  },
});

// Reducer
export default slice.reducer;

export function getCatalog() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCatalogData();
      dispatch(slice.actions.getCatalogSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductDetails(productId, flag) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProductDetailsData(productId, flag);
      dispatch(slice.actions.getProductDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getProductHistory(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProductHistoryData(productId);
      dispatch(slice.actions.getProductHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderHistory(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProductOrderedData(productId);
      dispatch(slice.actions.getOrderHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductOrderGuide(productId, agoraCategoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProductOrderGuideData(productId, agoraCategoryId);
      dispatch(slice.actions.getProductOrderGuideSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearHistory() {
  return async (dispatch) => {
    dispatch(slice.actions.clearBoth());
  };
}

export function getAllSKUsData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingAllSKUsData());
    try {
      const response = await getAllSKUs();    
      dispatch(slice.actions.getAllSKUsDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProduct(id, productId, isItem, enqueueSnackbar) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = isItem ? await deleteProductItem(id) : await deleteProductData(productId);
      if (response.status === 200)
      {
        dispatch(slice.actions.deleteItem({ id, productId, isItem }));
        enqueueSnackbar('Product deleted', { variant: 'success' });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
