import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { ExcelView } from '../../../components/dashboard/admin/excel';

// ----------------------------------------------------------------------

export default function ExcelUpload() {
  return (
    <>
      <Helmet>
        <title>Admin Excel Upload | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <ExcelView />
        </Grid>
      </Grid>
    </>
  );
}
