import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { BOOKING } from '../../../../reusable-components/datagrid/vendorColumns';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters';
import BookingAccountModal from './BookingAccountModal';
import { getVendorBookingAccountsData, postDeleteBookingVendorAccount } from '../../../../api/vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { HOST_API_URL } from '../../../../config-global';
import Iconify from '../../../../reusable-components/iconify';

BookingAccount.propTypes = {
  selectedRow: PropTypes.object,
};

export default function BookingAccount({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getVendorBookingAccountsData();
      const data = response.data.accounts;
      setBookingData(response.data.bookingVendors);
      const filteredVendorData = selectedRow ? data.filter((d) => d.vendorId === selectedRow.id) : data;
      setData(filteredVendorData);
      setIsLoading(false);
    } catch {
      enqueueSnackbar('Error fetching booking data', { variant: 'error' });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const deleteBookingAccount = async (id) => {
    try {
      const response = await postDeleteBookingVendorAccount(id);
      if (response.status === 200) {
        enqueueSnackbar('Booking account deleted successfully', { variant: 'success' });
        fetchData();
      }
    } catch (error) {
      enqueueSnackbar('Error deleting booking account', { variant: 'error' });
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { bookingVendor, id, vendor } = params.row;
      return (
        <>
          <BookingAccountModal fetchData={fetchData} edit row={params.row} bookingData={bookingData} />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete booking - ${vendor} - ${bookingVendor}?`}
            actionButton="Delete"
            action={() => deleteBookingAccount(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box>
              <BookingAccountModal fetchData={fetchData} bookingData={bookingData} />
              <Button
                variant="outlined"
                color="secondary"
                sx={{ mr: 1 }}
                size="small"
                onClick={() => {
                  window.open(`${HOST_API_URL}Api/Vendor/DownloadVendorBookingAccounts`, '_blank');
                }}
                startIcon={<Iconify icon="file-icons:microsoft-excel" />}
              >
                Download
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="secondary">
            Booking
          </Typography>
        </Grid>
        <Grid item />
        <Grid item />
      </Grid>
    ),
    [bookingData]
  );

  const columns = createColumns([...BOOKING, actionButtons]);

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-vendor-booking-account-view"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          forceReRender={data}
          gridColumns={columns}
          sort={[{ field: 'vendor', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          disableSelectionOnClick
          isModal
        />
      </Grid>
    </>
  );
}
