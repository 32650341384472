import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { TasksView } from '../../../components/dashboard/admin/tasks';

// ----------------------------------------------------------------------

export default function Tasks() {
  return (
    <>
      <Helmet>
        <title>Admin Tasks | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <TasksView />
        </Grid>
      </Grid>
    </>
  );
}
